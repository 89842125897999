import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {map} from 'rxjs/operators';
import { environment } from '../../environments/environment';
import {Globals} from '../globals';

@Injectable()
export class ProfileStatementService{

  httpOptions = {
    headers: new HttpHeaders({
      'Host': environment.dev_url_prefix + '/',
      'Authorization': 'Bearer ' + this.globals.access_token,
      'Accept': 'application/json'
    })
  };
  postOptions={
    headers: new HttpHeaders({
      'Host': environment.dev_url_prefix + '/',
      'Authorization': 'Bearer ' + this.globals.access_token,
      'Accept': 'application/json',
      'Content-Type':'application/x-www-form-urlencoded'
    })
  };
  deleteOptions = {
    headers: new HttpHeaders({
      'Host': '/',
      'Authorization': 'Bearer ' + this.globals.access_token,
      'Accept': 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded'
    })
  };

  constructor(
    private http: HttpClient,
    private globals: Globals
  ) { }

  /**
   Get founation playlists
   * @param cid - client playlist id
   * @returns {Observable<any>}
   */
  getPlaylists(cid){
    return this.http.get(environment.dev_url_prefix + '/api/V1/profile_statement/get_fp_playlists/' + cid, this.httpOptions).pipe(map(playlists => {
      return playlists;
    }));
  }

  /**
   Send calculation on server
   * @param fid - foundation playlist id
   * @param cid - client playlist id
   * @param formData - post data
   * @param save - saving data on server
   * @returns {Observable<any>}
   */
  sendCalculating(fid,cid,formData,save){
    var s = '';
    if(save)
      s='/true';
    return this.http.post(environment.dev_url_prefix + '/api/V1/profile_statement/calculate/'+fid+'/'+cid+s, formData, this.postOptions).pipe(map(calc => {
      return calc;
    }));
  }
  /*savePS(cid){
    return this.http.post(environment.dev_url_prefix + '/api/V1/profile_statement/save/'+cid, '', this.postOptions).pipe(map(ps => {
      return ps;
    }));
  }*/
  /**
   Save profile statement
   * @param cid - client playlist id
   * @param formData - post data
   * @returns {Observable<any>}
   */
  savePS(psr_id,formData,cid=null,reload = false){
    return this.http.post(environment.dev_url_prefix + '/api/V1/profile_statement/save_checked_list/'+psr_id+'/'+cid+'/'+reload, formData, this.postOptions).pipe(map(ps => {
      return ps;
    }));
  }

  /**
   Get profile statements list
   * @param cid - client playlist id
   * @returns {Observable<any>}
   */
  getPSList(psid){
    return this.http.get(environment.dev_url_prefix + '/api/V1/profile_statement/list/' + psid, this.httpOptions).pipe(map(ps => {
      return ps;
    }));
  }

  /**
   Delete profile statement
   * @param pid - playlist id
   * @returns {Observable<any>}
   */
  deletePS(pid){
    return this.http.delete(environment.dev_url_prefix + '/api/V1/profile_statement/remove/' + pid, this.deleteOptions).pipe(map(userDelete => {
      return userDelete;
    }));
  }
  deletePSR(psrid){
    return this.http.delete(environment.dev_url_prefix + '/api/V1/profile_statement/remove_psr/' + psrid, this.deleteOptions).pipe(map(userDelete => {
      return userDelete;
    }));
  }
  getPSHistory(cid){
    return this.http.get(environment.dev_url_prefix + '/api/V1/profile_statement/history/' + cid, this.httpOptions).pipe(map(history => {
      return history;
    }));
  }
  CheckProfileName(cid){
    return this.http.get(environment.dev_url_prefix + '/api/V1/profile_statement/check_profile/' + cid, this.httpOptions).pipe(map(status => {
      return status;
    }));
  }

  createNewProfileStatement(cid,formData){
    return this.http.put(environment.dev_url_prefix + '/api/V1/profile_statement/create_profile/' + cid, formData,this.postOptions).pipe(map(profileStatement => {
      return profileStatement;
    }));
  }

  suspend_create_new(psid,cid,formData){
    return this.http.post(environment.dev_url_prefix + '/api/V1/profile_statement/suspend_create_new/'+psid+'/'+cid, formData, this.postOptions).pipe(map(ps => {
      return ps;
    }));
  }

  getProfileStatementList(cid){
    return this.http.get(environment.dev_url_prefix + '/api/V1/profile_statement/raw_list/' + cid, this.httpOptions).pipe(map(profile_statements => {
      return profile_statements;
    }));
  }

  updatepsName(id,str){
    return this.http.get(environment.dev_url_prefix + '/api/V1/profile_statement/updateprofile/' + str +'/' +id ,this.httpOptions).pipe(map(profile_statements => {
      return profile_statements;
    }));
  }

  profileStatmentPlaylistUpdate(id,psr_id){
    return this.http.get(environment.dev_url_prefix + '/api/V1/profile_statement/updateplaylist/' + id +'/' +psr_id ,this.httpOptions).pipe(map(profile_statements => {
      return profile_statements;
    }));
  }
}
