import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { DropzoneComponent, DropzoneConfigInterface, DropzoneDirective } from 'ngx-dropzone-wrapper';
import { CPPlaylistCategoriesService } from "../playlist-categories/cp_playlist-categories.service";
import { CPPlaylistsService } from "../services/cp_playlists.service";
import { CommonService } from "../services/common.service";
import { ScheduleService } from "../services/schedule.service";
import { AlertComponent } from "../alert/alert.component";
import { ConfirmComponent } from "../confirm/confirm.component";
import { Globals } from "../globals";
import { ActivatedRoute, NavigationExtras, Router } from "@angular/router";
import { HttpHeaders, HttpClient } from "@angular/common/http";
import { ClientSampleService } from "../services/client_sample.service";
import { NgForm } from "@angular/forms";
import * as FileSaver from "file-saver";

@Component({
  selector: "app-sample-cp-playlist",
  templateUrl: "./sample-cp-playlist.component.html",
  styleUrls: ["./sample-cp-playlist.component.scss"],
  providers: [
    CPPlaylistsService,
    CPPlaylistCategoriesService,
    ScheduleService,
    CommonService,
    ClientSampleService,
  ],
})
export class SampleCpPlaylistComponent implements OnInit {
  constructor(
    private chanelsService: CPPlaylistCategoriesService,
    private playlistServ: CPPlaylistsService,
    private scheduleService: ScheduleService,
    private commonService: CommonService,
    private clientsampleservice: ClientSampleService,
    private router: Router,
    private http: HttpClient,
    private globals: Globals,
    private route: ActivatedRoute,
  ) { }

  public isLoad: boolean = false;
  public systemStatus = {
    status: "",
    called_in: "",
    next_expected_call: "",
  };
  isFocused = false;
  searchStr = ""; //Value of search input
  clientsearchStr = ""; // value for client search input
  entitySearchStr = "";
  entitySearchResult = [];
  usersList: any = Array(); //List of Clients
  usersListRaw: any = Array();
  searchedPlaylists: any = []; //Array of search results
  searchPopupOpened = false; //Open search results popup
  sitesList: any = null; //List of sites
  chanelsList: any = null; //List of Channels
  playlists; //List of playlists
  archivePlaylists: any;// List OF Archive Playlist

  clientsList = [];
  selectedClient = "";

  /*
   Schedule information in edit popup
   {
    userId: - Client id,
    sitesList - sites list for select,
    siteId - selected site id,
    channelsList - channels list for select,
    channelId - selected channel id,
    playlistList - playlists for select,
    playlistId - selected playlist id,
    name - schedule name,
    begin_date - schedule start date,
    finish_date - schedule end date,
    begin_time - schedule start time,
    finish_time - schedule end time,
    bgColor - color for background in schedule table,
    txtColor - color for text in schedule table,
    recurrence_fl - value for schedule recurrence,
    random - value for schedule random,
    mastering_fl - value for schedule mastering,
    clash_distance - value for schedule clash distance,
    duration - schedule duration,
    duration_unit - schedule duration time unit,
    artistsNumber - count of artists in schedule,
    recurrence: {
      radio_value - Value for recurence time unit
      daily: {
        days - count of days
      },
      weekly: {
        week - count of weeks
        days - array of days
      },
      monthly: {
        month - count of monthes
        monthSelection - value for selection type,
        days - count of days,
        number - value for recurrence select,
        type - days of week
      },
      yearly: {
        year - count of years,
        monthes - list of monthes,
        monthSelection - value for selection type,
        number- value for recurrence select,
        type - days of week
      }
    },//Recurrence settings


   */
  schedule = {
    userId: null,
    sitesList: [],
    siteId: null,
    channelsList: [],
    channelId: null,
    playlistList: [],
    playlistId: null,
    name: "",
    begin_date: {
      year: "",
      month: "",
      day: "",
    },
    finish_date: {
      year: "",
      month: "",
      day: "",
    },
    begin_time: {
      hours: "00",
      minutes: "00",
    },
    //'00:00',
    finish_time: {
      hours: "00",
      minutes: "00",
    },
    //'00:00',
    bgColor: "#FFFFFF",
    txtColor: "#FF0000",
    recurrence_fl: "0",
    random: "0",
    mastering_fl: "0",
    clash_distance: "0",
    duration: "0",
    override_duration: {
      hours: "00",
      minutes: "00",
    },
    duration_unit: "mins",
    artistsNumber: 0,
    recurrence: {
      radio_value: "1",
      daily: {
        days: 1,
      },
      weekly: {
        week: 1,
        days: [],
      },
      monthly: {
        month: 1,
        monthSelection: "1",
        days: [],
        number: 1,
        type: "SU",
      },
      yearly: {
        year: 1,
        monthes: [],
        monthSelection: false,
        number: 1,
        type: "SU",
      },
    },
    mastering: {
      m_switch: "0",
      days: 1,
      months: 1,
      months_2: 1,
      every_week_day: 1,
      recurrence_dow: "MO",
    },
    onDemand: false,
  };
  /**
   Value for confirmation popup
   {
     id(integer) - ID of element for action(default null),
     type(integer) - type of function for action(default 1)
   }
   **/
  confirmValues = {
    id: null,
    type: 0,
  };
  /**
   Clear array of playlists
   */
  clearPlaylistsData() {
    this.playlists = null;
  }
  /**
   Clear array of archiveplaylists
   */
  clearArchivePlaylistsData() {
    this.archivePlaylists = null;
  }


  /**
   Clear array of channels
   */
  clearChanelsData() {
    this.checkedChanelId.id = null;
    this.chanelsList = null;
  }
  /**
   Show actions for selected client/site/channel
   {
    number - number of type action,
    id - client/site/channel id
  };
   */
  isShowActions = {
    number: null,
    id: null,
  };
  /*
   Selected Client information
   {
    id - Client id,
    name - Client name
   }
   */
  checkedUserId = {
    id: null,
    name: "",
  };
  /*
   Selected Site information
   {
    id - Site id,
    name - Site name
   }
   */
  checkedSiteId = {
    id: null,
    name: "",
  };
  /*
  Selected Channel information
  {
    id - Channel id,
    name - Channel name
  }
  */
  checkedChanelId = {
    id: null,
    name: "",
  };
  schedulesList: any = []; //Schedules list
  current_user = null; // information of logged user
  createclient = false; //Open popup for playlists edit
  tabs = {
    selected: false,
  };


  channeltabs = {
    selected: 1,
  }

  changingStatus = false;

  // selected channel for edit
  channelEditPopUpOpen = false;
  selectedChannel = {
    id: "",
    name: "",
  };

  //List of playlists
  /*
  Playlilst information in edit popup
  {
    id - playlist id,
    name - playlist name,
    status - playlist activity,
    channel - selected channel for playlist,
    site - selected site for playlist,
    description - playlist description,
    user - Client id,
    sitesList - sites list for select,
    channelsList - channels list for select
    countrycode-country code
    countrycodeList-list of country with code
    edited_by user id store
  }
   */
  playlistFields = {
    id: null,
    name: "",
    status: 1,
    site: null,
    user: null,
    sitesList: [],
    edited_by: null,
  };
  //For create New playlist PopUp
  create_new_playlistpopUp = false;

  /*
  Channel information in edit popup
  {
    id - channel id,
    name - channel name,
    visible - channel activity,
    site - site id,
    user - client id
    sitesList - list of sites for select
  }
   */
  chanelFields = {
    id: null,
    name: "",
    visible: 1,
    site: null,
    user: null,
    sitesList: [],
  };

  popups={
    linkedopened:false,

  }



  public orderBy = 'created_at';
  public order = 'DESC';
  playlistStatusText = ["LIVE", "EDITING", "ARCHIVE"]; //Text for playlist status

  saveandManageplaylist: any; // return n save nad manage playlist;
  showClientProperties = false;

  showchannelList = false;
  localimagepath = "/storage_samples/images/";
  localdeckpath = "/storage_samples/documents/";

  @ViewChild(AlertComponent, { static: true }) alert: AlertComponent; //Component of alert popup
  @ViewChild(ConfirmComponent, { static: true }) confirm: ConfirmComponent; //Component of confirm popup
  @ViewChild("comments") comments: ElementRef;
  @ViewChild(DropzoneDirective) directiveRef?: DropzoneDirective; //Dropzone component
  @ViewChild('imageDropZone') imageDropZone: DropzoneComponent;
  @ViewChild('PDFDropZone') PDFDropZone?: DropzoneComponent; //Dropzone component
  imageconfig: DropzoneConfigInterface; //Dropzone component
  Pdfconfig: DropzoneConfigInterface; //Dropzone component

  ngOnInit() {
    this.commonService.getCurrentLoggedUser().subscribe((current_user) => {

      this.current_user = current_user;
      if (localStorage.getItem("cp_es_keyword") != null) {
        this.entitySearchStr = localStorage.getItem("cp_es_keyword");
        this.entitySearch(window.event);
      }
      this.updateClientsList();
      this.setCheckedElements();
      // this.getallcllient();
    });
    this.imageconfig = {
      // Change this to your upload POST address:
      // url: "http://localhost:8000/api/V1/client_sample/uploadfilename/",
      url: "/api/V1/client_sample/uploadfilename",
      maxFilesize: 512,
      maxFiles: 1,
      acceptedFiles: "image/png, image/jpeg",
      method: "POST",
      timeout: 900000, // 15 minutes
      parallelUploads: 1,
      headers: {
        Authorization: "Bearer " + this.globals.access_token,
      },
    };
    this.Pdfconfig = {
      // Change this to your upload POST address:
      // url: "http://localhost:8000/api/V1/client_sample/uploadpdf/",
      url: "/api/V1/client_sample/uploadpdf",
      maxFilesize: 512,
      maxFiles: 1,
      acceptedFiles: "application/pdf",
      method: "POST",
      timeout: 900000, // 15 minutes
      parallelUploads: 1,
      headers: {
        Authorization: "Bearer " + this.globals.access_token,
      },
    };

  }



  /**
   Set param if input focused
   */
  focusInput() {
    this.isFocused = true;
  }
  /**
   Set param if input blured
   */
  blurInput() {
    this.isFocused = false;
  }

  /**
   Crear search results
   @param e - event
   * @returns {boolean}
   */
  clearSearch(e: any) {
    e.preventDefault();
    this.searchStr = "";
  }

  onUploadError(e) {
    console.log(e[1][0] == "error");
    if (e[1][0] == "error") {
      this.alert.alertOpen("Uploaded Failed !!", 1);
    }
  }

  onUploadSuccess(e) {
    this.alert.alertOpen(e[1].msg, 0);
    this.getPropertyValuesById(this.selectedClient);
  }

  /**
   Get list of Clients
   */
  updateClientsList() {
    this.clientsampleservice.getClientsList().subscribe((clients:any) => {
      this.usersList = clients;
      this.usersListRaw = clients;

      if (this.checkedUserId.id && this.checkedUserId.name == "") {
        let user = this.usersList.find((x) => x.id == this.checkedUserId.id);
        this.checkedUserId.name = user.name;
      }
      this.isLoad = false;
    });
  }


  /**
  Set checked client,site,channel after back from edit playlist page
  */
  setCheckedElements() {
    this.route.queryParams.subscribe((params) => {
      if (params["uid"] != "" && params["uid"] != null) {
        this.checkedUserId.id = params["uid"];
        // this.scrollToElementUserList(this.checkedUserId.id);
        this.getSites(this.checkedUserId.id, "", 0);
      } else {
        this.checkedUserId.id = null;
      }
      if (params["cat"] != "" && params["cat"] != null) {
        this.checkedSiteId.id = params["cat"];
        this.showChanels("", this.checkedSiteId.id, "", 0);
      } else {
        this.checkedSiteId.id = null;
        this.sitesList = null;
      }
      this.isShowActions.number = 2;
      this.channeltabs.selected = 1;
    });
  }

  /**
   Show table of playlists
   * @param e - event
   * @param cid - channel id
   * @param name - channel name
   * @param num - type of action
   */
  showPlaylists(e, cid, name, num) {
    this.showClientProperties = false;
    this.isShowActions.number = 3;
    if (num == 1 && this.checkedChanelId.id == cid) {
      this.checkedChanelId.id = null;
      this.checkedChanelId.name = "";
      this.playlists = [];
      this.schedulesList = [];
    } else {
      this.playlists = [];
      this.schedulesList = [];
      this.checkedChanelId.id = cid;
      this.checkedChanelId.name = name;
      this.updatePlaylists(0);
    }
  }


  /**
   Crear search results
   @param e - event
   * @returns {boolean}
   */
  clearEntitySearch(e) {
    this.isLoad = true;
    e.preventDefault();
    this.entitySearchStr = "";
    this.entitySearchResult = [];
    this.checkedUserId.id = null;
    this.checkedSiteId.id = null;
    this.checkedChanelId.id = null;
    this.checkedUserId.name = "";
    this.checkedSiteId.name = "";
    this.checkedChanelId.name = "";
    this.updateClientsList();
    localStorage.removeItem("cp_es_keyword");
  }

  search(e: any) {
    this.clearEntitySearch(e);
    e.preventDefault();
    if (this.searchStr != "") {
      if (this.searchStr.length < 6) {
        this.alert.alertOpen("Please enter minimum 6 characters to search", 1);
        return false;
      }
      this.playlistServ
        .searchPlaylists(this.searchStr)
        .subscribe((playlists: Array<any>) => {
          if (playlists != null) {
            console.log("playlist", playlists);
            const newPlaylists = playlists.filter((object) => {
              return object.cp_category.parent_id !== 1;
            });
            this.searchedPlaylists = newPlaylists;
          } else this.searchedPlaylists = [];
          this.searchPopupOpened = true;
        });
    }
    console.log(this.searchedPlaylists);
  }

  entitySearch(e) {
    this.alert.alertOpen("In Progress", 1);
    // localStorage.setItem("cp_es_keyword", this.entitySearchStr.toLowerCase());
    // this.sitesList = [];
    // this.checkedUserId.id = null;
    // this.checkedSiteId.id = null;
    // this.checkedChanelId.id = null;
    // this.checkedUserId.name = "";
    // this.checkedSiteId.name = "";
    // this.checkedChanelId.name = "";
    // e.preventDefault();
    // if (this.entitySearchStr != "") {
    //   this.isLoad = true;

    //   this.chanelsService
    //     .getSiteSearch(this.entitySearchStr.toLowerCase())
    //     .subscribe((sites: any) => {
    //       if (sites == undefined || sites.length == 0) {
    //         this.alert.alertOpen("No results", 1, 15);
    //         this.clearEntitySearch(e);
    //       }
    //       let data = [];
    //       for (var row in sites) {
    //         let children = [];
    //         for (var rowInner in sites[row]["children"]) {
    //           children.push(sites[row]["children"][rowInner]);
    //         }
    //         sites[row]["children"] = children;
    //         data.push(sites[row]);
    //       }
    //       this.entitySearchResult = data;
    //       console.log(this.entitySearchResult);
    //       this.sitesList = sites;
    //       this.isLoad = false;
    //     });
    // }
  }

  /**
   Actions after breadcrumbs click
   * @param num - number of back action
   * @returns {boolean}
   */
  resetCheckedElements(num) {
    if (num < 4) {
      this.checkedChanelId.id = null;
    }
    if (num < 3) {
      this.checkedSiteId.id = null;
    }
    if (num < 2) {
      this.checkedUserId.id = null;
    }
    return false;
  }

  /**
   Get List of sites
   * @param uid - user id
   * @param name - user name
   * @param num - number of action
   */
  getSites(uid, name, num) {
    this.showClientProperties = false;
    this.tabs.selected = false;
    this.checkedSiteId.id = null;
    this.checkedSiteId.name = null;
    this.checkedChanelId.id = null;
    this.isShowActions.number = 1;
    this.clearChanelsData();
    this.clearPlaylistsData();
    if (num == 1 && this.checkedUserId.id == uid) {
      this.checkedUserId.id = null;
      this.checkedUserId.name = "";
    } else {
      this.checkedUserId.id = uid;
      this.checkedUserId.name = name;
      this.clientsampleservice
        .getChannelsByuser(uid)
        .subscribe((sites: any) => {
          if (this.entitySearchStr != "") {
            let searchString = this.entitySearchStr.toLowerCase();
            let filtered = [];
            // If search string is entity ID
            if (!isNaN(parseFloat(this.entitySearchStr))) {
              filtered = sites.filter(function search(row) {
                return Object.keys(row).some((key) => {
                  if (key == "entity_id" && typeof row[key] === "number") {
                    return row[key] === parseFloat(searchString);
                  } else if (row[key] && typeof row[key] === "object") {
                    return search(row[key]);
                  }
                  return false;
                });
              });
              // Filter channels exactly with entity ID
              for (let row of filtered) {
                const element = row.children;
                let filt = element.filter(
                  (x) => x.entity_id === parseFloat(searchString)
                );
                if (filt.length > 0) {
                  row.children = filt;
                }
              }
            } else {
              filtered = sites.filter(function search(row) {
                return Object.keys(row).some((key) => {
                  if (key == "name" && typeof row[key] === "string") {
                    return row[key].toLowerCase().indexOf(searchString) >= 0;
                  } else if (row[key] && typeof row[key] === "object") {
                    return search(row[key]);
                  }
                  return false;
                });
              });
            }
            this.sitesList = filtered;
          } else {
            this.sitesList = sites;
          }

          let site = this.sitesList.find((x) => x.id == this.checkedSiteId.id);
          if (this.checkedSiteId.id && this.checkedSiteId.name == "") {
            this.checkedSiteId.name = site.name;
          }

          if (this.checkedChanelId.id && this.checkedChanelId.name == "") {
            let channel = site.children.find(
              (x) => x.id == this.checkedChanelId.id
            );
            this.checkedChanelId.name = channel.name;
          }

          // console.log(sitesList.sort((a, b) => (a.name > b.name) ? 1 : -1));
          // for (let row of this.sitesList) {
          //   const element = row;
          //   let parent_entity = this.getParentEntityIDForSite(element.client_id);
          //   if (parent_entity) {
          //     element.parent_entity_id = parent_entity.entity_id;
          //     element.parent_entity_name = parent_entity.entity_name;
          //   }
          //   element.total_tracks = element.children.reduce(function (prev, cur) {
          //     return prev + cur.countDetails.clip_count;
          //   }, 0);
          // }
        });
    }
  }

  onSending(data): void {
    // data [ File , xhr, formData]
    const file = data[0];
    const formData = data[2];
    formData.append("cid", this.selectedClient);
  }

  getParentEntityIDForSite(client_id) {
    let user = this.usersListRaw.find((x) => x.id == client_id);
    if (user)
      return {
        entity_id: user.entity_id,
        entity_name: user.first_name,
      };
    return false;
  }

  /**
   Open confirmation popup
   * @param id - id of action
   * @param t - text for popup
   * @returns {boolean}
   */
  openConfirmPopup(id, t) {
    var text = "Do you want to delete this client?";
    if (t == 1) text = "Do you want to delete this site?";
    if (t == 2) text = "Do you want to delete this channel?";
    if (t == 3) text = "Do you want to delete this playlist?";
    if (t == 4) text = "Do you want to delete schedule?";
    if (t == 5)
      text = "Would you like to set this schedule for a indefinite date?";
    if (t == 6) text = "Do you want to delete Document?";
    if (t == 7) text = "Do you want to delete the profile statement?";
    if (t == 8) text = "Do you want to sync the changes to distribution group?";
    if (t == 9) text = "Do you want to hawk this site?";
    if (t == 10) text = "Do you want to mark this site as complete?";
    if (t == 11) text = "Do you want to cancel the hawk for this site?";

    this.confirmValues.id = id;
    this.confirmValues.type = t;
    this.confirm.confirmOpen(text);
    return false;
  }

  /**
   Click inside element
   * @param e - event
   *@param uid- selected client_id
   */
  clickedInside(e) {
    e.preventDefault();
    e.stopPropagation();
  }

  /**
   Confirm action in confirmation popup
   * @param e - event
   */
  isConfirm(e) {
    if (e) {
      if (this.confirmValues.type == 0) this.deleteUser(this.confirmValues.id);
      else if (this.confirmValues.type == 1)
        this.deleteSite(this.confirmValues.id, 1);
      else if (this.confirmValues.type == 2)
        this.deleteSite(this.confirmValues.id, 2);
      else if (this.confirmValues.type == 3)
        this.deletePlaylist(this.confirmValues.id);
      // else if (this.confirmValues.type == 4)
      //   this.deleteSchedule(this.confirmValues.id);
      // else if (this.confirmValues.type == 5) this.createSchedule();
      // else if (this.confirmValues.type == 6)
      //   this.deleteDocument(this.confirmValues.id);
      // else if (this.confirmValues.type == 7)
      //   this.deleteProfileRaw(this.confirmValues.id);
      // else if (this.confirmValues.type == 8)
      //   this.syncToDg(this.confirmValues.id);
      // else if (this.confirmValues.type == 9)
      //   this.reHawkSiteLevel(this.confirmValues.id);
      // else if (this.confirmValues.type == 10)
      //   this.forceHawk(this.confirmValues.id);
      // else if (this.confirmValues.type == 11)
      //   this.cancelHawk(this.confirmValues.id);
    }
  }

  /**
   Delete Client
   * @param uid - Client id
   */
  deleteUser(uid) {
    this.chanelsService.deleteClient(uid).subscribe((user) => {
      this.updateClientsList();
      this.checkedUserId.id = null;
      this.checkedSiteId.id = null;
      this.checkedChanelId.id = null;
      let currentUser = this.usersList.find((x) => x.id == uid);
      this.alert.alertOpen(
        "Client " + currentUser.company_name + " deleted successfully!",
        0
      );
    });
  }

  /**
   Delete site/channel
   * @param id - site/channel id
   * @param num - type of action 1-delete site, 2-delete channel
   */
  deleteSite(id, num) {
    this.chanelsService.deleteCategory(id).subscribe((siteDel) => {
      this.getSites(this.checkedUserId.id, "", 0);
      if (num == 1) this.alert.alertOpen("Site deleted", 0);
      else if (num == 2) this.alert.alertOpen("Channel deleted", 0);
    });
  }

  /**
   Delete playlist
   * @param id - playlist id
   */
  deletePlaylist(id) {
    this.clientsampleservice.deletePlaylist(id).subscribe((playlistDel) => {
      this.alert.alertOpen("Playlist deleted", 0);
      if (this.searchPopupOpened) {
        this.search(window.event);
      }
      this.updatePlaylists(0);
    });
  }

  /**
   Get playlists
   * @param num - type of action
   */
  updatePlaylists(num) {
    if (num == 1) {
      if (this.schedule.channelId != null) {
        // this.schedule.playlistId=null;
        this.playlistServ
          .getPlaylists(this.schedule.channelId)
          .subscribe((playlists: any) => {
            this.schedule.playlistList = playlists;
          });
      }
    } else {
      if (this.checkedSiteId.id != null) {
        this.clientsampleservice
          .getPlaylists(this.checkedSiteId.id)
          .subscribe((playlists) => {
            this.playlists = playlists;
          });
      }
    }
  }

  /**
   Show channels from selected site
   * @param e - event
   * @param sid - site id
   * @param name - site name
   * @param num - number of action type
   */
  showChanels(e, sid, name, num) {
    this.isShowActions.number = 2;
    this.checkedChanelId.id = null;
    this.clearPlaylistsData();
    this.playlistFields.site=sid;
    this.clearArchivePlaylistsData();
    if (num == 1 && this.checkedSiteId.id == sid) {
      this.checkedSiteId.id = null;
      this.checkedSiteId.name = "";
    } else {
      this.checkedSiteId.id = sid;
      this.checkedSiteId.name = name;
    }
    if (this.checkedUserId.id == null) {
      let site = this.entitySearchResult.find(
        (x) => x.id == this.checkedSiteId.id
      );
      let user = this.usersList.find((x) => x.id == site.client_id);
      this.checkedUserId.id = user.id;
      this.checkedUserId.name = user.first_name;
    }
    this.updatePlaylists(0);
    this.updateArchivePlayList();
  }

  openUserPopup(e, uid) {
    console.log("hello");
    return;
  }

  OpenClientPopup() {
    this.createclient = !this.createclient;
  }

  popupClose(e) {
    this.createclient = false;
    this.create_new_playlistpopUp = false;
    this.clientsearchStr = "";
    this.clientsList = [];
    this.popups.linkedopened=false;
    this.commentPopup=false;
    this.blurInput();
    return false;

  }

  clientPropertyPopOpen(uid) {
    this.showClientProperties = true;
    this.selectedClient = uid;
    this.checkedUserId.id = uid;
    let user = this.usersList.find((x) => x.id == this.checkedUserId.id);
    this.checkedUserId.name = user.name;
    this.getPropertyValuesById(uid);
    this.tabs.selected = true;
    this.checkedSiteId.name = null;
    this.PDFDropZone.directiveRef.reset();
    this.imageDropZone.directiveRef.reset();
    this.generated_link_value='';
  }
  /**
   *
   * @param e
   * @returns
   */

  clientsearch(e: any) {
    if (this.clientsearchStr != "") {
      if (this.clientsearchStr.length < 4) {
        this.alert.alertOpen("Please enter minimum 4 characters to search", 1);
        return false;
      }
      this.clientsampleservice
        .getclientlistbyname(this.clientsearchStr)
        .subscribe((clientname: Array<any>) => {
          if (clientname.length > 0) {
            this.clientsList = clientname;
            this.showchannelList = true;

            document.getElementById("list").style.display = "block";
          } else {
            this.alert.alertOpen("This Client is not our list", 1);
          }
        });
    } else {
      this.alert.alertOpen("Please enter minimum 4 characters to search", 1);
    }
  }

  clearClientSearchStr(e: any) {
    e.preventDefault();
    this.clientsearchStr = "";
    this.clientsList = [];
  }

  /**
 Show all clients from  endpoints data
 */

  getallcllient() {
    this.clientsampleservice.getAllClient().subscribe((clientslist: any) => {
      if (clientslist.length > 0) {
        this.clientsList = clientslist;
        console.log(this.clientsList);
      }
    });
  }

  /**
   *
   * @param e
   *
   */

  importclintdetailsWithChannels = "";
  importClient(e: any) {
    this.importclintdetailsWithChannels = "";
    if (!this.clientsearchStr) {
      this.alert.alertOpen("Please select client to import", 1);
    } else {
      this.clientsList.forEach((element) => {
        if (element.client_name == this.clientsearchStr) {
          this.importclintdetailsWithChannels = element.client_uid;
        }
      });
      this.clientsampleservice
        .importClientandChannels(
          this.importclintdetailsWithChannels,
          this.clientsearchStr
        )
        .subscribe((data: any) => {
          if (data.success) {
            this.alert.alertOpen(data.success, 0);
            this.createclient = false;
            this.clientsearchStr = "";
            this.updateClientsList();
          } else {
            this.alert.alertOpen(data.error, 1);
          }
        });
    }
  }

  /**
   *
   * @param e
   */
  onoptionselect(e: any) {
    this.clientsearchStr = e.target.value;
    document.getElementById("list").style.display = "none";
  }

  /**
   *
   * @param e
   *
   */

  setselectvalue(e: any) {
    let commenttext = this.comments.nativeElement.value;

    if (e.target.value == "null") {
      this.alert.alertOpen("Please select property type", 1);
    } else {
      this.clientsampleservice
        .storeAndUpdateClientProperty(
          e.target.value,
          this.selectedClient,
          this.current_user.id
        )
        .subscribe((data: any) => {
          console.log(data);
          if (data.msg != undefined) {
            this.alert.alertOpen(data.msg, 0);
          }
          this.getPropertyValuesById(this.selectedClient);
          this.propertycomments = commenttext;
        });
    }
  }

  propertyvalue: any;
  setpropertytype: "";

  getPropertyValuesById(cid) {
    this.propertycomments = "";

    this.clientsampleservice
      .getClientPropertydata(cid)
      .subscribe((data: any) => {
        if (data != null) {
          this.propertyvalue = data;
          this.setpropertytype = data.property_type;
          // localStorage.setItem('sample_cliet_id',
        } else {
          this.propertyvalue = null;
          this.setpropertytype = "";
          // localStorage.setItem('sample_cliet_id', cid);
        }
        // this.selectedClient=cid;
      });
  }

  fileName = ""; //uploaded file name
  fileData = [];

  imageUploadSelected(event: any) {
    const file = event.target.files[0];
    const file_type = event.target.files[0].type;
    this.fileData = event.target.files[0];
    const formData = new FormData();
    formData.append("file", file, file.name);

    console.log(formData.get("file"));
    if (file) {
      this.fileName = file.name;
      this.clientsampleservice
        .uploadImageandPDF(file)
        .subscribe((data: any) => {
          console.log(data);
        });
    }
  }

  PDFfileName = ""; //uploaded deck name

  onFileSelected(event: any) {
    const file: File = event.target.files[0];
    const file_type = event.target.files[0].type;
    if (file) {
      this.PDFfileName = file.name;
      this.clientsampleservice
        .uploadImageandPDF(file)
        .subscribe((data: any) => {
          console.log(data);
        });
    }
  }

  propertycomments = ""; //store value of comments
  property_comment_id = ""; // stoe comment id for update comments

  addComments(str: any) {
    if (this.playlistFields.id) {
      if (str == "") {
        this.alert.alertOpen(" Enter Comments !!", 1);
      } else {
        this.clientsampleservice
          .addcommentsonproperty(
            str,
            this.current_user.id,
            this.playlistFields.id
          )
          .subscribe((data: any) => {
            this.alert.alertOpen(data.msg, 0);
            this.getCommentsByPid(this.playlistFields.id);
            this.updatePlaylists(0);
            this.propertycomments = "";
          });
      }
    } else {
      this.alert.alertOpen("Please select property type ", 1);
    }
  }

  editComments(commentid: any, comstr: any) {
    this.property_comment_id = commentid;
    this.propertycomments = comstr;
  }

  editcommentsBYId(str) {
    if (this.playlistFields.id == null) {
      this.alert.alertOpen(" Please select property type !!", 1);
    }
    if (str == "") {
      this.alert.alertOpen(" Enter Comments !!", 1);
    } else {
      this.clientsampleservice
        .updatecommentsonproperty(
          str,
          this.current_user.id,
          this.playlistFields.id
        )
        .subscribe((data: any) => {
          this.alert.alertOpen(data.msg, 0);
          this.getCommentsByPid(this.playlistFields.id);
          this.property_comment_id = "";
          this.propertycomments = "";
        });
    }
  }

  deletecomment(comment_id) {
    this.clientsampleservice
      .deletecommentsonproperty(comment_id)
      .subscribe((data: any) => {
        this.alert.alertOpen(data.msg, 0);
        this.getCommentsByPid(this.playlistFields.id);
        this.property_comment_id = "";
        this.propertycomments = "";
        this.updatePlaylists(0);
      });
  }

  imagedownload(filename, event: any) {
    FileSaver.saveAs(this.localimagepath + filename, filename);
    event.preventDefault();
  }

  pDFdownload(filename, event: any) {
    FileSaver.saveAs(this.localdeckpath + filename, filename);
    event.preventDefault();
  }

  openPopUpforEdit(e: any, sid: any, sname: string) {
    this.selectedChannel.id = sid;
    this.selectedChannel.name = sname;
    this.channelEditPopUpOpen = true;
  }

  chaneleditPopUpClose(e: any) {
    this.selectedChannel.id = "";
    this.selectedChannel.name = "";
    this.channelEditPopUpOpen = false;
    return false;
  }

  chaneleditupdate(e) {
    this.clientsampleservice
      .editChannelNameById(this.selectedChannel.name, this.selectedChannel.id)
      .subscribe((data: any) => {
        if (data.error) {
          this.alert.alertOpen(data.error, 1);
        } else {
          this.alert.alertOpen(data.msg, 0);
          this.getSites(this.checkedUserId.id, this.checkedUserId.name, 1);
          this.channelEditPopUpOpen = false;
          this.selectedChannel.id = "";
          this.selectedChannel.name = "";
        }
      });
  }

  selectTab(tabId) {
    this.channeltabs.selected = tabId;
  }

  /**
     Actions when changed user in select
     * @param e - event
     * @param num - type of action in popup
     */
  userChange(e, num) {
    if (e.target.value == null) {
      if (num == 1) {
        this.chanelFields.sitesList = [];
      } else if (num == 2) {
        this.playlistFields.sitesList = [];
      }
    } else {
      this.updateSites(e.target.value, num);
    }
  }

  /**
 Get sites list
 * @param uid - user id
 * @param num - number of edit popup
 */
  updateSites(uid, num) {
    this.clientsampleservice.getChannelsByuser(uid).subscribe((sites: any) => {
      if (num == 1) {
        this.chanelFields.sitesList = sites || [];
      } else if (num == 2) {
        this.playlistFields.sitesList = sites || [];
      } else if (num == 3) {
        // this.schedule.siteId=null;
        this.schedule.sitesList = sites || [];
      }
    });
  }

  openPlaylistPopup(e: any, pid: any) {

    this.popupClose(e);

    this.playlistFields.id = pid;
    this.playlistFields.edited_by = this.current_user.id;
    // this.searchPopupOpened = false;
    if (this.playlistFields.id == null) {
      this.playlistFields.name = ''; // Clear previous name
      this.playlistFields.sitesList = [];
      
      if (this.checkedUserId.id != null) {
        this.playlistFields.user = this.checkedUserId.id;
        this.updateSites(this.playlistFields.user, 2);
      } else {
        this.playlistFields.user = null;
      }
    } else {
      this.clientsampleservice.getPlaylistById(pid).subscribe((playlist: any) => {
        this.playlistFields.id = playlist.id;
        this.playlistFields.user = playlist.sample_client_id;
        this.updateSites(playlist.sample_client_id, 2);
        this.playlistFields.site = playlist.sample_channel_id;
        this.playlistFields.name = playlist.name;
      });
    }
    this.focusInput();
    this.create_new_playlistpopUp = !this.create_new_playlistpopUp;
    return false;
  }

  /**
 Update playlist information
 * @param e - event
 * @param {NgForm} form - form
 * @param 0 is for simaple save 1 is used for save and trasfer to manage page
 * @returns {boolean}
 */
  playlistUpdate(e, form: NgForm, savemange = null) {
    if (!this.playlistFields.name || this.playlistFields.name.trim() === '') {
      this.alert.alertOpen("Please enter a Playlist Name", 1);
      return false;
    }

    if (this.playlistFields.site == null) {
      this.alert.alertOpen("Please select Channel", 1);
      return false;
    }

    var str = Object.keys(form.value)
      .map(function (key) {
        var val = encodeURIComponent(form.value[key]);
        if (val == "null" || val == null) {
          val = "";
        }
        return encodeURIComponent(key) + "=" + val;
      })
      .join("&");
    if (
      // this.playlistFields.chanel != null &&
      this.playlistFields.user != null
    ) {
      if (this.playlistFields.id == null) {
        this.clientsampleservice
          .createPlaylist(str)
          .subscribe((playlists: any) => {
            if (playlists.error == undefined) {
              this.alert.alertOpen("Playlist added", 0);
              this.updatePlaylists(0);
              if (savemange == 1) {
                this.manageClick(playlists.id);
              }
            } else {
              this.alert.alertOpen(playlists.error, 1);
            }
          });
      } else {
        this.clientsampleservice.updatePlaylist(this.playlistFields.id, str).subscribe((playlists: any) => {
          if (playlists.error == undefined) {
            this.alert.alertOpen("Playlist updated", 0);
            this.updatePlaylists(0);
            this.saveandManageplaylist = playlists;
            if (savemange == 1) {
              this.manageClick(playlists.id);
            }
          } else {
            this.alert.alertOpen(playlists.error, 1);
          }
        });
      }
    } else {
      this.alert.alertOpen("Please select Client", 1);
    }
    this.create_new_playlistpopUp = false;
  }

  /**
    Set status for playlist
    * @param e - event
    * @param pid - playlist id
    * @param sid - site id
    */


  statusChange(e, pid) {
    e.preventDefault();
    if (this.changingStatus) {
      this.alert.alertOpen(
        "Please wait! The previous status change request is in progress.",
        1
      );
      return false;
    }

    this.isLoad = true;
    this.changingStatus = true;
    this.clientsampleservice.setStatus(pid, e.target.value).subscribe((status) => {
      this.updatePlaylists(0);
      this.updateArchivePlayList();
      this.alert.alertOpen(
        "Status successfully changed to " +
        this.playlistStatusText[e.target.value] +
        "!",
        0
      );
      this.changingStatus = false;
      this.isLoad = false;
    });
  }

  updateArchivePlayList() {
    if (this.checkedSiteId.id != null) {
      this.clientsampleservice
        .getArchivePlaylists(this.checkedSiteId.id)
        .subscribe((playlists) => {
          this.archivePlaylists = playlists;
        });
    }

  }


  setOrderTC(order) {
    if (this.orderBy == order) {
      if (this.order == 'DESC')
        this.order = 'ASC';
      else
        this.order = 'DESC';
    }
    else {
      this.orderBy = order;
    }
    if (this.order == "DESC") {
      this.playlists.sort((a, b) => (a.order > b.order ? 1 : -1))
    } else {
      this.playlists.sort((a, b) => (a.order > b.order ? -1 : 1))
    }
  }

  /**
Cliek link to edit sample client playlist
* @param id - playlist id
*/
  manageClick(id) {
    let navigationExtras: NavigationExtras = {
      queryParams: {
        playlistID: id,
        pcat: this.checkedSiteId.id,
        uid: this.checkedUserId.id,
      },
    };
    this.router.navigate(["manage-sample-client-playlists"], navigationExtras);
  }

  generated_link_value='';

  generate_link(e,playlist_id){
    this.generated_link_value = '';
    if(!playlist_id){
      this.alert.alertOpen("There is no playlist in this Client",1);
    }else{
    this.clientsampleservice
    .createLink(playlist_id)
    .subscribe((response:any) => {
      this.generated_link_value=response.token;
    });
    this.popups.linkedopened=true;
  }
    return false;
  }

  copyLink(){
    // this.clipboard.copy(this.generated_link_value);
    this.alert.alertOpen("Link copied to clipboard:"  + this.generated_link_value, 0);
    return false;
  }

  previewPage(playlist_id){
    if(!playlist_id){
      this.alert.alertOpen("There is no playlist in this Client",1);
    }
    else{
      this.clientsampleservice
        .createLink(playlist_id)
        .subscribe((response:any) => {
          this.generated_link_value=response.token;
          if (this.generated_link_value) {
            const newWindow = window.open(this.generated_link_value, '_blank', 'resizable=1');
            if (!newWindow) {
              this.alert.alertOpen("Failed to open the new window", 1);
            }
          }
      });
    }
  }

  commentPopup=false;
  commentsFileds=null;

  playlistcommentsPopup(e,profileId){
    e.preventDefault();
    this.propertycomments='';
    this.commentsFileds=null;
    this.playlistFields.id=profileId;
    this.getCommentsByPid(profileId);
    this.commentPopup=!this.commentPopup;
    return false;
  }

  getCommentsByPid(profileId){
    this.clientsampleservice.getCommentsByPid(profileId).subscribe((comments:any)=>{
        this.commentsFileds=comments;
    })

  }

  cancelcomment(){

    this.propertycomments='';
  }


}
