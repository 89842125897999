import { Component, OnInit, ViewChild } from "@angular/core";
import { CookieService } from "ngx-cookie-service";
import { ActivatedRoute, Router } from "@angular/router";
import { UserService } from "../services/user.service";
import { NgForm } from "@angular/forms";
import { AlertComponent } from "../alert/alert.component";

@Component({
  selector: "app-reset-password",
  templateUrl: "./reset-password.component.html",
  styleUrls: ["./reset-password.component.scss"]
})
export class ResetPasswordComponent implements OnInit {
  userId = null;//User id
  hash = null;//Password hash
  message = "";//Message
  isForm = 0;//Open reset password form
  newPassword = "";//Password field
  repeatPassword = "";//Password confirmation
  @ViewChild(AlertComponent, { static: true }) alert: AlertComponent;//Component of alert popup
  constructor(
    private cookieService: CookieService,
    private route: ActivatedRoute,
    private userService: UserService,
    private router: Router
  ) {}

  ngOnInit() {
    this.cookieService.delete("access");
    this.cookieService.delete("enable");
    this.route.queryParams.subscribe(params => {
      this.hash = params["reset"];
      this.userId = params["uid"];
      this.userService
        .checkResetPassword(this.userId, this.hash)
        .subscribe((message: any) => {
          if (message.error != undefined) {
            this.message = message.error;
            this.isForm = 2;
          }
          if (message.success != undefined) {
            this.isForm = 1;
          }
        });
    });
  }

  /**
   Send data for reset password
   * @param e - event
   * @param {NgForm} form - form
   */
  resetDataSend(e, form: NgForm) {
    e.preventDefault();
    var str = Object.keys(form.value)
      .map(function(key) {
        return (
          encodeURIComponent(key) + "=" + encodeURIComponent(form.value[key])
        );
      })
      .join("&");
    if (
      this.newPassword == this.repeatPassword &&
      this.validatePassword(this.newPassword) == ""
    ) {
      this.userService.resetPassword(str).subscribe((message: any) => {
        console.log('msg',message);
        //return;
        if (message.error != undefined) {
          this.alert.alertOpen(message.error, 1);
        }
        if (message.success != undefined) {
          this.alert.alertOpen("Password has been reset successfully", 0);

          setTimeout(()=>{ this.router.navigate(["/login"]); }, 4000);
        }
      });
    } else if (this.validatePassword(this.newPassword) != "") {
      this.alert.alertOpen(
        "Password must be" + this.validatePassword(this.newPassword),
        1
      );
    } else {
      this.alert.alertOpen("Passwords don't match", 1);
    }
  }

  /**
   Password validate
   * @param str - password string
   * @returns {string}
   */
  validatePassword(str) {
    var s = "";
    var least = false;
  
    var spec = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
    if (str.length < 8 || str.length > 16) {
      s = " 8-16 characters small";
    }
    if (
      !str.match(/[A-z]/) ||
      !str.match(/[A-Z]/) ||
      !str.match(/[0-9]/) ||
      !spec.test(str)
    ) {
      if (s != "") {
       
        s += ";";
      }
      s += " at least ";
    }
    if (!str.match(/[A-Z]/)) {
      s += "one uppercase";
      least = true;
    }
    if (!str.match(/[A-z]/)) {
      if (least) {
        s += ", ";
      }
      s += "one lowercase";
      least = true;
    }
    if (!str.match(/[0-9]/)) {
      if (least) {
        s += ", ";
      }
      s += "one number";
      least = true;
    }
    if (!spec.test(str)) {
      if (least) {
        s += ", ";
      }
      s += "one special";
      least = true;
    }
    if (least) {
      s += " character.";
    }
    return s;
  }
}
