import {Component, OnInit, ViewChild} from '@angular/core';
import {UserService} from '../services/user.service';
import {DashboardService} from '../services/dashboard.service';
import {CookieService} from 'ngx-cookie-service';
import {NavigationExtras, Router} from '@angular/router';
import {AlertComponent} from '../alert/alert.component';
import { CPPlaylistsService } from '../services/cp_playlists.service';
import {NgForm} from '@angular/forms';
import { IMyDpOptions, IMyDateModel, IMyDate } from 'mydatepicker';
import { ConditionalExpr } from '@angular/compiler';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  providers: [CPPlaylistsService,UserService, DashboardService]
})
export class DashboardComponent implements OnInit {
  inprogressPlaylists = [];//Array of playlists which editing
  foundationPlaylists = [];//Array of foundation playlists
  playlist_being_edited = 0;//Count of playlists edited
  playlist_deployed_today = 0;//Count of playlists deployed
  playlist_edited_today = 0;//Count of playlists edited today
  periodPlaylists = [];//Array of playlists by period
  myCustomers = [];//Array of customers
  loadedBlocks:any = [];//Dashboard blocks
  selectedBlock:any = null;//Id of selected block
  userInfo:any = null;// information of logged user
  isCollapsed = [];//Collapsed blocks
  draggable = {
    data: null,
    effectAllowed: "all",
    disable: false,
    handle: false
  };//params for draggable module
  popupOpened = false;//Open popup with full tables
  openedId = 1;//Opened popup id
  popupTitle = '';//Opened popup title

  googleChartType = "LineChart";
  chartDataCP = [];
  chartDataFP = [];

  ChartColumnNames = ['weekdays', 'updates'];
  ChartTitleCP = "Clients Playlist Updates in Last 30 days";
  ChartTitleFP = "Foundation Playlists Updates in Last 30 days";
  ChartHeight = 180;
  ChartWidth = 400;
  chartOptionsCP = {
    colors: ['#ff0000'],
    legend: {position: 'none'}
  };
  chartOptionsFP = {
    colors: ['#0000ff'],
    legend: {position: 'none'}
  };
  @ViewChild(AlertComponent) alert: AlertComponent;//Component of alert popup
  public failed_hawks:any = [];
  isLoadHawk:boolean = false;
  isCancelHawk:boolean = false;
  public logdatedata:any=[];
  p: number = 1;
  pD:number=1;
  pFB: number = 1;
  public listData:any;
  selectAllToHawk = false;
  public selectedHawks:any = [];
  public hawk_type = 'failed';
  public status="both";
  public order = 'DESC';
  public orderBy = 'created_at';
  public failed_builds:any = [];
  public listDataFB:any;
  public orderFB = 'DESC';
  public orderByFB = 'created_at';
  public myDatePickerOptions: IMyDpOptions = {
    dateFormat: 'dd.mm.yyyy',
    showTodayBtn: true,
    todayBtnTxt: 'Today',
   
  };
  constructor(
    private userService: UserService,
    private dashboardService: DashboardService,
    private cookieService: CookieService,
    private router: Router,
    private playlistServ: CPPlaylistsService
  ) { }

  ngOnInit() {
    this.userService.getCurrentUserInfo().subscribe((user:any) => {
      this.userInfo = user;
      // console.log('user',user);
      for(var i = 1; i <= 4;i++){
        if(this.cookieService.get('dash-' + i) != null && this.cookieService.get('dash-' + i) != undefined && this.cookieService.get('dash-' + i) != ''){
          this.isCollapsed.push(i);
        }
      }
      this.dashboardService.getOrderedBlocks().subscribe((blocks:any) => {
       
        if(blocks.error==undefined){
          this.loadedBlocks = blocks;
          this.getPlaylists(this.userInfo.id);
        }
        else{
          this.alert.alertOpen(blocks.error,1);
        }
      });
    })

    this.getFailedHawks();
    this.getFailedBuilds();
  }

  setOrder(order){
    if(this.orderBy == order){
      if(this.order == 'DESC')
        this.order = 'ASC';
      else
        this.order = 'DESC';
    }
    else{
      this.orderBy = order;
    }
    if(this.hawk_type == 'failed'){
      this.getFailedHawks();
    }
    if(this.hawk_type == 'pending'){
      this.getPendingHawks();
    }
    if(this.hawk_type == 'canceled'){
      this.getCanceledHawks();
    }
  }

  changeHawkType(value){
    if(value == 'failed'){
      this.getFailedHawks();
    }
    if(value == 'pending'){
      this.getPendingHawks();
    }
    if(value == 'canceled'){
      this.getCanceledHawks();
    }
  }

  setOrderFB(orderFB){
    if(this.orderByFB == orderFB){
      if(this.orderFB == 'DESC')
        this.orderFB = 'ASC';
      else
        this.orderFB = 'DESC';
    }
    else{
      this.orderByFB = orderFB;
    }
    this.getFailedBuilds();
  }

  getFailedBuilds(){
    this.failed_builds = [];
    this.listDataFB = [];
    this.dashboardService.getFailedBuilds(this.orderFB,this.orderByFB,this.pFB,20).subscribe((res:any) => {
      if(res['statusCode'] == 200 && res['result'].length > 0){
        this.failed_builds = res['result'];
        this.listDataFB = res['result'];
        // console.log('failed_builds',this.failed_builds);
      }
    });
  }

  ConvertToInt(val){
    return parseInt(val) || 0;
  }

  getFailedHawks(){
    this.selectedHawks = [];
    this.failed_hawks = [];
    this.listData = [];
    this.dashboardService.getFailedHawks('failed',this.order,this.orderBy,this.p,20).subscribe((res:any) => {
      if(res['statusCode'] == 200 && res['result'].length > 0){
        this.failed_hawks = res['result'];
        this.listData = res['result'];
        for (let index = 0; index < this.listData.length; index++) {
          const element = this.listData[index];
          if(element.called_in){
            let date1 = new Date(element.called_in).getTime();
            let date2 = new Date().getTime();
            let time = date2 - date1;  //msec
            let hoursDiff = time / (3600 * 1000);
            if(hoursDiff <= 24){
              this.listData[index].called_in_bg = '#2ed52e';
            }
            if(hoursDiff > 24 && hoursDiff <= 72){
              this.listData[index].called_in_bg = '#FFBF00';
            }
            if(hoursDiff > 72){
              this.listData[index].called_in_bg = '#ec6767';
            }
          }
        }
        
      }
    });
  }

  getPendingHawks(){
    this.selectedHawks = [];
    this.failed_hawks = [];
    this.listData = [];
    this.dashboardService.getFailedHawks('pending',this.order,this.orderBy,this.p,20).subscribe((res:any) => {
      if(res['statusCode'] == 200 && res['result'].length > 0){
        this.failed_hawks = res['result'];
        this.listData = res['result'];
        for (let index = 0; index < this.listData.length; index++) {
          const element = this.listData[index];
          if(element.called_in){
            let date1 = new Date(element.called_in).getTime();
            let date2 = new Date().getTime();
            let time = date2 - date1;  //msec
            let hoursDiff = time / (3600 * 1000);
            if(hoursDiff <= 24){
              this.listData[index].called_in_bg = '#2ed52e';
            }
            if(hoursDiff > 24 && hoursDiff <= 72){
              this.listData[index].called_in_bg = '#FFBF00';
            }
            if(hoursDiff > 72){
              this.listData[index].called_in_bg = '#ec6767';
            }
          }
        }
       
      }
    });
  }

  getCanceledHawks(){
    this.selectedHawks = [];
    this.failed_hawks = [];
    this.listData = [];
    this.dashboardService.getFailedHawks('canceled',this.order,this.orderBy,this.p,20).subscribe((res:any) => {
      if(res['statusCode'] == 200 && res['result'].length > 0){
        this.failed_hawks = res['result'];
        this.listData = res['result'];
        for (let index = 0; index < this.listData.length; index++) {
          const element = this.listData[index];
          if(element.called_in){
            let date1 = new Date(element.called_in).getTime();
            let date2 = new Date().getTime();
            let time = date2 - date1;  //msec
            let hoursDiff = time / (3600 * 1000);
            if(hoursDiff <= 24){
              this.listData[index].called_in_bg = '#2ed52e';
            }
            if(hoursDiff > 24 && hoursDiff <= 72){
              this.listData[index].called_in_bg = '#FFBF00';
            }
            if(hoursDiff > 72){
              this.listData[index].called_in_bg = '#ec6767';
            }
          }
        }
        
      }
    });
  }

  onSelectHawk(currentIndex,isChecked){
    let index = this.selectedHawks.findIndex(x => x == currentIndex);
    if (isChecked && index == -1) {
      this.selectedHawks.push(currentIndex);
    } else {
      this.selectedHawks.splice(index, 1);
    }
  }

  checkSelected(currentIndex){
    let index = this.selectedHawks.findIndex(x => x == currentIndex);
    if(index >= 0){
      return true;
    }
    return false;
  }

  changeAllToHawk(){
    for( var i = 0; i< this.failed_hawks.length; i++ ){
      let index = this.selectedHawks.findIndex(x => x == i);
      if (this.selectAllToHawk && index == -1) {
        this.selectedHawks.push(i);
      }
      if(!this.selectAllToHawk){
        this.selectedHawks.splice(index, 1);
      }
    }
  } 

  checkSelectedHawk(){
    if(this.selectedHawks.length > 0){
      return true;
    }
    return false;
  }

  refreshHawkList(){
    if(this.hawk_type == 'failed'){
      this.getFailedHawks();
    }
    if(this.hawk_type == 'pending'){
      this.getPendingHawks();
    }
    if(this.hawk_type == 'canceled'){
      this.getCanceledHawks();
    }
  }

  multiHawk(){
    for( var i = 0; i< this.selectedHawks.length; i++ ){
      let element = this.failed_hawks[this.selectedHawks[i]];
      
      this.isLoadHawk = true;
      this.playlistServ.hawk(element.site_id,element.channel_id,element.buildid).subscribe(res => {
      //this.playlistServ.hawkSiteLevel(element.site_id).subscribe(res => {
        this.isLoadHawk = false;
        
        if (res["statusCode"] == 200 && res["result"].length > 0) {
          this.refreshHawkList();
          this.alert.alertOpen(res["result"], 0);
        } else {
          this.alert.alertOpen(res["error"], 1);
        }
        // for (var key in res) {
        //   if (res.hasOwnProperty(key)) {
        //     if(res[key].error){
        //       this.alert.alertOpen('<span> Hawk error : <br> Error: '+res[key].error+' </span><br><br>',1,15);
        //     }
        //     else{
        //       this.alert.alertOpen('<span> Hawk created: <br> Result: '+res[key].result+' </span><br><br>',0,15);
        //     }
        //   }
        // }
        this.selectedHawks.splice(i, 1);
      });
    }
  }

  multiForceHawk(){
    for( var i = 0; i< this.selectedHawks.length; i++ ){
      let element = this.failed_hawks[this.selectedHawks[i]];
      
      this.isLoadHawk = true;
      // element.buildid,element.serial_number,element.hawk_id
      this.playlistServ.forceHawk(element.site_id).subscribe(res => {
        this.isLoadHawk = false;
        
        if(res['statusCode'] == 200 && res['result'].length > 0){
          this.refreshHawkList();
          this.alert.alertOpen(res['result'],0)
        }
        else{
          this.alert.alertOpen(res['error'],1)
        }
        this.selectedHawks.splice(i, 1);
      });
    }
  }

  multiCancelHawk(){
    for( var i = 0; i< this.selectedHawks.length; i++ ){
      let element = this.failed_hawks[this.selectedHawks[i]];
      
      this.isCancelHawk = true;
      // element.buildid,element.serial_number,element.hawk_id
      this.playlistServ.cancelHawk(element.site_id).subscribe(res => {
        this.isCancelHawk = false;
       
        if(res['statusCode'] == 200 && res['result'].length > 0){
          this.refreshHawkList();
          this.alert.alertOpen(res['result'],0)
        }
        else{
          this.alert.alertOpen(res['error'],1)
        }
        this.selectedHawks.splice(i, 1);
      });
    }
  }

  /**
   Get playlists for dashboard
   * @param uid - logged user id
   */
  getPlaylists(uid){
    var date:any = new Date();
   
    var wdate:any = date - (1000 * 60 * 60 * 24 * 7);
   
    var endDate = this.formatDate(new Date(wdate));
    var beginDate = this.formatDate(date);
    this.dashboardService.getInProgressPlaylists(uid).subscribe((playlists:any) => {
      this.inprogressPlaylists = playlists;
    });
    this.dashboardService.getFoundationPlaylists().subscribe((foundation_playlists:any) => {
      if(foundation_playlists.result==undefined)
        this.foundationPlaylists = foundation_playlists;
      else
        this.foundationPlaylists = [];
    });
    this.dashboardService.getPlaylistsEdited().subscribe((edited_playlists:any)=>{
      this.playlist_being_edited = edited_playlists.CP_in_editing_status + edited_playlists.FP_in_editing_status;
    });
    this.dashboardService.getPlaylistsDeployedToday().subscribe((deployed_today:any)=>{
      this.playlist_deployed_today = deployed_today.fp_today_deployed + deployed_today.cp_today_deployed;
    });
    this.dashboardService.getPlaylistsEditedToday().subscribe((edited_today:any)=>{
      this.playlist_edited_today = edited_today.fp_today_edited + edited_today.cp_today_edited;
    });
    this.dashboardService.getDataForRecentUpdatesGraph().subscribe((recent_updates_data:any)=>{
      
      this.chartDataCP = recent_updates_data.cp;
      this.chartDataFP = recent_updates_data.fp;
      
    });
    this.dashboardService.getUpdatesByPerion( endDate, beginDate ).subscribe((period_playlists:any) => {
      if(period_playlists.error==undefined && period_playlists.result != 'No result')
        this.periodPlaylists = period_playlists;
      else
        this.periodPlaylists = [];
    })
    this.dashboardService.getCustomers().subscribe((customers:any)=>{
      console.log(customers);
      this.myCustomers = customers;
    });
  }

  /**
   Parse date for query
   * @param date - date string
   * @returns {string}
   */
  formatDate(date){
    var str = '';
    var d = date.getDate();
    var m = date.getMonth();
    var y = date.getFullYear();

    if(d < 10){
      str = '0' + d.toString();
    }
    else{
      str = d.toString();
    }
    if((m + 1) < 10){
      str += '-0' + (m + 1).toString();
    }
    else{
      str += '-' + (m + 1).toString();
    }
    str += '-' + y;
    return str;
  }

  /**
   Start moving blocks
   * @param {DragEvent} event - event
   * @param pos - block position
   */
  moveBlock(event:DragEvent, pos){
    this.draggable.data = pos;
    this.selectedBlock = this.loadedBlocks[pos - 1];
    
  }

  /**
   End moving block
   * @param {DragEvent} event - event
   * @param pos - block position
   */
  endMove(event:DragEvent, pos){
    this.loadedBlocks[this.draggable.data - 1] = this.selectedBlock;
  }
  

  /**
   Send updated block position
   * @param {DragEvent} event - event
   * @param pos - block position
   */
  setBlockPosition(event:DragEvent, pos){
    this.loadedBlocks[ this.draggable.data - 1 ] = this.loadedBlocks[ pos - 1 ];
    this.loadedBlocks[ pos - 1 ] = this.selectedBlock;
    this.dashboardService.setBlockPosition( this.loadedBlocks[ pos - 1 ].id , pos ).subscribe();
  }

  /**
   Checking collapsed blocks
   * @param id - block id
   * @returns {boolean}
   */
  checkCollapsed(id){
    for(var i = 0; i < this.isCollapsed.length; i++){
     if(this.isCollapsed[i] == id){
       return true;
     }
    }
    return false;
  }

  /**
   Collapse block
   * @param id - block id
   * @returns {boolean}
   */
  collapseBlock(id){
    if(this.checkCollapsed(id)){
      for(var i=0;i<this.isCollapsed.length;i++){
        if(this.isCollapsed[i] == id){
          this.isCollapsed.splice(i,1);
          this.cookieService.delete('dash-'+id);
        }
      }
    }
    else{
      this.isCollapsed.push(id);
      this.cookieService.set('dash-'+id, '1');
    }
    return false
  }

  /**
   Cliek link to edit playlist
   * @param id - playlist id
   * @param type - playlist type
   * @returns {boolean}
   */
  manageClick( id, type ){
    if(type == 'cp'){
      let navigationExtras: NavigationExtras={
        queryParams:{
          'playlistID': id,
          'pcat': 2,
          'uid': this.userInfo.id
        }
      };
      this.router.navigate(['manage-client-playlists'], navigationExtras);
    }
    else if(type == 'fp'){
      let navigationExtras: NavigationExtras={
        queryParams:{
          'playlistID': id
        }
      };
      this.router.navigate(['manage-playlists'], navigationExtras);
    }
    return false;
  }

  /**
   Open popup with full tables
   * @param id - table id
   * @param name - Popup title
   */
  openPopup(id,name){
    this.openedId = id;
    this.popupTitle = name;
    this.popupOpened = true;
  }
  /**
   Close all popups
   * @returns {boolean}
   */
  popupClose(){
    this.popupOpened = false;
  }

  syncOdbapi(type){
    this.dashboardService.syncOdbapi(type).subscribe((res:any)=>{
      console.log('tes',res);
    });
    this.alert.alertOpen("Sync successfully initiated!",0)
  }

  getHawkStatusValue(hawk_status){
    let st = '';
    switch (hawk_status) {
      case 0:
        st = 'In progress';
        break;
      case 1:
        st = 'Success';
        break;
      case -1:
        st = 'Failed';
        break;
      default:
        st = '';
        break;
    }
    return st;
  }

  reHawk(site_id,channel_id,build_id){
    // channel_id, build_id
    this.isLoadHawk = true;
    this.playlistServ.hawk(site_id,channel_id,build_id).subscribe(res => {
    //this.playlistServ.hawkSiteLevel(site_id).subscribe(res => {
      this.isLoadHawk = false;
      if(res['statusCode'] == 200 && res['result'].length > 0){
        
        this.alert.alertOpen(res['result'],0);
        this.refreshHawkList();
      }
      else{
        this.alert.alertOpen(res['error'],1)
      }

    });
  }

  forceHawk(site_id){
    // build_id,serial_number,hawk_id
    this.isLoadHawk = true;
    // build_id,serial_number,hawk_id
    this.playlistServ.forceHawk(site_id).subscribe(res => {
      this.isLoadHawk = false;
      console.log('ress',res);
      if(res['statusCode'] == 200 && res['result'].length > 0){
        this.refreshHawkList();
        this.alert.alertOpen(res['result'],0)
      }
      else{
        this.alert.alertOpen(res['error'],1)
      }
    });
  }

  cancelHawk(site_id){
    this.isCancelHawk = true;
    this.playlistServ.cancelHawk(site_id).subscribe(res => {
      this.isCancelHawk = false;
      if(res['statusCode'] == 200 && res['result'].length > 0){
        this.refreshHawkList();
        this.alert.alertOpen(res['result'],0)
      }
      else{
        this.alert.alertOpen(res['error'],1)
      }
    });
  }

  applyFilter(filterValue: string){
    let columns = ['channel_name','channel_entity_id','site_name','site_entity_id','serial_number'];
   
    this.failed_hawks = this.failed_hawks.filter(
      row => {
        for (var key in row) {
       
          if (row.hasOwnProperty(key)) {
                
              if(columns.indexOf(key) != -1 && row[key] && row[key].toString().toLowerCase().includes(filterValue.toLowerCase())){
                return true;
              }
              
          }
        }
      }
    );
    
  }
  
  datelog(start_date:any,end_date:any,value:any){  
  	this.logdatedata=[];
  	if(start_date == '' || end_date == ''){
  		 this.alert.alertOpen('Please fill date ',1)
  	}else{
  		this.dashboardService.getlogdata(start_date,end_date,value).subscribe((date:any) => {
        
       	this.logdatedata=date;	
      
      });
  	}
  }
  
  applyFilterOnLog(filterValue: any){
   
    let columns =['channel_name','playlist_name','tracks_count','duplicate_count'];
    for(var i=0; i< this.logdatedata.length;i++){
     
      this.logdatedata[i].channel_name=this.logdatedata[i].channel.name;
      this.logdatedata[i].playlist_name=this.logdatedata[i].playlist.name;
    }
    this.logdatedata = this.logdatedata.filter(
      row => {
        for (var key in row) {
         
          if (row.hasOwnProperty(key)) {

              if(columns.indexOf(key) != -1 && row[key] && row[key].toString().toLowerCase().includes(filterValue.toLowerCase())){
                return true;
              }
              
          }
        }
      }
    );
  

  }

  Channel_search(event: any){
  	this.logdatedata=[];
	this.dashboardService.getlogchanneldata((event.target.value).toLowerCase()).subscribe((date:any) => {
       	this.logdatedata=date;	
      });
  }
  
   Playlist_search(event: any){
  	this.logdatedata=[];
	this.dashboardService.getlogplaylistdata((event.target.value).toLowerCase()).subscribe((date:any) => {
       	this.logdatedata=date;	
      });
  }
  
  setOrderAL(order){
  if(this.orderBy == order){
      if(this.order == 'DESC')
        this.order = 'ASC';
      else
        this.order = 'DESC';
    }
    else{
      this.orderBy = order;
    }
	if(this.order=="DESC"){
	this.logdatedata.sort(function(a, b) {
	  var keyA = new Date(a.created_at),
	    keyB = new Date(b.created_at);
	  // Compare the 2 dates	
	  if (keyA < keyB) return ;
	  if (keyA > keyB) return -1;
	  return 0;
	});
	}else{
	this.logdatedata.sort(function(a, b) {
	  var keyA = new Date(a.created_at),
	    keyB = new Date(b.created_at);
	  // Compare the 2 dates	
	  if (keyA < keyB) return -1;
	  if (keyA > keyB) return ;
	  return 0;
	});
	}
  }
  
  setOrderTC(order){
  if(this.orderBy == order){
      if(this.order == 'DESC')
        this.order = 'ASC';
      else
        this.order = 'DESC';
    }
    else{
      this.orderBy = order;
    }
    if(this.order=="DESC"){
    this.logdatedata.sort((a,b)=> (a.order > b.order ? 1 : -1))
    }else{
    this.logdatedata.sort((a,b)=> (a.order > b.order ? -1 : 1))
    }
   
	
  }
  
  // statuschange(start_date:any,end_date:any,value:any){
   
  //     this.datelog(start_date,end_date,value);
    
  // }
  
}
