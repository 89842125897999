<app-page-header [pageTitle]="'Create, edit and manage your playlists'"></app-page-header>
<div class="dash_top box">
  <div class="left_info">
    <p>Items Awaiting Attention: 10</p>
    <p>Playlists Edited Today: {{playlist_edited_today}}</p>
    <p>Playlists Deployed Today: {{playlist_deployed_today}}</p>
    <p>Playlists Being Edited: {{playlist_being_edited}}</p>
  </div>
  <div class="graphic_images" style="margin-right: 250px">
    <google-chart [type]="googleChartType" [options]="chartOptionsCP" [data]="chartDataCP" [title]="ChartTitleCP" [width]="ChartWidth" [height]="ChartHeight"></google-chart>
  </div>
  <div class="graphic_images">
    <google-chart [type]="googleChartType" [options]="chartOptionsFP" [data]="chartDataFP" [title]="ChartTitleFP" [width]="ChartWidth" [height]="ChartHeight"></google-chart>
  </div>
</div>

<div class="dash_top box" *ngIf="userInfo && (userInfo.type == 'Administrator' || userInfo.type == 'Consultant')" style="margin-top: 40px;">
  <div class="box_move_inner">
    <div class="title_box">
      <div class="title" style="font-family: Verdana,sans-serif;font-size: 15px;line-height: 18px;color:#4c4c4c;text-transform: uppercase;margin: 20px;">
        <select name="hawk_title" [(ngModel)]="hawk_type" (change)="changeHawkType($event.target.value)">
          <option value="failed" [selected]="hawk_type=='failed'">Failed Hawks</option>
          <option value="pending" [selected]="hawk_type=='pending'">In progress Hawks</option>
          <option value="canceled" [selected]="hawk_type=='canceled'">Canceled Hawks</option>
        </select>
        <button *ngIf="failed_hawks.length > 0" class="btn" (click)="multiCancelHawk()" style="float: right; margin-left:5px;margin-right: 35px;" [disabled]="isCancelHawk || hawk_type=='canceled'">Cancel hawk</button>
        <button *ngIf="failed_hawks.length > 0" class="btn" (click)="multiForceHawk()" style="float: right; margin-left:5px;" [disabled]="isLoadHawk">Mark as complete</button>
        <button *ngIf="failed_hawks.length > 0"class="btn" (click)="multiHawk()" [ngClass]="{enabled: checkSelectedHawk()}" style="float: right;" [disabled]="isLoadHawk">Hawk</button>

      </div>

    </div>
    <div class="box_inner">
      <div class="table_wrap dashboard_table">
        <div class="table_titles">
          <div style="width: 2%;">
              <input type="checkbox" [(ngModel)]="selectAllToHawk" name="selectAllToHawk"
              (change)="changeAllToHawk()" [disabled]="failed_hawks.length == 0" />
          </div>
          <div class="order" style="width: 15%;" (click)="setOrder('channel_name')" [ngClass]="{active: orderBy=='channel_name',asc:order=='ASC'}">Channel</div>
          <div class="order" style="width: 15%;" (click)="setOrder('site_name')" [ngClass]="{active: orderBy=='site_name',asc:order=='ASC'}">Parent</div>
          <div class="order" style="width: 8%;" (click)="setOrder('serial_number')" [ngClass]="{active: orderBy=='serial_number',asc:order=='ASC'}">Serial Number</div>
          <div class="order" style="width: 9%;" (click)="setOrder('hawk_status_updated_at')" [ngClass]="{active: orderBy=='hawk_status_updated_at',asc:order=='ASC'}">Hawk Sent</div>

          <div class="order" style="width: 9%;" (click)="setOrder('created_at')" [ngClass]="{active: orderBy=='created_at',asc:order=='ASC'}">Build Date</div>
          <div class="order" style="width: 10%;" (click)="setOrder('called_in')" [ngClass]="{active: orderBy=='called_in',asc:order=='ASC'}">Online Status</div>
          <div style="width: 12%;">Notes</div>

          <div style="width: 20%;">Actions</div>
        </div>
        
        <div><input style="width: 100%; margin: 5px 0px;height: 30px;" type="text" pInputText placeholder="Search.." (keyup)="applyFilter($event.target.value)"></div>
        <div class="table_content" *ngIf="failed_hawks.length > 0">
          <div class="table_row" *ngFor="let fh of failed_hawks | paginate: { id: 'pagination1', itemsPerPage: 20, currentPage: p }; index as i;">
            <div style="width: 2%;">
              <input type="checkbox" (change)="onSelectHawk(i,$event.target.checked)" [ngModel]="checkSelected(i)" />
            </div>
            <div style="width: 15%;">{{fh.channel_name}} ({{fh.channel_entity_id}})</div>
            <div style="width: 15%;">{{fh.site_name}} ({{fh.site_entity_id}})</div>
            <div style="width: 8%;">{{fh.serial_number}}</div>
            <div style="width: 9%;">{{fh.hawk_status_updated_at}}</div>
          
            <div style="width: 9%;">{{fh.created_at}}</div>
            <div style="width: 10%;text-shadow:none" [ngStyle]="{'background':fh.called_in_bg}">{{fh.called_in}}</div>
            <div style="width: 12%;">
            Built By: {{fh.built_by}}<br>
            Hawked By: {{fh.hawked_by}}
            </div>

            <div style="width: 20%;">
                <button class="btn" (click)="reHawk(fh.site_id,fh.channel_id,fh.buildid)" style="margin-right:2px" [disabled]="isLoadHawk">Hawk</button>
                <button class="btn" (click)="forceHawk(fh.site_id)" style="margin-right:2px" [disabled]="isLoadHawk || !fh.hawk_id">Mark as Complete</button>
                <button class="btn" (click)="cancelHawk(fh.site_id)" [disabled]="isCancelHawk || !fh.hawk_id || hawk_type=='canceled'">Cancel hawk</button>
                <!-- fh.buildid,fh.serial_number,fh.hawk_id -->
              </div>
          </div>
            <div style="text-align: center; line-height: 12px; font-size: 12px;">
              <pagination-controls id="pagination1" (pageChange)="p = $event"></pagination-controls>
            </div>
        </div>
        <div style="text-align: center;margin: 20px;padding: 20px;" *ngIf="loadingHawkSection">
          Loading...
        </div>  
        <div style="text-align: center;margin: 20px;padding: 20px;" *ngIf="!loadingHawkSection && failed_hawks.length == 0">
          No results
        </div>      
      </div>
    </div>
  </div>
</div>



<div class="dash_top box" *ngIf="userInfo && (userInfo.type == 'Administrator' || userInfo.type == 'Consultant')" style="margin-top: 40px;">
  <div class="title_box">
      <div class="title custom-title">Auto Update Log Record</div>
    </div>
   
    <div class="table_titles" style="font-size: 12px;display: flex;padding: 0px 10px;">
    <label class="order" style="width: 14%;">From</label>
    <label class="order" style="width: 14%;">To</label>
    <label class="order" style="width: 15%;">Status</label>
    </div>
  <div class="box_move_inner">
    <div class="title_box">
      
      <div class="title" style="  display: flex;
      font-family: 'verdana', sans-serif; /* Specify a fallback generic font family */
      font-size: 12px;
      line-height: 18px;
      color: #4c4c4c;
      text-transform: uppercase;
      margin: 8px;
      margin-top: -3px;">
    
     <input type="date" #start_date style=" height: 30px;
    border: 1px solid #b8b9bb;
    min-width: 13%;
    padding: 0 5px;
    font-size: 12px;
    margin-right:10px;
      font-family: Verdana, sans-serif;">
    <input type="date" #end_date style=" height: 30px;
        border: 1px solid #b8b9bb;
        min-width: 13%;
        padding: 0 5px;
        font-size: 12px;
      margin-right:10px;  font-family: Verdana, sans-serif;">
	
	<!--
    <input type="text"  placeholder="Channel " (keyup)="Channel_search($event)" style=" height: 30px;
    border: 1px solid #b8b9bb;
    min-width: 13%;
    padding: 0 5px;
    font-size: 14px;
    margin-right:10px">
      <input type="text" placeholder="Profile"(keyup)="Playlist_search($event)" style=" height: 30px;
    border: 1px solid #b8b9bb;
    min-width: 13%;
    padding: 0 5px;
    font-size: 14px;
    margin-right:10px">
    -->
    <select style="border:1px solid #b8b9bb;height:30px;" name="hawk_title" [(ngModel)]="status" (change)="statuschange(start_date.value,end_date.value,$event.target.values)">
      <option value="both" [selected]="status=='both'">Both</option>
      <option value="created" [selected]="status=='created'">Created</option>
      <option value="error" [selected]="status=='error'">Error</option>
    </select>
     <button (click)="datelog(start_date.value,end_date.value,status)"  style="margin-left:14px;margin-right: 10px;width:70px;">Search</button>
     
      </div>

    </div>
    <div class="box_inner">
      <div class="table_wrap dashboard_table">
        <div class="table_titles">
          <div class="order" style="width: 15%;" (click)="setOrderTC('channel.name')" [ngClass]="{active: orderBy=='channel_name',asc:order=='ASC'}">Channel</div>
          <div class="order" style="width: 15%;" (click)="setOrderTC('profile')" [ngClass]="{active: orderBy=='profile',asc:order=='ASC'}">Profile</div>
          <div class="order" style="width: 10%;" (click)="setOrderTC('startdate')" [ngClass]="{active: orderBy=='start_date',asc:order=='ASC'}">Start Date</div>
          <div class="order" style="width: 10%;"(click)="setOrderTC('enddate')" [ngClass]="{active: orderBy=='end_date',asc:order=='ASC'}">End Date</div>

          <div class="order" style="width: 8%;" (click)="setOrderTC('tracks_count')" [ngClass]="{active: orderBy=='tracks_count',asc:order=='ASC'}">Track Count</div>
          <div class="order" style="width: 10%;" (click)="setOrderTC('duplicate_count')" [ngClass]="{active: orderBy=='duplicate_count',asc:order=='ASC'}">Duplicate Count</div>
          <div style="width: 8%;">Status</div>

          <div style="width: 12%;" (click)="setOrderAL('created_at')" [ngClass]="{active: orderBy=='channel_name',asc:order=='ASC'}">Created At</div>
          <div style="width: 12%;" (click)="setOrderAL('updated_at')" [ngClass]="{active: orderBy=='channel_name',asc:order=='ASC'}">Updated At</div>
        </div>
        <div><input style="width: 100%; margin: 5px 0px;height: 30px;" type="text" pInputText placeholder="Search.." (keyup)="applyFilterOnLog($event.target.value)"></div>
        
        <div class="table_content" *ngIf="logdatedata.length > 0">
          <div class="table_row" *ngFor="let dr of logdatedata | paginate: { id: 'paginationdate1', itemsPerPage: 20, currentPage: pD }; index as i;">
            <div style="width: 2%;">
             
            </div>
            <div style="width: 14%;">{{dr.channel.name}} </div>
            <div style="width: 14%;">{{dr.playlist?.name}}</div>
            <div style="width: 10%;">{{dr.startdate}}</div>
            <div style="width: 10%;">{{dr.enddate}}</div>
          
            <div style="width: 8%;">{{dr.tracks_count}}</div>
            <div style="width: 10%;text-shadow:none" [ngStyle]="{'background':dr.called_in_bg}">{{dr.duplicate_count}}</div>
            <div style="width: 8%;">{{dr.build_status}}</div>
            <div style="width: 12%;">{{dr.created_at}}</div>
	   <div style="width: 12%;">{{dr .updated_at }}</div>
            <div style="width: 20%;">
              
              </div>
          </div>
            <div style="text-align: center; line-height: 12px; font-size: 12px;">
              <pagination-controls id="paginationdate1" (pageChange)="pD = $event"></pagination-controls>
            </div>
        </div>
          
        <div style="text-align: center;margin: 20px;padding: 20px;" *ngIf="loadingAULR">
          Loading...
        </div> 
        <div style="text-align: center;margin: 20px;padding: 20px;" *ngIf="!loadingAULR && logdatedata.length == 0">
          No results
        </div>      
      </div>
    </div>
  </div>
</div>

<div class="dash_top box" *ngIf="userInfo && (userInfo.type == 'Administrator' || userInfo.type == 'Consultant')" style="margin-top: 40px;">
  <div class="box_move_inner">
    <div class="title_box">
      <div class="title custom-title">Failed Builds</div>
    </div>
    <div class="box_inner">
      <div class="table_wrap dashboard_table">
        <div class="table_titles">
          <div class="order" style="width: 30%;" (click)="setOrderFB('channel_name')" [ngClass]="{active: orderByFB=='channel_name',asc:orderFB=='ASC'}">Channel</div>
          <div class="order" style="width: 20%;" (click)="setOrderFB('site_name')" [ngClass]="{active: orderByFB=='site_name',asc:orderFB=='ASC'}">Site</div>
          <div style="width: 30%;">Curator / ODBAPI assignments</div>
          <div class="order" style="width: 20%;" (click)="setOrderFB('created_at')" [ngClass]="{active: orderByFB=='created_at',asc:orderFB=='ASC'}">Created At</div>
        </div>
        <div class="table_content" *ngIf="failed_builds.length > 0">
          <div class="table_row" *ngFor="let fb of failed_builds | paginate: { id: 'pagination2', itemsPerPage: 20, currentPage: pFB }; index as i;">
            <div style="width: 30%;">
              <a href="/client-playlists?uid={{fb.site_client_id}}&pcat={{fb.site_id}}&cat={{fb.channel_id}}">
                {{fb.channel_name}} ({{fb.channel_entity_id}})
              </a> </div>
            <div style="width: 20%;">{{fb.site_name}} ({{fb.site_entity_id}})</div>
            <div style="width: 30%;">
              <table style="text-align:left;width: 100%;" *ngIf="i==0" aria-describedby="failed_builds">
                <thead>
                  <th scope="col" style="width: 25%;"></th>
                  <th scope="col" style="width: 25%;"> Profiles </th>
                  <th scope="col" style="width: 25%;"> Clip </th>
                  <th scope="col" style="width: 25%;"> Assignments </th>
                </thead>
              </table>

              <table style="text-align:left;width: 100%;" aria-describedby="failed_builds">
               
                <tbody>
                  <tr>
                    <td style="width: 25%;">Curator</td>
                    <td style="width: 25%;">{{fb.profile_count}}</td>
                    <td style="width: 25%;">{{fb.clip_count}}</td>
                    <td style="width: 25%;">{{ ConvertToInt(fb.clip_count) + ConvertToInt(fb.profile_count)}}</td>
                  </tr>
                  <tr>
                    <td style="width: 25%;">ODBAPI</td>
                    <td style="width: 25%;">
                      <span *ngIf="fb.response && fb.response.statusMessage.returnvalue">
                        {{fb.response.statusMessage.returnvalue.NumberOfAssignments}}
                      </span>
                      <span *ngIf="!fb.response || (fb.response && !fb.response.statusMessage.returnvalue)"> - </span>
                    </td>
                    <td style="width: 25%;">
                      <span *ngIf="fb.response && fb.response.statusMessage.returnvalue">
                        {{fb.response.statusMessage.returnvalue.NumberOfProfiles}}
                      </span>
                      <span *ngIf="!fb.response || (fb.response && !fb.response.statusMessage.returnvalue)"> - </span>
                    </td>
                    <td style="width: 25%;">
                      <span *ngIf="fb.response && fb.response.statusMessage.returnvalue">
                        {{fb.response.statusMessage.returnvalue.NumberOfClips}}
                      </span>
                      <span *ngIf="!fb.response || (fb.response && !fb.response.statusMessage.returnvalue)"> - </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div style="width: 20%;">{{fb.created_at}}</div>
          </div>
          <div style="text-align: center; line-height: 12px; font-size: 12px;">
            <pagination-controls id="pagination2" (pageChange)="pFB = $event"></pagination-controls>
          </div>
        </div>
        <div style="text-align: center;margin: 20px;padding: 20px;" *ngIf="failed_builds.length == 0">
          No results
        </div> 
      </div>
    </div>
  </div>
</div>
<div class="dash_lists">
  <span *ngFor="let b of loadedBlocks; let i = index">
  <div class="box" dndDropzone  [ngClass]="{closed: checkCollapsed(b.block_id)}" (dndDrop)="setBlockPosition($event,i+1)">
    <div class="box_move_inner" [dndDraggable]="draggable.data" [dndEffectAllowed]="draggable.effectAllowed" [dndDisableIf]="draggable.disable" (dndStart)="moveBlock($event,i+1)" (dndCanceled)="endMove($event, i+1)">
      <div class="title_box">
        <div class="title">{{b.name}} <a href="#" class="collapse_box" (click)="collapseBlock(b.block_id)"></a></div>
        <div class="subtitle">Foundation playlists awaiting your action</div>
      </div>
      <div class="box_inner">
        <div class="table_wrap dashboard_table">
          <div class="table_titles" *ngIf="b.block_id != 4">
            <span class="more" (click)="openPopup(b.block_id,b.name)">View more</span>
            <div>Name</div>
            <div>Assigned by</div>
            <div>Assigned date</div>
            <div>Actions</div>
          </div>
          <div class="table_titles" *ngIf="b.block_id == 4">
            <span class="more" (click)="openPopup(b.block_id,b.name)">View more</span>
            <div>Name</div>
            <div>Email</div>
            <div>Type</div>
            <div>Actions</div>
          </div>
          <div class="table_content" *ngIf="b.block_id == 1">
            <div class="table_row" *ngFor="let pp of periodPlaylists | slice:0:3">
              <div>{{pp.name}}</div>
              <div>{{pp.first_name}} {{pp.last_name}}</div>
              <div>{{pp.created_at}}</div>
              <div>
                <a href="#" (click)="manageClick(pp.id,pp.type)"><em>View</em></a> &nbsp;
                
              </div>
            </div>
          </div>
          <div class="table_content" *ngIf="b.block_id == 2">
            <div class="table_row" *ngFor="let ipp of inprogressPlaylists | slice:0:3">
              <div>{{ipp.name}}</div>
              <div>{{ipp.first_name}} {{ipp.last_name}}</div>
              <div>{{ipp.created_at}}</div>
              <div>
                <a href="#" (click)="manageClick(ipp.id,ipp.type)"><em>View</em></a> &nbsp;
                
              </div>
            </div>
          </div>
          <div class="table_content" *ngIf="b.block_id == 3">
            <div class="table_row" *ngFor="let fp of foundationPlaylists | slice:0:3">
              <div>{{fp.name}}</div>
              <div>{{fp.user_id}}</div>
              <div>{{fp.created_at}}</div>
              <div>
                <a href="#" (click)="manageClick(fp.id,'fp')"><em>View</em></a> &nbsp;
                
              </div>
            </div>
          </div>
          <div class="table_content" *ngIf="b.block_id == 4">
            <div class="table_row" *ngFor="let mc of myCustomers | slice:0:3">
              <div>{{mc.first_name}} {{mc.last_name}}</div>
              <div>{{mc.email}}</div>
              <div>{{mc.type}}</div>
              <div>
                <a routerLink="/client-info"><em>View</em></a>
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  </span>
  <div class="box" *ngIf="userInfo && (userInfo.type == 'Administrator' || userInfo.type == 'Consultant')">
    <div class="box_move_inner">
      <div class="title_box">
        <div class="title">Sync Actions</div>
        <div class="subtitle">Integrate odbapi datas</div>
      </div>
      <div class="box_inner">
        <div class="table_wrap dashboard_table sync_table">
          <div class="table_titles">
            <div>Name</div>
            <div>Description</div>
            <div>Actions</div>
          </div>
          <div class="table_content">
            <div class="table_row">
              <div>Clip</div>
              <div> Integrate clips from odbapi </div>
              <div>
                <button title="Clip" (click)="syncOdbapi('clip')">Sync</button>  
              </div>
            </div>
            <div class="table_row">
              <div>Profile</div>
              <div> Integrate profiles from odbapi </div>
              <div>
                <button title="Clip" >Sync</button>  
              </div>
            </div>
            <div class="table_row">
              <div>Entity</div>
              <div> Integrate entity from odbapi </div>
              <div>
                <button title="Clip" >Sync</button>  
              </div>
            </div>
            <div class="table_row">
              <div>Assignment</div>
              <div> Integrate assignments from odbapi </div>
              <div>
                <button title="Clip" >Sync</button>  
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-alert></app-alert>
<div class="popup" [ngClass]="{opened: popupOpened}" ngDraggable ngResizable [rzHandles]="'n,e,s,w,se,sw,ne,nw'" [handle]="dragHandle">
  <a href="#" class="popup_close" (click)="popupClose()"></a>
  <div class="popup_title" #dragHandle>{{popupTitle}}</div>
  <div class="popup_form">
    <div class="table_wrap dashboard_table">
      <div class="table_titles" *ngIf="openedId != 4">
        <div>Name</div>
        <div>Assigned by</div>
        <div>Assigned date</div>
        <div>Actions</div>
      </div>
      <div class="table_titles" *ngIf="openedId == 4">
        <div>Name</div>
        <div>Email</div>
        <div>Type</div>
        <div>Actions</div>
      </div>
      <div class="table_content" *ngIf="openedId == 1">
        <div class="table_row" *ngFor="let pp of periodPlaylists">
          <div>{{pp.name}}</div>
          <div>{{pp.first_name}} {{pp.last_name}}</div>
          <div>{{pp.created_at}}</div>
          <div>
            <a href="#" (click)="manageClick(pp.id,pp.type)"><em>View</em></a> &nbsp;
            
          </div>
        </div>
      </div>
      <div class="table_content" *ngIf="openedId == 2">
        <div class="table_row" *ngFor="let ipp of inprogressPlaylists">
          <div>{{ipp.name}}</div>
          <div>{{ipp.first_name}} {{ipp.last_name}}</div>
          <div>{{ipp.created_at}}</div>
          <div>
            <a href="#" (click)="manageClick(ipp.id,ipp.type)"><em>View</em></a> &nbsp;
            
          </div>
        </div>
      </div>
      <div class="table_content" *ngIf="openedId == 3">
        <div class="table_row" *ngFor="let fp of foundationPlaylists">
          <div>{{fp.name}}</div>
          <div>{{fp.user_id}}</div>
          <div>{{fp.created_at}}</div>
          <div>
            <a href="#" (click)="manageClick(fp.id,'fp')"><em>View</em></a> &nbsp;
            
          </div>
        </div>
      </div>
      <div class="table_content" *ngIf="openedId == 4">
        <div class="table_row" *ngFor="let mc of myCustomers">
          <div>{{mc.first_name}} {{mc.last_name}}</div>
          <div>{{mc.email}}</div>
          <div>{{mc.type}}</div>
          <div>
            <a routerLink="/client-info"><em>View</em></a>
            
          </div>
        </div>
      </div>
    </div>
    <div class="popup_btns">
      <button type="button" class="cancel" (click)="popupClose()">Cancel</button>
    </div>
  </div>
</div>
<div class="fade" [ngClass]="{opened: isLoadHawk}"></div>
<img src="../../assets/img/loader.gif" alt="loader" class="loader" [ngClass]="{active:isLoadHawk}"/>
