<div class="page_wrap">
  <app-page-header [logout]="true" [pageTitle]="'Create, edit and manage your playlists'"></app-page-header>
  <form #forgotForm="ngForm" class="forgot_form">
    <h2>Forgot password</h2>
    <div class="input_wrap">
      <label>Email</label>
      <input type="text" value="{{email}}" name="email" [(ngModel)]="email"/>
    </div>
    <div class="popup_btns">
      <button type="submit" (click)="forgotDataSend($event,forgotForm)">Send</button>
      <a routerLink="/login">Back to homepage</a>
    </div>
  </form>
</div>
<app-alert></app-alert>
