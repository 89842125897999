<button (click)="openPopup()" title="Upload Tracks">Onboard Tracks</button>
<div class="popup upload_tracks_popup" [ngClass]="{opened: popupOpened}" ngDraggable ngResizable [rzHandles]="'n,e,s,w,se,sw,ne,nw'" [handle]="dragHandle">
  <a href="#" class="popup_close" (click)="popupClose($event)"></a>
  <div class="popup_title" #dragHandle>Upload Tracks</div>
  <div class="popup_form">
    <dropzone
      [config]="config"
      [message]="'Click or drag files here to upload'"
      (error)="onUploadError($event)"
      (success)="onUploadSuccess($event)" (addedFile)="addFile()">
    </dropzone>
    <div class="popup_btns">
      <button *ngIf="showbutton" type="button" class="hide" (click)="openConfirmPopup()">Export Failed Uploads</button>
      <button type="button" class="hide" (click)="popupClose($event)">Cancel</button>
     
    </div>
  </div>
</div>
<app-alert></app-alert>
<app-confirm (isConfirm)="isConfirm($event)"></app-confirm>
