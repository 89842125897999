import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {map} from 'rxjs/operators';
import { environment } from '../../environments/environment';
import {Globals} from '../globals';

@Injectable()
export class ScheduleService {

  httpOptions = {
    headers: new HttpHeaders({
      'Host': environment.dev_url_prefix + '/',
      'Authorization': 'Bearer ' + this.globals.access_token,
      'Accept': 'application/json'
    })
  };
  postOptions = {
    headers: new HttpHeaders({
      'Host': environment.dev_url_prefix + '/',
      'Authorization': 'Bearer ' + this.globals.access_token,
      'Accept': 'application/json',
      'Content-Type':'application/x-www-form-urlencoded'
    })
  };
  deleteOptions = {
    headers: new HttpHeaders({
      'Host': environment.dev_url_prefix + '/',
      'Authorization': 'Bearer ' + this.globals.access_token,
      'Accept': 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded'
    })
  };

  constructor(
    private http: HttpClient,
    private globals: Globals
  ) { }

  /**
   Create new schedule
   * @param cid - channel id
   * @param formData - post data
   * @returns {Observable<any>}
   */
  createSchedule( cid, formData ){
    return this.http.post(environment.dev_url_prefix + '/api/V1/schedule/add/' + cid, formData, this.postOptions).pipe(map(schedule => {
      return schedule;
    }));
  }

  /**
   Create new schedule with name only
   * @param cid - channel id
   * @param formData - post data
   * @returns {Observable<any>}
   */
  createScheduleByName( cid, formData ){
    return this.http.post(environment.dev_url_prefix + '/api/V1/schedule/add_by_name/' + cid, formData, this.postOptions).pipe(map(schedule => {
      return schedule;
    }));
  }

  /**
   Update schedule
   * @param sid - schedule id
   * @param formData - post data
   * @returns {Observable<any>}
   */
  updateSchedule( sid, formData ){
    
    return this.http.put(environment.dev_url_prefix + '/api/V1/schedule/edit/' + sid, formData, this.postOptions).pipe(map(schedule => {
      return schedule;
    }));
  }

  /**
   Update schedule name
   * @param sid - schedule id
   * @param formData - post data
   * @returns {Observable<any>}
   */
  updateScheduleName( sid, formData ){
    
    return this.http.put(environment.dev_url_prefix + '/api/V1/schedule/name_edit/' + sid, formData, this.postOptions).pipe(map(schedule => {
      return schedule;
    }));
  }

  /**
   Get list of schedules
   * @param cid - channel id
   * @returns {Observable<any>}
   */
  getSchedulesList(cid){
    return this.http.get(environment.dev_url_prefix + '/api/V1/schedules/' + cid, this.httpOptions).pipe(map(schedules => {
      return schedules;
    }));
  }

  /**
   Get schedule information
   * @param sid - schedule id
   * @returns {Observable<any>}
   */
  getScheduleInfo(sid){
    return this.http.get(environment.dev_url_prefix + '/api/V1/schedule/' + sid, this.httpOptions).pipe(map(schedule => {
      return schedule;
    }));
  }

  /**
   Delete schedule
   * @param sid - schedule id
   * @returns {Observable<any>}
   */
  deleteSchedule(sid){
    return this.http.delete(environment.dev_url_prefix + '/api/V1/schedule/delete/' + sid, this.deleteOptions).pipe(map(schedule => {
      return schedule;
    }));
  }

  /**
   Get dates for schedules actions
   * @param cid - channel id
   * @returns {Observable<any>}
   */
  getScheduleDates(cid){
    return this.http.get(environment.dev_url_prefix + '/api/V1/schedule/all_by_channel/' + cid, this.httpOptions).pipe(map(dates => {
      return dates;
    }));
  }

  deleteFR(sid){
    return this.http.delete(environment.dev_url_prefix + '/api/V1/schedule/deleteFR/' + sid, this.deleteOptions).pipe(map(schedule => {
      return schedule;
    }));
  }
}
