<div class="ph_block">
  <app-page-header *ngIf="isShowHeader" [pageTitle]="'Create, edit and manage your playlists'"></app-page-header>
  <div class="top_actions_box" *ngIf="isShowHeader" [ngClass]="{fullscreen: screenParams.isFullScreen}">
    <div class="left_box" *ngIf="!screenParams.isPlaylist">
      <h2>Track Library
        <div style="text-align: right;">
          <span class="festive_select">

            <label style="font-size: 10px;vertical-align: bottom;">Festive Type</label>
            <select style="width: auto;margin-right: 14px;" name="festive_type"
              (change)="openConfirmPopup($event.target.value,10)">
              <option value="0">None</option>
              <option value="1">Christmas</option>
              <option value="2">Chinese New Year</option>
              <option value="3">Ramadan</option>

            </select>

          </span>
        </div>
      </h2>
    </div>
    <div class="right_box" *ngIf="screenParams.isPlaylist||!screenParams.isFullScreen">
      <h2>Create Playlist <span *ngIf="tabs[selectedTabs.rightTabIndex].playlistInfo?.profile_type == 1"> - Master
          playlist</span>
        <div>
          <button (click)="openConfirmPopup(0,4)" title="Retain" style="margin-right:5px">Retain</button>
          <button (click)="openConfirmPopup(0,5)" title="Remove Retain" style="margin-right:5px">Remove retain</button>
          <button title="Copy From" style="margin-right:5px">Copy From</button>

          <button (click)="openConfirmPopup(0,9)" title="Export" style="margin-left:5px">Export</button>

          <!-- {{p.hidden}} -->
        </div>
      </h2>
      <div class="buttons_box">
        <a href="#" class="back_btn btn" (click)="backBtnClick()" title="Go Back"></a>
        <a href="#" class="add_playlist btn"
          *ngIf="current_user!=null&&(current_user.type=='Consultant'||current_user.type=='Administrator')"
          (click)="openCreatePlaylistPopup()" title="New Playlist"></a>
        <button [disabled]="tabs[selectedTabs.rightTabIndex].type != 'sfp'" class="open_new_window btn"
          (click)="openNewWindow($event)" title="Open the SFP in New Window"></button>
      </div>
    </div>
  </div>
  <div class="manage_fp_main" [ngClass]="{fullscreen: screenParams.isFullScreen}">
    <div class="manage_fp_left" *ngIf="!screenParams.isPlaylist">
      <div class="actions_box">
        <div class="search_box">
          <form method="get" #searchForm="ngForm">
            <input type="text" (focus)="focusInput()" (blur)="blurInput()" name="search" value="{{searchStr}}"
              placeholder="Track search" class="searchInput" [(ngModel)]="searchStr" (keyup)="searchPress()" />
            <button type="submit" class="searchBtn" (click)="search()"></button>
            <a href="#" class="clear_search" (click)="clearSearch()"></a>
          </form>
        </div>
        <button (click)="openFilterPopup()" class="with_arrow" title="Filter Tracks/Convert to Playlist">Smart
          filter</button>
        <button
          (click)="addTracks(leftTabs[selectedTabs.leftTabIndex].selectedRows,tabs[selectedTabs.rightTabIndex].playlistInfo.id)"
          *ngIf="current_user!=null&&(current_user.type=='Consultant'||current_user.type=='Administrator')"
          title="Add selected track to CP">Add to SFP</button>
        <div class="to_other_wrap" (click)="clickInside($event)"
          *ngIf="current_user!=null&&(current_user.type=='Consultant'||current_user.type=='Administrator')&&!screenParams.isFullScreen">
          <button (click)="openAnotherPlaylists(false)" class="addother_btn"
              title="Add selected track to another SFP of same channel">+ to Other</button>
            <button [ngClass]="{disableButton : tracksCount[selectedTabs.leftTabIndex].total <= infiniteScroll.addItems}" (click)="onScroll()" title="Load more" [disabled]="tracksCount[selectedTabs.leftTabIndex].total <= infiniteScroll.addItems">Load more</button>
          <ul [ngClass]="{opened: openPlaylistDrop2}">
            <li *ngFor="let cps of openPlaylist.sites.sitesArr">
              <span (click)="getPlaylistInfo(2,cps.id)"
                [ngClass]="{opened: openPlaylist.sites.id == cps.id }">{{cps.name}}</span>
              <ul [ngClass]="{opened: openPlaylist.sites.id == cps.id }">
                <li *ngFor="let cp of openPlaylist.playlists.playlistsArr">
                  <span (click)="getPlaylistInfo(4,cp.id)">{{cp.name}}</span>
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <a href="#" class="open_new_window btn separate_btn" (click)="openSeparateWindow()"
          *ngIf="current_user!=null&&(current_user.type=='Consultant'||current_user.type=='Administrator')&&!screenParams.isFullScreen"
          title="Open Track Library and SFP in different Window"></a>

      </div>
      <div class="tabs left_tabs">
        <div class="tracks_count">
          <span style="margin-right:5px">
            Selected tracks: {{leftTabs[selectedTabs.leftTabIndex].selectedRows.length}}
          </span>
          <span style="margin-right:7px">
            {{tracksCount[selectedTabs.leftTabIndex].selectedDuration}} /
            {{tracksCount[selectedTabs.leftTabIndex].totalDuration}}
            <!-- Total Duration : {{getDuration()}}  -->
          </span>
          {{
          (infiniteScroll.addItems + infiniteScroll.offsetItems) > tracksCount[selectedTabs.leftTabIndex].display ?
          tracksCount[selectedTabs.leftTabIndex].display : (infiniteScroll.addItems + infiniteScroll.offsetItems)
          }} /
          {{tracksCount[selectedTabs.leftTabIndex].display}}
          <!-- / {{tracksCount[selectedTabs.leftTabIndex].total}} tracks -->
        </div>
        <ul class="tab changetab" style="font-size:8px">

          <li *ngFor="let t of leftTabs; let i = index" [ngClass]="{selected: t.selected}" (click)="deselectfpTab(i)">
            {{t.title}}
            <!-- <span (click)="clearFilter()" *ngIf="smartFilters[i][0].categorySelect 
                && smartFilters[i][0].compareSelect
                && filterClicked[i]
                && smartFilters[i].length > 0">
                <img alt="filter_icon" class="funnel_img" src="../../assets/img/filter_icon.png">
                  
                </span> -->

            <ul *ngIf="i==1">
              <li><input *ngIf="fpList.category.catsArr.length > 0" type="text" class="form-control"
                  style="width: 100%;" value="{{searchStrFP}}" [(ngModel)]="searchStrFP"
                  (keyup)="searchPressFPDropdown($event)">
                <a *ngIf="!loadingFPSearch" href="#" class="clear_search_icon" (click)="clearSearchFP()"></a>
                <img *ngIf="loadingFPSearch" src="../../assets/img/loader.gif" alt="loader" class=""
                  style="position: absolute;left: 94%;height: 25px;" />
              </li>
              <li *ngIf="!loadingFPSearch && (searchStrFP != '' && filteredParentIDs.length == 0 )"
                style="padding: 15px;text-align: center;font-size: 14px;"> -- No results -- </li>
              <li *ngFor="let fpc of fpList.category.catsArr" (click)="selectFpPlaylist(0,fpc.id, '')">
                <span
                  *ngIf=" (searchStrFP == '' && filteredParentIDs.length == 0 ) || (filteredParentIDs.length > 0 && filteredParentIDs.indexOf(fpc.id) > -1)"
                  [ngClass]="{opened: fpList.category.id == fpc.id }">{{fpc.name}}</span>
                <ul *ngIf="fpc.children!=undefined" [ngClass]="{opened: fpList.category.id == fpc.id }">
                  <li *ngFor="let fpsc of fpc.children">
                    <span (click)="selectFpPlaylist(1,fpsc.id, '')"
                      *ngIf="(searchStrFP == '' && filteredChildIDs.length == 0 ) || (filteredChildIDs.length > 0 && filteredChildIDs.indexOf(fpsc.id) > -1)"
                      [ngClass]="{opened: fpList.subcategory.id == fpsc.id }">{{fpsc.name}}</span>
                    <ul [ngClass]="{opened: fpList.subcategory.id == fpsc.id }">
                      <li *ngFor="let fp of fpList.playlists.playlistsArr">
                        <span
                          *ngIf="searchStrFP == '' || ( searchStrFP != '' && filteredChildIDs.length > 0 && fp.name.toLowerCase().includes(searchStrFP.toLowerCase()))"
                          (click)="selectFpPlaylist(2,fp.id, fp.name)">{{fp.name}}</span>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
            </ul>
            <ul *ngIf="i==2">
              <li><input *ngIf="fpsampleList.category.catsArr.length > 0" type="text" class="form-control"
                  style="width: 100%;" value="{{searchStrFPsample}}" [(ngModel)]="searchStrFPsample"
                  (keyup)="searchPressFPSampleDropdown($event)">
                <a *ngIf="!loadingFPSampleSearch" href="#" class="clear_search_icon"
                  (click)="clearSearchFPSample()"></a>
                <img *ngIf="loadingFPSampleSearch" src="../../assets/img/loader.gif" alt="loader" class=""
                  style="position: absolute;left: 94%;height: 25px;" />
              </li>
              <li *ngIf="!loadingFPSampleSearch && (searchStrFPsample != '' && filteredParentIDsFPS.length == 0 )"
                style="padding: 15px;text-align: center;font-size: 14px;"> -- No results -- </li>
              <li *ngFor="let fpc of fpsampleList.category.catsArr" (click)="selectFpsamplePlaylist(0,fpc.id, '')">
                <span
                  *ngIf=" (searchStrFPsample == '' && filteredParentIDsFPS.length == 0 ) || (filteredParentIDsFPS.length > 0 && filteredParentIDsFPS.indexOf(fpc.id) > -1)"
                  [ngClass]="{opened: fpsampleList.category.id == fpc.id }">{{fpc.name}}</span>
                <ul *ngIf="fpc.subcategory!=undefined" [ngClass]="{opened: fpsampleList.category.id == fpc.id }">
                  <li *ngFor="let fpsc of fpc.subcategory">
                    <span (click)="selectFpsamplePlaylist(1,fpsc.id, '')"
                      *ngIf="(searchStrFPsample == '' && filteredChildIDsFPS.length == 0 ) || (filteredChildIDsFPS.length > 0 && filteredChildIDsFPS.indexOf(fpsc.id) > -1)"
                      [ngClass]="{opened: fpsampleList.subcategory.id == fpsc.id }">{{fpsc.name}}</span>
                    <ul [ngClass]="{opened: fpsampleList.subcategory.id == fpsc.id }">
                      <li *ngFor="let fp of fpsampleList.playlists.playlistsArr">
                        <span
                          *ngIf="searchStrFPsample == '' || ( searchStrFPsample != '' && filteredChildIDsFPS.length > 0 && fp.name.toLowerCase().includes(searchStrFPsample.toLowerCase()))"
                          (click)="selectFpsamplePlaylist(2,fp.id, fp.name)">{{fp.name}}</span>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
            </ul>
            <ul *ngIf="i==3">
              <li><input *ngIf="cpsampleList.category.catsArr.length > 0" type="text" class="form-control"
                  style="width: 100%;" value="{{searchStrCPsample}}" [(ngModel)]="searchStrCPsample"
                  (keyup)="searchPressCPSampleDropdown($event)">
                <a *ngIf="!loadingCPSampleSearch" href="#" class="clear_search_icon"
                  (click)="clearSearchCPSample()"></a>
                <img *ngIf="loadingCPSampleSearch" src="../../assets/img/loader.gif" alt="loader" class=""
                  style="position: absolute;left: 94%;height: 25px;" />
              </li>
              <li *ngIf="!loadingCPSampleSearch && (searchStrCPsample != '' && filteredParentIDsCPS.length == 0 )"
                style="padding: 15px;text-align: center;font-size: 14px;"> -- No results -- </li>
              <li *ngFor="let fpc of cpsampleList.category.catsArr" (click)="selectCpsamplePlaylist(0,fpc.id, '')">
                <span
                  *ngIf=" (searchStrCPsample == '' && filteredParentIDsCPS.length == 0 ) || (filteredParentIDsCPS.length > 0 && filteredParentIDsCPS.indexOf(fpc.id) > -1)"
                  [ngClass]="{opened: cpsampleList.category.id == fpc.id }">{{fpc.name}}</span>
                <ul *ngIf="fpc.subcategory!=undefined" [ngClass]="{opened: cpsampleList.category.id == fpc.id }">
                  <li *ngFor="let fpsc of fpc.subcategory">
                    <span (click)="selectCpsamplePlaylist(1,fpsc.id, '')"
                      *ngIf="(searchStrCPsample == '' && filteredChildIDsCPS.length == 0 ) || (filteredChildIDsCPS.length > 0 && filteredChildIDsCPS.indexOf(fpsc.id) > -1)"
                      [ngClass]="{opened: cpsampleList.subcategory.id == fpsc.id }">{{fpsc.name}}</span>
                    <ul [ngClass]="{opened: cpsampleList.subcategory.id == fpsc.id }">
                      <li *ngFor="let fp of cpsampleList.playlists.playlistsArr">
                        <span
                          *ngIf="searchStrCPsample == '' || ( searchStrCPsample != '' && filteredChildIDsCPS.length > 0 && fp.name.toLowerCase().includes(searchStrCPsample.toLowerCase()))"
                          (click)="selectCpsamplePlaylist(2,fp.id, fp.name)">{{fp.name}}</span>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
            </ul>
            <ul *ngIf="i==4">
              <li><input *ngIf="cpList.client.clientArr.length > 0" type="text" class="form-control"
                  style="width: 100%;" value="{{searchStrCP}}" [(ngModel)]="searchStrCP"
                  (keyup)="searchPressCPDropdown($event)">
                <a *ngIf="!loadingCPSearch" href="#" class="clear_search_icon" (click)="clearSearchCP()"></a>
                <img *ngIf="loadingCPSearch" src="../../assets/img/loader.gif" alt="loader" class=""
                  style="position: absolute;left: 94%;height: 25px;" />
              </li>
              <li *ngIf="!loadingCPSearch && (searchStrCP != '' && filteredClientIDsCP.length == 0 )"
                style="padding: 15px;text-align: center;font-size: 14px;"> -- No results -- </li>
              
              <!-- <cdk-virtual-scroll-viewport itemSize="50" style="height: 400px;">
              <li *cdkVirtualFor="let fpc of cpList.client.clientArr" (click)="selectCpPlaylist(0,fpc.id, '')">
                <span
                  *ngIf=" (searchStrCP == '' && filteredClientIDsCP.length == 0 ) || (filteredClientIDsCP.length > 0 && filteredClientIDsCP.indexOf(fpc.id) > -1)"
                  [ngClass]="{opened: cpList.client.id == fpc.id }">{{fpc.company_name}}</span>
                <ul *ngIf="fpc.category!=undefined" [ngClass]="{opened: cpList.client.id == fpc.id }">
                  <li *ngFor="let fpsc of fpc.category">
                    <span (click)="selectCpPlaylist(1,fpsc.id, '')"
                      *ngIf="(searchStrCP == '' && filteredParentIDsCP.length == 0 ) || (filteredParentIDsCP.length > 0 && filteredParentIDsCP.indexOf(fpsc.id) > -1)"
                      [ngClass]="{opened: cpList.category.id == fpsc.id }">{{fpsc.name}}</span>
                    <ul [ngClass]="{opened: cpList.category.id == fpsc.id }">
                      <li *ngFor="let sub of cpList.subcategory.subcarArr">
                        <span (click)="selectCpPlaylist(2,sub.id, '')"
                          *ngIf="(searchStrCP == '' && filteredChildIDsCP.length == 0 ) || (filteredChildIDsCP.length > 0 && filteredChildIDsCP.indexOf(sub.id) > -1)"
                          [ngClass]="{opened: cpList.subcategory.id == sub.id }">{{sub.name}}</span>

                        <ul [ngClass]="{opened: cpList.subcategory.id == sub.id }">
                          <li *ngFor="let fp of cpList.playlists.playlistsArr">
                            <span
                              *ngIf="searchStrCP == '' || ( searchStrCP != '' && filteredChildIDsCP.length > 0 && fp.name.toLowerCase().includes(searchStrCP.toLowerCase()))"
                              (click)="selectCpPlaylist(3,fp.id, fp.name)">{{fp.name}}</span>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
              </cdk-virtual-scroll-viewport> -->

              <li *ngFor="let fpc of cpList.client.clientArr" (click)="selectCpPlaylist(0,fpc.id, '')">
                <span
                  *ngIf=" (searchStrCP == '' && filteredClientIDsCP.length == 0 ) || (filteredClientIDsCP.length > 0 && filteredClientIDsCP.indexOf(fpc.id) > -1)"
                  [ngClass]="{opened: cpList.client.id == fpc.id }">{{fpc.company_name}}</span>
                <ul *ngIf="fpc.category!=undefined" [ngClass]="{opened: cpList.client.id == fpc.id }">
                  <li *ngFor="let fpsc of fpc.category">
                    <span (click)="selectCpPlaylist(1,fpsc.id, '')"
                      *ngIf="(searchStrCP == '' && filteredParentIDsCP.length == 0 ) || (filteredParentIDsCP.length > 0 && filteredParentIDsCP.indexOf(fpsc.id) > -1)"
                      [ngClass]="{opened: cpList.category.id == fpsc.id }">{{fpsc.name}}</span>
                    <ul [ngClass]="{opened: cpList.category.id == fpsc.id }">
                      <li *ngFor="let sub of cpList.subcategory.subcarArr">
                        <span (click)="selectCpPlaylist(2,sub.id, '')"
                          *ngIf="(searchStrCP == '' && filteredChildIDsCP.length == 0 ) || (filteredChildIDsCP.length > 0 && filteredChildIDsCP.indexOf(sub.id) > -1)"
                          [ngClass]="{opened: cpList.subcategory.id == sub.id }">{{sub.name}}</span>

                        <ul [ngClass]="{opened: cpList.subcategory.id == sub.id }">
                          <li *ngFor="let fp of cpList.playlists.playlistsArr">
                            <span
                              *ngIf="searchStrCP == '' || ( searchStrCP != '' && filteredChildIDsCP.length > 0 && fp.name.toLowerCase().includes(searchStrCP.toLowerCase()))"
                              (click)="selectCpPlaylist(3,fp.id, fp.name)">{{fp.name}}</span>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
        <div class="tab_content" *ngFor="let t of leftTabs; let i = index" [ngClass]="{selected: t.selected}"
          dndDropzone (dndDrop)="onDropRemove()">
          <div class="table_wrap tracks_table">
            <div class="table_titles">
              <div class="order" (click)="setAllTracksOrder('mc_tags')"
                [ngClass]="{active: leftTabs[selectedTabs.leftTabIndex].orderBy=='mc_tags',asc:leftTabs[selectedTabs.leftTabIndex].order=='ASC'}">
                MC Tag</div>
              <div class="order" (click)="setAllTracksOrder('title')"
                [ngClass]="{active: leftTabs[selectedTabs.leftTabIndex].orderBy=='title',asc:leftTabs[selectedTabs.leftTabIndex].order=='ASC'}">
                Name</div>
              <div class="order" (click)="setAllTracksOrder('artist')"
                [ngClass]="{active: leftTabs[selectedTabs.leftTabIndex].orderBy=='artist',asc:leftTabs[selectedTabs.leftTabIndex].order=='ASC'}">
                Artist</div>

              <div class="order bpm" (click)="setAllTracksOrder('bpm')"
                [ngClass]="{active: leftTabs[selectedTabs.leftTabIndex].orderBy=='bpm',asc:leftTabs[selectedTabs.leftTabIndex].order=='ASC'}">
                BPM</div>
              <div></div>
            </div>
            <div class="table_content" *ngIf="t.tracks.length > 0" infinite-scroll [infiniteScrollDistance]="2"
              [infiniteScrollThrottle]="300" [scrollWindow]="false" (scrolled)="onScroll()">
              <div class="table_row" [dndDraggable]="draggable.data" [dndEffectAllowed]="draggable.effectAllowed"
                [dndDisableIf]="draggable.disable" (dndStart)="addDragTrack(pt.id, pt.pivot_id)"
                *ngFor="let pt of t.tracks;let ind = index" (click)="selectRow($event,pt.id, pt.pivot_id, i, ind )"
                [ngClass]="{selected: checkSelectedRow( leftTabs[i].selectedRows, pt.id, pt.pivot_id ),'isDeleted': pt.deleted_at != null}"
                [ngStyle]="setBgFromTag(pt.tags)">
                <div><em>{{pt.mc_tags}}</em></div>
                <div><strong>{{pt.title}}</strong></div>
                <div><em>{{pt.artist}}</em></div>

                <div><em>{{pt.bpm}}</em></div>
                <div>
                  <a href="#" class="track_info" *ngIf="pt.deleted_at == null "
                    (click)="showEdit( pt.id, pt.pivot_id, 0)" title="Detail"></a>
                  <a href="#" class="add_track"
                    (click)="addTrack(pt.id,tabs[selectedTabs.rightTabIndex].playlistInfo.id,false)"
                    *ngIf="current_user!=null&&(current_user.type=='Consultant'||current_user.type=='Administrator')"
                    title="Add"></a>
                  <span
                    *ngIf="playerInfo.trackId == pt.id && playerInfo.trackIndex == ind && playerInfo.isPlaying  == 'play'"
                    class="volume"></span>
                </div>
              </div>
            </div>
            <div class="table_content nf" *ngIf="t.tracks.length <= 0">
              <span>No results found</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="manage_fp_right" *ngIf="screenParams.isPlaylist||!screenParams.isFullScreen">
      <div class="actions_box">
        <!-- <button class="profile_statements_btm" (click)="openProfilePopup()" -->
        <!-- title="Edit/Create Profile Statement">Profile Statements</button> -->
        <button (click)="openInfoPopup()" class="" title="Info" style="float: right;">Info</button>

        <button (click)="openTagsPopup()" class="tag_manager_btn with_arrow" title="Edit Tags">Tag Manager</button>
      </div>
      <div class="tabs">
        <ul class="tab">

          <li *ngFor="let t of tabs; let i = index" [ngClass]="{selected: t.selected && !showExcludedTracks}"
            (click)="deselectTab(i)">
            <span *ngIf="t.playlistInfo != null" title="{{t.tab_str}}">{{t.playlistInfo.name}}</span>
            <a href="#" class="close_tab" (click)="closeTab(i)" *ngIf="i > 0"></a>
          </li>
          <li class="open_playlist" (click)="clickInside($event)">
            <span (click)="openPlaylists($event,false)" title="Open another SCP"></span>
            <div style="display: flex;">
              <ul [ngClass]="{opened: openPlaylistDrop}"
                style="left: auto;right: 0px;box-shadow: 2px 2px 10px #7a7a7a;">
                <li style="margin-top: 6px;"><input *ngIf="openPlaylist.users.usersArr.length > 0" type="text"
                    class="form-control" style="width: 100%;" value="{{searchStrCP}}" [(ngModel)]="searchStrCP"
                    (keyup.enter)="searchPressCPDropdown($event)">
                  <a *ngIf="!loadingCPSearch" href="#" class="clear_search_icon" (click)="clearSearchCP()"></a>
                  <img *ngIf="loadingCPSearch" src="../../assets/img/loader.gif" alt="loader" class=""
                    style="position: absolute;left: 94%;height: 25px;" />
                </li>
                <li>
                  <span [ngClass]="{opened: playlistType == 1 }" (click)="selectPlaylistType(1)">Client playlists</span>
                  <ul [ngClass]="{opened: playlistType == 1 }">
                    <li *ngFor="let cpu of openPlaylist.users.usersArr; let i = index; trackBy: identify">
                      <span
                        *ngIf=" (searchStrCP == '' && filteredClientIDsCP.length == 0 ) || (filteredClientIDsCP.length > 0 && filteredClientIDsCP.indexOf(cpu.id) > -1)"
                        (click)="getPlaylistInfo(1,cpu.id, i)"
                        [ngClass]="{opened: openPlaylist.users.id == cpu.id }">{{cpu.first_name}}</span>
                      <ul [ngClass]="{opened: openPlaylist.users.id == cpu.id }">
                        <li *ngFor="let cps of openPlaylist.sites.sitesArr; index as j; trackBy: identify">
                          <span
                            *ngIf=" (searchStrCP == '' && filteredParentIDsCP.length == 0 ) || (filteredParentIDsCP.length > 0 && filteredParentIDsCP.indexOf(cps.id) > -1)"
                            (click)="getPlaylistInfo(2,cps.id, j)"
                            [ngClass]="{opened: openPlaylist.sites.id == cps.id }">{{cps.name}}</span>
                          <ul *ngIf="cps.children!=undefined" [ngClass]="{opened: openPlaylist.sites.id == cps.id }">
                            <!-- *ngIf="cps.children!=undefined"  cps.children -->
                            <li *ngFor="let cpc of cps.children; let k = index; trackBy: identify">
                              <span
                                *ngIf="(searchStrCP == '' && filteredChildIDsCP.length == 0 ) || (filteredChildIDsCP.length > 0 && filteredChildIDsCP.indexOf(cpc.id) > -1)"
                                (click)="getPlaylistInfo(3,cpc.id, k)"
                                [ngClass]="{opened: openPlaylist.channels.id == cpc.id }">{{cpc.name}}</span>
                              <!-- <ul [ngClass]="{opened: openPlaylist.channels.id == cpc.id }" id="playlist-ul"> -->
                              <!-- <li *ngFor="let cp of openPlaylist.playlists.playlistsArr; let l = index; trackBy: identify">
                                  <span 
                                  *ngIf="searchStrCP == '' || ( searchStrCP != '' && filteredChildIDsCP.length > 0 && cp.name.toLowerCase().includes(searchStrCP.toLowerCase()))"
                                    (click)="getPlaylistInfo(4,cp.id)">{{cp.name}}</span> -->
                              <!-- *ngIf="searchStrCP == '' || ( searchStrCP != '' && filteredChildIDsCP.length > 0 && cp.name.toLowerCase().includes(searchStrCP.toLowerCase()))" -->
                              <!-- </li> -->
                              <!-- </ul> -->
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li>
                  <span [ngClass]="{opened: playlistType == 2 }" (click)="selectPlaylistType(2)">Foundation
                    playlists</span>
                  <ul [ngClass]="{opened: playlistType == 2 }">
                    <li *ngFor="let fpc of openFPPlaylist.category.catsArr">
                      <span
                        *ngIf=" (searchStrCP == '' && filteredParentIDsFP.length == 0 ) || (filteredParentIDsFP.length > 0 && filteredParentIDsFP.indexOf(fpc.id) > -1)"
                        [ngClass]="{opened: openFPPlaylist.category.id == fpc.id }"
                        (click)="getFPPlaylistInfo(1,fpc.id)">{{fpc.name}}</span>
                      <ul *ngIf="fpc.children!=undefined" [ngClass]="{opened: openFPPlaylist.category.id == fpc.id }">
                        <li *ngFor="let fpsc of fpc.children">
                          <span
                            *ngIf="(searchStrCP == '' && filteredChildIDsFP.length == 0 ) || (filteredChildIDsFP.length > 0 && filteredChildIDsFP.indexOf(fpsc.id) > -1)"
                            (click)="getFPPlaylistInfo(2,fpsc.id)"
                            [ngClass]="{opened: openFPPlaylist.subcategory.id == fpsc.id }">{{fpsc.name}}</span>
                          <ul [ngClass]="{opened: openFPPlaylist.subcategory.id == fpsc.id }">
                            <li *ngFor="let fp of openFPPlaylist.playlists.playlistsArr">
                              <span
                                *ngIf="searchStrCP == '' || ( searchStrCP != '' && filteredChildIDsFP.length > 0 && fp.name.toLowerCase().includes(searchStrCP.toLowerCase()))"
                                (click)="getFPPlaylistInfo(3,fp.id)">{{fp.name}}</span>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>


              </ul>
              <ul [ngClass]="{opened: showPlaylists && openPlaylistDrop}"
                style="box-shadow: 2px 2px 10px #7a7a7a;left: 40px;top: 40px;"
                *ngIf="openPlaylist.playlists.playlistsArr.length > 0">
                <li style="background: #7a7a7a;color: white;"><span [ngClass]="{opened: true }">Playlists</span></li>
                <li *ngFor="let cp of openPlaylist.playlists.playlistsArr; let l = index;">
                  <span
                    *ngIf="searchStrCP == '' || ( searchStrCP != '' && filteredChildIDsCP.length > 0 && cp.name.toLowerCase().includes(searchStrCP.toLowerCase()))"
                    (click)="getPlaylistInfo(4,cp.id)">{{cp.name}}</span>

                </li>
              </ul>
            </div>
          </li>
          <li [ngClass]="{selected: showExcludedTracks}" (click)="onclickExcludedTracks()">
            <span>Excluded Tracks</span>
          </li>
        </ul>
        <div *ngIf="!showExcludedTracks">
          <div class="tab_content" *ngFor="let t of tabs; let i = index" [ngClass]="{selected: t.selected}" dndDropzone
            (dndDrop)="onDrop(t.playlistInfo.id)">
            <div class="info_box">
              <div class="info">BPM: <strong>{{t.averageBpm}}</strong></div>
              <div class="info" *ngIf="t.playlistInfo!=null">Last Autosave:
                <em>{{lastAutosave(t.playlistInfo.updated_sec)}}</em> ago
              </div>
              <div class="info" *ngIf="t.playlistInfo!=null">Current Status:
                <strong>{{playlistStatusText[t.playlistInfo.status]}}</strong>
              </div>
              <a href="#" class="playlist_restore" (click)="openPlaylistRestorePopup(t.playlistInfo.id)"></a>
              <div class="info" *ngIf="t.playlistInfo!=null">Tracks duration:
                <strong>{{convertTime(t.playlistInfo.total_track_duration)}}</strong>
              </div>
              <div class="info" *ngIf="t!=null">#Tracks: <strong>{{t.tracks.length}}</strong></div>
            </div>
            <div class="info_box bottom">

            </div>
            <div class="table_wrap playlist_tracks">
              <div class="table_titles">
                <div class="order order-empty-div">
                </div>
                <div class="order" (click)="setOrder('mc_tags')"
                  [ngClass]="{active: tabs[selectedTabs.rightTabIndex].orderBy=='mc_tags',asc:tabs[selectedTabs.rightTabIndex].order=='ASC'}">
                  MC Tag</div>
                <div class="order" (click)="setOrder('title')"
                  [ngClass]="{active: tabs[selectedTabs.rightTabIndex].orderBy=='title',asc:tabs[selectedTabs.rightTabIndex].order=='ASC'}">
                  Name</div>
                <div class="order" (click)="setOrder('artist')"
                  [ngClass]="{active: tabs[selectedTabs.rightTabIndex].orderBy=='artist',asc:tabs[selectedTabs.rightTabIndex].order=='ASC'}">
                  Artist</div>
                <div class="order" (click)="setOrder('bpm')"
                  [ngClass]="{active: tabs[selectedTabs.rightTabIndex].orderBy=='bpm',asc:tabs[selectedTabs.rightTabIndex].order=='ASC'}">
                  BPM</div>

                <div class="order" (click)="setOrder('add_date')"
                  [ngClass]="{active: tabs[selectedTabs.rightTabIndex].orderBy=='add_date',asc:tabs[selectedTabs.rightTabIndex].order=='ASC'}">
                  Date Added</div>

                <div>Tag</div>
                <div>Action</div>
              </div>
              <div class="table_content" *ngIf="t.tracks.length > 0">
                <div class="table_row" [dndDraggable]="draggableRemove.data"
                  [dndEffectAllowed]="draggableRemove.effectAllowed" [dndDisableIf]="draggableRemove.disable"
                  (dndStart)="removeDragTrack(pt.pivot_id)" *ngFor="let pt of t.tracks; let j = index"
                  (click)="selectPlaylistRow($event,pt.pivot_id,i,j)"
                  [ngClass]="{selected: checkSelectedRow( tabs[i].selectedRows, pt.id, pt.pivot_id ) || pt.excluded}"
                  [ngStyle]="setBgFromTag(pt.tags)" #wrap>
                  <input type="checkbox" (change)="onClickCPCheckbox(pt.pivot_id,$event.target.checked,wrap,i,j)"
                    [checked]="pt.excluded">
                  <div style="position: relative;">
                    <span style="border-radius: 25px;
                    padding: 1px 5px;
                    position: absolute;
                    left: -3px;
                    top: -8px;
                    background: #ba975c;
                    text-shadow: none;
                    color: white;
                    width: 17px;
                    height: 17px;" *ngIf="pt.retained=='1'">R</span>
                    <em>{{pt.mc_tags}}</em>
                  </div>
                  <div><strong>{{pt.title}}</strong></div>
                  <div><em>{{pt.artist}}</em></div>
                  <div><em>{{pt.bpm}}</em></div>

                  <div>{{pt.add_date}}</div>

                  <div class="track_tags"><span *ngFor="let ptt of pt.tags">{{ptt.name}}<span class="comma">,
                      </span></span></div>
                  <div class="func_col" [ngClass]="{'disableDiv': pt.excluded}">
                    <a href="#" class="track_info" (click)="showEdit(pt.id,pt.pivot_id,1)" title="Detail"></a>
                    <a href="#"
                      [ngClass]="playerInfo.trackId == pt.id && playerInfo.trackIndex == j && playerInfo.isPlaying  == 'play' ? 'track_paused active' : 'track_play active'"
                      (click)="playTrack( pt.id, pt.file_name,j, pt.album, pt.artist,pt.title,pt.storage)"
                      title="Play"></a>
                    <a href="#" class="remove_track" (click)="openConfirmPopup(pt.pivot_id,0)" title="Delete"
                      *ngIf="current_user!=null&&(current_user.type=='Consultant'||current_user.type=='Administrator')"></a>
                    <span
                      *ngIf="playerInfo.trackId == pt.id && playerInfo.trackIndex == j && playerInfo.isPlaying  == 'play'"
                      class="volume"></span>
                  </div>

                </div>
                <div class="table_content nf" *ngIf="t.tracks.length <= 0">
                  <span>No results found</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="showExcludedTracks">
          <div class="tab_content selected">
            <div class="info_box"></div>
            <div class="info_box bottom"></div>
            <div class="table_wrap playlist_tracks">
              <div class="table_titles">
                <div class="order order-empty-div"></div>
                <div class="order w20" (click)="setOrderET('playlist_name')">
                  Playlist</div>
                <div class="order w20" (click)="setOrderET('playlist_name')">
                  Playlist Type</div>
                <div class="order" (click)="setOrderET('title')">
                  Name</div>
                <div class="order" (click)="setOrderET('excluded_by')">
                  Excluded By</div>
                <div class="order" (click)="setOrderET('excluded_at')">
                  Excluded At</div>
                <div></div>

              </div>
              <div class="table_content">
                <div class="table_row" [dndDraggable]="draggableRemove.data"
                  [dndEffectAllowed]="draggableRemove.effectAllowed" [dndDisableIf]="draggableRemove.disable"
                  (dndStart)="removeDragTrack(et.pivot_id)" *ngFor="let et of excludedTracks; let j = index"
                  [ngStyle]="setBgFromTag(et.tags)" #wrap1>

                  <div></div>
                  <div class="w20"><strong>{{et.playlist_name}}</strong></div>
                  <div class="w20"><strong>{{et.playlist_type}}</strong></div>
                  <div><strong>{{et.title}}</strong></div>
                  <div>{{et.excluded_by}}</div>
                  <div>{{et.excluded_at}}</div>
                  <div></div>

                  <div>{{et.album}}</div>
                  <div>{{et.add_date}}</div>
                  <div><em>{{et.bpm}}</em></div>
                  <div class="track_tags"><span *ngFor="let ett of et.tags">{{ett.name}}<span class="comma">,
                      </span></span>
                  </div> -->

                </div>
                <div class="table_content nf" *ngIf="excludedTracks.length <= 0">
                  <span>No results found</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="popup track_info_popup" *ngIf="current_user!=null&&current_user.type=='Technician'"
  [ngClass]="{opened: popups.editPopupOpened}" ngDraggable ngResizable [rzHandles]="'n,e,s,w,se,sw,ne,nw'"
  [handle]="dragHandle">
  <a href="#" class="popup_close" (click)="popupClose()"></a>
  <div class="popup_title" #dragHandle>File Information</div>
  <div class="popup_info" *ngIf="trackInfo!=undefined">
    <img src="{{trackInfo.data.storage}}/waves/{{trackInfo.data.id}}.png" alt="">
    <div class="left_info">
      <div class="info_wrap">Title: {{trackInfo.data.title}}</div>
      <div class="info_wrap">Rating: {{trackInfo.data.rating!=null ? trackInfo.data.rating : '0'}}</div>
      <div class="info_wrap">Contributing Artists:
        {{trackInfo.data.contribute_artist!=null ? trackInfo.data.contribute_artist.name : ''}}</div>
      <div class="info_wrap">Album: {{trackInfo.data.album!=null ? trackInfo.data.album.name : ''}}</div>
      <div class="info_wrap">Project: {{trackInfo.data.genre!=null ? trackInfo.data.genre.name : ''}}</div>
      <div class="info_wrap">Length: {{trackInfo.data.length_time}}</div>
      <div class="info_wrap">Start: {{trackInfo.data.start}}</div>
      <div class="info_wrap">Highestchartpos: {{trackInfo.data.highestchartpos}}</div>
      <div class="info_wrap">Composers: {{trackInfo.data.composer!=null ? trackInfo.data.composer.name : ''}}</div>
      <div class="info_wrap">Mood: {{trackInfo.data.mood!=null ? trackInfo.data.mood.name : ''}}</div>
      <div class="info_wrap">Publisher: {{trackInfo.data.publisher!=null ? trackInfo.data.publisher.name : ''}}</div>
      <div class="info_wrap">Additional attributes: <span *ngFor="let a of additional">{{a}} </span></div>
    </div>
    <div class="right_info">
      <div class="info_wrap">Sub Title: {{trackInfo.data.sub_title}}</div>
      <div class="info_wrap">Comments: {{trackInfo.data.comments}}</div>
      <div class="info_wrap">Album Artist: {{trackInfo.data.artist!=null ? trackInfo.data.artist.name : ''}}</div>
      <div class="info_wrap">Year: {{trackInfo.data.year}}</div>
      <div class="info_wrap">Category: {{trackInfo.data.track_category!=null ? trackInfo.data.track_category.name : ''}}
      </div>
      <div class="info_wrap">Bitrate: {{trackInfo.data.bitrate_encoded}}</div>
      <div class="info_wrap">Finish: {{trackInfo.data.finish}}</div>
      <div class="info_wrap">Conductors: {{trackInfo.data.conductor!=null ? trackInfo.data.conductor.name : ''}}</div>
      <div class="info_wrap">BPM: {{trackInfo.data.bpm}}</div>
      <div class="info_wrap">Copyright: {{trackInfo.data.copyright}}</div>
      <div class="info_wrap" *ngIf="trackInfo.from_fp!=null">Sourced: {{trackInfo.from_fp.name}}</div>
    </div>
    <div class="popup_btns">
      <button type="button" class="cancel" (click)="popupClose()">Cancel</button>
    </div>
  </div>
</div>
<div *ngIf="current_user!=null&&(current_user.type=='Consultant'||current_user.type=='Administrator')"
  class="popup edit_track_popup" [ngClass]="{opened: popups.editPopupOpened}" ngDraggable ngResizable
  [rzHandles]="'n,e,s,w,se,sw,ne,nw'" [handle]="dragHandle2">
  <a href="#" class="popup_close" (click)="popupClose()"></a>
  <div class="popup_title" #dragHandle2>File Information</div>
  <div class="popup_form" *ngIf="trackInfo!=undefined">
    <form #updateTrackForm="ngForm">
      <input type="hidden" name="id" value="{{trackInfo.data.id}}" [(ngModel)]="trackInfo.data.id">
      <div class="left_form">
        <div class="input_wrap">
          <label>Title</label>
          <input name="title" [disabled]="disabledInputs.title ? true : null" type="text"
            value="{{trackInfo.data.title}}" [(ngModel)]="trackInfo.data.title" (focus)="focusInput()"
            (blur)="blurInput()">
        </div>
        <div class="input_wrap">
          <label>UID</label>
          <input name="uid" [readonly]="true" type="text" value="{{trackInfo.data.uid}}">
        </div>
        <div class="input_wrap">
          <label>Rating</label>
          <select name="rating" [(ngModel)]="trackInfo.data.rating">
            <option value="null" [selected]="trackInfo.data.rating==null">Select rating</option>
            <option value="1" [selected]="trackInfo.data.rating=='1'">1</option>
            <option value="2" [selected]="trackInfo.data.rating=='2'">2</option>
            <option value="3" [selected]="trackInfo.data.rating=='3'">3</option>
            <option value="4" [selected]="trackInfo.data.rating=='4'">4</option>
            <option value="5" [selected]="trackInfo.data.rating=='5'">5</option>
          </select>
        </div>
        <div class="input_wrap">
          <label>Contributing Artists</label>
          <app-input-autocomplete
            [inputVal]="trackInfo.data.contribute_artist!=null ? trackInfo.data.contribute_artist.name : ''"
            [inputName]="'contribute_artist'" [queryData]="'contribute_artist'" (focus)="autoFocus($event)">
          </app-input-autocomplete>
        </div>
        <div class="input_wrap">
          <label>Album</label>
          <app-input-autocomplete [disabled]="disabledInputs.album ? true : null"
            [inputVal]="trackInfo.data.album!=null ? trackInfo.data.album.name : ''" [inputName]="'album'"
            [queryData]="'album'" (focus)="autoFocus($event)">
          </app-input-autocomplete>
        </div>
        <div class="input_wrap">
          <label>Project</label>
          <app-input-autocomplete [inputVal]="trackInfo.data.genre!=null ? trackInfo.data.genre.name : ''"
            [inputName]="'genre'" [queryData]="'genre'" (focus)="autoFocus($event)">
          </app-input-autocomplete>
        </div>
        <div class="input_wrap">
          <label>Length</label>
          <input type="text" value="{{trackInfo.data.length_time}}" disabled="disabled" (focus)="focusInput()"
            (blur)="blurInput()">
        </div>
        <div class="input_wrap">
          <label>Start</label>
          <input name="start" type="text" value="{{trackInfo.data.start}}" [(ngModel)]="trackInfo.data.start"
            (focus)="focusInput()" (blur)="blurInput()">
        </div>
        <div class="input_wrap">
          <label>Highest Chart Pos</label>
          <input name="highestchartpos" type="text" value="{{trackInfo.data.highestchartpos}}"
            [(ngModel)]="trackInfo.data.highestchartpos" (focus)="focusInput()" (blur)="blurInput()">
        </div>
        <div class="input_wrap">
          <label>Composers</label>
          <app-input-autocomplete [inputVal]="trackInfo.data.composer!=null ? trackInfo.data.composer.name : ''"
            [inputName]="'composer'" [queryData]="'composer'" (focus)="autoFocus($event)">
          </app-input-autocomplete>
        </div>
        <div class="input_wrap">
          <label>Mood</label>
          <app-input-autocomplete [inputVal]="trackInfo.data.mood!=null ? trackInfo.data.mood.name : ''"
            [inputName]="'mood'" [queryData]="'mood'" (focus)="autoFocus($event)">
          </app-input-autocomplete>
        </div>
        <div class="input_wrap">
          <label>Publisher</label>
          <app-input-autocomplete [inputVal]="trackInfo.data.publisher!=null ? trackInfo.data.publisher.name : ''"
            [inputName]="'publisher'" [queryData]="'publisher'" (focus)="autoFocus($event)">
          </app-input-autocomplete>
        </div>
        <div class="input_wrap" (click)="focusInput()">
          <label>Additional attributes</label>
          <ng-multiselect-dropdown [placeholder]="'MC Tags'" [settings]="dropdownSettings" [data]="mcTagsList"
            [(ngModel)]="selectedMCTags" name="mc_tags" class="mc_tag"></ng-multiselect-dropdown>

        </div>

      </div>
      <div class="right_form">
        <div class="input_wrap">
          <label>Sub Title</label>
          <input name="sub_title" type="text" value="{{trackInfo.data.sub_title}}"
            [(ngModel)]="trackInfo.data.sub_title" (focus)="focusInput()" (blur)="blurInput()">
        </div>
        <div class="input_wrap">
          <label>File name</label>
          <input name="file_name" [readonly]="true" type="text" value="{{trackInfo.data.file_name}}">
        </div>
        <div class="input_wrap">
          <label>Comments</label>
          <input name="comments" type="text" value="{{trackInfo.data.comments}}" [(ngModel)]="trackInfo.data.comments"
            (focus)="focusInput()" (blur)="blurInput()">
        </div>
        <div class="input_wrap">
          <label>Album Artist</label>
          <app-input-autocomplete [disabled]="disabledInputs.artist ? true : null"
            [inputVal]="trackInfo.data.artist!=null ? trackInfo.data.artist.name : ''" [inputName]="'artist'"
            [queryData]="'artist'" (focus)="autoFocus($event)">
          </app-input-autocomplete>
        </div>
        <div class="input_wrap">
          <label>Year</label>
          <input name="year" [disabled]="disabledInputs.year ? true : null" type="text" value="{{trackInfo.data.year}}"
            [(ngModel)]="trackInfo.data.year" (focus)="focusInput()" (blur)="blurInput()">
        </div>
        <div class="input_wrap">
          <label>Category</label>
          <app-input-autocomplete
            [inputVal]="trackInfo.data.track_category!=null ? trackInfo.data.track_category.name : ''"
            [inputName]="'track_category'" [queryData]="'track_category'" (focus)="autoFocus($event)">
          </app-input-autocomplete>
        </div>
        <div class="input_wrap">
          <label>Bitrate</label>
          <input type="text" value="{{trackInfo.data.bitrate_encoded}}" disabled="disabled" (focus)="focusInput()"
            (blur)="blurInput()">
        </div>
        <div class="input_wrap">
          <label>Finish</label>
          <input name="finish" type="text" value="{{trackInfo.data.finish}}" [(ngModel)]="trackInfo.data.finish"
            (focus)="focusInput()" (blur)="blurInput()">
        </div>
        <div class="input_wrap">
          <label>Conductors</label>
          <app-input-autocomplete [inputVal]="trackInfo.data.conductor!=null ? trackInfo.data.conductor.name : ''"
            [inputName]="'conductor'" [queryData]="'conductor'" (focus)="autoFocus($event)">
          </app-input-autocomplete>
        </div>
        <div class="input_wrap">
          <label>BPM</label>
          <input name="bpm" pattern="[0-9]*" type="text" value="{{trackInfo.data.bpm}}" [(ngModel)]="trackInfo.data.bpm"
            (focus)="focusInput()" (blur)="blurInput()">
        </div>
        <div class="input_wrap">
          <label>Copyright</label>
          <input name="copyright" type="text" value="{{trackInfo.data.copyright}}"
            [(ngModel)]="trackInfo.data.copyright" (focus)="focusInput()" (blur)="blurInput()">
        </div>
        <div class="input_wrap">
          <label>Encoded file name</label>
          <input type="text" value="{{trackInfo.data.file_name_altered_id}}" disabled="disabled">
        </div>
        <div class="input_wrap with_list">
          <label>Clients assigned track</label>
          <ul [ngClass]="{opened: drops.clients}" (click)="openDrop(1)">
            <li *ngFor="let cas of trackInfo.cp_lists_with_this_track">{{cas.name}}</li>
          </ul>
        </div>
        <div class="input_wrap with_list">
          <label>FPs assigned track</label>
          <ul [ngClass]="{opened: drops.fp}" (click)="openDrop(2)">
            <li *ngFor="let fas of trackInfo.fp_lists_with_this_track">{{fas.name}}</li>
          </ul>
        </div>
        <div class="input_wrap">
          <label>Wave form</label>
          <img src="{{trackInfo.data.storage}}/waves/{{trackInfo.data.id}}.png" alt="track wave" style="width:350px">
        </div>
        <div class="input_wrap" *ngIf="trackInfo.from_fp!=null"><label>Sourced</label><input disabled="disabled"
            type="text" value="{{trackInfo.from_fp.name}}" /></div>
      </div>
      <div class="popup_btns">
        <a href="#" class="btn prev" (click)="getPrevTrackInfo()">Prev</a>
        <a href="#" class="btn next" (click)="getNextTrackInfo()">Next</a>
        <button type="button" class="delete" (click)="openConfirmPopup(trackInfo.data.id,2)"></button>
        <button type="button" class="cancel" (click)="popupClose()">Cancel</button>
        <button type="submit" (click)="trackDataUpdate(updateTrackForm)">Update</button>
      </div>
    </form>
  </div>
</div>
<div class="popup smart_filter_popup" [ngClass]="{opened: popups.smartFilterPopupOpened}" ngDraggable ngResizable
  [rzHandles]="'n,e,s,w,se,sw,ne,nw'" [handle]="dragHandle3">
  <a href="#" class="popup_close" (click)="popupClose()"></a>
  <div class="popup_title" #dragHandle3>Smart Filter - {{ selectedTabname }}</div>
  <div class="filter_inner">
    <div class="top_filter">
      Match
      <select [(ngModel)]="matchSelect">
        <option value="all">All</option>
        <option value="any">Any</option>
        <option value="none">None</option>
      </select>
      of the following rules:

      <span style="float: right;" *ngIf="profileFilterCount > 1">
        <select [(ngModel)]="andOrSelect">
          <option value="and">AND</option>
          <option value="or">OR</option>
        </select>
      </span>
    </div>

    <div class="filter_line" *ngFor="let filter of smartFilters[selectedTabs.leftTabIndex];let i = index">
      <div class="left_col">
        <select (change)="changeSmartSelect($event,i)" [disabled]="filter.isHidden"
          [(ngModel)]="smartFilters[selectedTabs.leftTabIndex][i].categorySelect">
          <option value="null">Select...</option>
          <option *ngFor="let sf of defaultSmartFilters[selectedTabs.leftTabIndex]" value="{{sf.field}}">
            {{sf.title}}
          </option>
        </select>
      </div>
      <div class="middle_col">
        <select *ngIf="filter.compare!=null" [(ngModel)]="smartFilters[selectedTabs.leftTabIndex][i].compareSelect">
          <option value="null">Select...</option>
          <option *ngFor="let c of filter.compare" value="{{c}}">{{c}}</option>
        </select>
      </div>
      <div class="right_col"
        *ngIf="smartFilters[selectedTabs.leftTabIndex][i].categorySelect == 'mc_tags'; else checkCpDr">
        <select [(ngModel)]="smartFilters[selectedTabs.leftTabIndex][i].field">
          <option value="null">Select...</option>
          <option *ngFor="let tag of mcTagsList" value="{{tag.item_text}}">{{tag.item_text}}</option>
        </select>
      </div>
      <ng-template #checkCpDr>
        <div class="right_col"
          *ngIf="smartFilters[selectedTabs.leftTabIndex][i].categorySelect == 'foundation_play_list_id'; else default">
          <select [(ngModel)]="smartFilters[selectedTabs.leftTabIndex][i].field">
            <option value="null">Select...</option>
            <option *ngFor="let tag of cpPlaylistList" value="{{tag.item_text}}">{{tag.item_text}}</option>
          </select>
        </div>
      </ng-template>
      <ng-template #default>
        <div class="right_col" *ngIf="filter.type=='str'">
          <input type="text" value="" [(ngModel)]="smartFilters[selectedTabs.leftTabIndex][i].field" />
        </div>
        <div class="right_col" *ngIf="filter.type=='date'">
          <my-date-picker name="date1" [options]="myDatePickerOptions" placeholder="dd/mm/yyyy"
            [(ngModel)]="smartFilters[selectedTabs.leftTabIndex][i].arrFields[0]"></my-date-picker>
          <my-date-picker name="date1" [options]="myDatePickerOptions" placeholder="dd/mm/yyyy"
            [(ngModel)]="smartFilters[selectedTabs.leftTabIndex][i].arrFields[1]"></my-date-picker>
        </div>
        <div class="right_col" *ngIf="filter.type=='int'">
          <input type="number" class="number" value=""
            [(ngModel)]="smartFilters[selectedTabs.leftTabIndex][i].arrFields[0]" />
          <input type="number" class="number" value=""
            [(ngModel)]="smartFilters[selectedTabs.leftTabIndex][i].arrFields[1]"
            *ngIf="smartFilters[selectedTabs.leftTabIndex][i].compareSelect=='between'" />
        </div>
      </ng-template>
      <div class="func_col">
        <a href="#" *ngIf="smartFilters[selectedTabs.leftTabIndex].length>1" class="btn_remove"
          (click)="removeFilterField(i)"></a>
        <a href="#" class="btn_add" (click)="addFilterField()"></a>
      </div>
    </div>

    <div class="popup_btns">
      <button type="button" class="cancel" (click)="openConvertPopup()"
        *ngIf="current_user!=null&&(current_user.type=='Consultant'||current_user.type=='Administrator')">Convert to
        playlist</button>
      <button type="button" class="cancel" (click)="clearFilter()">Clear Filter</button>
      <button type="button" class="cancel" (click)="popupClose()">Cancel</button>
      <button type="submit" class="submit" (click)="smartSearch(true)">Search</button>
    </div>
  </div>
</div>
<div class="popup tag_manager_popup" [ngClass]="{opened: popups.manageTagsPopupOpened}" ngDraggable ngResizable
  [rzHandles]="'n,e,s,w,se,sw,ne,nw'" [handle]="dragHandle4">
  <a href="#" class="popup_close" (click)="popupClose()"></a>
  <div class="popup_title" #dragHandle4>Tag Manager</div>
  <div class="tag_manager_inner">
    <div class="tags_column">
      <ul>
        <li *ngIf="tags[0]!=undefined">
          <span class="btn">1</span>
          <input type="text" value="{{tags[0].name}}" [(ngModel)]="tags[0].name" />
          <input [value]="tags[0].color" [(colorPicker)]="tags[0].color" (colorPickerChange)="changeBg( 0)"
            [(style.background)]="tags[0].bgColor" class="colorpicker" />
          <a [routerLink]="[]" class="clear_values" (click)="clearTags(0)">X</a>
        </li>
        <li *ngIf="tags[1]!=undefined">
          <span class="btn">2</span>
          <input type="text" value="{{tags[1].name}}" [(ngModel)]="tags[1].name" />
          <input [value]="tags[1].color" [(colorPicker)]="tags[1].color" (colorPickerChange)="changeBg( 1 )"
            [(style.background)]="tags[1].bgColor" class="colorpicker" />
          <a [routerLink]="[]" class="clear_values" (click)="clearTags(1)">X</a>
        </li>
        <li *ngIf="tags[2]!=undefined">
          <span class="btn">3</span>
          <input type="text" value="{{tags[2].name}}" [(ngModel)]="tags[2].name" />
          <input [value]="tags[2].color" [(colorPicker)]="tags[2].color" (colorPickerChange)="changeBg( 2)"
            [(style.background)]="tags[2].bgColor" class="colorpicker" />
          <a [routerLink]="[]" class="clear_values" (click)="clearTags(2)">X</a>
        </li>
        <li *ngIf="tags[3]!=undefined">
          <span class="btn">4</span>
          <input type="text" value="{{tags[3].name}}" [(ngModel)]="tags[3].name" />
          <input [value]="tags[3].color" [(colorPicker)]="tags[3].color" (colorPickerChange)="changeBg( 3 )"
            [(style.background)]="tags[3].bgColor" class="colorpicker" />
          <a [routerLink]="[]" class="clear_values" (click)="clearTags(3)">X</a>
        </li>
        <li *ngIf="tags[4]!=undefined">
          <span class="btn">5</span>
          <input type="text" value="{{tags[4].name}}" [(ngModel)]="tags[4].name" />
          <input [value]="tags[4].color" [(colorPicker)]="tags[4].color" (colorPickerChange)="changeBg( 4 )"
            [(style.background)]="tags[4].bgColor" class="colorpicker" />
          <a [routerLink]="[]" class="clear_values" (click)="clearTags(4)">X</a>
        </li>
      </ul>
    </div>
    <div class="tags_column">
      <ul>
        <li *ngIf="tags[5]!=undefined">
          <span class="btn">6</span>
          <input type="text" value="{{tags[5].name}}" [(ngModel)]="tags[5].name" />
          <input [value]="tags[5].color" [(colorPicker)]="tags[5].color" (colorPickerChange)="changeBg( 5 )"
            [(style.background)]="tags[5].bgColor" class="colorpicker" />
          <a [routerLink]="[]" class="clear_values" (click)="clearTags(5)">X</a>
        </li>
        <li *ngIf="tags[6]!=undefined">
          <span class="btn">7</span>
          <input type="text" value="{{tags[6].name}}" [(ngModel)]="tags[6].name" />
          <input [value]="tags[6].color" [(colorPicker)]="tags[6].color" (colorPickerChange)="changeBg( 6 )"
            [(style.background)]="tags[6].bgColor" class="colorpicker" />
          <a [routerLink]="[]" class="clear_values" (click)="clearTags(6)">X</a>
        </li>
        <li *ngIf="tags[7]!=undefined">
          <span class="btn">8</span>
          <input type="text" value="{{tags[7].name}}" [(ngModel)]="tags[7].name" />
          <input [value]="tags[7].color" [(colorPicker)]="tags[7].color" (colorPickerChange)="changeBg( 7 )"
            [(style.background)]="tags[7].bgColor" class="colorpicker" />
          <a [routerLink]="[]" class="clear_values" (click)="clearTags(7)">X</a>
        </li>
        <li *ngIf="tags[8]!=undefined">
          <span class="btn">9</span>
          <input type="text" value="{{tags[8].name}}" [(ngModel)]="tags[8].name" />
          <input [value]="tags[8].color" [(colorPicker)]="tags[8].color" (colorPickerChange)="changeBg( 8 )"
            [(style.background)]="tags[8].bgColor" class="colorpicker" />
          <a [routerLink]="[]" class="clear_values" (click)="clearTags(8)">X</a>
        </li>
        <li *ngIf="tags[9]!=undefined">
          <span class="btn">0</span>
          <input type="text" value="{{tags[9].name}}" [(ngModel)]="tags[9].name" />
          <input [value]="tags[9].color" [(colorPicker)]="tags[9].color" (colorPickerChange)="changeBg( 9 )"
            [(style.background)]="tags[9].bgColor" class="colorpicker" />
          <a [routerLink]="[]" class="clear_values" (click)="clearTags(9)">X</a>
        </li>
      </ul>
    </div>
    <div class="popup_btns">
      <button type="button" class="cancel" (click)="popupClose()">Cancel</button>
      <button type="submit" class="submit" (click)="updateTags()"
        *ngIf="current_user!=null&&(current_user.type=='Consultant'||current_user.type=='Administrator')">Save</button>
    </div>
  </div>
</div>
<div class="popup other_playlists_popup" [ngClass]="{opened: popups.otherPlaylistsPopupOpened}" ngDraggable ngResizable
  [rzHandles]="'n,e,s,w,se,sw,ne,nw'" [handle]="dragHandle5">
  <a href="#" class="popup_close" (click)="popupClose()"></a>
  <div class="popup_title" #dragHandle5>Open Channel</div>
  <div class="other_playlists_inner">
    <ul *ngIf="similarPlaylists.length!=0 && similarPlaylists!=null">
      <li *ngFor="let sp of similarPlaylists" (click)="addTab(sp.id)">{{sp.name}}</li>
    </ul>
    <div class="popup_btns">
      <button type="button" class="cancel" (click)="popupClose()">Cancel</button>
    </div>
  </div>
</div>

<app-player-component [isInputFocused]="isFocused" [storage]="playerInfo.storage" [token]="playerInfo.token"
  [track]="playerInfo.trackId" [wave]="playerInfo.trackId" [album]="playerInfo.album" [artist]="playerInfo.artist"
  (outputPlaying)="checkPlaying($event)" (next)="nextTrack($event)" (prev)="prevTrack($event)"></app-player-component>

<div class="popup create_playlist_popup" [ngClass]="{opened: popups.playlistPopupOpened}" ngDraggable ngResizable
  [rzHandles]="'n,e,s,w,se,sw,ne,nw'" [handle]="dragHandle6">
  <a href="#" class="popup_close" (click)="popupClose()"></a>
  <div class="popup_title" #dragHandle6>Create/Edit Playlist</div>
  <div class="popup_form">
    <form #updateSamplePlaylist="ngForm">
      <input type="hidden" name="status" value="{{playlistFields.status}}" [(ngModel)]="playlistFields.status">
      
      <div class="input_wrap">
        <label>Name</label>
        <input autocomplete="off" type="text" name="name" value="{{playlistFields.name}}"
          [(ngModel)]="playlistFields.name" />
      </div>
      <div class="input_wrap">
        <label>Category</label>
        <select name="client_id" *ngIf="categoriesList!=null" [(ngModel)]="playlistFields.client_id"
          (change)="changeCategory($event)">
          <option *ngFor="let c of categoriesList" value="{{c.id}}" [selected]="playlistFields.client_id == c.id">{{c.name}}
          </option>
        </select>
      </div>

      <div class="input_wrap" *ngIf="subcategoriesList!=null">
        <label>Sub-Category</label>
        <select name="site_id" [(ngModel)]="playlistFields.site_id">
          <option *ngFor="let sc of subcategoriesList" value="{{sc.id}}" [selected]="playlistFields.site_id == sc.id">
            {{sc.name}}</option>
        </select>
      </div>

      <div class="popup_btns">
        <button type="button" class="cancel" (click)="popupClose()">Cancel</button>
        <button type="submit" (click)="playlistDataUpdate(updateSamplePlaylist)">Save</button>
      </div>
    </form>
  </div>
</div>

<div class="popup convert_playlist_popup" [ngClass]="{opened: popups.convertPlaylistPopupOpened}" ngDraggable
  ngResizable [rzHandles]="'n,e,s,w,se,sw,ne,nw'" [handle]="dragHandle7">
  <a href="#" class="popup_close" (click)="popupConvertClose()"></a>
  <div class="popup_title" #dragHandle7>Convert To Playlist</div>
  <div class="popup_form">
    <div class="table_wrap convert_playlist_tracks">
      <div class="table_titles">
        <div><input type="checkbox" [(ngModel)]="selectAllConvert" name="selectAllConvert"
            (change)="changeAllConvert()" /></div>
        <div>Name</div>
        <div>Artist</div>
        <div>Album</div>
        <div>BPM</div>
        <div></div>
      </div>
      <div class="table_content">
        <div class="table_row" *ngFor="let sft of smartFilterResults; let i = index" (click)="selectCheckbox(i)">
          <div><input type="checkbox" [(ngModel)]="sft.checkbox" name="chb-{{sft.id}}" /></div>
          <div>{{sft.title}}</div>
          <div>{{sft.artist}}</div>
          <div>{{sft.album}}</div>
          <div>{{sft.bpm}}</div>
          <div>
            <a href="#" class="track_info" (click)="showEdit(sft.id, sft.id, 2)"></a>
          </div>
        </div>
      </div>
    </div>
    <div class="popup_btns">
      <button type="button" class="cancel" (click)="popupConvertClose()">Cancel</button>
      <button type="submit" class="btn_convert" (click)="convertToPlaylist()"
        [ngClass]="{enabled: checkSelectedTrack()}"
        *ngIf="current_user!=null&&(current_user.type=='Consultant'||current_user.type=='Administrator')">Convert To
        Playlist</button>
    </div>
  </div>
</div>
<div class="popup restored_playlist_popup" [ngClass]="{opened: popups.restoredPlaylistPopupOpened}" ngDraggable
  ngResizable [rzHandles]="'n,e,s,w,se,sw,ne,nw'" [handle]="dragHandle8">
  <a href="#" class="popup_close" (click)="popupClose()"></a>
  <div class="popup_title" #dragHandle8>&nbsp;</div>
  <div class="popup_form">
    <div class="table_wrap restored_playlist_table">
      <div class="table_titles">
        <div>
          <span (click)="openFilter(1)">Date</span>
          <ul *ngIf="restoredPlaylistTracks.filters != undefined && restoredPlaylistTracks.filters.date != undefined"
            [ngClass]="{active: restoredFilters.activeTab == 1}">
            <li *ngFor="let rfd of restoredPlaylistTracks.filters.date" (click)="addFilter(rfd,1)"
              [ngClass]="{active: checkSelectedFilter(rfd)}">{{rfd}}</li>
          </ul>
        </div>
        <div>
          <span (click)="openFilter(2)">Track</span>
          <ul *ngIf="restoredPlaylistTracks.filters != undefined && restoredPlaylistTracks.filters.title != undefined"
            [ngClass]="{active: restoredFilters.activeTab == 2}">
            <li *ngFor="let rft of restoredPlaylistTracks.filters.title" (click)="addFilter(rft,2)"
              [ngClass]="{active: restoredFilters.track == rft}">{{rft}}</li>
          </ul>
        </div>
        <div>
          <span (click)="openFilter(3)">User</span>
          <ul *ngIf="restoredPlaylistTracks.filters != undefined && restoredPlaylistTracks.filters.user != undefined"
            [ngClass]="{active: restoredFilters.activeTab == 3}">
            <li *ngFor="let rfu of restoredPlaylistTracks.filters.user" (click)="addFilter(rfu,3)"
              [ngClass]="{active: restoredFilters.user == rfu}">{{rfu.replace(':',' ')}}</li>
          </ul>
        </div>
        <div>
          <span (click)="openFilter(4)">Action</span>
          <ul *ngIf="restoredPlaylistTracks.filters != undefined && restoredPlaylistTracks.filters.action != undefined"
            [ngClass]="{active: restoredFilters.activeTab == 4}">
            <li *ngFor="let rfa of restoredPlaylistTracks.filters.action" (click)="addFilter(rfa,4)"
              [ngClass]="{active: restoredFilters.actions == rfa}">
              <span *ngIf="rfa=='POST'">Track added</span>
              <span *ngIf="rfa=='DELETE'">Track removed</span>
              <span *ngIf="rfa=='PUT'">Playlist distribited</span>
            </li>
          </ul>
        </div>
      </div>
      <div class="table_content">
        <div class="table_row" *ngFor="let rpt of restoredPlaylistTracks.list">
          <div>{{rpt.created_at}}</div>
          <div>{{rpt.title}}</div>
          <div>{{rpt.first_name}} {{rpt.last_name}}</div>
          <div *ngIf="rpt.method=='POST'">Track added</div>
          <div *ngIf="rpt.method=='DELETE'">Track removed</div>
          <div *ngIf="rpt.method=='PUT'">Playlist distribited</div>
        </div>
      </div>
    </div>
    <div class="popup_btns">
      <button type="button" class="cancel" (click)="popupClose()">Close</button>
    </div>
  </div>
</div>
<div class="popup edit_track_popup profile_popup" [ngClass]="{opened: popups.profileStatementOpened}" ngDraggable
  ngResizable [rzHandles]="'n,e,s,w,se,sw,ne,nw'" [handle]="dragHandle9">
  <a href="#" class="popup_close" (click)="popupClose()"></a>
  <div class="popup_title" #dragHandle9>Profile Statement</div>
  <div class="popup_form">
    <form #profileStatement="ngForm" *ngIf="tabs[selectedTabs.rightTabIndex].playlistInfo!=null">
      <div class="left_form" style="width:45%">
        <div class="input_wrap">
          <label>Client</label>
          <label>{{tabs[selectedTabs.rightTabIndex].playlistInfo.client_name}}</label>
        </div>
        <div class="input_wrap">
          <label>Channel</label>
          <label>{{tabs[selectedTabs.rightTabIndex].playlistInfo.channel_name}}</label>
        </div>
        <div class="input_wrap">
          <label>Playlist Duration</label>

          <input type="text" name="dur" [(ngModel)]="playlist_duration_ps">
          <label> hrs</label>
        </div>
        <div class="input_wrap">
          <label>Percentage of playlist to update</label>
          <input type="text" name="perToUpt" [(ngModel)]="percentage_to_update">
          <label> %</label>
        </div>
      </div>
      <div class="right_form" style="width:45%">
        <div class="input_wrap">
          <label>Site</label>
          <label>{{tabs[selectedTabs.rightTabIndex].playlistInfo.site_name}}</label>
        </div>
        <div class="input_wrap">
          <label>Playlist</label>
          <label>{{tabs[selectedTabs.rightTabIndex].playlistInfo.name}}</label>
        </div>
        <div class="input_wrap">
          <label>Profile Name</label>
          <label>{{tabs[selectedTabs.rightTabIndex].playlistInfo.profile_name}}</label>
        </div>
      </div>
      <div class="history_botton_right">
        <button type="button" class="History" (click)="openProfileHistoryPopup()" title="History">History</button>
      </div>

      <div class="foundation_playlist_selects">
        <div class="row titles">
          <div style="width:15%">Foundation playlist</div>
          <div style="width:5%">Cover</div>
          <div style="width:7%">Ratio %</div>
          <div style="width:40%">Filter Rules</div>
          <div style="width:5%">Notes</div>
          <div style="width:4%">&nbsp;</div>
          <div style="width:12%">Available Tracks</div>
          <div style="width:12%">Future Coverage</div>
        </div>
        <div class="">
          <div class="row" *ngFor="let ps of profileStatements;let i=index">
            <div style="width:15%">
              <div class="select_fp" *ngIf="psSelects[i] != undefined">
                <span class="fp_name" (click)="psOpenDrop(i)">{{psSelects[i].title}}</span>
                <div class="custom-fp-wrap list_wrap" [ngClass]="{opened: psSelects[i].dropOpened}"
                  style="width: 630%;">
                  <ul class="custom-fp-list">
                    <li *ngFor="let fc of fpCategories" [ngClass]="{selected: psSelects[i].catId == fc.id}">
                      <span (click)="psSelectCategory(fc.id,i)">{{fc.name}}</span>
                      <ul *ngIf="fc.children.length>0" style="margin-top: 0px;">
                        <li *ngFor="let pcc of fc.children" [ngClass]="{selected: psSelects[i].subcatId == pcc.id}">
                          <span (click)="psSelectSubcategory(pcc.id,i)">{{pcc.name}}</span>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <ul class="playlists custom-fp-list-last">
                    <li *ngFor="let fp of psSelects[i].fp_arr" (click)="psSelectPlaylist(fp.id,i,fp.name)"
                      [ngClass]="{selected: psSelects[i].fp_id == fp.id}">
                      <span>{{fp.name}}</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div style="width:5%">
              <select name="cover-{{i}}" [(ngModel)]="profileStatements[i].cover">

                <option [selected]="profileStatements[i].cover==cover" *ngFor="let cover of coverOptions"
                  value="{{cover}}">
                  {{cover}}</option>
              </select>

            </div>
            <div style="width:7%">
              <select name="ratio-{{i}}" [(ngModel)]="profileStatements[i].ratio">

                <option [selected]="profileStatements[i].ratio==ratio" *ngFor="let ratio of ratioOptions"
                  value="{{ratio}}">
                  {{ratio}} %</option>
              </select>

            </div>

            <div style="width:40%">
              <div style="width: 100%;" class="filter_line"
                *ngFor="let filter of profileStatements[i].smartFiltersPS;let k = index">
                <div class="left_col">
                  <select name="categorySelect_{{i}}_{{k}}" (change)="changeSmartSelectPS($event,i,k)"
                    [disabled]="filter.isHidden" [(ngModel)]="profileStatements[i].smartFiltersPS[k].categorySelect">
                    <option value="null">Select...</option>
                    <option *ngFor="let sf of defaultSmartFiltersPS" value="{{sf.field}}">

                      {{sf.title}}</option>
                  </select>
                </div>
                <div class="middle_col">
                  <select name="compareSelect_{{i}}_{{k}}" *ngIf="filter.compare!=null"
                    [(ngModel)]="profileStatements[i].smartFiltersPS[k].compareSelect">
                    <option value="null">Select...</option>
                    <option *ngFor="let c of filter.compare" value="{{c}}">{{c}}</option>
                  </select>
                </div>
                <div class="right_col"
                  *ngIf="profileStatements[i].smartFiltersPS[k].categorySelect == 'mc_tags'; else checkCpDr">
                  <select name="defaultField_{{i}}_{{k}}" [(ngModel)]="profileStatements[i].smartFiltersPS[k].field">
                    <option value="null">Select...</option>
                    <option *ngFor="let tag of mcTagsList" value="{{tag.item_text}}">{{tag.item_text}}</option>
                  </select>
                </div>
                <ng-template #checkCpDr>
                  <div class="right_col"
                    *ngIf="profileStatements[i].smartFiltersPS[k].categorySelect == 'foundation_play_list_id'; else default">
                    <select name="fpInputField_{{i}}_{{k}}" [(ngModel)]="profileStatements[i].smartFiltersPS[k].field">
                      <option value="null">Select...</option>
                      <option *ngFor="let tag of cpPlaylistList" value="{{tag.item_text}}">{{tag.item_text}}</option>
                    </select>
                  </div>
                </ng-template>
                <ng-template #default>
                  <div class="right_col" *ngIf="filter.type=='str'">
                    <input name="strInputField_{{i}}_{{k}}" type="text" value=""
                      [(ngModel)]="profileStatements[i].smartFiltersPS[k].field" />
                  </div>
                  <div class="right_col" *ngIf="filter.type=='date'">
                    <my-date-picker name="date1_{{i}}_{{k}}" [options]="myDatePickerOptions" placeholder="dd/mm/yyyy"
                      [(ngModel)]="profileStatements[i].smartFiltersPS[k].arrFields[0]"></my-date-picker>
                    <my-date-picker name="date2_{{i}}_{{k}}" [options]="myDatePickerOptions" placeholder="dd/mm/yyyy"
                      [(ngModel)]="profileStatements[i].smartFiltersPS[k].arrFields[1]"></my-date-picker>
                  </div>
                  <div class="right_col" *ngIf="filter.type=='int'">
                    <input name="intInputField_{{i}}_{{k}}" type="number" class="number" value=""
                      [(ngModel)]="profileStatements[i].smartFiltersPS[k].arrFields[0]" />
                    <input name="intBetInputField_{{i}}_{{k}}" type="number" class="number" value=""
                      [(ngModel)]="profileStatements[i].smartFiltersPS[k].arrFields[1]"
                      *ngIf="profileStatements[i].smartFiltersPS[k].compareSelect=='between'" />
                  </div>
                </ng-template>
                <div class="func_col" style="width: 12%;">
                  <a href="#" *ngIf="profileStatements[i].smartFiltersPS.length>1" class="btn_remove"
                    (click)="removeFilterFieldPS(i,k)" style="margin-right: 3px;width:16px;height:18px;"></a>
                  <a href="#" class="btn_add_filter" (click)="addFilterFieldPS(i)"
                    style="margin-right: 0px;width:16px;height:18px;"></a>
                </div>
                <div style="width:10%;">
                  <button class="load_btn" style="height: 20px;line-height: 20px;" type="button"
                    (click)="smartSearchPS(i)">Load</button>
                </div>
              </div>
            </div>


            <div style="width:5%">
              <input type="text" name="note-{{i}}" [(ngModel)]="profileStatements[i].note" />
            </div>
            <div style="width:4%">
              <a href="#" class="btn_remove" *ngIf="profileStatements.length>1"
                (click)="removeProfile(i,ps.id,true)"></a>
              <a href="#" class="btn_add" (click)="addProfile()"></a>
            </div>
            <div style="width:12%;font-size: 10px;padding: 10px;">{{profileStatements[i].availableTracks}}</div>
            <div style="width:12%;font-size: 10px;padding: 10px;">{{profileStatements[i].futureCoverage}}</div>
          </div>
        </div>
        <div class="row last">
          <div style="width: 15%;">
            <label>Total</label>
          </div>
          <div style="width: 5%;text-align: center;">
            <label>{{getTotalCover()}}</label>
          </div>
          <div style="width: 7%;text-align: center;">
            <label>{{getTotalRatio()}} %</label>
          </div>
        </div>
      </div>
      <div class="popup_btns">

        <button type="button" class="cancel" (click)="popupClose()">Cancel</button>
        <button type="submit" (click)="saveProfileStatement(false)"
          *ngIf="current_user!=null&&(current_user.type=='Consultant'||current_user.type=='Administrator')">Save</button>
      </div>
    </form>
  </div>
</div>

<!-- profile statement pop up open -->

<div class="popup create_profileStatement_popup" [ngClass]="{opened: popups.profileCreatePopupOpened}" ngDraggable
  ngResizable [rzHandles]="'n,e,s,w,se,sw,ne,nw'" [handle]="dragHandle14">
  <a href="#" class="popup_close" (click)="popupClose()"></a>
  <div class="popup_title" #dragHandle14>Create Profile </div>
  <div class="popup_form">
    <form #updateProfileStament="ngForm">
      <input type="hidden" name="visible" value="{{profileStatementFields.visible}}"
        [(ngModel)]="profileStatementFields.visible">
      <div class="input_wrap">
        <label>Profile Name</label>
        <input autocomplete="off" type="text" maxlength="50" name="name" value="{{profileStatementFields.name}}"
          [(ngModel)]="profileStatementFields.name" />

      </div>
      <div class="popup_btns">
        <button type="button" class="cancel" (click)="popupClose()">Cancel</button>
        <button type="submit" (click)="profileUpdate($event, updateProfileStament)">Save</button>
      </div>
    </form>
  </div>
</div>

<!-- end  -->


<!-- History pop up -->
<div class="popup edit_track_popup profile_popup" [ngClass]="{opened: popups.profileHistoryopened}" ngDraggable
  ngResizable [rzHandles]="'n,e,s,w,se,sw,ne,nw'" [handle]="dragHandle11">
  <a href="#" class="popup_close" (click)="popupClose()"></a>
  <div class="popup_title" #dragHandle11>History</div>
  <div class="popup_form">
    <div *ngIf="tabs[selectedTabs.rightTabIndex].playlistInfo!=null">
      <div class="left_form">
        <div class="input_wrap">
          <label>Client</label>
          <label>{{tabs[selectedTabs.rightTabIndex].playlistInfo.client_name}}</label>
        </div>
        <div class="input_wrap">
          <label>Channel</label>
          <label>{{tabs[selectedTabs.rightTabIndex].playlistInfo.channel_name}}</label>
        </div>

      </div>
      <div class="right_form">
        <div class="input_wrap">
          <label>Site</label>
          <label>{{tabs[selectedTabs.rightTabIndex].playlistInfo.site_name}}</label>
        </div>
        <div class="input_wrap">
          <label>Playlist</label>
          <label>{{tabs[selectedTabs.rightTabIndex].playlistInfo.name}}</label>
        </div>
      </div>

      <div class="foundation_playlist_selects">
        <div class="row titles foundation-inline-width">
          <div>Fplaylist</div>
          <div>Cover</div>
          <div>Ratio %</div>
          <div>created_by</div>
          <div>Created_Date</div>
          <div>Modified_Date</div>
          <div>Action</div>
          <div>Name</div>
          <div style="width: 0%;"></div>
        </div>
        <div class="table_content" style="height:160px">
          <div class="table_row foundation-inline-width" *ngFor="let u of profileActiveStatus">
            <div class="wd-22">{{u.foundation_name}}</div>
            <div class="wd-22">{{u.cover}}</div>
            <div class="wd-22">{{u.ratio}}</div>
            <div class="wd-22">{{u.first_name}}</div>
            <div class="wd-22">{{u.created_at}} </div>
            <div class="wd-22"> NULL </div>
            <div class="wd-22">FP Active</div>
            <div class="wd-22">{{ u.first_name}}</div>
          </div>

          <div class="table_row foundation-inline-width" *ngFor="let u of profileModifiedStatus">
            <div class="wd-22">{{u.foundation_name}}</div>
            <div class="wd-22">{{u.cover}}</div>
            <div class="wd-22">{{u.ratio}}</div>
            <div class="wd-22">{{u.first_name}}</div>
            <div class="wd-22">{{u.created_at }} </div>
            <div class="wd-22">{{u.updated_at }} </div>
            <div class="wd-22">{{u.status}}</div>
            <div class="wd-22">{{ u.Modified_Fname}}</div>
          </div>

          <div class="table_row foundation-inline-width" *ngFor="let u of profileDeleteStatus">
            <div class="wd-22">{{u.foundation_name}}</div>
            <div class="wd-22">{{u.cover}}</div>
            <div class="wd-22">{{u.ratio}}</div>
            <div class="wd-22">{{u.Create_Fname}}</div>
            <div class="wd-22">{{u.created_at }} </div>
            <div class="wd-22">{{u.updated_at }}</div>
            <div class="wd-22">Deleted</div>
            <div class="wd-22">{{ u.first_name}}</div>
          </div>
        </div>
      </div>
      <div class="popup_btns">
        <button type="button" class="cancel" (click)="popupClose()">Cancel</button>
      </div>
    </div>
  </div>
</div>

<!-- end  -->

<div class="popup playlist_report_popup" [ngClass]="{opened: playlist_report_dialog}" ngDraggable ngResizable
  [rzHandles]="'n,e,s,w,se,sw,ne,nw'" [handle]="dragHandle10">
  <a href="#" class="popup_close" (click)="popupCloseRD()"></a>
  <div class="popup_title" #dragHandle10>Playlist report</div>
  <div class="popup_form">
    <div class="table_wrap playlist_report_table">
      <div class="table_titles">
        <div>MC tag</div>
        <div>% of tracks</div>
      </div>
      <div class="table_content">
        <div class="table_row" *ngFor="let report of playlist_report | keyvalue">
          <div>{{report.key}}</div>
          <div>{{report.value}}</div>
        </div>
        <div class="table_row" *ngIf="playlist_report">
          <div style="border-top: 1px solid #3e3d3b;">Total</div>
          <div style="border-top: 1px solid #3e3d3b;">100</div>
        </div>
      </div>
    </div>
    <div class="popup_btns">
      <button type="button" class="cancel" (click)="popupCloseRD()">Close</button>
    </div>
  </div>
</div>

<div class="fade" [ngClass]="{opened: popups.profileStatementOpened||isLoad}"></div>
<div class="fade opened" *ngIf="tabs[selectedTabs.rightTabIndex].playlistInfo?.profile_type == 2"></div>
<img src="../../assets/img/loader.gif" alt="loader" class="loader" [ngClass]="{active:isLoad}" style="z-index:101" />
<app-alert></app-alert>
<app-confirm (isConfirm)="isConfirm($event)"></app-confirm>