import { Component, OnInit, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";
import { UserService } from "../services/user.service";
import { CookieService } from "ngx-cookie-service";
import { Router } from "@angular/router";
import { AlertComponent } from "../alert/alert.component";
import { Google2FAsharedDataService } from "../services/google2-fashared-data.service";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
  providers: [UserService, CookieService]
})
export class LoginComponent implements OnInit {
  loginData = {
    username: "",
    password: "",
    client_id: 1,
    client_secret: "ylTeDJSWV7j8HlWrdmQSDo9gAwkFjUBsGyOvdwwQ",
    grant_type: "password",
    scope: "*"
  };//Data for user login


  @ViewChild(AlertComponent, { static: true }) alert: AlertComponent;//Component of alert popup
  constructor(
    private userService: UserService,
    private cookieService: CookieService,
    private router: Router,
    private Google2FAsharedDataService:Google2FAsharedDataService
  ) {}

  ngOnInit() {
    // Do nothing
  }

  /**
   Send login data
   * @param e - event
   * @param {NgForm} form - form
   * @returns {boolean}
   */
  loginDataSend(e, form: NgForm) {
    e.preventDefault();
    //alert(validate(this.loginData.username))
    if (this.loginData.username == "" && this.loginData.password == "") {
      this.alert.alertOpen("Please enter your username and password.", 1);
      return false;
    }
    if (this.validateEmail(this.loginData.username)) {
      var str = Object.keys(form.value)
        .map(function(key) {
          return (
            encodeURIComponent(key) + "=" + encodeURIComponent(form.value[key])
          );
        })
        .join("&");
      this.userService.loginUser(str).subscribe(
        response => {
          if (response['requires_2fa']) {
            this.Google2FAsharedDataService.setData(response);
            this.router.navigate(['Google2Fa'])
          } else {
            this.storeTokenAndRedirect(response['access_token']);
          }
        },
        (error: any) => {
          this.alert.alertOpen(error.error.message, 1);
        }
      );
    } else {
      this.alert.alertOpen("Incorrect username and password .", 1);
    }
  }

  /**
   Email validation
   * @param email - email string
   * @returns {boolean}
   */
  validateEmail(email) {
    var pattern = /^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i;
    return pattern.test(email);
  }



  private storeTokenAndRedirect(token: string) {
    this.cookieService.set("access", token);
    if (window.location.pathname == "/login") {

            window.location.href = "/";
  }
  }
}
