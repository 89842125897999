import {Component, ElementRef, OnInit, ViewChild, OnDestroy} from '@angular/core';
import {TracksService} from '../services/tracks.service';
import {PlaylistsService} from '../services/playlists.service';
import {ActivatedRoute, NavigationExtras, Router} from '@angular/router';
import {CommonService} from '../services/common.service';
import {NgForm} from '@angular/forms';
import {HostListener} from '@angular/core';
import {TagsService} from '../services/tags.service';
import {SmartFilterService} from '../services/smart-filter.service';
import {PlaylistCategoriesService} from '../playlist-categories/playlist-categories.service';
import {IMyDpOptions} from 'mydatepicker';
import {PlayerComponentComponent} from '../player-component/player-component.component';
import {CPPlaylistsService} from '../services/cp_playlists.service';
import {CPPlaylistCategoriesService} from '../playlist-categories/cp_playlist-categories.service';
import {AlertComponent} from '../alert/alert.component';
import {ConfirmComponent} from '../confirm/confirm.component';
import {Arrays} from '../classes/arrays';
import {Globals} from '../globals';
import { ExcelService } from '../services/excel.service';
import { DndDragImageRefDirective } from 'ngx-drag-drop';

@Component({
  selector: 'app-manage-fp',
  templateUrl: './manage-fp.component.html',
  styleUrls: ['./manage-fp.component.scss'],
  //encapsulation: ViewEncapsulation.None,
  providers: [ TracksService, PlaylistsService, CommonService, TagsService, SmartFilterService,
    PlaylistCategoriesService, CPPlaylistCategoriesService,
    CPPlaylistsService,ExcelService ]
})
export class ManageFpComponent implements OnInit,OnDestroy {

  tracks = []; //Tracks library
  //players = [];
  trackInfo;// Information of selected track
  trackId = null;//ID of selected track
  link = 'manage-foundation-playlists';//Link of previous page
  playlistInfo: any;//Information of playlist
  playlistParent = null;//Playlist sub category id
  averageBpm = 0;//the average BPM of playlist
  selectedRows = [];//Selected rows in left side
  current_user = null;// information of logged user
  additional = [];// Array of additional attributes for information of track
  searchStr = '';//Value of search input
  defaultSmartFilters: any = [];// Array of default smart filters
  /**
   Selected smart filters values
   {
     categorySelect - ID of selected smart filter category,
     compare - array of compare values,
     compareSelect  - selected compare value,
     field - smart filter value,
     arrFields - array of smart filter values(if selected type of smart filter = 'date'),
     type - smart filter type. List of possible values ['date', 'int','str'](default 'str'),
     isHidden - is smart filter available for change
   }
   **/
  smartFilters: any = [{
    categorySelect: null,
    compare: [],
    compareSelect: null,
    field: null,
    arrFields: [ '', '' ],
    type: 'str',
    isHidden: false
  }];
  matchSelect = 'all';//Value for smart filter match of following rules
  public myDatePickerOptions: IMyDpOptions = {
    dateFormat: 'dd/mm/yyyy',
    editableDateField: false,
    inline: false,
    height: '30px'
  };//params for Date picker module
  similarPlaylists: any = [];//Array of similar playlists
  /**
   Array of params for tabs in right side
   Params={
      selected(boolean) - set active tab(default false),
      playlistId(number) - playlist id,
      category_parent(number) - category parent id,
      tabid(number) - tab index
      tracks(array) - Array of tracks,
      playlistInfo(Object) - information of playlist,
      averageBpm(integet) - the average BPM of playlist,
      selectedRows(array) - array of selected rows in current tab,
      order(string) - order of diplaying tracks(default 'ASC'),
      orderBy(string) - ordering rule. List of possible values [ 'title', 'artist', 'album', 'add_date', 'bpm','add_date_lib'](default 'add_date')
      tab_str(string) - title for tab(default ''),
      type(string) - type of playlist. List of possible values ['cp', 'fp'](default 'fp')
    }
   **/
  tabs: any = [{
    selected: true,
    playlistId: null,
    category_parent: null,
    tabid: 1,
    tracks: [],
    playlistInfo: null,
    averageBpm: 0,
    selectedRows: [],
    order: 'ASC',
    orderBy: 'add_date',
    tab_str: '',
    type: 'fp'
  }];
  tags:any = [];//Array of tags
  /**
   Popups list. Params for opening/closing popups
   **/
  popups = {
    otherPlaylistsPopupOpened: false,
    popupOpened: false,
    editPopupOpened: false,
    smartFilterPopupOpened: false,
    manageTagsPopupOpened: false,
    playlistPopupOpened: false,
    linkedPlaylistsPopupOpened: false,
    linkedPlaylistEditPopupOpened: false
  };
  selectedTabIndex = 0;//Index of selected tab
  /**
   Player information
   {
     isPlaying(string) - player status. List of possible values ['stop', 'play','pause'](default 'stop'),
     trackIndex(integer) - index of playling track in playlist(default null),
     trackId(integer) - playling track id(default null),
     filename(string) - file name of playing track(default null),
     artist(string) - playling track artist(default ''),
     album(string) - playling track album(default ''),
     storage(string) - storage of playling track(default ''),
     token(string) - access token for logged user(default '')
   }
   **/
  playerInfo = {
    isPlaying: 'stop',
    trackIndex: null,
    trackId: null,
    filename: null,
    album: '',
    artist: '',
    storage: '',
    token: ''
  };
  /**
   Field for creating or editing playlist
   {
     id(integer) - playlist ID(default null),
     name(string) - playlist name(default ''),
     catId(integer) - playlist category ID(default null),
     subcatId(integer) - playlist sub category ID(default null),
     status(integer)  - statue of playlist(default 1)
   }
   **/
  playlistFields = {
    id: null,
    name: '',
    catId: null,
    subcatId: null,
    status: 1
  };
  /**
   Field for linked client playlists
   {
     id(integer) - playlist ID(default null),
     name(string) - playlist name(default ''),
     status(integer)  - statue of playlist(default 1)
     chanel(integer) - playlist channel ID(default null),
     site(integer) - playlist site ID(default null),
     description(string) - description of playlist,
     user(integer) - client ID(default null),
     sitesList(Array) -array of sites
     channelsList(Array) - array of channeld

   }
   **/
  linkedPlaylistFields = {
    id: null,
    name: '',
    status: 1,
    chanel: null,
    site: null,
    description: '',
    user: null,
    sitesList: [],
    channelsList: []
  }
  categoriesList = null;// List of playlists categories
  subcategoriesList = null;// List of playlists sub categories
  updateTimer = null;//Timer for automatic updating tables in playlists
  playlistStatusText = ['Live', 'Editing', 'Suspended'];// Array of statuses text for playlists
  checkedUserId = null;//User id for opened playlist
  /**
   Params for page.
   If isFullscreen = true - Tracks library and playlist will display in different windows.
   If isPlaylist = true browser will show playlist in current window
   **/
  screenParams = {
    isFullScreen: false,
    isPlaylist: false
  };
  isFocused = false;// Checking of inputs focus
  draggable = {
    data: null,
    effectAllowed: "all",
    disable: false,
    handle: false
  };//params for draggable module
  draggableRemove = {
    data: null,
    effectAllowed: "all",
    disable: false,
    handle: false
  };//params for draggable module
  isShowHeader = true;// Sets displaying header or not
  @ViewChild(PlayerComponentComponent, { static: true }) player: PlayerComponentComponent;//Component of player
  @ViewChild(AlertComponent, { static: true }) alert: AlertComponent;//Component of alert popup
  @ViewChild(ConfirmComponent, { static: true }) confirm: ConfirmComponent;//Component of confirm popup

  linkedPlaylists: any = []; //Array of linked client playlists to selected foundation playlist
  drops = {
    clients: false,
    fp: false
  };// Open dropdowns with list of categories/playlists
  /**
   Counter of tracks adding to playlist
   {
     count(integer) - number of adding tracks
     countet(integer) - number of success added tracks
     count(integer) -  number of errors when adding tracks
   }
   **/
  addCount = {
    count: 1,
    counter: 1,
    errors: 0
  }
  isLoad = false;//Display preloader
  openPlaylistDrop = false;//Open playlist dropdown for adding new tab
  openPlaylistDrop2 = false;//Open playlist dropdown for adding new tab
    profileFilterCount = 0;
    andOrSelect = 'and'

  /**
   Foundation playlist values for dropdowns
   {
     category: {
       id - selected category ID,
       catsArr - array of categories
     },
     subcategory: {
       id - selected sub category ID
     },
     playlists: {
       id - selected playlist ID,
       playlistsArr - array of playlists
     }
   }
   **/
  openPlaylist = {
    category: {
      id: null,
      catsArr: []
    },
    subcategory: {
      id: null
    },
    playlists: {
      id: null,
      playlistsArr: []
    }
  };
  /**
   Client playlist values for dropdowns
   {
     users: {
       id - selected user ID
       usersArr - array of users
     },
     sites: {
       id - selected site ID
       sitesArr - array of sites
     },
     channels: {
       id - selected channel ID
     },
     playlists: {
       id - selected playlist ID
       playlistsArr - array of playlists
     }
   }
   **/
  openCPPlaylist = {
    users: {
      id: null,
      usersArr: []
    },
    sites: {
      id: null,
      sitesArr: []
    },
    channels: {
      id: null
    },
    playlists: {
      id: null,
      playlistsArr: []
    }
  };
  playlistType = 0;//Selected playlist type in dropdown 0 - any selected, 1 - Client playlist, 2 - Foundation playlist
  openSubcats = {
    subcatsArr: []
  };//Subcategories array
  /**
   Order rules for track library
   {
      order(string) - order of diplaying tracks(default 'ASC'),
      orderBy(string) - ordering rule. List of possible values [ 'title', 'artist', 'album', 'add_date', 'bpm'](default 'add_date')
   }
   */
  tracksOrder = {
    order: 'ASC',
    orderBy: 'add_date'
  };
  /**
   Params for tracks scrolling
   Params={
      offsetItems(integer) - offet tracks number(default 0) ,
      addItems(integer) -  number tracks for upload(default 100),
      uploadedItems(integer) -  number uploaded tracks(default 100)
    }
   **/
  infiniteScroll = {
    items: 0,
    addItems: 100
  };
  /**
   Value for confirmation popup
   {
     id(integer) - ID of element for action(default null),    
     type(integer) - type of function for action(default 1)
   }
   **/
  confirmValues = {
    id: null,
    type: 0
  };
  /**
   Tracks count in Tracks library
   {
     total - total tracks count,
     display - displaying tracks count
   }
   **/
  tracksCount = {
    total: 0,
    display: 0
  };
  isSmartSearch = false;//Smart filter searching
  searchTimeout = null;// Timeout for starting searching
  mcTagsList = [];
  selectedMCTags = [];
  dropdownSettings = {
    singleSelection: false,
    idField: 'item_id',
    textField: 'item_text',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 3,
    allowSearchFilter: true
  };

  disabledInputs = {
    year: false,
    artist: false,
    album: false,
    title: false
  }

  filteredParentIDsCP = [];
  filteredChildIDsCP  = [];
  filteredClientIDsCP = [];
  loadingCPSearch:boolean = false;
  searchStrCP = '';
  filteredParentIDsFP = [];
  filteredChildIDsFP  = [];
  playlist_report = {};
  playlist_report_dialog = false;
  qualityoptions = [
    { value: 'H', label: 'High' },
    { value: 'L', label: 'Low' }
  ];
  cliptypeoptions = [
    { value: 'A', label: 'Audio' },
    { value: 'V', label: 'Video' }
  ];
  selectedqualityOption: string; 
  selectedclipTypeOption:string;
  selectedmusicGenre1:any;
  selectedmusicGenre2:any;
  selectedmusicGenre3:any;
  hiddenset:any;

  musicFileDetails ={
    explicit:0,
    hard_in_point:0,
    hard_out_point:0,
  }
  searchBulkUidPopUpOpen =false;
  searchUID: string = '';
  isSearchUID=false;

  constructor(
    private trackService: TracksService,
    private playlistService: PlaylistsService,
    private route: ActivatedRoute,
    private router: Router,
    private commonService: CommonService,
    private tagService: TagsService,
    private smartService: SmartFilterService,
    private foundationCategoriesService: PlaylistCategoriesService,
    private elRef: ElementRef,
    private cpService: CPPlaylistsService,
    private chanelsService: CPPlaylistCategoriesService,
    private globals: Globals,
    private excelService: ExcelService,
  ) { }

  ngOnInit() {
    this.commonService.getCurrentLoggedUser().subscribe(current_user => {
      this.current_user = current_user;
    });
   
    this.route.queryParams.subscribe(params=>{
      if( params['sh'] != null&&params['sh'] == 0 ){
        this.isShowHeader = false;
      }
      if(params['ipl'] == 'true'|| params['ipl']){
        this.screenParams.isPlaylist = true;
      }
      if(params['ifs'] == 'true'|| params['ifs']){
        this.screenParams.isFullScreen = true;
      }
      this.setTabsInfo(0, params['playlistID']);
      this.setStatus(params['playlistID'], 1);
      this.getAllTracks( params['playlistID'] );
    });

    this.smartService.getFilters('fp').subscribe(filters => {
      this.defaultSmartFilters = filters;
    });
    
    this.updateCategoriesList();
    this.getTags();
    this.getMCTags();
    this.isSmartSearch = false;
	  this.clearFilter(e);
  }
  

  
  getMCTags(){
    this.trackService.getMCTags().subscribe((tags:any) => {
      if (tags) {
        for (let key in tags) {
          if (tags.hasOwnProperty(key)) {
            this.mcTagsList.push({
              item_id   : tags[key].name,
              item_text : tags[key].name
            })
          }
        }
      }  
    });
  }
  onItemSelect(item: any) {
    console.log(item);
  }
  onSelectAll(items: any) {
    console.log(items);
  }
  onFilterChange(e){
    this.isFocused = true;
  }
  onDropDownClose(){
    console.log('Dropdown closed');
    this.isFocused = false;
  }

  ngOnDestroy(){
    localStorage.removeItem("smartFilters");
    clearInterval(this.updateTimer);
    if(this.playerInfo.isPlaying=='play'){
      this.player.pause();
    }
  }
  /**
   Get tracks library
   * @param id - selected playlist id
   */
  getAllTracks( pid ){
    this.isSmartSearch = false;
    var offset = this.infiniteScroll.items;
    var limit = this.infiniteScroll.addItems;
    this.trackService.getTracksAllInPlaylist( pid,  'fp', this.tracksOrder.order, this.tracksOrder.orderBy, offset, limit ).subscribe( (tracks:any) => {
      this.tracksCount.total = tracks.total;
      this.tracksCount.display = tracks.total;
      if(offset == 0)
        this.tracks = tracks.list;
      else
        this.tracks = this.tracks.concat(tracks.list);
    });
  }
  /**
   Add track to playlist when user drag row(s)
   * @param {DragEvent} event - event
   * @param id - track id
   * @param pivot_id - track id in playlist
   */
  addDragTrack(event:DragEvent, id) {
    this.draggable.data = id;
  }
  /**
   Remove track from playlist when user drag row(s)
   * @param {DragEvent} event - event
   * @param id - track id in playlist
   */
  removeDragTrack(event:DragEvent, id) {
    this.draggableRemove.data = id;
  }
  /**
   Drop event when user drag track to add to playlist
   * @param pid - playlist id
   */
  onDrop( event, pid ) {
    if(this.selectedRows.length > 1){
      this.addTracks( event, this.selectedRows, pid );
    }
    else{
      this.addTrack(event, this.draggable.data, pid, false);
    }
  }
  /**
   Drop event when user drag track to remove from playlist
   */
  onDropRemove(event){
    if(this.tabs[this.selectedTabIndex].selectedRows.length > 1){
      this.openConfirmPopup(0,1);
    }
    else{
      this.removeTrack( this.draggableRemove.data );
    }
  }
  /**
   Set information for tabs
   * @param tabindex - index of tab
   * @param pid - playlist id
   */
  setTabsInfo(tabindex, pid){
    this.playlistService.getPlaylistById(pid).subscribe((info:any) =>{
      this.tabs[tabindex].playlistInfo = info;
      this.tabs[tabindex].tab_str = info.parent_node.name;
      this.getPlaylistTracks(pid, tabindex);
      this.getCategoryParent( this.tabs[tabindex].playlistInfo.foundation_category_id, tabindex);
    });
  }
  /**
   Set information for tabs with client playlists
   * @param tabindex - index of tab
   * @param pid - playlist id
   */
  setCPTabsInfo(tabindex, pid){
    this.cpService.getPlaylistById(pid).subscribe((info:any) =>{
      this.tabs[tabindex].playlistInfo = info;
      this.tabs[tabindex].tab_str = info.client_name + ' -> ' + info.channel_name + ' -> '+ info.site_name + ' -> ' + info.name;
      this.getCPPlaylistTracks(pid, tabindex);
      this.getCPCategoryParent( this.tabs[tabindex].playlistInfo.cp_category_id, tabindex);
    });
  }
  /**
   Update tabs information and tracks for all tabs
   */
  updateTabs(){
    if(this.searchStr == '') {
      for (var i = 0; i < this.tabs.length; i++) {
        this.setTabsInfo(i, this.tabs[i].playlistInfo.id);
      }
    }
  }
  /**
   Add new tab
   * @param e - event
   * @param pid - playlist id
   * @returns {boolean}
   */
  addTab(e, pid){
    var newtab = {
      selected: true,
      playlistId: null,
      category_parent: null,
      tabid: this.tabs.length,
      tracks: [],
      playlistInfo: null,
      averageBpm: 0,
      selectedRows: [],
      order: 'ASC',
      orderBy: 'add_date',
      tab_str: '',
      type: 'fp'
    };
    this.addTracks(e, this.selectedRows, pid);
    var checkTab = this.checkTabs(pid);
    if(checkTab == -1){
      this.tabs.push(newtab);
      this.setTabsInfo(this.tabs.length - 1, pid);
      this.deselectTab(this.tabs.length - 1);
    }
    else{
      this.deselectTab(checkTab);
    }
    this.popupClose(window.event);
  }
  /**
   Deselect all tabs in right side excerpt choosen
   * @param tid - tab index
   */
  deselectTab(tid){
    for( var i = 0; i < this.tabs.length; i++ ){
      if( i != tid ){
        this.tabs[i].selected = false;
      }
      else{
        this.tabs[i].selected = true;
        this.selectedTabIndex = tid;
      }
    }
  }
  /**
   Check opened playlist in right side
   * @param pid - playlilst id
   * @returns {number} - tab index
   */
  checkTabs(pid){
    for( var i = 0; i < this.tabs.length; i++ ){
      if(this.tabs[i].playlistInfo.id == pid ){
        return i;
      }
    }
    return -1;
  }
  /**
   Checking events on click keys on keyboard
   * @param {KeyboardEvent} event - event
   */
  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if(this.checkOpenedPopups()){
      var k = parseInt(event.key);
      if(k==1||k==2||k==3||k==4||k==5||k==6||k==7||k==8||k==9){
        if(this.tags[ k - 1 ].info != null){
          if(this.tabs[this.selectedTabIndex].selectedRows.length > 0 ){
            var str = 'tag_ids[0]=' + this.tags[ k-1 ].info.id;
            this.tagService.setTagsForTrack( this.tabs[this.selectedTabIndex].selectedRows[0], str , 'fp' ).subscribe(tags=>{
              this.getPlaylistTracks(this.tabs[this.selectedTabIndex].playlistInfo.id, this.selectedTabIndex);
            });
          }
          else if(this.selectedRows.length > 0){
            var str = 'tag_ids[0]=' + this.tags[ k-1 ].info.id;
            this.tagService.setTagsForTrackLibrary( this.selectedRows[0], str, 'fp', this.tabs[this.selectedTabIndex].playlistInfo.id ).subscribe(tags => {
              this.getAllTracks(this.tabs[this.selectedTabIndex].playlistInfo.id);
            });
          }
        }
      }
      else if( k == 0 ){
        if(this.tags[ 9 ].info != null){
          if(this.tabs[this.selectedTabIndex].selectedRows.length > 0 ){
            var str = 'tag_ids[10]=' + this.tags[ 9 ].info.id;
            this.tagService.setTagsForTrack( this.tabs[this.selectedTabIndex].selectedRows[0], str, 'fp' ).subscribe(tags=>{
              this.getPlaylistTracks(this.tabs[this.selectedTabIndex].playlistInfo.id, this.selectedTabIndex);
            });
          }
          else if(this.selectedRows.length > 0){
            var str = 'tag_ids[10]=' + this.tags[ 9 ].info.id;
            this.tagService.setTagsForTrackLibrary( this.selectedRows[0], str, 'fp', this.tabs[this.selectedTabIndex].playlistInfo.id ).subscribe(tags => {
              this.getAllTracks(this.tabs[this.selectedTabIndex].playlistInfo.id);
            });
          }
        }
      }
      if(event.key == 'ArrowLeft'){
        if(!this.isFocused){
          event.preventDefault();
          if(this.tabs[this.selectedTabIndex].selectedRows.length > 0){
            this.openConfirmPopup(0,1);
          }
        }
      }
      else if(event.key == 'ArrowRight'){
        if(!this.isFocused){
          event.preventDefault();
          if(this.selectedRows.length>0){
            this.addTracks(event,this.selectedRows,this.tabs[this.selectedTabIndex].playlistInfo.id);
          }
        }  
      }
      else if(event.key == 'ArrowUp'){
        event.preventDefault();
        this.setPosition(0);
      }
      else if(event.key == 'ArrowDown'){
        event.preventDefault();
        this.setPosition(1);
      }
    }
  }
  /**
   Set position after click key up/key down on keyboad
   * @param arrow(number) - arrow 0=key up, 1=key down
   */
  setPosition(arrow){
    var arr = [];
    var el = false;
    if(this.selectedRows.length > 0){
      var pos = this.getSelectedPosition(this.tracks, this.selectedRows[0]);
      if(arrow == 0){
        if(pos > 0){
          this.selectedRows=[];
          this.selectedRows[0] = this.tracks[pos-1].id;
          if(this.playerInfo.isPlaying!='play'){
            this.playerInfo.trackIndex = pos-1
            this.playerInfo.trackId = this.tracks[pos-1].id;
            this.playerInfo.filename = this.tracks[pos-1].file_name;
            this.playerInfo.album = this.tracks[pos-1].title + ' - ' + this.tracks[pos-1].album;
            this.playerInfo.artist = this.tracks[pos-1].artist;
            this.playerInfo.storage = this.tracks[pos-1].storage;
            this.playerInfo.token = this.globals.access_token;
          }
        }
      }
      else if(arrow == 1){
        if( (pos + 1) != this.tracks.length){
          this.selectedRows=[];
          this.selectedRows[0] = this.tracks[pos+1].id;
          if(this.playerInfo.isPlaying!='play'){
            this.playerInfo.trackIndex = pos+1;
            this.playerInfo.trackId = this.tracks[pos+1].id;
            this.playerInfo.filename = this.tracks[pos+1].file_name;
            this.playerInfo.album = this.tracks[pos+1].title + ' - ' + this.tracks[pos+1].album;
            this.playerInfo.artist = this.tracks[pos+1].artist;
            this.playerInfo.storage = this.tracks[pos+1].storage;
            this.playerInfo.token = this.globals.access_token;
          }
        }
      }
 
    }
    else if(this.tabs[this.selectedTabIndex].selectedRows.length > 0){
      var pos = this.getSelectedPosition( this.tabs[this.selectedTabIndex].tracks, this.tabs[this.selectedTabIndex].selectedRows[0]);
      if(arrow == 0){
        if(pos > 0){
          this.tabs[this.selectedTabIndex].selectedRows = [];
          this.tabs[this.selectedTabIndex].selectedRows[0] = this.tabs[this.selectedTabIndex].tracks[pos-1].pivot_id;
          if(this.playerInfo.isPlaying!='play'){
            this.playerInfo.trackIndex = pos-1;
            this.playerInfo.trackId = this.tabs[this.selectedTabIndex].tracks[pos-1].id;
            this.playerInfo.filename = this.tabs[this.selectedTabIndex].tracks[pos-1].file_name;
            this.playerInfo.album = this.tabs[this.selectedTabIndex].tracks[pos-1].title + ' - ' + this.tabs[this.selectedTabIndex].tracks[pos-1].album;
            this.playerInfo.artist = this.tabs[this.selectedTabIndex].tracks[pos-1].artist;
            this.playerInfo.storage = this.tabs[this.selectedTabIndex].tracks[pos-1].storage;
            this.playerInfo.token = this.globals.access_token;
          }
        }
      }
      else if(arrow == 1){
        if( (pos + 1) != this.tabs[this.selectedTabIndex].tracks.length){
          this.tabs[this.selectedTabIndex].selectedRows = [];
          this.tabs[this.selectedTabIndex].selectedRows[0] = this.tabs[this.selectedTabIndex].tracks[pos+1].pivot_id;
          if(this.playerInfo.isPlaying!='play'){
            this.playerInfo.trackIndex = pos+1;
            this.playerInfo.trackId = this.tabs[this.selectedTabIndex].tracks[pos+1].id;
            this.playerInfo.filename = this.tabs[this.selectedTabIndex].tracks[pos+1].file_name;
            this.playerInfo.album = this.tabs[this.selectedTabIndex].tracks[pos+1].title + ' - ' + this.tabs[this.selectedTabIndex].tracks[pos+1].album;
            this.playerInfo.artist = this.tabs[this.selectedTabIndex].tracks[pos+1].artist;
            this.playerInfo.storage = this.tabs[this.selectedTabIndex].tracks[pos+1].storage;
            this.playerInfo.token = this.globals.access_token;
          }
        }
      }
    }
    else {
      this.selectedRows[0] = this.tracks[0].id;
    }
    this.scrollToElement();
  }
  /**
   Scroll window to element when user press up/down keys
   */
  scrollToElement(){
    var el = this.elRef.nativeElement.querySelector('.table_row.selected');
    if(el != null){
      var parent = el.parentElement;
      if(el.offsetTop + (el.offsetHeight*2) - parent.offsetTop > parent.scrollTop + parent.clientHeight){
        el.parentElement.scrollTop = (el.offsetTop + (el.offsetHeight * 2)) - parent.clientHeight + 20;
      }
      if(el.offsetTop + parent.offsetTop + (el.offsetHeight * 2) < parent.scrollTop + parent.clientHeight){
        el.parentElement.scrollTop = el.offsetTop - parent.offsetTop - parent.clientHeight + (el.offsetHeight * 2);
      }
    }
  }
  /**
   Get selected row index in tracks library or playlist
   * @param arr - array of tracks
   * @param id - track id or track id in playlist
   * @returns {number} - row index
   */
  getSelectedPosition(arr,id){
    for(var i = 0; i < arr.length; i++){
      if(this.selectedRows.length > 0){
        if(id == arr[i].id){
          return i;
        }
      }
      else if(this.tabs[this.selectedTabIndex].selectedRows.length > 0){
        if(id == arr[i].pivot_id){
          return i;
        }
      }
    }
  }
  /**
   If track row selected in tracks library or playlist, select and play next row
   * @param e - event
   */
  nextTrack(e){
    if(this.selectedRows.length > 0){
      if( this.tracks.length > this.playerInfo.trackIndex + 1 ){
        this.playerInfo.trackIndex = this.playerInfo.trackIndex + 1;
        this.selectedRows[0] = this.tracks[ this.playerInfo.trackIndex ].id;
        this.playerInfo.trackId = this.tracks[ this.playerInfo.trackIndex ].id;
        this.playerInfo.filename = this.tracks[ this.playerInfo.trackIndex ].file_name;
        this.playerInfo.album = this.tracks[ this.playerInfo.trackIndex ].title + ' - ' + this.tracks[ this.playerInfo.trackIndex ].album;
        this.playerInfo.artist = this.tracks[ this.playerInfo.trackIndex ].artist;
        this.playerInfo.storage = this.tracks[ this.playerInfo.trackIndex ].storage;
        this.playerInfo.token = this.globals.access_token;
      }
    }
    else if(this.tabs[this.selectedTabIndex].selectedRows.length > 0){
      if( this.tabs[this.selectedTabIndex].tracks.length > this.playerInfo.trackIndex+1 ){
        this.playerInfo.trackIndex = this.playerInfo.trackIndex + 1;
        this.tabs[this.selectedTabIndex].selectedRows[0] = this.tabs[this.selectedTabIndex].tracks[this.playerInfo.trackIndex].pivot_id;
        this.playerInfo.trackId = this.tabs[this.selectedTabIndex].tracks[this.playerInfo.trackIndex].id;
        this.playerInfo.filename = this.tabs[this.selectedTabIndex].tracks[this.playerInfo.trackIndex].file_name;
        this.playerInfo.album = this.tabs[this.selectedTabIndex].tracks[this.playerInfo.trackIndex].title + ' - ' + this.tabs[this.selectedTabIndex].tracks[this.playerInfo.trackIndex].album;
        this.playerInfo.artist = this.tabs[this.selectedTabIndex].tracks[this.playerInfo.trackIndex].artist;
        this.playerInfo.storage = this.tabs[this.selectedTabIndex].tracks[this.playerInfo.trackIndex].storage;
        this.playerInfo.token = this.globals.access_token;
      }
    }
    if(this.playerInfo.isPlaying == 'play'){
      this.player.start( this.playerInfo.trackId, true );
    }
  }
  /**
   If track row selected in tracks library or playlist, select and play previous row
   * @param e
   */
  prevTrack(e){
    if(this.selectedRows.length > 0){
      if( this.playerInfo.trackIndex > 0 ){
        this.playerInfo.trackIndex = this.playerInfo.trackIndex - 1;
        this.selectedRows[0] = this.tracks[ this.playerInfo.trackIndex ].id;
        this.playerInfo.trackId = this.tracks[ this.playerInfo.trackIndex ].id;
        this.playerInfo.filename = this.tracks[ this.playerInfo.trackIndex ].file_name;
        this.playerInfo.album = this.tracks[ this.playerInfo.trackIndex ].title + ' - ' + this.tracks[ this.playerInfo.trackIndex ].album;
        this.playerInfo.artist = this.tracks[ this.playerInfo.trackIndex ].artist;
        this.playerInfo.storage = this.tracks[ this.playerInfo.trackIndex ].storage;
        this.playerInfo.token = this.globals.access_token;
      }
    }
    else if(this.tabs[this.selectedTabIndex].selectedRows.length > 0){
      if( this.playerInfo.trackIndex>0 ){
        this.playerInfo.trackIndex = this.playerInfo.trackIndex - 1;
        this.tabs[this.selectedTabIndex].selectedRows[0] = this.tabs[this.selectedTabIndex].tracks[this.playerInfo.trackIndex].pivot_id;
        this.playerInfo.trackId = this.tabs[this.selectedTabIndex].tracks[this.playerInfo.trackIndex].id;
        this.playerInfo.filename = this.tabs[this.selectedTabIndex].tracks[this.playerInfo.trackIndex].file_name;
        this.playerInfo.album = this.tabs[this.selectedTabIndex].tracks[this.playerInfo.trackIndex].title + ' - ' + this.tabs[this.selectedTabIndex].tracks[this.playerInfo.trackIndex].album;
        this.playerInfo.artist = this.tabs[this.selectedTabIndex].tracks[this.playerInfo.trackIndex].artist;
        this.playerInfo.storage = this.tabs[this.selectedTabIndex].tracks[this.playerInfo.trackIndex].storage;
        this.playerInfo.token = this.globals.access_token;
      }
    }
    if(this.playerInfo.isPlaying == 'play'){
      this.player.start( this.playerInfo.trackId, true );
    }
  }
  /**
   Checking if one of popup opened
   * @returns {boolean}
   */
  checkOpenedPopups(){
    if(this.popups.popupOpened || this.popups.editPopupOpened || this.popups.smartFilterPopupOpened || this.popups.manageTagsPopupOpened || this.popups.otherPlaylistsPopupOpened)
      return false;
    else
      return true;
  }
  /**
   Add track to playlist
   * @param tid - track id
   * @param pid - playlist id
   * @param once(boolean) - is adding single track or multiple
   * @returns {boolean}
   */
  addTrack(e, id, pid, once){
    if(this.tabs[this.selectedTabIndex].type=='fp'){
      this.playlistService.addTrackToPlaylist(this.tabs[this.selectedTabIndex].playlistInfo.id, id).subscribe((tracks:any) => {
        if(tracks.delete_error!=undefined){
          this.alert.alertOpen(tracks.delete_error,1);
        }
        if(once){
          this.addCount.counter += 1;
        }
        if(tracks.error!=undefined){
          if(once){
            this.addCount.errors += 1;
          }
          else{
            this.alert.alertOpen(tracks.error,1);
          }
        }
        else{
          this.updateTabs();
          this.updatePlaylists();
        }
        if(this.addCount.count == this.addCount.counter && once && this.addCount.errors>0){
          this.alert.alertOpen( 'From the selected tracks ' + this.addCount.errors + ' of them already exists',1 );
        }
      });
    
    }
    else{
      this.cpService.addTrackToPlaylist(this.tabs[this.selectedTabIndex].playlistInfo.id, id,null).subscribe((tracks:any) => {
        if(once){
          this.addCount.counter += 1;
        }
        if(tracks.error!=undefined){
          if(once){
            this.addCount.errors += 1;
          }
          else{
            this.alert.alertOpen(tracks.error,1);
          }
        }
        else{
          this.updateTabs();
          this.updatePlaylists();
        }
        if(this.addCount.count == this.addCount.counter && once && this.addCount.errors>0){
          this.alert.alertOpen( 'From the selected tracks ' + this.addCount.errors + ' of them already exists',1 );
        }
      });
    }

    return false;
  }
  /**
   Add multiple tracks to playlist
   * @param arr - array of selected rows
   * @param pid - playlist id
   * @returns {boolean}
   */
  addTracks( e, arr, pid ){
    if(arr.length > 0){
      this.addCount.count = arr.length;
      this.addCount.counter = 0;
      this.addCount.errors = 0;
      for(var i = 0; i < arr.length; i++){
        this.addTrack( e, arr[i], pid, true );
      }
    }
    else{
      this.alert.alertOpen("No Tracks selected!",1);
    }
  }
  /**
   Updates tag colors for tracks
   */
  updateTags(e){
    var str = '';
    for( var i = 1; i <= this.tags.length; i++ ){
      str += 'tags[' + i + ']=' + this.tags[i - 1].name + '&';
    }
    for(var i = 1; i <= this.tags.length; i++){
      str += 'colors[' + i + ']=' + this.tags[i - 1].color;
      if( i != this.tags.length ){
        str += "&";
      }
    }
   
    this.tagService.updatePlaylistTag( str ).subscribe(tags => {
      this.alert.alertOpen("Tags for playlist updated",0);
      this.getTags();
      this.popupClose(e);
      this.getPlaylistTracks( this.tabs[this.selectedTabIndex].playlistInfo.id, this.selectedTabIndex );
    });
  }
  /**
   Get tracks for Foundation playlist
   * @param pid - playlist id
   * @param index - tab index
   */
  getPlaylistTracks(pid, index){
    this.playlistService.getPlaylistTracks( pid, this.tabs[index].order, this.tabs[index].orderBy ).subscribe((tracks:any) => {
     
      if(tracks.message == undefined){
        this.tabs[index].tracks = tracks;
        this.tabs[index].averageBpm = Math.round(this.getBpm(this.tabs[index].tracks));
      }
      else{
        this.tabs[index].tracks = [];
        this.tabs[index].averageBpm = 0;
      }
    });
  }
  /**
   Get tracks for Client playlist
   * @param pid - playlist id
   * @param index - tab index
   */
  getCPPlaylistTracks(pid, index){
    this.cpService.getPlaylistTracks( pid, this.tabs[index].order, this.tabs[index].orderBy ).subscribe((tracks:any) => {
      if(tracks.message == undefined){
        this.tabs[index].tracks = tracks;
        this.tabs[index].averageBpm = Math.round(this.getBpm(this.tabs[index].tracks));
      }
      else{
        this.tabs[index].tracks = [];
        this.tabs[index].averageBpm = 0;
      }
    });
  }
  /**
   Update tracks for opened playlists
   */
  updatePlaylists(){
    for( var i = 0; i < this.tabs.length; i++ ){
      if( this.tabs[i].type == 'fp' )
        this.getPlaylistTracks( this.tabs[i].playlistInfo.id, i );
      else
        this.getCPPlaylistTracks( this.tabs[i].playlistInfo.id, i );
    }
  }
  /**
   Change status for playlist
   * @param pid - playlist id
   * @param sid(number) - playlist status 0=Live, 1=Editing, 2=Suspended
   */
  setStatus(pid,sid){
    this.playlistService.setStatus( pid, sid );
  }
  /**
   Get average BPM for playlist
   * @param arr - array of tracks in playlist
   * @returns {number} - average bpm
   */
  getBpm(arr){
    var sum = 0;
    var count: number = 0;
    arr.forEach((track:any) => {
      count++;
      if(!isNaN(parseInt(track.bpm)))
        sum += parseInt(track.bpm);
    });
    return sum / count;
  }
  /**
   Remove single track from playlist
   * @param id - track id in playlist
   * @returns {boolean}
   */
  removeTrack(id){
    this.playlistService.deletePlaylistTrack(this.tabs[this.selectedTabIndex].playlistInfo.id, id ).subscribe(tracks=>{
      this.updateTabs();
      this.getPlaylistTracks(this.tabs[this.selectedTabIndex].playlistInfo.id,this.selectedTabIndex);
    });
    return false;
  }
  /**
   Remove multiple tracks from playlist
   * @returns {boolean}
   */
  removeTracks(){
    for( var i = 0; i < this.tabs[this.selectedTabIndex].selectedRows.length; i++){
      this.playlistService.deletePlaylistTrack(this.tabs[this.selectedTabIndex].playlistInfo.id,  this.tabs[this.selectedTabIndex].selectedRows[i] ).subscribe(tracks=>{
        this.updateTabs();
        this.getPlaylistTracks( this.tabs[this.selectedTabIndex].playlistInfo.id, this.selectedTabIndex );
      });
    }
    return false;
  }

  /**
   Get track information and show popup for track information
   * @param e - event
   * @param id - track id
   * @returns {boolean}
   */
  showInfo(e,id){
    this.trackService.getTrackInfo(id).subscribe(trackInfo => {
      this.trackInfo = trackInfo;
      this.additional = [];
      this.selectedMCTags = [];
      for(var i = 0; i < this.trackInfo.additional_attributes.length; i++){
        this.additional.push(this.trackInfo.additional_attributes[i].name);
        this.selectedMCTags.push({
          item_id   : this.trackInfo.additional_attributes[i].name,
          item_text : this.trackInfo.additional_attributes[i].name
        });
      }
     
      this.popups.popupOpened = !this.popups.popupOpened;
    });
    return false;
  }
  /**
   Get track information and show popup for track info editing
   * @param e - event
   * @param id - track id
   * @param pivot_id - track id in playlist
   * @param selection(number) - selected side 0=left tabs, 1=right side
   * @returns {boolean}
   */
  showEdit(e,id, pivot_id, selection){
    this.resetDisabledInputs();
    this.trackService.getTrackInfo(id).subscribe(trackInfo => {
      this.trackInfo = trackInfo;
      

      this.additional = [];
      this.selectedRows = [];
      this.selectedMCTags = [];
      this.tabs[this.selectedTabIndex].selectedRows = [];
      
      for (let row of this.trackInfo.additional_attributes) {
        this.additional.push(row.name);
        this.selectedMCTags.push({
          item_id   : row.name,
          item_text : row.name
        });
      }
      if (this.trackInfo?.data?.music_file_details) {
        this.selectedmusicGenre1 = this.trackInfo.data.music_file_details.genre1 ?? -1;
        this.selectedmusicGenre2 = this.trackInfo.data.music_file_details.genre2 ?? -1;
        this.selectedmusicGenre3 = this.trackInfo.data.music_file_details.genre3 ?? -1;
        this.selectedclipTypeOption = this.trackInfo.data.music_file_details.clipType ?? 'A';
        this.selectedqualityOption = this.trackInfo.data.music_file_details.quality ?? 'H';
        this.hiddenset = this.trackInfo.data.music_file_details.hidden ?? 0;
        this.musicFileDetails.explicit=this.trackInfo.data.music_file_details.explicit ?? 0,
        this.musicFileDetails.hard_in_point=this.trackInfo.data.music_file_details.hard_in_point ?? 0,
        this.musicFileDetails.hard_out_point=this.trackInfo.data.music_file_details.hard_out_point ?? 0
    } else {
        this.selectedmusicGenre1 = -1;
        this.selectedmusicGenre2 = -1;
        this.selectedmusicGenre3 = -1;
        this.selectedclipTypeOption = 'A';
        this.selectedqualityOption = 'H';
        this.hiddenset = 0;
        this.musicFileDetails.explicit=  0,
        this.musicFileDetails.hard_in_point= 0,
        this.musicFileDetails.hard_out_point= 0
    }
      if(selection == 0){
        this.selectedRows[0] = id;
        if(this.playerInfo.isPlaying!='play')
          this.addPlayerInfo(selection,id);
      }
      else if(selection == 1){
        this.tabs[this.selectedTabIndex].selectedRows[0] = pivot_id;
        if(this.playerInfo.isPlaying!='play')
          this.addPlayerInfo(selection,pivot_id);
      }
      this.popups.editPopupOpened = true;
    });
    return false;
  }
  /**
   Add track info to player
   * @param selection(number) - selected side 0=left tabs, 1=right side
   * @param id - track id in library or in playlist
   */
  addPlayerInfo(selection,id){
    var pos;
    var t;
    if(selection == 0){
      pos = this.getSelectedPosition(this.tracks,id);
      t = this.tracks[pos];
    }
    else if(selection == 1){
      pos = this.getSelectedPosition(this.tabs[this.selectedTabIndex].tracks,id);
      t = this.tabs[this.selectedTabIndex].tracks[pos];
    }
    this.playerInfo.trackIndex = pos;
    this.playerInfo.trackId = t.id;
    this.playerInfo.filename = t.file_name;
    this.playerInfo.album = t.title + ' - ' + t.album;
    this.playerInfo.artist = t.artist;
    this.playerInfo.storage = t.storage;
    this.playerInfo.token = this.globals.access_token;
  }
  /**
   Sets information for player and start/pause track
   * @param file_id - file id from database
   * @param file_name - name of file
   * @param index - index of active tab
   * @param album - album name
   * @param artist - artist name
   * @param title - track title
   * @param storage - storage on server
   * @returns {boolean}
   **/
  playTrack(e, file_id, file_name, index, album, artist, title, storage ){
    e.preventDefault();
    if( this.playerInfo.isPlaying=='play' && this.playerInfo.trackId == file_id && this.playerInfo.trackIndex == index ){
      this.player.pause();
    }
    else{
      this.playerInfo.filename = file_name;
      this.playerInfo.trackId = file_id;
      this.playerInfo.trackIndex = index;
      this.playerInfo.album = title + ' - ' + album;
      this.playerInfo.artist = artist;
      this.playerInfo.storage = storage;
      this.playerInfo.token = this.globals.access_token;
      this.player.start(this.playerInfo.trackId,true);
    }
  }
  /**
   Chegking tracks playing
   * @param e - event
   */
  checkPlaying(e){
    this.playerInfo.isPlaying = e;
    if(this.playerInfo.isPlaying != 'play'){
      if(this.selectedRows.length > 0)
        this.addPlayerInfo(0,this.selectedRows[0]);
      else
        this.addPlayerInfo(1,this.tabs[this.selectedTabIndex].selectedRows[0]);
    }
  }
  /**
   Select row after click on it in left side
   * @param e - event
   * @param id - track id
   * @param ind - row index
   */
  selectRow(e, id, ind){
    this.deselectPlaylistsRows(null);
    var selected = this.checkSelectedRow(this.selectedRows, id);
    if( e.ctrlKey || e.metaKey ){
      if(selected == -1){
        this.selectedRows.push(id);
      }
      else{
        this.selectedRows.splice(selected,1);
      }
    }
    else if(e.shiftKey){
      if( this.selectedRows.length > 0 ){
        this.checkStartRows( ind );
      }
      else{
        this.selectedRows[0] = id;
      }
    }
    else{
      this.selectedRows = [];
      if( selected == -1 ){
        this.selectedRows[0] = id;
        if(this.playerInfo.isPlaying!='play'){
          var index= this.getSelectedPosition( this.tracks, id );
          this.playerInfo.trackIndex = index;
          this.playerInfo.trackId = id;
          this.playerInfo.filename = this.tracks[index].file_name;
          this.playerInfo.album = this.tracks[index].title + ' - ' + this.tracks[index].album;
          this.playerInfo.artist = this.tracks[index].artist;
          this.playerInfo.storage = this.tracks[index].storage;
          this.playerInfo.token = this.globals.access_token;
        }
      }
      else{
        this.selectedRows.splice(0,1);
      }
    }
  }
  /**
   Deselect rows in right side
   * @param index - tab index
   */
  deselectPlaylistsRows(index){
    for( var i = 0; i < this.tabs.length; i++ ){
      if(i != index)
        this.tabs[i].selectedRows = [];
    }
  }
  /**
   Select row after click on it in right side
   * @param e - event
   * @param id - track id
   * @param pivot_id - track id in playlist
   * @param index - tab index
   */
  selectPlaylistRow( e, id, pivot_id, index ){
    this.selectedRows = [];
    var selected = this.checkSelectedRow(this.tabs[this.selectedTabIndex].selectedRows, pivot_id);
    this.deselectPlaylistsRows(this.selectedTabIndex);
    if( e.ctrlKey || e.metaKey ){
      if(selected == -1){
        this.tabs[this.selectedTabIndex].selectedRows.push(pivot_id);
      }
      else{
        this.tabs[this.selectedTabIndex].selectedRows.splice(selected,1);
      }
    }
    else if(e.shiftKey){
      if( this.tabs[this.selectedTabIndex].selectedRows.length > 0 ){
        this.checkStartPlaylistRows( index );
      }
      else{
        this.tabs[this.selectedTabIndex].selectedRows[0] = pivot_id;
      }
    }
    else{
      this.tabs[this.selectedTabIndex].selectedRows = [];
      if(selected == -1){
        this.tabs[this.selectedTabIndex].selectedRows[0] = pivot_id;
        if(this.playerInfo.isPlaying!='play'){
          this.playerInfo.trackIndex = index;
          this.playerInfo.trackId = id;
          this.playerInfo.filename = this.tabs[this.selectedTabIndex].tracks[index].file_name;
          this.playerInfo.artist = this.tabs[this.selectedTabIndex].tracks[index].artist;
          this.playerInfo.album = this.tabs[this.selectedTabIndex].tracks[index].title + ' - ' + this.tabs[this.selectedTabIndex].tracks[index].album;
          this.playerInfo.storage = this.tabs[this.selectedTabIndex].tracks[index].storage;
          this.playerInfo.token = this.globals.access_token;
        }
      }
      else{
        this.tabs[this.selectedTabIndex].selectedRows.splice(0,1);
      }
    }
  }
  /**
   Check start and end of selected rows in playlist
   * @param index - row index
   * @returns {Array | any[]}
   */
  checkStartPlaylistRows( index ){
    for( var i = 0; i < index; i++ ){
      
        if(this.tabs[this.selectedTabIndex].tracks[i].pivot_id == this.tabs[this.selectedTabIndex].selectedRows[this.tabs[this.selectedTabIndex].selectedRows.length-1]){
          
          this.setMultipleRows( i, index, 1 );
          return false;
        }
      //}
    }
    for(var i = this.tabs[this.selectedTabIndex].tracks.length-1; i > index; i-- ){
      if(this.tabs[this.selectedTabIndex].tracks[i].pivot_id == this.tabs[this.selectedTabIndex].selectedRows[this.tabs[this.selectedTabIndex].selectedRows.length-1]){
       
        this.setMultipleRows( i, index, 1 );
        return false;
      }
    }
    
  }
  /**
   Check start and end of selected rows in tracks library
   * @param index - row index
   * @returns {Array | any[]}
   */
  checkStartRows( index ){
    for (var i = 0; i < index; i++) {

      if (this.tracks[i].id == this.selectedRows[this.selectedRows.length - 1]) {

        this.setMultipleRows(i, index, 0);
        return false;
      }

    }
    for(var i = this.tracks.length-1; i > index; i-- ){
      if(this.tracks[i].id == this.selectedRows[this.selectedRows.length-1]){
        
        this.setMultipleRows( i, index, 0 );
        return false;
      }
    }
  }
 
  /**
   Select rows from start index to end index in tracks library
   * @param start - start index
   * @param end - end index
   * @param tracks - array of tracks
   * @returns {any[]}
   */
  setMultipleRows( start, end, arrIndex ){
    var arrClass = new Arrays();
    var s = start;
    var e = end;
    if(start > end){
      s = end;
      e = start;
    }
    s = s + 1;
    for( var i = s; i <= e; i++){
      if(arrIndex == 1){
        if(start >= end ){
          if(arrClass.checkElementInArray(this.tabs[this.selectedTabIndex].selectedRows,this.tabs[this.selectedTabIndex].tracks[i].pivot_id)){
            var selected = arrClass.getElementPositionInArray(this.tabs[this.selectedTabIndex].selectedRows,this.tabs[this.selectedTabIndex].tracks[i].pivot_id);
            this.tabs[this.selectedTabIndex].selectedRows.splice(selected,1);
          }
        }
        else{
          if(!arrClass.checkElementInArray(this.tabs[this.selectedTabIndex].selectedRows,this.tabs[this.selectedTabIndex].tracks[i].pivot_id)){
            this.tabs[this.selectedTabIndex].selectedRows.push(this.tabs[this.selectedTabIndex].tracks[i].pivot_id);
          }
        }
      }
      else{
        if(start >= end ){
          if(arrClass.checkElementInArray(this.selectedRows,this.tracks[i].id)){
            var selected = arrClass.getElementPositionInArray(this.selectedRows,this.tracks[i].id);
            this.selectedRows.splice(selected,1);
          }
        }
        else{
          if(!arrClass.checkElementInArray(this.selectedRows,this.tracks[i].id)){
            this.selectedRows.push(this.tracks[i].id);
          }
        }
      }
    }
  }
  /**
   Check selected row
   * @param arr - array of tracks
   * @param id - track id
   * @param pivot - track id in playlist
   * @returns {boolean}
   */
  checkSelectedRow(arr, id){
    if( arr != undefined ){
      for( var i = 0; i < arr.length; i++ ){
        if(arr[i] == id){
          return i;
        }
      }
    }
    return -1;
  }
  /**
   Open popup with smart filters
   */
  openFilterPopup(){
    this.smartFilters = JSON.parse(localStorage.getItem('smartFilters'));
    if(this.smartFilters == '' || this.smartFilters == null )
    this.smartFilters = [{
      categorySelect: null,
      compare: [],
      compareSelect: null,
      field: null,
      arrFields: ['',''],
      type: 'str',
      isHidden:false
    }];
    this.focusInput();
    this.popups.smartFilterPopupOpened = !this.popups.smartFilterPopupOpened;
  }
  /**
   Open popup with tags
   */
  openTagsPopup(){
    this.popups.manageTagsPopupOpened = !this.popups.manageTagsPopupOpened;
    this.focusInput();
    this.getTags();
  }
  /**
   Get tags info
   */
  getTags(){
    this.tagService.getTags().subscribe((tags:any) => {

      var ti = 0;
      for( var i = 0; i < 10; i++){
        var tag = {
          'name' : '',
          'color': 'undefined',
          'bgColor': '#ffffff',
          'info' : null
        };
        if(tags[ti] != undefined && tags[ti] != 'undefin' && parseInt(tags[ti].position) == i+1 && tags.error == undefined){
          tag.name =  tags[ti].name;
          tag.color = tags[ti].color;
          tag.bgColor = tags[ti].color;
          tag.info = tags[ti];
        }
        ti++;
        this.tags[i] = tag;
      }
    });
  }
  /**
   Close all popups
   * @param e - event
   * @returns {boolean}
   */
  popupClose(e){
    this.trackInfo = null;
    this.popups.otherPlaylistsPopupOpened = false;
    this.popups.popupOpened = false;
    this.popups.editPopupOpened = false;
    this.popups.smartFilterPopupOpened = false;
    this.popups.manageTagsPopupOpened = false;
    this.popups.playlistPopupOpened = false;
    this.popups.linkedPlaylistsPopupOpened = false;
    this.popups.linkedPlaylistEditPopupOpened = false;
    this.drops.fp = false;
    this.drops.clients = false;
    this.isSmartSearch = false;
    this.selectedqualityOption='';
    this.selectedclipTypeOption='';
    this.selectedmusicGenre1= -1;
    this.selectedmusicGenre3= -1;
    this.selectedmusicGenre2= -1;
    this.searchBulkUidPopUpOpen =false;
    this.blurInput();
    return false;
  }
  /**
   Close popup with linked playlists
   * @param e - event
   * @returns {boolean}
   */
  popupLinkedClose(e){
    e.preventDefault();
    this.popups.linkedPlaylistsPopupOpened = true;
    this.popups.linkedPlaylistEditPopupOpened = false;
  }
  /**
   Go to previous page
   * @param e - event
   * @returns {boolean}
   */
  backBtnClick(e){
    e.preventDefault();
    var catID = 0;
    catID = this.tabs[0].playlistInfo.foundation_category_id;
    let navigationExtras: NavigationExtras={
      queryParams: {
        'cat': catID,
        'pcat': this.tabs[0].category_parent
      }
    };
    this.router.navigate([this.link], navigationExtras);
  }
  /**
   Get parent category
   * @param catid - category id
   * @param tabindex - tab index
   */
  getCategoryParent( catid, tabindex){
    this.foundationCategoriesService.getPlaylistCategoriesByID(catid).subscribe((category:any) => {
      this.tabs[tabindex].tab_str += ' -> ' + category.name + ' -> ' + this.tabs[tabindex].playlistInfo.name;
      this.tabs[tabindex].category_parent = category.parent_id;
    });
  }
  /**
   Get parent site
   * @param catid - channel id
   * @param tabindex - tab index
   */
  getCPCategoryParent( catid, tabindex){
    this.chanelsService.getPlaylistCategoriesByID(catid).subscribe((category:any) => {
      this.tabs[tabindex].category_parent = category.parent_id;
    });
  }
  /**
   Add new additional attribute
   */
  newAttribute(){
    var input = (<HTMLInputElement>document.getElementById('additional'));
    if(input.value.trim() != '')
      this.additional.push(input.value);
    input.value = '';
  }
  /**
   Remove additional attribute
   * @param id - attribute id
   */
  removeAdditional(id){
    this.additional.splice(id, 1);
  }
  /**
   Update track information
   * @param e - event
   * @param {NgForm} form - form object
   */
  trackDataUpdate(e,form: NgForm){
    form.value['title'] = form.controls['title'].value;
    form.value['album'] = form.controls['album'].value;
    form.value['artist'] = form.controls['artist'].value;
    form.value['year'] = form.controls['year'].value;
    form.value["clipType"]=this.selectedclipTypeOption ?? "A";  //A->  Audio(default) and V->video 
    form.value["quality"]=this.selectedqualityOption ?? "H";  //H-> High(default) and L->Low
    form.value["genre1"]=this.selectedmusicGenre1 ;
    form.value["genre2"]=this.selectedmusicGenre2 ;
    form.value["genre3"]=this.selectedmusicGenre3 ;

    if(this.trackInfo.data.title == '' || this.trackInfo.data.title==null){
      this.alert.alertOpen("Please enter track title",1);
    }
    else if(form.value.artist == ''||form.value.artist == null){
      this.alert.alertOpen("Please enter artist name",1);
    }
    else{
      var str = Object.keys(form.value).map(function(key){
        var val = '';
        if(key == 'mc_tags'){
          val = form.value[key].map(a => encodeURIComponent(a.item_id));
        }
        else{
          val= encodeURIComponent(form.value[key]);
        }
        if(val == 'null' || val == null){
          val = '';
        }
        return encodeURIComponent(key) + '=' + val;
      }).join('&');
      this.trackService.updateTrackInfo(str).subscribe(dataInfo=>{
        if(this.searchStr == ''){
          var limit = this.infiniteScroll.items + this.infiniteScroll.addItems;
          this.trackService.getTracks(this.tracksOrder.order,this.tracksOrder.orderBy,0,limit).subscribe((tracks:any)=>{
            this.tracksCount.total = tracks.total;
            this.tracksCount.display = tracks.total;
            this.tracks = tracks.list;
          });
        }
        else{
          this.search();
        }
        this.updatePlaylists();
        this.alert.alertOpen( "Info updated" ,0);
      });
    }
  }
  /**
   Delete track from library
   * @param id - track id
   */
  deleteTrack(id){
    this.trackService.deleteTrack(id).subscribe(trackDel=>{
      this.trackService.getTracks(this.tracksOrder.order,this.tracksOrder.orderBy,0,100).subscribe((tracks:any)=>{
        this.tracksCount.total = tracks.total;
        this.tracksCount.display = tracks.total;
        this.tracks = tracks.list;
      });
      this.updatePlaylists();
      this.alert.alertOpen("Track deleted",0);
      this.popupClose(window.event);
    });
  }
  /**
   Checking entering query in search field
   * @param e - event
   */
  searchPress(e){
    this.isSmartSearch = false;
    this.isSearchUID=false;
    this.infiniteScroll.items = 0;
    clearTimeout(this.searchTimeout);
    this.searchTimeout = setTimeout(()=>{
      this.isLoad = true;
      if(this.searchStr!='')
        this.search();
      else
        this.clearSearch(e);
    }, 1000);
  }

  /**
   Get search results
   */
  search(){
    this.isSmartSearch=false;
    this.isSearchUID=false;
    var offset = this.infiniteScroll.items;
    var limit = this.infiniteScroll.addItems;
    if(this.searchStr != ''){
      this.trackService.search(this.searchStr, this.tracksOrder.order, this.tracksOrder.orderBy,offset, limit).subscribe( (tracks:any) => {
        if(tracks.result == undefined && tracks.result != 'No result'){
          if(offset == 0)
            this.tracks = tracks.list;
          else
            this.tracks = this.tracks.concat(tracks.list);
            this.tracksCount.total = tracks.total;
            this.tracksCount.display = tracks.total;

        }
        else{
          if(offset == 0){
            this.tracks = [];
            this.tracksCount.display = 0;
          }
        }
        this.isLoad = false;
      });
      
    }
    else{
      this.clearSearch(window.event);
    }
    return false;
  }
  /**
   Crear search results and get all tracks in playlist and tracks library
   * @param e - event
   * @returns {boolean}
   */
  clearSearch(e){
    this.searchStr = '';
    this.isLoad = true;
    this.isSmartSearch = false;
    this.trackService.getTracks(this.tracksOrder.order,this.tracksOrder.orderBy,0,100).subscribe((tracks:any) => {
      this.tracksCount.total = tracks.total;
      this.tracksCount.display = tracks.total;
      this.tracks = tracks.list;
      this.isLoad = false;
    });
    
    for (let row of this.tabs) {
      row.playlistInfo.updated_at = 1;
    }
    this.updateTabs();
    return false;
  }
  /**
   Clear information for tag
   * @param e - event
   * @param id - tag id(index)
   */
  clearTags( e, id){
    e.preventDefault();
    var tag = {
      'name' : '',
      'color': 'undefined',
      'bgColor': '#ffffff',
      'info' : null
    };
    this.tags[id] = tag;
  }
  /**
   Checking if tag color changed
   * @param id
   */
  changeBg(id){
    if(this.tags[id].color != undefined){
      this.tags[id].bgColor = this.tags[id].color;
    }
  }
  /**
   Set background for row from tags
   * @param arr - array of tags for track
   * @returns {any}
   */
  setBgFromTag(arr){
    var myStyles:any;
    if(arr!=undefined&&arr.length>0){
      myStyles={
        'background-color':arr[arr.length-1].color
      }
    }
    return myStyles;
  }
  /**
   Checking for changing select with smart filters
   * @param e - event
   * @param index - smart filter index
   */
  changeSmartSelect(e,index){
   
    for(var i = 0; i<this.defaultSmartFilters.length; i++ ){
      if(this.defaultSmartFilters[i].field == e.target.value){
        this.smartFilters[index].compare = this.defaultSmartFilters[i].сompare_str;
        this.smartFilters[index].type = this.defaultSmartFilters[i].type;
        /* Smart filter task - Start
        // if(e.target.value == 'foundation_play_list_id')
        //   this.addStaticFilterField('add_to_cp_playlist', index );
        // else
        //   this.removeStaticFilterField(index);
        Smart filter task - End */
      }
    }
      if(e.target.value == "cp_playlist_id"){
      this.profileFilterCount = this.smartFilters.filter(
        (obj) => obj.categorySelect === 'cp_playlist_id' ).length;
      }
      if(e.target.value == "foundation_play_list_id"){

        this.profileFilterCount = this.smartFilters.filter(
          (obj) => obj.categorySelect === 'foundation_play_list_id' ).length;
      }
     
  }
  /**
   Add static smart filter field
   * @param name - smart filter title
   * @param index - smart filter index
   */
  addStaticFilterField(name,index){
    for(var i = 0; i < this.defaultSmartFilters.length; i++ ){
      if(this.defaultSmartFilters[i].field == name){
        var o = {
          categorySelect: name,
          compare: this.defaultSmartFilters[i].сompare_str,
          compareSelect: null,
          field: null,
          arrFields: ['', ''],
          type: this.defaultSmartFilters[i].type,
          isHidden: true
        };
        this.smartFilters.splice(index + 1, 0, o);
        break;
      }
    }
  }
  /**
   Add new smart filter field
   * @returns {boolean}
   */
  addFilterField(){
    var o = {
      categorySelect: null,
      compare: [],
      compareSelect: null,
      field: null,
      arrFields: ['', ''],
      type: 'str',
      isHidden: false
    };
    this.smartFilters.push(o);
    return false;
  }
  /**
   Remove static smart filter field
   * @param index - smart filter index
   */
  removeStaticFilterField(index){
    if(this.smartFilters[index + 1] && this.smartFilters[index + 1].categorySelect == 'add_to_cp_playlist')
      this.smartFilters.splice( index + 1, 1 );
  }
  /**
   Remove smart filter field
   * @param index - smart filter index
   * @returns {boolean}
   */
  removeFilterField(e,index){
    var count = 1;
    var ind = index;
    /* Smart filter task - Start
    // if( this.smartFilters[index].categorySelect == 'foundation_play_list_id' || this.smartFilters[index].categorySelect == 'add_to_cp_playlist' )
    //   count = 2;
    // if(this.smartFilters[index].categorySelect == 'add_to_cp_playlist')
    //   ind = index - 1;
    Smart filter task - End */
    this.smartFilters.splice( ind, count );
    if(this.smartFilters.length < 1){
      var o = {
        categorySelect: null,
        compare: [],
        compareSelect: null,
        field: null,
        arrFields: ['', ''],
        type: 'str',
        isHidden: false
      };
      this.smartFilters[0] = o;
    }
   this.profileFilterCount = this.smartFilters.filter(
      (obj) => obj.categorySelect === 'cp_playlist_id').length;
    
    return false;
  }
  /**
   Get search results from smart filters
   * @param clicked(boolean) - if true search results will show from start
   * @returns {boolean}
   */
  smartSearch(clicked){
      
    this.blurInput();
    this.isLoad = true;
    this.isSearchUID=false;
    this.searchStr='';
    var str = '';
    if(clicked)
      this.infiniteScroll.items = 0;
    var offset = this.infiniteScroll.items;
    var limit = this.infiniteScroll.addItems;
    this.isSmartSearch = true;
    if(this.checkSmartFilters()){
      localStorage.setItem('smartFilters',JSON.stringify(this.smartFilters));
      let add_to_cp_playlist = this.smartFilters.find(x => x.categorySelect == 'add_to_cp_playlist');
      let add_to_fp_playlist=this.smartFilters.find(x => x.categorySelect == 'add_to_fp_playlist');
      let foundation_play_list_id = this.smartFilters.find(x => x.categorySelect == 'foundation_play_list_id');
      
      if(add_to_cp_playlist && !foundation_play_list_id){
        this.alert.alertOpen("Please add the playlist name filter to proceed with this!",1);
        this.isLoad = false;
        return false;
      }

      for( var i = 0; i < this.smartFilters.length; i++ ){
        var el = this.smartFilters[i];
        if(el.categorySelect != null && el.compareSelect != null){
          str += el.categorySelect + '[' + el.compareSelect + ']=';
          if( el.type == 'str' ){
            str += encodeURIComponent(el.field);
          }
          else if(el.type == 'int'){
            str += "[" + el.arrFields[0];
            if(el.compareSelect == 'between'){
              str += "," + el.arrFields[1];
            }
            str += "]";
          }
          else if(el.type == 'date'){
            str += "['" + el.arrFields[0].formatted + "','" + el.arrFields[1].formatted + "']";
          }
          str += '&';
          if(el.categorySelect == 'cp_playlist_id'){
            str += 'cp_playlist_name'+'[' + i + ']'+'[' + el.compareSelect + ']=';
            str += encodeURIComponent(el.field);
            str += '&';
          }

          if(el.categorySelect == 'foundation_play_list_id'){
            str += 'fp_playlist_name'+'[' + i + ']'+'[' + el.compareSelect + ']=';
            str += encodeURIComponent(el.field);
            str += '&';
          }
        }
        else{
          this.alert.alertOpen("One or more search criteria is blank. Please fill them and search",1);
          this.isLoad = false;
          return false;
        }
      }
      str += 'match_type=' + this.matchSelect;
      str += '&and_or_type=' + this.andOrSelect;
      str+= '&orderBy=' + this.tracksOrder.orderBy + '&orderDirect=' + this.tracksOrder.order + '&offset=' + offset + '&limit=' + limit
      + '&filterPage=fpEditor';
      this.smartService.getFilteredTracks(str).subscribe((tracks:any) => {
        this.isLoad = false;
        if(tracks.result!='empty'){
          if(offset==0)
            this.tracks = tracks.list;
          else
            this.tracks = this.tracks.concat(tracks.list);
          this.tracksCount.display = tracks.total;
          this.tracksCount.total = tracks.total;
        }
        else{
          if(offset == 0){
            this.alert.alertOpen("No results",0);
            this.isSmartSearch = false;
          }
        }
        this.popups.smartFilterPopupOpened = false;
       
      });
    }
    else{
      this.alert.alertOpen("One or more search criteria is blank. Please fill them and search",1);

      this.isLoad = false;
    }
  }
  /**
   Check smart filter for fill
   * @returns {boolean}
   */
  checkSmartFilters(){
    for( var i = 0; i < this.smartFilters.length; i++ ){
      var el = this.smartFilters[i];
      if( el.categorySelect != null && el.compareSelect != null){
        if( el.type == 'str' ){
          if(el.field != '')
            return true;
        }
        else if(el.type == 'int' || el.type == 'date'){
          if(el.arrFields[0] != '')
            return true;
        }
      }
    }
    return false;
  }
  /**
   Clear smart filter search results and get all track library
   * @param e - event
   */
  clearFilter(e){
  this.profileFilterCount = 0;
    localStorage.removeItem("smartFilters");
    this.trackService.getTracks(this.tracksOrder.order,this.tracksOrder.orderBy,0,100).subscribe((tracks:any)=>{
      this.tracks = tracks.list;
      this.tracksCount.total = tracks.total;
      this.tracksCount.display = tracks.total;
      this.smartFilters = [{
        categorySelect: null,
        compare: [],
        compareSelect: null,
        field: null,
        arrFields: ['', ''],
        type: 'str',
        isHidden:false
      }];
    });
    this.isSmartSearch = false;
    this.popupClose(e);
  }
  /**
   Open dropdown with another playlist
   * @param e - event
   * @param hover - checking if user hover mouse to dropdown
   * @returns {boolean}
   */
  openAnotherPlaylists(e,hover){
    e.preventDefault();
    if(this.selectedRows.length != 0){
      if(!this.openPlaylistDrop){
        if(!hover){
          var catId =this.tabs[0].playlistInfo.parent_node.id;
          this.foundationCategoriesService.getPlaylistsCategories(catId).subscribe((cats:any) => {
            this.openSubcats.subcatsArr = cats[0].children;
          });
          this.openPlaylist.category.id = null;
          this.openPlaylist.subcategory.id = null;
          this.openPlaylist.playlists.id = null;
        }
      }
      if(hover){
        this.openPlaylistDrop2 = false;
      }
      else{
        this.openPlaylistDrop2 = !this.openPlaylistDrop2;
      }
    }
    else{
      if(!hover)
        this.alert.alertOpen("No Tracks selected!",1);
    }
  }
  /*getSimilarPlaylists(){
    this.playlistService.getPlaylists(this.tabs[0].playlistInfo.foundation_category_id).subscribe(playlists => {
      this.similarPlaylists = playlists;
    });
  }*/
  /**
   Parse time
   * @param time - time in seconds
   * @returns {string} - parsed time
   */
   convertTime(timeInSeconds) {
    var str = '';
    if (!isNaN(timeInSeconds)) {
        var milliseconds = Math.round(timeInSeconds * 1000); // convert seconds to milliseconds
        var totalSeconds = Math.floor(milliseconds / 1000);
        var hours = Math.floor(totalSeconds / 3600);
        var minutes = Math.floor((totalSeconds % 3600) / 60);
        var seconds = totalSeconds % 60;
        var remainingMilliseconds = milliseconds % 1000;

        // Ensure two digits for hours, minutes, seconds, and three digits for milliseconds
        str += hours.toString().padStart(2, '0') + ':';
        str += minutes.toString().padStart(2, '0') + ':';
        str += seconds.toString().padStart(2, '0') + '.';
        str += remainingMilliseconds.toString().padStart(3, '0');
    } else {
        str = '00:00:00.000';
    }
    return str;
    }
  /**
   Open popup for creating playlist
   * @param e - event
   * @returns {boolean}
   */
  openCreatePlaylistPopup(e){
    e.preventDefault();
    this.updateCategoriesList();
    this.focusInput();
    this.popups.playlistPopupOpened = true;
  }
  /**
   Get categories list
   */
  updateCategoriesList() {
    this.foundationCategoriesService.getPlaylistsCategories('').subscribe(playCats => {
      this.categoriesList = playCats;
    });
  }
  /**
   Change categories select
   * @param e - event
   */
  changeCategory(e){
    this.updateSubcategories(e.target.value);
  }
  /**
   Get subcategories list
   * @param id - category id
   */
  updateSubcategories(id){
    this.foundationCategoriesService.getPlaylistsCategories(id).subscribe((categories:any) => {
      this.subcategoriesList = categories[0].children;
    });
  }
  /**
   Update playlist data
   * @param e - event
   * @param {NgForm} form - form
   */
  playlistDataUpdate(e,form:NgForm){
    var categoryID = form.value.foundation_category_id;
    let subcategoryID = form.value.foundation_subcategory_id;
    if (subcategoryID != 'undefined' && subcategoryID != null) {
      categoryID = subcategoryID;
    }
    delete form.value.foundation_subcategory_id;
    delete form.value.foundation_category_id;

    var str = Object.keys(form.value).map(function (key) {
      var val = encodeURIComponent(form.value[key]);
      if(val == 'null' || val == null){
        val = '';
      }
      return encodeURIComponent(key) + '=' + val;
    }).join('&');

    if (this.playlistFields.id == null) {
      this.playlistService.createPlaylist(categoryID, str).subscribe((playlists:any) => {
        this.alert.alertOpen("Playlist added",0);
        this.popupClose(e);
        this.addTab(e,playlists.id);
      });
    }
  }
  /**
   Open playlist in new window
   * @param e - event
   */
  openNewWindow(e){
    e.preventDefault();
    var str = window.location.href+'&sh=0';

    window.open( str,"_blank" );
  }
  /**
   Open playlist and track library in separate windows
   * @returns {boolean}
   */
  openSeparateWindow(){
    this.screenParams.isFullScreen = true;
    var str = window.location.href + '&ipl=true&ifs=true';
    window.open( str,"_blank" );
    return false;
  }
  /**
   Get last playlist autosave
   * @param sec - seconds
   * @returns {string} - parsed string
   */
  lastAutosave(sec:any){
    var d:any = new Date();
    var t = Math.round( d / 1000 );
    var str='';
    if(t - sec < 60){
      str = 'less than minute';
    }
    else if(t - sec >= 60 && t - sec < 120){
      str = Math.round((t-sec)/60 )+' minute';
    }
    else if( t - sec >= 120 && t-sec < 60 * 60 ){
      str = Math.round((t-sec)/60 )+' minutes';
    }
    else if(t - sec >= 60 * 60 && t-sec < 60 * 120 ){
      str = Math.round((t-sec)/(60*60) )+' hour';
    }
    else if( t - sec >= 60 * 120 && t-sec < 60 * 60 * 24 ){
      str = Math.round((t-sec)/(60*60) )+' hours';
    }
    else if( t - sec >= 60 * 60 * 24 && t - sec < 60 * 60 * 24 * 2 ){
      str = Math.round(( t - sec ) / ( 60 * 60 * 24 ) ) + ' day';
    }
    else if( t - sec >= 60 * 60 * 24 * 2 ){
      str = Math.round(( t- sec ) / ( 60 * 60 * 24 ) ) + ' days';
    }
    return str;
  }
  /**
   ??
   */
  getTrackInfoById(id){
    for( var i = 0; i < this.tracks.length; i++ ){
      if(this.tracks[i].id == id){
        return i;
      }
    }
  }
  /**
   Get track id in library by id in playlist
   * @param pivot - track id in playlist
   * @param list - track in playlist
   * @returns {any}
   */
  getTrackIdByPivot( pivot , list ){
    for( var i = 0; i < list.length; i++ ){
      if( list[i].pivot_id == pivot ){
        return list[i].id;
      }
    }
  }

  /**
   Open popup with linked Client playlists to current Foundation playlist
   * @param e - event
   * @param pid - playlist id
   * @returns {boolean}
   */
  openLinkedPlaylistsPopup( e, pid ){
    this.updateLinkedPlaylists(pid);
    return false;
  }
  /**
   Open popup for edit Client playlists
   * @param e - event
   * @param pid - playlist id
   * @returns {boolean}
   */
  openLinkedPlaylistsEditPopup(e, pid){
      this.linkedPlaylistFields.id = pid;
      this.popups.linkedPlaylistsPopupOpened = false;
      if( pid != null ){
        this.cpService.getPlaylistById(pid).subscribe((playlist:any) => {
          console.log(playlist);
          this.linkedPlaylistFields.id = playlist.id;
          this.linkedPlaylistFields.chanel = playlist.cp_category_id;
          this.linkedPlaylistFields.user = playlist.client_id;
          this.updateSites(this.linkedPlaylistFields.user);
          this.chanelsService.getPlaylistsCategories(this.linkedPlaylistFields.chanel).subscribe((chanel:any)=>{
            this.linkedPlaylistFields.site = chanel.parent_id;
            this.updateChanels(this.linkedPlaylistFields.site);
          });
          this.linkedPlaylistFields.name = playlist.name;
          this.linkedPlaylistFields.description = playlist.description;
          this.popups.linkedPlaylistEditPopupOpened = true;
        });
      }

      return false;
  }

  /**
   Get sites
   * @param uid - client id
   */
  updateSites(uid){
    this.chanelsService.getSitesByUser(uid).subscribe((sites:any) => {
      console.log(sites);
      this.linkedPlaylistFields.sitesList = sites;
    });
  }

  /**
   Update channels
   * @param sid - site id
   */
  updateChanels(sid){
    this.chanelsService.getPlaylistsCategories(sid).subscribe((chanels:any) => {
      this.linkedPlaylistFields.channelsList = chanels.children;
    });
  }

  /**
   Changing site in select
   * @param e - event
   */
  siteChange(e) {
    this.updateChanels(e.target.value);
  }

  /**
   Update client playlist
   * @param e - event
   * @param {NgForm} form - form
   * @returns {boolean}
   */
  linkedPlaylistUpdate(e, form: NgForm) {
    if(form.value.chanel_id != null){
      this.linkedPlaylistFields.chanel = form.value.chanel_id;
      delete form.value.site_id;
      delete form.value.chanel_id;
    }
    else{
      this.linkedPlaylistFields.chanel = null;
      this.alert.alertOpen("Please select site and channel",1);
      return false;
    }

    var str = Object.keys(form.value).map(function (key) {
      var val = encodeURIComponent(form.value[key]);
      if(val == 'null' || val == null){
        val = '';
      }
      return encodeURIComponent(key) + '=' + val;
    }).join('&');
    if(this.linkedPlaylistFields.chanel != null && this.linkedPlaylistFields.user != null){
      if (this.linkedPlaylistFields.id != null) {
        this.cpService.updatePlaylist(this.linkedPlaylistFields.chanel, this.linkedPlaylistFields.id, str).subscribe(playlists => {
          this.alert.alertOpen("Playlist updated",0);
          this.updateLinkedPlaylists(this.tabs[this.selectedTabIndex].playlistInfo.id);
        });
      }
      this.popupLinkedClose(e);
    }
    else{
      this.alert.alertOpen("Please select channel",1);
    }

  }

  /**
   Delete Client playlist
   * @param id - playlist id
   */
  deleteLinkedPlaylist(id) {
    this.cpService.deletePlaylist(id).subscribe((playlistDel:any) => {
      if(playlistDel.error != undefined){
        this.alert.alertOpen(playlistDel.error,1);
      }
      else{
        this.updateLinkedPlaylists(this.tabs[this.selectedTabIndex].playlistInfo.id);
        this.alert.alertOpen("Playlist deleted",0);
      }
    });
  }

  /**
   Get linked playlists for current foundation playlist
   * @param pid - playlist id
   */
  updateLinkedPlaylists(pid){
    this.playlistService.getLinkedPlaylists(pid).subscribe((playlists:any) => {
      this.linkedPlaylists = playlists;
      this.popups.linkedPlaylistsPopupOpened = true;
    });
  }
  /**
   Get total time in seconds for selected tracks
   * @returns {number} - time in seconds
   */
  getSelectedTime(){
    var time = 0;
    for(var i=0;i<this.tabs[this.selectedTabIndex].selectedRows.length;i++){
      var index = this.getPlaylistTrackInfoById(this.tabs[this.selectedTabIndex].selectedRows[i]);
      time += parseInt(this.tabs[this.selectedTabIndex].tracks[index].length_sec);
    }
    return time;
  }
  /**
   Get track position in array
   * @param id - track id
   * @returns {number} - track position
   */
  getPlaylistTrackInfoById(id){
    for( var i = 0; i < this.tabs[this.selectedTabIndex].tracks.length; i++ ){
      if(this.tabs[this.selectedTabIndex].tracks[i].pivot_id == id){
        return i;
      }
    }
  }
  /**
   Open dropdowns with list of playlists
   * @param index - type of playlist 1=client playlists, 2= foundation playlists
   */
  openDrop(index){
    if(index==1){
      this.drops.clients = !this.drops.clients;
      this.drops.fp = false;
    }
    else if(index==2){
      this.drops.fp = !this.drops.fp;
      this.drops.clients = false;
    }
  }
  /**
   Click outside element
   * @param $event
   */
  @HostListener('document:click', ['$event']) clickedOutside($event){
    // Do nothing
  }
  /**
   Click inside element
   * @param e - event
   */
  clickInside(e){
    e.preventDefault();
    e.stopPropagation();
  }
  /**
   Open playlists dropdown
   * @param e - event
   * @param hover - hover on dropdown
   */
  openPlaylists(e, hover){
    if(!this.openPlaylistDrop){
    
      this.getPlaylistInfo(0,null);

     
      this.getCPPlaylistInfo(0,null);
    }
    if(hover){
      this.openPlaylistDrop = false;
     
    }
    else{
      this.openPlaylistDrop = !this.openPlaylistDrop;
    }
  }
  /**
   Get lists of categories/sub categories/foundation playlists
   * @param type - type of action 0-get categories, 1-get sub categories,2-get playlists,3-open selected playlist
   * @param id - id of category/sub category/foundation playlist
   */
  getPlaylistInfo( type, id ){
    if(type == 0){
      this.foundationCategoriesService.getPlaylistsCategories('').subscribe((cats:any) => {
        this.openPlaylist.category.catsArr = cats;
      });
    }
    else if(type == 1){
      if(this.openPlaylist.category.id == id)
        this.openPlaylist.category.id = null;
      else
        this.openPlaylist.category.id = id;
    }
    else if(type == 2){
      this.playlistService.getPlaylists(id).subscribe((playlist:any)=>{
        if(this.openPlaylist.subcategory.id == id){
          this.openPlaylist.subcategory.id = null;
        }
        else {
          this.openPlaylist.playlists.playlistsArr = playlist;
          this.openPlaylist.subcategory.id = id;
        }
      });
    }
    else if(type == 3){
      if(this.openPlaylistDrop){
        this.openPlaylistDrop = false;
        var newtab = {
          selected: true,
          playlistId: null,
          category_parent: null,
          tabid: this.tabs.length,
          tracks: [],
          playlistInfo: null,
          averageBpm: 0,
          selectedRows: [],
          order: 'ASC',
          orderBy: 'add_date',
          tab_str: '',
          type: 'fp'
        };
        this.tabs.push(newtab);
        this.setTabsInfo(this.tabs.length - 1, id);
        this.deselectTab(this.tabs.length - 1);
      }
      else if(this.openPlaylistDrop2){
        this.openPlaylistDrop2 = false;
        this.addTab(window.event,id);
      }
    }
  }
  /**
   Get lists of clients/sites/channels/client playlists
   * @param type - type of action 0-get clients list, 1-get sites,2-get channels,3-get playlists,4-open selected playlist
   * @param id - id of client/site/channel/client playlist
   */
  getCPPlaylistInfo( type, id ){
    if(type == 0){
      this.chanelsService.getClientsList().subscribe((clients:any) => {
        this.openCPPlaylist.users.usersArr = clients;
      });
    }
    else if(type == 1){
      this.chanelsService.getSitesByUser(id).subscribe( (sites:any) => {
        if(this.openCPPlaylist.users.id == id){
          this.openCPPlaylist.users.id = null;
        }
        else{
          this.openCPPlaylist.sites.sitesArr = sites;
          this.openCPPlaylist.users.id = id;
        }
      });
    }
    else if(type == 2){
      if(this.openCPPlaylist.sites.id == id)
        this.openCPPlaylist.sites.id = null
      else
        this.openCPPlaylist.sites.id = id;
    }
    else if(type == 3){
      this.cpService.getPlaylists(id).subscribe((playlist:any)=>{
        if(this.openCPPlaylist.channels.id == id){
          this.openCPPlaylist.channels.id = null;
        }
        else{
          this.openCPPlaylist.playlists.playlistsArr = playlist;
          this.openCPPlaylist.channels.id = id;
        }
      });
    }
    else if(type == 4){
      if(this.openPlaylistDrop){
        this.openPlaylistDrop = false;
        var newtab = {
          selected: true,
          playlistId: null,
          category_parent: null,
          tabid: this.tabs.length,
          tracks: [],
          playlistInfo: null,
          averageBpm: 0,
          selectedRows: [],
          order: 'ASC',
          orderBy: 'add_date',
          tab_str: '',
          type: 'cp'
        };
        this.tabs.push(newtab);
        this.setCPTabsInfo(this.tabs.length - 1, id);
        this.deselectTab(this.tabs.length - 1);
      }
    }
  }
  /**
   Set order for tracks in playlist
   * @param order - order rule. List of possible values [ 'title', 'artist', 'album', 'add_date', 'bpm','add_date_lib']
   */
  setOrder(order){
    
    if(this.tabs[this.selectedTabIndex].orderBy == order){
      if(this.tabs[this.selectedTabIndex].order == 'DESC')
        this.tabs[this.selectedTabIndex].order = 'ASC';
      else
        this.tabs[this.selectedTabIndex].order = 'DESC';
    }
    else{
      this.tabs[this.selectedTabIndex].orderBy = order;
    }
    if(this.searchStr != '')
      this.search();
    else
      this.updateTabs();
  }
  /**
   Set order for tracks library
   * @param order - order rule. List of possible values [ 'title', 'artist', 'album', 'add_date', 'bpm','add_date_lib']
   */
  setAllTracksOrder(order){
    
    if(this.tracksOrder.orderBy == order){
      if(this.tracksOrder.order == 'DESC')
        this.tracksOrder.order = 'ASC';
      else
        this.tracksOrder.order = 'DESC';
    }
    else{
      this.tracksOrder.orderBy = order;
    }
    this.infiniteScroll.items = 0;
    if (this.searchStr === '' && !this.isSmartSearch && !this.isSearchUID) {
      this.getAllTracks(this.tabs[0].playlistInfo.id);
  } else if (this.isSmartSearch) {
      this.smartSearch(false);
  } else if (this.isSearchUID) {
      this.searchUIDFunction();
  }else{
    this.search();
  }
  }
  /**
   Append items in track library after scroll page
   */
  appendItems() {
    this.infiniteScroll.items += this.infiniteScroll.addItems;
    if(this.searchStr == '' && !this.isSmartSearch)
      this.getAllTracks( this.tabs[0].playlistInfo.id );
    else if(this.isSmartSearch)
      this.smartSearch(false);
    else
      this.search();
  }
  /**
   Scrolling event
   */
  onScroll (e) {
    this.appendItems();
  }
  /**
   Confirm action in confirmation popup
   * @param e - event
   */
  isConfirm(e){
    if(e){
      if( this.confirmValues.type == 0 )
        this.removeTrack(this.confirmValues.id);
      else if( this.confirmValues.type == 1 )
        this.removeTracks();
      else if( this.confirmValues.type == 2 )
        this.deleteTrack(this.confirmValues.id);
      else if( this.confirmValues.type == 3 )
        this.deleteLinkedPlaylist(this.confirmValues.id);
      else if( this.confirmValues.type == 4 )
        this.exportPlaylist(this.confirmValues.id);
    }
  }
  /**
   Open confirmation popup
   * @param id - id of action
   * @param t - text for popup
   * @returns {boolean}
   */
  openConfirmPopup(id, t){
    var text = "Do you want delete track from this playlist?";
    if(t == 1)
      text = "Do you want delete tracks from this playlist?";
    if(t == 2)
      text = "Do you want delete this track?";
    if(t == 3)
      text = "Do you want delete this playlist?";
    if(t == 4)
      text = "Do you want to export the current playlist?";

    this.confirmValues.id = id;
    this.confirmValues.type = t;
    this.confirm.confirmOpen(text);
    return false;
  }
  /**
   Focusing in autocomplete inputs
   * @param e
   */
  autoFocus(e){
    if(e)
      this.focusInput();
    else
      this.blurInput();
  }
  /**
   Set param if input focused
   */
  focusInput(){
    this.isFocused = true;
  }
  /**
   Set param if input blured
   */
  blurInput(){
    this.isFocused = false;
  }
  /**
   Get information for next track in playlist or track library if opened track information edit popup
   * @returns {boolean}
   */
  getNextTrackInfo(e){
    var pos;

    if(this.tabs[this.selectedTabIndex].selectedRows.length > 0){
      pos = this.getSelectedPosition(this.tabs[this.selectedTabIndex].tracks, this.tabs[this.selectedTabIndex].selectedRows[0]);
      if(pos < this.tabs[this.selectedTabIndex].tracks.length)
        this.showEdit(e, this.tabs[this.selectedTabIndex].tracks[pos + 1].id, this.tabs[this.selectedTabIndex].tracks[pos + 1].pivot_id,1);
    }
    else{
      pos = this.getSelectedPosition(this.tracks, this.selectedRows[0]);
      if(pos < this.tracks.length)
        this.showEdit(e, this.tracks[pos + 1].id, null,0);
    }
    return false;
  }
  /**
   Get information for previous track in playlist or track library if opened track information edit popup
   * @returns {boolean}
   */
  getPrevTrackInfo(e){
    var pos;
    if(this.tabs[this.selectedTabIndex].selectedRows.length > 0){
      pos = this.getSelectedPosition(this.tabs[this.selectedTabIndex].tracks, this.tabs[this.selectedTabIndex].selectedRows[0]);
      if(pos > 0)
        this.showEdit(e, this.tabs[this.selectedTabIndex].tracks[pos - 1].id, this.tabs[this.selectedTabIndex].tracks[pos - 1].pivot_id,1);
    }
    else{
      pos = this.getSelectedPosition(this.tracks, this.selectedRows[0]);
      if(pos > 0)
        this.showEdit(e, this.tracks[pos - 1].id, null,0);
    }
    return false
  }
  /**
   Select playlist type in dropdowns
   * @param num - type of playlist 0-deselect playlists,1-client playlist,2-foundation playlist
   * @returns {boolean}
   */
  selectPlaylistType(num){
    if(this.playlistType == num)
      this.playlistType = 0;
    else
      this.playlistType = num;
    return false;
  }
  /**
   Close tab in right side
   * @param index - tab index
   * @returns {boolean}
   */
  closeTab(index){
    this.tabs.splice(index,1);
    if(this.selectedTabIndex >= this.tabs.length)
      this.deselectTab(this.tabs.length - 1);
    return false;
  }
  /**
   Check smart filter field
   * @param sf - smart filter
   * @returns {boolean}
   */
  checkSmartField(sf){
    if(sf.field == 'add_to_cp_playlist' && !sf.isHidden){
      return true;
    }
    return false;
  }
  /*getCategoiesTree(id,index){
    var str='';
    if(this.tabs[index].playlistInfo.foundation_category_id != null){
      str += this.tabs[index].playlistInfo.parent_node.name + ' -> ';
      this.foundationCategoriesService.getPlaylistCategoriesByID(this.tabs[index].playlistInfo.foundation_category_id).subscribe((category:any) => {
        console.log(category);
        str += category.name + ' -> ' + this.tabs[index].playlistInfo.name;
      });
    }
    //console.log(this.tabs);
    return str;
  }*/
  resetDisabledInputs(){
    this.disabledInputs.year = false;
    this.disabledInputs.album = false;
    this.disabledInputs.artist = false;
    this.disabledInputs.title = false;
  }

  searchPressCPDropdown(e){
    this.loadingCPSearch = true;
    clearTimeout(this.searchTimeout);
    this.searchTimeout = setTimeout(()=>{
      this.onSearchChangeCP(e);
      // else
      //   this.clearSearch(e);
    }, 1000);
  }

  onSearchChangeCP(e) {
    this.loadingCPSearch = true;
    this.filteredParentIDsCP = [];
    this.filteredChildIDsCP = [];
    this.filteredParentIDsFP = [];
    this.filteredChildIDsFP = [];
    if(this.searchStrCP == ''){
      this.loadingCPSearch = false;
      return false;
    }
    
    this.playlistService.searchPlaylistsCP(this.searchStrCP).subscribe( (c_ids:any) => {
      
      if (c_ids) {
        this.filteredParentIDsCP = c_ids.parent_ids;
        this.filteredChildIDsCP = c_ids.child_ids;
        this.filteredClientIDsCP = c_ids.client_ids;
      }
      
      this.playlistService.searchPlaylistsFP(this.searchStrCP).subscribe( (c_ids:any) => {
        this.loadingCPSearch = false;
        if (c_ids) {
          this.filteredParentIDsFP = c_ids.parent_ids;
          this.filteredChildIDsFP = c_ids.child_ids;
        }  
      });
    });
  }

  clearSearchCP(){
    this.searchStrCP = '';
    return false;
  }

  openInfoPopup(){
    let total_count = this.tabs[this.selectedTabIndex].tracks.length;
    var res = {};
    this.tabs[this.selectedTabIndex].tracks.forEach(function(v) {
      res[v.mc_tags] = (res[v.mc_tags] || 0) + 1;
    });
    for (var property in res) {
      if (res.hasOwnProperty(property)) {
        res[property] = ( (res[property] /  total_count) * 100 ).toFixed(2);
      }
    }
    this.playlist_report = res;
    this.playlist_report_dialog = !this.playlist_report_dialog;
  }

  popupCloseRD(){
    this.playlist_report_dialog = false;
    return false;
  }



  exportPlaylist(id){
    const newArray = this.tabs[this.selectedTabIndex].tracks.map(({ 
                      id,
                      file_name,
                      track_category_id,
                      artist_id,
                      album_id,
                      genre_id,
                      publisher_id,
                      mood_id,
                      composer_id,
                      conductor_id,
                      deleted_at,
                      restored_at,
                      storage,
                      duplicate_check,
                      file_name_altered,
                      pivot_id,
                      contribute_artist_id,
                      foundation_play_list_id,
                      from,
                      ...item 
                    }) => item);
    this.excelService.exportAsExcelFile(newArray, this.tabs[this.selectedTabIndex].playlistInfo.name);
  }
  setHidden(e, tid) {
    this.trackService.setHidden(tid, e).subscribe((status: any) => {
      if (status.error != undefined) {
        this.alert.alertOpen(status.error, 1);
      } else {
        if (e) e = "YES";
        else e = "NO";
        this.alert.alertOpen("Hidden value changed to " + e + " !", 0);
      }
    });
  }

  updateExplicit(e, tid) {
    this.trackService.updateExplicit(tid, e).subscribe((status: any) => {
      if (status.error != undefined) {
        this.alert.alertOpen(status.error, 1);
      } else {
        if (e) e = "YES";
        else e = "NO";
        this.alert.alertOpen("Explicit value changed to " + e + " !", 0);
      }
    });
  }

  clearBoxUID() {
    this.searchUID = '';
  }

  searchUidPopUpOpen(){
    this.searchBulkUidPopUpOpen= !this.searchBulkUidPopUpOpen;
    this.focusInput();
  }

  searchUIDFunction() {
    this.isLoad = true;
    this.isSmartSearch = false;
    this.isSearchUID=true;
    this.searchStr='';
    var offset = this.infiniteScroll.items;
    var limit = this.infiniteScroll.addItems;
    if (this.searchUID === '' || this.searchUID === null) {
        this.alert.alertOpen("Please enter UID", 1);
        this.isLoad = false;
        return;
    } else {
        // Split the input string by both newlines and commas to get an array of UIDs
        let uids = this.searchUID.split(/[\n,]+/).map(uid => {
            // Trim whitespace, remove braces, and filter out empty strings
            uid = uid.trim();
            if (uid.startsWith('{') && uid.endsWith('}')) {
                uid = uid.slice(1, -1).trim();
            }
            return uid;
        }).filter(uid => uid !== '');

        if (uids.length === 0) {
            this.alert.alertOpen("Please enter valid UIDs", 1);
            this.isLoad = false;
            return;
        } else {
            this.trackService.bulkSearchUid(uids,this.tracksOrder.order, this.tracksOrder.orderBy, offset, limit).subscribe((tracks: any) => {
              this.isLoad = false;
              this.tracksCount.total = tracks.total;
              this.tracksCount.display = tracks.total;
              if(offset == 0)
                this.tracks = tracks.list;
              else
                this.tracks = this.tracks.concat(tracks.list);
              this.searchBulkUidPopUpOpen =false;
            });
        }
    }
}
}

function e(e: any) {
  throw new Error('Function not implemented.');
}


