import {Component, OnInit, ViewChild} from '@angular/core';
import {UserService} from '../services/user.service';
import {NgForm} from '@angular/forms';
import {DropzoneComponent, DropzoneConfigInterface, DropzoneDirective} from 'ngx-dropzone-wrapper';
import {Globals} from '../globals';
import {CommonService} from '../services/common.service';
import {CPPlaylistCategoriesService} from '../playlist-categories/cp_playlist-categories.service';
import {AlertComponent} from '../alert/alert.component';
import {ConfirmComponent} from '../confirm/confirm.component';

@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.scss'],
  providers: [UserService,CommonService,CPPlaylistCategoriesService]
})
export class UserManagementComponent implements OnInit {
  usersInfo:any = []; //Array of users
  clientsInfo:any = [];//Array of clients
  teamsInfo:any = [];//Array of teams
  userPopupOpened = false;//Open popup for user edit
  clientPopupOpened = false;//Open popup for client edit
  teamPopupOpened = false;//Open popup for team edit
  /**
   Field for user edit popup
   {
      id - user id,
      first_name - user first name,
      last_name - user last name,
      company_name - company name,
      email - email,
      phone - phone,
      photo - user photo,
      address - address,
      client_profile - description,
      password - password,
      repeat_password - password confirmation,
      type - user type,
      active - user activity
    }
   */
  userFields = {
    id: null,
    first_name: '',
    last_name: '',
    company_name: '',
    email: '',
    phone: '',
    photo: null,
    address: '',
    client_profile: '',
    password: '',
    repeat_password: '',
    type: 'Technician',
    active: 'false'
  };
  /**
   Field for client edit popup
   {
      id - user id,
      first_name - user first name,
      last_name - user last name,
      company_name - company name,
      email - email,
      phone - phone,
      photo - user photo,
      address - address,
      address_2 - address,
      address_3 - address,
      address_4 - address,
      city - city,
      county - county,
      postcode - post code,
      country - country,
      client_profile - description,
      type - user type,
      active - user activity
    }
   */
  clientFields = {
    id: null,
    first_name: '',
    last_name: '',
    company_name: '',
    email: '',
    phone: '',
    address: '',
    address_2: '',
    address_3: '',
    address_4: '',
    city: '',
    county: '',
    postcode: '',
    country: '',
    client_profile: '',
    type: 'Client',
    active: 1
  };
  /**
   Field for team edit popup
   {
      id - team id,
      name - team name,
      clientId - selected client id,
      userId - selected user id,
      usersArr - array of users in selects,
      usersInfoArr - list of attached users,
      clientsArr - array of clients in selects,
      clientsInfoArr - list of attached clients
    }
   */
  teamFields = {
    id: null,
    name: '',
    clientId: null,
    userId: null,
    usersArr: [],
    usersInfoArr: [],
    clientsArr: [],
    clientsInfoArr: []
  };
  current_user: any;// information of logged user
  config: DropzoneConfigInterface;//Dropzone component
  activeTab = 1;//Active tab index
  import_entity_ids: string = "";
  entityDetails : any;
  existingEntityIds:string='';
  isLoad = false;

  @ViewChild(DropzoneComponent) componentRef?: DropzoneComponent;//Dropzone component
  @ViewChild(DropzoneDirective) directiveRef?: DropzoneDirective;//Dropzone component
  @ViewChild(AlertComponent, { static: true }) alert: AlertComponent;//Component of alert popup
  @ViewChild(ConfirmComponent, { static: true }) confirm: ConfirmComponent;//Component of confirm popup
  /**
   Value for confirmation popup
   {
     id(integer) - ID of element for action(default null),
     type(integer) - type of function for action(default 1)
   }
   **/
  confirmValues = {
    id: null,
    type: 0
  };
  constructor(
    private usersService: UserService,
    private clientService: CPPlaylistCategoriesService,
    private globals: Globals,
    private commonService: CommonService
  ) { }

  ngOnInit() {
    this.commonService.getCurrentLoggedUser().subscribe(current_user => {
      this.current_user = current_user;

    });
    this.updateUsersList();
    this.updateClientsList();
    this.updateTeamsList();
  }

  /**
   Event if user photo uploaded with error
   * @param e - event
   */
  onUploadError(e){
    this.alert.alertOpen('error',1);
  }
  /**
   Event if user photo uploaded correct
   * @param e - event
   */
  onUploadSuccess(e){
    var info = e[1].info;
    this.userFields.photo = info.photo;
  }

  /**
   Get users list
   */
  updateUsersList(){
    this.usersService.getUsersList(null).subscribe(usersInfo => {
      console.log(usersInfo);
      this.usersInfo = usersInfo;
    });
  }

  /**
   Get clients list
   */
  updateClientsList(){

    this.clientService.getClientsList().subscribe(clients=>{
      this.clientsInfo = clients;
    })
  }

  /**
   Get teams list
   */
  updateTeamsList(){
    this.usersService.getTeams().subscribe(teamsInfo => {
      this.teamsInfo = teamsInfo;
    });
  }

  /**
   Open popup for user edit
   * @param e - event
   * @param uid - user id
   * @returns {boolean}
   */
  openUserPopup(e,uid){
    this.popupClose();
    this.userPopupOpened = !this.userPopupOpened;
    if(uid != null){
      this.usersService.getUserByID(uid).subscribe((userInfo: any) => {
        this.userFields.id = userInfo.id;
        this.userFields.first_name = userInfo.first_name;
        this.userFields.last_name = userInfo.last_name;
        this.userFields.company_name = userInfo.company_name;
        this.userFields.email = userInfo.email;
        this.userFields.phone = userInfo.phone;
        this.userFields.address = userInfo.address;
        this.userFields.client_profile = userInfo.client_profile;
        this.userFields.type = userInfo.type;
        this.userFields.active = userInfo.active;
        if(userInfo.active=='1'){
          this.userFields.active = 'true';
        }
        this.userFields.password = '';
        this.userFields.repeat_password = '';
        this.userFields.photo = userInfo.photo;
        this.config = {
          // Change this to your upload POST address:
          url: '/api/V1/user/photo/' + this.userFields.id,
          maxFilesize: 50,
          acceptedFiles: 'image/*',
          paramName: 'photo',
          method: 'POST',
          timeout: 180000,
          headers: {
            'Authorization': 'Bearer ' + this.globals.access_token
          }
        };
      });
    }
    else{
      this.userFields = {
        id: null,
        first_name: '',
        last_name: '',
        company_name: '',
        email: '',
        phone: '',
        photo: null,
        address: '',
        client_profile: '',
        password: '',
        repeat_password: '',
        type: 'Technician',
        active: 'false'
      }

    }
    return false;
  }

  /**
   Open popup for client edit
   * @param e - event
   * @param uid - client id
   */
  openClientPopup(e,uid){
    e.preventDefault();
    this.popupClose();
    this.clientPopupOpened = !this.clientPopupOpened;
    if(uid != null){
      this.usersService.getUserByID(uid).subscribe((userInfo: any) => {
        this.clientFields.id = userInfo.id;
        this.clientFields.first_name = userInfo.first_name;
        this.clientFields.last_name = userInfo.last_name;
        this.clientFields.company_name = userInfo.company_name;
        this.clientFields.email = userInfo.email;
        this.clientFields.phone = userInfo.phone;
        this.clientFields.address = userInfo.address;
        this.clientFields.address_2 = userInfo.address_2;
        this.clientFields.address_3 = userInfo.address_3;
        this.clientFields.address_4 = userInfo.address_4;
        this.clientFields.city = userInfo.city;
        this.clientFields.county = userInfo.county;
        this.clientFields.postcode = userInfo.postcode;
        this.clientFields.country = userInfo.country;
        this.clientFields.client_profile = userInfo.client_profile;
        this.clientFields.type = userInfo.type;
        this.clientFields.active = userInfo.active;
      });
    }
    else{
      this.clientFields.id = null;
      this.clientFields.first_name = '';
      this.clientFields.last_name = '';
      this.clientFields.company_name = '';
      this.clientFields.email = '';
      this.clientFields.phone = '';
      this.clientFields.address = '';
      this.clientFields.address = '';
      this.clientFields.address_2 = '';
      this.clientFields.address_3 = '';
      this.clientFields.address_4 = '';
      this.clientFields.city = '';
      this.clientFields.county = '';
      this.clientFields.postcode = '';
      this.clientFields.country = '';
      this.clientFields.client_profile = '';
      this.clientFields.type = 'Client';
      this.clientFields.active = 1;
    }
  }

  /**
   Update user information
   * @param e - event
   * @param {NgForm} form - form
   */
  formDataUpdate( e, form:NgForm ){
    e.preventDefault();

    if(form.value.first_name == ''){
      this.alert.alertOpen("First name field can not be empty!",1);
      return;
    }
    if(form.value.last_name == ''){
      this.alert.alertOpen("Last name field can not be empty!",1);
      return;
    }
    if(!this.validateEmail(form.value.email)){
      this.alert.alertOpen("Please provide a valid email address!!",1);
      return;
    }
    if(this.userFields.id == null){
      if(form.value.password == ''){
        this.alert.alertOpen("Password field can not be empty!",1);
        return;
      }
      if(form.value.repeat_password == ''){
        this.alert.alertOpen("Repeat password field can not be empty!",1);
        return;
      }
    }
    if(!this.validatePassword(form.value.password)){
      this.alert.alertOpen('Password must be 8-16 characters long; at least one number, one uppercase, one lowercase, one special character',1)
      return;
    }

    if(form.value.password != form.value.repeat_password){
      this.alert.alertOpen("Passwords not match",1);
      return;
    }

    delete form.value.repeat_password;
    var str = Object.keys(form.value).map(function (key) {
      var val=encodeURIComponent(form.value[key]);
      if(val == 'null' || val == null){
        val = '';
      }
      if(encodeURIComponent(key) == 'active'){
        if(val == 'true'){
          val = '1';
        }
        else{
          val = '0'
        }
      }
      return encodeURIComponent(key) + '=' + val;
    }).join('&');


      if(this.userFields.id == null){
          this.usersService.createUser(str).subscribe((user:any) => {
            if(!user.error){
              this.updateUsersList();
              this.alert.alertOpen("New user created",0);
              this.popupClose();
            }
            else{
              this.alert.alertOpen(user.error,1);
            }
          });
      }
      else{
          this.usersService.updateUser(str,this.userFields.id).subscribe((user:any) => {
            if(!user.error){
              this.updateUsersList();
              this.alert.alertOpen("User info updated",0);
              this.popupClose();
            }
            else{
              this.alert.alertOpen(user.error,1);
            }
          });
      }
  }

  /**
   Update client information
   * @param e - event
   * @param {NgForm} form - form
   */
  formClientUpdate( e, form:NgForm ){
    e.preventDefault();
    var str = Object.keys(form.value).map(function (key) {
      var val=encodeURIComponent(form.value[key]);
      if(val == 'null' || val == null){
        val = '';
      }
      return encodeURIComponent(key) + '=' + val;
    }).join('&');
    str += '&parent_ids[0]='+this.current_user.id;
    if(form.value.first_name != '' || form.value.email != ''){
      if(this.clientFields.id == null){
        if(this.validateEmail(this.clientFields.email)){
          this.usersService.createUser(str).subscribe((user:any) => {
            if(!user.error){
              this.updateClientsList();
              this.alert.alertOpen("New client created",0);
              this.popupClose();
            }
            else{
              this.alert.alertOpen("Please provide a valid email address!",1);
            }
          });
        }
        else if (!this.validateEmail(this.clientFields.email)){
          this.alert.alertOpen("Email incorrect",1);
        }
      }
      else{
        if(this.validateEmail(this.clientFields.email)){
          this.usersService.updateUser(str,this.clientFields.id).subscribe((user:any) => {
            if(!user.error){
              this.updateClientsList();
              this.alert.alertOpen("Client info updated",0);
              this.popupClose();
            }
            else{
              this.alert.alertOpen("Please provide a valid email address!",1);
            }
          });
        }
        else if (!this.validateEmail(this.clientFields.email)){
          this.alert.alertOpen("Email incorrect",1);
        }
      }
    }
    else{
      this.alert.alertOpen("Please fill in the user details",1);
    }
  }

  /**
   Update team information
   * @param e - event
   * @param {NgForm} form - form
   */
  formTeamUpdate( e, form:NgForm ){
    e.preventDefault();

    var str = 'name=' + this.teamFields.name;
    for( var i = 0; i < this.teamFields.clientsArr.length; i++){
      str += '&client_ids[' + i + ']=' + this.teamFields.clientsArr[i];
    }


    for( var i = 0; i < this.teamFields.usersArr.length; i++){
      str += '&user_ids[' + i + ']=' + this.teamFields.usersArr[i];
    }
    if(this.teamFields.name != ''){
      if(this.teamFields.id == null){
        this.usersService.createTeam(str).subscribe((team:any) => {
          if(!team.error){
            this.updateTeamsList();
            this.alert.alertOpen("New team created",0);
            this.popupClose();
          }
          else{
            this.alert.alertOpen(team.error,1);
          }
        });
      }
      else{
        this.usersService.updateTeam(str,this.teamFields.id).subscribe((team:any) => {
          if(!team.error){
            this.updateTeamsList();
            this.alert.alertOpen("Team info updated",0);
            this.popupClose();
          }
          else{
            this.alert.alertOpen(team.error,1);
          }
        });
      }
    }
    else{
      this.alert.alertOpen("Please enter Name field",1);
    }
  }

  /**
   Password validation
   * @param str - password string
   * @returns {boolean}
   */
  validatePassword(str){
    if(str == ''){
      return true;
    }
    var spec = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
    if(str.length < 8 && str.length > 16 ){
      return false;
    }
    if(!str.match(/[A-z]/) || !str.match(/[A-Z]/) || !str.match(/[0-9]/) ){
      return false;
    }
    if(!spec.test(str)){
      return false;
    }
    return true;
  }

  /**
   Delete user/client
   * @param uid - user/client id
   */
  deleteUser(uid){
    this.usersService.deleteUser(uid).subscribe(user => {
      this.updateUsersList();
      this.updateClientsList();

      let currentUser = this.usersInfo.find(x => x.id == uid);
      this.alert.alertOpen("Client "+currentUser.first_name+" deleted successfully!", 0);
    });
  }
  /**
   Close all popups
   * @returns {boolean}
   */
  popupClose(){
    this.userPopupOpened = false;
    this.clientPopupOpened = false;
    this.teamPopupOpened = false;
    return false;
  }
  /**
   Checking press keys in keyboard
   * @param event - event
   */
  keyPress(event: any) {
    const pattern = /[0-9\ ]/;
    let inputChar = String.fromCharCode(event.charCode);

    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }

  /**
   Email validation
   * @param email - email string
   * @returns {boolean}
   */
  validateEmail(email){
    var pattern = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    return pattern.test(email);
  }

  /**
   Select tab
   * @param num - tab index
   */
  selectTab(num){
    this.activeTab = num;
  }

  /**
   Open popup for team edit
   * @param tid - team id
   * @returns {boolean}
   */
  openTeamPopup(tid){
    this.popupClose();
    this.teamPopupOpened = !this.teamPopupOpened;
    this.teamFields.userId = null;
    this.teamFields.usersArr = [];
    this.teamFields.usersInfoArr = [];
    this.teamFields.clientsArr = [];
    this.teamFields.clientsInfoArr = [];
    this.teamFields.clientId = null;
    if(tid == null){
      this.teamFields.id = null;

    }
    else{
      this.usersService.getTeamInfo(tid).subscribe((team:any) => {
        this.teamFields.id = tid;
        this.teamFields.name = team.name;
        if(team.clients.length > 0){
          this.teamFields.clientsInfoArr = team.clients;
          for( var i = 0; i < team.clients.length; i++){
            this.teamFields.clientsArr[i] = team.clients[i].id;
          }
        }
        if( team.users.length > 0 ){
          this.teamFields.usersInfoArr = team.users;
          for( var i = 0; i < team.users.length; i++){
            this.teamFields.usersArr[i] = team.users[i].id;
          }
        }
      });
    }
    return false;
  }

  /**
   Delete team
   * @param tid - team id
   * @returns {boolean}
   */
  deleteTeam(tid){
    this.usersService.deleteTeam(tid).subscribe(team => {
      this.updateTeamsList();
      this.alert.alertOpen("Team deleted!",0);
      let currentTeam = this.teamsInfo.find(x => x.id == tid);
      this.alert.alertOpen("Team "+currentTeam.name+" deleted successfully!", 0);
    });
    return false;
  }

  /**
   Add user for team
   */
  addTeamUser(){
    if( this.teamFields.userId != null){
      if(this.checkUserArray( this.teamFields.userId, this.teamFields.usersArr )){
        this.teamFields.usersArr.push(this.teamFields.userId);
        this.teamFields.usersInfoArr.push(this.getTeamInfoUser(this.teamFields.userId));
      }
      else{
        this.alert.alertOpen("This user already exist",1);
      }
    }
  }

  /**
   Add client for team
   */
  addTeamClient(){
    if( this.teamFields.clientId != null ){
      if(this.checkUserArray( this.teamFields.clientId, this.teamFields.clientsArr )){
        this.teamFields.clientsArr.push(this.teamFields.clientId);
        this.teamFields.clientsInfoArr.push(this.getTeamInfoClient(this.teamFields.clientId));
      }
      else{
        this.alert.alertOpen("This client already exist",1);
      }
    }
  }

  /**
   Сheck user/client availability in team
   * @param id -user/client id
   * @param arr - users/clients array
   * @returns {boolean}
   */
  checkUserArray(id,arr){

    for (let row of arr) {
      if (row == id){
        return false;
      }
    }
    return true;
  }

  /**
   Get information of user in team
   * @param uid - user id
   * @returns {any}
   */
  getTeamInfoUser(uid) {
    for (let row of this.usersInfo) {
      if (uid == row.id) {
        return row;
      }
    }
  }
  /**
   Get information of client in team
   * @param uid - user id
   * @returns {any}
   */
  getTeamInfoClient(uid) {
    for (const row of this.clientsInfo) {
      if (uid == row.id) {
        return row;
      }
    }
  }

  /**
   Delete user from team
   * @param index - user index
   * @returns {boolean}
   */
  deleteTeamUser(index){
    this.teamFields.usersArr.splice( index,1 );
    this.teamFields.usersInfoArr.splice( index,1 );
    return false;
  }
  /**
   Delete client from team
   * @param index - client index
   * @returns {boolean}
   */
  deleteTeamClient(index){
    this.teamFields.clientsArr.splice( index,1 );
    this.teamFields.clientsInfoArr.splice( index,1 );
    return false;
  }
  /**
   Confirm action in confirmation popup
   * @param e - event
   */
  isConfirm(e){
    if(e){
      if( this.confirmValues.type == 0 )
        this.deleteUser(this.confirmValues.id);
      else if( this.confirmValues.type == 1 )
        this.deleteTeam(this.confirmValues.id);
    }
  }
  /**
   Open confirmation popup
   * @param id - id of action
   * @param t - text for popup
   * @returns {boolean}
   */
  openConfirmPopup(id, t){
    var text = "Do you want to delete this user?";
    if(t == 1)
      text = "Do you want to delete this team?";

    this.confirmValues.id = id;
    this.confirmValues.type = t;
    this.confirm.confirmOpen(text);
    return false;
  }

  import_entity() {
    this.existingEntityIds = '';

    // Validation: Only numbers, commas, and spaces after commas are allowed
    const regex = /^[0-9]+(,\s*[0-9]+)*$/;
    if (!regex.test(this.import_entity_ids)) {
      this.alert.alertOpen('Please enter only numbers separated by commas.', 1);
      return;
    }

    if (this.import_entity_ids == "" || this.import_entity_ids == null) {
      this.alert.alertOpen('Please enter Entity Id.', 1);
    } else {
      this.isLoad = true;
      this.entityDetails = [];
      const idsArray = this.import_entity_ids.split(",").map((id) => id.trim());
      this.usersService.import_entity_ids(idsArray).subscribe((list: any) => {
        this.isLoad = false;
        this.entityDetails = list;
        if (list.existingEntityIds.length) {
          this.existingEntityIds = list.existingEntityIds.join(', ');
        }
      });
    }
  }

  enable2FA(e, tid) {
    this.usersService.enable2FA(tid, e).subscribe((status: any) => {
      if (status.error != undefined) {
        this.alert.alertOpen(status.error, 1);
      } else {
        const message = status.message ;
        this.alert.alertOpen(`Two-Factor Authentication is ${message} for this user!`, 0)

      }
    });
  }
}
