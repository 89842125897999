<app-page-header [pageTitle]="'Create, edit and manage your playlists'"></app-page-header>
<div class="ph_block">
  <div class="user_info_wrap">
    <form #updateUser="ngForm">
      <div class="left_form">
        <div class="input_wrap">
          <label>First Name</label>
          <input type="text" name="first_name" value="{{userFields.first_name}}" [(ngModel)]="userFields.first_name"/>
        </div>
        <div class="input_wrap">
          <label>Last Name</label>
          <input type="text" name="last_name" value="{{userFields.last_name}}" [(ngModel)]="userFields.last_name"/>
        </div>
        <div class="input_wrap">
          <label>Email</label>
          <input type="text" name="email" value="{{userFields.email}}" [(ngModel)]="userFields.email"/>
        </div>
        <div class="input_wrap">
          <label>Password</label>
          <input type="password" name="password" value="{{userFields.password}}" [(ngModel)]="userFields.password"/>
        </div>
        <div class="input_wrap">
          <label>Repeat Password</label>
          <input type="password" name="repeat_password" value="{{userFields.repeat_password}}" [(ngModel)]="userFields.repeat_password"/>
        </div>
      </div>
      <div class="right_form">
        <div class="input_wrap">
          <label>Company Name</label>
          <input type="text" name="company_name" value="{{userFields.company_name}}" [(ngModel)]="userFields.company_name"/>
        </div>
        <div class="input_wrap">
          <label>Phone #</label>
          <input type="text" name="phone" (keypress)="keyPress($event)" value="{{userFields.phone}}" [(ngModel)]="userFields.phone"/>
        </div>
        <div class="input_wrap">
          <label>Address</label>
          <input type="text" name="address" value="{{userFields.address}}" [(ngModel)]="userFields.address"/>
        </div>
        <div class="input_wrap">
          <label>Client Profile</label>
          <textarea name="client_profile" [(ngModel)]="userFields.client_profile">{{userFields.client_profile}}</textarea>
        </div>
        <div class="input_wrap">
          <label>User Photo</label>
          <div class="dropzone_wrap" [ngClass]="{zone_hidden: userFields!=undefined&&userFields.photo!=null}">
            <dropzone
              [config]="config"
              [message]="'Click or drag photo here to upload'"
              (error)="onUploadError($event)"
              (success)="onUploadSuccess($event)" *ngIf="userFields.id!=null">
            </dropzone>
            <img alt="user_photo" class="user_photo" *ngIf="userFields!=undefined&&userFields.photo!=null" src="/profile_photo/{{userFields.photo}}">
          </div>
        </div>
      </div>
      <div class="btns">
        <button type="submit" (click)="formDataUpdate($event,updateUser)">Save</button>
      </div>
    </form>
  </div>
</div>
<app-alert></app-alert>
