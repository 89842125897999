import {Component, OnInit, ViewChild} from '@angular/core';
import {ScheduleService} from '../services/schedule.service';
import {ActivatedRoute, Router} from '@angular/router';
import {CpScheduleComponent} from '../cp-schedule/cp-schedule.component';
import {CPPlaylistCategoriesService} from '../playlist-categories/cp_playlist-categories.service';
import {NgForm} from '@angular/forms';
import {CPPlaylistsService} from '../services/cp_playlists.service';
import {AlertComponent} from '../alert/alert.component';

@Component({
  selector: 'app-manage-shedule',
  templateUrl: './manage-shedule.component.html',
  styleUrls: ['./manage-shedule.component.scss'],
  providers: [ ScheduleService, CPPlaylistCategoriesService, CPPlaylistsService ]
})
export class ManageSheduleComponent implements OnInit {
  clearSearch;
  channelId = null;//Channel id
  checkedScheduleId = null;//Selected schedule id
  schedulesList:any = [];//List of schedules
  userId = null;//Client id
  categoryParentId = null;//Site id
  @ViewChild(CpScheduleComponent, { static: true }) shedule: CpScheduleComponent;//Component of alert popup
  @ViewChild(AlertComponent, { static: true }) alert: AlertComponent;//Component of confirm popup
  playlistsList = [];//List of playlists
  /*
   Popups list. Params for opening/closing popups
   */
  popups = {
    schedulePopupOpened: false
  };
  /**
   Selected Schedule information
   {
     id - Schedule id,
     name - Schedule name
   }
   */
  scheduleFields = {
    name: '',
    playlistID: null
  };
  constructor(
    private scheduleService: ScheduleService,
    private route: ActivatedRoute,
    private router: Router,
    private chanelService: CPPlaylistCategoriesService,
    private playlistService: CPPlaylistsService
  ) { }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.channelId = params['cid'];
      this.userId = params['uid'];
      this.checkedScheduleId = params['scheduleID'];
      this.getSchedulesList(this.channelId);
      this.selectSchedule(this.checkedScheduleId);
      this.getCategoryParent(this.channelId);
    });
  }

  /**
   Get list of schedules
   * @param cid - channel id
   */
  getSchedulesList(cid){
    this.scheduleService.getSchedulesList(cid).subscribe(schedules => {
      this.schedulesList = schedules;
    });
  }

  /**
   Select schedule
   * @param sid - schedule id
   */
  selectSchedule(sid,profile_type = null){
    if(profile_type == 2){
      this.alert.alertOpen("Locked schedule",2);
      return false;
    }
    this.checkedScheduleId = sid;
    this.shedule.changeSchedule(sid);
  }
  /**
   Go to previous page
   * @returns {boolean}
   */
  backBtnClick(){
    let navigationExtras = {
      queryParams: {
        'cat': this.channelId,
        'uid': this.userId,
        'pcat': this.categoryParentId,
        'tab': 2
      }
    };
    if (localStorage.getItem("cp_es_keyword") !== null) {
      localStorage.setItem('cp_search_keyword',localStorage.getItem('cp_es_keyword'));
      localStorage.removeItem("cp_es_keyword");
            
     }
    this.router.navigate(['client-playlists'], navigationExtras);
    return false;
  }
  /**
   Get parent site
   * @param catid - channel id
   */
  getCategoryParent( catid ){
    this.chanelService.getPlaylistCategoriesByID(catid).subscribe((chanel:any) => {
      this.categoryParentId = chanel.parent_id;
    });
  }

  /**
   Open small popup for create new schedule
   * @returns {boolean}
   */
  openCreateSchedulePopup(){
    this.scheduleFields.name = '';
    this.scheduleFields.playlistID = null;
    this.playlistService.getPlaylists(this.channelId).subscribe((list:any) => {
      this.playlistsList = list;
      this.popups.schedulePopupOpened = true;
    });
    return false;
  }
  /**
   Close all popups
   * @returns {boolean}
   */
  popupClose(){
    this.popups.schedulePopupOpened = false;
    return false;
  }

  /**
   Create new schedule
   * @param e - event
   * @param {NgForm} form - form
   */
  createSchedule( e, form:NgForm ){
    if(this.scheduleFields.name != ''){
      var str = 'name=' + this.scheduleFields.name + '&cp_playlist_id=' + this.scheduleFields.playlistID;
      this.scheduleService.createScheduleByName(this.channelId,str).subscribe((sch:any) => {
        console.log(sch);
        if(sch.error == undefined){
          this.alert.alertOpen("New schedule created",0);
          this.getSchedulesList(this.channelId);
          this.checkedScheduleId = sch.id;
          this.popupClose();
        }
        else{
          this.alert.alertOpen(sch.error,1);
        }
      });
    }
  }
}
