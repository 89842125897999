import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-profile-statement-popup',
  templateUrl: './profile-statement-popup.component.html',
  styleUrls: ['./profile-statement-popup.component.scss']
})
export class ProfileStatementPopupComponent implements OnInit {
  popups;
  playlistFields;
  categoriesList;
  subcategoriesList;
  popupClose;
  playlistDataUpdate;
  

  ngOnInit() {
    // Do nothing
  }

}
