<app-page-header [pageTitle]="'Create, edit and manage your playlists'"></app-page-header>
<div class="ph_block">
  <div class="top_box">
    <button class="create_user_btn" (click)="openUserPopup($event,null)" title="New User">Create New User</button>

    <button class="create_team_btn" (click)="openTeamPopup(null)" title="New Team">Create New Team</button>
  </div>
  <div class="users_list_block">
    <ul class="tabs">
      <li [ngClass]="{selected:activeTab==1}" (click)="selectTab(1)">Users</li>

      <li [ngClass]="{selected:activeTab==3}" (click)="selectTab(3)">Teams</li>
      <span *ngIf="current_user && [
      'mark.hoffmann@kaleidovision.co.uk',
      'steve.cross@kaleidovision.co.uk',
      'subramanian.baskaran@globalizeme.com',
      'udi@musicconcierge.co.uk',
      'paul.buckthorpe@kaleidovision.co.uk',
      'Michael.Xu@musicconcierge.co.uk',
      'sami.fahmy@kaleidovision.co.uk',
      'kent.chen@kaleidovision.co.uk'
    ].includes(current_user.email)">
      <li [ngClass]="{selected:activeTab==4}" (click)="selectTab(4)">Onboard Entity</li>
      <li [ngClass]="{selected:activeTab==5}" (click)="selectTab(5)">Flush</li>
    </span>
    </ul>
    <div class="table_wrap" [ngClass]="{active:activeTab==1}">
      <div class="table_titles">
        <div>First Name</div>
        <div>Last Name</div>
        <div>Email</div>
        <div>Phone#</div>
        <div>User Status</div>
        <div>User Type</div>
        <div>Enable 2FA</div>
        <div>Actions</div>
      </div>

      <div class="table_content">
        <div class="table_row" *ngFor="let u of usersInfo">
          <div>{{u.first_name}}</div>
          <div>{{u.last_name}}</div>
          <div>{{u.email}}</div>
          <div>{{u.phone}}</div>
          <div>
            <span *ngIf="u.active=='1'">Active</span>
            <span *ngIf="u.active!='1'">Inactive</span>
          </div>
          <div>{{u.type}}</div>
          <div>
            <ui-switch
              labelOn="Y" labelOff="N"
              size="small"
              name="hidden"
              switchColor="#7a7a7a"
              defaultBoColor="#a8a8a8"
              [checked]="u.google2fa_enabled == '1'"
              (change)="enable2FA($event, u.id)">
            </ui-switch>
          </div>

          <div class="func_col">
            <a href="#" class="edit_icon" (click)="openUserPopup($event,u.id)" title="Edit"></a>
            <a href="#" class="delete_icon" (click)="openConfirmPopup(u.id,0)" title="Delete"></a>
          </div>
        </div>
      </div>
    </div>

    <div class="table_wrap team_table" [ngClass]="{active:activeTab==3}">
      <div class="table_titles">
        <div>Name</div>
        <div>Client's Name</div>
        <div># of Members</div>
        <div>Created at</div>
        <div>Updated at</div>
        <div>Actions</div>
      </div>
      <div class="table_content">
        <div class="table_row" *ngFor="let t of teamsInfo">
          <div>{{t.name}}</div>
          <div>
            <span *ngFor="let c of t.clients">{{c.first_name}} {{c.last_name}}<span>, </span></span>
          </div>
          <div>{{t.users.length}}</div>
          <div>{{t.created_at}}</div>
          <div>{{t.updated_at}}</div>
          <div class="func_col">
            <a href="#" class="edit_icon" (click)="openTeamPopup(t.id)" title="Edit"></a>
            <a href="#" class="delete_icon" (click)="openConfirmPopup(t.id,1)" title="Delete"></a>
          </div>
        </div>
      </div>
    </div>

    <div class="table_wrap " [ngClass]="{active:activeTab==4}">

      <div class="dash_top box">
        <div class="box_flex">
          <label for="import_entity">Entity</label>
          <input class="" type="text"   [(ngModel)]="import_entity_ids"
          pattern="^\d+(,\d+)*$"
          title="Comma-separated Entity"
          required />
          <button (click)="import_entity()">Submit</button>
        </div>

      </div>

      <div class="existing" *ngIf="existingEntityIds != ''"><span ><p>Existing Entity IDs: <span style="color: lightcoral;">{{ existingEntityIds }}</span></p></span></div>

      <div class="table_titles">
        <div style="width: 50%;">Entity_id</div>
        <div style="width: 50%;">Status</div>

      </div>
      <div class="table_content">
        <div class="table_row"  *ngFor="let t of entityDetails?.response_message">
          <div style="width: 50%;">{{ t.entity_id }}</div>
          <div style="width: 50%;">
            {{ t.message }}
          </div>

        </div>
      </div>
    </div>

    <div class="table_wrap " [ngClass]="{active:activeTab==5}">
        <app-flush></app-flush>
    </div>

  </div>
</div>
<div class="popup create_user_popup" [ngClass]="{opened: userPopupOpened}" ngDraggable ngResizable [rzHandles]="'n,e,s,w,se,sw,ne,nw'" [handle]="dragHandle">
  <a href="#" class="popup_close" (click)="popupClose()"></a>
  <div class="popup_title" #dragHandle>Create/Edit User</div>
  <div class="popup_form">
    <form #updateUser="ngForm">

      <div class="left_form">
        <div class="input_wrap">
          <label>First Name</label>
          <input type="text" name="first_name" value="{{userFields.first_name}}" [(ngModel)]="userFields.first_name"/>
        </div>
        <div class="input_wrap">
          <label>Last Name</label>
          <input type="text" name="last_name" value="{{userFields.last_name}}" [(ngModel)]="userFields.last_name"/>
        </div>
        <div class="input_wrap">
          <label>Email</label>
          <input type="text" name="email" value="{{userFields.email}}" [(ngModel)]="userFields.email"/>
        </div>
        <div class="input_wrap">
          <label>Password</label>
          <input type="password" name="password" value="{{userFields.password}}" [(ngModel)]="userFields.password"/>
        </div>
        <div class="input_wrap">
          <label>Repeat Password</label>
          <input type="password" name="repeat_password" value="{{userFields.repeat_password}}" [(ngModel)]="userFields.repeat_password"/>
        </div>
        <div class="input_wrap">
          <label>Active User</label>
          <input type="checkbox" name="active" value="{{userFields.active}}" [(ngModel)]="userFields.active" [checked]="userFields.active=='true'"/>
        </div>
      </div>
      <div class="right_form">
        <div class="input_wrap">
          <label>Company Name</label>
          <input type="text" name="company_name" value="{{userFields.company_name}}" [(ngModel)]="userFields.company_name"/>
        </div>
        <div class="input_wrap">
          <label>Phone #</label>
          <input type="text" maxlength="10" (keypress)="keyPress($event)" name="phone" value="{{userFields.phone}}" [(ngModel)]="userFields.phone"/>
        </div>
        <div class="input_wrap">
          <label>Address</label>
          <input type="text" name="address" value="{{userFields.address}}" [(ngModel)]="userFields.address"/>
        </div>
        <div class="input_wrap">
          <label>Client Profile</label>
          <textarea name="client_profile" [(ngModel)]="userFields.client_profile">{{userFields.client_profile}}</textarea>
        </div>
        <div class="input_wrap">
          <label>User Type</label>
          <select name="type" [(ngModel)]="userFields.type">
            <option value="Technician" [selected]="userFields.type === 'Technician'">Technician</option>
            <option value="Consultant" [selected]="userFields.type === 'Consultant'">Consultant</option>
            <option value="Administrator" [selected]="userFields.type === 'Administrator'">Administrator</option>
          </select>
        </div>
        <div class="input_wrap" *ngIf="userFields.id!=null">
          <label>User Photo</label>
          <div class="dropzone_wrap" [ngClass]="{zone_hidden: userFields!=undefined&&userFields.photo!=null}">
            <dropzone
              [config]="config"
              [message]="'Click or drag photo here to upload'"
              (error)="onUploadError($event)"
              (success)="onUploadSuccess($event)">
            </dropzone>
            <img alt="user_photo" class="user_photo" *ngIf="userFields!=undefined&&userFields.photo!=null" src="profile_photo/{{userFields.photo}}">
          </div>
        </div>
      </div>
      <div class="popup_btns">
        <button type="button" class="cancel" (click)="popupClose()">Cancel</button>
        <button type="submit" (click)="formDataUpdate($event,updateUser)">Save</button>
      </div>
    </form>
  </div>
</div>
<div class="fade" [ngClass]="{ opened: isLoad }"></div>
<img src="../../assets/img/loader.gif" alt="loader" class="loader" [ngClass]="{ active: isLoad }" />
<div class="popup create_team_popup" [ngClass]="{opened: teamPopupOpened}" ngDraggable ngResizable [rzHandles]="'n,e,s,w,se,sw,ne,nw'" [handle]="dragHandle2">
  <a href="#" class="popup_close" (click)="popupClose()"></a>
  <div class="popup_title" #dragHandle2>Create/Edit Team</div>
  <div class="popup_form">
    <form #updateTeam="ngForm">
      <div class="input_wrap">
        <label>Team name</label>
        <input type="text" name="name" value="{{teamFields.name}}" [(ngModel)]="teamFields.name"/>
      </div>
      <div class="input_wrap">
        <label>Clients name</label>
        <select name="client_id" [(ngModel)]="teamFields.clientId">
          <option value="null" [selected]="teamFields.clientId==null">Select client...</option>
          <option *ngFor="let c of clientsInfo" value="{{c.id}}" [selected]="teamFields.clientId==c.id">{{c.first_name}} {{c.last_name}}</option>
        </select>
        <button (click)="addTeamClient()">Select</button>
      </div>
      <div class="input_wrap">
        <label>Members</label>
        <select name="user_id" [(ngModel)]="teamFields.userId">
          <option value="null" [selected]="teamFields.userId==null">Select member...</option>
          <option *ngFor="let m of usersInfo" value="{{m.id}}" [selected]="teamFields.userId==m.id">{{m.first_name}} {{m.last_name}}</option>
        </select>
        <button (click)="addTeamUser()">Add</button>
      </div>
      <div class="table_wrap" [ngClass]="{active:activeTab==2}">
        <div class="table_titles">
          <div>Name</div>
          <div>Email</div>
          <div>Role</div>
          <div>Clients</div>
          <div>Actions</div>
        </div>
        <div class="table_content" *ngIf="teamFields.usersArr.length>0||teamFields.clientsArr.length>0">
          <div class="table_row" *ngFor="let c of teamFields.clientsInfoArr;let i = index">
            <div>{{c.first_name}} {{c.last_name}}</div>
            <div>{{c.email}}</div>
            <div></div>
            <div>Client</div>
            <div class="func_col">
              <a href="#" class="delete_icon" (click)="deleteTeamClient(i)"></a>
            </div>
          </div>
          <div class="table_row" *ngFor="let u of teamFields.usersInfoArr;let i = index">
            <div>{{u.first_name}} {{u.last_name}}</div>
            <div>{{u.email}}</div>
            <div>{{u.type}}</div>
            <div></div>
            <div class="func_col">
              <a href="#" class="delete_icon" (click)="deleteTeamUser(i)"></a>
            </div>
          </div>
        </div>
      </div>
      <div class="popup_btns">
        <button type="button" class="cancel" (click)="popupClose()">Cancel</button>
        <button type="submit" (click)="formTeamUpdate($event,updateTeam)">Save</button>
      </div>
    </form>
  </div>
</div>
<app-alert></app-alert>
<app-confirm (isConfirm)="isConfirm($event)"></app-confirm>
