import { Component, ContentChildren, QueryList, AfterContentInit } from '@angular/core';
import { AccordionGroupComponent } from './accordion-group.component';

@Component({
  selector: 'custom-accordion',
  template: `
    <ng-content></ng-content>
`,
  styleUrls: ['./accordion.component.css'],
})
export class AccordionComponent  implements AfterContentInit {
  @ContentChildren(AccordionGroupComponent, {descendants: true}) groups: QueryList<AccordionGroupComponent>;

  /**
   * Invoked when all children (groups) are ready
   */
  ngAfterContentInit() {
    // setTimeout(() => {
    //     this.paginator.page
    //         .pipe(
    //             startWith(null),
    //             tap(() => this.dataSource.loadLessons(...))
    //         ).subscribe();
    // });
    // Set active to first element
    //this.groups.toArray()[0].opened = true;
    // Loop through all Groups
    this.groups.toArray().forEach((t) => {
      // when title bar is clicked
      // (toggle is an @output event of Group)
      t.toggle.subscribe(() => {
        // Open the group
        this.openGroup(t);
      });
    });
  }

  /**
   * Open an accordion group
   * @param group   Group instance
   */
  openGroup(group: any) {
    // close other groups
    this.groups.toArray().forEach((t) => t.opened = false);
    // open current group
    group.opened = true;
  }

}
