import {Directive, HostBinding, HostListener, Input, OnInit} from '@angular/core';
import {Howl} from 'howler';

@Directive({
  selector: "[player]"
})
export class PlayerDirective implements OnInit{
  @Input() file_id:string;
  @Input() file_name:string;
  players = [];
  
  ngOnInit(){
    // Do nothing
  }
  @HostBinding('class.track_pause') status = false;

  @HostListener('click',['$event']) playTrack(e){
    e.preventDefault();
    var file_id = this.file_id;
    var file_name = this.file_name;

    this.status = !this.status;
    var player = {
      file_id: file_id,
      file: file_name,
      id: null,
      howl: null
    };
    let already_cliked = false;

    this.players.forEach(current_player => {
      if (current_player.file_id == file_id) {
        already_cliked = true;
      }
    });

    if (!already_cliked) {
      //new file
      var sound = new Howl({
        src: ['/encoded/' + file_name],
        html5: true
      });

      player.id = sound.play();
      player.howl = sound;
      this.players.push(player);

      this.players.forEach(current_player => {
        if (current_player.file_id != file_id) {
          current_player.howl.stop(current_player.id);
        }
      });

    }else {

      this.players.forEach(current_player => {
        if (current_player.file_id != file_id) {
          current_player.howl.stop(current_player.id);
        }
      });

      this.players.forEach(current_player => {
        if (current_player.file_id == file_id) {
          if (current_player.howl.playing(current_player.id)){
            current_player.howl.pause(current_player.id);
          }else {
            current_player.howl.play(current_player.id);
          }
        }
      });

    }
  }
}
