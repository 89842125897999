import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { AlertComponent } from '../alert/alert.component';
import { ConfirmComponent } from '../confirm/confirm.component';
import { CommonService } from '../services/common.service';
import {ReportsService} from '../services/reports.service';
import { CPPlaylistCategoriesService } from '../playlist-categories/cp_playlist-categories.service';
import { ExcelService } from '../services/excel.service';


@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss'],
  providers:[
    ReportsService,
    CommonService,
    CPPlaylistCategoriesService,
    ExcelService
  ]
})

export class ReportsComponent implements OnInit {
  
  // DEFINE VARIABLES START
  type = '';
  reportList = [];
  reportListFestive = [];
  isLoad = false;//Display preloader
  fpList = [];
  selectedFP = [];
  selectedFestive = 1;
  dropdownSettings = {
    singleSelection: false,
    idField: 'item_id',
    textField: 'item_text',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 5,
    allowSearchFilter: true
  };
  loadContent:boolean = false;
  tabs = {
    selected: 1
  };
  p: number = 1;
  q: number = 1;
  public order = 'DESC';
  public orderBy = 'hours_required';
  public orderF = 'DESC';
  public orderByF = 'date_updated';
  // DEFINE VARIABLES END

  @ViewChild(AlertComponent, { static: true }) alert: AlertComponent; //Component of alert popup
  @ViewChild(ConfirmComponent) confirm: ConfirmComponent; //Component of confirm popup

  constructor(
    private reportsService: ReportsService,
    private commonService: CommonService,
    private channelService: CPPlaylistCategoriesService,
    private router: Router,
    private route: ActivatedRoute,
    private excelService: ExcelService
  ) { }

  ngOnInit() {
    
    this.getFPList();
    this.route.queryParams.subscribe(params=>{
      this.type = params['type'];
      if(this.type == null || this.type == '' ){
        this.type = 'ps';
      }
      
    });
  }

  getReportList() { // Temp function
    this.isLoad = true;
    this.reportsService.getReportList(this.type).subscribe((list: any) => {
      this.isLoad = false;
      this.reportList = list;
    });
  }

  getFPList(){
    this.reportsService.getPlaylists().subscribe((playlist:any)=>{
      
      if (playlist) {
        for (let key in playlist) {
          if (playlist.hasOwnProperty(key)) {
            this.fpList.push({
              item_id   : playlist[key].id,
              item_text : playlist[key].name
            })
          }
        }
      }
      this.loadContent = true;  
    });
  }
 
 

  searchPsReport(e,status){
    this.isLoad = true;
    let str = 'type='+this.type;
   
    if(this.selectedFP.length <= 0){
      this.isLoad = false;
      this.alert.alertOpen("Please select a foundation playlist!", 1);
      return false;
    }
    for( var i = 0; i < this.selectedFP.length; i++ ){
      if(this.selectedFP[i].item_id != null){
        str += '&fpIDs[' + i + ']=' + this.selectedFP[i].item_id;
      }
    }
   
    this.reportList = [];
      str += '&status='+status;
      console.log(str);
    this.reportsService.searchReport(str).subscribe((res:any)=>{
      
      this.isLoad = false;
      this.orderBy = 'hours_required';
      this.order = 'DESC';
      this.reportList = res;
      
    });  
    // return false;
  }

  searchFestiveReport(e,status){
    this.isLoad = true;
    let str = 'type='+this.type+'&festive_type='+this.selectedFestive+'&status='+status;
    console.log(str);
    this.reportListFestive = [];
    this.reportsService.searchReport(str).subscribe((res:any)=>{
      
      this.isLoad = false;
      this.orderByF = 'date_updated';
      this.orderF = 'DESC';
      this.reportListFestive = res;
    });  
   
  }
  // PRIMARY FUNCTIONS END

  // SECONDARY FUNCTIONS START
  goBack(){
    this.router.navigate(['reports']);
  }

  selectTab(tabId) {
    this.tabs.selected = tabId;
    if(tabId == 1){
      this.type = 'ps';
    }
    if(tabId == 2){
      this.type = 'festive';
    }
  }

  exportResult(){
    if(this.tabs.selected == 1 && this.reportList.length <= 0){
      this.alert.alertOpen("No results found to export!", 1);
      return false;
    }
    if(this.tabs.selected == 2 && this.reportListFestive.length <= 0){
      this.alert.alertOpen("No results found to export!", 1);
      return false;
    }
    let filename = 'Profile statements report';
    let newArray = this.reportList.map(({ id,foundation_play_list_id,notes,created_at,
        updated_at,created_by,name,available_tracks,future_coverage,smart_filters,psr_id,
        first_name,last_name,
                        ...item 
                      }) => item);
    if(this.tabs.selected == 2){
      let festive_name = '';
      switch (this.selectedFestive) {
        case 1:
          festive_name = 'Christmas';
          break;
        case 2:
          festive_name = 'Chinese New Year';
          break;
        case 3:
          festive_name = 'Ramadan';
          break;
        default:
          festive_name = 'Christmas';
          break;
      }
      filename = festive_name + ' profiles report';
      newArray = this.reportListFestive.map(({ 
        id,playlist_id,channel_id,client_id,user_id,notes,cp_category_id,
        status,description,created_by,last_edit_by,deleted_at,restored_at,changed_to_live,
        changed_to_editing,uid,mcluid,deleted_in_odbapi,startdate,finishdate,persistent,
        createdat,updatedat,hidden,auto_generate,psr_id,auto_build,profile_type,master_id,dg_id,sync,sync_by,
        unlock_by,festive_type,
                        ...item 
                      }) => item);
    }
   
    this.excelService.exportAsExcelFile(newArray, filename);
  }

  viewPlaylist(playlist_id,channel_id,client_id){
    if(playlist_id == 0 || playlist_id == null){
      this.alert.alertOpen("Invalid profile ID!",1);
      return false;
    }
    
    let navigationExtras: NavigationExtras = {
      queryParams: {
        'playlistID': playlist_id,
        'pcat': channel_id,
        'uid': client_id
      }
    };
    this.router.navigate(['manage-client-playlists'], navigationExtras);
    // });
  }

  sort(colName) {
    
    if(this.tabs.selected == 1){
        if(this.orderBy == colName){
          if(this.order == 'DESC'){
            this.reportList.sort((a, b) => a[this.orderBy] > b[this.orderBy] ? 1 : a[this.orderBy] < b[this.orderBy] ? -1 : 0);
            this.order = 'ASC';
          }
          else{
            this.reportList.sort((a, b) => a[this.orderBy] < b[this.orderBy] ? 1 : a[this.orderBy] > b[this.orderBy] ? -1 : 0);
            this.order = 'DESC';
          }
        }
        else{
          this.orderBy = colName;
        //  this.reportList.sort((a, b) => a[this.orderBy] > b[this.orderBy] ? 1 : a[this.orderBy] < b[this.orderBy] ? -1 : 0)
        }
    }
    if(this.tabs.selected == 2){
      if(this.orderByF == colName){
        if(this.orderF == 'DESC'){
          this.reportListFestive.sort((a, b) => a[this.orderByF] > b[this.orderByF] ? 1 : a[this.orderByF] < b[this.orderByF] ? -1 : 0);
          this.orderF = 'ASC';
        }
        else{
          this.reportListFestive.sort((a, b) => a[this.orderByF] < b[this.orderByF] ? 1 : a[this.orderByF] > b[this.orderByF] ? -1 : 0);
          this.orderF = 'DESC';
        }
      }
      else{
        this.orderByF = colName;
        //this.reportListFestive.sort((a, b) => a[this.orderByF] > b[this.orderByF] ? 1 : a[this.orderByF] < b[this.orderByF] ? -1 : 0)
      }
    }
  }

  // for(var i= 0;i < this.trackInfo.additional_attributes.length;i++){
  //   this.additional.push(this.trackInfo.additional_attributes[i].name);
  //   this.selectedMCTags.push(this.trackInfo.additional_attributes[i].name);
  // }
  // SECONDARY FUNCTIONS END
}
