<app-alert></app-alert>

<!-- <app-page-header [pageTitle]="'Flush Entity'"></app-page-header> -->
<app-confirm (isConfirm)="isConfirm($event)"></app-confirm>

<div class="dash_top box">
  <div class="box_flex">
    <label for="flush_text">Flush Entity</label>
    <input class="" type="text"    [(ngModel)]="flush_entity_ids"
    pattern="^\d+(,\d+)*$"
    title="Comma-separated Entity"
    (keyup.enter)="flush_entity()"
    required />
    <button (click)="flush_entity()">Submit</button>
  </div>
</div>

<div class="table_box">
  <div class="delete_all">
    <button title="Delete All Entity" (click)="openConfirmPopup(flush_entity_ids,1)">Delete All Entity</button>
  </div>

  <div class="box_inner">
        <div class="table_wrap dashboard_table">
        <div class="table_titles">
            <div class="order" style="width: 30%">Client</div>
            <div class="order" style="width: 30%">Site</div>
            <div class="order" style="width: 30%">Channel</div>
            <div class="order" style="width: 10%">Action</div>
        </div>

        <div class="table_content" style="height: 360px" *ngIf="entityDetails">
            <div class="table_row"  *ngFor="let entity of entityDetails">
            <div style="width: 30%">{{entity.client?.company_name || '' }}  </div>
            <div style="width: 30%">{{entity.get_parent?.name || ''}} ({{ entity.get_parent.entity_id }})</div>
            <div style="width: 30%">{{entity.name}} ({{ entity.entity_id }})</div>
            <div style="width: 10%"><button title="Delete" (click)="openConfirmPopup(entity.entity_id,0)">Delete</button></div>
            </div>
        </div>
        </div>
  </div>
</div>
