<div class="navigation" (click)="clickedInside($event)" *ngIf="isShowHeader">
  <nav>
    <ul [ngClass]="{'opened': menuOpened}">
      <li class="nav_user">
        <a (click)="navOpen($event)" href="#" class="label" [ngClass]="{with_photo: userInfo!=undefined&&userInfo.photo!=null}" title="{{userInfo.first_name}} {{userInfo.last_name}}">
          <img alt="nav_user" *ngIf="userInfo!=undefined&&userInfo.photo!=null" src="profile_photo/{{userInfo.photo}}">
        </a>
        <a routerLink="/edit_user" (click)="editClick($event, userInfo.id)" class="text" *ngIf="userInfo!=undefined" title="{{userInfo.first_name}} {{userInfo.last_name}}">{{userInfo.first_name}} {{userInfo.last_name}}</a>
      </li>
      <li *ngIf="userInfo.type == 'Administrator' || userInfo.type == 'Manager' || userInfo.type == 'Consultant'"  class="nav_dashboard" [ngClass]="{active: router.url=='/'}">
        <a (click)="navClose($event)" routerLink="/" class="label" title="Dashboard"></a>
        <a (click)="navClose($event)" routerLink="/" class="text" title="Dashboard">Dashboard</a>
      </li>
      <li class="divider"></li>
      <li *ngIf="userInfo.type == 'Administrator' || userInfo.type == 'Manager' || userInfo.type == 'Consultant'"  class="nav_foundation_playlists" [ngClass]="{active: checkUrl(router.url)=='/manage-foundation-playlists'||checkUrl(router.url)=='/manage-playlists'}">
        <a (click)="navClose($event)" routerLink="/manage-foundation-playlists" class="label" title="Foundation Playlists"></a>
        <a (click)="navClose($event)" routerLink="/manage-foundation-playlists" class="text" title="Foundation Playlists">Foundation Playlists</a>
      </li>
      <li *ngIf="userInfo.type == 'Administrator' || userInfo.type == 'Manager' || userInfo.type == 'Consultant'"  class="nav_client_playlists" [ngClass]="{active: checkUrl(router.url)=='/client-playlists'||checkUrl(router.url)=='/manage-schedule'||checkUrl(router.url)=='/manage-client-playlists'}">
        <a (click)="navClose($event)" routerLink="/client-playlists" class="label" title="Client Playlists"></a>
        <a (click)="navClose($event)" routerLink="/client-playlists" class="text" title="Client Playlists">Client Playlists</a>
      </li>

      <!--
      MULTI SITE : TEMP HIDE FOR LIVE  -->
      <li *ngIf="userInfo.type == 'Administrator' || userInfo.type == 'Manager' || userInfo.type == 'Consultant'" class="nav_sample_playlists" [ngClass]="{active: router.url=='/distribution-group'}">
        <a (click)="navClose($event)" routerLink="/distribution-group" class="label" title="Distribution Group"></a>
        <a (click)="navClose($event)" routerLink="/distribution-group" class="text" title="Distribution Group">Distribution Group</a>
      </li> <!---->

       <!--Client Sample Part -->
       <!-- <li class="divider"></li>
       <li *ngIf="userInfo.type == 'Administrator' || userInfo.type == 'Manager' || userInfo.type == 'Consultant'"  class="nav_foundation_sample_playlists" [ngClass]="{active: checkUrl(router.url)=='/sample-foundation-playlists'||checkUrl(router.url)=='/manage-sample-foundation-playlists'}">
        <a (click)="navClose($event)" routerLink="/sample-foundation-playlists" class="label" title="Foundation Sample  Playlists"></a>
        <a style="margin-left:15px;" (click)="navClose($event)" routerLink="/sample-foundation-playlists" class="text" title="Foundation Sample Playlists">Foundation Sample Playlists</a>
      </li>
       <li *ngIf="userInfo.type == 'Administrator' || userInfo.type == 'Manager' || userInfo.type == 'Consultant'"  class="nav_client_playlists_sample" [ngClass]="{active: checkUrl(router.url)=='/client-sample'||checkUrl(router.url)=='/manage-sample-client-playlists'}">
        <a (click)="navClose($event)" routerLink="/client-sample" class="label" title="Client Sample "></a>
        <a (click)="navClose($event)" routerLink="/client-sample" class="text" title="Client Sample  ">Client Sample </a>
      </li> -->

      <li class="divider"></li>
      <li *ngIf="userInfo.type == 'Administrator' || userInfo.type == 'Manager' || userInfo.type == 'Consultant' || userInfo.type == 'Technician'" class="nav_admin_functions" [ngClass]="{active: router.url=='/admin-functions'}">
        <a (click)="navClose($event)" routerLink="/admin-functions" class="label" title="Tracks Library"></a>
        <a (click)="navClose($event)" routerLink="/admin-functions" class="text" title="Tracks Library">Tracks Library</a>
      </li>
      <li *ngIf="userInfo.type == 'Administrator'||userInfo.type == 'Manager'||userInfo.type == 'Consultant'" class="nav_consultant_functions" [ngClass]="{active: router.url=='/consultant-functions'}">
        <a (click)="navClose($event)" routerLink="/consultant-functions" class="label" title="Consultant Functions"></a>
        <a (click)="navClose($event)" routerLink="/consultant-functions" class="text" title="Consultant Functions">Consultant Functions</a>
      </li>
      <li *ngIf="userInfo.type == 'Administrator'||userInfo.type == 'Manager'||userInfo.type == 'Consultant'" class="nav_reports" [ngClass]="{active: router.url=='/reports'}">
        <a (click)="navClose($event)" routerLink="/reports" class="label" title="Reports"></a>
        <a (click)="navClose($event)" routerLink="/reports" class="text" title="Reports">Reports</a>
      </li>
      <li class="divider"></li>
      <!-- <li
       *ngIf="userInfo.email=='mark.hoffmann@kaleidovision.co.uk'||userInfo.email=='subramanian.baskaran@globalizeme.com'||userInfo.email=='matthew.pollard@kaleidovision.co.uk'||userInfo.email=='di@musicconcierge.co.uk'"
       class="nav_flush" [ngClass]="{active: router.url=='/flush-entity'}">
        <a  (click)="navClose($event)" routerLink="/flush-entity" class="label" title="Flush"></a>
        <a (click)="navClose($event)" routerLink="/flush-entity" class="text" title="Flush">Flush</a>
      </li> -->
      <!-- <li *ngIf="userInfo.type == 'Administrator'||userInfo.type == 'Manager'||userInfo.type == 'Consultant'" class="nav_notifications" [ngClass]="{active: router.url=='/notifications'}">
        <a (click)="navClose($event)" routerLink="/notifications" class="label" title="Notifications"></a>
        <a (click)="navClose($event)" routerLink="/notifications" class="text" title="Notifications">Notifications</a>
      </li> -->
      <li *ngIf="userInfo.type == 'Administrator'||userInfo.type == 'Manager'" class="nav_users_management" [ngClass]="{active: router.url=='/users-management'}">
        <a (click)="navClose($event)" routerLink="/users-management" class="label" title="Users Management"></a>
        <a (click)="navClose($event)" routerLink="/users-management" class="text" title="Users Management">Users Management</a>
      </li>
      <li class="nav_logout">
        <a routerLink="" class="label" (click)="logout($event)" title="Logout"></a>
        <a routerLink="" class="text" (click)="logout($event)" title="Logout">Logout</a>
      </li>
    </ul>
  </nav>
</div>
