import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {Globals} from '../globals';

@Injectable()
export class UserService{
  httpOptions = {
    headers: new HttpHeaders({
      'Host': environment.dev_url_prefix + '/',
      'Authorization': 'Bearer ' + this.globals.access_token,
      'Accept': 'application/json'
    })
  };
  postOptions={
    headers: new HttpHeaders({
      'Host': environment.dev_url_prefix + '/',
      'Authorization': 'Bearer ' + this.globals.access_token,
      'Accept': 'application/json',
      'Content-Type':'application/x-www-form-urlencoded'
    })
  };
  loginOptions={
    headers: new HttpHeaders({
      'Host': environment.dev_url_prefix + '/',
      'Accept': 'application/json',
      'Content-Type':'application/x-www-form-urlencoded'
    })
  }
  deleteOptions={
    headers: new HttpHeaders({
      'Host': environment.dev_url_prefix + '/',
      'Authorization': 'Bearer ' + this.globals.access_token,
      'Accept': 'application/json',
      'Content-Type':'application/x-www-form-urlencoded'
    })
  }

  constructor(
    private http: HttpClient,
    private globals: Globals
  ){}

  /**
   Login user
   * @param formData - post data
   * @returns {Observable<any>}
   */
  loginUser(formData){
    return this.http.post(environment.dev_url_prefix + '/api/loginUser', formData, this.postOptions).pipe(map(token => {
      return token;
    }));
  }

  /**
   Send new token on email
   * @param formData - post data
   * @returns {Observable<any>}
   */
  forgotPassword(formData){
    return this.http.post(environment.dev_url_prefix + '/user/forgot_password', formData, this.loginOptions).pipe(map(message => {
      return message;
    }));
  }

  /**
   Logout user
   * @returns {Observable<any>}
   */
  logoutUser(){
    return this.http.get(environment.dev_url_prefix + '/api/V1/api_logout', this.httpOptions).pipe(map(token => {
      return token;
    }));
  }

  /**
   Get logged user info
   * @returns {Observable<any>}
   */
  getCurrentUserInfo(){
    return this.http.get(environment.dev_url_prefix + '/api/V1/api_current_user', this.httpOptions).pipe(map(userInfo => {
      return userInfo;
    }));
  }

  /**
   Get users list
   * @param type - user type
   * @returns {Observable<any>}
   */
  getUsersList(type){
    var str = '';
    if(type!=null){
      str = '/' + type;
    }
    return this.http.get(environment.dev_url_prefix + '/api/V1/users' + str, this.httpOptions).pipe(map(userInfo => {
      return userInfo;
    }));
  }

  /**
   Create new user
   * @param formData - post data
   * @returns {Observable<any>}
   */
  createUser(formData){
    return this.http.post(environment.dev_url_prefix + '/api/V1/user', formData, this.postOptions).pipe(map(user => {
      return user;
    }));
  }

  /**
   Delete user
   * @param uid - user id
   * @returns {Observable<any>}
   */
  deleteUser(uid){
    return this.http.delete(environment.dev_url_prefix + '/api/V1/user/delete/' + uid, this.deleteOptions).pipe(map(userDelete => {
      return userDelete;
    }));
  }

  /**
   Get user information
   * @param uid - user id
   * @returns {Observable<any>}
   */
  getUserByID(uid){
    return this.http.get(environment.dev_url_prefix + '/api/V1/user/'+uid, this.httpOptions).pipe(map(userInfo => {
      return userInfo;
    }));
  }

  /**
   Update user information
   * @param formData - post data
   * @param uid - user id
   * @returns {Observable<any>}
   */
  updateUser(formData,uid){
    return this.http.put(environment.dev_url_prefix + '/api/V1/user/update/' + uid, formData,this.postOptions).pipe(map(userInfo => {
      return userInfo;
    }));
  }

  /**
   Validate password
   * @param uid - user id
   * @param hash - password hash
   * @returns {Observable<any>}
   */
  checkResetPassword(uid,hash){
    return this.http.get(environment.dev_url_prefix + '/user/valid_reset_password/' + uid + '/' + hash, this.loginOptions).pipe(map(message => {
      return message;
    }));
  }

  /**
   Reset password
   * @param formData - post data
   * @returns {Observable<any>}
   */
  resetPassword(formData){
    return this.http.post(environment.dev_url_prefix + '/user/reset_password', formData, this.loginOptions).pipe(map(message => {
      return message;
    }));
  }

  /**
   Send request for new user
   * @param formData - post data
   * @returns {Observable<any>}
   */
  sendNewUserRequest(formData){
    return this.http.post(environment.dev_url_prefix + '/user/new_registration_request', formData, this.loginOptions).pipe(map(message => {
      return message;
    }));
  }

  /**
   Create new team
   * @param formData - post data
   * @returns {Observable<any>}
   */
  createTeam(formData){
    return this.http.post(environment.dev_url_prefix + '/api/V1/team', formData,this.postOptions).pipe(map(teamInfo => {
      return teamInfo;
    }));
  }

  /**
   Get list of teams
   * @returns {Observable<any>}
   */
  getTeams(){
    return this.http.get(environment.dev_url_prefix + '/api/V1/teams', this.httpOptions).pipe(map(teamInfo => {
      return teamInfo;
    }));
  }

  /**
   Update team information
   * @param formData - post data
   * @param tid - team id
   * @returns {Observable<any>}
   */
  updateTeam( formData, tid ){
    return this.http.put(environment.dev_url_prefix + '/api/V1/team/' + tid, formData,this.postOptions).pipe(map(teamInfo => {
      return teamInfo;
    }));
  }

  /**
   * Get team information
   * @param tid - team id
   * @returns {Observable<any>}
   */
  getTeamInfo(tid){
    return this.http.get(environment.dev_url_prefix + '/api/V1/team/' + tid, this.httpOptions).pipe(map(teamInfo => {
      return teamInfo;
    }));
  }

  /**
   Delete team
   * @param tid - team id
   * @returns {Observable<any>}
   */
  deleteTeam(tid){
    return this.http.delete(environment.dev_url_prefix + '/api/V1/team/' + tid, this.deleteOptions).pipe(map(teamDelete => {
      return teamDelete;
    }));
  }


   /**
    IMPORT ENTITY
   * @param eid
   * @returns {Observable<any>}
   */
   import_entity_ids(eid){
    const str = 'eid=' + JSON.stringify(eid);
    return this.http.post(environment.dev_url_prefix + '/api/V1/import_entity' ,str, this.postOptions).pipe(map(data => {
      return data;
    }));
  }


  verify2FA(str){
    return this.http.post(environment.dev_url_prefix+ '/api/verify-2fa' , str, this.postOptions).pipe(map(data=>{
      return data;
    }))
  }

  enable2FA(uid, hiddenStatus) {
    return this.http.get(environment.dev_url_prefix + '/api/V1/user/enable2FA/' + uid + '/' + hiddenStatus, this.httpOptions).pipe(map(status => {
      return status;
    }));
  }

}
