import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {map} from 'rxjs/operators';
import { environment } from '../../environments/environment';
import {Globals} from '../globals';

@Injectable()
export class McSamplegenerateService{
    httpOptions = {
        headers: new HttpHeaders({
          'Host': environment.dev_url_prefix + '/',
          'Authorization': 'Bearer ' + this.globals.access_token,
          'Accept': 'application/json'
        })
      };
      postOptions={
        headers: new HttpHeaders({
          'Host': environment.dev_url_prefix + '/',
          'Authorization': 'Bearer ' + this.globals.access_token,
          'Accept': 'application/json',
          'Content-Type':'application/x-www-form-urlencoded'
        })
      }
      deleteOptions={
        headers: new HttpHeaders({
          'Host': environment.dev_url_prefix + '/',
          'Authorization': 'Bearer ' + this.globals.access_token,
          'Accept': 'application/json',
          'Content-Type':'application/x-www-form-urlencoded'
        })
      }
      tokenOptions={
        headers: new HttpHeaders({
          'Host': environment.dev_url_prefix + '/',
          'Accept': 'application/json',
          'Content-Type':'application/x-www-form-urlencoded'
        })
      }
    
      constructor(
        private http: HttpClient,
        private globals: Globals
      ) { }


      getdatabyToken(token:any){
        return this.http.get(environment.dev_url_prefix + '/mc_sample/get_data_bytoken/' + token, this.tokenOptions).pipe(map(list => {
          return list;
        }));
      }

      gettracksbyPlaylistID(id:any){
        return this.http.get(environment.dev_url_prefix + '/mc_sample/gettracks/' +id , this.tokenOptions).pipe(map(list => {
          return list;
        }));

      }

      getPlaylistBYChannel(id:number){
        return this.http.get(environment.dev_url_prefix + '/mc_sample/getPlaylistBYchannel/' +id , this.tokenOptions).pipe(map(list => {
          return list;
        }));
      }
   



}
