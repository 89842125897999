import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {map} from 'rxjs/operators';
import { environment } from '../../environments/environment';
import {Globals} from '../globals';

@Injectable()
export class TracksService{

  httpOptions = {
    headers: new HttpHeaders({
      'Host': environment.dev_url_prefix + '/',
      'Authorization': 'Bearer ' + this.globals.access_token,
      'Accept': 'application/json'
    })
  };
  putOptions = {
    headers: new HttpHeaders({
      'Host': environment.dev_url_prefix + '/',
      'Authorization': 'Bearer ' + this.globals.access_token,
      'Accept': 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded'
    })
  };
  postOptions = {
    headers: new HttpHeaders({
      'Host': environment.dev_url_prefix + '/',
      'Authorization': 'Bearer ' + this.globals.access_token,
      'Accept': 'application/json',
      'Content-Type':'application/x-www-form-urlencoded'
    })
  };
  deleteOptions = {
    headers: new HttpHeaders({
      'Host': '/',
      'Authorization': 'Bearer ' + this.globals.access_token,
      'Accept': 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded'
    })
  };

  constructor(
    private http: HttpClient,
    private globals: Globals
  ) { }

  /**
   Get all tracks
   * @param order - order of diplaying tracks
   * @param orderBy - ordering rule
   * @param offet - count for offset tracks
   * @param limit - limit tracks
   * @returns {Observable<any>}
   */
  getTracks(order,orderBy,offet,limit) {
    return this.http.get(environment.dev_url_prefix + '/api/V1/tracks/files/all/' + orderBy + '/' + order + '/' + offet + '/' + limit , this.httpOptions).pipe(map(tracks => {
      return tracks;
    }));
  }

  /**
   Get all tracks with tags
   * @param pid - playlist id
   * @param cat - playlist type
   * @param order - order of diplaying tracks
   * @param orderBy - ordering rule
   * @param offet - count for offset tracks
   * @param limit - limit tracks
   * @returns {Observable<any>}
   */
  getTracksAllInPlaylist( pid, cat, order, orderBy, offet, limit ) {
    return this.http.get(environment.dev_url_prefix + '/api/V1/tracks/files/all/with_tags/' + pid + '/' + cat+ '/' + orderBy + '/' + order + '/' + offet + '/' + limit, this.httpOptions).pipe(map(tracks => {
      return tracks;
    }));
  }

  /**
   Get track information
   * @param tid - track id
   * @returns {Observable<any>}
   */
  getTrackInfo(tid){
    return this.http.get(environment.dev_url_prefix + '/api/V1/tracks/file/' + tid, this.httpOptions).pipe(map(trackInfo => {
      return trackInfo;
    }));
  }

  /**
   Delete track
   * @param tid - track id
   * @returns {Observable<any>}
   */
  deleteTrack(tid){
    return this.http.delete(environment.dev_url_prefix + '/api/V1/tracks/file/' + tid, this.deleteOptions).pipe(map(trackDelete => {
      return trackDelete;
    }));
  }

  /**
   Update track information
   * @param formData - post data
   * @returns {Observable<any>}
   */
  updateTrackInfo(formData) {
    return this.http.put(environment.dev_url_prefix + '/api/V1/tracks/file', formData, this.putOptions).pipe(map(dataInfo=>{
      return dataInfo;
    }));
  }

  /**
   Bulk track update
   * @param tid - track id
   * @param formData - post data
   * @returns {Observable<any>}
   */
  updateTrackInfoBulk(tid,formData) {
    return this.http.put(environment.dev_url_prefix + '/api/V1/tracks/file/bulk_update/'+tid, formData, this.putOptions).pipe(map(dataInfo=>{
      return dataInfo;
    }));
  }

  /**
   Search tracks
   * @param str - search string
   * @param order - order of diplaying tracks
   * @param orderBy - ordering rule
   * @param offet - count for offset tracks
   * @param limit - limit tracks
   * @returns {Observable<any>}
   */
  search( str, order, orderBy, offset, limit ){
    console.log("hit3");
    return this.http.get(environment.dev_url_prefix + '/api/V1/tracks/search/' + str + '/'+ offset + '/' + limit + '/' + orderBy + '/' + order, this.httpOptions).pipe(map(trackInfo => {
      return trackInfo;
    }));
  }

  /**
   Track information in playlist
   * @param pid - playlist id
   * @param tid - track id
   * @returns {Observable<any>}
   */
  playlistTrackInfo(pid, tid){
    return this.http.get(environment.dev_url_prefix + '/api/V1/cp_playlists/file/' + pid + "/" + tid, this.httpOptions).pipe(map(trackInfo => {
      return trackInfo;
    }));
  }

  /**
   Get track history
   * @param tid - track id
   * @param date - history date
   * @returns {Observable<any>}
   */
  getModifyTracks( tid, date ){
    var d = '';
    if(date!=null){
      d = '/' + date;
    }
    
    return this.http.get(environment.dev_url_prefix + '/api/V1/cp_playlists/fields_history/' + tid + d, this.httpOptions).pipe(map(tracks => {
      return tracks;
    }));
  }

  getMCTags(){
    return this.http.get(environment.dev_url_prefix + '/api/V1/tracks/mc_tags', this.httpOptions).pipe(map(tags => {
      return tags;
    }));
  }

  createMCTag(formData){
    return this.http.post(environment.dev_url_prefix + '/api/V1/tracks/mc_tags/create',formData,this.postOptions).pipe(map(mcTags => {
      return mcTags;
    }));
  }

  updateMCTag(id,formData){
    return this.http.post(environment.dev_url_prefix + '/api/V1/tracks/mc_tags/update/' + id, formData,this.postOptions).pipe(map(mcTags => {
      return mcTags;
    }));
  }

  /**
   Get all tracks
   * @param order - order of diplaying tracks
   * @param orderBy - ordering rule
   * @param offet - count for offset tracks
   * @param limit - limit tracks
   * @returns {Observable<any>}
   */
   getMCTagsNew(order,orderBy,offet,limit) {
    return this.http.get(environment.dev_url_prefix + '/api/V1/tracks/mc_tags/all/' + orderBy + '/' + order + '/' + offet + '/' + limit , this.httpOptions).pipe(map(mcTags => {
      return mcTags;
    }));
  }

  getchannelsOfTracks(ids:any){ 
    return this.http.get(environment.dev_url_prefix + '/api/V1/trackschannel/' + ids , this.httpOptions).pipe(map(tracksChannels => {
      return tracksChannels;
    }));
  }

  setHidden(pid, hiddenStatus) {
    return this.http.get(environment.dev_url_prefix + '/api/V1/tracks/hidden_change/' + pid + '/' + hiddenStatus, this.httpOptions).pipe(map(status => {
      return status;
    }));
  }

  updateExplicit(pid, status) {
    return this.http.get(environment.dev_url_prefix + '/api/V1/tracks/update_explicit/' + pid + '/' + status, this.httpOptions).pipe(map(status => {
      return status;
    }));
  }

  bulkSearchUid(uids,order,orderBy,offset,limit){
    const str = 'uid=' + JSON.stringify(uids);
    
    return this.http.post(environment.dev_url_prefix + '/api/V1/tracks/track_search_by_uid/'+ orderBy + '/' + order + '/' + offset + '/' + limit,str,this.postOptions).pipe(map(tracks => {
      return tracks;
    }));

  }

}
