import { Component, ElementRef, OnInit, ViewChild, OnDestroy, HostListener } from '@angular/core';
import { TracksService } from '../services/tracks.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CPPlaylistsService } from '../services/cp_playlists.service';
import { CPPlaylistCategoriesService } from '../playlist-categories/cp_playlist-categories.service';
import { CommonService } from '../services/common.service';
import { NgForm } from '@angular/forms';
import { TagsService } from '../services/tags.service';
import { SmartFilterService } from '../services/smart-filter.service';
import { IMyDpOptions } from 'mydatepicker';
import { PlaylistsService } from '../services/playlists.service';
import { PlayerComponentComponent } from '../player-component/player-component.component';
import { ProfileStatementService } from '../services/profile_statements.service';
import { PlaylistCategoriesService } from '../playlist-categories/playlist-categories.service';
import { AlertComponent } from '../alert/alert.component';
import { ConfirmComponent } from '../confirm/confirm.component';
import { Arrays } from '../classes/arrays';
import { Globals } from '../globals';
import { ExcelService } from '../services/excel.service';
import { ClientSampleService } from '../services/client_sample.service';
import { FpSampleService } from '../services/fp_sample.service';

@Component({
  selector: 'app-manage-sample-fp',
  templateUrl: './manage-sample-fp.component.html',
  styleUrls: ['./manage-sample-fp.component.scss'],
  providers: [
    TracksService, CPPlaylistsService, CPPlaylistCategoriesService,
    CommonService, TagsService, SmartFilterService, PlaylistsService,
    ProfileStatementService, PlaylistCategoriesService, ExcelService, ClientSampleService, FpSampleService]
})
export class ManageSampleFPComponent implements OnInit {

 /**
     Params for page.
     If isFullscreen = true - Tracks library and playlist will display in different windows.
     If isPlaylist = true browser will show playlist in current window
   **/
     screenParams = {
      isFullScreen: false,
      isPlaylist: false
    };
    isShowHeader = true; // Sets displaying header or not
    /**
      Array of params for tabs in left side
      Params={
        selected(boolean) - set active tab(default false),
        playlistId(integer) - ID of playlist(default -1),
        tabid(integer) - index of tab,
        tracks(array) - Array of tracks,
        selectedRows(array) - array of selected rows in current tab,
        title(string) - title for tab(default ''),
        order(string) - order of diplaying tracks(default 'ASC'),
        orderBy(string) - ordering rule. List of possible values [ 'title', 'artist', 'album', 'add_date', 'bpm'](default 'add_date')
      }
     **/
    leftTabs: any = [{
      selected: true,
      playlistId: 0,
      tabid: 1,
      tracks: [],
      selectedRows: [],
      title: 'Tracks Library',
      order: 'ASC',
      orderBy: 'add_date'
    }, {
      selected: false,
      playlistId: -1,
      tabid: 2,
      tracks: [],
      selectedRows: [],
      title: 'Foundation playlist',
      order: 'ASC',
      orderBy: 'add_date'
    },
    {
      selected: false,
      playlistId: -1,
      tabid: 3,
      tracks: [],
      selectedRows: [],
      title: 'FP Samples',
      order: 'ASC',
      orderBy: 'add_date'
    },
    {
      selected: false,
      playlistId: -1,
      tabid: 4,
      tracks: [],
      selectedRows: [],
      title: 'Client Samples',
      order: 'ASC',
      orderBy: 'add_date'
    },
    {
      selected: false,
      playlistId: -1,
      tabid: 5,
      tracks: [],
      selectedRows: [],
      title: 'Client Playlists',
      order: 'ASC',
      orderBy: 'add_date'
  
    }
    ];
    /**
      Params for tracks scrolling
      Params={
        offsetItems(integer) - offet tracks number(default 0) ,
        addItems(integer) -  number tracks for upload(default 100),
        uploadedItems(integer) -  number uploaded tracks(default 100)
      }
     **/
    infiniteScroll = {
      offsetItems: 0,
      addItems: 1000,
      uploadedItems: 100
    };
    draggable = {
      data: null,
      effectAllowed: "all",
      disable: false,
      handle: false
    };//params for draggable module
    draggableRemove = {
      data: null,
      effectAllowed: "all",
      disable: false,
      handle: false
    };//params for draggable module
    selectedTabs = {
      leftTabIndex: 0,
      rightTabIndex: 0
    };//indexes of selected tabs in left and right side
    tempPlaylistId = null; //
    /**
      Array of params for tabs in right side
      Params={
        selected(boolean) - set active tab(default false),
        tracks(array) - Array of tracks,
        playlistInfo(Object) - information of playlist,
        averageBpm(integet) - the average BPM of playlist,
        selectedRows(array) - array of selected rows in current tab,
        order(string) - order of diplaying tracks(default 'ASC'),
        orderBy(string) - ordering rule. List of possible values [ 'title', 'artist', 'album', 'add_date', 'bpm'](default 'add_date')
        tab_str(string) - title for tab(default ''),
        type(string) - type of playlist. List of possible values ['cp', 'fp'](default 'cp')
      }
     **/
    tabs: any = [{
      selected: true,
      tracks: [],
      playlistInfo: null,
      averageBpm: 0,
      selectedRows: [],
      order: 'ASC',
      orderBy: 'add_date',
      tab_str: '',
      type: 'sfp'
    }];
  
    playlistStatusText = ['Live', 'Editing', 'Suspended']; // Array of statuses text for playlists
    trackInfo; // Information of selected track
    additional = []; // Array of additional attributes for information of track
    /**
      Popups list. Params for opening/closing popups
    **/
    popups = {
      otherPlaylistsPopupOpened: false,
      popupOpened: false,
      editPopupOpened: false,
      smartFilterPopupOpened: false,
      manageTagsPopupOpened: false,
      playlistPopupOpened: false,
      convertPlaylistPopupOpened: false,
      restoredPlaylistPopupOpened: false,
      profileStatementOpened: false,
      profileHistoryopened: false,
      profileCreatePopupOpened: false
    };
    current_user;// information of logged user
    isFocused = false; // Checking of inputs focus
    drops = {
      clients: false,
      fp: false
    };// Open dropdowns with list of categories/playlists
    @ViewChild(PlayerComponentComponent, { static: true }) player: PlayerComponentComponent; //Component of player
    @ViewChild(AlertComponent, { static: true }) alert: AlertComponent; //Component of alert popup
    @ViewChild(ConfirmComponent, { static: true }) confirm: ConfirmComponent; //Component of confirm popup
    /**
      Counter of tracks adding to playlist
      {
        count(integer) - number of adding tracks
        countet(integer) - number of success added tracks
        count(integer) -  number of errors when adding tracks
      }
     **/
    addCount = {
      count: 1,
      counter: 1,
      errors: 0
    };
    link = 'sample-foundation-playlists';//Link of previous page
    categoriesList = []; // List of playlists categories
    checkedUserId = null; //User id for opened playlist
    checkedSiteId = null;
    /**
      Player information
      {
        isPlaying(string) - player status. List of possible values ['stop', 'play','pause'](default 'stop'),
        trackIndex(integer) - index of playling track in playlist(default null),
        trackId(integer) - playling track id(default null),
        filename(string) - file name of playing track(default null),
        artist(string) - playling track artist(default ''),
        album(string) - playling track album(default ''),
        storage(string) - storage of playling track(default ''),
        token(string) - access token for logged user(default '')
      }
    **/
    playerInfo = {
      isPlaying: 'stop',
      trackIndex: null,
      trackId: null,
      filename: null,
      artist: '',
      album: '',
      storage: '',
      token: ''
    };
    /**
      Field for creating or editing playlist
      {
        id(integer) - playlist ID(default null),
        name(string) - playlist name(default ''),
        catId(integer) - playlist category ID(default null),
        subcatId(integer) - playlist sub category ID(default null),
        status(integer)  - statue of playlist(default 1)
      }
    **/
    playlistFields = {
      id: null,
      name: '',
      client_id: null,
      site_id: null,
      status: 1,
      edited_by:null,
    };
    /**
      Value for confirmation popup
      {
        id(integer) - ID of element for action(default null),
        type(integer) - type of function for action(default 1)
      }
    **/
    confirmValues = {
      id: null,
      type: 0
    };
    /**
      Value of foundation playlists tree
      {
        category: {
          id - ID of playlist category
          catsArr - array of playlilst categories
        },
        subcategory: {
          id - ID of playlist sub category
        },
        playlists: {
          id - ID of playlist
          playlistsArr - array of playlilsts
        }
      }
    **/
    fpList = {
      category: {
        id: null,
        catsArr: [],
        catsArrRaw: []
      },
      subcategory: {
        id: null
      },
      playlists: {
        id: null,
        playlistsArr: []
      }
    };
    fpsampleList = {
      category: {
        id: null,
        catsArr: [],
        catsArrRaw: []
      },
      subcategory: {
        id: null
      },
      playlists: {
        id: null,
        playlistsArr: []
      }
    };
  
    cpsampleList = {
      category: {
        id: null,
        catsArr: [],
        catsArrRaw: []
      },
      subcategory: {
        id: null
      },
      playlists: {
        id: null,
        playlistsArr: []
      }
  
    };
  
    cpList = {
      client: {
        id: null,
        clientArr: [],
        clientArrRaw: []
      },
      category: {
        id: null,
        catsArr: [],
        catsArrRaw: []
      },
      subcategory: {
        id: null,
        subcarArr: []
      },
      playlists: {
        id: null,
        playlistsArr: []
      }
  
    };
    defaultSmartFilters: any = []; // Array of default smart filters
    defaultSmartFiltersPS: any = [];
    /**
      Selected smart filters values
      {
        categorySelect - ID of selected smart filter category,
        compare - array of compare values,
        compareSelect  - selected compare value,
        field - smart filter value,
        arrFields - array of smart filter values(if selected type of smart filter = 'date'),
        type - smart filter type. List of possible values ['date', 'int','str'](default 'str'),
        isHidden - is smart filter available for change
      }
    **/
  
    smartFilters: any = [[{
      categorySelect: null,
      compare: [],
      compareSelect: null,
      field: null,
      arrFields: ['', ''],
      type: 'str',
      isHidden: false
    }],
    [{
      categorySelect: null,
      compare: [],
      compareSelect: null,
      field: null,
      arrFields: ['', ''],
      type: 'str',
      isHidden: false
    }],
    [{
      categorySelect: null,
      compare: [],
      compareSelect: null,
      field: null,
      arrFields: ['', ''],
      type: 'str',
      isHidden: false
    }],
    [{
      categorySelect: null,
      compare: [],
      compareSelect: null,
      field: null,
      arrFields: ['', ''],
      type: 'str',
      isHidden: false
    }],
    [{
      categorySelect: null,
      compare: [],
      compareSelect: null,
      field: null,
      arrFields: ['', ''],
      type: 'str',
      isHidden: false
    }]
    ];
    matchSelect = 'all'; //Value for smart filter match of following rules
    andOrSelect = 'and';
    public myDatePickerOptions: IMyDpOptions = {
      dateFormat: 'dd/mm/yyyy',
      editableDateField: false,
      inline: false,
      height: '30px'
    };//params for Date picker module
    tags: any = [];//Array of tags
    updateTimer = null; //Timer for automatic updating tables in playlists
  
    searchStr = ''; //Value of search input
    searchStrFP = '';
    searchStrFPsample = '';
    searchStrCPsample = '';
  
  
    similarPlaylists: any = [];//Array of similar playlists
  
    subcategoriesList = null; //Array of sub categories
  
    smartFilterResults: any = []; // Array of smart filter results
    selectAllConvert = false; // Value for convert all results in playlist
    convert = false; //Convert popup open value
  
    restoredPlaylistTracks: any = []; //List of restored playlist tracks
    restoredFilters = {
      activeTab: 0,
      date: [],
      track: '',
      user: '',
      actions: ''
    };
    /**
      Array of profile statements params
      {
        id(integer) - id of profile statement,
        fp_id(integer) - id of selected foundation playlist,
        cover(string) - value of Cover field ,
        ratio(string) - value of Ratio field  ,
        note(string) - Note for profile statement
      }
     **/
    profileStatements = [{
      id: null,
      fp_id: null,
      cover: '1',
      ratio: '',
      note: '',
      smartFiltersPS: [{
        categorySelect: null,
        compare: [],
        compareSelect: null,
        field: null,
        arrFields: ['', ''],
        type: 'str',
        isHidden: false
      }],
      availableTracks: 0,
      futureCoverage: 0,
      trackIDs: [],
      length_sec: 0
    }];
    coverOptions = [1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5, 5.5, 6, 6.5, 7, 7.5, 8, 8.5, 9, 9.5, 10];
    ratioOptions = [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100];
    /**
      Value of focused fields Ratio and Cover in profile statement popup
      {
        index(integer) - index of profile statement in popup,
        num(integer) - number of field in current profile statement index
      }
    **/
    focusedInput = {
      index: null,
      num: null
    };
  
    fpCategories = []; //Array of founation playlist categories
  
    isLoad = false; //Display preloader
    /**
      Array of foundation playlists for selecting
      {
        catId - category ID,
        subcatId - subcategory ID,
        fp_id: - playlist ID,
        fp_arr - array of foundation playlists,
        dropOpened - open dropdown,
        title - default select value
      }
    **/
    psSelects = [{
      catId: null,
      subcatId: null,
      fp_id: null,
      fp_arr: [],
      dropOpened: false,
      title: "Select Foundation playlist..."
    }];
    openPlaylistDrop = false; //Open playlist dropdown for adding new tab
    openPlaylistDrop2 = false; //Open playlist dropdown for adding new tab
    showPlaylists = false;
    /**
      Client playlist values for dropdowns
      {
        users: {
          id - selected user ID
          usersArr - array of users
        },
        sites: {
          id - selected site ID
          sitesArr - array of sites
        },
        channels: {
          id - selected channel ID
        },
        playlists: {
          id - selected playlist ID
          playlistsArr - array of playlists
        }
      }
    **/
    openPlaylist = {
      users: {
        id: null,
        usersArr: []
      },
      sites: {
        id: null,
        sitesArr: []
      },
      channels: {
        id: null,
        channelsArr: []
      },
      playlists: {
        id: null,
        playlistsArr: []
      }
    };
    /**
      Foundation playlist values for dropdowns
      {
        category: {
          id - selected category ID,
          catsArr - array of categories
        },
        subcategory: {
          id - selected sub category ID
        },
        playlists: {
          id - selected playlist ID,
          playlistsArr - array of playlists
        }
      }
    **/
    openFPPlaylist = {
      category: {
        id: null,
        catsArr: []
      },
      subcategory: {
        id: null
      },
      playlists: {
        id: null,
        playlistsArr: []
      }
    };
    playlistType = 0;//Selected playlist type in dropdown 0 - any selected, 1 - Client playlist, 2 - Foundation playlist
    /**
      Tracks count in Tracks library
      {
        total - total tracks count,
        display - displaying tracks count
      }
    **/
    tracksCount = [{
      total: 0,
      display: 0,
      totalDuration: '0',
      selectedDuration: '0'
    },
    {
      total: 0,
      display: 0,
      totalDuration: '0',
      selectedDuration: '0'
    },
    {
      total: 0,
      display: 0,
      totalDuration: '0',
      selectedDuration: '0'
    }, {
      total: 0,
      display: 0,
      totalDuration: '0',
      selectedDuration: '0'
    },
    {
      total: 0,
      display: 0,
      totalDuration: '0',
      selectedDuration: '0'
    },
    ];
    isSmartSearch = false;//Smart filter searching
    searchTimeout = null; // Timeout for starting searching
    filterClicked = { 0: false, 1: false }
  
    profileStatementFields = {
      id: null,
      name: '',
      visible: 1
    }
  
    excludedTracks: any = [];
    showExcludedTracks = false;
    mcTagsList = [];
    selectedMCTags = [];
    dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
  
    disabledInputs = {
      year: false,
      artist: false,
      album: false,
      title: false
    }
    cpPlaylistList = [];
    filteredParentIDs = [];
    filteredChildIDs = [];
    loadingFPSearch: boolean = false;
    loadingFPSampleSearch: boolean = false;
    loadingCPSampleSearch: boolean = false;
    filteredParentIDsFPS = [];
    filteredChildIDsFPS = [];
    filteredParentIDsCPS = [];
    filteredChildIDsCPS = [];
  
    filteredParentIDsCP = [];
    filteredChildIDsCP = [];
    filteredClientIDsCP = [];
    loadingCPSearch: boolean = false;
    searchStrCP = '';
    filteredParentIDsFP = [];
    filteredChildIDsFP = [];
    playlist_duration_ps = 0;
    percentage_to_update = 0;
    playlist_report = {};
    playlist_report_dialog = false;
    profileFilterCount = 0;
    selectedTabname='';
  
  
  
  
    constructor(
      private trackService: TracksService,
      private cpService: CPPlaylistsService,
      private route: ActivatedRoute,
      private router: Router,
      private chanelService: CPPlaylistCategoriesService,
      private commonService: CommonService,
      private tagService: TagsService,
      private smartService: SmartFilterService,
      private elRef: ElementRef,
      private fpService: PlaylistsService,
      private profile_statementService: ProfileStatementService,
      private fp_categoriesService: PlaylistCategoriesService,
      private globals: Globals,
      private excelService: ExcelService,
      private clientSamplePlaylist: ClientSampleService,
      private fpsamplePlaylistService: FpSampleService,
    ) { }
  
    ngOnInit(): void {
      this.commonService.getCurrentLoggedUser().subscribe(current_user => {
        this.current_user = current_user;
  
        this.fp_categoriesService.getPlaylistsCategories('').subscribe((cats: any) => {
  
          this.fpList.category.catsArr = cats;
          this.fpList.category.catsArrRaw = cats;
        });
        this.fpsamplePlaylistService.getPlaylistsCategories().subscribe((cats: any) => {
  
          this.fpsampleList.category.catsArr = cats;
          this.fpsampleList.category.catsArrRaw = cats;
        });
        this.clientSamplePlaylist.getPlaylistsCategories().subscribe((cats: any) => {
  
          this.cpsampleList.category.catsArr = cats;
          this.cpsampleList.category.catsArrRaw = cats;
        });
        this.cpService.getclientPlaylistsCategories().subscribe((cats: any) => {
  
          this.cpList.client.clientArr = cats;
          this.cpList.client.clientArrRaw = cats;
        });
  
      });
  
  
      this.route.queryParams.subscribe(params => {
        this.getAllTracks(params['playlistID']);
        this.tempPlaylistId = params['playlistID'];
        if (params['sh'] != null && params['sh'] == 0) {
          this.isShowHeader = false;
        }
        if (params['ipl'] == 'true' || params['ipl']) {
          this.screenParams.isPlaylist = true;
        }
        if (params['ifs'] == 'true' || params['ifs']) {
          this.screenParams.isFullScreen = true;
        }
        this.setTabsInfo(0, params['playlistID'], 'sfp');
        this.checkedUserId = params['uid'];
        this.checkedSiteId = params['pcat'];
        this.setStatus(params['playlistID'], 1);
      });
  
      this.smartService.getFilters('').subscribe(filters => {
        this.defaultSmartFilters[0] = filters;
        this.defaultSmartFiltersPS = filters;
      });
  
      this.updateCategoriesList();
      this.getTags();
      this.getMCTags();
      this.getPlaylistsByClient();
      this.getPlaylistDuration();
    }
  
    getPlaylistsByClient() {
      this.cpPlaylistList = [];
      this.cpService.getPlaylistsByClient(this.checkedSiteId).subscribe((playlists: any) => {
  
        if (playlists) {
          for (let key in playlists) {
            if (playlists.hasOwnProperty(key)) {
              this.cpPlaylistList.push({
                item_id: playlists[key].name,
                item_text: playlists[key].name
              })
            }
          }
        }
      });
    }
  
    getPlaylistDuration() {
  
      this.cpService.getLongestSchedule(this.tempPlaylistId).subscribe((playlists: any) => {
  
        if (playlists) {
          this.playlist_duration_ps = playlists.time_gap.toFixed(1);
        }
  
      });
    }
  
    getMCTags() {
      this.trackService.getMCTags().subscribe((tags: any) => {
        if (tags) {
          for (let key in tags) {
            if (tags.hasOwnProperty(key)) {
              this.mcTagsList.push({
                item_id: tags[key].name,
                item_text: tags[key].name
              })
            }
          }
        }
      });
    }
  
    onFilterChange(e) {
      this.isFocused = true;
    }
    onDropDownClose() {
  
      this.isFocused = false;
    }
  
    ngOnDestroy() {
      localStorage.removeItem("smartFiltersTL");
      localStorage.removeItem("smartFiltersFP");
      localStorage.removeItem("smartFiltersSCP");
      localStorage.removeItem("smartFiltersSFP");
      localStorage.removeItem("smartFiltersCP")
      clearInterval(this.updateTimer);
      if (this.playerInfo.isPlaying == 'play') {
        this.player.pause();
      }
    }
  
    /**
      Sets information for player and start/pause track
     * @param file_id - file id from database
     * @param file_name - name of file
     * @param index - index of active tab
     * @param album - album name
     * @param artist - artist name
     * @param title - track title
     * @param storage - storage on server
     * @returns {boolean}
     **/
    playTrack(file_id, file_name, index, album, artist, title, storage) {
      if (this.playerInfo.isPlaying == 'play' && this.playerInfo.trackId == file_id && this.playerInfo.trackIndex == index) {
        this.player.pause();
      }
      else {
        this.playerInfo.filename = file_name;
        this.playerInfo.trackId = file_id;
        this.playerInfo.trackIndex = index;
        this.playerInfo.album = title + ' - ' + album;
        this.playerInfo.artist = artist;
        this.playerInfo.storage = storage;
        this.playerInfo.token = this.globals.access_token;
        this.player.start(this.playerInfo.trackId, true);
      }
      return false;
    }
  
    /**
     Get tracks library
     * @param id - selected playlist id
     */
    getAllTracks(id) {
      this.isSmartSearch = false;
      var offset = this.infiniteScroll.offsetItems;
      var limit = this.infiniteScroll.addItems;
      this.trackService.getTracksAllInPlaylist(id, 'sfp', this.leftTabs[0].order, this.leftTabs[0].orderBy, offset, limit).subscribe((tracks: any) => {
        this.tracksCount[this.selectedTabs.leftTabIndex].total = tracks.total;
        this.tracksCount[this.selectedTabs.leftTabIndex].display = tracks.total;
        if (offset == 0)
          this.leftTabs[0].tracks = tracks.list;
        else
          this.leftTabs[0].tracks = this.leftTabs[0].tracks.concat(tracks.list);
  
        this.getDuration();
      });
    }
  
    /**
     Append items in track library after scroll page
     */
    appendItems() {
      this.infiniteScroll.offsetItems += this.infiniteScroll.addItems;
      if (this.searchStr == '' && !this.isSmartSearch && this.selectedTabs.leftTabIndex == 0)
        this.getAllTracks(this.tabs[0].playlistInfo.id);
      else if (this.isSmartSearch)
        this.smartSearch(false);
      else
        this.search();
    }
  
    /**
     Scrolling event
     */
    onScroll() {
  
      if (this.infiniteScroll.addItems < this.tracksCount[this.selectedTabs.leftTabIndex].total) {
        this.appendItems();
      }
  
    }
  
    /**
     Chegking tracks playing
     * @param e - event
     */
    checkPlaying(e) {
  
      this.playerInfo.isPlaying = e;
      if (this.playerInfo.isPlaying != 'play') {
        if (this.leftTabs[this.selectedTabs.leftTabIndex].selectedRows.length > 0)
          this.addPlayerInfo(0, this.leftTabs[this.selectedTabs.leftTabIndex].selectedRows[0]);
        else
          this.addPlayerInfo(1, this.tabs[this.selectedTabs.rightTabIndex].selectedRows[0]);
      }
    }
  
    /**
     Add track to playlist when user drag row(s)
     * @param {DragEvent} event - event
     * @param id - track id
     * @param pivot_id - track id in playlist
     */
    addDragTrack(id, pivot_id) {
      this.draggable.data = id;
      if (pivot_id != undefined) {
        this.draggable.data = this.getTrackIdByPivot(pivot_id, this.leftTabs[this.selectedTabs.leftTabIndex].tracks);
      }
    }
  
    /**
     Remove track from playlist when user drag row(s)
     * @param {DragEvent} event - event
     * @param id - track id in playlist
     */
    removeDragTrack(id) {
      this.draggableRemove.data = id;
    }
  
    /**
     Drop event when user drag track to add to playlist
     * @param pid - playlist id
     */
    onDrop(pid) {
      if (this.leftTabs[this.selectedTabs.leftTabIndex].selectedRows.length > 1) {
        this.addTracks(this.leftTabs[this.selectedTabs.leftTabIndex].selectedRows, pid);
      }
      else {
        this.addTrack(this.draggable.data, pid, false);
      }
    }
  
    /**
     Drop event when user drag track to remove from playlist
     */
    onDropRemove() {
  
      if (this.tabs[this.selectedTabs.rightTabIndex].selectedRows.length > 1) {
        this.openConfirmPopup(0, 1);
      }
      else {
        this.removeTrack(this.draggableRemove.data);
      }
    }
  
    /**
     Set information for tabs
     * @param tabindex - index of tab
     * @param pid - playlist id
     * @param type - playlist type 'sfp' or 'fp'  SFP--Sample Foundation Playlist 
     */
    setTabsInfo(tabindex, pid, type) {
      this.tabs[tabindex].type = type;
      if (type == 'sfp') {
        this.fpsamplePlaylistService.getmanagerPlaylistById(pid).subscribe((info: any) => {
          this.tabs[tabindex].playlistInfo = info;
          this.tabs[tabindex].tab_str = info.category.name + ' -> ' + info.subcategory.name + ' -> ' + info.name;
          this.getPlaylistTracks(pid, tabindex);
          this.getCategoryParent(info.cp_category_id, tabindex);
        });
      }
    }
  
    /**
     Get tracks from playlist
     * @param pid - playlist id
     * @param index - tab index
     */
    getPlaylistTracks(pid, index) {
      var tab = this.tabs[index];
      if (tab.type == 'sfp') {
        this.fpsamplePlaylistService.getPlaylistTracks(pid, tab.order, tab.orderBy).subscribe((tracks: any) => {
          if (tracks.message == undefined) {
            this.tabs[index].tracks = tracks;
            this.tabs[index].averageBpm = Math.round(this.getBpm(this.tabs[index].tracks));
          }
          else {
            this.tabs[index].tracks = [];
            this.tabs[index].averageBpm = 0;
          }
  
          for (var i = 0; i < this.tabs[index].tracks.length; i++) {
            if (this.tabs[index].tracks[i].excluded) {
              let et = this.tabs[index].tracks[i];
              et.playlist_name = this.tabs[index].playlistInfo.name;
              et.playlist_type = 'Sample Foundation Playlist';
              this.excludedTracks.push(et);
  
            }
          }
          this.removeETDuplicates();
          this.getCpDuration();
  
        });
      }
    }
  
    /**
     Set tabs info for Foundation playlist
     * @param tabindex - tab index
     * @param pid - playlist id
     */
    setFPTabsInfo(tabindex, pid) {
      this.fpService.getPlaylistById(pid).subscribe((info: any) => {
        this.tabs[tabindex].playlistInfo = info;
        this.tabs[tabindex].tab_str = info.parent_node.name;
        this.getFPPlaylistTracks(pid, tabindex);
        this.getFPCategoryParent(this.tabs[tabindex].playlistInfo.foundation_category_id, tabindex);
      });
    }
  
    /**
     Update tabs information and tracks for all tabs
     */
    updateTabs() {
      if (this.searchStr == '') {
        for (var i = 0; i < this.tabs.length; i++) {
          this.setTabsInfo(i, this.tabs[i].playlistInfo.id, this.tabs[i].type);
        }
      }
    }
  
    /**
     Add new tab
     * @param pid - playlist id
     * @returns {boolean}
     */
    addTab(pid) {
      var newtab = {
        selected: true,
        category_parent: null,
        tracks: [],
        playlistInfo: null,
        averageBpm: 0,
        selectedRows: [],
        order: 'ASC',
        orderBy: 'add_date',
        tab_str: '',
        type: 'sfp'
      }; // default tabs information
      this.addTracks(this.leftTabs[this.selectedTabs.leftTabIndex].selectedRows, pid);
      var checkTab = this.checkTabs(pid);
      if (checkTab == -1) {
        this.tabs.push(newtab);
        this.setTabsInfo(this.tabs.length - 1, pid, 'sfp');
        this.deselectTab(this.tabs.length - 1);
      }
      else {
        this.deselectTab(checkTab);
      }
      this.popupClose();
      return false;
    }
  
    /**
     Deselect all tabs in right side excerpt choosen
     * @param tid - tab index
     */
    deselectTab(tid) {
      for (var i = 0; i < this.tabs.length; i++) {
        if (i != tid) {
          this.tabs[i].selected = false;
        }
        else {
          this.tabs[i].selected = true;
          this.selectedTabs.rightTabIndex = tid;
        }
      }
      this.showExcludedTracks = false;
    }
  
    /**
     Deselect all tabs in left side excerpt choosen
     * @param id - tab index
     */
    deselectfpTab(id) {
      for (var i = 0; i < this.leftTabs.length; i++) {
        if (i != id) {
          this.leftTabs[i].selected = false;
          this.leftTabs[i].selectedRows = [];
        }
        else {
          this.leftTabs[i].selected = true;
          this.selectedTabs.leftTabIndex = id;
        }
  
      }
  
  
      if (this.leftTabs[1].selected && (this.defaultSmartFilters[1] == undefined || this.defaultSmartFilters[1].length == 0)) {
        this.smartService.getFilters('fp').subscribe(filters => {
  
          this.defaultSmartFilters[1] = filters;
        });
      }
      else if (this.leftTabs[0].selected && (this.defaultSmartFilters[0] == undefined || this.defaultSmartFilters[0].length == 0)) {
        this.smartService.getFilters('').subscribe(filters => {
          this.defaultSmartFilters[0] = filters;
        });
      }
      else if (this.leftTabs[2].selected && (this.defaultSmartFilters[2] == undefined || this.defaultSmartFilters[2].length == 0)) {
        this.smartService.getFilters('').subscribe(filters => {
          this.defaultSmartFilters[2] = filters;
        });
      }
      else if (this.leftTabs[3].selected && (this.defaultSmartFilters[3] == undefined || this.defaultSmartFilters[3].length == 0)) {
        this.smartService.getFilters('').subscribe(filters => {
          this.defaultSmartFilters[3] = filters;
        });
      }
      else if (this.leftTabs[4].selected && (this.defaultSmartFilters[4] == undefined || this.defaultSmartFilters[4].length == 0)) {
        this.smartService.getFilters('').subscribe(filters => {
          this.defaultSmartFilters[4] = filters;
        });
      }
      this.getDuration();
  
    }
  
    /**
      Check opened playlist in right side
     * @param pid - playlilst id
     * @returns {number} - tab index
     */
    checkTabs(pid) {
      for (var i = 0; i < this.tabs.length; i++) {
        if (this.tabs[i].playlistInfo.id == pid) {
          return i;
        }
      }
      return -1;
    }
  
    /**
     Checking events on click keys on keyboard
     * @param {KeyboardEvent} event - event
     */
    @HostListener('document:keydown', ['$event'])
    handleKeyboardEvent(event: KeyboardEvent) {
      if (this.checkOpenedPopups()) {
        var k = parseInt(event.key);
        if (k == 1 || k == 2 || k == 3 || k == 4 || k == 5 || k == 6 || k == 7 || k == 8 || k == 9) {
          if (this.tags[k - 1].info != null) {
            if (this.tabs[this.selectedTabs.rightTabIndex].selectedRows.length > 0) {
              var str = 'tag_ids[0]=' + this.tags[k - 1].info.id;
              this.tagService.setTagsForTrack(this.tabs[this.selectedTabs.rightTabIndex].selectedRows[0], str, 'sfp').subscribe(tags => {
                this.getPlaylistTracks(this.tabs[this.selectedTabs.rightTabIndex].playlistInfo.id, this.selectedTabs.rightTabIndex);
              });
            }
            else if (this.leftTabs[this.selectedTabs.leftTabIndex].selectedRows.length > 0) {
              var str = 'tag_ids[0]=' + this.tags[k - 1].info.id;
              this.tagService.setTagsForTrackLibrary(this.leftTabs[this.selectedTabs.leftTabIndex].selectedRows[0], str, 'sfp', this.tabs[this.selectedTabs.rightTabIndex].playlistInfo.id).subscribe(tags => {
                this.infiniteScroll.offsetItems = 0;
                this.getAllTracks(this.tabs[this.selectedTabs.rightTabIndex].playlistInfo.id);
              });
            }
          }
        }
        else if (k == 0) {
          if (this.tags[9].info != null) {
            if (this.tabs[this.selectedTabs.rightTabIndex].selectedRows.length > 0) {
              var str = 'tag_ids[10]=' + this.tags[9].info.id;
              this.tagService.setTagsForTrack(this.tabs[this.selectedTabs.rightTabIndex].selectedRows[0], str, 'sfp').subscribe(tags => {
                this.getPlaylistTracks(this.tabs[this.selectedTabs.rightTabIndex].playlistInfo.id, this.selectedTabs.rightTabIndex);
              });
            }
            else if (this.leftTabs[this.selectedTabs.leftTabIndex].selectedRows.length > 0) {
              var str = 'tag_ids[10]=' + this.tags[9].info.id;
              this.tagService.setTagsForTrackLibrary(this.leftTabs[this.selectedTabs.leftTabIndex].selectedRows[0], str, 'sfp', this.tabs[this.selectedTabs.rightTabIndex].playlistInfo.id).subscribe(tags => {
                this.infiniteScroll.offsetItems = 0;
                this.getAllTracks(this.tabs[this.selectedTabs.rightTabIndex].playlistInfo.id);
              });
            }
          }
        }
        if (event.key == 'ArrowLeft') {
          if (!this.isFocused) {
            event.preventDefault();
            if (this.tabs[this.selectedTabs.rightTabIndex].selectedRows.length > 0) {
              this.openConfirmPopup(0, 1);
            }
          }
        }
        else if (event.key == 'ArrowRight') {
          if (!this.isFocused) {
            event.preventDefault();
            if (this.leftTabs[this.selectedTabs.leftTabIndex].selectedRows.length > 0) {
              this.addTracks(this.leftTabs[this.selectedTabs.leftTabIndex].selectedRows, this.tabs[this.selectedTabs.rightTabIndex].playlistInfo.id);
            }
          }
        }
        else if (event.key == 'ArrowUp') {
          event.preventDefault();
          this.setPosition(0);
        }
        else if (event.key == 'ArrowDown') {
          event.preventDefault();
          this.setPosition(1);
        }
      }
    }
  
    /**
     Set position after click key up/key down on keyboad
     * @param arrow(number) - arrow 0=key up, 1=key down
     */
    setPosition(arrow) {
      var arr = [];
      var el = false;
      if (this.leftTabs[this.selectedTabs.leftTabIndex].selectedRows.length > 0) {
        var pos = this.getSelectedPosition(this.leftTabs[this.selectedTabs.leftTabIndex].tracks, this.leftTabs[this.selectedTabs.leftTabIndex].selectedRows[0]);
        if (arrow == 0) {
          if (pos > 0) {
            this.leftTabs[this.selectedTabs.leftTabIndex].selectedRows = [];
            if (this.selectedTabs.leftTabIndex == 0)
              this.leftTabs[this.selectedTabs.leftTabIndex].selectedRows[0] = this.leftTabs[this.selectedTabs.leftTabIndex].tracks[pos - 1].id;
            else if (this.smartFilters[1][0].categorySelect
              && this.smartFilters[1][0].compareSelect
              //&& this.smartFilters[1][0].field
              && this.filterClicked[1]
              && this.smartFilters[1].length > 0) {
              this.leftTabs[this.selectedTabs.leftTabIndex].selectedRows[0] = this.leftTabs[this.selectedTabs.leftTabIndex].tracks[pos - 1].id;
            }
            else {
              this.leftTabs[this.selectedTabs.leftTabIndex].selectedRows[0] = this.leftTabs[this.selectedTabs.leftTabIndex].tracks[pos - 1].pivot_id;
            }
            if (this.playerInfo.isPlaying != 'play') {
              this.playerInfo.trackIndex = pos - 1;
              this.playerInfo.trackId = this.leftTabs[this.selectedTabs.leftTabIndex].tracks[pos - 1].id;
              this.playerInfo.filename = this.leftTabs[this.selectedTabs.leftTabIndex].tracks[pos - 1].file_name;
              this.playerInfo.artist = this.leftTabs[this.selectedTabs.leftTabIndex].tracks[pos - 1].artist;
              this.playerInfo.album = this.leftTabs[this.selectedTabs.leftTabIndex].tracks[pos - 1].title + ' - ' + this.leftTabs[this.selectedTabs.leftTabIndex].tracks[pos - 1].album;
              this.playerInfo.storage = this.leftTabs[this.selectedTabs.leftTabIndex].tracks[pos - 1].storage;
              this.playerInfo.token = this.globals.access_token;
            }
          }
        }
        else if (arrow == 1) {
          if ((pos + 1) != this.leftTabs[this.selectedTabs.leftTabIndex].tracks.length) {
            this.leftTabs[this.selectedTabs.leftTabIndex].selectedRows = [];
            if (this.selectedTabs.leftTabIndex == 0)
              this.leftTabs[this.selectedTabs.leftTabIndex].selectedRows[0] = this.leftTabs[this.selectedTabs.leftTabIndex].tracks[pos + 1].id;
            else if (this.smartFilters[1][0].categorySelect
              && this.smartFilters[1][0].compareSelect
              //&& this.smartFilters[1][0].field
              && this.filterClicked[1]
              && this.smartFilters[1].length > 0) {
              this.leftTabs[this.selectedTabs.leftTabIndex].selectedRows[0] = this.leftTabs[this.selectedTabs.leftTabIndex].tracks[pos + 1].id;
            }
            else
              this.leftTabs[this.selectedTabs.leftTabIndex].selectedRows[0] = this.leftTabs[this.selectedTabs.leftTabIndex].tracks[pos + 1].pivot_id;
            if (this.playerInfo.isPlaying != 'play') {
              this.playerInfo.trackIndex = pos + 1;
              this.playerInfo.trackId = this.leftTabs[this.selectedTabs.leftTabIndex].tracks[pos + 1].id;
              this.playerInfo.filename = this.leftTabs[this.selectedTabs.leftTabIndex].tracks[pos + 1].file_name;
              this.playerInfo.artist = this.leftTabs[this.selectedTabs.leftTabIndex].tracks[pos + 1].artist;
              this.playerInfo.album = this.leftTabs[this.selectedTabs.leftTabIndex].tracks[pos + 1].album + ' - ' + this.leftTabs[this.selectedTabs.leftTabIndex].tracks[pos + 1].album;
              this.playerInfo.storage = this.leftTabs[this.selectedTabs.leftTabIndex].tracks[pos + 1].storage;
              this.playerInfo.token = this.globals.access_token;
            }
  
          }
        }
  
      }
      else if (this.tabs[this.selectedTabs.rightTabIndex].selectedRows.length > 0) {
        var pos = this.getSelectedPosition(this.tabs[this.selectedTabs.rightTabIndex].tracks, this.tabs[this.selectedTabs.rightTabIndex].selectedRows[0]);
        if (arrow == 0) {
          if (pos > 0) {
            this.tabs[this.selectedTabs.rightTabIndex].selectedRows = [];
            this.tabs[this.selectedTabs.rightTabIndex].selectedRows[0] = this.tabs[this.selectedTabs.rightTabIndex].tracks[pos - 1].pivot_id;
            if (this.playerInfo.isPlaying != 'play') {
              this.playerInfo.trackIndex = pos - 1;
              this.playerInfo.trackId = this.tabs[this.selectedTabs.rightTabIndex].tracks[pos - 1].id;
              this.playerInfo.filename = this.tabs[this.selectedTabs.rightTabIndex].tracks[pos - 1].file_name;
              this.playerInfo.artist = this.tabs[this.selectedTabs.rightTabIndex].tracks[pos - 1].artist;
              this.playerInfo.album = this.tabs[this.selectedTabs.rightTabIndex].tracks[pos - 1].title + ' - ' + this.tabs[this.selectedTabs.rightTabIndex].tracks[pos - 1].album;
              this.playerInfo.storage = this.tabs[this.selectedTabs.rightTabIndex].tracks[pos - 1].storage;
              this.playerInfo.token = this.globals.access_token;
            }
          }
        }
        else if (arrow == 1) {
          if ((pos + 1) != this.tabs[this.selectedTabs.rightTabIndex].tracks.length) {
            this.tabs[this.selectedTabs.rightTabIndex].selectedRows = [];
            this.tabs[this.selectedTabs.rightTabIndex].selectedRows[0] = this.tabs[this.selectedTabs.rightTabIndex].tracks[pos + 1].pivot_id;
            if (this.playerInfo.isPlaying != 'play') {
              this.playerInfo.trackIndex = pos + 1;
              this.playerInfo.trackId = this.tabs[this.selectedTabs.rightTabIndex].tracks[pos + 1].id;
              this.playerInfo.filename = this.tabs[this.selectedTabs.rightTabIndex].tracks[pos + 1].file_name;
              this.playerInfo.artist = this.tabs[this.selectedTabs.rightTabIndex].tracks[pos + 1].artist;
              this.playerInfo.album = this.tabs[this.selectedTabs.rightTabIndex].tracks[pos + 1].title + ' - ' + this.tabs[this.selectedTabs.rightTabIndex].tracks[pos + 1].album;
              this.playerInfo.storage = this.tabs[this.selectedTabs.rightTabIndex].tracks[pos + 1].storage;
              this.playerInfo.token = this.globals.access_token;
            }
          }
        }
  
      }
      else {
        this.leftTabs[this.selectedTabs.leftTabIndex].selectedRows[0] = this.leftTabs[this.selectedTabs.leftTabIndex].tracks[0].id;
      }
      this.scrollToElement();
    }
  
    /**
     Scroll window to element when user press up/down keys
     */
    scrollToElement() {
      var el = this.elRef.nativeElement.querySelector('.table_row.selected');
      if (el != null) {
        var parent = el.parentElement;
        if (el.offsetTop + (el.offsetHeight * 2) - parent.offsetTop > parent.scrollTop + parent.clientHeight) {
          el.parentElement.scrollTop = (el.offsetTop + (el.offsetHeight * 2)) - parent.clientHeight + 20;
        }
        if (el.offsetTop + parent.offsetTop + (el.offsetHeight * 2) < parent.scrollTop + parent.clientHeight) {
          el.parentElement.scrollTop = el.offsetTop - parent.offsetTop - parent.clientHeight + (el.offsetHeight * 2);
        }
      }
    }
  
    /**
     Get selected row index in tracks library or playlist
     * @param arr - array of tracks
     * @param id - track id or track id in playlist
     * @returns {number} - row index
     */
    getSelectedPosition(arr, id) {
      for (var i = 0; i < arr.length; i++) {
        if (this.leftTabs[this.selectedTabs.leftTabIndex].selectedRows.length > 0) {
          if (this.selectedTabs.leftTabIndex == 0) {
            if (id == arr[i].id) {
              return i;
            }
          }
          else if (this.smartFilters[1][0].categorySelect
            && this.smartFilters[1][0].compareSelect
            //&& this.smartFilters[1][0].field
            && this.filterClicked[1]
            && this.smartFilters[1].length > 0) {
            if (id == arr[i].id) {
              return i;
            }
          }
          else {
            if (id == arr[i].pivot_id) {
              return i;
            }
          }
        }
        else if (this.tabs[this.selectedTabs.rightTabIndex].selectedRows.length > 0) {
          if (id == arr[i].pivot_id) {
            return i;
          }
        }
      }
    }
  
    /**
     Checking if one of popup opened
     * @returns {boolean}
     */
    checkOpenedPopups() {
      if (this.popups.popupOpened || this.popups.editPopupOpened || this.popups.smartFilterPopupOpened || this.popups.manageTagsPopupOpened || this.popups.otherPlaylistsPopupOpened || this.popups.profileStatementOpened)
        return false;
      else
        return true;
    }
  
    /**
     Add track to playlist
     * @param tid - track id
     * @param pid - playlist id
     * @param once(boolean) - is adding single track or multiple
     * @returns {boolean}
     */
    addTrack(tid, pid, once, reload = true) {
      var id = tid;
      var fpid = null;
      if (this.selectedTabs.leftTabIndex == 1) {
        id = this.getTrackIdByPivot(tid, this.leftTabs[1].tracks);
        fpid = this.leftTabs[this.selectedTabs.leftTabIndex].playlistId;
      }
      if (this.selectedTabs.leftTabIndex == 2) {
        id = this.getTrackIdByPivot(tid, this.leftTabs[2].tracks);
        fpid = this.leftTabs[this.selectedTabs.leftTabIndex].playlistId;
      }
      if (this.selectedTabs.leftTabIndex == 3) {
        id = this.getTrackIdByPivot(tid, this.leftTabs[3].tracks);
        fpid = this.leftTabs[this.selectedTabs.leftTabIndex].playlistId;
      }
      if (this.selectedTabs.leftTabIndex == 4) {
        id = this.getTrackIdByPivot(tid, this.leftTabs[4].tracks);
        fpid = this.leftTabs[this.selectedTabs.leftTabIndex].playlistId;
      }
      var plId = this.tabs[this.selectedTabs.rightTabIndex].playlistInfo.id;
      if (this.tabs[this.selectedTabs.rightTabIndex].type == 'sfp') {
        this.fpsamplePlaylistService.addTrackToPlaylist(plId, id, fpid).subscribe((tracks: any) => {
          if (once) {
            this.addCount.counter += 1;
          }
          if (tracks.error != undefined) {
            if (once) {
              this.addCount.errors += 1;
            }
            else {
              this.alert.alertOpen(tracks.error, 1);
            }
          }
          else {
            if (reload) {
              this.updatePlaylists();
            }
          }
          if (this.addCount.count == this.addCount.counter && once && this.addCount.errors > 0) {
            this.alert.alertOpen('From the selected tracks ' + this.addCount.errors + ' of them already exists', 1);
            
          }
  
        });
      }
      else {
        this.fpService.addTrackToPlaylist(plId, id).subscribe((tracks: any) => {
          if (once) {
            this.addCount.counter += 1;
          }
          if (tracks.error != undefined) {
            if (once) {
              this.addCount.errors += 1;
            }
            else {
              this.alert.alertOpen(tracks.error, 1);
            }
          }
          else {
            if (reload) {
              this.updatePlaylists();
            }
          }
          if (this.addCount.count == this.addCount.counter && once && this.addCount.errors > 0) {
            this.alert.alertOpen('From the selected tracks ' + this.addCount.errors + ' of them already exists', 1);
          }
        });
      }
  
      return false;
    }
  
    /**
     Add multiple tracks to playlist
     * @param arr - array of selected rows
     * @param pid - playlist id
     * @returns {boolean}
     */
    addTracks(arr, pid) {
  
      if (arr.length > 0) {
        // this.isAdding = true;
        this.addCount.count = arr.length;
        this.addCount.counter = 0;
        this.addCount.errors = 0;

        const plId = this.tabs[this.selectedTabs.rightTabIndex].playlistInfo.id;
        const isSfp = this.tabs[this.selectedTabs.rightTabIndex].type === 'sfp';
        const trackIds = arr.map(tid => this.getTrackIdByTab(tid));
        const fpid = isSfp ? this.leftTabs[this.selectedTabs.leftTabIndex].playlistId : null;

        const service = isSfp ? this.fpsamplePlaylistService : this.fpService;
        this.fpsamplePlaylistService.addTracksToPlaylist(plId, trackIds, fpid).subscribe((response: any) => {
            this.addCount.counter = response.added || 0;
            this.addCount.errors = response.errors?.length || 0;
            if (this.addCount.errors > 0) {
                this.alert.alertOpen(`From the ${this.addCount.count} selected tracks, ${this.addCount.errors} of them already exists`, 1);
            } else {
                this.alert.alertOpen("Tracks added successfully", 0);
            }
            this.updatePlaylists();
            // this.isAdding = false;
        });
    } else {
        this.alert.alertOpen("No Tracks selected!", 1);
    }
    return false;

      // if (arr.length > 0) {
      //   this.addCount.count = arr.length;
      //   this.addCount.counter = 0;
      //   this.addCount.errors = 0;
      //   for (var i = 0; i < arr.length; i++) {
      //     let reload = false;
      //     if (i === (arr.length - 1)) {
      //       reload = true;
      //     }
      //     this.addTrack(arr[i], pid, true, reload);
  
      //   }
      // }
      // else {
      //   this.alert.alertOpen("No Tracks selected!", 1);
      // }
      // return false;
    }
  
    private getTrackIdByTab(tid: any): string {
      const tabIndex = this.selectedTabs.leftTabIndex;
      return tabIndex >= 1 && tabIndex <= 4 ? this.getTrackIdByPivot(tid, this.leftTabs[tabIndex].tracks) : tid;
    }

    /**
     Updates tag colors for tracks
     */
    updateTags() {
      var str = '';
      for (var i = 1; i <= this.tags.length; i++) {
        str += 'tags[' + i + ']=' + this.tags[i - 1].name + '&';
      }
      for (var i = 1; i <= this.tags.length; i++) {
        str += 'colors[' + i + ']=' + this.tags[i - 1].color;
        if (i != this.tags.length) {
          str += "&";
        }
      }
  
      this.tagService.updatePlaylistTag(str).subscribe(tags => {
        this.alert.alertOpen("Tags for playlist updated", 0);
        this.getTags();
        this.popupClose();
        this.getPlaylistTracks(this.tabs[this.selectedTabs.rightTabIndex].playlistInfo.id, this.selectedTabs.rightTabIndex);
      });
    }
  
    /**
     Get tracks for Foundation playlist
     * @param pid - playlist id
     * @param index - tab index
     */
    getFPPlaylistTracks(pid, index) {
      this.fpService.getPlaylistTracks(pid, this.tabs[index].order, this.tabs[index].orderBy).subscribe((tracks: any) => {
        if (tracks.message == undefined) {
          this.tabs[index].tracks = tracks;
          this.tabs[index].averageBpm = Math.round(this.getBpm(this.tabs[index].tracks));
        }
        else {
          this.tabs[index].tracks = [];
          this.tabs[index].averageBpm = 0;
        }
  
        for (var i = 0; i < this.tabs[index].tracks.length; i++) {
          if (this.tabs[index].tracks[i].excluded) {
            let et = this.tabs[index].tracks[i];
            et.playlist_name = this.tabs[index].playlistInfo.name;
            et.playlist_type = 'Foundation Playlist';
            this.excludedTracks.push(et);
          }
        }
        this.removeETDuplicates();
        this.getCpDuration();
  
      });
    }
  
    /**
     Update tracks for opened playlists
     */
    updatePlaylists() {
      for (var i = 0; i < this.tabs.length; i++) {
        if (this.tabs[i].type == 'sfp')
          this.getPlaylistTracks(this.tabs[i].playlistInfo.id, i);
      }
    }
  
    /**
     Change status for playlist
     * @param pid - playlist id
     * @param sid(number) - playlist status 0=Live, 1=Editing, 2=Suspended
     */
    setStatus(pid, sid) {
      this.fpsamplePlaylistService.setStatus(pid, sid);
    }
  
    /**
     Get average BPM for playlist
     * @param arr - array of tracks in playlist
     * @returns {number} - average bpm
     */
    getBpm(arr) {
      var sum = 0;
      var count: number = 0;
      arr.forEach((track: any) => {
        count++;
        if (!isNaN(parseInt(track.bpm)))
          sum += parseInt(track.bpm);
      });
      return sum / count;
    }
  
    /**
     Remove single track from playlist
     * @param id - track id in playlist
     * @returns {boolean}
     */
    removeTrack(id) {
      console.log(this.tabs[this.selectedTabs.rightTabIndex].type);
      if (this.tabs[this.selectedTabs.rightTabIndex].type == 'sfp') {
        this.fpsamplePlaylistService.deletePlaylistTrack(this.tabs[this.selectedTabs.rightTabIndex].playlistInfo.id, id).subscribe(tracks => {
          this.getPlaylistTracks(this.tabs[this.selectedTabs.rightTabIndex].playlistInfo.id, this.selectedTabs.rightTabIndex);
        });
      }
      if (this.tabs[this.selectedTabs.rightTabIndex].type == 'fp') {
        this.fpService.deletePlaylistTrack(this.tabs[this.selectedTabs.rightTabIndex].playlistInfo.id, id).subscribe(tracks => {
          this.getFPPlaylistTracks(this.tabs[this.selectedTabs.rightTabIndex].playlistInfo.id, this.selectedTabs.rightTabIndex);
        });
      }
      return false;
    }
  
    /**
     Remove multiple tracks from playlist
     * @returns {boolean}
     */
    removeTracks() {
      for (var i = 0; i < this.tabs[this.selectedTabs.rightTabIndex].selectedRows.length; i++) {
        let reload = false;
        if (i === (this.tabs[this.selectedTabs.rightTabIndex].selectedRows.length - 1)) {
          reload = true;
        }
        if (this.tabs[this.selectedTabs.rightTabIndex].type == 'sfp') {
          this.fpsamplePlaylistService.deletePlaylistTrack(this.tabs[this.selectedTabs.rightTabIndex].playlistInfo.id, this.tabs[this.selectedTabs.rightTabIndex].selectedRows[i]).subscribe(tracks => {
            if (reload)
              this.getPlaylistTracks(this.tabs[this.selectedTabs.rightTabIndex].playlistInfo.id, this.selectedTabs.rightTabIndex);
          });
        }
        if (this.tabs[this.selectedTabs.rightTabIndex].type == 'fp') {
          this.fpService.deletePlaylistTrack(this.tabs[this.selectedTabs.rightTabIndex].playlistInfo.id, this.tabs[this.selectedTabs.rightTabIndex].selectedRows[i]).subscribe(tracks => {
            if (reload)
              this.getFPPlaylistTracks(this.tabs[this.selectedTabs.rightTabIndex].playlistInfo.id, this.selectedTabs.rightTabIndex);
          });
        }
      }
      return false;
    }
  
    /**
     If track row selected in tracks library or playlist, select and play next row
     * @param e - event
     */
    nextTrack(e) {
      if (this.leftTabs[this.selectedTabs.leftTabIndex].selectedRows.length > 0) {
        if (this.leftTabs[this.selectedTabs.leftTabIndex].tracks.length > this.playerInfo.trackIndex + 1) {
          this.playerInfo.trackIndex = this.playerInfo.trackIndex + 1;
          if (this.selectedTabs.leftTabIndex == 0) {
            this.leftTabs[this.selectedTabs.leftTabIndex].selectedRows[0] = this.leftTabs[this.selectedTabs.leftTabIndex].tracks[this.playerInfo.trackIndex].id;
          }
          else {
            this.leftTabs[this.selectedTabs.leftTabIndex].selectedRows[0] = this.leftTabs[this.selectedTabs.leftTabIndex].tracks[this.playerInfo.trackIndex].pivot_id;
          }
          this.playerInfo.trackId = this.leftTabs[this.selectedTabs.leftTabIndex].tracks[this.playerInfo.trackIndex].id;
          this.playerInfo.filename = this.leftTabs[this.selectedTabs.leftTabIndex].tracks[this.playerInfo.trackIndex].file_name;
          this.playerInfo.artist = this.leftTabs[this.selectedTabs.leftTabIndex].tracks[this.playerInfo.trackIndex].artist;
          this.playerInfo.album = this.leftTabs[this.selectedTabs.leftTabIndex].tracks[this.playerInfo.trackIndex].title + ' - ' + this.leftTabs[this.selectedTabs.leftTabIndex].tracks[this.playerInfo.trackIndex].album;
          this.playerInfo.storage = this.leftTabs[this.selectedTabs.leftTabIndex].tracks[this.playerInfo.trackIndex].storage;
          this.playerInfo.token = this.globals.access_token;
        }
      }
      else if (this.tabs[this.selectedTabs.rightTabIndex].selectedRows.length > 0) {
        if (this.tabs[this.selectedTabs.rightTabIndex].tracks.length > this.playerInfo.trackIndex + 1) {
          this.playerInfo.trackIndex = this.playerInfo.trackIndex + 1;
          this.tabs[this.selectedTabs.rightTabIndex].selectedRows[0] = this.tabs[this.selectedTabs.rightTabIndex].tracks[this.playerInfo.trackIndex].pivot_id;
          this.playerInfo.trackId = this.tabs[this.selectedTabs.rightTabIndex].tracks[this.playerInfo.trackIndex].id;
          this.playerInfo.filename = this.tabs[this.selectedTabs.rightTabIndex].tracks[this.playerInfo.trackIndex].file_name;
          this.playerInfo.artist = this.tabs[this.selectedTabs.rightTabIndex].tracks[this.playerInfo.trackIndex].artist;
          this.playerInfo.album = this.tabs[this.selectedTabs.rightTabIndex].tracks[this.playerInfo.trackIndex].title + ' - ' + this.tabs[this.selectedTabs.rightTabIndex].tracks[this.playerInfo.trackIndex].album;
          this.playerInfo.storage = this.tabs[this.selectedTabs.rightTabIndex].tracks[this.playerInfo.trackIndex].storage;
          this.playerInfo.token = this.globals.access_token;
        }
      }
      if (this.playerInfo.isPlaying == 'play') {
        this.player.start(this.playerInfo.trackId, true);
      }
    }
  
    /**
     If track row selected in tracks library or playlist, select and play previous row
     * @param e
     */
    prevTrack(e) {
      if (this.playerInfo.isPlaying == 'play') {
        this.player.start(this.playerInfo.trackId, true);
      }
      if (this.leftTabs[this.selectedTabs.leftTabIndex].selectedRows.length > 0) {
        if (this.playerInfo.trackIndex > 0) {
          this.playerInfo.trackIndex = this.playerInfo.trackIndex - 1;
          if (this.selectedTabs.leftTabIndex == 0) {
            this.leftTabs[this.selectedTabs.leftTabIndex].selectedRows[0] = this.leftTabs[this.selectedTabs.leftTabIndex].tracks[this.playerInfo.trackIndex].id;
          }
          else {
            this.leftTabs[this.selectedTabs.leftTabIndex].selectedRows[0] = this.leftTabs[this.selectedTabs.leftTabIndex].tracks[this.playerInfo.trackIndex].pivot_id;
          }
          this.playerInfo.trackId = this.leftTabs[this.selectedTabs.leftTabIndex].tracks[this.playerInfo.trackIndex].id;
          this.playerInfo.filename = this.leftTabs[this.selectedTabs.leftTabIndex].tracks[this.playerInfo.trackIndex].file_name;
          this.playerInfo.artist = this.leftTabs[this.selectedTabs.leftTabIndex].tracks[this.playerInfo.trackIndex].artist;
          this.playerInfo.album = this.leftTabs[this.selectedTabs.leftTabIndex].tracks[this.playerInfo.trackIndex].title + ' - ' + this.leftTabs[this.selectedTabs.leftTabIndex].tracks[this.playerInfo.trackIndex].album;
          this.playerInfo.storage = this.leftTabs[this.selectedTabs.leftTabIndex].tracks[this.playerInfo.trackIndex].storage;
          this.playerInfo.token = this.globals.access_token;
        }
      }
      else if (this.tabs[this.selectedTabs.rightTabIndex].selectedRows.length > 0) {
        if (this.playerInfo.trackIndex > 0) {
          this.playerInfo.trackIndex = this.playerInfo.trackIndex - 1;
          this.tabs[this.selectedTabs.rightTabIndex].selectedRows[0] = this.tabs[this.selectedTabs.rightTabIndex].tracks[this.playerInfo.trackIndex].pivot_id;
          this.playerInfo.trackId = this.tabs[this.selectedTabs.rightTabIndex].tracks[this.playerInfo.trackIndex].id;
          this.playerInfo.filename = this.tabs[this.selectedTabs.rightTabIndex].tracks[this.playerInfo.trackIndex].file_name;
          this.playerInfo.artist = this.tabs[this.selectedTabs.rightTabIndex].tracks[this.playerInfo.trackIndex].artist;
          this.playerInfo.album = this.tabs[this.selectedTabs.rightTabIndex].tracks[this.playerInfo.trackIndex].title + ' - ' + this.tabs[this.selectedTabs.rightTabIndex].tracks[this.playerInfo.trackIndex].album;
          this.playerInfo.storage = this.tabs[this.selectedTabs.rightTabIndex].tracks[this.playerInfo.trackIndex].storage;
          this.playerInfo.token = this.globals.access_token;
        }
      }
    }
    /*showInfo(e,id){
      e.preventDefault();
  
      //this.trackService.getTrackInfo(id).subscribe(trackInfo=>{
        //this.trackInfo = trackInfo;
        //this.additional = [];
        //for(var i= 0;i < this.trackInfo.additional_attributes.length;i++){
          //this.additional.push(this.trackInfo.additional_attributes[i].name);
        //}
        //this.popups.popupOpened =! this.popups.popupOpened;
      //});
      this.trackService.playlistTrackInfo(this.tabs[this.selectedTabs.rightTabIndex].playlistInfo.id, id).subscribe(trackInfo => {
        this.trackInfo = trackInfo;
        this.additional = [];
        for(var i= 0;i < this.trackInfo.additional_attributes.length;i++){
          this.additional.push(this.trackInfo.additional_attributes[i].name);
        }
        this.popups.popupOpened =! this.popups.popupOpened;
      });
  
    }*/
    /**
     Get track information and show popup for track info editing
     * @param id - track id
     * @param pivot_id - track id in playlist
     * @param selection(number) - selected side 0=left tabs, 1=right side
     * @returns {boolean}
     */
    showEdit(id, pivot_id, selection) {
      this.resetDisabledInputs();
      this.trackService.playlistTrackInfo(this.tabs[this.selectedTabs.rightTabIndex].playlistInfo.id, id).subscribe(trackInfo => {
        this.trackInfo = trackInfo;
  
  
  
        this.additional = [];
        this.selectedMCTags = [];
        this.leftTabs[this.selectedTabs.leftTabIndex].selectedRows = [];
        this.tabs[this.selectedTabs.rightTabIndex].selectedRows = [];
        for (var i = 0; i < this.trackInfo.additional_attributes.length; i++) {
          this.additional.push(this.trackInfo.additional_attributes[i].name);
          this.selectedMCTags.push({
            item_id: this.trackInfo.additional_attributes[i].name,
            item_text: this.trackInfo.additional_attributes[i].name
          });
        }
        if (selection == 0) {
          if (pivot_id != undefined && pivot_id != null) {
            this.leftTabs[this.selectedTabs.leftTabIndex].selectedRows[0] = pivot_id;
            if (this.playerInfo.isPlaying != 'play')
              this.addPlayerInfo(selection, pivot_id);
          }
          else {
            this.leftTabs[this.selectedTabs.leftTabIndex].selectedRows[0] = id;
            if (this.playerInfo.isPlaying != 'play')
              this.addPlayerInfo(selection, id);
          }
        }
        else if (selection == 1) {
          this.tabs[this.selectedTabs.rightTabIndex].selectedRows[0] = pivot_id;
          if (this.playerInfo.isPlaying != 'play')
            this.addPlayerInfo(selection, pivot_id);
        }
  
        this.popups.editPopupOpened = true;
      });
      return false;
    }
  
    /**
     Add track info to player
     * @param selection(number) - selected side 0=left tabs, 1=right side
     * @param id - track id in library or in playlist
     */
    addPlayerInfo(selection, id) {
      var pos;
      var t;
      if (selection == 0) {
        pos = this.getSelectedPosition(this.leftTabs[this.selectedTabs.leftTabIndex].tracks, id);
        t = this.leftTabs[this.selectedTabs.leftTabIndex].tracks[pos];
      }
      else if (selection == 1) {
        pos = this.getSelectedPosition(this.tabs[this.selectedTabs.rightTabIndex].tracks, id);
        t = this.tabs[this.selectedTabs.rightTabIndex].tracks[pos];
      }
      this.playerInfo.trackIndex = pos;
      this.playerInfo.trackId = t.id;
      this.playerInfo.filename = t.file_name;
      this.playerInfo.album = t.title + ' - ' + t.album;
      this.playerInfo.artist = t.artist;
      this.playerInfo.storage = t.storage;
      this.playerInfo.token = this.globals.access_token;
    }
  
    /**
     Select row after click on it in left side
     * @param e - event
     * @param tid - track id
     * @param pivot_id - track id in playlist
     * @param ind - tab index
     * @param rIndex - row index
     */
    selectRow(e, tid, pivot_id, ind, rIndex) {
      var id = pivot_id;
      if (pivot_id == undefined || pivot_id == null) {
        id = tid;
      }
      var arrClass = new Arrays();
      this.deselectPlaylistsRows(null);
      if (e.ctrlKey || e.metaKey) {
        if (arrClass.checkElementInArray(this.leftTabs[ind].selectedRows, id)) {
          var selected = arrClass.getElementPositionInArray(this.leftTabs[ind].selectedRows, id);
          this.leftTabs[ind].selectedRows.splice(selected, 1);
        }
        else {
          this.leftTabs[ind].selectedRows.push(id);
        }
      }
      else if (e.shiftKey) {
        let a = []; // shift key work 
        if (this.leftTabs[ind].selectedRows.length > 0)
          a = this.checkStartRows(ind, rIndex);
        else
          this.leftTabs[ind].selectedRows[0] = id;
  
        // shift key work - START
        var firstIndex = -1;
        for (var i = 0; i < this.leftTabs[ind].tracks.length; i++) {
          // shift key work
          if (ind == 0 ) {
            if (this.leftTabs[ind].tracks[i].id == this.leftTabs[ind].selectedRows[this.leftTabs[ind].selectedRows.length - 1]) {
              if (i < firstIndex || firstIndex == -1) {
                firstIndex = i;
              }
            }
          }
          if (ind >= 1 && ind <=4 ) {
            if (this.leftTabs[ind].tracks[i].pivot_id == this.leftTabs[ind].selectedRows[this.leftTabs[ind].selectedRows.length - 1]) {
              if (i < firstIndex || firstIndex == -1) {
                firstIndex = i;
              }
            }
          }
       
        }
      
        for (let k = 0; k < a.length; k++) {
          const element = a[k];
          if (firstIndex >= rIndex) {
            if (arrClass.checkElementInArray(this.leftTabs[ind].selectedRows, element)) {
              var selected = arrClass.getElementPositionInArray(this.leftTabs[ind].selectedRows, element);
              this.leftTabs[ind].selectedRows.splice(selected, 1);
            }
          }
          else {
            if (!arrClass.checkElementInArray(this.leftTabs[ind].selectedRows, element)) {
              this.leftTabs[ind].selectedRows.push(element);
            }
          }
        }
        // shift key work - END
      }
      else {
        if (arrClass.checkElementInArray(this.leftTabs[ind].selectedRows, id)) {
          this.leftTabs[ind].selectedRows = [];
        }
        else {
          this.leftTabs[ind].selectedRows = [];
          this.leftTabs[ind].selectedRows[0] = id;
          if (this.playerInfo.isPlaying != 'play') {
            var index = this.getSelectedPosition(this.leftTabs[ind].tracks, id);
  
            this.playerInfo.trackIndex = index;
            this.playerInfo.trackId = tid;
            this.playerInfo.filename = this.leftTabs[this.selectedTabs.leftTabIndex].tracks[index].file_name;
            this.playerInfo.album = this.leftTabs[this.selectedTabs.leftTabIndex].tracks[index].title + ' - ' + this.leftTabs[this.selectedTabs.leftTabIndex].tracks[index].album;
            this.playerInfo.artist = this.leftTabs[this.selectedTabs.leftTabIndex].tracks[index].artist;
            this.playerInfo.storage = this.leftTabs[this.selectedTabs.leftTabIndex].tracks[index].storage;
            this.playerInfo.token = this.globals.access_token;
          }
        }
      }
      this.getDuration();
    }
  
    /**
     Deselect rows in right side
     * @param index - tab index
     */
    deselectPlaylistsRows(index) {
      for (var i = 0; i < this.tabs.length; i++) {
        if (i != index)
          this.tabs[i].selectedRows = [];
      }
    }
  
    /**
     Select row after click on it in right side
     * @param e - event
     * @param pivot_id - track id in playlist
     * @param index - tab index
     * @param rIndex - row index
     */
    selectPlaylistRow(e, pivot_id, index, rIndex) {
  
      this.leftTabs[0].selectedRows = [];
      this.leftTabs[1].selectedRows = [];
      var arrClass = new Arrays();
      this.deselectPlaylistsRows(this.selectedTabs.rightTabIndex);
      if (e.ctrlKey || e.metaKey) {
        if (arrClass.checkElementInArray(this.tabs[index].selectedRows, pivot_id)) {
          var selected = arrClass.getElementPositionInArray(this.tabs[index].selectedRows, pivot_id);
          this.tabs[index].selectedRows.splice(selected, 1);
        }
        else {
          this.tabs[index].selectedRows.push(pivot_id);
        }
      }
      else if (e.shiftKey) {
        console.log('shift Key');
        // shift key work
        // if( this.tabs[index].selectedRows.length > 0 )
        //   this.tabs[index].selectedRows = this.checkStartPlaylistRows(rIndex);
        // else
        //   this.tabs[index].selectedRows[0] = pivot_id;
  
        let a = []; // shift key work 
        if (this.tabs[index].selectedRows.length > 0)
          a = this.checkStartPlaylistRows(rIndex);
        else
          this.tabs[index].selectedRows[0] = pivot_id;
  
        // shift key work - START
        var firstIndex = -1;
        for (var i = 0; i < this.tabs[index].tracks.length; i++) {
          // shift key work
          if (this.tabs[index].tracks[i].pivot_id == this.tabs[index].selectedRows[this.tabs[index].selectedRows.length - 1]) {
            if (i < firstIndex || firstIndex == -1) {
              firstIndex = i;
            }
  
          }
        }
        for (let k = 0; k < a.length; k++) {
          const element = a[k];
          if (firstIndex >= rIndex) {
            if (arrClass.checkElementInArray(this.tabs[index].selectedRows, element)) {
              var selected = arrClass.getElementPositionInArray(this.tabs[index].selectedRows, element);
              this.tabs[index].selectedRows.splice(selected, 1);
            }
          }
          else {
            console.log('element', element)
            if (!arrClass.checkElementInArray(this.tabs[index].selectedRows, element)) {
              console.log('element', element)
              this.tabs[index].selectedRows.push(element);
            }
          }
        }
        // shift key work - END
      }
      else {
       
        if (arrClass.checkElementInArray(this.tabs[index].selectedRows, pivot_id)) {
          this.tabs[index].selectedRows = [];
        }
        else {
          this.tabs[index].selectedRows = [];
          this.tabs[index].selectedRows[0] = pivot_id;
          if (this.playerInfo.isPlaying != 'play') {
            this.playerInfo.trackIndex = rIndex;
            this.playerInfo.trackId = this.tabs[this.selectedTabs.rightTabIndex].tracks[rIndex].id;
            this.playerInfo.filename = this.tabs[this.selectedTabs.rightTabIndex].tracks[rIndex].file_name;
            this.playerInfo.album = this.tabs[this.selectedTabs.rightTabIndex].tracks[rIndex].title + ' - ' + this.tabs[this.selectedTabs.rightTabIndex].tracks[rIndex].album;
            this.playerInfo.artist = this.tabs[this.selectedTabs.rightTabIndex].tracks[rIndex].artist;
            this.playerInfo.storage = this.tabs[this.selectedTabs.rightTabIndex].tracks[rIndex].storage;
            this.playerInfo.token = this.globals.access_token;
          }
        }
      }
    
    }
  
    /**
     Check start and end of selected rows in playlist
     * @param index - row index
     * @returns {Array | any[]}
     */
    checkStartPlaylistRows(index) {
      var first = this.getFirstRow(this.tabs[this.selectedTabs.rightTabIndex].selectedRows, this.tabs[this.selectedTabs.rightTabIndex].tracks);
      var arr = this.tabs[this.selectedTabs.rightTabIndex].selectedRows;
      if (first != -1) {
        var s = first;
        var e = index;
        if (first > index) {
          s = index;
          e = first;
        }
        s = s + 1;
        //if(this.selectedTabs.rightTabIndex == 0){
        arr = this.setMultipleRows(s, e, this.tabs[this.selectedTabs.rightTabIndex].tracks);
        //}
      }
      return arr;
    }
  
  
    /**
     Get first index from selected rows in tracks library
     * @param arr - array of selected rows
     * @param tracks - array of tracks
     * @returns {number} - row index
     */
    getFirstRowLibrary(arr, tracks) {
      var index = -1;
      for (var i = 0; i < tracks.length; i++) {
        // shift key work
        if (tracks[i].id == arr[arr.length - 1]) {
          if (i < index || index == -1)
            index = i;
        }
        // shift key work
        // for( var j = 0; j < arr.length; j++ ){
        //   if(tracks[i].id == arr[j]){
        //     if(i < index || index == -1)
        //       index = i;
        //   }
        // }
      }
      return index;
    }
  
    /**
     Get first index from selected rows in playlist
     * @param arr - array of selected rows
     * @param tracks - array of tracks
     * @returns {number} - row index
     */
    getFirstRow(arr, tracks) {
      var index = -1;
      for (var i = 0; i < tracks.length; i++) {
        // shift key work
        if (this.smartFilters[1][0].categorySelect
          && this.smartFilters[1][0].compareSelect
          //&& this.smartFilters[1][0].field
          && this.filterClicked[1]
          && this.smartFilters[1].length > 0) {
          if (tracks[i].id == arr[arr.length - 1]) {
            if (i < index || index == -1)
              index = i;
          }
        }
        else {
          if (tracks[i].pivot_id == arr[arr.length - 1]) {
            if (i < index || index == -1)
              index = i;
          }
        }
  
        // shift key work - hide
        // for( var j = 0; j < arr.length; j++ ){
        //   if(this.smartFilters[1][0].categorySelect 
        //     && this.smartFilters[1][0].compareSelect
        //     //&& this.smartFilters[1][0].field
        //     && this.filterClicked[1]
        //     && this.smartFilters[1].length > 0){
        //       if(tracks[i].id == arr[j]){
        //         if(i < index || index == -1)
        //           index = i;
        //       }
        //   }
        //   else{
        //     if(tracks[i].pivot_id == arr[j]){
        //       if(i < index || index == -1)
        //         index = i;
        //     }
        //   }
        // }
      }
      return index;
    }
    /**
     Check start and end of selected rows in tracks library
     * @param index - row index
     * @returns {Array | any[]}
     */
    checkStartRows(ind, index) {
      var first = 0;
      if (this.selectedTabs.leftTabIndex == 0) {
        first = this.getFirstRowLibrary(this.leftTabs[ind].selectedRows, this.leftTabs[ind].tracks);
      }
      else if (this.selectedTabs.leftTabIndex >= 1 && this.selectedTabs.leftTabIndex <= 4) {
        first = this.getFirstRow(this.leftTabs[ind].selectedRows, this.leftTabs[ind].tracks);
      }
      var arr = this.leftTabs[ind].selectedRows;
      if (first != -1) {
        var s = first;
        var e = index;
        if (first > index) {
          s = index;
          e = first;
        }
        s = s + 1;
        if (this.selectedTabs.leftTabIndex == 0) {
          arr = this.setMultipleRowsLibrary(s, e, this.leftTabs[ind].tracks);
        }
        else if (this.selectedTabs.leftTabIndex >=1 && this.selectedTabs.leftTabIndex <= 4) {
          arr = this.setMultipleRows(s, e, this.leftTabs[ind].tracks);
        }
      }
      return arr;
    }
  
    /**
     Select rows from start index to end index in playlist
     * @param start - start index
     * @param end - end index
     * @param tracks - array of tracks
     * @returns {any[]}
     */
    setMultipleRowsLibrary(start, end, tracks) {
      var arr = [];
      for (var i = start; i <= end; i++) {
        arr.push(tracks[i].id);
      }
      return arr;
    }
    /**
     Select rows from start index to end index in tracks library
     * @param start - start index
     * @param end - end index
     * @param tracks - array of tracks
     * @returns {any[]}
     */
    setMultipleRows(start, end, tracks) {
      var arr = [];
      for (var i = start; i <= end; i++) {
        if (this.smartFilters[1][0].categorySelect
          && this.smartFilters[1][0].compareSelect
          //&& this.smartFilters[1][0].field
          && this.filterClicked[1]
          && this.smartFilters[1].length > 0) {
          arr.push(tracks[i].id);
        }
        else
          arr.push(tracks[i].pivot_id);
      }
      return arr;
    }
  
    /**
     Check selected row
     * @param arr - array of tracks
     * @param id - track id
     * @param pivot - track id in playlist
     * @returns {boolean}
     */
    checkSelectedRow(arr, id, pivot) {
      var aid = pivot;
      if (pivot == null || pivot == undefined) {
        aid = id;
      }
      var arrClass = new Arrays();
      return arrClass.checkElementInArray(arr, aid);
    }
  
    /**
     Open popup with smart filters
     */
    openFilterPopup() {
      console.log(this.selectedTabs);
      if (this.selectedTabs.leftTabIndex == 0) {
        this.selectedTabname="TL";
        this.smartFilters[0] = JSON.parse(localStorage.getItem('smartFiltersTL'));
      }
      if (this.selectedTabs.leftTabIndex == 1) {
        this.selectedTabname="FP";
        this.smartFilters[1] = JSON.parse(localStorage.getItem('smartFiltersFP'));
      }
      if (this.selectedTabs.leftTabIndex == 2) {
        this.selectedTabname="SFP";
        this.smartFilters[2] = JSON.parse(localStorage.getItem('smartFiltersSFP'));
      }
      if (this.selectedTabs.leftTabIndex == 3) {
        this.selectedTabname="SCP";
        this.smartFilters[3] = JSON.parse(localStorage.getItem('smartFiltersSCP'));
      }
      if (this.selectedTabs.leftTabIndex == 4) {
        this.selectedTabname="CP";
        this.smartFilters[4] = JSON.parse(localStorage.getItem('smartFiltersCP'));
      }
    
      if (this.smartFilters[this.selectedTabs.leftTabIndex] == '' || this.smartFilters[this.selectedTabs.leftTabIndex] == null)
        this.smartFilters[this.selectedTabs.leftTabIndex] = [{
          categorySelect: null,
          compare: [],
          compareSelect: null,
          field: null,
          arrFields: ['', ''],
          type: 'str',
          isHidden: false
        }];
  
        console.log(this.smartFilters[this.selectedTabs.leftTabIndex]);
  
      this.focusInput();
      this.popups.smartFilterPopupOpened = !this.popups.smartFilterPopupOpened;
    }
  
    /**
     Open popup with tags
     */
    openTagsPopup() {
      this.popups.manageTagsPopupOpened = !this.popups.manageTagsPopupOpened;
      this.focusInput();
      this.getTags();
    }
  
    /**
     Get tags info
     */
    getTags() {
      this.tagService.getTags().subscribe((tags: any) => {
  
        var ti = 0;
        for (var i = 0; i < 10; i++) {
          var tag = {
            'name': '',
            'color': 'undefined',
            'bgColor': '#ffffff',
            'info': null
          };
          if (tags[ti] != undefined && tags[ti] != 'undefin' && parseInt(tags[ti].position) == i + 1 && tags.error == undefined) {
            tag.name = tags[ti].name;
            tag.color = tags[ti].color;
            tag.bgColor = tags[ti].color;
            tag.info = tags[ti];
          }
          ti++;
          this.tags[i] = tag;
        }
      });
    }
  
    /**
     Close all popups
     * @returns {boolean}
     */
    popupClose() {
      this.trackInfo = null;
      this.popups.otherPlaylistsPopupOpened = false;
      this.popups.popupOpened = false;
      this.popups.editPopupOpened = false;
      this.popups.smartFilterPopupOpened = false;
      this.popups.manageTagsPopupOpened = false;
      this.popups.playlistPopupOpened = false;
      this.popups.restoredPlaylistPopupOpened = false;
      this.popups.profileStatementOpened = false;
      this.popups.profileHistoryopened = false;
      this.popups.profileCreatePopupOpened = false;
      this.drops.fp = false;
      this.drops.clients = false;
      this.isSmartSearch = false;
      this.blurInput();
      return false;
    }
  
    /**
     Close popup for convertation tracks in playlist
     * @returns {boolean}
     */
    popupConvertClose() {
      this.popups.convertPlaylistPopupOpened = false;
      this.popups.smartFilterPopupOpened = true;
      this.convert = false;
      return false;
    }
  
    /**
     Open popup for creating playlist and set parameter for convert
     * @param e - event
     * @returns {boolean}
     */
    convertToPlaylist(e) {
      this.convert = true;
      this.openCreatePlaylistPopup();
      return false;
    }
  
    /**
     Go to previous page
     * @returns {boolean}
     */
    backBtnClick() {
      console.log(this.tabs[0].playlistInfo);
      let navigationExtras = {
        queryParams: {
          'cat': this.tabs[0].playlistInfo.fp_sample_subcategory_id,
          'uid': this.tabs[0].playlistInfo.fp_sample_category_id,
          // 'pcat': this.tabs[0].playerInfo.sample_channel_id
        }
      };

      this.router.navigate([this.link], navigationExtras);
      return false;
    }
  
    /**
     Get parent site
     * @param catid - channel id
     * @param tabindex - tab index
     */
    getCategoryParent(catid, index) {
      if (this.tabs[index].type == 'cp') {
        this.chanelService.getPlaylistCategoriesByID(catid).subscribe((chanel: any) => {
          this.tabs[index].category_parent = chanel.parent_id;
        });
      }
    }
    /**
     Get parent category
     * @param catid - sub category id
     * @param tabindex - tab index
     */
    getFPCategoryParent(catid, tabindex) {
      this.fp_categoriesService.getPlaylistCategoriesByID(catid).subscribe((chanel: any) => {
        this.tabs[tabindex].tab_str += ' -> ' + chanel.name + ' -> ' + this.tabs[tabindex].playlistInfo.name;
        this.tabs[tabindex].category_parent = chanel.parent_id;
      });
    }
  
    /**
     Add new additional attribute
     */
    newAttribute() {
      var input = (<HTMLInputElement>document.getElementById('additional'));
      if (input.value.trim() != '')
        this.additional.push(input.value);
      input.value = '';
    }
  
    /**
     Remove additional attribute
     * @param id - attribute id
     */
    removeAdditional(id) {
      this.additional.splice(id, 1);
    }
  
    /**
     Update track information
     * @param e - event
     * @param {NgForm} form - form object
     */
    trackDataUpdate(form: NgForm) {
      form.value['title'] = form.controls['title'].value;
      form.value['album'] = form.controls['album'].value;
      form.value['artist'] = form.controls['artist'].value;
      form.value['year'] = form.controls['year'].value;
  
      if (this.trackInfo.data.title == '' || this.trackInfo.data.title == null) {
        this.alert.alertOpen("Please enter track title", 1);
      }
      else if (form.value.artist == '' || form.value.artist == null) {
        this.alert.alertOpen("Please enter artist name", 1);
      }
      else {
        var str = Object.keys(form.value).map(function (key) {
          var val = '';
          if (key == 'mc_tags') {
            val = form.value[key].map(a => encodeURIComponent(a.item_id));
          }
          else {
            val = encodeURIComponent(form.value[key]);
          }
          if (val == 'null' || val == null) {
            val = '';
          }
          return encodeURIComponent(key) + '=' + val;
        }).join('&');
        this.trackService.updateTrackInfo(str).subscribe(dataInfo => {
          if (this.searchStr == '') {
            var limit = this.infiniteScroll.offsetItems + this.infiniteScroll.addItems;
            this.trackService.getTracks(this.leftTabs[this.selectedTabs.leftTabIndex].order, this.leftTabs[this.selectedTabs.leftTabIndex].orderBy, 0, limit).subscribe((tracks: any) => {
              this.leftTabs[0].tracks = tracks.list;
              this.tracksCount[this.selectedTabs.leftTabIndex].total = tracks.total;
              this.tracksCount[this.selectedTabs.leftTabIndex].display = tracks.total;
            });
          }
          else {
            this.search();
          }
          this.updatePlaylists();
          this.alert.alertOpen("Info updated", 0);
        });
      }
    }
  
    /**
     Delete track from library
     * @param id - track id
     */
    deleteTrack(id) {
      this.trackService.deleteTrack(id).subscribe(trackDel => {
        this.trackService.getTracks(this.leftTabs[this.selectedTabs.leftTabIndex].order, this.leftTabs[this.selectedTabs.leftTabIndex].orderBy, 0, 1000).subscribe((tracks: any) => {
          this.leftTabs[this.selectedTabs.leftTabIndex].tracks = tracks.list;
          this.tracksCount[this.selectedTabs.leftTabIndex].total = tracks.total;
          this.tracksCount[this.selectedTabs.leftTabIndex].display = tracks.total;
        });
        this.updatePlaylists();
        this.alert.alertOpen("Track deleted", 0);
        this.popupClose();
      });
    }
  
    /**
     Checking entering query in search field
     */
    searchPress() {
      clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(() => {
        this.isLoad = true;
        this.isSmartSearch = false;
        this.infiniteScroll.offsetItems = 0;
  
        if (this.searchStr != '')
          this.search();
        else
          this.clearSearch();
      }, 1000);
    }
  
    /**
     Get search results
     */
    search() {
      console.log(this.leftTabs);
      var offset = this.infiniteScroll.offsetItems;
      var limit = this.infiniteScroll.addItems;
      if (this.searchStr != '') {
        if(this.leftTabs[0].selected){
        this.trackService.search(this.searchStr, this.leftTabs[0].order, this.leftTabs[0].orderBy, offset, limit).subscribe((tracks: any) => {
          if (tracks.result == undefined && tracks.result != 'No result') {
            if (offset == 0)
              this.leftTabs[0].tracks = tracks.list;
            else
              this.leftTabs[0].tracks = this.leftTabs[0].tracks.concat(tracks.list);
            this.tracksCount[0].display = tracks.total;
          }
          else {
            if (offset == 0) {
              this.leftTabs[0].tracks = [];
              this.tracksCount[0].display = 0;
            }
          }
          this.isLoad = false;
        });
      }
        else if(this.leftTabs[1].selected){
          this.fpService.search(this.searchStr, this.leftTabs[1].playlistId).subscribe((tracks: any) => {
  
            if (tracks.result == undefined && tracks.result != 'No result') {
              this.leftTabs[1].tracks = tracks;
              this.tracksCount[1].display = tracks.length;
            }
            else {
              this.leftTabs[1].tracks = [];
              this.tracksCount[1].display = 0;
            }
            this.isLoad = false;
          });
        }
        else if(this.leftTabs[2].selected){
          this.fpsamplePlaylistService.search(this.searchStr, this.leftTabs[3].playlistId).subscribe((tracks: any) => {
  
            if (tracks.result == undefined && tracks.result != 'No result') {
              this.leftTabs[2].tracks = tracks;
              this.tracksCount[2].display = tracks.length;
            }
            else {
              this.leftTabs[2].tracks = [];
              this.tracksCount[2].display = 0;
            }
            this.isLoad = false;
          });
         
        }else if(this.leftTabs[3].selected){
          this.clientSamplePlaylist.searchbar(this.searchStr, this.leftTabs[3].playlistId).subscribe((tracks: any) => {
  
            if (tracks.result == undefined && tracks.result != 'No result') {
              this.leftTabs[3].tracks = tracks;
              this.tracksCount[3].display = tracks.length;
            }
            else {
              this.leftTabs[3].tracks = [];
              this.tracksCount[3].display = 0;
            }
            this.isLoad = false;
          });
        }else if(this.leftTabs[4].selected){
          this.cpService.search(this.searchStr, this.leftTabs[4].playlistId).subscribe((tracks: any) => {
  
            if (tracks.result == undefined && tracks.result != 'No result') {
              this.leftTabs[4].tracks = tracks;
              this.tracksCount[4].display = tracks.length;
            }
            else {
              this.leftTabs[4].tracks = [];
              this.tracksCount[4].display = 0;
            }
            this.isLoad = false;
          });
          
        }
  
        for (var i = 0; i < this.tabs.length; i++) {
          let a = i;
          this.clientSamplePlaylist.searchbar(this.searchStr, this.tabs[i].playlistInfo.id).subscribe((tracks: any) => {
            if (tracks.result == undefined && tracks.result != 'No result') {
              this.tabs[a].tracks = tracks;
            }
            else {
              this.tabs[a].tracks = [];
            }
            this.isLoad = false;
          });
        }
      }
      else {
        this.clearSearch();
      }
  
    }
  
    /**
     Crear search results and get all tracks in playlist and tracks library
     * @returns {boolean}
     */
    clearSearch() {
      this.searchStr = '';
      this.isSmartSearch = false;
      this.isLoad = true;
      this.trackService.getTracks(this.leftTabs[0].order, this.leftTabs[0].orderBy, 0, 1000).subscribe((tracks: any) => {
        this.leftTabs[0].tracks = tracks.list;
        this.tracksCount[0].total = tracks.total;
        this.tracksCount[0].display = tracks.total;
        this.isLoad = false;
      });
      this.selectFpPlaylist(2, this.leftTabs[1].playlistId, this.leftTabs[1].title);
      for (var i = 0; i < this.tabs.length; i++) {
        this.tabs[i].playlistInfo.updated_at = 1;
      }
      this.updateTabs();
      this.getDuration();
      return false;
    }
  
    /**
     Clear information for tag
     * @param id - tag id(index)
     */
    clearTags(id) {
  
      var tag = {
        'name': '',
        'color': 'undefined',
        'bgColor': '#ffffff',
        'info': null
      };
      this.tags[id] = tag;
    }
  
    /**
     Checking if tag color changed
     * @param id
     */
    changeBg(id) {
      if (this.tags[id].color != undefined) {
        this.tags[id].bgColor = this.tags[id].color;
      }
    }
  
    /**
     Set background for row from tags
     * @param arr - array of tags for track
     * @returns {any}
     */
    setBgFromTag(arr) {
      var myStyles: any;
      if (arr != undefined && arr.length > 0) {
        myStyles = {
          'background-color': arr[arr.length - 1].color
        }
      }
      return myStyles;
    }
  
    /**
     Checking for changing select with smart filters
     * @param e - event
     * @param index - smart filter index
     */
    changeSmartSelect(e, index) {
      for (var i = 0; i < this.defaultSmartFilters[this.selectedTabs.leftTabIndex].length; i++) {
        if (this.defaultSmartFilters[this.selectedTabs.leftTabIndex][i].field == e.target.value) {
          this.smartFilters[this.selectedTabs.leftTabIndex][index].compare = this.defaultSmartFilters[this.selectedTabs.leftTabIndex][i].сompare_str;
          this.smartFilters[this.selectedTabs.leftTabIndex][index].type = this.defaultSmartFilters[this.selectedTabs.leftTabIndex][i].type;
          /* Smart filter work - Start
          //console.log('e.target.value',e.target.value);
          // if(e.target.value == 'cp_playlist_id')
          //   this.addStaticFilterField('add_to_cp_playlist', index );
          // else
          //   this.removeStaticFilterField(index);
          Smart filter work - End */
        }
      }
      this.profileFilterCount = this.smartFilters[this.selectedTabs.leftTabIndex].filter(
        (obj) => obj.categorySelect === 'cp_playlist_id').length;
    }
  
    changeSmartSelectPS(e, i, k) {
      for (var m = 0; m < this.defaultSmartFiltersPS.length; m++) {
        if (this.defaultSmartFiltersPS[m].field == e.target.value) {
          this.profileStatements[i].smartFiltersPS[k].compare = this.defaultSmartFiltersPS[m].сompare_str;
          this.profileStatements[i].smartFiltersPS[k].type = this.defaultSmartFiltersPS[m].type;
          /* Smart filter work - Start
          //console.log('e.target.value',e.target.value);
          // if(e.target.value == 'cp_playlist_id')
          //   this.addStaticFilterField('add_to_cp_playlist', index );
          // else
          //   this.removeStaticFilterField(index);
          Smart filter work - End */
        }
      }
    }
  
    /**
     Add static smart filter field
     * @param name - smart filter title
     * @param index - smart filter index
     */
    addStaticFilterField(name, index) {
      for (var i = 0; i < this.defaultSmartFilters[this.selectedTabs.leftTabIndex].length; i++) {
        if (this.defaultSmartFilters[this.selectedTabs.leftTabIndex][i].field == name) {
          var o = {
            categorySelect: name,
            compare: this.defaultSmartFilters[this.selectedTabs.leftTabIndex][i].сompare_str,
            compareSelect: null,
            field: null,
            arrFields: ['', ''],
            type: this.defaultSmartFilters[this.selectedTabs.leftTabIndex][i].type,
            isHidden: true
          };
          this.smartFilters[this.selectedTabs.leftTabIndex].splice(index + 1, 0, o);
          break;
        }
      }
    }
  
    /**
     Add new smart filter field
     * @returns {boolean}
     */
    addFilterField() {
      var o = {
        categorySelect: null,
        compare: [],
        compareSelect: null,
        field: null,
        arrFields: ['', ''],
        type: 'str',
        isHidden: false
      };
      this.smartFilters[this.selectedTabs.leftTabIndex].push(o);
      return false;
    }
  
    addFilterFieldPS(i) {
      var o = {
        categorySelect: null,
        compare: [],
        compareSelect: null,
        field: null,
        arrFields: ['', ''],
        type: 'str',
        isHidden: false
      };
      this.profileStatements[i].smartFiltersPS.push(o);
      console.log('this.profileStatements', this.profileStatements);
      return false;
    }
    /**
     Remove static smart filter field
     * @param index - smart filter index
     */
    removeStaticFilterField(index) {
      if (this.smartFilters[this.selectedTabs.leftTabIndex][index + 1] && this.smartFilters[this.selectedTabs.leftTabIndex][index + 1].categorySelect == 'add_to_cp_playlist' && this.selectedTabs.leftTabIndex == 0)
        this.smartFilters[this.selectedTabs.leftTabIndex].splice(index + 1, 1);
    }
  
    /**
     Remove smart filter field
     * @param index - smart filter index
     * @returns {boolean}
     */
    removeFilterField(index) {
  
      var count = 1;
      var ind = index;
      /* Smart filter work - Start
      // if( this.smartFilters[this.selectedTabs.leftTabIndex][index].categorySelect == 'cp_playlist_id' || this.smartFilters[this.selectedTabs.leftTabIndex][index].categorySelect == 'add_to_cp_playlist' && ( this.selectedTabs.leftTabIndex == 0 ) )
      //   count = 2;
      // if(this.smartFilters[this.selectedTabs.leftTabIndex][index].categorySelect == 'add_to_cp_playlist' && ( this.selectedTabs.leftTabIndex == 0 ) )
      //   ind = index - 1;
      Smart filter work - End */
  
  
  
      this.smartFilters[this.selectedTabs.leftTabIndex].splice(ind, count);
      if (this.smartFilters[this.selectedTabs.leftTabIndex].length < 1) {
        var o = {
          categorySelect: null,
          compare: [],
          compareSelect: null,
          field: null,
          arrFields: ['', ''],
          type: 'str',
          isHidden: false
        };
        this.smartFilters[this.selectedTabs.leftTabIndex][0] = o;
      }
      this.profileFilterCount = this.smartFilters[this.selectedTabs.leftTabIndex].filter(
        (obj) => obj.categorySelect === 'cp_playlist_id').length;
  
      return false;
    }
  
    removeFilterFieldPS(i, k) {
      var count = 1;
      this.profileStatements[i].smartFiltersPS.splice(k, count)
      if (this.profileStatements[i].smartFiltersPS.length < 1) {
        var o = {
          categorySelect: null,
          compare: [],
          compareSelect: null,
          field: null,
          arrFields: ['', ''],
          type: 'str',
          isHidden: false
        };
        this.profileStatements[i].smartFiltersPS[0] = o;
      }
  
      return false;
    }
  
    smartSearchPS(m, type = 0) {
      this.isLoad = true;
      var str = '';
  
      if (this.playlist_duration_ps == 0) {
        this.alert.alertOpen("Playlist duration can not be zero", 1);
        this.isLoad = false;
        return false;
      }
      if (this.profileStatements[m].fp_id == null) {
        this.alert.alertOpen("Please select foundation playlist lo continue", 1);
        this.isLoad = false;
        return false;
      }
  
      if (this.profileStatements[m].cover == '') {
        this.alert.alertOpen("Please select cover", 1);
        this.isLoad = false;
        return false;
      }
      if (this.profileStatements[m].ratio == '') {
        this.alert.alertOpen("Please select ratio", 1);
        this.isLoad = false;
        return false;
      }
  
      let add_to_cp_playlist = this.profileStatements[m].smartFiltersPS.find(x => x.categorySelect == 'add_to_cp_playlist');
      let cp_playlist_id = this.profileStatements[m].smartFiltersPS.find(x => x.categorySelect == 'cp_playlist_id');
  
      if (add_to_cp_playlist && !cp_playlist_id) {
        this.alert.alertOpen("Please add the playlist name filter to proceed with this!", 1);
        this.isLoad = false;
        return false;
      }
      // }
  
      localStorage.setItem('psSelect', JSON.stringify(this.profileStatements));
  
  
      this.smartService.getFilteredTracksCount(this.profileStatements[m]).subscribe((tracks: any) => {
        console.log('tracks', tracks);
        this.isLoad = false;
        if (tracks.result != 'empty') {
          let maxHours = (this.playlist_duration_ps * (this.percentage_to_update / 100)) * Number(this.profileStatements[m].cover) * (Number(this.profileStatements[m].ratio) / 100);
          maxHours = Math.round(maxHours * 3600);
  
  
          let length_sec = 0;
          let trackCount = 0;
          let trackIDs = [];
          for (var i = 0; i < tracks.list.length; i++) {
            let el = tracks.list[i];
            length_sec += el.length_sec;
            trackCount++;
            trackIDs.push(el.id);
            if (length_sec > maxHours)
              break;
          }
  
          this.profileStatements[m].availableTracks = trackCount;
          this.profileStatements[m].trackIDs = trackIDs;
          this.profileStatements[m].futureCoverage = tracks.total - trackCount;
          this.profileStatements[m].length_sec = Math.round(length_sec);
  
  
        }
        else {
          this.profileStatements[m].availableTracks = 0;
          this.profileStatements[m].futureCoverage = 0;
          this.profileStatements[m].trackIDs = [];
          this.profileStatements[m].length_sec = 0;
  
        }
  
        if (type == 1)
          this.openConfirmPopup(0, 3);
        if (type == 2)
          this.openConfirmPopup(0, 6);
      });
  
    }
  
    /**
     Get search results from smart filters
     * @param clicked(boolean) - if true search results will show from start
     * @returns {boolean}
     */
    smartSearch(clicked) {
      console.log(this.selectedTabs);
  
      this.blurInput();
      this.isLoad = true;
      this.filterClicked[this.selectedTabs.leftTabIndex] = true;
      var str = '';
      if (clicked)
        this.infiniteScroll.offsetItems = 0;
      var offset = this.infiniteScroll.offsetItems;
      var limit = this.infiniteScroll.addItems;
      this.isSmartSearch = true;
      if (this.checkSmartFilters()) {
        if (this.selectedTabs.leftTabIndex == 0) {
          localStorage.setItem('smartFiltersTL', JSON.stringify(this.smartFilters[0]));
        }
        if (this.selectedTabs.leftTabIndex == 1) {
          localStorage.setItem('smartFiltersFP', JSON.stringify(this.smartFilters[1]));
        }
        if (this.selectedTabs.leftTabIndex == 2) {
          localStorage.setItem('smartFiltersSFP', JSON.stringify(this.smartFilters[2]));
        }
        if (this.selectedTabs.leftTabIndex == 3) {
          localStorage.setItem('smartFiltersSCP', JSON.stringify(this.smartFilters[3]));
        }
        if (this.selectedTabs.leftTabIndex == 4) {
          localStorage.setItem('smartFiltersCP', JSON.stringify(this.smartFilters[4]));
        }
       
        let foundation_play_list_id = this.smartFilters[this.selectedTabs.leftTabIndex].find(x => x.categorySelect == 'foundation_play_list_id');
        let add_to_cp_playlist = this.smartFilters[this.selectedTabs.leftTabIndex].find(x => x.categorySelect == 'add_to_cp_playlist');
        let cp_playlist_id = this.smartFilters[this.selectedTabs.leftTabIndex].find(x => x.categorySelect == 'cp_playlist_id');
        let scp_playlist_id = this.smartFilters[this.selectedTabs.leftTabIndex].find(x => x.categorySelect == 'scp_playlist_id');
        let sfp_playlist_id = this.smartFilters[this.selectedTabs.leftTabIndex].find(x => x.categorySelect == 'sfp_playlist_id');
  
        if (this.selectedTabs.leftTabIndex == 0 && add_to_cp_playlist && !cp_playlist_id) {
          this.alert.alertOpen("Please add the playlist name filter to proceed with this!", 1);
          this.isLoad = false;
          return;
        }
  
        if (this.selectedTabs.leftTabIndex == 1 && add_to_cp_playlist && !foundation_play_list_id) {
          this.alert.alertOpen("Please select a playlist name to proceed with the filter", 1);
          this.isLoad = false;
          return;
        }
  
        for (var i = 0; i < this.smartFilters[this.selectedTabs.leftTabIndex].length; i++) {
          var el = this.smartFilters[this.selectedTabs.leftTabIndex][i];
  
          if (el.categorySelect == 'foundation_play_list_id' && (el.compareSelect == null
            || el.field == null)) {
            this.alert.alertOpen("One or more search criteria is blank. Please fill them and search", 1);
            this.isLoad = false;
            return false;
          }
          if (el.categorySelect != null && el.compareSelect != null) {
            str += el.categorySelect + '[' + el.compareSelect + ']=';
            if (el.type == 'str') {
              str += encodeURIComponent(el.field);
            }
            else if (el.type == 'int') {
              str += "[" + el.arrFields[0];
              if (el.compareSelect == 'between') {
                str += "," + el.arrFields[1];
              }
              str += "]";
            }
            else if (el.type == 'date') {
              str += "['" + el.arrFields[0].formatted + "','" + el.arrFields[1].formatted + "']";
            }
            str += '&';
            if (el.categorySelect == 'cp_playlist_id') {
              str += 'cp_playlist_name' + '[' + i + ']' + '[' + el.compareSelect + ']=';
              str += encodeURIComponent(el.field);
              str += '&';
            }
            if (el.categorySelect == 'scp_playlist_id') {
              str += 'scp_playlist_name' + '[' + i + ']' + '[' + el.compareSelect + ']=';
              str += encodeURIComponent(el.field);
              str += '&';
            }
            if (el.categorySelect == 'sfp_playlist_id') {
              str += 'sfp_playlist_name' + '[' + i + ']' + '[' + el.compareSelect + ']=';
              str += encodeURIComponent(el.field);
              str += '&';
            }
          }
          else {
            this.alert.alertOpen("One or more search criteria is blank. Please fill them and search", 1);
            this.isLoad = false;
            return false;
          }
        }
        str += 'match_type=' + this.matchSelect;
        str += '&and_or_type=' + this.andOrSelect;
        str += '&orderBy=' + this.leftTabs[this.selectedTabs.leftTabIndex].orderBy + '&orderDirect=' + this.leftTabs[this.selectedTabs.leftTabIndex].order + '&offset=' + offset + '&limit=' + limit + '&filterPage=sfcEditor';
        if (this.selectedTabs.leftTabIndex == 1) {
          str += '&fpID=' + this.leftTabs[1].playlistId;
        } else if(this.selectedTabs.leftTabIndex == 2){
          str += '&sfpID=' + this.leftTabs[2].playlistId;
      } else if(this.selectedTabs.leftTabIndex == 3){
        str += '&scpID=' + this.leftTabs[3].playlistId;
      }else if(this.selectedTabs.leftTabIndex == 4){
        str += '&cpID=' + this.leftTabs[4].playlistId;
      }
  
     
  
        this.smartService.getFilteredTracks(str).subscribe((tracks: any) => {
          this.isLoad = false;
          if (tracks.result != 'empty') {
            if (offset == 0)
              this.leftTabs[this.selectedTabs.leftTabIndex].tracks = tracks.list;
            else
              this.leftTabs[this.selectedTabs.leftTabIndex].tracks = this.leftTabs[this.selectedTabs.leftTabIndex].tracks.concat(tracks.list);
            this.tracksCount[this.selectedTabs.leftTabIndex].display = tracks.total;
          }
          else {
            if (offset == 0) {
              this.alert.alertOpen("No results", 0);
              this.isSmartSearch = false;
              this.tracksCount[this.selectedTabs.leftTabIndex].display = 0;
              this.leftTabs[this.selectedTabs.leftTabIndex].tracks = [];
            }
          }
          this.popups.smartFilterPopupOpened = false;
  
          this.getDuration();
        });
      }
      else {
        this.alert.alertOpen("One or more search criteria is blank. Please fill them and search", 1);
        this.isLoad = false;
      }
    }
  
    /**
     Check smart filter for fill
     * @returns {boolean}
     */
    checkSmartFilters() {
      for (var i = 0; i < this.smartFilters[this.selectedTabs.leftTabIndex].length; i++) {
        var el = this.smartFilters[this.selectedTabs.leftTabIndex][i];
        if (el.categorySelect != null && el.compareSelect != null) {
          if (el.type == 'str') {
            if (el.field != '')
              return true;
          }
          else if (el.type == 'int' || el.type == 'date') {
            if (el.arrFields[0] != '')
              return true;
          }
        }
      }
      return false;
    }
  
    /**
     Clear smart filter search results and get all track library
     * @param e - event
     */
    clearFilter() {
      this.profileFilterCount = 0;
      this.isLoad = true;
      if (this.selectedTabs.leftTabIndex == 0) {
        localStorage.removeItem("smartFiltersTL");
        this.trackService.getTracks(this.leftTabs[this.selectedTabs.leftTabIndex].order, this.leftTabs[this.selectedTabs.leftTabIndex].orderBy, 0, 1000).subscribe((tracks: any) => {
          this.leftTabs[this.selectedTabs.leftTabIndex].tracks = tracks.list;
          this.tracksCount[this.selectedTabs.leftTabIndex].total = tracks.total;
          this.tracksCount[this.selectedTabs.leftTabIndex].display = tracks.total;
          this.smartFilters[this.selectedTabs.leftTabIndex] = [{
            categorySelect: null,
            compare: [],
            compareSelect: null,
            field: null,
            arrFields: ['', ''],
            type: 'str',
            isHidden: false
          }];
          this.smartFilterResults = [];
          this.isSmartSearch = false;
          this.isLoad = false;
        });
  
      }
      if (this.selectedTabs.leftTabIndex == 1) {
        localStorage.removeItem("smartFiltersFP");
        this.selectFpPlaylist(2, this.leftTabs[1].playlistId, this.leftTabs[1].title);
        this.smartFilters[this.selectedTabs.leftTabIndex] = [{
          categorySelect: null,
          compare: [],
          compareSelect: null,
          field: null,
          arrFields: ['', ''],
          type: 'str',
          isHidden: false
        }];
        this.smartFilterResults = [];
        this.isSmartSearch = false;
      }
      if (this.selectedTabs.leftTabIndex == 2) {
        localStorage.removeItem("smartFiltersSFP");
        this.selectFpsamplePlaylist(2, this.leftTabs[2].playlistId, this.leftTabs[2].title);
        this.smartFilters[this.selectedTabs.leftTabIndex] = [{
          categorySelect: null,
          compare: [],
          compareSelect: null,
          field: null,
          arrFields: ['', ''],
          type: 'str',
          isHidden: false
        }];
        this.smartFilterResults = [];
        this.isSmartSearch = false;
      }
      if (this.selectedTabs.leftTabIndex == 3) {
        localStorage.removeItem("smartFiltersSCP");
        this.selectCpsamplePlaylist(2, this.leftTabs[3].playlistId, this.leftTabs[3].title);
        this.smartFilters[this.selectedTabs.leftTabIndex] = [{
          categorySelect: null,
          compare: [],
          compareSelect: null,
          field: null,
          arrFields: ['', ''],
          type: 'str',
          isHidden: false
        }];
        this.smartFilterResults = [];
        this.isSmartSearch = false;
      }
      if (this.selectedTabs.leftTabIndex == 4) {
        localStorage.removeItem("smartFiltersCP");
        this.selectCpPlaylist(3, this.leftTabs[4].playlistId, this.leftTabs[4].title);
        this.smartFilters[this.selectedTabs.leftTabIndex] = [{
          categorySelect: null,
          compare: [],
          compareSelect: null,
          field: null,
          arrFields: ['', ''],
          type: 'str',
          isHidden: false
        }];
        this.smartFilterResults = [];
        this.isSmartSearch = false;
      }
  
      this.filterClicked[this.selectedTabs.leftTabIndex] = false;
      this.popupClose();
    }
    
    
  
    /**
     Add checkboxes for smart filter results for converting results to playlist
     */
    addSmartFilterCheckbox() {
      for (var i = 0; i < this.smartFilterResults.length; i++) {
        this.smartFilterResults[i].checkbox = false;
      }
    }
  
    /**
     Open popup for convert search results to playlist
     * @param e - event
     * @returns {boolean}
     */
    openConvertPopup(e) {
      var str = '';
      if (this.smartFilters[0][0].categorySelect != null) {
        for (var i = 0; i < this.smartFilters[0].length; i++) {
          var el = this.smartFilters[0][i];
          if (el.categorySelect != null && el.compareSelect != null) {
            str += el.categorySelect + '[' + el.compareSelect + ']=';
            if (el.type == 'str') {
              str += el.field;
            }
            else if (el.type == 'int') {
              str += "[" + el.arrFields[0];
              if (el.compareSelect == 'between') {
                str += "," + el.arrFields[1];
              }
              str += "]";
            }
            else if (el.type == 'date') {
              str += "['" + el.arrFields[0].formatted + "','" + el.arrFields[1].formatted + "']";
            }
            str += '&';
            if (el.categorySelect == 'cp_playlist_id') {
              str += 'cp_playlist_name' + '[' + i + ']' + '[' + el.compareSelect + ']=';
              str += encodeURIComponent(el.field);
              str += '&';
            }
          }
        }
        str += 'match_type=' + this.matchSelect + '&filterPage=cpEditor';
        str += '&and_or_type=' + this.andOrSelect;
        this.smartService.getFilteredTracks(str).subscribe((tracks: any) => {
          if (tracks.result != 'empty') {
            this.smartFilterResults = tracks.list;
            this.addSmartFilterCheckbox();
            this.popupClose();
            this.popups.convertPlaylistPopupOpened = true;
            this.convert = false;
          }
          else {
            this.alert.alertOpen("No results for converting", 0);
          }
          this.popups.smartFilterPopupOpened = false;
        });
      }
      return false;
    }
  
    /**
     Add selected tracks from search results to playlist
     * @param pid - playlist id
     * @returns {boolean}
     */
    addTracksToConvert(pid) {
      for (var i = 0; i < this.smartFilterResults.length; i++) {
        if (this.smartFilterResults[i].checkbox) {
          this.addTrack(this.smartFilterResults[i].id, pid, false);
        }
      }
      this.convert = false;
      this.popupConvertClose();
      this.popupClose();
      return false;
    }
  
    /**
     Check/uncheck checkbox after click on row
     * @param index
     */
    selectCheckbox(index) {
      this.smartFilterResults[index].checkbox = !this.smartFilterResults[index].checkbox;
    }
  
    /**
     Change checkbox for select/deselect all results for convert to playlist
     */
    changeAllConvert() {
      for (var i = 0; i < this.smartFilterResults.length; i++) {
        this.smartFilterResults[i].checkbox = this.selectAllConvert;
      }
    }
  
    /**
     Open dropdown with another playlist
     * @param hover - checking if user hover mouse to dropdown
     * @returns {boolean}
     */
    openAnotherPlaylists(hover) {
      if (this.leftTabs[this.selectedTabs.leftTabIndex].selectedRows.length != 0) {
        if (!this.openPlaylistDrop) {
          this.openPlaylist.users.id = null;
          this.openPlaylist.sites.id = null;
          this.openPlaylist.channels.id = null;
          this.openPlaylist.playlists.id = null;
          this.getPlaylistInfo(1, this.checkedUserId);
        }
        if (hover) {
          this.openPlaylistDrop2 = false;
        }
        else {
          this.openPlaylistDrop2 = !this.openPlaylistDrop2;
        }
  
      }
      else {
        if (!hover)
          this.alert.alertOpen("No Tracks selected!", 1);
      }
      return false;
    }
  
    /**
     Get playlists from selected channel
     */
    getSimilarPlaylists() {
      this.cpService.getPlaylists(this.tabs[0].playlistInfo.cp_category_id).subscribe(playlists => {
        this.similarPlaylists = playlists;
      });
    }
  
    /**
     Open popup for creating playlist
     * @returns {boolean}
     */
    openCreatePlaylistPopup() {
      this.updateCategoriesList();
      this.popups.playlistPopupOpened = true;
      return false;
    }
  
    /**
     Get sites from selected client
     */
    updateCategoriesList() {
    
      this.fpsamplePlaylistService.getClientsList().subscribe((sites: any) => {
        this.categoriesList = sites;
      });
    }
  
    /**
     Change categories select
     * @param e - event
     */
    changeCategory(e) {
      this.updateSubcategories(e.target.value);
    }
  
    /**
     Get channels
     * @param id - site id
     */
    updateSubcategories(id) {
      this.fpsamplePlaylistService.getChannelsByuser(id).subscribe((categories: any) => {
        this.subcategoriesList = categories;
      });
    }
  
    /**
     Update playlist data
     * @param {NgForm} form - form
     */
    playlistDataUpdate(form: NgForm) {
      if (this.playlistFields.site_id = null) {
        this.alert.alertOpen("Please select sub-category", 1);
        return false;
      }
      form.value.edited_by=this.current_user.id;
  
      var str = Object.keys(form.value).map(function (key) {
        var val = encodeURIComponent(form.value[key]);
        if (val == 'null' || val == null) {
          val = '';
        }
        return encodeURIComponent(key) + '=' + val;
      }).join('&');
  
      if (this.playlistFields.id == null) {
       
          this.fpsamplePlaylistService.createPlaylist(str).subscribe((playlists: any) => {
            this.alert.alertOpen("Playlist added", 0);
            this.popupClose();
            this.addTab(playlists.id);
            if (this.convert) {
              this.addTracksToConvert(playlists.id);
            }
          });
        
      }
    }
  
    /**
     Open playlist in new window
     * @param e - event
     */
    openNewWindow(e) {
  
      let id = this.tabs[this.selectedTabs.rightTabIndex].playlistInfo.id;
      let host = location.host;
      let pcat = this.route.snapshot.queryParams.pcat;
      let uid = this.route.snapshot.queryParams.uid;
      let method = this.route.snapshot.routeConfig.path;
      let protocol = 'https://';
      if (location.protocol != 'https:') {
        protocol = 'http://'
      }
      let href = protocol + host + '/' + method + '?playlistID=' + id + '&pcat=' + pcat + '&uid=' + uid + '&sh=0';
      console.log(href);
      window.open(href, "_blank");
    }
  
    /**
     Open playlist and track library in separate windows
     * @returns {boolean}
     */
    openSeparateWindow() {
      this.screenParams.isFullScreen = true;
      var str = window.location.href + '&ipl=true&ifs=true';
      window.open(str, "_blank");
      return false;
    }
  
    /**
     Get last playlist autosave
     * @param sec - seconds
     * @returns {string} - parsed string
     */
    lastAutosave(sec: any) {
      var d: any = new Date();
      var t = Math.round(d / 1000);
      var str = '';
      if (t - sec < 60) {
        str = 'less than minute';
      }
      else if (t - sec >= 60 && t - sec < 120) {
        str = Math.round((t - sec) / 60) + ' minute';
      }
      else if (t - sec >= 120 && t - sec < 60 * 60) {
        str = Math.round((t - sec) / 60) + ' minutes';
      }
      else if (t - sec >= 60 * 60 && t - sec < 60 * 120) {
        str = Math.round((t - sec) / (60 * 60)) + ' hour';
      }
      else if (t - sec >= 60 * 120 && t - sec < 60 * 60 * 24) {
        str = Math.round((t - sec) / (60 * 60)) + ' hours';
      }
      else if (t - sec >= 60 * 60 * 24 && t - sec < 60 * 60 * 24 * 2) {
        str = Math.round((t - sec) / (60 * 60 * 24)) + ' day';
      }
      else if (t - sec >= 60 * 60 * 24 * 2) {
        str = Math.round((t - sec) / (60 * 60 * 24)) + ' days';
      }
      return str;
    }
  
    /**
     ??
     */
    getTrackInfoById(id) {
      for (var i = 0; i < this.leftTabs[this.selectedTabs.leftTabIndex].tracks.length; i++) {
        if (this.leftTabs[this.selectedTabs.leftTabIndex].tracks[i].id == id) {
          return i;
        }
      }
    }
  
    /**
     Get track id in library by id in playlist
     * @param pivot - track id in playlist
     * @param list - track in playlist
     * @returns {any}
     */
    getTrackIdByPivot(pivot, list) {
      for (var i = 0; i < list.length; i++) {
        if (list[i].pivot_id == pivot) {
          return list[i].id;
        }
      }
      return pivot;
    }
  
    /**
     Select Foundation playlist in left side
     * @param type - type of action 0 - get subcategories, 1 - get playlists, 2 - select playlist
     * @param id - id of category, subcategory or playlist
     * @param name - playlist name
     */
    selectFpPlaylist(type, id, name) {
      if (type == 0) {
        this.fpList.category.id = id;
      }
      else if (type == 1) {
        this.fpService.getPlaylists(id).subscribe((playlist: any) => {
          this.fpList.playlists.playlistsArr = playlist;
          if (this.searchStrFP != '') {
            this.fpList.playlists.playlistsArr = this.fpList.playlists.playlistsArr.filter(x => x.name.toLowerCase().indexOf(this.searchStrFP.toLowerCase()) >= 0);
            //this.fpList.subcategory.id = id;
          }
          this.fpList.subcategory.id = id;
        });
      }
      else if (type == 2) {
        this.leftTabs[1].title = name;
        this.leftTabs[1].playlistId = id;
        this.fpService.getPlaylistTracks(id, this.leftTabs[1].order, this.leftTabs[1].orderBy).subscribe((tracks: any) => {
          if (tracks.message == undefined)
            this.leftTabs[1].tracks = tracks;
          else {
            this.leftTabs[1].tracks = [];
          }
          this.isSmartSearch = false;
          this.filterClicked[1] = false;
          localStorage.removeItem("smartFiltersTL");
          localStorage.removeItem("smartFiltersFP");
          localStorage.removeItem("smartFiltersSCP");
          localStorage.removeItem("smartFiltersSFP")
          localStorage.removeItem("smartFiltersCP")
          this.tracksCount[1].display = tracks.length;
          this.tracksCount[1].total = tracks.length;
          this.isLoad = false;
          this.getDuration();
        });
      }
      // e.stopPropagation();
    }
  
    selectFpsamplePlaylist(type, id, name) {
  
      if (type == 0) {
        this.fpsampleList.category.id = id;
  
      }
      else if (type == 1) {
        this.fpsamplePlaylistService.getPlaylists(id).subscribe((playlist: any) => {
          this.fpsampleList.playlists.playlistsArr = playlist;
          if (this.searchStrFPsample != '') {
            this.fpsampleList.playlists.playlistsArr = this.fpsampleList.playlists.playlistsArr.filter(x => x.name.toLowerCase().indexOf(this.searchStrFPsample.toLowerCase()) >= 0);
            //this.fpList.subcategory.id = id;
          }
          this.fpsampleList.subcategory.id = id;
        });
      }
      else if (type == 2) {
        this.leftTabs[2].title = name;
        this.leftTabs[2].playlistId = id;
        this.fpsamplePlaylistService.getPlaylistTracks(id, this.leftTabs[2].order, this.leftTabs[2].orderBy).subscribe((tracks: any) => {
          if (tracks.message == undefined)
            this.leftTabs[2].tracks = tracks;
          else {
            this.leftTabs[2].tracks = [];
          }
          this.isSmartSearch = false;
          this.filterClicked[1] = false;
          localStorage.removeItem("smartFiltersTL");
          localStorage.removeItem("smartFiltersFP");
          localStorage.removeItem("smartFiltersSCP");
          localStorage.removeItem("smartFiltersSFP");
          localStorage.removeItem("smartFiltersCP")
          this.tracksCount[2].display = tracks.length;
          this.tracksCount[2].total = tracks.length;
          this.isLoad = false;
          this.getDuration();
        });
      }
      // e.stopPropagation();
    }
  
  
    selectCpsamplePlaylist(type, id, name) {
  
      if (type == 0) {
        this.cpsampleList.category.id = id;
      }
      else if (type == 1) {
        this.clientSamplePlaylist.getPlaylists(id).subscribe((playlist: any) => {
          this.cpsampleList.playlists.playlistsArr = playlist;
          if (this.searchStrCPsample != '') {
            this.cpsampleList.playlists.playlistsArr = this.cpsampleList.playlists.playlistsArr.filter(x => x.name.toLowerCase().indexOf(this.searchStrCPsample.toLowerCase()) >= 0);
            //this.fpList.subcategory.id = id;
          }
          this.cpsampleList.subcategory.id = id;
        });
      }
      else if (type == 2) {
        this.leftTabs[3].title = name;
        this.leftTabs[3].playlistId = id;
        this.clientSamplePlaylist.getPlaylistTracks(id, this.leftTabs[2].order, this.leftTabs[2].orderBy).subscribe((tracks: any) => {
          if (tracks.message == undefined)
            this.leftTabs[3].tracks = tracks;
          else {
            this.leftTabs[3].tracks = [];
          }
          this.isSmartSearch = false;
          this.filterClicked[1] = false;
          localStorage.removeItem("smartFiltersTL");
          localStorage.removeItem("smartFiltersFP");
          localStorage.removeItem("smartFiltersSCP");
          localStorage.removeItem("smartFiltersSFP");
          localStorage.removeItem("smartFiltersCP")
          this.tracksCount[3].display = tracks.length;
          this.tracksCount[3].total = tracks.length;
          this.isLoad = false;
          this.getDuration();
        });
      }
      // e.stopPropagation();
    }
  
  
  
    selectCpPlaylist(type, id, name) {
      if (type == 0) {
        this.cpList.client.id = id;
      } else if (type == 1) {
        this.cpService.getSubCategory(id).subscribe((playlist: any) => {
          this.cpList.subcategory.subcarArr = playlist;
          this.cpList.category.id = id;
        })
      }
      else if (type == 2) {
        this.cpService.getPlaylists(id).subscribe((playlist: any) => {
          this.cpList.playlists.playlistsArr = playlist;
          if (this.searchStrCP != '') {
            this.cpList.playlists.playlistsArr = this.cpList.playlists.playlistsArr.filter(x => x.name.toLowerCase().indexOf(this.searchStrCP.toLowerCase()) >= 0);
            //this.fpList.subcategory.id = id;
          }
          this.cpList.subcategory.id = id;
        });
      }
      else if (type == 3) {
        this.leftTabs[4].title = name;
        this.leftTabs[4].playlistId = id;
        this.leftTabs[4].tracks = [];
        this.cpService.getPlaylistTracks(id, this.leftTabs[2].order, this.leftTabs[2].orderBy).subscribe((tracks: any) => {
          if (tracks.message == undefined)
            this.leftTabs[4].tracks = tracks;
          
          this.isSmartSearch = false;
          this.filterClicked[1] = false;
          localStorage.removeItem("smartFiltersTL");
          localStorage.removeItem("smartFiltersFP");
          localStorage.removeItem("smartFiltersSCP");
          localStorage.removeItem("smartFiltersSFP");
          localStorage.removeItem("smartFiltersCP")
          this.tracksCount[4].display = tracks.length;
          this.tracksCount[4].total = tracks.length;
          this.isLoad = false;
          this.getDuration();
        });
      }
      // e.stopPropagation();
    }
    /**
     Checking for selecting one of checkbox in convert to playlist popup
     * @returns {boolean}
     */
    checkSelectedTrack() {
      for (var i = 0; i < this.smartFilterResults.length; i++) {
        if (this.smartFilterResults[i].checkbox) {
          return true;
        }
      }
      return false;
    }
  
    /**
     Open popup for playlist restoring
     * @param e - event
     * @param pid - playlist id
     * @returns {boolean}
     */
    openPlaylistRestorePopup(pid) {
      this.popupClose();
      this.restoredFilters.activeTab = 0;
      this.restoredFilters.actions = '';
      this.restoredFilters.track = '';
      this.restoredFilters.user = '';
      this.restoredFilters.date = [];
      this.cpService.getPlaylistLog(pid).subscribe(tracks => {
        this.restoredPlaylistTracks = tracks;
        this.popups.restoredPlaylistPopupOpened = true;
      });
      return false;
    }
  
    /**
     Open filter dropdown in restore playlist popup
     * @param num - type of filter
     */
    openFilter(num) {
      if (this.restoredFilters.activeTab == num)
        this.restoredFilters.activeTab = 0;
      else
        this.restoredFilters.activeTab = num;
    }
  
    /**
     Select filter
     * @param value - filter value
     * @param num - filter type number
     */
    addFilter(value, num) {
      if (num == 1) {
        var isSelected = this.checkFilter(value, this.restoredFilters.date);
        if (isSelected == -1) {
          this.restoredFilters.date.push(value);
        }
        else {
          this.restoredFilters.date.splice(isSelected, 1);
        }
      }
      else if (num == 2) {
        if (this.restoredFilters.track == value) {
          this.restoredFilters.track = '';
        }
        else {
          this.restoredFilters.track = value;
        }
      }
      else if (num == 3) {
        if (this.restoredFilters.user == value) {
          this.restoredFilters.user = '';
        }
        else {
          this.restoredFilters.user = value;
        }
      }
      else if (num == 4) {
        if (this.restoredFilters.actions == value) {
          this.restoredFilters.actions = '';
        }
        else {
          this.restoredFilters.actions = value;
        }
      }
      this.sendRestoredFilters();
    }
  
    /**
     Checking filter value
     * @param value - filter value
     * @param arr - filter arraye
     * @returns {number} - filter index in array
     */
    checkFilter(value, arr) {
      for (var i = 0; i < arr.length; i++) {
        if (arr[i] == value) {
          return i;
        }
      }
      return -1;
    }
  
    /**
     Get filtered playlist log
     */
    sendRestoredFilters() {
      if (this.restoredFilters.date.length == 0 && this.restoredFilters.track == '' && this.restoredFilters.user == '' && this.restoredFilters.actions == '') {
        this.cpService.getPlaylistLog(this.tabs[this.selectedTabs.rightTabIndex].playlistInfo.id).subscribe(tracks => {
          this.restoredPlaylistTracks = tracks;
        });
      }
      else {
        var str = '';
        for (var i = 0; i < this.restoredFilters.date.length; i++) {
          if (i != 0) {
            str += '&'
          }
          str += 'date[' + i + ']=' + this.restoredFilters.date[i];
        }
        if (this.restoredFilters.track != '') {
          if (str != '') {
            str += '&';
          }
          str += 'track_title=' + this.restoredFilters.track;
        }
        if (this.restoredFilters.user != '') {
          if (str != '') {
            str += '&';
          }
          str += 'user_name=' + this.restoredFilters.user;
        }
        if (this.restoredFilters.actions != '') {
          if (str != '') {
            str += '&';
          }
          str += 'action=' + this.restoredFilters.actions;
        }
        this.cpService.getFilteredPlaylistLog(this.tabs[this.selectedTabs.rightTabIndex].playlistInfo.id, str).subscribe(tracks => {
          this.restoredPlaylistTracks = tracks;
        });
      }
    }
  
    /**
     Check filter value from array
     * @param value - filter value
     * @returns {boolean}
     */
    checkSelectedFilter(value) {
      for (var i = 0; i < this.restoredFilters.date.length; i++) {
        if (this.restoredFilters.date[i] == value) {
          return true;
        }
      }
      return false;
    }
  
    /**
     Open profile statement popup
     */
    openProfilePopup() {
  
  
      if (this.tabs[this.selectedTabs.rightTabIndex].playlistInfo.psr_id == null) {
        this.alert.alertOpen("Please select profile statement for this profile in CP manager page!", 1);
        return false;
      }
  
      this.focusInput();
      this.profile_statementService.getPSList(this.tabs[this.selectedTabs.rightTabIndex].playlistInfo.psr_id).subscribe((ps: any) => {
  
        if (ps.length > 0) {
          this.profileStatements = [];
          this.psSelects = [];
          this.percentage_to_update = ps[0].percentage_to_update;
          this.playlist_duration_ps = ps[0].playlist_duration;
        }
  
        let sf = [{
          categorySelect: null,
          compare: [],
          compareSelect: null,
          field: null,
          arrFields: ['', ''],
          type: 'str',
          isHidden: false
        }];
  
        for (var i = 0; i < ps.length; i++) {
          // Smart filters add
          if (this.IsValidJSONString(ps[i].smart_filters)) {
            let smart_filters_parsed = JSON.parse(ps[i].smart_filters);
            if (smart_filters_parsed == null) {
              smart_filters_parsed = [];
            }
            if (smart_filters_parsed.length > 0)
              sf = [];
            for (var k = 0; k < smart_filters_parsed.length; k++) {
              sf.push({
                categorySelect: smart_filters_parsed[k].categorySelect,
                compare: smart_filters_parsed[k].compare,
                compareSelect: smart_filters_parsed[k].compareSelect,
                field: smart_filters_parsed[k].field,
                arrFields: smart_filters_parsed[k].arrFields,
                type: smart_filters_parsed[k].type,
                isHidden: smart_filters_parsed[k].isHidden
              });
            }
          }
  
          var o = {
            id: ps[i].id,
            fp_id: ps[i].foundation_play_list_id,
            cover: ps[i].cover,
            ratio: ps[i].ratio,
            note: ps[i].note,
            smartFiltersPS: sf,
            availableTracks: ps[i].available_tracks,
            futureCoverage: ps[i].future_coverage,
            trackIDs: [],
            length_sec: 0
          };
          this.profileStatements.push(o);
          var so = {
            catId: null,
            subcatId: null,
            fp_id: ps[i].foundation_play_list_id,
            fp_arr: [],
            dropOpened: false,
            title: ""
          };
          this.psSelects.push(so);
          this.setPSSelects(i);
        }
      });
      this.fp_categoriesService.getPlaylistsCategories('').subscribe((cats: any) => {
        this.fpCategories = cats;
        this.popups.profileStatementOpened = true;
      });
  
    }
  
    /**
     Add new profile statement row
     * @returns {boolean}
     */
    addProfile() {
      var o = {
        id: null,
        fp_id: null,
        cover: '1',
        ratio: '',
        note: '',
        sCat: null,
        sSubcat: null,
        smartFiltersPS: [{
          categorySelect: null,
          compare: [],
          compareSelect: null,
          field: null,
          arrFields: ['', ''],
          type: 'str',
          isHidden: false
        }],
        availableTracks: 0,
        futureCoverage: 0,
        trackIDs: [],
        length_sec: 0
      };
      this.profileStatements.push(o);
      var so = {
        catId: null,
        subcatId: null,
        fp_id: null,
        fp_arr: [],
        dropOpened: false,
        title: "Select Foundation playlist..."
      };
      this.psSelects.push(so);
      return false;
    }
  
    IsValidJSONString(str) {
      try {
        JSON.parse(str);
      } catch (e) {
        return false;
      }
      return true;
    }
    /**
     Remove profile statement row
     * @param index - row index
     * @param pid - playlist id
     * @param splice - combine profile statement rows
     * @returns {boolean}
     */
    removeProfile(index, pid, splice) {
      if (pid != null) {
        this.profile_statementService.deletePS(pid).subscribe((ps: any) => {
          if (ps.error != undefined) {
            this.alert.alertOpen(ps.error, 1);
          }
          else {
            if (splice) {
              this.splicePS(index);
            }
          }
        });
      }
      else {
        if (splice) {
          this.splicePS(index);
        }
      }
      return false;
    }
  
    /**
     Combine profile statement rows
     * @param index - row index
     */
    splicePS(index) {
      this.profileStatements.splice(index, 1);
      this.psSelects.splice(index, 1);
    }
  
    /**
     Send params for calculation rating and cover
     * @param index - profile statement index
     * @param save(boolean) - save params on server
     * @param param - param for calculate 1=cover, 2=rating
     */
    sendCalc(index, save, param) {
      if (this.profileStatements[index].fp_id != null) {
        var ps = this.profileStatements[index];
        var str = 'cover=' + ps.cover;
        if (param == 2) {
          str = 'rating=' + ps.ratio;
        }
        this.profile_statementService.sendCalculating(ps.fp_id, this.tabs[this.selectedTabs.rightTabIndex].playlistInfo.id, str, false).subscribe((calc: any) => {
          if (calc.error != undefined) {
            this.alert.alertOpen(calc.error, 1);
          }
          else {
            if (calc.propoused_cover_seconds != undefined) {
              this.profileStatements[index].cover = calc.propoused_cover_seconds;
            }
            if (calc.propoused_rating_percents != undefined) {
              this.profileStatements[index].ratio = calc.propoused_rating_percents
            }
          }
        });
      }
    }
  
    /**
     Check Foundation playlist dublicates in profile statement popup
     * @param index - profile statement row index
     * @returns {boolean}
     */
    checkFPDublicates(index) {
      this.profileStatements[index].cover = '';
      this.profileStatements[index].ratio = '';
      this.profileStatements[index].note = '';
      if (this.profileStatements[index].id != null) {
        this.removeProfile(index, this.profileStatements[index].id, false);
      }
      this.profileStatements[index].id = null;
      for (var i = 0; i < this.profileStatements.length; i++) {
        if (index != i && this.profileStatements[i].fp_id == this.profileStatements[index].fp_id) {
          this.alert.alertOpen("You are selecting a duplicate playlist", 1);
          this.profileStatements[index].fp_id = null;
          this.psSelects[index].fp_id = null;
          this.psSelects[index].title = 'Select Foundation playlist...';
          return false;
        }
      }
      return true;
    }
  
    reloadPlaylist(suspend = false) {
      this.isLoad = true;
      this.profile_statementService.getPSList(this.tabs[this.selectedTabs.rightTabIndex].playlistInfo.psr_id).subscribe((ps: any) => {
        console.log('ps', ps);
        if (ps.result && ps.result == 'There are no profile statements found') {
          this.isLoad = false;
          this.alert.alertOpen("Please assign a profile statement to this profile!", 1);
          return false;
        }
        if (ps.length > 0) {
          this.profileStatements = [];
          this.percentage_to_update = ps[0].percentage_to_update;
          this.playlist_duration_ps = ps[0].playlist_duration;
        }
  
        let sf = [{
          categorySelect: null,
          compare: [],
          compareSelect: null,
          field: null,
          arrFields: ['', ''],
          type: 'str',
          isHidden: false
        }];
  
        for (var i = 0; i < ps.length; i++) {
          // Smart filters add
          if (this.IsValidJSONString(ps[i].smart_filters)) {
            let smart_filters_parsed = JSON.parse(ps[i].smart_filters);
            if (smart_filters_parsed.length > 0)
              sf = [];
            for (var k = 0; k < smart_filters_parsed.length; k++) {
              sf.push({
                categorySelect: smart_filters_parsed[k].categorySelect,
                compare: smart_filters_parsed[k].compare,
                compareSelect: smart_filters_parsed[k].compareSelect,
                field: smart_filters_parsed[k].field,
                arrFields: smart_filters_parsed[k].arrFields,
                type: smart_filters_parsed[k].type,
                isHidden: smart_filters_parsed[k].isHidden
              });
            }
          }
  
          var o = {
            id: ps[i].id,
            fp_id: ps[i].foundation_play_list_id,
            cover: ps[i].cover,
            ratio: ps[i].ratio,
            note: ps[i].note,
            smartFiltersPS: sf,
            availableTracks: ps[i].available_tracks,
            futureCoverage: ps[i].future_coverage,
            trackIDs: [],
            length_sec: 0
          };
          this.profileStatements.push(o);
  
          let type = 0;
          if (i === (ps.length - 1)) {
            //
            if (suspend) {
  
              type = 2;
            }
            else
              type = 1;
          }
          this.smartSearchPS(i, type);
        }
  
        this.isLoad = false;
      });
    }
  
  
    suspendCreateNew() {
      console.log('profileStatements', this.profileStatements);
      this.isLoad = true;
      var sum = this.profileStatements.reduce((a, b) => a + (Number(b['ratio']) || 0), 0);
      if (sum > 100) {
        this.isLoad = false;
        this.alert.alertOpen("Sum of ratio exceeds 100%", 1);
        return false;
      }
      if (this.percentage_to_update == 0) {
        this.isLoad = false;
        this.alert.alertOpen("Please enter percentage of playlist to update value", 1);
        return false;
      }
  
      let totalMaxHours = 0;
      let totalLengthSec = 0;
      for (let index = 0; index < this.profileStatements.length; index++) {
        const element = this.profileStatements[index];
        if (element.trackIDs.length == 0) {
          this.isLoad = false;
          this.alert.alertOpen("Please load tracks from FP", 1);
          return false;
        }
  
        let maxHours = (this.playlist_duration_ps * (this.percentage_to_update / 100)) * Number(this.profileStatements[index].cover) * (Number(this.profileStatements[index].ratio) / 100);
        totalMaxHours += maxHours * 3600;
        totalLengthSec += this.profileStatements[index].length_sec;
      }
      console.log('this.playlist_duration_ps', Math.round(this.playlist_duration_ps));
      console.log('this.percentage_to_update', Math.round(this.percentage_to_update));
      console.log('totalMaxHours', Math.round(totalMaxHours));
      console.log('totalLengthSec', Math.round(totalLengthSec));
      if (Math.round(totalLengthSec) < Math.round(totalMaxHours)) {
        this.isLoad = false;
        this.alert.alertOpen("Not enough tracks. Please add more tracks!", 1);
        return false;
      }
  
      var ps_str = this.getPSString(this.profileStatements);
      this.profile_statementService.suspend_create_new(this.tabs[this.selectedTabs.rightTabIndex].playlistInfo.psr_id, this.tabs[this.selectedTabs.rightTabIndex].playlistInfo.id, ps_str).subscribe((ps: any) => {
        this.isLoad = false;
        if (ps.error != undefined) {
          this.alert.alertOpen(ps.error, 1);
        }
        else {
          this.alert.alertOpen("Profile created", 0);
  
          this.popupClose();
          this.backBtnClick();
        }
      });
    }
  
    toggleAutoGenerate(e) {
      //if(e)
      this.openConfirmPopup(e, 7)
      return false;
    }
    toggleAutoBuild(e) {
  
      if (this.tabs[this.selectedTabs.rightTabIndex].playlistInfo.auto_generate == 0) {
        return this.alert.alertOpen("Please switch on the auto generation option to continue!", 1);
      }
      this.openConfirmPopup(e, 8)
      return false;
    }
  
    // toggleXmasProfile(e){
    //   this.openConfirmPopup(e,10)
    //   return false;
    // }
  
    setAutoGenerate(e) {
      this.cpService.setAutoGenerate(this.tabs[this.selectedTabs.rightTabIndex].playlistInfo.id, e).subscribe((status: any) => {
        if (status.error != undefined) {
          this.alert.alertOpen(status.error, 1);
        }
        else {
          if (e) e = "enabled";
          else e = "disabled";
          this.alert.alertOpen("Auto generation " + e + " !", 0);
        }
      });
    }
  
    setAutoBuild(e) {
      this.cpService.setAutoBuild(this.tabs[this.selectedTabs.rightTabIndex].playlistInfo.id, e).subscribe((status: any) => {
        if (status.error != undefined) {
          this.alert.alertOpen(status.error, 1);
        }
        else {
          if (e) e = "enabled";
          else e = "disabled";
          this.alert.alertOpen("Auto build " + e + " !", 0);
        }
      });
    }
  
    setFestive(e) {
      this.cpService.setFestive(this.tabs[this.selectedTabs.rightTabIndex].playlistInfo.id, e).subscribe((status: any) => {
        if (status.error != undefined) {
          this.alert.alertOpen(status.error, 1);
        }
        else {
          if (e == 0) {
            this.alert.alertOpen("Festive type removed successfully!", 0);
            return false;
          }
          if (e == 1) e = "Christmas";
          else if (e == 2) e = "Chinese New Year";
          else if (e == 3) e = "Ramadan";
          this.alert.alertOpen("Successfully marked as " + e + " profile !", 0);
        }
      });
    }
  
    /**
     Save information in profile statements popup
     */
    saveProfileStatement($rl = true) {
      this.isLoad = true;
      var sum = this.profileStatements.reduce((a, b) => a + (Number(b['ratio']) || 0), 0);
      if (sum > 100) {
        this.isLoad = false;
        this.alert.alertOpen("Sum of ratio exceeds 100%", 1);
        return false;
      }
      if (this.percentage_to_update == 0) {
        this.isLoad = false;
        this.alert.alertOpen("Please enter percentage of playlist to update value", 1);
        return false;
      }
  
      let totalMaxHours = 0;
      let totalLengthSec = 0;
      for (let index = 0; index < this.profileStatements.length; index++) {
        const element = this.profileStatements[index];
        if (element.trackIDs.length == 0) {
          this.isLoad = false;
          this.alert.alertOpen("Please load tracks from FP", 1);
          return false;
        }
  
        let maxHours = (this.playlist_duration_ps * (this.percentage_to_update / 100)) * Number(this.profileStatements[index].cover) * (Number(this.profileStatements[index].ratio) / 100);
        totalMaxHours += maxHours * 3600;
        totalLengthSec += this.profileStatements[index].length_sec;
      }
      console.log('this.playlist_duration_ps', Math.round(this.playlist_duration_ps));
      console.log('this.percentage_to_update', Math.round(this.percentage_to_update));
      console.log('totalMaxHours', Math.round(totalMaxHours));
      console.log('totalLengthSec', Math.round(totalLengthSec));
      if (Math.round(totalLengthSec) < Math.round(totalMaxHours)) {
        this.isLoad = false;
        this.alert.alertOpen("Not enough tracks. Please add more tracks!", 1);
        return false;
      }
      //this.sendCalc(this.profileStatements.length - 1,true, 0 );
      // var ps = this.profileStatements[this.focusedInput.index];
      // var str = 'cover=' + ps.cover;
      // if( this.focusedInput.num == 2 ){
      //   str = 'rating=' + ps.ratio;
      // }
      // this.profile_statementService.sendCalculating(ps.fp_id, this.tabs[this.selectedTabs.rightTabIndex].playlistInfo.id, str, false).subscribe((calc:any) => {
      //   if(calc.error != undefined){
      //     this.alert.alertOpen(calc.error,1);
      //   }
      //   else{
      //     if(calc.propoused_cover_seconds!=undefined){
      //       this.profileStatements[this.focusedInput.index].cover = calc.propoused_cover_seconds;
      //     }
      //     if(calc.propoused_rating_percents!=undefined){
      //       this.profileStatements[this.focusedInput.index].ratio = calc.propoused_rating_percents
      //     }
      var ps_str = this.getPSString(this.profileStatements);
      // this.profile_statementService.savePS(this.tabs[this.selectedTabs.rightTabIndex].playlistInfo.psr_id,ps_str,this.tabs[this.selectedTabs.rightTabIndex].playlistInfo.id,true).subscribe((ps:any) => {
      let cp_playlist_id = null;
      let reload = false;
      if ($rl) {
        cp_playlist_id = this.tabs[this.selectedTabs.rightTabIndex].playlistInfo.id;
        reload = true;
      }
      this.profile_statementService.savePS(this.tabs[this.selectedTabs.rightTabIndex].playlistInfo.psr_id, ps_str, cp_playlist_id, reload).subscribe((ps: any) => {
        this.isLoad = false;
        if (ps.error != undefined) {
          this.alert.alertOpen(ps.error, 1);
        }
        else {
          this.alert.alertOpen("Profile statement saved", 0);
          this.setTabsInfo(0, this.tabs[0].playlistInfo.id, 'cp');
          this.popupClose();
        }
      });
      //   }
      // });
    }
  
    /**
     Get total number of results all cover fields in profile statement popup
     * @returns {number} - total cover
     */
    getTotalCover() {
      var t = 0;
      for (var i = 0; i < this.profileStatements.length; i++) {
        var n = parseFloat(this.profileStatements[i].cover);
        if (!isNaN(n) && typeof n == 'number')
          t += n;
      }
      return t;
    }
  
    /**
     Get total number of results all ratio fields in profile statement popup
     * @returns {number}
     */
    getTotalRatio() {
      var t = 0;
      for (var i = 0; i < this.profileStatements.length; i++) {
        var n = parseInt(this.profileStatements[i].ratio);
        if (!isNaN(n) && typeof n == 'number')
          t += n;
      }
      return t;
    }
  
    /**
     Check input focus in profile statement popup
     * @param index - row index
     * @param num - number of input 1=cover input, 2=ration input
     */
    updateInputs(index, num) {
      this.focusedInput = {
        index: index,
        num: num
      };
    }
  
    /**
     Get string for updating on server
     * @param arr - array of profile statements
     * @returns {string} - parsed string
     */
    getPSString(arr) {
  
      var str = '';
      for (var i = 0; i < arr.length; i++) {
        if (arr[i].fp_id != null) {
          if (i > 0) {
            str += '&'
          }
          str += 'fp_id[' + i + ']=' + arr[i].fp_id + '&cover[' + i + ']=' + arr[i].cover + '&rating[' + i + ']=' + arr[i].ratio + '&notes[' + i + ']=' + arr[i].note;
          if (arr[i].id != null) {
            str += '&id[' + i + ']=' + arr[i].id;
          }
          else {
            str += '&id[' + i + ']=undefined';
          }
          str += '&availableTracks[' + i + ']=' + arr[i].availableTracks;
          str += '&trackIDs[' + arr[i].fp_id + ']=' + arr[i].trackIDs;
          str += '&futureCoverage[' + i + ']=' + arr[i].futureCoverage;
          str += '&smartFiltersPS[' + i + ']=' + JSON.stringify(arr[i].smartFiltersPS);
        }
      }
      str += '&percentageToUpdate=' + this.percentage_to_update;
      str += '&playlist_duration=' + this.playlist_duration_ps;
      return str;
    }
  
    /**
     Parse time
     * @param time - time in seconds
     * @returns {string} - parsed time
     */
     convertTime(time){
      var str = '';
      if (!isNaN(time)) {
        var hours = Math.floor(time / 3600);
        var minutes = Math.floor((time % 3600) / 60);
        var seconds = Math.floor(time % 60);
  
        // Ensure two digits for hours, minutes, and seconds
        str += hours.toString().padStart(2, '0') + ':';
        str += minutes.toString().padStart(2, '0') + ':';
        str += seconds.toString().padStart(2, '0');
      } else {
          str = '00:00:00';
      }
      return str;
    }
  
    /**
     Get total time in seconds for selected tracks
     * @returns {number} - time in seconds
     */
    getSelectedTime() {
      var time = 0;
      for (var i = 0; i < this.tabs[this.selectedTabs.rightTabIndex].selectedRows.length; i++) {
        var index = this.getPlaylistTrackInfoById(this.tabs[this.selectedTabs.rightTabIndex].selectedRows[i]);
        time += parseInt(this.tabs[this.selectedTabs.rightTabIndex].tracks[index].length_sec);
      }
      return time;
    }
  
    /**
     Get track position in array
     * @param id - track id
     * @returns {number} - track position
     */
    getPlaylistTrackInfoById(id) {
      for (var i = 0; i < this.tabs[this.selectedTabs.rightTabIndex].tracks.length; i++) {
        if (this.tabs[this.selectedTabs.rightTabIndex].tracks[i].pivot_id == id) {
          return i;
        }
      }
    }
  
    /**
     Open dropdowns with list of playlists
     * @param index - type of playlist 1=client playlists, 2= foundation playlists
     */
    openDrop(index) {
      if (index == 1) {
        this.drops.clients = !this.drops.clients;
        this.drops.fp = false;
      }
      else if (index == 2) {
        this.drops.fp = !this.drops.fp;
        this.drops.clients = false;
      }
    }
  
    /**
     Select category in profile statement popup
     * @param cid - category id
     * @param index - profile statement index
     */
    psSelectCategory(cid, index) {
      this.psSelects[index].catId = cid;
      this.psSelects[index].fp_arr = [];
    }
    /**
     Select sub category in profile statement popup
     * @param scid - sub category id
     * @param index - profile statement index
     */
    psSelectSubcategory(scid, index) {
      this.psSelects[index].subcatId = scid;
      this.fpService.getPlaylists(scid).subscribe((playlists: any) => {
        this.psSelects[index].fp_arr = playlists;
      });
    }
  
    /**
     Select playlist in profile statement popup
     * @param pid - playlist id
     * @param index - profile statement index
     * @param title - playlist title
     */
    psSelectPlaylist(pid, index, title) {
      this.psSelects[index].fp_id = pid;
      this.profileStatements[index].fp_id = pid;
      this.psSelects[index].title = title;
      this.psSelects[index].dropOpened = false;
      this.checkFPDublicates(index);
    }
  
    /**
     Open dropdown with categories/sub categories and playlist in profile statemtnt popup
     * @param index - profile statement row index
     */
    psOpenDrop(index) {
      for (var i = 0; i < this.psSelects.length; i++) {
        if (index != i)
          this.psSelects[i].dropOpened = false;
      }
      this.psSelects[index].dropOpened = !this.psSelects[index].dropOpened;
    }
  
    /**
     Update profile statement select
     * @param index - profile statement row index
     */
    setPSSelects(index) {
      this.fpService.getPlaylistById(this.psSelects[index].fp_id).subscribe((playlist: any) => {
        this.psSelects[index].title = playlist.name;
        this.psSelects[index].catId = playlist.parent_node.id;
        this.psSelects[index].subcatId = playlist.foundation_category_id;
        this.psSelectSubcategory(this.psSelects[index].subcatId, index);
      });
    }
  
    /**
     Click outside element
     * @param $event
     */
    @HostListener('document:click', ['$event']) clickedOutside($event) {
      // Do nothing
    }
  
    /**
     Click inside element
     * @param e - event
     */
    clickInside(e) {
      e.preventDefault();
      e.stopPropagation();
    }
  
    /**
     Open playlists dropdown
     * @param e - event
     * @param hover - hover on dropdown
     */
    openPlaylists(e, hover) {
      if (!this.openPlaylistDrop) {
  
        this.getPlaylistInfo(0, null);
  
  
        this.getFPPlaylistInfo(0, null);
      }
      if (hover) {
        this.openPlaylistDrop = false;
  
      }
      else {
        this.openPlaylistDrop = !this.openPlaylistDrop;
      }
    }
  
    identify(index, item) {
      return item.id;
    }
  
    /**
     Get lists of clients/sites/channels/client playlists
     * @param type - type of action 0-get sample clients list, 1-get sites,2-get channels,3-get playlists,4-open selected playlist
     * @param id - id of client/site/channel/client playlist
     */
    getPlaylistInfo(type, id, index = -1) {
      this.loadingCPSearch = true;
      if (type == 0) {
        this.showPlaylists = false;
        this.fpsamplePlaylistService.getClientsList().subscribe((clients: any) => {
          this.openPlaylist.users.usersArr = clients;
          this.loadingCPSearch = false;
        });
      }
      else if (type == 1) {
        this.showPlaylists = false;
        if (this.openPlaylist.users.id == id) {
          this.openPlaylist.users.id = null
          this.loadingCPSearch = false;
          return;
        }
        this.fpsamplePlaylistService.getChannelsByuser(id).subscribe((sites: any) => {
          this.openPlaylist.sites.sitesArr = sites;
          this.openPlaylist.users.id = id;
          this.loadingCPSearch = false;
  
        });
      }
      else if (type == 2) {
        this.showPlaylists = false;
        if (this.openPlaylist.sites.id == id){
          this.openPlaylist.sites.id = null
          this.loadingCPSearch = false;
          return;
      }
      this.fpsamplePlaylistService.getPlaylists(id).subscribe((playlist: any) => {
        this.openPlaylist.sites.id = id;
        this.openPlaylist.playlists.playlistsArr = playlist;
        this.loadingCPSearch = false;
      });
      }
      // else if (type == 3) {
      //   this.showPlaylists = true;
      //   if (this.openPlaylist.sites.id == id) {
      //     this.openPlaylist.sites.id = null
      //     this.loadingCPSearch = false;
      //     return;
      //   }
      //   this.cpService.getPlaylists(id).subscribe((playlist: any) => {
      //     this.openPlaylist.sites.id = id;
      //     this.openPlaylist.playlists.playlistsArr = playlist;
      //     this.loadingCPSearch = false;
      //     // }
      //   });
      // }
      else if (type == 4) {
        this.loadingCPSearch = false;
        if (this.openPlaylistDrop) {
          this.openPlaylistDrop = false;
          var newtab = {
            selected: true,
            category_parent: null,
            tracks: [],
            playlistInfo: null,
            averageBpm: 0,
            selectedRows: [],
            order: 'ASC',
            orderBy: 'add_date',
            tab_str: ''
          };

          this.tabs.push(newtab);
          this.setTabsInfo(this.tabs.length - 1, id, 'sfp');
          this.deselectTab(this.tabs.length - 1);
        }
        else if (this.openPlaylistDrop2) {
          this.openPlaylistDrop2 = false;
          this.addTab(id);
        }
        this.showPlaylists = false;
      }
    }
    /**
     Get lists of categories/sub categories/foundation playlists
     * @param type - type of action 0-get categories, 1-get sub categories,2-get playlists,3-open selected playlist
     * @param id - id of category/sub category/foundation playlist
     */
    getFPPlaylistInfo(type, id) {
      if (type == 0) {
        this.fp_categoriesService.getPlaylistsCategories('').subscribe((cats: any) => {
          this.openFPPlaylist.category.catsArr = cats;
        });
      }
      else if (type == 1) {
        if (this.openFPPlaylist.category.id == id)
          this.openFPPlaylist.category.id = null;
        else
          this.openFPPlaylist.category.id = id;
      }
      else if (type == 2) {
        this.fpService.getPlaylists(id).subscribe((playlist: any) => {
          if (this.openFPPlaylist.subcategory.id == id) {
            this.openFPPlaylist.subcategory.id = null;
          }
          else {
            this.openFPPlaylist.playlists.playlistsArr = playlist;
            this.openFPPlaylist.subcategory.id = id;
          }
        });
      }
      else if (type == 3) {
        if (this.openPlaylistDrop) {
          this.openPlaylistDrop = false;
          var newtab = {
            selected: true,
            category_parent: null,
            tracks: [],
            playlistInfo: null,
            averageBpm: 0,
            selectedRows: [],
            order: 'ASC',
            orderBy: 'add_date',
            tab_str: '',
            type: 'fp'
          };
          this.tabs.push(newtab);
          this.setFPTabsInfo(this.tabs.length - 1, id);
          this.deselectTab(this.tabs.length - 1);
        }
      }
    }
  
    /**
     Set order for tracks in playlist
     * @param order - order rule. List of possible values [ 'title', 'artist', 'album', 'add_date', 'bpm']
     */
    setOrder(order) {
      if (this.tabs[this.selectedTabs.rightTabIndex].orderBy == order) {
        if (this.tabs[this.selectedTabs.rightTabIndex].order == 'DESC')
          this.tabs[this.selectedTabs.rightTabIndex].order = 'ASC';
        else
          this.tabs[this.selectedTabs.rightTabIndex].order = 'DESC';
      }
      else {
        this.tabs[this.selectedTabs.rightTabIndex].orderBy = order;
      }
  
      if (this.searchStr != '')
        this.search();
      else
        this.updateTabs();
    }
    setOrderET(order) {
      // Do nothing
    }
    /**
     Set order for tracks library
     * @param order - order rule. List of possible values [ 'title', 'artist', 'album', 'add_date', 'bpm']
     */
    setAllTracksOrder(order) {
      var index = this.selectedTabs.leftTabIndex;
      if (this.leftTabs[index].orderBy == order) {
        if (this.leftTabs[index].order == 'DESC')
          this.leftTabs[index].order = 'ASC';
        else
          this.leftTabs[index].order = 'DESC';
      }
      else {
        this.leftTabs[index].orderBy = order;
      }
      if (index == 0) {
        this.infiniteScroll.offsetItems = 0;
        if (this.searchStr == '' && !this.isSmartSearch)
          this.getAllTracks(this.tabs[0].playlistInfo.id);
        else if (this.isSmartSearch)
          this.smartSearch(false);
        else
          this.search();
      }
      else {
        this.infiniteScroll.offsetItems = 0;
        if (this.searchStr == '' && !this.isSmartSearch)
          this.search();
        else if (this.isSmartSearch)
          this.smartSearch(false);
        else
          this.selectFpPlaylist(2, this.leftTabs[1].playlistId, this.leftTabs[1].title);
      }
    }
  
    /**
     Confirm action in confirmation popup
     * @param e - event
     */
    isConfirm(e) {
      if (e) {
        if (this.confirmValues.type == 0)
          this.removeTrack(this.confirmValues.id);
        else if (this.confirmValues.type == 1)
          this.removeTracks();
        else if (this.confirmValues.type == 2)
          this.deleteTrack(this.confirmValues.id);
        else if (this.confirmValues.type == 3)
          this.saveProfileStatement();
        else if (this.confirmValues.type == 4)
          this.retain();
        else if (this.confirmValues.type == 5)
          this.removeRetain();
        else if (this.confirmValues.type == 6)
          this.suspendCreateNew();
        else if (this.confirmValues.type == 7)
          this.setAutoGenerate(this.confirmValues.id);
        else if (this.confirmValues.type == 8)
          this.setAutoBuild(this.confirmValues.id);
        else if (this.confirmValues.type == 9)
          this.exportPlaylist(this.confirmValues.id);
        else if (this.confirmValues.type == 10)
          this.setFestive(this.confirmValues.id);
      }
    }
  
    /**
     Open confirmation popup
     * @param id - id of action
     * @param t - text for popup
     * @returns {boolean}
     */
    openConfirmPopup(id, t) {
      var text = "Do you want delete track from this playlist?";
      if (t == 1)
        text = "Do you want delete tracks from this playlist?";
      if (t == 2)
        text = "Do you want delete tracks from this playlist?";
      if (t == 3)
        text = "Do you want to delete all the tracks from this playlist and load tracks based on profile statement?";
      if (t == 4) {
        let tracks = this.tabs[this.selectedTabs.rightTabIndex].selectedRows;
        if (tracks.length == 0) {
          this.isLoad = false;
          this.alert.alertOpen("Please select tracks to retain!", 1);
          return;
        }
        text = "Do you want to retain the selected tracks?";
      }
  
      if (t == 5) {
        let tracks = this.tabs[this.selectedTabs.rightTabIndex].selectedRows;
        if (tracks.length == 0) {
          this.isLoad = false;
          this.alert.alertOpen("Please select tracks to remove retain!", 1);
          return;
        }
        text = "Do you want to remove retain for the selected tracks?";
      }
  
      if (t == 6)
        text = "Do you want to suspend the current playlist and create new playlist based on profile statement?";
  
      if (t == 7)
        text = "Do you want to enable/disable auto generation and distribution of updates?";
  
      if (t == 8)
        text = "Do you want to enable/disable auto build of updates?";
  
      if (t == 9)
        text = "Do you want to export the current playlist?";
  
      if (t == 10)
        text = "Do you want to set festive type for this playlist?";
  
      this.confirmValues.id = id;
      this.confirmValues.type = t;
      this.confirm.confirmOpen(text);
      return false;
    }
  
    /**
     Focusing in autocomplete inputs
     * @param e
     */
    autoFocus(e) {
      if (e)
        this.focusInput();
      else
        this.blurInput();
    }
  
    /**
     Set param if input focused
     */
    focusInput() {
      this.isFocused = true;
    }
    /**
     Set param if input blured
     */
    blurInput() {
      this.isFocused = false;
    }
  
    /**
     Get information for next track in playlist or track library if opened track information edit popup
     * @returns {boolean}
     */
    getNextTrackInfo() {
      var pos;
      if (!this.popups.convertPlaylistPopupOpened) {
        if (this.tabs[this.selectedTabs.rightTabIndex].selectedRows.length > 0) {
          pos = this.getSelectedPosition(this.tabs[this.selectedTabs.rightTabIndex].tracks, this.tabs[this.selectedTabs.rightTabIndex].selectedRows[0]);
          if (pos < this.tabs[this.selectedTabs.rightTabIndex].tracks.length)
            this.showEdit(this.tabs[this.selectedTabs.rightTabIndex].tracks[pos + 1].id, this.tabs[this.selectedTabs.rightTabIndex].tracks[pos + 1].pivot_id, 1);
        }
        else {
          pos = this.getSelectedPosition(this.leftTabs[this.selectedTabs.leftTabIndex].tracks, this.leftTabs[this.selectedTabs.leftTabIndex].selectedRows[0]);
          if (pos < this.leftTabs[this.selectedTabs.leftTabIndex].tracks.length)
            this.showEdit(this.leftTabs[this.selectedTabs.leftTabIndex].tracks[pos + 1].id, this.leftTabs[this.selectedTabs.leftTabIndex].tracks[pos + 1].pivot_id, 0);
        }
      }
      else {
        this.selectConverted(true);
      }
      return false;
    }
    /**
     Get information for previous track in playlist or track library if opened track information edit popup
     * @returns {boolean}
     */
    getPrevTrackInfo(e) {
      var pos;
      if (!this.popups.convertPlaylistPopupOpened) {
        if (this.tabs[this.selectedTabs.rightTabIndex].selectedRows.length > 0) {
          pos = this.getSelectedPosition(this.tabs[this.selectedTabs.rightTabIndex].tracks, this.tabs[this.selectedTabs.rightTabIndex].selectedRows[0]);
          if (pos > 0)
            this.showEdit(this.tabs[this.selectedTabs.rightTabIndex].tracks[pos - 1].id, this.tabs[this.selectedTabs.rightTabIndex].tracks[pos - 1].pivot_id, 1);
        }
        else {
          pos = this.getSelectedPosition(this.leftTabs[this.selectedTabs.leftTabIndex].tracks, this.leftTabs[this.selectedTabs.leftTabIndex].selectedRows[0]);
          if (pos > 0)
            this.showEdit(this.leftTabs[this.selectedTabs.leftTabIndex].tracks[pos - 1].id, this.leftTabs[this.selectedTabs.leftTabIndex].tracks[pos - 1].pivot_id, 0);
        }
      }
      else {
        this.selectConverted(false);
      }
      return false
    }
    selectConverted(next) {
      var pos;
      for (var i = 0; i < this.smartFilterResults.length; i++) {
        if (this.trackInfo.data.id == this.smartFilterResults[i].id) {
          if (next) {
            if (i < this.smartFilterResults.length)
              pos = i + 1;
            else
              pos = i;
          }
          else {
            if (i > 0)
              pos = i - 1;
            else
              pos = i;
          }
          this.showEdit(this.smartFilterResults[pos].id, null, 2);
        }
      }
    }
  
    /**
     Select playlist type in dropdowns
     * @param num - type of playlist 0-deselect playlists,1-client playlist,2-foundation playlist
     * @returns {boolean}
     */
    selectPlaylistType(num) {
      if (num == this.playlistType)
        this.playlistType = 0;
      else
        this.playlistType = num;
      return false;
    }
  
    /**
     Close tab in right side
     * @param index - tab index
     * @returns {boolean}
     */
    closeTab(index) {
      this.tabs.splice(index, 1);
      if (this.selectedTabs.rightTabIndex >= this.tabs.length)
        this.deselectTab(this.tabs.length - 1);
  
      return false;
    }
  
    /**
     Check smart filter field
     * @param sf - smart filter
     * @returns {boolean}
     */
    checkSmartField(sf) {
      if (sf.field == 'add_to_cp_playlist' && !sf.isHidden && this.selectedTabs.leftTabIndex == 0) {
        return true;
      }
      return false;
    }
  
  
  
    // History  profile pop up
    profileStatus: any;
    profileActive = [];
    profileActiveStatus: any;
    profileDeleteStatus: any;
    profileModifiedStatus: any;
    openProfileHistoryPopup() {
      this.popupClose();
  
      this.profile_statementService.getPSHistory(this.tabs[this.selectedTabs.rightTabIndex].playlistInfo.id).subscribe(history => {
        this.profileStatus = history;
        this.profileActiveStatus = this.profileStatus.profileActive;
        this.profileDeleteStatus = this.profileStatus.profileDelete;
        this.profileModifiedStatus = this.profileStatus.profileModified;
        this.popups.profileHistoryopened = true;
      });
    }
    // end
  
    profileUpdate(e, form: NgForm) {
      var str = Object.keys(form.value).map(function (key) {
        var val = encodeURIComponent(form.value[key]);
        if (val == 'null' || val == null) {
          val = '';
        }
        return encodeURIComponent(key) + '=' + val;
      }).join('&');
      if (this.profileStatementFields.name == '') {
        this.alert.alertOpen("Please enter profile Stament name", 1);
        return false;
      }
      if (this.tabs[this.selectedTabs.rightTabIndex].playlistInfo.id != null) {
        this.profile_statementService.createNewProfileStatement(this.tabs[this.selectedTabs.rightTabIndex].playlistInfo.id, str).subscribe((category: any) => {
          this.alert.alertOpen("Profile Statement Created", 0);
          this.ngOnInit();
          this.openProfilePopup();
        });
      }
      // else{
      //  // console.log(this.profileStatementFields.id);
      //   // this.profile_statementService.createNewProfileStatement(this.profileStatementFields.id, str).subscribe((category:any) => {
      //   // //  this.updateCategoriesList();
      //   //   this.alert.alertOpen("Profile Statement updated",0);
      //   // });
      // }
      this.popupClose();
    }
    // checkbox checked functionality starts //
    onClickCPCheckbox(cpmf_id, isChecked: boolean, wrap: HTMLElement, tabIndex, trackIndex) {
  
      var str = 'excluded=' + Number(isChecked);
      this.fpsamplePlaylistService.updateExcluded(cpmf_id, str, this.playlistType).subscribe((res: any) => {
        // if(res.error){
        // 	this.alert.alertOpen(res.error,1);
        // }
        this.tabs[tabIndex].tracks[trackIndex].excluded_by = res['excluded_by'];
        this.tabs[tabIndex].tracks[trackIndex].excluded_at = res['excluded_at'];
        if (isChecked === true) {
          this.tabs[tabIndex].tracks[trackIndex].excluded = 1;
          let et = this.tabs[tabIndex].tracks[trackIndex];
          et.playlist_name = this.tabs[tabIndex].playlistInfo.name;
          let type = 'Sample Foundation Playlist';
          if (this.playlistType == 2) {
            type = 'Foundation Playlist';
          }
          et.playlist_type = type;
          this.excludedTracks.push(et);
          wrap.classList.add('bg-change');
        } else {
          this.excludedTracks = this.excludedTracks.filter(function (obj) {
            return obj.pivot_id !== cpmf_id;
          });
          this.tabs[tabIndex].tracks[trackIndex].excluded = 0;
          wrap.classList.remove('bg-change');
        }
        this.removeETDuplicates();
      });
    }
  
    onclickExcludedTracks() {
      this.showExcludedTracks = true;
    }
  
    removeETDuplicates() {
      this.excludedTracks = this.excludedTracks.filter((track, index, self) =>
        index === self.findIndex((t) => (
          t.id === track.id && t.playlist_name === track.playlist_name
        ))
      )
    }
    resetDisabledInputs() {
      this.disabledInputs.year = false;
      this.disabledInputs.album = false;
      this.disabledInputs.artist = false;
      this.disabledInputs.title = false;
    }
  
    getDuration() {
      let tabIndex = this.selectedTabs.leftTabIndex;
      let selectedDuration = 0;
  
      for (var i = 0; i < this.leftTabs[tabIndex].selectedRows.length; i++) {
        let data;
        if (tabIndex == 1) {
          if (this.smartFilters[1][0].categorySelect && this.smartFilters[1][0].compareSelect
            //&& this.smartFilters[1][0].field
            && this.filterClicked[1] && this.smartFilters[1].length > 0) {
            data = this.leftTabs[tabIndex].tracks.find(x => x.id == this.leftTabs[tabIndex].selectedRows[i]);
          }
          else {
            data = this.leftTabs[tabIndex].tracks.find(x => x.pivot_id == this.leftTabs[tabIndex].selectedRows[i]);
          }
        }
        if (tabIndex == 2) {
          if (this.smartFilters[1][0].categorySelect && this.smartFilters[1][0].compareSelect
            //&& this.smartFilters[1][0].field
            && this.filterClicked[1] && this.smartFilters[1].length > 0) {
            data = this.leftTabs[tabIndex].tracks.find(x => x.id == this.leftTabs[tabIndex].selectedRows[i]);
          }
          else {
            data = this.leftTabs[tabIndex].tracks.find(x => x.pivot_id == this.leftTabs[tabIndex].selectedRows[i]);
          }
        }
        else {
          data = this.leftTabs[tabIndex].tracks.find(x => x.id == this.leftTabs[tabIndex].selectedRows[i]);
        }
        if (tabIndex == 3) {
          if (this.smartFilters[1][0].categorySelect && this.smartFilters[1][0].compareSelect
            //&& this.smartFilters[1][0].field
            && this.filterClicked[1] && this.smartFilters[1].length > 0) {
            data = this.leftTabs[tabIndex].tracks.find(x => x.id == this.leftTabs[tabIndex].selectedRows[i]);
          }
          else {
            data = this.leftTabs[tabIndex].tracks.find(x => x.pivot_id == this.leftTabs[tabIndex].selectedRows[i]);
          }
        }
        else {
          data = this.leftTabs[tabIndex].tracks.find(x => x.id == this.leftTabs[tabIndex].selectedRows[i]);
        }
        if (tabIndex == 4) {
          if (this.smartFilters[1][0].categorySelect && this.smartFilters[1][0].compareSelect
            //&& this.smartFilters[1][0].field
            && this.filterClicked[1] && this.smartFilters[1].length > 0) {
            data = this.leftTabs[tabIndex].tracks.find(x => x.id == this.leftTabs[tabIndex].selectedRows[i]);
          }
          else {
            data = this.leftTabs[tabIndex].tracks.find(x => x.pivot_id == this.leftTabs[tabIndex].selectedRows[i]);
          }
        }
        else {
          data = this.leftTabs[tabIndex].tracks.find(x => x.id == this.leftTabs[tabIndex].selectedRows[i]);
        }
  
        if (data)
          selectedDuration += data.length_sec;
      }
  
      let totalDuration = 0;
      for (var i = 0; i < this.leftTabs[tabIndex].tracks.length; i++) {
        totalDuration += this.leftTabs[tabIndex].tracks[i].length_sec;
      }
      this.tracksCount[tabIndex].selectedDuration = this.convertTime(selectedDuration);
      this.tracksCount[tabIndex].totalDuration = this.convertTime(totalDuration);
  
  
    }
  
    getCpDuration() {
      let tabIndex = this.selectedTabs.rightTabIndex;
      let totalDuration = 0;
  
      for (var i = 0; i < this.tabs[tabIndex].tracks.length; i++) {
        totalDuration += this.tabs[tabIndex].tracks[i].length_sec;
      }
      if (this.tabs[tabIndex].playlistInfo)
        this.tabs[tabIndex].playlistInfo.total_track_duration = totalDuration
    }
  
    searchPressFPDropdown(e) {
      this.loadingFPSearch = true;
      clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(() => {
        this.onSearchChange(e);
        // else
        //   this.clearSearch(e);
      }, 1000);
    }
  
    onSearchChange(e) {
      this.loadingFPSearch = true;
      this.filteredParentIDs = [];
      this.filteredChildIDs = [];
      if (this.searchStrFP == '') {
        this.loadingFPSearch = false;
        return false;
      }
  
      this.fpService.searchPlaylistsFP(this.searchStrFP).subscribe((c_ids: any) => {
        this.loadingFPSearch = false;
        if (c_ids) {
          this.filteredParentIDs = c_ids.parent_ids;
          this.filteredChildIDs = c_ids.child_ids;
        }
      });
    }
  
    searchPressFPSampleDropdown(e) {
      this.loadingFPSampleSearch = true;
      clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(() => {
        this.onSearchChangeFPS(e);
        // else
        //   this.clearSearch(e);
      }, 1000);
    }
  
    onSearchChangeFPS(e) {
      this.loadingFPSampleSearch = true;
      this.filteredParentIDsFPS = [];
      this.filteredChildIDsFPS = [];
      if (this.searchStrFPsample == '') {
        this.loadingFPSampleSearch = false;
        return false;
      }
  
      this.fpsamplePlaylistService.searchPlaylistsFP(this.searchStrFPsample).subscribe((c_ids: any) => {
        this.loadingFPSampleSearch = false;
        if (c_ids) {
          this.filteredParentIDsFPS = c_ids.parent_ids;
          this.filteredChildIDsFPS = c_ids.child_ids;
        }
      });
  
    }
  
  
    searchPressCPSampleDropdown(e) {
      this.loadingCPSampleSearch = true;
      clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(() => {
        this.onSearchChangeCPS(e);
        // else
        //   this.clearSearch(e);
      }, 1000);
    }
  
    onSearchChangeCPS(e) {
      this.loadingCPSampleSearch = true;
      this.filteredParentIDsCPS = [];
      this.filteredChildIDsCPS = [];
      if (this.searchStrCPsample == '') {
        this.loadingCPSampleSearch = false;
        return false;
      }
  
      this.clientSamplePlaylist.searchPlaylistsFP(this.searchStrCPsample).subscribe((c_ids: any) => {
        this.loadingCPSampleSearch = false;
        if (c_ids) {
          this.filteredParentIDsCPS = c_ids.parent_ids;
          this.filteredChildIDsCPS = c_ids.child_ids;
        }
      });
  
    }
  
    searchPressCPDropdown(e) {
      this.loadingCPSearch = true;
      clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(()=>{
      this.onSearchChangeCP(e);
      }, 1000);
    }
  
    onSearchChangeCP(e) {
      this.loadingCPSearch = true;
      this.filteredParentIDsCP = [];
      this.filteredChildIDsCP = [];
      this.filteredClientIDsCP =[];
      if (this.searchStrCP == '') {
        this.loadingCPSearch = false;
        return false;
      }
  
      this.fpService.searchPlaylistsCP(this.searchStrCP).subscribe((c_ids: any) => {
        this.loadingCPSearch = false;
        if (c_ids) {
          this.filteredParentIDsCP = c_ids.parent_ids;
          this.filteredChildIDsCP = c_ids.child_ids;
          this.filteredClientIDsCP = c_ids.client_ids;
        }

      });
    }
  
    clearSearchFP() {
      this.searchStrFP = '';
      this.fp_categoriesService.getPlaylistsCategories('').subscribe((cats: any) => {
        this.fpList.category.catsArr = cats;
        this.fpList.category.catsArrRaw = cats;
      });
      return false;
    }
  
    clearSearchFPSample() {
      this.searchStrFPsample = '';
      this.fpsamplePlaylistService.getPlaylistsCategories().subscribe((cats: any) => {
        this.fpsampleList.category.catsArr = cats;
        this.fpsampleList.category.catsArrRaw = cats;
      });
      return false;
    }
  
    clearSearchCPSample() {
      this.searchStrCPsample = '';
      this.clientSamplePlaylist.getPlaylistsCategories().subscribe((cats: any) => {
        this.cpsampleList.category.catsArr = cats;
        this.cpsampleList.category.catsArrRaw = cats;
      });
      return false;
    }
  
    clearSearchCP() {
      this.searchStrCP = '';
      this.cpService.getclientPlaylistsCategories().subscribe((cats: any) => {
        this.cpList.client.clientArr = cats;
        this.cpList.client.clientArrRaw = cats;
      });
      return false;
    }
  
    retain() {
      let tracks = this.tabs[this.selectedTabs.rightTabIndex].selectedRows;
      // tracks = tracks.join(", ")
      if (tracks.length == 0) {
        this.isLoad = false;
        this.alert.alertOpen("Please select tracks to retain!", 1);
        return false;
      }
      this.fpsamplePlaylistService.retain(tracks).subscribe((res: any) => {
  
        this.setTabsInfo(0, this.tabs[0].playlistInfo.id, 'sfp');
  
      });
    }
  
    removeRetain() {
      let tracks = this.tabs[this.selectedTabs.rightTabIndex].selectedRows;
      if (tracks.length == 0) {
        this.isLoad = false;
        this.alert.alertOpen("Please select tracks to remove retain!", 1);
        return false;
      }
      this.fpsamplePlaylistService.removeRetain(tracks).subscribe((res: any) => {
  
        this.setTabsInfo(0, this.tabs[0].playlistInfo.id, 'sfp');
      });
    }
  
    openInfoPopup() {
      let total_count = this.tabs[this.selectedTabs.rightTabIndex].tracks.length;
      var res = {};
      this.tabs[this.selectedTabs.rightTabIndex].tracks.forEach(function (v) {
        res[v.mc_tags] = (res[v.mc_tags] || 0) + 1;
      });
      for (var property in res) {
        if (res.hasOwnProperty(property)) {
          res[property] = ((res[property] / total_count) * 100).toFixed(2);
        }
      }
      this.playlist_report = res;
      this.playlist_report_dialog = !this.playlist_report_dialog;
    }
  
    popupCloseRD() {
      this.playlist_report_dialog = false;
      return false;
    }
  
    exportPlaylist(id) {
      const newArray = this.tabs[this.selectedTabs.rightTabIndex].tracks.map(({
        id,
        file_name,
        track_category_id,
        artist_id,
        album_id,
        genre_id,
        publisher_id,
        mood_id,
        composer_id,
        conductor_id,
        deleted_at,
        restored_at,
        storage,
        duplicate_check,
        file_name_altered,
        pivot_id,
        ...item
      }) => item);
      this.excelService.exportAsExcelFile(newArray, this.tabs[this.selectedTabs.rightTabIndex].playlistInfo.name);
    }
  
  }
  
  function esleif(selected: any) {
    throw new Error('Function not implemented.');
  }