import {
  Component,
  ElementRef,
  HostListener,
  Injectable,
  OnInit,
  ViewChild,
  OnDestroy,
  // ,ViewEncapsulation
} from "@angular/core";
import { TracksService } from "../services/tracks.service";
import { NgForm } from "@angular/forms";
import { AutocompleteService } from "../input-autocomplete/autocomplete.service";
import { SmartFilterService } from "../services/smart-filter.service";
import { IMyDpOptions } from "mydatepicker";
import { PlayerComponentComponent } from "../player-component/player-component.component";
import { CommonService } from "../services/common.service";
import { AlertComponent } from "../alert/alert.component";
import { ConfirmComponent } from "../confirm/confirm.component";
import { Globals } from "../globals"; 
import { ExcelService } from "../services/excel.service";
import { CPPlaylistsService } from "../services/cp_playlists.service";



@Component({
  selector: "app-admin-functions",
  templateUrl: "./admin-functions.component.html",
  styleUrls: ["./admin-functions.component.scss"],
  providers: [
    TracksService,
    AutocompleteService,
    SmartFilterService,
    CommonService,
    ExcelService,
    CPPlaylistsService,
  ],
  //encapsulation: ViewEncapsulation.None
})
@Injectable()
export class AdminFunctionsComponent implements OnInit, OnDestroy {
  tracks = []; //Tracks list
  trackInfo; //Track information
  popupOpened = false; //Open popup for edit track information
  smartFilterPopupOpened = false; //Open smart filter popup
  mcPopupOpened = false;
  mcFormPopupOpened = false;
  mcPage: number = 1;
  tracksAndChannelspage: number = 1;
  additional = []; // Array of additional attributes for information of track
  attrVal = ""; //Additional attribute field value
  defaultSmartFilters: any = null; // Array of default smart filters
  /**
   Selected smart filters values
   {
     categorySelect - ID of selected smart filter category,
     compare - array of compare values,
     compareSelect  - selected compare value,
     field - smart filter value,
     arrFields - array of smart filter values(if selected type of smart filter = 'date'),
     type - smart filter type. List of possible values ['date', 'int','str'](default 'str'),
     isHidden - is smart filter available for change
   }
   **/
  smartFilters: any = [
    {
      categorySelect: null,
      compare: [],
      compareSelect: null,
      field: null,
      arrFields: ["", ""],
      type: "str",
      isHidden: false,
    },
  ];
  matchSelect = "all"; //Value for smart filter match of following rules
  andOrSelect = "and";
  selectedRows = []; //array of selected rows in table
  searchStr = ""; //Value of search input
  public myDatePickerOptions: IMyDpOptions = {
    dateFormat: "dd/mm/yyyy",
    editableDateField: false,
    inline: false,
    height: "30px",
  }; //params for Date picker module
  /**
   Player information
   {
     isPlaying(string) - player status. List of possible values ['stop', 'play','pause'](default 'stop'),
     trackIndex(integer) - index of playling track in playlist(default null),
     trackId(integer) - playling track id(default null),
     filename(string) - file name of playing track(default null),
     artist(string) - playling track artist(default ''),
     album(string) - playling track album(default ''),
     storage(string) - storage of playling track(default ''),
     token(string) - access token for logged user(default '')
   }
   **/
  playerInfo = {
    isPlaying: "stop",
    trackIndex: null,
    trackId: null,
    filename: null,
    album: "",
    artist: "",
    storage: "",
    token: "",
  };
  innerWidth = 0; //Window width
  /**
   Popups list. Params for opening/closing popups
   **/
  popups = {
    bulkPopupOpened: false,
  };
  /**
   Array for bulk updates
   {
      name - parameter name,
      value - parameter value,
      check - checkbox value
    }
   */
  bulkInfo: any = [
    {
      name: "contribute_artist",
      value: "",
      check: false,
    },
    {
      name: "album",
      value: "",
      check: false,
    },
    {
      name: "genre",
      value: "",
      check: false,
    },
    {
      name: "composer",
      value: "",
      check: false,
    },
    {
      name: "mood",
      value: "",
      check: false,
    },
    {
      name: "publisher",
      value: "",
      check: false,
    },
    {
      name: "comments",
      value: "",
      check: false,
    },
    {
      name: "artist",
      value: "",
      check: false,
    },
    {
      name: "year",
      value: "",
      check: false,
    },
    {
      name: "track_category",
      value: "",
      check: false,
    },
    {
      name: "conductor",
      value: "",
      check: false,
    },
    {
      name: "bpm",
      value: "",
      check: false,
    },
    {
      name: "copyright",
      value: "",
      check: false,
    },
    {
      name: "start",
      value: "",
      check: false,
    },
    {
      name: "finish",
      value: "",
      check: false,
    },
    {
      name: "highestchartpos",
      value: "",
      check: false,
    },
    {
      name: "mc_tags",
      value: "",
      check: false,
    },
  ];

  qualityoptions = [
    { value: 'H', label: 'High' },
    { value: 'L', label: 'Low' }
  ];
  cliptypeoptions = [
    { value: 'A', label: 'Audio' },
    { value: 'V', label: 'Video' }
  ];
  selectedqualityOption: string; 
  selectedclipTypeOption:string;
  selectedmusicGenre1:any;
  selectedmusicGenre2:any;
  selectedmusicGenre3:any;
  hiddenset:any;

  musicFileDetails ={
    explicit:0,
    hard_in_point:0,
    hard_out_point:0,
  }

  isFocused = false; // Checking of inputs focus
  @ViewChild(PlayerComponentComponent, { static: true })
  player: PlayerComponentComponent; //Component of player
  @ViewChild(AlertComponent, { static: true }) alert: AlertComponent; //Component of alert popup
  @ViewChild('confirm') confirm: ConfirmComponent; //Component of confirm popup
  @ViewChild('confirmAll') confirmAll: ConfirmComponent; //Component of confirm popup
  @ViewChild('finalConfirm') finalConfirm: ConfirmComponent; //Component of final confirm popup after channel list popclose 
  current_user = null; // information of logged user
  isLoad = false; //Display preloader
  /**
   Rules for tracks order
   {
    order - order of diplaying tracks(default 'ASC'),
    orderBy - ordering rule. List of possible values [ 'title', 'artist', 'album', 'add_date', 'bpm'](default 'add_date')
   }
   */
  tracksOrder = {
    order: "ASC",
    orderBy: "add_date",
  };
  /**
   Params for tracks scrolling
   Params={
      items(integer) - offet tracks number(default 0) ,
      addItems(integer) -  number tracks for upload(default 100)
    }
   **/
  infiniteScroll = {
    items: 0,
    addItems: 100,
  };
  /**
   Value for confirmation popup
   {
     id(integer) - ID of element for action(default null),
     type(integer) - type of function for action(default 1)
   }
   **/
  confirmValues = {
    id: null,
    type: 0,
  };
  /**
   Tracks count in Tracks library
   {
     total - total tracks count,
     display - displaying tracks count
   }
   **/
  tracksCount = {
    total: 0,
    display: 0,
  };
  isSmartSearch = false; //Smart filter searching
  searchTimeout = null; // Timeout for starting searching
  loading: boolean = false; //Display preloader
  mcTagsList = [];
  mcTags = [];
  mcTagsRaw = [];
  selectedMCTags = [];
  dropdownSettings = {
    singleSelection: false,
    idField: "item_id",
    textField: "item_text",
    selectAllText: "Select All",
    unSelectAllText: "UnSelect All",
    itemsShowLimit: 3,
    allowSearchFilter: true,
  };

  disabledInputs = {
    year: false,
    artist: false,
    album: false,
    title: false,
  };
  loadContent: boolean = false;
  drops = {
    clients: false,
    fp: false,
  }; // Open dropdowns with list of categories/playlists
  channelPopUp = false; //open popup for channels with selected tracks

  searchBulkUidPopUpOpen =false;
  searchUID: string = '';
  constructor(
    private tracksService: TracksService,
    private smartService: SmartFilterService,
    private commonService: CommonService,
    private elRef: ElementRef,
    private globals: Globals,
    private excelService: ExcelService,
    private cpplaylistService: CPPlaylistsService,
  ) { }

  ngOnInit() {
    this.commonService.getCurrentLoggedUser().subscribe((current_user) => {
      this.current_user = current_user;
    });
    this.getTracks(false);
    this.getMCTags();

    this.smartService.getFilters("").subscribe((filters) => {
      this.defaultSmartFilters = filters;
    });
  }

  openDrop(index) {
    if (index == 1) {
      this.drops.clients = !this.drops.clients;
      this.drops.fp = false;
    } else if (index == 2) {
      this.drops.fp = !this.drops.fp;
      this.drops.clients = false;
    }
  }

  /**
   Get all tracks
   * @param loaded - if true results will show from start
   */

  getTracks(loaded) {
    this.isLoad = true;
    var offset = this.infiniteScroll.items;
    var limit = this.infiniteScroll.addItems;
    this.isSmartSearch = false;
    if (loaded) {
      offset = 0;
      limit = this.infiniteScroll.items + this.infiniteScroll.addItems;
    }
    this.tracksService
      .getTracks(
        this.tracksOrder.order,
        this.tracksOrder.orderBy,
        offset,
        limit
      )
      .subscribe((tracks: any) => {
        this.isLoad = false;
        this.tracksCount.total = tracks.total;
        this.tracksCount.display = tracks.total;
        const arr2 = this.mapCheckedTrack(tracks.list);
        if (offset == 0) {
          this.tracks = arr2;
        } else {
          this.tracks = this.tracks.concat(arr2);
        }
      });
  }

  getMCTags() {
    this.tracksService.getMCTags().subscribe((tags: any) => {
      if (tags) {
        for (let key in tags) {
          if (tags.hasOwnProperty(key)) {
            this.mcTagsList.push({
              item_id: tags[key].name,
              item_text: tags[key].name,
            });
          }
        }
      }
      this.loadContent = true;
    });
  }
  onItemSelect(item: any) {
    console.log(item);
  }
  onSelectAll(items: any) {
    console.log(items);
  }

  ngOnDestroy() {
    localStorage.removeItem("smartFilters");
    if (this.playerInfo.isPlaying == "play") {
      this.player.pause();
    }
  }

  /**
   Value from additional attribute input
   * @param e
   */
  inputValue(e) {
    this.attrVal = e.target.value;
  }
  /**
   Get track information and show popup with track info
   * @param _e - event
   * @param id - track id
   * @returns {boolean}
   */
  showInfo(_e, id) {
    this.resetDisabledInputs();
    this.isLoad = true;
    this.tracksService.getTrackInfo(id).subscribe(trackInfo=>{
      this.isLoad =false;
      this.trackInfo = trackInfo;
      this.additional = [];
      this.selectedMCTags = [];
      for (const row of this.trackInfo.additional_attributes) {
        this.additional.push(row.name);
        this.selectedMCTags.push({
          item_id: row.name,
          item_text: row.name,
        });
      }
      if (this.trackInfo?.data?.music_file_details) {
        this.selectedmusicGenre1 = this.trackInfo.data.music_file_details.genre1 ?? -1;
        this.selectedmusicGenre2 = this.trackInfo.data.music_file_details.genre2 ?? -1;
        this.selectedmusicGenre3 = this.trackInfo.data.music_file_details.genre3 ?? -1;
        this.selectedclipTypeOption = this.trackInfo.data.music_file_details.clipType ?? 'A';
        this.selectedqualityOption = this.trackInfo.data.music_file_details.quality ?? 'H';
        this.hiddenset = this.trackInfo.data.music_file_details.hidden ?? 0;
        this.musicFileDetails.explicit=this.trackInfo.data.music_file_details.explicit ?? 0,
        this.musicFileDetails.hard_in_point=this.trackInfo.data.music_file_details.hard_in_point ?? 0,
        this.musicFileDetails.hard_out_point=this.trackInfo.data.music_file_details.hard_out_point ?? 0
    } else {
        this.selectedmusicGenre1 = -1;
        this.selectedmusicGenre2 = -1;
        this.selectedmusicGenre3 = -1;
        this.selectedclipTypeOption = 'A';
        this.selectedqualityOption = 'H';
        this.hiddenset = 0;
        this.musicFileDetails.explicit=  0,
        this.musicFileDetails.hard_in_point= 0,
        this.musicFileDetails.hard_out_point= 0
    }

      this.popupOpened = true;

      this.selectedRows[0] = id;
      if (this.playerInfo.isPlaying != "play") this.addPlayerInfo(id);
    });
  
    return false;
  }

  /**
   Add track info to player
   * @param id - track id in library or in playlist
   */
  addPlayerInfo(id) {
    var pos = this.getSelectedPosition(this.tracks, id);
    var t = this.tracks[pos];
    this.playerInfo.trackIndex = pos;
    this.playerInfo.trackId = t.id;
    this.playerInfo.filename = t.file_name;
    this.playerInfo.album = t.title + " - " + t.album;
    this.playerInfo.artist = t.artist;
    this.playerInfo.storage = t.storage;
    this.playerInfo.token = this.globals.access_token;
  }
  /**
   Close all popups
   * @param _e - event
   * @returns {boolean}
   */
  popupClose(e){
    this.trackInfo = null;
    this.popupOpened = false;
    this.smartFilterPopupOpened = false;
    this.popups.bulkPopupOpened = false;
    this.searchBulkUidPopUpOpen =false;
    if (this.playerInfo.trackId != null) {
      this.playerInfo.filename =
        this.tracks[this.playerInfo.trackIndex].file_name;
      this.playerInfo.filename =
        this.tracks[this.playerInfo.trackIndex].storage;
    }
    if (<HTMLInputElement>document.getElementById("additional")) {
      var input = <HTMLInputElement>document.getElementById("additional");
      input.value = "";
    }
    this.selectedqualityOption='';
    this.selectedclipTypeOption='';
    this.selectedmusicGenre1= -1;
    this.selectedmusicGenre3= -1;
    this.selectedmusicGenre2= -1;
    // this.isSmartSearch = false;
    this.blurInput();
    return false;
  }

  /**
   Update track information
   * @param _e - event
   * @param {NgForm} form - form
   */
  formDataUpdate(_e, form: NgForm) {
    console.log("form", form);
    form.value["title"] = form.controls["title"].value;
    form.value["album"] = form.controls["album"].value;
    form.value["artist"] = form.controls["artist"].value;
    form.value["year"] = form.controls["year"].value;   
    form.value["clipType"]=this.selectedclipTypeOption ?? "A";  //A->  Audio(default) and V->video 
    form.value["quality"]=this.selectedqualityOption ?? "H";  //H-> High(default) and L->Low
    form.value["genre1"]=this.selectedmusicGenre1 ;
    form.value["genre2"]=this.selectedmusicGenre2 ;
    form.value["genre3"]=this.selectedmusicGenre3 ;
    


    if (this.trackInfo.data.title == "" || this.trackInfo.data.title == null) {
      this.alert.alertOpen("Please enter track title", 1);
    } else if (form.value.artist == "" || form.value.artist == null) {
      this.alert.alertOpen("Please enter artist name", 1);
    } else {
      var str = Object.keys(form.value)
        .map(function (key) {
          var val = "";
          if (key == "mc_tags") {
            val = form.value[key].map((a) => encodeURIComponent(a.item_id));
          } else {
            val = encodeURIComponent(form.value[key]);
          }
          if (val == "null" || val == null) {
            val = "";
          }
          return encodeURIComponent(key) + "=" + val;
        })
        .join("&");

      this.tracksService.updateTrackInfo(str).subscribe((_dataInfo) => {
        if (this.searchStr == "" && !this.isSmartSearch) this.getTracks(true);
        else if (this.isSmartSearch) this.smartSearch(false, true);
        else this.search(true);
        this.alert.alertOpen("Info updated", 0);
      });
    }
  }
  /**

   Delete track
   * @param id - track id
   */
  deleteTrack(id) {
    this.tracksService.deleteTrack(id).subscribe((_trackDel) => {
      this.popupClose(window.event);
      this.alert.alertOpen("Track deleted", 0);
      this.getTracks(true);
    });
  }
  /**
   Add new additional attribute
   */
  newAttribute() {
    var input = <HTMLInputElement>document.getElementById("additional");
    if (input.value.trim() != "") this.additional.push(input.value);
    input.value = "";
  }
  /**
   Remove additional attribute
   * @param id - attribute id
   */
  removeAdditional(id) {
    this.additional.splice(id, 1);
  }
  /**
   Open popup with smart filters
   */
  openFilterPopup() {
    this.smartFilterPopupOpened = !this.smartFilterPopupOpened;
    this.focusInput();

    this.smartFilters = JSON.parse(localStorage.getItem("smartFilters"));
    if (this.smartFilters == "" || this.smartFilters == null) {
      this.smartFilters = [
        {
          categorySelect: null,
          compare: [],
          compareSelect: null,
          field: null,
          arrFields: ["", ""],
          type: "str",
          isHidden: false,
        },
      ];
    }
  }

  profileFilterCount = 0;
  /**
   Checking for changing select with smart filters
   * @param e - event
   * @param index - smart filter index
   */
  changeSmartSelect(e, index) {
    for (let row of this.defaultSmartFilters) {
      if (row.field == e.target.value) {
        this.smartFilters[index].compare = row.сompare_str;
        this.smartFilters[index].type = row.type;
        //if (e.target.value == 'cp_playlist_id')
        //this.addStaticFilterField('add_to_cp_playlist', index);
        //else
        //this.removeStaticFilterField(index);
      }
    }
    this.profileFilterCount = this.smartFilters.filter(
      (obj) => obj.categorySelect === "cp_playlist_id"
    ).length;
  }
  /**
   Add static smart filter field
   * @param name - smart filter title
   * @param index - smart filter index
   */
  addStaticFilterField(name, index) {
    for (var i = 0; i < this.defaultSmartFilters.length; i++) {
      if (this.defaultSmartFilters[i].field == name) {
        var o = {
          categorySelect: name,
          compare: this.defaultSmartFilters[i].сompare_str,
          compareSelect: null,
          field: null,
          arrFields: ["", ""],
          type: this.defaultSmartFilters[i].type,
          isHidden: true,
        };
        this.smartFilters.splice(index + 1, 0, o);
        break;
      }
    }
  }
  /**
   Add new smart filter field
   * @returns {boolean}
   */
  addFilterField(_e) {
    var o = {
      categorySelect: null,
      compare: [],
      compareSelect: null,
      field: null,
      arrFields: ["", ""],
      type: "str",
      isHidden: false,
    };
    this.smartFilters.push(o);
    return false;
  }
  /**
   Remove static smart filter field
   * @param index - smart filter index
   */
  removeStaticFilterField(index) {
    if (
      this.smartFilters[index + 1] &&
      this.smartFilters[index + 1].categorySelect == "add_to_cp_playlist"
    )
      this.smartFilters.splice(index + 1, 1);
  }
  /**
   Remove smart filter field
   * @param _e - event
   * @param index - smart filter index
   * @returns {boolean}
   */
  removeFilterField(_e, index) {
    var count = 1;
    var ind = index;
    if (
      this.smartFilters[index].categorySelect == "cp_playlist_id" ||
      this.smartFilters[index].categorySelect == "add_to_cp_playlist"
    )
      count = 2;
    if (this.smartFilters[index].categorySelect == "add_to_cp_playlist")
      ind = index - 1;
    this.smartFilters.splice(ind, count);
    if (this.smartFilters.length < 1) {
      var o = {
        categorySelect: null,
        compare: [],
        compareSelect: null,
        field: null,
        arrFields: ["", ""],
        type: "str",
        isHidden: false,
      };
      this.smartFilters[0] = o;
    }
    this.profileFilterCount = this.smartFilters.filter(
      (obj) => obj.categorySelect === "cp_playlist_id"
    ).length;
    return false;
  }
  /**
   Get search results from smart filters
   * @param clicked(boolean) - if true search results will show from start
   * @param loaded(boolean) - if true search results will show from start
   * @returns {boolean}
   */
  smartSearch(clicked, loaded) {
    var str = "";
    if (clicked) this.infiniteScroll.items = 0;
    var offset = this.infiniteScroll.items;
    var limit = this.infiniteScroll.addItems;
    this.isSmartSearch = true;
    if (loaded) {
      offset = 0;
      limit = this.infiniteScroll.items + this.infiniteScroll.addItems;
    }
    if (this.checkSmartFilters()) {
      localStorage.setItem("smartFilters", JSON.stringify(this.smartFilters));
      for (var i = 0; i < this.smartFilters.length; i++) {
        var el = this.smartFilters[i];
        if (el.categorySelect != null && el.compareSelect != null) {
          str += el.categorySelect + "[" + el.compareSelect + "]=";
          if (el.type == "str") {
            str += el.field;
          } else if (el.type == "int") {
            str += "[" + el.arrFields[0];
            if (el.compareSelect == "between") {
              str += "," + el.arrFields[1];
            }
            str += "]";
          } else if (el.type == "date") {
            str +=
              "['" +
              el.arrFields[0].formatted +
              "','" +
              el.arrFields[1].formatted +
              "']";
          }
          str += "&";
          if (el.categorySelect == "cp_playlist_id") {
            str +=
              "cp_playlist_name" +
              "[" +
              i +
              "]" +
              "[" +
              el.compareSelect +
              "]=";
            str += encodeURIComponent(el.field);
            str += "&";
          }
        } else {
          this.alert.alertOpen(
            "One or more search criteria is blank. Please fill them and search",
            1
          );
          return false;
        }
      }
      str += "match_type=" + this.matchSelect;
      str += "&and_or_type=" + this.andOrSelect;
      str +=
        "&orderBy=" +
        this.tracksOrder.orderBy +
        "&orderDirect=" +
        this.tracksOrder.order +
        "&offset=" +
        offset +
        "&limit=" +
        limit +
        "&filterPage=trackLibrary";

      this.smartService.getFilteredTracks(str).subscribe((tracks: any) => {
        if (tracks.result != "empty") {
          if (offset == 0) this.tracks = this.mapCheckedTrack(tracks.list);
          else
            this.tracks = this.tracks.concat(this.mapCheckedTrack(tracks.list));
          this.tracksCount.display = tracks.total;
        } else {
          if (offset == 0) {
            this.alert.alertOpen("No results", 0);
            this.isSmartSearch = false;
          }
        }
        this.smartFilterPopupOpened = false;
        this.blurInput();
      });
    } else {
      this.alert.alertOpen(
        "One or more search criteria is blank. Please fill them and search",
        1
      );
    }
  }
  /**
   Check smart filter for fill
   * @returns {boolean}
   */
  checkSmartFilters() {
    for (var i = 0; i < this.smartFilters.length; i++) {
      var el = this.smartFilters[i];
      if (el.categorySelect != null && el.compareSelect != null) {
        if (el.type == "str") {
          if (el.field != "") return true;
        } else if (el.type == "int" || el.type == "date") {
          if (el.arrFields[0] != "") return true;
        }
      }
    }
    return false;
  }
  /**
   Clear smart filter search results and get all track library
   * @param _e - event
   */
  clearFilter(_e) {
    this.profileFilterCount = 0;
    localStorage.removeItem("smartFilters");
    this.infiniteScroll.items = 0;
    this.tracksService
      .getTracks(
        this.tracksOrder.order,
        this.tracksOrder.orderBy,
        this.infiniteScroll.items,
        this.infiniteScroll.addItems
      )
      .subscribe((tracks: any) => {
        this.tracks = tracks.list;
        this.smartFilters = [
          {
            categorySelect: null,
            compare: [],
            compareSelect: null,
            field: null,
            arrFields: ["", ""],
            type: "str",
            isHidden: false,
          },
        ];
        this.tracksCount.total = tracks.total;
        this.tracksCount.display = tracks.total;
        this.isSmartSearch = false;
      });
    return false;
  }
  /**
   Sets information for player and start/pause track
   * @param e - event
   * @param file_id - file id from database
   * @param file_name - name of file
   * @param index - index of active tab
   * @param album - album name
   * @param artist - artist name
   * @param title - track title
   * @param storage - storage on server
   * @returns {boolean}
   **/
  playTrack(e, file_id, file_name, index, album, artist, storage) {
    e.preventDefault();
    if (
      this.playerInfo.isPlaying == "play" &&
      this.playerInfo.trackId == file_id &&
      this.playerInfo.trackIndex == index
    ) {
      this.player.pause();
    } else {
      this.playerInfo.filename = file_name;
      this.playerInfo.trackId = file_id;
      this.playerInfo.trackIndex = index;
      this.playerInfo.album = album;
      this.playerInfo.artist = artist;
      this.playerInfo.storage = storage;
      this.playerInfo.token = this.globals.access_token;
      this.player.start(this.playerInfo.trackId, true);
    }
  }
  /**
   Chegking tracks playing
   * @param e - event
   */
  checkPlaying(e) {
    this.playerInfo.isPlaying = e;
    if (this.playerInfo.isPlaying != "play") {
      this.addPlayerInfo(this.selectedRows[0]);
    }
  }
  /**
   Select row after click on it in left side
   * @param e - event
   * @param id - track id
   * @param index - row index
   */
  selectRow(e, id, index) {
    if (
      !this.popups.bulkPopupOpened &&
      !this.popupOpened &&
      !this.smartFilterPopupOpened
    ) {
      var selected = this.checkSelectedRow(this.selectedRows, id);
      if (e.ctrlKey || e.metaKey) {
        if (selected == -1) {
          this.selectedRows.push(id);
        } else {
          this.selectedRows.splice(selected, 1);
        }
      } else if (e.shiftKey) {
        if (this.selectedRows.length > 0) {
          this.checkStartRows(index);
        } else {
          this.selectedRows[0] = id;
        }
      } else {
        this.selectedRows = [];
        if (selected == -1) {
          this.selectedRows[0] = id;
          if (this.playerInfo.isPlaying != "play") {
            this.playerInfo.trackIndex = index;
            this.playerInfo.trackId = id;
            this.playerInfo.filename = this.tracks[index].file_name;
            this.playerInfo.artist = this.tracks[index].artist;
            this.playerInfo.album = this.tracks[index].album;
            this.playerInfo.storage = this.tracks[index].storage;
            this.playerInfo.token = this.globals.access_token;
          }
        } else {
          this.selectedRows.splice(0, 1);
        }
      }
    }
  }
  /**
   Check start and end of selected rows in tracks library
   * @param index - row index
   * @returns {Array | any[]}
   */
  checkStartRows(index) {
    for (var i = 0; i < index; i++) {
      for (var j = 0; j < this.selectedRows.length; j++) {
        if (this.tracks[i].id == this.selectedRows[j]) {
          this.selectedRows = [];
          this.setMultipleRows(i, index);
          return false;
        }
      }
    }
    for (var k = this.tracks.length - 1; k > index; k--) {
      for (var r = 0; r < this.selectedRows.length; r++) {
        if (this.tracks[k].id == this.selectedRows[r]) {
          this.selectedRows = [];
          this.setMultipleRows(k, index);
          return false;
        }
      }
    }
  }
  /**
   Select rows from start index to end index in playlist
   * @param start - start index
   * @param end - end index
   * @returns {any[]}
   */
  setMultipleRows(start, end) {
    var s = start;
    var e = end;
    if (start > end) {
      s = end;
      e = start;
    }
    for (var i = s; i <= e; i++) {
      this.selectedRows.push(this.tracks[i].id);
    }
  }
  /**
   Check selected row
   * @param arr - array of tracks
   * @param id - track id
   * @returns {boolean}
   */
  checkSelectedRow(arr, id) {
    for (var i = 0; i < arr.length; i++) {
      if (arr[i] == id) {
        return i;
      }
    }
    return -1;
  }
  /**
   Checking events on click keys on keyboard
   * @param {KeyboardEvent} event - event
   */
  @HostListener("document:keydown", ["$event"])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (this.checkOpenedPopups()) {
      if (event.key == "ArrowUp") {
        if (!this.popups.bulkPopupOpened) {
          event.preventDefault();
          this.setPosition(0);
        }
      } else if (event.key == "ArrowDown") {
        if (!this.popups.bulkPopupOpened) {
          event.preventDefault();
          this.setPosition(1);
        }
      }
    }
  }
  /**
   Set position after click key up/key down on keyboad
   * @param arrow(number) - arrow 0=key up, 1=key down
   */
  setPosition(arrow) {
    var arr = [];
    var el = false;
    if (this.selectedRows.length > 0) {
      var pos = this.getSelectedPosition(this.tracks, this.selectedRows[0]);
      if (arrow == 0) {
        if (pos > 0) {
          this.selectedRows = [];
          this.selectedRows[0] = this.tracks[pos - 1].id;
          if (this.playerInfo.isPlaying != "play") {
            this.playerInfo.trackIndex = pos - 1;
            this.playerInfo.trackId = this.tracks[pos - 1].id;
            this.playerInfo.filename = this.tracks[pos - 1].file_name;
            this.playerInfo.album = this.tracks[pos - 1].album;
            this.playerInfo.artist = this.tracks[pos - 1].artist;
            this.playerInfo.storage = this.tracks[pos - 1].storage;
            this.playerInfo.token = this.globals.access_token;
          }
        }
      } else if (arrow == 1) {
        if (pos + 1 != this.tracks.length) {
          this.selectedRows = [];
          this.selectedRows[0] = this.tracks[pos + 1].id;
          if (this.playerInfo.isPlaying != "play") {
            this.playerInfo.trackIndex = pos + 1;
            this.playerInfo.trackId = this.tracks[pos + 1].id;
            this.playerInfo.filename = this.tracks[pos + 1].file_name;
            this.playerInfo.album = this.tracks[pos + 1].album;
            this.playerInfo.artist = this.tracks[pos + 1].artist;
            this.playerInfo.storage = this.tracks[pos + 1].storage;
            this.playerInfo.token = this.globals.access_token;
          }
        }
      }
    } else {
      this.selectedRows[0] = this.tracks[0].id;
    }
    this.scrollToElement();
  }
  /**
   Get selected row index in tracks library or playlist
   * @param arr - array of tracks
   * @param id - track id
   * @returns {number} - row index
   */
  getSelectedPosition(arr, id) {
    for (var i = 0; i < arr.length; i++) {
      if (this.selectedRows.length > 0) {
        if (id == arr[i].id) {
          return i;
        }
      }
    }
  }
  /**
   If track row selected in tracks library, select and play next row
   * @param _e - event
   */
  nextTrack(_e) {
    if (this.tracks.length > this.playerInfo.trackIndex + 1) {
      this.playerInfo.trackIndex = this.playerInfo.trackIndex + 1;
      this.selectedRows[0] = this.tracks[this.playerInfo.trackIndex].id;
      this.playerInfo.trackId = this.tracks[this.playerInfo.trackIndex].id;
      this.playerInfo.filename =
        this.tracks[this.playerInfo.trackIndex].file_name;
      this.playerInfo.album = this.tracks[this.playerInfo.trackIndex].album;
      this.playerInfo.artist = this.tracks[this.playerInfo.trackIndex].artist;
      this.playerInfo.storage = this.tracks[this.playerInfo.trackIndex].storage;
      this.playerInfo.token = this.globals.access_token;
    }
    if (this.playerInfo.isPlaying == "play") {
      this.player.start(this.playerInfo.trackId, true);
    }
  }
  /**
   If track row selected in tracks library , select and play previous row
   * @param _e - event
   */
  prevTrack(_e) {
    if (this.playerInfo.trackIndex > 0) {
      this.playerInfo.trackIndex = this.playerInfo.trackIndex - 1;
      this.selectedRows[0] = this.tracks[this.playerInfo.trackIndex].id;
      this.playerInfo.trackId = this.tracks[this.playerInfo.trackIndex].id;
      this.playerInfo.filename =
        this.tracks[this.playerInfo.trackIndex].file_name;
      this.playerInfo.album = this.tracks[this.playerInfo.trackIndex].album;
      this.playerInfo.artist = this.tracks[this.playerInfo.trackIndex].artist;
      this.playerInfo.storage = this.tracks[this.playerInfo.trackIndex].storage;
      this.playerInfo.token = this.globals.access_token;
    }
    if (this.playerInfo.isPlaying == "play") {
      this.player.start(this.playerInfo.trackId, true);
    }
  }
  /**
   Checking if one of popup opened
   * @returns {boolean}
   */
  checkOpenedPopups() {
    if (this.popupOpened || this.smartFilterPopupOpened) return false;
    else return true;
  }
  /**
   Scroll window to element when user press up/down keys
   */
  scrollToElement() {
    var el = this.elRef.nativeElement.querySelector(".selected");
    if (el != null) {
      var parent = el.parentElement;

      if (
        el.offsetTop + el.offsetHeight * 2 - parent.offsetTop >
        parent.scrollTop + parent.clientHeight
      ) {
        el.parentElement.scrollTop =
          el.offsetTop + el.offsetHeight * 2 - parent.clientHeight + 20;
      }
      if (
        el.offsetTop + parent.offsetTop + el.offsetHeight * 2 <
        parent.scrollTop + parent.clientHeight
      ) {
        el.parentElement.scrollTop =
          el.offsetTop -
          parent.offsetTop -
          parent.clientHeight +
          el.offsetHeight * 2;
      }
    }
  }

  /**
   Checking entering query in search field
   * @param e - event
   */
  searchPress(e) {
    this.isSmartSearch = false;
    this.infiniteScroll.items = 0;
    clearTimeout(this.searchTimeout);
    this.searchTimeout = setTimeout(() => {
      this.isLoad = true;
      if (this.searchStr != "") this.search(false);
      else this.clearSearch(e);
    }, 1000);
  }
  /**
   Get search results
   * @param loaded(boolean) - if true search results will show from start
   */
  search(loaded) {
    this.isLoad = true;
    var offset = this.infiniteScroll.items;
    var limit = this.infiniteScroll.addItems;
    if (loaded) {
      offset = 0;
      limit = this.infiniteScroll.items + this.infiniteScroll.addItems;
    }
    this.tracksService
      .search(
        this.searchStr,
        this.tracksOrder.order,
        this.tracksOrder.orderBy,
        offset,
        limit
      )
      .subscribe((tracks: any) => {
        if (tracks.result == undefined && tracks.result != "No result") {
          this.tracksCount.display = tracks.total;
          const arr2 = this.mapCheckedTrack(tracks.list);
          if (offset == 0) this.tracks = arr2;
          else this.tracks = this.tracks.concat(arr2);
        } else {
          if (offset == 0) {
            this.tracksCount.display = 0;
            this.tracks = [];
          }
        }
        this.isLoad = false;
      });
  }
  /**
   Crear search results and get all tracks in playlist and tracks library
   * @returns {boolean}
   */
  clearSearch(_e) {
    this.searchStr = "";
    this.isSmartSearch = false;
    this.isLoad = true;
    this.infiniteScroll.items = 0;
    this.tracksService
      .getTracks(
        this.tracksOrder.order,
        this.tracksOrder.orderBy,
        this.infiniteScroll.items,
        this.infiniteScroll.addItems
      )
      .subscribe((tracks: any) => {
        this.tracks = this.mapCheckedTrack(tracks.list);
        this.tracksCount.total = tracks.total;
        this.tracksCount.display = tracks.total;
        this.isLoad = false;
      });
    return false;
  }

  /**
   Checking search field value
   * @param e - event
   */
  checkSearch(e) {
    if (this.searchStr == "") {
      this.clearSearch(e);
    }
    this.blurInput();
  }

  /**
   Open popup with bulk updates
   */
  openBulkPopup() {
    for (let row of this.bulkInfo) {
      row.value = "";
      row.check = false;
    }
    this.playerInfo.filename = null;
    this.popups.bulkPopupOpened = true;
    this.focusInput();
  }

  /**
   Bulk updates for selected tracks
   * @param {NgForm} form - form
   */
  bulkUpdate(form: NgForm) {
    if (this.selectedRows.length > 0) {
      var showed = false;
      for (var i = 0; i < this.selectedRows.length; i++) {
        try {
          var str = "";
          console.log("this.bulkInfo", this.bulkInfo);
          for (var j = 0; j < this.bulkInfo.length; j++) {
            if (this.bulkInfo[j].check) {
              if (str != '')
                str += '&'
              var val = '';
              if(this.bulkInfo[j].name == 'mc_tags'){
                val = form.value[this.bulkInfo[j].name].map(a => encodeURIComponent(a.item_id));
              }
              else{
                val = encodeURIComponent(form.value[this.bulkInfo[j].name]);
              }
              str += this.bulkInfo[j].name + '=' + val;
            }
          }
        } finally {
          if (str != "") {
            this.tracksService
              .updateTrackInfoBulk(this.selectedRows[i], str)
              .subscribe((_dataInfo) => {
                if (i >= this.selectedRows.length - 1) {
                  if (this.searchStr == "" && !this.isSmartSearch)
                    this.getTracks(true);
                  else if (this.isSmartSearch) this.smartSearch(false, false);
                  else this.search(false);
                  if (!showed) {
                    showed = true;
                    this.alert.alertOpen("Info updated", 0);
                    this.popupClose(window.event);
                  }
                }
              });
          } else {
            if (i >= this.selectedRows.length - 1) {
              this.alert.alertOpen("Any checkboxes selected", 1);
            }
          }
        }
      }
    } else {
      this.alert.alertOpen("No Tracks selected", 1);
    }
  }
  /**
   Focusing in autocomplete inputs
   * @param e
   */
  autoFocus(e) {
    if (e) this.focusInput();
    else this.blurInput();
  }
  /**
   Set param if input focused
   */
  focusInput() {
    this.isFocused = true;
  }
  /**
   Set param if input blured
   */
  blurInput() {
    this.isFocused = false;
  }
  /**
   Checking press keys in keyboard
   * @param event - event
   */
  keyPress(event: any) {
    const pattern = /[0-9\ ]/;
    let inputChar = String.fromCharCode(event.charCode);

    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }
  /**
   Get information for next track in track library if opened track information edit popup
   * @param e - event
   * @returns {boolean}
   */
  getNextTrackInfo(e) {
    var pos = this.getSelectedPosition(this.tracks, this.trackInfo.data.id);
    this.showInfo(e, this.tracks[pos + 1].id);
    this.scrollToElement();
    return false;
  }
  /**
   Get information for previous track in track library if opened track information edit popup
   * @param e - event
   * @returns {boolean}
   */
  getPrevTrackInfo(e) {
    var pos = this.getSelectedPosition(this.tracks, this.trackInfo.data.id);
    if (pos > 0) this.showInfo(e, this.tracks[pos - 1].id);
    this.scrollToElement();
    return false;
  }

  /**
   Get track index in array
   * @param arr - tracks array
   * @param id - track id
   * @returns {number}
   */
  getTrackPosition(arr, id) {
    for (var i = 0; i < arr.length; i++) {
      if (id == arr[i].id) {
        return i;
      }
    }
  }
  /**
   Set order for tracks in playlist
   * @param order - order rule. List of possible values [ 'title', 'artist', 'album', 'add_date', 'bpm']
   */
  setOrder(order) {
    if (this.tracksOrder.orderBy == order) {
      if (this.tracksOrder.order == "DESC") this.tracksOrder.order = "ASC";
      else this.tracksOrder.order = "DESC";
    } else {
      this.tracksOrder.orderBy = order;
    }
    this.infiniteScroll.items = 0;
    if (this.searchStr == "" && !this.isSmartSearch && this.searchUID== '') this.getTracks(false);
    else if (this.isSmartSearch) this.smartSearch(false, false);
    else if(this.searchUID != '') this.searchUIDFunction;
    else this.search(false);
  }
  /**
   Append items in track library after scroll page
   */
  appendItems() {
    this.infiniteScroll.items += this.infiniteScroll.addItems;
    if (this.searchStr == "" && !this.isSmartSearch) this.getTracks(false);
    else if (this.isSmartSearch) this.smartSearch(false, false);
    else this.search(false);
  }
  /**
   Scrolling event
   */
  onScroll(_e) {
    this.appendItems();
  }
  /**
   Confirm action in confirmation popup
   * @param e - event
   */
  isConfirm(e) {
    if (e) {
      if (this.confirmValues.type == 0) this.deleteTrack(this.confirmValues.id);
      else if (this.confirmValues.type == 1)
        this.bulkUpdate(this.confirmValues.id);
    }
  }
  /**
   Open confirmation popup
   * @param id - id of action
   * @param t - text for popup
   * @returns {boolean}
   */
  openConfirmPopup(id, t) {
    var text = "Do you want delete this track?";
    if (t == 1)
      text =
        this.selectedRows.length +
        " Tracks selected for bulk update. Do you want to proceed?";

    this.confirmValues.id = id;
    this.confirmValues.type = t;
    this.confirm.confirmOpen(text);
    return false;
  }
  /**
   Check smart filter field
   * @param sf - smart filter
   * @returns {boolean}
   */
  checkSmartField(sf) {
    if (sf.field == "add_to_cp_playlist" && !sf.isHidden) {
      return true;
    }
    return false;
  }

  resetDisabledInputs() {
    this.disabledInputs.year = false;
    this.disabledInputs.album = false;
    this.disabledInputs.artist = false;
    this.disabledInputs.title = false;
  }

  mcPopupClose(_e) {
    this.mcPopupOpened = false;
    this.mcFormPopupOpened = false;
    return false;
  }

  mcFormPopupClose(_e) {
    this.mcFormPopupOpened = false;
    return false;
  }

  // MC TAG SECTION
  mcTagForm = {
    id: null,
    name: "",
  };

  mcTagsOrder = {
    order: "DESC",
    orderBy: "updated_at",
  };
  mcTagsCount = {
    total: 0,
    display: 0,
  };

  getMCTagsNew(search = false) {
    this.isLoad = true;
    this.tracksService
      .getMCTagsNew(this.mcTagsOrder.order, this.mcTagsOrder.orderBy, 0, 0)
      .subscribe((tags: any) => {
        this.isLoad = false;
        this.mcTags = this.mcTagsRaw = tags.list;
        this.mcTagsCount.total = tags.total;
        this.mcTagsCount.display = tags.total;
        if (search) {
          this.mcTagSearch();
        }
      });
  }

  openMCPopup() {
    this.mcPopupOpened = !this.mcPopupOpened;
    this.getMCTagsNew();
  }

  createMCTag(_e) {
    this.mcTagForm.id = null;
    this.mcTagForm.name = "";
    this.mcFormPopupOpened = !this.mcFormPopupOpened;
    return false;
  }

  editMCTag(_e, id) {
    let mc_tag = this.mcTags.find((x) => x.id == id);
    this.mcTagForm.id = id;
    this.mcTagForm.name = mc_tag.name;
    this.mcFormPopupOpened = !this.mcFormPopupOpened;
    return false;
  }

  mcTagUpdate(e, form: NgForm) {
    var str = Object.keys(form.value)
      .map(function (key) {
        var val = encodeURIComponent(form.value[key]);
        if (val == "null" || val == null) {
          val = "";
        }
        return encodeURIComponent(key) + "=" + val;
      })
      .join("&");

    if (this.mcTagForm.name == "") {
      this.alert.alertOpen("Please enter MC-Tag name", 1);
      return false;
    }
    this.isLoad = true;
    if (this.mcTagForm.id == null) {
      this.tracksService.createMCTag(str).subscribe((tags: any) => {
        this.isLoad = false;
        if (tags.error == undefined) {
          this.mcFormPopupClose(e);
          this.alert.alertOpen("MC-Tag added", 0);
          this.getMCTagsNew();
          this.getMCTags();
        } else {
          this.alert.alertOpen(tags.error, 1);
        }
      });
    } else {
      this.tracksService
        .updateMCTag(this.mcTagForm.id, str)
        .subscribe((tags: any) => {
          this.isLoad = false;
          this.isLoad = false;
          if (tags.error == undefined) {
            this.mcFormPopupClose(e);
            this.alert.alertOpen("MC-Tag updated", 0);
            this.getMCTagsNew();
            this.getMCTags();
            this.getTracks(true);
          } else {
            this.alert.alertOpen(tags.error, 1);
          }
        });
    }
  }

  mcTagSearchStr = "";

  mcTagSearch() {
    this.isLoad = true;
    setTimeout(() => {
      if (this.mcTagSearchStr == "") {
        this.mcTags = this.mcTagsRaw;
        this.isLoad = false;
      } else {
        this.mcTags = this.mcTagsRaw.filter((x) =>
          x.name
            .trim()
            .toLowerCase()
            .includes(this.mcTagSearchStr.trim().toLowerCase())
        );
        this.isLoad = false;
      }
      this.mcTagsCount.display = this.mcTags.length;
    }, 1000);
  }

  clearMCTagSearch() {
    this.mcTagSearchStr = "";
    this.getMCTagsNew();
    return false;
  }

  setOrderMCTags(order) {
    if (this.mcTagsOrder.orderBy == order) {
      if (this.mcTagsOrder.order == "DESC") this.mcTagsOrder.order = "ASC";
      else this.mcTagsOrder.order = "DESC";
    } else {
      this.mcTagsOrder.orderBy = order;
    }
    this.getMCTagsNew(true);
  }

  slectedcheckboxItem = [];  // store id of selected tracks 
  checkboxselection(id: any, isChecked: boolean) {
    if (isChecked) {
      this.slectedcheckboxItem.push(id);
    } else {
      this.slectedcheckboxItem = this.slectedcheckboxItem.filter(
        (m) => m != id
      );
    }
    this.tracks = this.tracks.map((d) => {
      if (d.id == id) {
        d.selected = isChecked;
        return d;
      }
      return d;
    });
  }

  mapCheckedTrack(trackLists = []) {
    return trackLists.map((x) => ({
      ...x,
      selected: this.slectedcheckboxItem.includes(x.id),
    }));
  }

  tracksAndChannels: any;
  tracksdetails: any = {
    id: null,
    name: "",
    channels: [
      {
        track_id: null,
        track_name: null,
        client_id: null,
        site_id: null,
        channel_id: null,
        client_name: null,
        site_name: null,
        channel_name: null,
        masterlength: null,
        mastersdata: null,
        showbuildbutton: 1,
        showhawkbutton: 1,
        build: false,
        buildResponse: false,
        hawk: false,
        hawkResponse: false
      },
    ],
  }; //DG Info
  openchannelPopup() {
    var channel = [];
    if (this.slectedcheckboxItem.length > 0) {
      var text = "Do you want delete " + this.slectedcheckboxItem.length + " tracks?";
      this.confirmAll.confirmOpen(text);
      return false;
    } else {
      this.alert.alertOpen('Please select any one tracks', 1);
    }
  }
  isAllConfirm(e) {
    if (e) {
      this.removeAllTracksAndDetails();
    }
  }
  removeAllTracksAndDetails() {
    this.tracksService.getchannelsOfTracks(this.slectedcheckboxItem).subscribe((channels: any) => {
        this.alert.alertOpen(this.slectedcheckboxItem.length + " Track deleted", 0);
        this.tracksAndChannels = channels.data;
     
        if(channels.data.length > 0){
          this.buildstatus(channels.data);
        }
        //setTimeout( ()=>{
          this.channelPopUp = !this.channelPopUp;
        //}, 3000)
    });

    // this.channelPopUp = !this.channelPopUp;
  }

 

  channelPopUpClose(_e) {
    var text = "Do you really want to close channel list popup !!";
    this.finalConfirm.confirmOpen(text);
    return false;
  }

  isfinalConfirm(e:any){
    if (e) {
        this.channelPopUp = false;
        this.slectedcheckboxItem=[];
        this.getTracks(true);
        return false;
    }
  }

  Export() {
    console.log('this.channel_ids',this.channel_ids);
    var excelarrayfile = [];
    var hawkValue='';
    var filename = "Remove selected and Build/Hawk";
    for (let index = 0; index < this.channel_ids.length; index++) {
        excelarrayfile.push({
          client_name: this.channel_ids[index]['client_name'],
          site_name: this.channel_ids[index]['site_name'],
          channel_name: this.channel_ids[index]['channel_name'],
          entity_id: this.channel_ids[index]['entity_id'],
          build_status:this.getBuildStatus(this.channel_ids[index]),
          hawk_status : this.getHawkStatus(this.channel_ids[index])
        });
    }
    this.excelService.exportAsExcelFile(excelarrayfile, filename);
  }


  logResponse: any = null;
  public isLoadCHawk: boolean = false;

  public progressValue = [];
  progressBarDisplay(current_progress, maxValue, channel_id) {
    var interval = setInterval(() => {
      current_progress += 1;
      this.progressValue[channel_id] = current_progress;
      if (current_progress >= maxValue)
        clearInterval(interval);
    }, 10);
  }

  ConvertToInt(val) {
    return parseInt(val) || 0;
  }

  polling: any = [];
  getBuildReponse(id, index) {
    var c = this;
    c.polling[index] = setInterval(function x() {
      c.cpplaylistService.getBuildResponse(id).subscribe(res => {
        if (res['statusCode'] == 200 && res['result']) {
          if (res['result']['response'] != null && res['result']['response']['statusCode'] == 200) {
            c.progressBarDisplay(70, 100, c.channel_ids[index]['channel_id']);
            let msg = c.channel_ids[index]['channel_name'] + ': <br> Build Success';
            let type = 0; //success
            if (res['result']['response']['statusMessage']['returnvalue'] != undefined) {
              let returnValue = res['result']['response']['statusMessage']['returnvalue'];
              if (returnValue['NumberOfClips'] != c.ConvertToInt(res['result']['clip_count']) ||
                returnValue['NumberOfProfiles'] != c.ConvertToInt(res['result']['profile_count'])
              ) {
                msg = c.channel_ids[index]['channel_name'] + ': <br> Build failed - Count mismatch';
                type = 1; // error
              }
            }
            c.alert.alertOpen(msg, type);
          }
          if (res['result']['response'] != null && res['result']['response']['statusCode'] == 'BUILD_FAILED') {
            c.alert.alertOpen(c.channel_ids[index]['channel_name'] + ': <br> Build Failed', 1);
          }
          if (res['result']['response']) {
            c.channel_ids[index].build = false;
            c.channel_ids[index].buildResponse = res['result'];
            clearInterval(c.polling[index]);
          }
        }
        console.log('res', res);
      });
      return x;
    }(), 15000);
  }


build(channel_id:any, index,channel_name) {
  console.log('Enter build: Channel-ID', channel_id);
  this.channel_ids[index].build = true;
  this.progressBarDisplay(0,10,channel_id);
  this.cpplaylistService.buildPlaylist(channel_id).subscribe((buildinfo: any) => {
    this.progressBarDisplay(10, 30,channel_id);
    if (buildinfo.error == undefined) {
      this.progressBarDisplay(30,70,channel_id);
      this.getBuildReponse(buildinfo.data.odbapi_log_id,index);
      // this.alert.alertOpen("Job created", 0);
    } else {
      this.alert.alertOpen(channel_name +': <br>'+buildinfo.error, 1, 30);
      this.channel_ids[index].build = false;
      this.channel_ids[index].buildResponse = 'build_not_created';
      this.channel_ids[index].build_not_created_em = buildinfo.error;
    }
  });
}

  channel_ids: any;
  buildAll(): void {
    console.log('Enter build All. All channels:',this.tracksAndChannels);
    var sortedchannel_id=this.removeDuplicatesByProperty(this.tracksAndChannels.flat(1),'channel_id');
    console.log('After removed duplicate:',sortedchannel_id);
    for (let index = 0; index < sortedchannel_id.length; index++) {
      setTimeout(
        () => this.build(sortedchannel_id[index].channel_id, index,sortedchannel_id[index].channel_name),
        1000 * index
      );
    }
  }

  removeDuplicatesByProperty(arr, prop) {
    const seen = new Set();
    return arr.filter(item => {
      const key = item[prop];
      if (!seen.has(key)) {
        seen.add(key);
        return true;
      }
      return false;
    });
  }

  hawk(site_id: any, channel_id: any, index) {
    this.isLoadCHawk = true;
    this.channel_ids[index].hawk = true;
    this.cpplaylistService.hawk(site_id, channel_id, null).subscribe((res) => {
      this.isLoadCHawk = false;
      this.channel_ids[index].hawk = false;
      if (res["statusCode"] == 200 && res["result"].length > 0) {
        this.channel_ids[index].hawkResponse = res;
        // this.alert.alertOpen(this.channel_ids[index]['channel_name'] + ': <br>' + res["result"], 0);
      } else {
        this.channel_ids[index].hawkResponse = 'hawk_not_created';
        this.channel_ids[index].hawk_not_created_em = res["error"];
        // this.alert.alertOpen(this.channel_ids[index]['channel_name'] + ': <br>' + res["error"], 1);
      }
    });
  }

  hawkAll() {
    console.log('this.cha',this.channel_ids);
    for (let index = 0; index < this.channel_ids.length; index++) {
      let build_response = this.getBuildStatus(this.channel_ids[index]);
      console.log('build_response', build_response);
      if(build_response == 'Success')
      this.hawk(
        this.channel_ids[index]['site_id'],
        this.channel_ids[index]['channel_id'],
        index
      );
    }
  }

  findProgress(channelList, channel) {
    if (Array.isArray(channelList))
      return channelList.find(c => c.channel_id == channel.channel_id);
    return undefined;
  }

  buildstatus(channles: any) {
    var channel_ids = [];
    var resArr = [];
    for (let index = 0; index < channles.length; index++) {
      channles[index].filter(function (item) {
        var i = resArr.findIndex(x => (x.channel_id == item.channel_id));
        if (i <= -1) {
          resArr.push(item);
        }
        return null;
      });
    }
    this.channel_ids = resArr;
    for (let index = 0; index < this.channel_ids.length; index++) {
      channel_ids.push(resArr[index].channel_id);
    }

    // this.cpplaylistService.buildresponse(channel_ids).subscribe((buildinfo: any) => {
    //   this.logResponse = buildinfo;
      for (let index = 0; index < this.channel_ids.length; index++) {
        this.channel_ids[index].build = false;
        this.channel_ids[index].buildResponse =null;
        // this.channel_ids[index].hawkResponse = buildinfo[index];
      }
    // });
  }

  getBuildStatus(row: any): string {
    if (!row.buildResponse || row.buildResponse == null) {
      return "No status";
    }
    
    if(row.buildResponse == 'build_not_created'){
      return row.build_not_created_em;
    }

    const response = row.buildResponse.response;
    if (!response) {
      return "No response";
    }
  
    const statusMessage = response.statusMessage;
    if (!statusMessage || !statusMessage.returnvalue) {
      return "No status message";
    }
  
    const numberOfClips = statusMessage.returnvalue.NumberOfClips;
    const numberOfProfiles = statusMessage.returnvalue.NumberOfProfiles;
    const clipCount = this.ConvertToInt(row.buildResponse.clip_count);
    const profileCount = row.buildResponse.profile_count;
  
    if (numberOfClips !== clipCount || numberOfProfiles !== profileCount) {
      return "Count mismatch";
    }
  
    if (response.statusCode === 200) {
      return "Success";
    }
  
    return "Failed";
  }

  getHawkStatus(row:any): string {
    if (!row.hawkResponse || row.hawkResponse == null) {
      return "No status";
    }

    if(row.hawkResponse == 'hawk_not_created'){
      return row.hawk_not_created_em;
    }

    const hawk_status = row.hawkResponse.hawk_status;

    switch (hawk_status) {
      case 0:
        return "In Progress";
        break;
      case 1:
        return "Success";
        break;
      case -1:
        return "Failed";
        break;
      default:
        return "No hawk status";
        break;
    }
  }

  refreshBuildData(ch,index){
    ch.build = true;
    this.build(ch.channel_id, index,ch.channel_name);
  }
    
  setHidden(e, tid) {
    console.log(tid);
    this.tracksService.setHidden(tid, e).subscribe((status: any) => {
      if (status.error != undefined) {
        this.alert.alertOpen(status.error, 1);
      } else {
        if (e) e = "YES";
        else e = "NO";
        this.alert.alertOpen("Hidden value changed to " + e + " !", 0);
      }
    });
  }
 
  updateExplicit(e, tid) {
    console.log(tid);
    this.tracksService.updateExplicit(tid, e).subscribe((status: any) => {
      if (status.error != undefined) {
        this.alert.alertOpen(status.error, 1);
      } else {
        if (e) e = "YES";
        else e = "NO";
        this.alert.alertOpen("Explicit value changed to " + e + " !", 0);
      }
    });
  }

  clearBoxUID() {
    this.searchUID = '';
  }

  searchUidPopUpOpen(){
    this.searchBulkUidPopUpOpen= !this.searchBulkUidPopUpOpen;
    this.focusInput();
  }

  searchUIDFunction() {
    this.isLoad = true;
    var offset = this.infiniteScroll.items;
    var limit = this.infiniteScroll.addItems;
    this.isSmartSearch = false;
      offset = 0;
      limit = this.infiniteScroll.items + this.infiniteScroll.addItems;
    if (this.searchUID === '' || this.searchUID === null) {
        this.alert.alertOpen("Please enter UID", 1);
        this.isLoad = false;
        return;
    } else {
        // Split the input string by both newlines and commas to get an array of UIDs
        let uids = this.searchUID.split(/[\n,]+/).map(uid => {
            // Trim whitespace, remove braces, and filter out empty strings
            uid = uid.trim();
            if (uid.startsWith('{') && uid.endsWith('}')) {
                uid = uid.slice(1, -1).trim();
            }
            return uid;
        }).filter(uid => uid !== '');

        if (uids.length === 0) {
            this.alert.alertOpen("Please enter valid UIDs", 1);
            this.isLoad = false;
            return;
        } else {
            this.tracksService.bulkSearchUid(uids,this.tracksOrder.order, this.tracksOrder.orderBy, offset, limit).subscribe((tracks: any) => {
              this.isLoad = false;
              this.tracksCount.total = tracks.total;
              this.tracksCount.display = tracks.total;
              const arr2 = this.mapCheckedTrack(tracks.list);
              if (offset == 0) {
                this.tracks = arr2;
              } else {
                this.tracks = this.tracks.concat(arr2);
              }
            });
        }
    }
}





}
