import {Component, EventEmitter, Injectable, Input, OnInit, Output} from '@angular/core';
import {AutocompleteService} from './autocomplete.service';
import {NgForm, ControlContainer} from '@angular/forms';


import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-input-autocomplete',
  templateUrl: './input-autocomplete.component.html',
  styleUrls: ['./input-autocomplete.component.scss'],
  providers:[AutocompleteService],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
@Injectable()
export class InputAutocompleteComponent implements OnInit {
  @Input() inputVal = '';//Input value
  @Input() inputName = '';//Input name
  @Input() queryData = '';//Query data
  @Input() inputID = false;//Input id
  @Input() disabled = false; 
  @Input()  formCtrl;
  @Output() focus = new EventEmitter<boolean>();//Check input focus

  queryVal;//Array of query results
  isVisible = false;//Display dropdown
  searchTextChanged = new Subject<string>();
  
  constructor(private autoServ: AutocompleteService) {}

  ngOnInit() {
    this.focus.emit(false);
    this.searchTextChanged.pipe(
        debounceTime(1000),
        distinctUntilChanged())
        .subscribe(value => { 
          this.getData(value);
        });
  }

  /**
   Get query results
   * @param e - event
   */
  getData(value){
    this.autoServ.getAutocompleteData(value, this.queryData).subscribe(data=>{
      this.queryVal = data;
      this.isVisible = true;
    });
  }

  search($event) {
    this.searchTextChanged.next($event.target.value);
  }


  /**
   Blur input
   * @param e - event
   */
  blured(e){
    setTimeout(() => {
      this.isVisible = false;
    },200);
    this.focus.emit(false);
  }

  /**
   Focus input
   * @param e - event
   */
  focused(e){
    this.focus.emit(true);
  }

  /**
   Add value to input from dropdown
   * @param name - value
   * @param id - input id
   */
  addInfo(name,id){
    this.inputVal = name;
    this.isVisible = false;
  }
}
