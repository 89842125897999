import { Component, OnInit, ViewChild } from "@angular/core";
import { FlushService } from "../services/flush.service";
import { AlertComponent } from "../alert/alert.component";
import { ConfirmComponent } from "../confirm/confirm.component";

@Component({
  selector: "app-flush",
  templateUrl: "./flush.component.html",
  styleUrls: ["./flush.component.scss"],
  providers: [FlushService],
})
export class FlushComponent implements OnInit {
  @ViewChild(AlertComponent, { static: true }) alert: AlertComponent; //Component of alert popup
  @ViewChild(ConfirmComponent) confirm: ConfirmComponent; //Component of confirm popup

  constructor(private flushservice: FlushService) {}

  ngOnInit(): void {}

  flush_entity_ids: string = "";
  entityDetails: any[] = []; 
  confirmValues = {
    id: null,
    type:0,
  };

  flush_entity() {
    if(this.flush_entity_ids == "" || this.flush_entity_ids ==null){
      this.alert.alertOpen('Please enter Entity Id.', 1);
    }else{
      this.entityDetails = [];
      const idsArray = this.flush_entity_ids.split(",").map((id) => id.trim());
      this.flushservice.flush_entity_search(idsArray).subscribe((list: any) => {
        console.log(list);
        if (list.length > 0) {
          this.entityDetails = list;
        } else {
          this.alert.alertOpen(list.error, 1);
        }
      });
    }
  }

  async singleDelete(id: any) {
    try {
      const data: any = await this.flushservice.singleDeleteId(id).toPromise();
      const message = data.success ? data.success : data.error;
      const messageType = data.success ? 0 : 1;
      this.alert.alertOpen(message, messageType);
      this.entityDetails = this.entityDetails.filter(
        (item: any) => item.entity_id !== id
      );
    } catch (error) {
      this.alert.alertOpen("An error occurred while deleting.", 1);
    }
  }

  async delete_all_entity() {
    const idsToDelete = this.flush_entity_ids.split(",").map((id) => id.trim());

    for (const id of idsToDelete) {
      await this.singleDelete(id);
    }
    this.entityDetails = [];
  }

  openConfirmPopup(id,t) {
    var text = "Do you want to flush this entity?";
    if (t == 1) text = "Do you want to delete flush all entity?";
    this.confirmValues.id = id;
    this.confirmValues.type = t;
    this.confirm.confirmOpen(text);
    return false;
  }


  isConfirm(e) {
    if (e) {
      if (this.confirmValues.type == 0) this.singleDelete(this.confirmValues.id);
      else if (this.confirmValues.type == 1)
        this.delete_all_entity();
      } 
  }

  
}
