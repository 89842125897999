import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Globals } from '../globals';

@Injectable()
export class CPPlaylistsService {

  httpOptions = {
    headers: new HttpHeaders({
      'Host': environment.dev_url_prefix + '/',
      'Authorization': 'Bearer ' + this.globals.access_token,
      'Accept': 'application/json'
    })
  };
  postOptions = {
    headers: new HttpHeaders({
      'Host': environment.dev_url_prefix + '/',
      'Authorization': 'Bearer ' + this.globals.access_token,
      'Accept': 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded'
    })
  }
  deleteOptions = {
    headers: new HttpHeaders({
      'Host': environment.dev_url_prefix + '/',
      'Authorization': 'Bearer ' + this.globals.access_token,
      'Accept': 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded'
    })
  }

  constructor(
    private http: HttpClient,
    private globals: Globals
  ) { }

  /**
   Get playlist information
   * @param pid - playlist id
   * @returns {Observable<any>}
   */
  getPlaylistById(pid) {
    return this.http.get(environment.dev_url_prefix + '/api/V1/cp_playlists/read/' + pid, this.httpOptions).pipe(map(playlist => {
      return playlist;
    }));
  }

  /**
   Get playlists from channel
   * @param pid - channel id
   * @returns {Observable<any>}
   */
  getPlaylists(pid) {
    return this.http.get(environment.dev_url_prefix + '/api/V1/cp_playlists/list/' + pid, this.httpOptions).pipe(map(playlists => {
      return playlists;

    }));
  }

  getPlaylistsByClient(clientID) {
    return this.http.get(environment.dev_url_prefix + '/api/V1/cp_playlists/listByClient/' + clientID, this.httpOptions).pipe(map(playlists => {
      return playlists;

    }));
  }

  /**
   Create new playlist
   * @param pid - channel id
   * @param formData - post data
   * @returns {Observable<any>}
   */
  createPlaylist(pid, formData) {
    return this.http.post(environment.dev_url_prefix + '/api/V1/cp_playlists/create/' + pid, formData, this.postOptions).pipe(map(playlists => {
      return playlists;
    }));
  }

  /**
   Update playlist
   * @param cat_id - channel id
   * @param pid - playlist id
   * @param formData - post data
   * @returns {Observable<any>}
   */
  updatePlaylist(cat_id, pid, formData) {
    return this.http.put(environment.dev_url_prefix + '/api/V1/cp_playlists/update/' + cat_id + '/' + pid, formData, this.postOptions).pipe(map(playlists => {
      return playlists;
    }));
  }

  /**
   Delete playlist
   * @param pid - playlist id
   * @returns {Observable<any>}
   */
  deletePlaylist(pid) {
    return this.http.delete(environment.dev_url_prefix + '/api/V1/cp_playlists/delete/' + pid, this.deleteOptions).pipe(map(playlistDelete => {
      return playlistDelete;
    }));
  }

  /**
   Add new track to playlist
   * @param pid - playlist id
   * @param tid - track id
   * @param fpid - foundation playlist id
   * @returns {Observable<any>}
   */
  addTrackToPlaylist(pid, tid, fpid) {
    var str = '';
    if (fpid != null) {
      str = '/' + fpid;
    }
    return this.http.post(environment.dev_url_prefix + '/api/V1/cp_playlists/file/add/' + pid + '/' + tid + str, '', this.postOptions).pipe(map(tracks => {
      return tracks;
    }));
  }

  /**
   Get all tracks in playlist
   * @param pid - playlist id
   * @param order - order of diplaying tracks
   * @param orderBy - ordering rule
   * @returns {Observable<any>}
   */
  getPlaylistTracks(pid, order, orderBy) {
    return this.http.get(environment.dev_url_prefix + '/api/V1/cp_playlists/files/' + pid + '/' + orderBy + '/' + order, this.httpOptions).pipe(map(tracks => {
      return tracks;
    }));
  }

  /**
   Delete track from playlist
   * @param pid - playlist id
   * @param tid - track id
   * @returns {Observable<any>}
   */
  deletePlaylistTrack(pid, tid) {
    return this.http.delete(environment.dev_url_prefix + '/api/V1/cp_playlists/file/delete/' + pid + '/' + tid, this.deleteOptions).pipe(map(trackDelete => {
      return trackDelete;
    }));
  }

  /**
   Set status for playlist
   * @param pid - playlist id
   * @param statusId - status id
   * @returns {Observable<any>}
   */
  setStatus(pid, statusId) {
    return this.http.get(environment.dev_url_prefix + '/api/V1/cp_playlists/status_change/' + pid + '/' + statusId, this.httpOptions).pipe(map(status => {
      return status;
    }));
  }

  setHidden(pid, hiddenStatus) {
    return this.http.get(environment.dev_url_prefix + '/api/V1/cp_playlists/hidden_change/' + pid + '/' + hiddenStatus, this.httpOptions).pipe(map(status => {
      return status;
    }));
  }

  /**
   Search playlist
   * @param str - search string
   * @returns {Observable<any>}
   */
  searchPlaylists(str) {
    return this.http.get(environment.dev_url_prefix + '/api/V1/cp_playlists/search/' + str, this.httpOptions).pipe(map(playlists => {
      return playlists;
    }));
  }

  /**
   Get all tracks with deleted
   * @param pid - playlist id
   * @param date - date of delete
   * @returns {Observable<any>}
   */
  getTracksWithDeleted(pid, date) {
    var d = date;
    if (date != null) {
      d = '/' + date;
    }
    return this.http.get(environment.dev_url_prefix + '/api/V1/cp_playlists/with_deleted_files/' + pid + d, this.httpOptions).pipe(map(playlists => {
      return playlists;
    }));
  }

  /**
   Get history dates
   * @param cid - channel id
   * @returns {Observable<any>}
   */
  getModifyDates(cid) {
    return this.http.get(environment.dev_url_prefix + '/api/V1/cp_playlists/history_dates/' + cid, this.httpOptions).pipe(map(dates => {
      return dates;
    }));
  }

  /**
   Get all playlist with deleted tracks
   * @param pid - playlist id
   * @param date - date of delete
   * @returns {Observable<any>}
   */
  getModifyPlaylists(pid, date) {
    var d = '';
    if (date != null) {
      d = '/' + date;
    }
    return this.http.get(environment.dev_url_prefix + '/api/V1/cp_playlists/with/deleted/' + pid + d, this.httpOptions).pipe(map(playlists => {
      return playlists;
    }));
  }

  /**
   Get log of actions in playlist
   * @param pid - playlist id
   * @returns {Observable<any>}
   */
  getPlaylistLog(pid) {
    return this.http.get(environment.dev_url_prefix + '/api/V1/cp_playlists/history/' + pid, this.httpOptions).pipe(map(history => {
      return history;
    }));
  }

  /**
   Get log of actions in playlist with filters
   * @param pid - playlist id
   * @param formData - post data
   * @returns {Observable<any>}
   */
  getFilteredPlaylistLog(pid, formData) {
    return this.http.post(environment.dev_url_prefix + '/api/V1/cp_playlists/history/' + pid, formData, this.postOptions).pipe(map(history => {
      return history;
    }));
  }

  /**
   Get artists count in playlist
   * @param pid - playlist id
   * @returns {Observable<any>}
   */
  getArtistsNumber(pid) {
    return this.http.get(environment.dev_url_prefix + '/api/V1/cp_playlists/uniq_artists_count/' + pid, this.httpOptions).pipe(map(num => {
      return num;
    }));
  }

  /**
   Search tracks in playlist
   * @param str - search string
   * @param cid - playlist id
   * @returns {Observable<any>}
   */
  search(str, cid) {
    return this.http.get(environment.dev_url_prefix + '/api/V1/cp_playlists/track_search/' + str + "/" + cid + "/0/100", this.httpOptions).pipe(map(tracks => {
      return tracks;
    }));
  }
  getClientDocuments(site_id) {
    return this.http.get(environment.dev_url_prefix + '/api/V1/Documents/file/' + site_id, this.httpOptions).pipe(map(documents => {
      return documents;
    }));
  }
  getClientDocumentsChannel(channel_id) {
    return this.http.get(environment.dev_url_prefix + '/api/V1/Documents/file_channel/' + channel_id, this.httpOptions).pipe(map(documents => {
      return documents;
    }));
  }
  getClientDocumentView(document_id) {
    return this.http.get(environment.dev_url_prefix + '/api/V1/Documents/view/' + document_id, this.httpOptions).pipe(map(view => {
      return view;
    }));
  }
  deleteDocument(id) {
    return this.http.delete(environment.dev_url_prefix + '/api/V1/Documents/file/' + id, this.deleteOptions).pipe(map(trackDelete => {
      return trackDelete;
    }));
  }
  updateExcluded(cpmf_id, formData, type) {
    let url = '/api/V1/cp_playlists/updateCpmfExcluded/';
    if (type == 2) {
      url = '/api/V1/fp_playlists/updateFpmfExcluded/';
    }
    return this.http.put(environment.dev_url_prefix + url + cpmf_id, formData, this.postOptions).pipe(map(playlists => {
      return playlists;
    }));
  }
  buildPlaylist(cid) {
    return this.http.get(environment.dev_url_prefix + '/api/V1/cp_playlists/buildjob/' + cid, this.httpOptions).pipe(map(buildinfo => {
      return buildinfo;
    }));
  }
  siteBuildPlaylist(site_id) {
    return this.http.get(environment.dev_url_prefix + '/api/V1/cp_playlists/site_level_buildjob/' + site_id, this.httpOptions).pipe(map(buildinfo => {
      return buildinfo;
    }));
  }

  getOBDAPILogs(site_id, channel_id, date_filter, date_range_from, date_range_to, build_status) {

    let params = new HttpParams()
      .set('channel_id', channel_id)
      .set('date_filter', date_filter)
      .set('date_range_from', date_range_from).set('date_range_to', date_range_to)
      .set('build_status', build_status);

    return this.http.get(environment.dev_url_prefix + '/api/V1/odbapi_log/get_logs/' + site_id, {
      headers: new HttpHeaders({
        'Host': environment.dev_url_prefix + '/',
        'Authorization': 'Bearer ' + this.globals.access_token,
        'Accept': 'application/json'
      }), params: params
    }).pipe(map(buildinfo => {
      return buildinfo;
    }));
  }

  hawk(site_id, channel_id, build_id) {
    let params = new HttpParams();
    return this.http.get(environment.dev_url_prefix + '/api/V1/odbapi/hawk/' + site_id + '/' + channel_id + '/' + build_id, {
      headers: new HttpHeaders({
        'Host': environment.dev_url_prefix + '/',
        'Authorization': 'Bearer ' + this.globals.access_token,
        'Accept': 'application/json'
      }), params: params
    }).pipe(map(response => {
      return response;
    }));
  }

  hawkSiteLevel(site_id) {
    let params = new HttpParams();
    return this.http.get(environment.dev_url_prefix + '/api/V1/odbapi/hawk-site-level/' + site_id, {
      headers: new HttpHeaders({
        'Host': environment.dev_url_prefix + '/',
        'Authorization': 'Bearer ' + this.globals.access_token,
        'Accept': 'application/json'
      }), params: params
    }).pipe(map(response => {
      return response;
    }));
  }

  forceHawk(site_id) {
    // build_id,serial_number,hawk_id
    let params = new HttpParams();
    return this.http.get(
      environment.dev_url_prefix + '/api/V1/odbapi/force-hawk/' + site_id,
      // environment.dev_url_prefix + '/api/V1/odbapi/force-hawk/'+build_id+'/'+serial_number+'/'+hawk_id,
      {
        headers: new HttpHeaders({
          'Host': environment.dev_url_prefix + '/',
          'Authorization': 'Bearer ' + this.globals.access_token,
          'Accept': 'application/json'
        }), params: params
      }).pipe(map(response => {
        return response;
      }));
  }

  cancelHawk(site_id) {
    let params = new HttpParams();
    return this.http.get(
      environment.dev_url_prefix + '/api/V1/odbapi/cancel-hawk/' + site_id,
      {
        headers: new HttpHeaders({
          'Host': environment.dev_url_prefix + '/',
          'Authorization': 'Bearer ' + this.globals.access_token,
          'Accept': 'application/json'
        }), params: params
      }
    ).pipe(map(response => {
      return response;
    }));
  }

  // syncODBAPIDB(){
  //   return this.http.get(environment.dev_url_prefix + '/api/V1/odbapi/syncDB', this.httpOptions).pipe(map( result => {
  //     return result;
  //   }));
  // }

  getBuildResponse(id) {
    let params = new HttpParams();

    return this.http.get(environment.dev_url_prefix + '/api/V1/odbapi_log/get_build_response/' + id, {
      headers: new HttpHeaders({
        'Host': environment.dev_url_prefix + '/',
        'Authorization': 'Bearer ' + this.globals.access_token,
        'Accept': 'application/json'
      }), params: params
    }).pipe(map(buildinfo => {
      return buildinfo;
    }));
  }

  getSystemStatus(site_id, channel_id) {
    let params = new HttpParams();
    return this.http.get(environment.dev_url_prefix + '/api/V1/odbapi_log/get_system_status/' + site_id + '/' + channel_id, {
      headers: new HttpHeaders({
        'Host': environment.dev_url_prefix + '/',
        'Authorization': 'Bearer ' + this.globals.access_token,
        'Accept': 'application/json'
      }), params: params
    }).pipe(map(res => {
      return res;
    }));
  }

  getSystemStatusDB(site_id) {
    let params = new HttpParams();
    return this.http.get(environment.dev_url_prefix + '/api/V1/odbapi_log/get_system_status_db/' + site_id, {
      headers: new HttpHeaders({
        'Host': environment.dev_url_prefix + '/',
        'Authorization': 'Bearer ' + this.globals.access_token,
        'Accept': 'application/json'
      }), params: params
    }).pipe(map(res => {
      return res;
    }));
  }

  getSyncProfileLogs(site_id, channel_id, date_filter, date_range_from, date_range_to, build_status) {

    let params = new HttpParams()
      .set('channel_id', channel_id)
      .set('date_filter', date_filter)
      .set('date_range_from', date_range_from).set('date_range_to', date_range_to)
      .set('build_status', build_status);

    return this.http.get(environment.dev_url_prefix + '/api/V1/sync_profile_log/get_logs/' + channel_id, {
      headers: new HttpHeaders({
        'Host': environment.dev_url_prefix + '/',
        'Authorization': 'Bearer ' + this.globals.access_token,
        'Accept': 'application/json'
      }), params: params
    }).pipe(map(buildinfo => {
      return buildinfo;
    }));
  }

  getAutoUpdateLogs(channel_id, date_filter, date_range_from, date_range_to) {
    let params = new HttpParams()
      .set('date_filter', date_filter)
      .set('date_range_from', date_range_from).set('date_range_to', date_range_to);

    return this.http.get(environment.dev_url_prefix + '/api/V1/auto_update_log/' + channel_id, {
      headers: new HttpHeaders({
        'Host': environment.dev_url_prefix + '/',
        'Authorization': 'Bearer ' + this.globals.access_token,
        'Accept': 'application/json'
      }), params: params
    }).pipe(map(res => {
      return res;
    }));
  }


  getSplDetail(org_mcluid, dup_mcluid) {
    let params = new HttpParams()
      .set('org_mcluid', org_mcluid)
      .set('dup_mcluid', dup_mcluid);

    return this.http.get(environment.dev_url_prefix + '/api/V1/sync_profile_log/get_detail/', {
      headers: new HttpHeaders({
        'Host': environment.dev_url_prefix + '/',
        'Authorization': 'Bearer ' + this.globals.access_token,
        'Accept': 'application/json'
      }), params: params
    }).pipe(map(buildinfo => {
      return buildinfo;
    }));
  }

  getLongestSchedule(pid) {
    return this.http.get(environment.dev_url_prefix + '/api/V1/schedule/get_longest_schedule/' + pid, this.httpOptions).pipe(map(schedules => {
      return schedules;
    }));
  }


  retain(formData) {
    return this.http.post(environment.dev_url_prefix + '/api/V1/profile_statement/retain', JSON.stringify(formData), this.postOptions).pipe(map(playlists => {
      return playlists;
    }));
  }
  removeRetain(formData) {
    return this.http.post(environment.dev_url_prefix + '/api/V1/profile_statement/remove_retain', JSON.stringify(formData), this.postOptions).pipe(map(playlists => {
      return playlists;
    }));
  }
  setAutoGenerate(pid, status) {
    return this.http.get(environment.dev_url_prefix + '/api/V1/cp_playlists/auto_generate/' + pid + '/' + status, this.httpOptions).pipe(map(status => {
      return status;
    }));
  }
  setAutoBuild(pid, status) {
    return this.http.get(environment.dev_url_prefix + '/api/V1/cp_playlists/auto_build/' + pid + '/' + status, this.httpOptions).pipe(map(status => {
      return status;
    }));
  }
  setFestive(pid, festive_type) {
    return this.http.get(environment.dev_url_prefix + '/api/V1/cp_playlists/set_festive/' + pid + '/' + festive_type, this.httpOptions).pipe(map(festive_type => {
      return festive_type;
    }));
  }


  buildPlaylistArray(cid) {
    return this.http.get(environment.dev_url_prefix + '/api/V1/cp_playlists/buildjobarray/' + cid, this.httpOptions).pipe(map(buildinfo => {
      return buildinfo;
    }));
  }

  buildresponse(cid) {
    return this.http.get(environment.dev_url_prefix + '/api/V1/odbi/buildresponse/' + cid, this.httpOptions).pipe(map(buildinfo => {
      return buildinfo;
    }));

  }
  getPlaylistsCategories(pcid) {
    var str = '/' + pcid;
    if (pcid == '') {
      str = '';
    }
    return this.http.get(environment.dev_url_prefix + '/api/V1/cp_playlists/getplaylistcategory' + str, this.httpOptions).pipe(map(playCats => {
      return playCats;
    }));
  }
  getSubCategory(id: any) {
    return this.http.get(environment.dev_url_prefix + '/api/V1/cp_playlists/getSubCategory/' + id, this.httpOptions).pipe(map(playCats => {
      return playCats;
    }));
  }

  removeMaster(id:any){
    return this.http.get(environment.dev_url_prefix + '/api/V1/cp_playlists/removemaster/' + id, this.httpOptions).pipe(map(res => {
      return res;
    }));
  }

  getPlaylistsByStatus(pid:any,status:any){
    return this.http.get(environment.dev_url_prefix + '/api/V1/cp_playlists/listByStatus/' + pid +'/'+status, this.httpOptions).pipe(map(playlists => {
      return playlists;
    }));
  }

  getPlaylistsArchive(pid) {
    return this.http.get(environment.dev_url_prefix + '/api/V1/cp_playlists/archivelist/' + pid, this.httpOptions).pipe(map(playlists => {
      return playlists;

    }));
  }

  getclientPlaylistsCategories(){
    return this.http.get(environment.dev_url_prefix + '/api/V1/cp_playlists/getclientplaylistcategory', this.httpOptions).pipe(map(data => {
      return data;
    }));
  }


  updateOrderOfPlaylist(playlist_id,id,row){
    const str = 'rows=' + JSON.stringify(row);
    return this.http.post(environment.dev_url_prefix + '/api/V1/cp_playlists/updateOrderOfPlaylist/'+playlist_id+'/'+id,str,this.postOptions).pipe(map(data => {
      return data;
    }));
  }



}
