import { Component, OnInit, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";
import { UserService } from "../services/user.service";
import { AlertComponent } from "../alert/alert.component";

@Component({
  selector: "app-forgot-password",
  templateUrl: "./forgot-password.component.html",
  styleUrls: ["./forgot-password.component.scss"]
})
export class ForgotPasswordComponent implements OnInit {
  email = "";//User email
  @ViewChild(AlertComponent, { static: true }) alert: AlertComponent;//Component of alert popup
  constructor(private userService: UserService) {}

  ngOnInit() {
    // Do nothing
  }

  /**
   Send data for forgot password
   * @param e - event
   * @param {NgForm} form - form
   */
  forgotDataSend(e, form: NgForm) {
    e.preventDefault();
    var str = Object.keys(form.value)
      .map(function(key) {
        return (
          encodeURIComponent(key) + "=" + encodeURIComponent(form.value[key])
        );
      })
      .join("&");
    if (this.email == "") {
      this.alert.alertOpen("Please enter a valid registered email id", 1);
    } else {
      this.userService.forgotPassword(str).subscribe(
        (forgot: any) => {
          if (forgot.success != undefined) {
            this.alert.alertOpen("Password restore link sent to your email", 0);
          }
          if (forgot.error != undefined) {
            this.alert.alertOpen(forgot.error, 1);
          }
        },
        (error: any) => {
          this.alert.alertOpen(error.message, 1);
        }
      );
    }
  }
}
