<div class="alert" [ngClass]="{opened: opened}">
  <a href="#" class="popup_close" (click)="alertClose()"></a>
  <div class="popup_title" *ngIf="type==0">Information</div>
  <div class="popup_title error" *ngIf="type==1">Error</div>
  <div class="alert_wrap">
    
    <p [innerHTML]="message"></p>
    <div class="popup_btns">
      <button type="button" class="cancel" (click)="alertClose()">Ok</button>
    </div>
  </div>
</div>
<div class="fade" [ngClass]="{opened: opened}"></div>
