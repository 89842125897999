import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'group',
  template: `
  <div class="mypanel">
    <div class="title row" (click)="toggle.emit()">
      <div class="col-6">{{title}}</div>
      <div class="col-3"><span >{{label}}</span></div>
      <div class="col-3" style="text-align:right">
      </div>
      <a [ngClass]="opened?'opened':'closed'"></a>
    </div>
    <div class="body" [ngClass]="{'hidden': !opened}">
      <ng-content></ng-content>
    </div>
  <div>
  `,
  // <div class="col-3" style="text-align:right" *ngIf="progress_bar_value!=''">
  //       <progress-bar [progress]="progress_bar_value" [color-degraded]="{'0': '#f00e0e',  '25': '#f35b08', '50': '#f2be0e' , '75': '#04db52'}">
  //       </progress-bar>
  //     </div>
  styleUrls: ['accordion.component.css'],
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class AccordionGroupComponent {

  /**
   * If the panel is opened or closed
   */
  @Input() opened = false;

  /**
   * Text to display in the group title bar
   */
  @Input() title: string;
  @Input() label: string;
  @Input() progress_bar_value: string;
  @Input() build_status: string;
  @Input() hawkLabel: string;
  /**
   * Emitted when user clicks on group titlebar
   * @type {EventEmitter<any>}
   */
  @Output() toggle: EventEmitter<any> = new EventEmitter<any>();
  

  reHawk(){
    alert('re hack');
  }

  manualHawk(){
    alert('manual hack');
  }
}
