<button class="profile_statements_btn">Profile Statements</button>
<div class="popup profile_statement_popup" [ngClass]="{opened: popups.playlistPopupOpened}">
  <a href="#" class="popup_close" (click)="popupClose()"></a>
  <div class="popup_title">Create/Edit Playlist</div>
  <div class="popup_form">
    <form #updateFoundationPlaylist="ngForm">
      <input type="hidden" name="status" value="{{playlistFields.status}}" [(ngModel)]="playlistFields.status">
      <div class="input_wrap">
        <label>Name</label>
        <input type="text" name="name" value="{{playlistFields.name}}" [(ngModel)]="playlistFields.name"/>
      </div>
      <div class="input_wrap">
        <label>Category</label>
        <select name="foundation_category_id" *ngIf="categoriesList!=null" [(ngModel)]="playlistFields.catId" (change)="changeCategory($event)">
          <option *ngFor="let c of categoriesList" value="{{c.id}}" [selected]="playlistFields.catId == c.id">{{c.name}}</option>
        </select>
      </div>

      <div class="input_wrap" *ngIf="subcategoriesList!=null">
        <label>Subcategory</label>
        <select name="foundation_subcategory_id" [(ngModel)]="playlistFields.subcatId">
          <option *ngFor="let sc of subcategoriesList" value="{{sc.id}}" [selected]="playlistFields.subcatId == sc.id">{{sc.name}}</option>
        </select>
      </div>

      <div class="input_wrap">
        <label>Profile Statement</label>
        <textarea [(ngModel)]="playlistFields.description" name="description">{{playlistFields.description}}</textarea>
      </div>
      <div class="popup_btns">
        <button type="button" class="cancel" (click)="popupClose()">Cancel</button>
        <button type="submit" (click)="playlistDataUpdate($event,updateFoundationPlaylist,false)">Save</button>
      </div>
    </form>
  </div>
</div>
