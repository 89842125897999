import {Component, ElementRef, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Howl} from 'howler';
import {interval, Subscription} from 'rxjs';

@Component({
  selector: 'app-player-progress-bar',
  templateUrl: './player-progress-bar.component.html',
  styleUrls: ['./player-progress-bar.component.scss']
})
export class PlayerProgressBarComponent implements OnInit {

  @Input() player: Howl;
  @Input() innerWidth = 0;

  @Output()
  public playing = new EventEmitter<[number, number]>();

  public value: number;
  public maxValue: number;

  private updateObservable: any;
  private updateSubscriber: Subscription;

  constructor(
    private elementRef: ElementRef
  ) { }

  ngOnInit() {
    this.player.on('pause', () => this.onPause());
    this.player.on('play', () => this.onPlay());

    this.setupUpdateObservable();
  }
  setPosition(e){
    if(this.player!=undefined){
      this.innerWidth = e.layerX - this.elementRef.nativeElement.firstChild.offsetLeft;
      this.player.seek( (this.innerWidth / 400) * this.maxValue );
    }
  }
  change(value: number) {
    this.player.seek(value);
    this.subscribe();
  }

  getCurrentDuration(): number {
    return Math.round(<number>this.player.seek());
  }

  getMaxDuration(): number {
    return Math.round(<number>this.player.duration());
  }

  private onPlay() {
    this.subscribe();
  }

  private onPause() {
    
    this.innerWidth = 0;
    this.unsubscribe();
  }

  private setupUpdateObservable() {
    this.value = 0;
    this.maxValue = 0;
    this.updateObservable = interval(1000);
  }

  private subscribe() {
    this.updateSubscriber = this.updateObservable.subscribe(() => {
      this.value = this.getCurrentDuration();
      this.maxValue = this.getMaxDuration();
      var percent = this.value/this.maxValue;
      this.playing.emit([this.value, this.maxValue]);
      this.innerWidth = 400 * percent;
    });
  }

  private unsubscribe() {
    this.updateSubscriber.unsubscribe();
  }
}
