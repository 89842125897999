import {Component, OnInit, ViewChild} from '@angular/core';
import {UserService} from '../services/user.service';
import {ActivatedRoute, Router} from '@angular/router';
import {NgForm} from '@angular/forms';
import {DropzoneComponent, DropzoneConfigInterface, DropzoneDirective} from 'ngx-dropzone-wrapper';
import {Globals} from '../globals';
import {AlertComponent} from '../alert/alert.component';

@Component({
  selector: 'app-edit-user-component',
  templateUrl: './edit-user-component.component.html',
  styleUrls: ['./edit-user-component.component.scss'],
  providers: [UserService]
})
export class EditUserComponentComponent implements OnInit {
  /*
  Field for user edit popup
  {
    id - user id
    first_name - user first name,
    last_name - user last name,
    company_name - company name,
    email - email,
    phone - phone,
    address - address,
    photo - user image,
    client_profile - description,
    password - password,
    repeat_password - password confirmation
  }
   */
  userFields = {
    id: null,
    first_name: '',
    last_name: '',
    company_name: '',
    email: '',
    phone: '',
    address: '',
    photo: null,
    client_profile: '',
    password: '',
    repeat_password: ''
  };
  config: DropzoneConfigInterface;//Dropzone component
  @ViewChild(DropzoneComponent) componentRef?: DropzoneComponent;//Dropzone component
  @ViewChild(DropzoneDirective) directiveRef?: DropzoneDirective;//Dropzone component
  @ViewChild(AlertComponent, { static: true }) alert: AlertComponent;//Component of alert popup
  constructor(
    private usersService: UserService,
    private route: ActivatedRoute,
    private globals: Globals,
    private router: Router
  ) { }

  ngOnInit() {

    this.route.queryParams.subscribe(params => {
      this.userFields.id = params['uid'];
      this.config = {
          url: '/api/V1/user/photo/' + this.userFields.id,
          maxFilesize: 50,
          acceptedFiles: 'image/*',
          paramName: 'photo',
          method: 'POST',
          timeout: 180000,
          headers: {
            'Authorization': 'Bearer ' + this.globals.access_token
          }
      };
      this.usersService.getUserByID(this.userFields.id).subscribe((info:any) => {
        console.log(info);
        this.userFields.first_name = info.first_name;
        this.userFields.last_name = info.last_name;
        this.userFields.email = info.email;
        this.userFields.company_name = info.company_name;
        this.userFields.phone = info.phone;
        this.userFields.address = info.address;
        this.userFields.client_profile = info.client_profile;
        this.userFields.photo = info.photo;
      });
    });
  }

  /**
   Event if user photo uploaded with error
   * @param e - event
   */
  onUploadError(e){
    this.alert.alertOpen('error',1);
    console.log(e);
  }

  /**
   Event if user photo uploaded correct
   * @param e - event
   */
  onUploadSuccess(e){

    var info = e[1].info;
    this.userFields.photo = info.photo;
  }

  /**
   Update user information
   * @param e - event
   * @param {NgForm} form - form
   */
  formDataUpdate( e, form:NgForm ){
    e.preventDefault();
    delete form.value.repeat_password;
    var str = Object.keys(form.value).map(function (key) {
      var val=encodeURIComponent(form.value[key]);
      if(val == 'null' || val == null){
        val = '';
      }
      return encodeURIComponent(key) + '=' + val;
    }).join('&');
    if(this.userFields.id != null){
      if(this.userFields.password == this.userFields.repeat_password){
        this.usersService.updateUser(str, this.userFields.id ).subscribe((user:any) => {

          if(!user.error){
            this.alert.alertOpen("User info updated",0);
            setTimeout(()=>{  
              this.router.navigate(['/']);
            },3000);
          }
          else{
            this.alert.alertOpen(user.error,1);
          }
        });
      }
      else{
        this.alert.alertOpen("Passwords don't match",1);
      }
    }
  }
  /**
   Checking press keys in keyboard
   * @param event - event
   */
  keyPress(event: any) {
    const pattern = /[0-9\+\-\(\)\ ]/;
    let inputChar = String.fromCharCode(event.charCode);

    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }
}
