import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {map} from 'rxjs/operators';
import {environment} from '../../environments/environment';
import {Globals} from '../globals';

@Injectable()
export class PlaylistCategoriesService {

  httpOptions = {
    headers: new HttpHeaders({
      'Host': environment.dev_url_prefix + '/',
      'Authorization': 'Bearer ' + this.globals.access_token,
      'Accept': 'application/json'
    })
  };
  postOptions={
    headers: new HttpHeaders({
      'Host': environment.dev_url_prefix + '/',
      'Authorization': 'Bearer ' + this.globals.access_token,
      'Accept': 'application/json',
      'Content-Type':'application/x-www-form-urlencoded'
    })
  };
  deleteOptions={
    headers: new HttpHeaders({
      'Host': environment.dev_url_prefix + '/',
      'Authorization': 'Bearer ' + this.globals.access_token,
      'Accept': 'application/json',
      'Content-Type':'application/x-www-form-urlencoded'
    })
  }

  constructor(
    private http: HttpClient,
    private globals: Globals
  ) { }

  /**
   Get playlist categories/sub category
   * @param pcid - category id
   * @returns {Observable<any>}
   */
  getPlaylistsCategories(pcid) {
    var str='/'+pcid;
    if(pcid == ''){
      str = '';
    }
    return this.http.get(environment.dev_url_prefix + '/api/V1/foundations/tree' + str, this.httpOptions).pipe(map(playCats=>{
      return playCats;
    }));
  }

  /**
   Create new category/sub category
   * @param formData - post data
   * @param parentID - category parent id
   * @returns {Observable<any>}
   */
  createCategory(formData,parentID){
    var str='/'+parentID;
    if(parentID == ''){
      str = '';
    }
    return this.http.post(environment.dev_url_prefix + '/api/V1/foundations/create'+str,formData,this.postOptions).pipe(map(playlistsCats => {
      return playlistsCats;
    }));
  }

  /**
   Delete category/subcategory
   * @param cid
   * @returns {Observable<any>}
   */
  deleteCategory(cid){
    return this.http.delete(environment.dev_url_prefix + '/api/V1/foundations/delete/' + cid, this.deleteOptions).pipe(map(categoryDelete => {
      return categoryDelete;
    }));
  }

  /**
   Get playlist category/sub category information
   * @param pcid - playlist id
   * @returns {Observable<any>}
   */
  getPlaylistCategoriesByID(pcid){
    return this.http.get(environment.dev_url_prefix + '/api/V1/foundations/read/' + pcid, this.httpOptions).pipe(map(playCats=>{
      return playCats;
    }));
  }
  /**
   Update playlist category/sub category information
   * @param pcid - playlist id,
   * @param formData - post data
   * @returns {Observable<any>}
   */
  updatePlaylistCategory(pcid,formData){
    return this.http.put(environment.dev_url_prefix + '/api/V1/foundations/update/' + pcid, formData,this.postOptions).pipe(map(playlistCats => {
      return playlistCats;
    }));
  }
  
  deleteTracks(formData,subCategoryID){
    var str='/'+subCategoryID;
    if(subCategoryID == ''){
      str = '';
    }
    return this.http.post(environment.dev_url_prefix + '/api/V1/foundations/deleteTracks'+str,formData,this.postOptions).pipe(map(playlistsCats => {
      return playlistsCats;
    }));
  }

}
