import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {map} from 'rxjs/operators';
import { environment } from '../../environments/environment';
import {Globals} from '../globals';

@Injectable()
export class DistributionGroupService {

  httpOptions = {
    headers: new HttpHeaders({
      'Host': environment.dev_url_prefix + '/',
      'Authorization': 'Bearer ' + this.globals.access_token,
      'Accept': 'application/json'
    })
  };
  putOptions = {
    headers: new HttpHeaders({
      'Host': environment.dev_url_prefix + '/',
      'Authorization': 'Bearer ' + this.globals.access_token,
      'Accept': 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded'
    })
  };
  postOptions={
    headers: new HttpHeaders({
      'Host': environment.dev_url_prefix + '/',
      'Authorization': 'Bearer ' + this.globals.access_token,
      'Accept': 'application/json',
      'Content-Type':'application/x-www-form-urlencoded'
    })
  }
  deleteOptions = {
    headers: new HttpHeaders({
      'Host': '/',
      'Authorization': 'Bearer ' + this.globals.access_token,
      'Accept': 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded'
    })
  };

  constructor(
    private http: HttpClient,
    private globals: Globals
  ) { }

  /**
   Get all tracks
   * @param order - order of diplaying tracks
   * @param orderBy - ordering rule
   * @param offet - count for offset tracks
   * @param limit - limit tracks
   * @returns {Observable<any>}
   */
  getDGs(order,orderBy,offet,limit) {
    return this.http.get(environment.dev_url_prefix + '/api/V1/dgs/all/' + orderBy + '/' + order + '/' + offet + '/' + limit , this.httpOptions).pipe(map(dgs => {
      return dgs;
    }));
  }

  getAllDGs() {
    return this.http.get(environment.dev_url_prefix + '/api/V1/dgs/allList', this.httpOptions).pipe(map(dgs => {
      return dgs;
    }));
  }

  createDg(formData){
    return this.http.post(environment.dev_url_prefix + '/api/V1/dgs/create',formData,this.postOptions).pipe(map(dg => {
      return dg;
    }));
  }

  copyDg(formData){
    return this.http.post(environment.dev_url_prefix + '/api/V1/dgs/copy',formData,this.postOptions).pipe(map(dg => {
      return dg;
    }));
  }
  replaceMaster(formData){
    return this.http.post(environment.dev_url_prefix + '/api/V1/dgs/replaceMaster',formData,this.postOptions).pipe(map(res => {
      return res;
    }));
  }
  overwrite(formData){
    return this.http.post(environment.dev_url_prefix + '/api/V1/dgs/overwrite',formData,this.postOptions).pipe(map(res => {
      return res;
    }));
  }
  adjust(formData){
    return this.http.post(environment.dev_url_prefix + '/api/V1/dgs/adjust',formData,this.postOptions).pipe(map(res => {
      return res;
    }));
  }
  /**
   Get track information
   * @param tid - track id
   * @returns {Observable<any>}
   */
  getDgInfo(id){
    return this.http.get(environment.dev_url_prefix + '/api/V1/dgs/read/' + id, this.httpOptions).pipe(map(dg => {
      return dg;
    }));
  }

  /**
   Delete track
   * @param tid - track id
   * @returns {Observable<any>}
   */
  deleteDg(id){
    return this.http.delete(environment.dev_url_prefix + '/api/V1/dgs/delete/' + id, this.deleteOptions).pipe(map(dgDelete => {
      return dgDelete;
    }));
  }

  /**
   Update track information
   * @param formData - post data
   * @returns {Observable<any>}
   */
  updateDg(id, formData) {
    return this.http.put(environment.dev_url_prefix + '/api/V1/dgs/update/'+id, formData, this.putOptions).pipe(map(dataInfo=>{
      return dataInfo;
    }));
  }

  unlockProfile(id){
    return this.http.get(environment.dev_url_prefix + '/api/V1/dgs/unlock_profile/' + id, this.httpOptions).pipe(map(res => {
      return res;
    }));
  }

  unlockSchedule(id){
    return this.http.get(environment.dev_url_prefix + '/api/V1/dgs/unlock_schedule/' + id, this.httpOptions).pipe(map(res => {
      return res;
    }));
  }

  sync(master_id){
    return this.http.get(environment.dev_url_prefix + '/api/V1/dgs/sync/' + master_id, this.httpOptions).pipe(map(res => {
      return res;
    }));
  }
  
   distributionSearch(searchText){
    return this.http.get(environment.dev_url_prefix + '/api/V1/distribution_search/'+searchText, this.httpOptions).pipe(map(res=>{
      return res;
    }));
  }

  keep_as_same(formData){
    return this.http.post(environment.dev_url_prefix + '/api/V1/dgs/keepsame',formData,this.postOptions).pipe(map(res => {
      return res;
    }));
  }
  overwritesamename(formData){
    return this.http.post(environment.dev_url_prefix + '/api/V1/dgs/overwritesamename',formData,this.postOptions).pipe(map(res => {
      return res;
    }));
  }
}
