import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Globals} from '../globals';
import {map} from 'rxjs/operators';
import {environment} from '../../environments/environment';

@Injectable()
export class DashboardService{
  httpOptions = {
    headers: new HttpHeaders({
      'Host': environment.dev_url_prefix + '/',
      'Authorization': 'Bearer ' + this.globals.access_token,
      'Accept': 'application/json'
    })
  };
  postOptions={
    headers: new HttpHeaders({
      'Host': environment.dev_url_prefix + '/',
      'Authorization': 'Bearer ' + this.globals.access_token,
      'Accept': 'application/json',
      'Content-Type':'application/x-www-form-urlencoded'
    })
  };

  constructor(
    private http: HttpClient,
    private globals: Globals
  ) { }

  /**
   Get editing playlists
   * @param uid - logged user id
   * @returns {Observable<any>}
   */
  getInProgressPlaylists(uid){
    return this.http.get(environment.dev_url_prefix + '/api/V1/dashboard/editing_playlists/'+uid, this.httpOptions).pipe(map(playlists => {
      return playlists;
    }));
  }
  /**
   Get foundation playlists
   * @returns {Observable<any>}
   */
  getFoundationPlaylists(){
    return this.http.get(environment.dev_url_prefix + '/api/V1/dashboard/my_fp_playlists', this.httpOptions).pipe(map(playlists => {
      return playlists;
    }));
  }
  /**
   Get edited playlists
   * @returns {Observable<any>}
   */
  getPlaylistsEdited(){
    return this.http.get(environment.dev_url_prefix + '/api/V1/dashboard/playlist_edited', this.httpOptions).pipe(map(edited_playlists => {
      return edited_playlists;
    }));
  }
  /**
   Get playlists deployed today
   * @returns {Observable<any>}
   */
  getPlaylistsDeployedToday(){
    return this.http.get(environment.dev_url_prefix + '/api/V1/dashboard/playlist_today_dpld', this.httpOptions).pipe(map(deployed_today => {
      return deployed_today;
    }));
  }
  /**
   Get playlists edited today
   * @returns {Observable<any>}
   */
  getPlaylistsEditedToday(){
    return this.http.get(environment.dev_url_prefix + '/api/V1/dashboard/playlist_today_edtd', this.httpOptions).pipe(map(edited_today => {
      return edited_today;
    }));
  }
  getDataForRecentUpdatesGraph(){
    return this.http.get(environment.dev_url_prefix + '/api/V1/dashboard/graph_data_recent_updates', this.httpOptions).pipe(map(recent_updates_data => {
      return recent_updates_data;
    }));
  }

  /**
   Get blocks information
   * @returns {Observable<any>}
   */
  getOrderedBlocks(){
    return this.http.get(environment.dev_url_prefix + '/api/V1/dashboard/block_list', this.httpOptions).pipe(map(blocks => {
      return blocks;
    }));
  }

  /**
   Set position for block
   * @param id - block id
   * @param pos - block position
   * @returns {Observable<any>}
   */
  setBlockPosition( id, pos ){
    return this.http.get(environment.dev_url_prefix + '/api/V1/dashboard/move/' + id + '/' + pos, this.httpOptions).pipe(map(blocks => {
      return blocks;
    }));
  }

  /**
   Get updated playlists by period
   * @param beginDate - start date
   * @param endDate - end date
   * @returns {Observable<any>}
   */
  getUpdatesByPerion(beginDate,endDate){
    return this.http.get(environment.dev_url_prefix + '/api/V1/dashboard/playlist_updates/' + beginDate + '/' + endDate, this.httpOptions).pipe(map(playlists => {
      return playlists;
    }));
    
  }

  /**
   Get cutomers
   * @returns {Observable<any>}
   */
  getCustomers(){
    return this.http.get(environment.dev_url_prefix + '/api/V1/dashboard/my_customers', this.httpOptions).pipe(map(customers => {
      return customers;
    }));
  }

  syncOdbapi(type){
    let url = '';
    if(type == 'clip'){
      url = '/api/V1/odbapi/syncDB';
    }
    return this.http.get(environment.dev_url_prefix + url, this.httpOptions).pipe(map( result => {
      return result;
    }));
  }

  getFailedHawks(hawk_Type, order, orderBy, offset, limit){
    return this.http.get(environment.dev_url_prefix + '/api/V1/odbapi/failed-hawks/'+hawk_Type+'/'+ offset + '/' + limit + '/' + orderBy + '/' + order, this.httpOptions).pipe(map( result => {
      return result;
    }));
  }

  getFailedBuilds(order, orderBy, offset, limit){
    return this.http.get(environment.dev_url_prefix + '/api/V1/odbapi/failed-builds/'+ offset + '/' + limit + '/' + orderBy + '/' + order, this.httpOptions).pipe(map( result => {
      return result;
    }));
  }
  
  getlogdata(start_date,end_date,value:any){
  	return this.http.get(environment.dev_url_prefix + '/api/V1/dashboard/date_data/'+ start_date +'/'+ end_date +'/'+value , this.httpOptions).pipe(map( result => {
      return result;
    }));
  }
  
   getlogchanneldata(channel){
   	return this.http.get(environment.dev_url_prefix + '/api/V1/dashboard/log_channel_data/'+ channel  , this.httpOptions).pipe(map( result => {
      return result;
    }));
   }
   
    getlogplaylistdata(playlist){
   	return this.http.get(environment.dev_url_prefix + '/api/V1/dashboard/log_playlist_data/'+ playlist  , this.httpOptions).pipe(map( result => {
      return result;
    }));
   }
   
}
