<app-alert></app-alert>
<app-confirm (isConfirm)="isConfirm($event)"></app-confirm>
<!-- LOADER -->
<div class="fade" [ngClass]="{ opened: isLoad }"></div>
<img
  src="../../assets/img/loader.gif"
  alt="loader"
  class="loader"
  [ngClass]="{ active: isLoad }"
/>

<div class="ph_block">
  <!-- HEADER -->
  <app-page-header
    [pageTitle]="'Create, edit and manage your distribution groups'"
  ></app-page-header>
  <!-- CREATE BUTTON -->
  <div class="top_actions_box top_box" *ngIf="!showForm">
    <div class="main_box">
      <h2>
        Distribution Group - List
        <button
          style="float: right"
          (click)="openCreateDgPopup($event)"
          title="Create new distribution group"
        >
          Create New
        </button>
      </h2>
    </div>

    <div style="margin-bottom: 5px">
      <div class="search_box">
        <form method="get" #searchForm="ngForm">
          <input
            type="text"
            name="search"
            (focus)="focusInput()"
            (blur)="blurInput()"
            value="{{ distributionSearchStr }}"
            placeholder="search"
            class="searchInput no-outline"
            [(ngModel)]="distributionSearchStr"
            maxlength="30"
          />
          <button
            type="submit"
            class="searchBtn"
            (click)="distributionSearch($event)"
          ></button>
          <a
            href="#"
            class="clear_search"
            (click)="clearDistributionSearch($event)"
          ></a>
        </form>
      </div>
      <div class="dgs_count">
        Displaying {{ dgsCount.display }}/{{ dgsCount.total }} Records
      </div>
    </div>
  </div>

  <!-- TABLE START -->
  <div class="dgs_list_block table_wrap" *ngIf="!showForm">
    <div class="table_titles">
      <div
        class="order"
        (click)="setOrder('name')"
        [ngClass]="{
          active: dgsOrder.orderBy == 'name',
          asc: dgsOrder.order == 'ASC'
        }"
      >
        Name
      </div>
      <div
        class="order"
        (click)="setOrder('created_at')"
        [ngClass]="{
          active: dgsOrder.orderBy == 'created_at',
          asc: dgsOrder.order == 'ASC'
        }"
      >
        Created At
      </div>
      <div
        class="order"
        (click)="setOrder('updated_at')"
        [ngClass]="{
          active: dgsOrder.orderBy == 'updated_at',
          asc: dgsOrder.order == 'ASC'
        }"
      >
        Updated At
      </div>
      <div
        class="order"
        (click)="setOrder('created_by')"
        [ngClass]="{
          active: dgsOrder.orderBy == 'created_by',
          asc: dgsOrder.order == 'ASC'
        }"
      >
        Created By
      </div>
      <div
        class="order"
        (click)="setOrder('updated_by')"
        [ngClass]="{
          active: dgsOrder.orderBy == 'updated_by',
          asc: dgsOrder.order == 'ASC'
        }"
      >
        Updated By
      </div>
      <div>Actions</div>
    </div>
    <div
      class="table_content"
      *ngIf="dgs.length > 0"
      infinite-scroll
      [infiniteScrollDistance]="2"
      [infiniteScrollThrottle]="300"
      [scrollWindow]="false"
      (scrolled)="onScroll($event)"
    >
      <div class="table_row" *ngFor="let dg of dgs; let i = index">
        <div>{{ dg.name }}</div>
        <div>{{ dg.created_at }}</div>
        <div>{{ dg.updated_at }}</div>
        <div>{{ dg.full_name_created }}</div>
        <div>{{ dg.full_name_edited }}</div>
        <div>
          <a
            class="dgs_edit"
            title="Edit"
            (click)="editDg(dg.id)"
            routerLink="/edit-dg"
          ></a>
          <a
            class="dgs_delete"
            title="Delete"
            href="#"
            (click)="openConfirmPopup(dg.id, 1)"
          ></a>
        </div>
      </div>
    </div>
    <div class="table_content nf" *ngIf="dgs.length <= 0 && !isLoad">
      <span>No results found</span>
    </div>
  </div>
  <!-- TABLE END -->

  <!-- EDIT FORM START -->
  <div [ngClass]="{ closed: !showForm }" class="top_actions_box top_box">
    <div class="main_box">
      <h2>Distribution Group - Add/Edit</h2>
    </div>
    <div style="margin-bottom: 5px">
      <div>Add new channels to the distribution group</div>
    </div>
  </div>

  <div class="dg_form" *ngIf="showForm">
    <form #dgForm="ngForm" style="padding: 0px 10px;border: 1px solid #e6e6e6;margin: 10px 0px;">
      <div class="popup_btns" style="margin: 10px">
        <button type="button" class="cancel" (click)="goBack()">Cancel</button>
        <button
          style="padding: 0px 20px"
          type="submit"
          (click)="updateDg(dgForm)"
        >
          Save
        </button>
      </div>
      <div class="form_column">
        <div class="input_wrap">
          <label>Name</label>
          <input
            type="text"
            value="{{ dg.name }}"
            [(ngModel)]="dg.name"
            name="name"
          />
        </div>
        <div class="input_wrap dg_entities">
          <label>Distribution group entities:</label>
          <div class="foundation_playlist_selects" style="min-width: 900px">
            <div
              class="filter_line row"
              style="
                border: 1px solid #eaeaea;
                padding: 0px 10px 10px;
                background: #ededed;
              "
            >
              <div style="width: 30%">
                <label style="font-size: 10px">Client</label>
                <select
                  style="width: 90%; margin-right: 10px"
                  name="client_id"
                  [(ngModel)]="addForm.client_id"
                  (ngModelChange)="addForm.client_id = $event ? $event : '0'"
                  (change)="getSites(addForm.client_id, 0)"
                >
                  <option value="0">-select client-</option>
                  <option
                    *ngFor="let client of clients"
                    value="{{ client.id }}"
                  >
                    {{ client.first_name }}
                  </option>
                </select>
              </div>
              <div style="width: 30%">
                <label style="font-size: 10px">Site</label>
                <select
                  style="width: 90%; margin-right: 10px"
                  name="site_id"
                  [(ngModel)]="addForm.site_id"
                  (ngModelChange)="addForm.site_id = $event ? $event : '0'"
                  (change)="getChannels(addForm.site_id, 0)"
                >
                  <option value="0">-select site-</option>
                  <option *ngFor="let site of sites" value="{{ site.id }}">
                    {{ site.name }}
                  </option>
                </select>
              </div>
              <div style="width: 30%">
                <label style="font-size: 10px">Channel</label>
                <select
                  style="width: 90%; margin-right: 10px"
                  name="channel_id"
                  [(ngModel)]="addForm.channel_id"
                  (ngModelChange)="addForm.channel_id = $event ? $event : '0'"
                >
                  <option value="0">-select channel-</option>
                  <option
                    *ngFor="let channel of channels"
                    value="{{ channel.id }}"
                  >
                    {{ channel.name }}
                  </option>
                </select>
              </div>
              <div
                class="func_col"
                style="width: 10%; text-align: center; margin-top: 10px"
              >
                <button type="button" (click)="addRecord()">Add</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
    <div class="popup_btns" style="float: right; margin-bottom:10px;">
      <button (click)="Export()" title="Export">Export</button>
      <button type="submit" (click)="buildAll()">Build All</button>
      <button
        style="padding: 0px 6px 1px 8px"
        type="submit"
        (click)="hawkAll()"
      >
        Hawk All
      </button>
    </div>
    <div>{{dg.entities.length}} records</div>
    <div class="foundation_playlist_selects" style="min-width: 900px">
      <div class="row titles">
        <div style="width: 20%">Client</div>
        <div style="width: 20%">Site</div>
        <div style="width: 27%">Channel</div>
        <div style="width: 10%">Build status</div>
        <div style="width: 10%">Hawk status</div>
        <div style="width: 10%; text-align: center">Action</div>
        <div style=""></div>
      </div>
      <div *ngIf="dg.entities.length > 0">
        <div
          class="filter_line row"
          *ngFor="let dge of dg.entities; let i = index"
        >
          <div style="width: 20%">
            {{ dge.client_name }}
          </div>
          <div style="width: 20%">
            {{ dge.site_name }}
          </div>
          <div style="width: 27%">
            <a
              href="javascript:void(0)"
              (click)="
                goToLink($event, dge.client_id, dge.site_id, dge.channel_id)
              "
              >{{ dge.channel_name }}</a
            >
            <ng-template #MasterSchTooltipContent>
              <div style="font-size: 12px !important;">
                Channels :
                <ul *ngFor="let mas of dge.mastersdata">
                  <li>{{ mas.name }}</li>
                </ul>
              </div>
            </ng-template>

            <span
              [options]="tooltipOptions"
              [tooltip]="MasterSchTooltipContent"
              contentType="template"
              *ngIf="dge.masterlength != 0"
              class="master_icon"
              >{{ dge.masterlength }}</span
            >
          </div>
          <div style="width: 10%; white-space: nowrap">
            <div *ngIf="dge.build" style="width: 100%;">
              <progress-bar [progress]="progressValue[i]" [color-degraded]="{'0': '#f00e0e',  '25': '#f35b08', '50': '#f2be0e' , '75': '#04db52'}">
              </progress-bar>
            </div>
            <div *ngIf="!dge.build && dge.buildResponse">
              <!-- <div *ngFor="let br of dge.buildResponse"> -->
                <!-- <div *ngIf="dge.channel_id == br.channel_id"> -->
                  {{
                    dge.buildResponse.response &&
                    dge.buildResponse.response.statusMessage.returnvalue &&
                    ( dge.buildResponse.response.statusMessage.returnvalue.NumberOfClips !=
                      ConvertToInt(dge.buildResponse.clip_count) ||
                      dge.buildResponse.response.statusMessage.returnvalue
                        .NumberOfProfiles != dge.buildResponse.profile_count
                    ) ? "Failed"
                      : dge.buildResponse.response && dge.buildResponse.response.statusCode == 200
                          ? "Success" 
                          : dge.buildResponse.response == null  ? "" : "Failed"
                  }}
                  <!-- In Progress dge.buildResponse != "" ? dge.buildResponse -->
                <!-- </div> -->
              <!-- </div> -->
            </div>
            <!-- <div *ngIf="statuschange != null">
              <div *ngIf="dge.channel_id === statuschange; else logprocess">
                {{dge.channel_id}} - {{statuschange}}
                Processing...
              </div>
              <ng-template #logprocess>
                <div *ngFor="let log of logResponse">
                  <div *ngIf="dge.channel_id == log.channel_id">
                    {{
                      log.response &&
                      log.response.statusMessage.returnvalue &&
                      (log.response.statusMessage.returnvalue.NumberOfClips !=
                        ConvertToInt(log.clip_count) ||
                        log.response.statusMessage.returnvalue
                          .NumberOfProfiles != log.profile_count)
                        ? "Failed"
                        : log.response && log.response.statusCode == 200
                        ? "Success"
                        : log.response == null
                        ? "In Progress"
                        : "Failed"
                    }}
                  </div>
                </div>
              </ng-template>
            </div> -->

            <!-- <div
              class="col-1"
              *ngIf="statuschange == null"
            >
              <div *ngIf="buildallstatuschange; else notstatuschange">
                Processing...
              </div>
              <ng-template #notstatuschange>
                <div *ngFor="let log of logResponse">
                  <div *ngIf="dge.channel_id == log.channel_id">
                    {{
                      log.response &&
                      log.response.statusMessage.returnvalue &&
                      (log.response.statusMessage.returnvalue.NumberOfClips !=
                        ConvertToInt(log.clip_count) ||
                        log.response.statusMessage.returnvalue
                          .NumberOfProfiles != log.profile_count)
                        ? "Failed"
                        : log.response && log.response.statusCode == 200
                        ? "Success"
                        : log.response == null
                        ? "In Progress"
                        : "Failed"
                    }}
                  </div>
                </div>
              </ng-template>
            </div> -->
          </div>
          <div style="width: 10%; white-space: nowrap">
            <div *ngIf="dge.hawk">
              Processing...
            </div>
            <div *ngIf="!dge.hawk && dge.hawkResponse">
              {{
                dge.hawkResponse.hawk_status == 1
                  ? "Success"
                  : dge.hawkResponse.hawk_status == 0
                  ? "In Progress"
                  : dge.hawkResponse.hawk_status == null
                  ? ""
                  : "Failed"
              }}
            </div>

            <!-- <div *ngIf="hawkstatuschange != null">
              <div *ngIf="dge.channel_id == hawkstatuschange; else logprocess">
                Processing...
              </div>
              <ng-template #logprocess>
                <div *ngFor="let log of logResponse">
                  <div *ngIf="dge.channel_id == log.channel_id">
                    {{
                      log.hawk_status == 1
                        ? "Success"
                        : log.hawk_status == 0
                        ? "In Progress"
                        : log.hawk_status == null
                        ? ""
                        : "Failed"
                    }}
                  </div>
                </div>
              </ng-template>
            </div> -->

            <!-- <div
              class="col-1"
              style="text-align: center"
              *ngIf="hawkstatuschange == null"
            > -->
              <!-- <div *ngIf="hawkAllstatuschange; else notstatushawkchange">
                Processing...
              </div>
              <ng-template #notstatushawkchange>
                <div *ngFor="let log of logResponse">
                  <div *ngIf="dge.channel_id == log.channel_id">
                    {{
                      log.hawk_status == 1
                        ? "Success"
                        : log.hawk_status == 0
                        ? "In Progress"
                        : log.hawk_status == null
                        ? ""
                        : "Failed"
                    }}
                  </div>
                </div>
              </ng-template> -->
            <!-- </div> -->
          </div>
          <div class="func_col" style="width: 10%; text-align: center">
           
            <button
              type="button"
              style="margin: 0px 12px 0px 1px"
              class="cancel"
              *ngIf="dge.showbuildbutton != 0"
              (click)="build(dge.channel_id,i)"
            >
              build
            </button>
            <button
              style="margin: 0px 12px 0px 1px"
              type="submit"
              (click)="hawk(dge.site_id, dge.channel_id,i)"
              *ngIf="dge.showhawkbutton != 0"
            >
              hawk
            </button>
          </div>
          <div style="width: 3%">
            <a
              href="#"
              class="btn_remove"
              (click)="removeEntity(i, dge.id, true)"
            ></a>
          </div>
        </div>
      </div>
      <div
        class="filter_line row"
        style="text-align: center"
        *ngIf="dg.entities.length <= 0"
      >
        <span>No results found</span>
      </div>
    </div>
  </div>
  <!-- EDIT FORM END -->
</div>

<!-- CREATE POPUP -->
<div
  class="popup create_dg_popup"
  [ngClass]="{ opened: createPopupOpened }"
  ngDraggable
  ngResizable
  [rzHandles]="'n,e,s,w,se,sw,ne,nw'"
  [handle]="dragHandle"
>
  <a href="#" class="popup_close" (click)="popupClose($event)"></a>
  <div class="popup_title" #dragHandle>Create/Edit Distribution Group</div>
  <div class="popup_form">
    <form #createDgForm="ngForm">
      <div class="input_wrap">
        <label>Name</label>
        <input
          autocomplete="off"
          type="text"
          maxlength="100"
          name="name"
          value="{{ dgFormFields.name }}"
          [(ngModel)]="dgFormFields.name"
        />
      </div>
      <div class="popup_btns">
        <button type="button" class="cancel" (click)="popupClose($event)">
          Cancel
        </button>
        <button
          type="submit"
          (click)="createDg($event, createDgForm); createDgForm.resetForm()"
        >
          Save
        </button>
      </div>
    </form>
  </div>
</div>
