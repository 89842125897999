<app-page-header [pageTitle]="'Create, edit and manage your sample playlists'"></app-page-header>
<div class="top_box">
    <div class="mf_buttons">
        <div class="search_box">
            <form method="get" #searchForm="ngForm">
                <input type="text" name="search" (focus)="focusInput()" (blur)="blurInput()" value="{{ searchStr }}"
                    placeholder="Playlist search" class="searchInput" [(ngModel)]="searchStr" maxlength="30" />
                <button type="submit" class="searchBtn" (click)="search($event)"></button>
                <a href="#" class="clear_search" (click)="clearSearch($event)"></a>
            </form>
        </div>
        <div class="search_box">
            <form method="get" #searchForm="ngForm">
                <input type="text" name="search" (focus)="focusInput()" (blur)="blurInput()" value="{{entitySearchStr}}"
                    placeholder="Entity search" class="searchInput" [(ngModel)]="entitySearchStr" maxlength="30" />
                <button type="submit" class="searchBtn" (click)="entitySearch($event)"></button>
                <a href="#" class="clear_search" (click)="clearEntitySearch($event)"></a>
            </form>
        </div>
        <button *ngIf="current_user!=null&&(current_user.type=='Consultant'||current_user.type=='Administrator')"
            class="create_user_btn" (click)="OpenClientPopup()" title="New Client">Create New Client</button>

        <button *ngIf="current_user!=null&&(current_user.type=='Consultant'||current_user.type=='Administrator')"
            class="create_subcategory_btn" (click)="openChanelsPopup($event,null)" title="New Channel">Create New
            Channel</button>
        <button *ngIf="current_user!=null&&(current_user.type=='Consultant'||current_user.type=='Administrator')"
            class="create_playlist_btn" (click)="openPlaylistPopup($event,null)" title="New Playlist">Create New
            Playlist</button>

    </div>
</div>

<div class="foundation_playlist_main">
    <div class="breadcrumbs">
        <a href="#" (click)="resetCheckedElements(1)">Client Sample Playlists</a>
        <a href="#" *ngIf="checkedUserId.name!=''" (click)="resetCheckedElements(2)">{{checkedUserId.name}}</a>
        <a href="#" *ngIf="checkedSiteId.name!=''" (click)="resetCheckedElements(3)">{{checkedSiteId.name}}</a>
        <a href="#" *ngIf="checkedChanelId.name!=''" (click)="resetCheckedElements(4)">{{checkedChanelId.name}}</a>

    </div>
    <div class="left_block"
        [ngStyle]="entitySearchResult.length > 0 && {'width': '210px'} || tabs.selected && {'width':'210px'}">
        <div class="users_block box" *ngIf="entitySearchResult.length==0">
            <ul>
                <li *ngFor="let u of usersList" (click)="clickedInside($event,u.id)"
                    [ngClass]="{opened: checkedUserId.id == u.id}" id="user-list-{{u.id}}">
                    <!-- <ng-template #ClientPopupContent>
                        <span style="font-size: 12px !important;">Entity ID : {{u.id}}</span>
                    </ng-template> -->
                    <span contentType="template" (click)="getSites(u.id, u.name ,1)">{{u.name}}</span>
                    <div class="actions_wrap">
                        <a href="#" class="arrow" (click)="getSites(u.id, u.name ,1)"></a>
                        <!-- <ul class="actions_popup"
                            *ngIf="isShowActions.number==1&&current_user!=null&&(current_user.type=='Consultant'||current_user.type=='Administrator')">
                            <li>
                                <a href="#" class="users_edit" (click)="openUserPopup($event,u.id)"></a>
                            </li>
                            <li>
                                <a href="#" class="users_delete" (click)="openConfirmPopup(u.id,0)"></a>
                            </li>
                        </ul> -->
                    </div>
                </li>
                <!-- <div id="user-list">Test</div> -->
            </ul>

        </div>
        <div class="playlists_block box"
            *ngIf="checkedUserId.id!=null && entitySearchResult.length==0 && !tabs.selected">
            <ul *ngIf="sitesList!=null">
                <li *ngFor="let site of sitesList" (click)="clickedInside($event)"
                    [ngClass]="{opened: checkedSiteId.id == site.id}">
                    <!-- , site_hidden: checkEntityFilterSite(site) -->
                    <div class="wrap">
                        <!-- <ng-template #SitePopupContent>
                            <span style="font-size: 12px !important;">Entity ID : {{site.entity_id}} <br>
                                Parent Entity ID : {{site.parent_entity_id}} <br>
                                Parent Entity Name : {{site.parent_entity_name}} <br>
                                Total tracks : {{site.total_tracks}}
                            </span>
                        </ng-template> -->
                        <span contentType="template"
                            (click)="showChanels($event,site.id,site.name,1)">{{site.name}}</span>

                    </div>
                    <div class="actions_wrap">
                        <a href="#" class="arrow" (click)="showChanels($event,site.id,site.name,1)"></a>
                        <ul class="actions_popup"
                            *ngIf="isShowActions.number==2&&current_user!=null&&(current_user.type=='Consultant'||current_user.type=='Administrator')">
                            <li>
                                <a href="#" class="playlist_categories_edit"
                                    (click)="openPopUpforEdit($event,site.id,site.name)"></a>
                            </li>
                            <li>
                                <a href="#" class="playlist_categories_delete"
                                    (click)="openConfirmPopup( site.id, 1)"></a>
                            </li>
                            <li>
                                <a href="#"
                                    *ngIf="current_user!=null&&(current_user.type=='Consultant'||current_user.type=='Administrator')"
                                    class="playlist_restore" (click)="openFileUploadPopup(site.id)"></a>
                            </li>
                        </ul>
                    </div>
                    <ul *ngIf="site.children !=0">
                        <li *ngFor="let chanel of site.children" (click)="clickedInside($event)"
                            [ngClass]="{opened: checkedChanelId.id == chanel.id}">
                            <!-- <ng-template #ChannelPopupContent>
                                <span style="font-size: 12px;">Entity ID : {{chanel.entity_id}} <br>
                                    Parent Entity ID : {{site.entity_id}} <br>
                                    Parent Entity Name : {{site.name}} <br>
                                    Total Tracks : {{chanel.countDetails.clip_count}}
                                </span>
                            </ng-template> -->
                            <span [tooltip]="ChannelPopupContent" [options]="tooltipOptions" contentType="template"
                                (click)="showPlaylists($event,chanel.id,chanel.name, 1)">{{chanel.name}}</span>
                            <div class="actions_wrap">
                                <a href="#" class="arrow" (click)="showPlaylists($event,chanel.id,chanel.name, 1)"></a>
                                <ul class="actions_popup"
                                    *ngIf="isShowActions.number==3&&current_user!=null&&(current_user.type=='Consultant'||current_user.type=='Administrator')">
                                    <li>
                                        <a href="#" class="playlist_categories_edit"
                                            (click)="openChanelsPopup($event,chanel.id)"></a>
                                    </li>
                                    <li>
                                        <a href="#" class="playlist_categories_delete"
                                            (click)="openConfirmPopup( chanel.id, 2)"></a>
                                    </li>
                                    <li>
                                        <a href="#" class="playlist_categories_restore"
                                            (click)="openRestoredDatesPopup($event,chanel.id)"></a>
                                    </li>

                                </ul>
                            </div>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>
        <div class="playlists_block box" *ngIf="entitySearchResult.length > 0">
            <ul>
                <li *ngFor="let site of entitySearchResult" (click)="clickedInside($event)"
                    [ngClass]="{opened: checkedSiteId.id == site.id}">
                    <div class="wrap">
                        <ng-template #SitePopupContent>
                            <span style="font-size: 12px !important;">Entity ID : {{site.entity_id}} <br>
                                Parent Entity ID : {{site.parent_entity_id}} <br>
                                Parent Entity Name : {{site.parent_entity_name}} <br>
                                Total tracks : {{site.total_tracks}}
                            </span>
                        </ng-template>
                        <span [tooltip]="SitePopupContent" [options]="tooltipOptions"
                            (click)="showChanels($event,site.id,site.name,1)"
                            contentType="template">{{site.name}}</span>

                    </div>
                    <div class="actions_wrap">
                        <a href="#" class="arrow" (click)="showChanels($event,site.id,site.name,1)"></a>
                        <ul class="actions_popup"
                            *ngIf="isShowActions.number==2&&current_user!=null&&(current_user.type=='Consultant'||current_user.type=='Administrator')">
                            <li>
                                <a href="#" class="playlist_categories_edit"
                                    (click)="openSitePopup($event,site.id)"></a>
                            </li>
                            <li>
                                <a href="#" class="playlist_categories_delete"
                                    (click)="openConfirmPopup( site.id, 1)"></a>
                            </li>
                            <li>
                                <a href="#"
                                    *ngIf="current_user!=null&&(current_user.type=='Consultant'||current_user.type=='Administrator')"
                                    class="playlist_categories_upload" (click)="openFileUploadPopup(site.id)"></a>
                            </li>
                        </ul>
                    </div>
                    <ul *ngIf="site.children !=undefined">
                        <li *ngFor="let channel of site.children" (click)="clickedInside($event)"
                            [ngClass]="{opened: checkedChanelId.id == channel.id}">
                            <ng-template #ChannelPopupContentAlpha>
                                <span style="font-size: 12px;">Entity ID : {{channel.entity_id}} <br>
                                    Parent Entity ID : {{site.entity_id}}<br>
                                </span>
                            </ng-template>
                            <span [tooltip]="ChannelPopupContentAlpha" [options]="tooltipOptions" contentType="template"
                                (click)="showPlaylists($event,channel.id,channel.name, 1)">{{channel.name}}</span>
                            <div class="actions_wrap">
                                <a href="#" class="arrow"
                                    (click)="showPlaylists($event,channel.id,channel.name, 1)"></a>
                                <ul class="actions_popup"
                                    *ngIf="isShowActions.number==3&&current_user!=null&&(current_user.type=='Consultant'||current_user.type=='Administrator')">
                                    <li>
                                        <a href="#" class="playlist_categories_edit"
                                            (click)="openChanelsPopup($event,channel.id)"></a>
                                    </li>
                                    <li>
                                        <a href="#" class="playlist_categories_delete"
                                            (click)="openConfirmPopup( channel.id, 2)"></a>
                                    </li>
                                    <li>
                                        <a href="#" class="playlist_categories_restore"
                                            (click)="openRestoredDatesPopup($event,channel.id)"></a>
                                    </li>
                                </ul>
                            </div>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>
    </div>



    <!-- Commented by Abhimanyu
    As per requirement of CR58  -->

    <!-- <div class="playlists_list_block" *ngIf="showClientProperties && current_user!=undefined&& tabs.selected"
        [ngClass]="{'client_playlist_block': current_user.type != 'Administrator' && current_user.type != 'Manager' && current_user.type != 'Consultant'}"
        [ngStyle]="entitySearchResult.length > 0 ">
        <ul class="tabs">
            <li style="background: white;">CLIENT PROPERTIES</li>
        </ul>
        <div class="table_wrap" [ngClass]="{selected: tabs.selected == true}">

            <div style="min-height: calc(91vh - 250px);margin-bottom: 60px;">

                <div class="commentwrapper">
                    <div class="form_cols">
                        <div class="buttonsWrapper">
                            <div class="input_wrap proprtyselect">
                                <label>Property Type</label>
                                <select id="selectlist" style="margin-left: -17%;" (change)="setselectvalue($event)"
                                    [(ngModel)]="setpropertytype">
                                    <option value="null">Please Select</option>
                                    <option value="hotel-city">hotel-city</option>
                                    <option value="hotel-beach">hotel-beach</option>
                                    <option value="restaurant">Restaurant</option>
                                    <option value="retail">Retail</option>
                                </select>
                            </div>
                            <div class="input_wrap">
                                <label>Upload Client Background</label>
                                 <input type="file" class="file-input" multiple="false"
                                    (change)="imageUploadSelected($event)" #imageUpload
                                    accept="image/png, image/gif, image/jpeg">
                                <button mat-mini-fab color="primary" class="upload-btn" (click)="imageUpload.click()">
                                    <mat-icon>Upload Image</mat-icon>
                                </button>

                                <div class="dropzone_wrap">
                                    <dropzone [config]="imageconfig" [message]="'Upload photo'" #imageDropZone
                                        (error)="onUploadError($event)" (success)="onUploadSuccess($event)"
                                        (sending)="onSending($event)">
                                        <div class="filenameformation">
                                            <a href="#" (click)="imagedownload(propertyvalue?.image)">
                                                <div>{{propertyvalue?.image || "No file uploaded yet."}}</div>

                                            </a>
                                        </div>
                                    </dropzone>

                                </div>

                            </div>

                            <div class="input_wrap" style="margin-top: -25px;">
                                <label>Upload Sample Deck</label>
                                <input type="file" class="file-input" multiple="false" (change)="onFileSelected($event)"
                                    #fileUpload accept="application/pdf">
                                <button mat-mini-fab color="primary" class="upload-btn" (click)="fileUpload.click()">

                                    <mat-icon>Upload PDF</mat-icon>

                                </button>

                                <div class="dropzone_wrap">
                                    <dropzone [config]="Pdfconfig" [message]="'Upload PDF'" #PDFDropZone
                                        (error)="onUploadError($event)" (success)="onUploadSuccess($event)"
                                        (sending)="onSending($event)">
                                        <a href="#" class="filenameformation" style="cursor:pointer"
                                            (click)=pDFdownload(propertyvalue?.deck)>
                                            <div>
                                                {{propertyvalue?.deck || "No file uploaded yet."}}
                                            </div>
                                        </a>
                                    </dropzone>
                                </div>
                            </div>
                            <div class="input_wrap">
                                <label>Preview Client Site</label>
                                <div class="share_button">

                                    <button (click)="generate_link($event,selectedClientPlaylist)">Share</button>
                                    <button (click)="previewPage(selectedClientPlaylist)" >Preview</button>
                                </div>
                            </div>
                        </div>
                        <div class="input_wrap">
                            <label>Comments </label>
                            <div class="input_group" style="
                            display: flex;
                            align-items: center;
                            flex-direction: column;
                            gap: 10px;
                            margin-left: -11px;">
                                <textarea #comments ng-maxlength="5"  style="font-family: 'Adobe Caslon Pro', serif;"
                                    [(ngModel)]="propertycomments"></textarea>
                                <button *ngIf="property_comment_id==''"
                                    style="height: 100%;margin-left: 67%;width: 70px; line-height: none; background-color: #c0bfbf;"
                                    (click)="addComments(comments.value)"> Ok</button>
                                <button *ngIf="property_comment_id"
                                    style="height: 100%;margin-left: 67%;width: 70px; line-height: none; background-color: #c0bfbf;"
                                    (click)="editcommentsBYId(comments.value)"> Ok</button>
                            </div>
                        </div>
                    </div>
                    <div class="commentbox">
                        <div class="container" *ngIf="propertyvalue?.comments.length > 0;">
                            <div class="header">
                                <div class="grp-info">
                                    <h3 class="grp-name">
                                        Comments
                                    </h3>
                                </div>
                            </div>
                            <div class="all_chat">
                                <div class="chatbox" *ngFor="let c of propertyvalue.comments">
                                    <div class="chat">
                                        <div class="msg-bubble">
                                            <div class="msg">
                                                <div class="commentdiv">
                                                    {{ c.comments }}
                                                </div>
                                                <div class="msg-info">

                                                    <div class="usertime">
                                                        <div class="msg-info-name">{{ c.commentscreateduser.first_name
                                                            }}</div>
                                                        <div class="msg-info-time">{{ c.updated_at | date:'dd/MM/yyyy
                                                            h:m a' }}</div>
                                                    </div>
                                                    <div class="comment_action"
                                                        *ngIf="current_user!=null&&(current_user.type=='Consultant'||current_user.type=='Administrator')">

                                                        <button [options]="tooltipOptions" [tooltip]="edittimepopUp"
                                                            contentType="template" href="#" class="editcomment"
                                                            *ngIf="current_user.id==c.created_by"
                                                            (click)="editComments(c.id,c.comments)"></button>
                                                        <div>
                                                            <ng-template #edittimepopUp>
                                                                <span style="font-size: 12px !important; ">Updated at
                                                                    {{c.updated_at | date:'dd/MM/yyyy h:m a' }}</span>
                                                            </ng-template>
                                                        </div>

                                                        <button href="#" class="clear_search"
                                                            *ngIf="current_user.id==c.created_by"
                                                            (click)="deletecomment(c.id)"></button>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> -->


    <div class="playlists_list_block" *ngIf="!showClientProperties && current_user!=undefined&&checkedSiteId.id != null"
        [ngClass]="{'client_playlist_block': current_user.type != 'Administrator' && current_user.type != 'Manager' && current_user.type != 'Consultant'}"
        [ngStyle]="entitySearchResult.length > 0 ">
        <ul class="tabs">
            <li [ngClass]="{selected: channeltabs.selected == 1}" (click)="selectTab(1)">Playlists</li>
            <li [ngClass]="{selected: channeltabs.selected == 2}" (click)="selectTab(2)">Archive</li>
        </ul>
        <div class="table_wrap" [ngClass]="{selected: channeltabs.selected == 1}">
            <div class="table_titles">
                <div class="order" (click)="setOrderTC('name')" [ngClass]="{active: orderBy=='name',asc:order=='ASC'}">
                    Name</div>
                <div class="order" (click)="setOrderTC('created_at')"
                    [ngClass]="{active: orderBy=='created_at',asc:order=='ASC'}">Date Added</div>
                <div class="order" (click)="setOrderTC('updated_at')"
                    [ngClass]="{active: orderBy=='updated_at',asc:order=='ASC'}">Date Updated</div>
                <div># of Tracks</div>
                <div class="order" (click)="setOrderTC('status')"
                    [ngClass]="{active: orderBy=='status',asc:order=='ASC'}">Status</div>
                <div>Functions</div>
                <div>Actions</div>
            </div>
            <div class="table_content">
                <div *ngFor="let p of playlists">
                    <div class="table_row"
                        [ngClass]="{'highlight': p.profile_type == 1 && (p.sync == 0 || p.schedule?.sync == 0)}"
                        *ngIf="p.profile_name!='temp_profile'">
                        <div>
                            <!-- <ng-template #MasterTooltipContent>
                                <span style="font-size: 12px !important;" *ngIf="p.name">Distribution group :
                                    {{p.}}<name/span>
                            </ng-template>

                            <span [options]="tooltipOptions" [tooltip]="MasterTooltipContent" contentType="template"
                                *ngIf="p.profile_type == 1" class="master_icon"></span> -->

                            {{p.name}}
                        </div>
                        <div>{{p.created_at | date:'dd-MM-yyy
                            h:m:s'}}</div>
                        <div>{{p.updated_at| date:'dd-MM-yyy
                            h:m:s'}}</div>

                        <div>{{p.files_count}}</div>
                        <div>
                            <select id="Id" #Id="ngModel" class="custom-select hideLabel form-control"
                                [(ngModel)]="p.status" name="Id" style="width: 90px;"
                                (change)="statusChange($event,p.id)">
                                <option value="0">LIVE</option>
                                <option value="1">EDITING</option>
                                <option value="2">ARCHIVE</option>
                            </select>
                        </div>
                        <div>
                            <a *ngIf="p.profile_type != 2" class="playlist_manage" title="Manage"
                                (click)="manageClick(p.id)" routerLink="/manage-sample-client-playlists">Manage</a>
                        </div>
                        <div>
                            <a  class="playlist_comments"  title="Comments" href="#" (click)="playlistcommentsPopup($event,p.id)"
                            [ngClass]="p.comments_count > 0 ? 'playlist_comments_gold':'playlist_comments_grey' "
                            ></a>

                            <div class="editing_wrap"
                                *ngIf="current_user!=null&&(current_user.type=='Consultant'||current_user.type=='Administrator')">
                                <a class="playlist_edit" title="Edit" href="#"
                                    (click)="openPlaylistPopup($event,p.id)"></a>
                            </div>
                            <a class="playlist_delete " title="Delete"
                            *ngIf="current_user!=null &&(current_user.type=='Consultant'||current_user.type=='Administrator')" (click)="openConfirmPopup(p.id,3)" ></a>
                            <a class="playlist_share" title="Generate Link" href="#" (click)="generate_link($event,p.id)" ></a>

                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!--For Show Archive data -->

        <div class="table_wrap" [ngClass]="{selected: channeltabs.selected == 2}">
            <div class="table_titles">
                <div>Name</div>
                <div>Date Added</div>
                <div>Date Updated</div>
                <div># of Tracks</div>
                <div>Status</div>
                <div>Functions</div>
                <div>Actions</div>
            </div>
            <div class="table_content">
                <div *ngFor="let p of archivePlaylists">
                    <div class="table_row"
                        [ngClass]="{'highlight': p.profile_type == 1 && (p.sync == 0 || p.schedule?.sync == 0)}"
                        *ngIf="p.profile_name!='temp_profile'">
                        <div>
                            <!-- <ng-template #MasterTooltipContent>
                                <span style="font-size: 12px !important;" *ngIf="p.name">Distribution group :
                                    {{p.}}<name/span>
                            </ng-template>

                            <span [options]="tooltipOptions" [tooltip]="MasterTooltipContent" contentType="template"
                                *ngIf="p.profile_type == 1" class="master_icon"></span> -->

                            {{p.name}}
                        </div>
                        <div>{{p.created_at | date:'dd-MM-yyy
                            h:m:s'}}</div>
                        <div>{{p.updated_at| date:'dd-MM-yyy
                            h:m:s'}}</div>

                        <div>{{p.files_count}}</div>
                        <div>
                            <select id="Id" #Id="ngModel" class="custom-select hideLabel form-control"
                                [(ngModel)]="p.status" name="Id" style="width: 90px;"
                                (change)="statusChange($event,p.id)">
                                <option value="0">LIVE</option>
                                <option value="1">EDITING</option>
                                <option value="2">ARCHIVE</option>
                            </select>
                        </div>
                        <div>
                            <a *ngIf="p.profile_type != 2" class="playlist_manage" title="Manage"
                                (click)="manageClick(p.id)" routerLink="/manage-client-playlists">Manage</a>
                        </div>
                        <div>
                            <a class="playlist_comments" title="Comments" href="#" (click)="playlistcommentsPopup($event,p.id)" ></a>
                            <div class="editing_wrap"
                                *ngIf="current_user!=null&&(current_user.type=='Consultant'||current_user.type=='Administrator')">
                                <a class="playlist_edit" title="Edit" href="#"
                                    (click)="openPlaylistPopup($event,p.id)"></a>
                            </div>
                            <a class="playlist_delete" title="Delete"
                                *ngIf="p.uid == '' && current_user!=null &&(current_user.type=='Consultant'||current_user.type=='Administrator')"
                                href="#"
                                (click)="(p.profile_type == 0 || p.profile_type == 1) && openConfirmPopup(p.id,3)"></a>
                            <a class="playlist_delete disabled" title="Delete"
                                *ngIf="p.uid != '' && current_user!=null &&(current_user.type=='Consultant'||current_user.type=='Administrator')"></a>

                        </div>
                    </div>
                </div>
            </div>
        </div>


    </div>


</div>

<!-- PopUpOpen for create Client -->

<div class="popup create_playlist_popup" [ngClass]="{opened: createclient}" ngDraggable ngResizable
    [rzHandles]="'n,e,s,w,se,sw,ne,nw'" [handle]="dragHandle">
    <a href="#" class="popup_close" (click)="popupClose($event)"></a>
    <div class="popup_title" #dragHandle style="font-family: 'Gill Sans MT', sans-serif;">NEW CLIENT</div>
    <div class="popup_form">
        <form class="form_inline_client">
            <div class="clientform">
                <label>Client Search</label>
                <div class="inputdatalist">
                    <input type="text" id="listClient" name="clientsearch" (focus)="focusInput()" (blur)="blurInput()"
                        value="{{ clientsearchStr }}" placeholder="Client Search" class="searchInput"
                        [(ngModel)]="clientsearchStr" maxlength="30" list="" autocomplete="off">
                    <datalist id="list">
                        <option *ngFor="let c of clientsList" [value]="c.client_name" (click)="onoptionselect($event)">
                            {{c.client_name}}</option>

                    </datalist>
                    <button type="submit" class="searchBtn" (click)="clientsearch($event)"></button>
                    <a href="#" class="clear_search" (click)="clearClientSearchStr($event)"></a>
                </div>
            </div>
        </form>
        <div class="popup_btns">
            <button type="button" class="cancel" (click)="importClient($event)">Import</button>

        </div>
    </div>
</div>

<!-- channelEditPopUpOpen  -->

<div class="popup edit_Channel_popup" style="min-height: auto;top: 255px;" [ngClass]="{opened: channelEditPopUpOpen}"
    ngDraggable ngResizable [rzHandles]="'n,e,s,w,se,sw,ne,nw'" [handle]="dragHandle">
    <a href="#" class="popup_close" (click)="chaneleditPopUpClose($event)"></a>
    <div class="popup_title" #dragHandle style="font-family: 'Gill Sans MT', sans-serif;">Edit Channel</div>
    <div class="popup_form">
        <form class="form_inline_client">
            <div class="clientform">
                <label>Channel Name</label>
                <div class="inputdatalist">
                    <input type="text" id="listClient" name="selectedChannel" (focus)="focusInput()"
                        (blur)="blurInput()" value="{{ selectedChannel.id }}" [(ngModel)]="selectedChannel.name">
                </div>
            </div>
        </form>
        <div class="popup_btns">
            <button type="button" class="cancel" (click)="chaneleditupdate($event)">Save</button>
            <button type="button" class="cancel" (click)="chaneleditPopUpClose($event)">Cancel</button>
        </div>
    </div>
</div>


<!--For Create new playlist popup and edit popup-->

<div class="popup create_playlist_popup" [ngClass]="{opened: create_new_playlistpopUp}" ngDraggable ngResizable
    [rzHandles]="'n,e,s,w,se,sw,ne,nw'" [handle]="dragHandle">
    <a href="#" class="popup_close" (click)="popupClose($event)"></a>
    <div class="popup_title" #dragHandle>Create/Edit Playlist</div>
    <div class="popup_form">
        <form #updatePlaylist="ngForm">
            <input type="hidden" name="status" value="playlistFields.status" [(ngModel)]="playlistFields.status">
            <input type="hidden" name="edited_by" value=current_user.id [(ngModel)]="playlistFields.edited_by">
            <input type="hidden" name="user_id" *ngIf="playlistFields.id != null" value="{{playlistFields.user}}"
                [(ngModel)]="playlistFields.user" />
            <div class="input_wrap">
                <label>Name</label>
                <input autocomplete="off" style="width: 50%;height: 20px;border: 1px solid #b8b9bb;font-family: 'Brandon', Arial, sans-serif;font-size: 10px;
          padding: 0 5px;" type="text" maxlength="50" name="name" value="{{playlistFields.name}}"
                    [(ngModel)]="playlistFields.name" />
            </div>
            <div class="input_wrap" *ngIf="playlistFields.id == null">
                <label>Client</label>
                <select name="client_id" *ngIf="usersList!=null" [(ngModel)]="playlistFields.user"
                    (change)="userChange($event,2)">
                    <option value="null" [selected]="playlistFields.user==null">Select Client...</option>
                    <option *ngFor="let u of usersList" value="{{u.id}}" [selected]="playlistFields.user == u.id">
                        {{u.name}}</option>
                </select>
            </div>

            <div class="input_wrap" *ngIf="playlistFields.id == null">
                <label>Channel</label>
                <select name="site_id" [(ngModel)]="playlistFields.site">
                    <option value="null" [selected]="playlistFields.site==null">Select Channel...</option>
                    <option *ngFor="let site of playlistFields.sitesList" value="{{site.id}}"
                        [selected]="playlistFields.site==site.id">{{site.name}}</option>
                </select>
            </div>
            <div class="popup_btns">
                <button type="button" class="cancel" (click)="popupClose($event)">Cancel</button>
                <button type="submit" (click)="playlistUpdate($event,updatePlaylist,0)">Save & Close</button>
                <button type="submit" (click)="playlistUpdate($event,updatePlaylist,1)">Save & Manage</button>
            </div>
        </form>
    </div>
</div>


<!--Generated Link PopUp-->
<div class="popup generate_link_popup" [ngClass]="{opened: popups.linkedopened}" ngDraggable
  ngResizable [rzHandles]="'n,e,s,w,se,sw,ne,nw'" [handle]="dragHandle19">
  <a href="#" class="popup_close" (click)="popupClose($event)"></a>
  <div class="popup_title" #dragHandle19>Generated Link</div>

      <div class="generated_link">
        <input class="generate_link_input" [(ngModel)]="generated_link_value" type="text" #formInputText readonly>
        <button [ngxClipboard]="formInputText" (click)="copyLink()" class="copybutton" title="copy">Copy</button>
        <!-- <button  (click)="previewPage()" class="copybutton" title="preview">Preview</button> -->
      </div>

</div>

<div class="popup commentPopup" [ngClass]="{opened:commentPopup}" ngDraggable
  ngResizable [rzHandles]="'n,e,s,w,se,sw,ne,nw'" [handle]="dragHandle2">
  <a href="#" class="popup_close" (click)="popupClose($event)"></a>
  <div class="popup_title" #dragHandle2>Comments</div>

  <div class="grid-container">
    <div class="comments-container">
        <div class="all_chat">
             <div class="chatbox" *ngFor="let c of commentsFileds">
                <div class="chat">
                    <div class="msg-bubble">
                        <div class="msg">
                            <div class="commentdiv">
                                {{ c.comments }}
                            </div>

                        </div>
                        <div class="msg-info">

                            <div class="usertime">
                                <div class="msg-info-name">{{ c.commentscreateduser.first_name
                                    }}</div>
                                <div class="msg-info-time">{{ c.updated_at | date:'dd/MM/yyyy
                                    h:m a' }}</div>
                            </div>
                            <div class="comment_action"
                                *ngIf="current_user!=null&&(current_user.type=='Consultant'||current_user.type=='Administrator')">

                                <button [options]="tooltipOptions" [tooltip]="edittimepopUp"
                                    contentType="template" href="#" class="editcomment"
                                    *ngIf="current_user.id==c.created_by"
                                    (click)="editComments(c.id,c.comments)"></button>
                                <div>
                                    <ng-template #edittimepopUp>
                                        <span style="font-size: 12px !important; ">Updated at
                                            {{c.updated_at | date:'dd/MM/yyyy h:m a' }}</span>
                                    </ng-template>
                                </div>

                                <button href="#" class="clear_search"
                                    *ngIf="current_user.id==c.created_by"
                                    (click)="deletecomment(c.id)"></button>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    </div>
    <div class="comments-input">
       <textarea #comments  [(ngModel)]="propertycomments" name="" id="commentboxarea" rows="5"></textarea>
       <div class="commebox-buttons">
       <button *ngIf="property_comment_id==''"
       (click)="addComments(comments.value)">Submit</button>
        <button *ngIf="property_comment_id"
       (click)="editcommentsBYId(comments.value)">Submit</button>
       <button *ngIf="propertycomments" (click)="cancelcomment()">Cancel</button>
    </div>
    </div>
  </div>




</div>

<app-alert></app-alert>
<app-confirm (isConfirm)="isConfirm($event)"></app-confirm>
