import {Component, Injectable, OnInit} from '@angular/core';
import {PlaylistCategoriesService} from './playlist-categories.service';
import {PlaylistsService} from '../services/playlists.service';

@Component({
  selector: 'app-playlist-categories',
  templateUrl: './playlist-categories.component.html',
  styleUrls: ['./playlist-categories.component.scss'],
  providers:[PlaylistCategoriesService, PlaylistsService]
})
@Injectable()
export class PlaylistCategoriesComponent implements OnInit {
  playlistCats;
  selected = [];
  constructor(
    private playlistCatsServ: PlaylistCategoriesService,
    private playlistsServ: PlaylistsService
  ) { }

  ngOnInit() {
    // Do nothing
  }
  /*updatePlaylistCategories(){
    this.playlistCatsServ.getPlaylistsCategories('').subscribe(pcats=>{
      this.playlistCats = pcats;
    });
  }
  onClick(e,item,i){
    this.selected[i] = item;
    this.getPlaylists(item.id);
  }

  getPlaylists(pid){
    this.playlistsServ.getPlaylists(pid).subscribe(playlists=>{
      this.playlistComp.playlists = playlists;
      this.playlistComp.checkedCatID = pid;
    })
  }
  isActive(item,i) {
    return this.selected[i] === item;
  }*/
  /*removeCategory(e,id){
    e.preventDefault();
    this.playlistComp.deleteCategory(id);
  }*/
  /*removeSubcategory(e,id){
    e.preventDefault();
    this.playlistComp.deleteCategory(id);
  }*/
  /*editCategory(e,id){
    e.preventDefault();
    this.playlistComp.openCategoriesPopup(e,id);
  }
  editSubcategory(e,id){
    e.preventDefault();
    this.playlistComp.openSubcategoriesPopup(e,id);
  }*/
}
