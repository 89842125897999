import {Component, HostListener, Injectable, OnInit, ViewChild} from '@angular/core';
import {PlaylistsService} from '../services/playlists.service';
import {PlaylistCategoriesService} from '../playlist-categories/playlist-categories.service';
import {NgForm} from '@angular/forms';
import {CommonService} from '../services/common.service';
import {ActivatedRoute, NavigationExtras, Router} from '@angular/router';
import {PlayerComponentComponent} from '../player-component/player-component.component';
import {AlertComponent} from '../alert/alert.component';
import {ConfirmComponent} from '../confirm/confirm.component';
import {Globals} from '../globals';

@Component({
    selector: 'app-manage-foundation-playlists',
    templateUrl: './manage-foundation-playlists.component.html',
    styleUrls: ['./manage-foundation-playlists.component.scss'],
    providers: [PlaylistsService, PlaylistCategoriesService, CommonService]
})
@Injectable()
export class ManageFoundationPlaylistsComponent implements OnInit {
  categoriesList:any = Array();//List of categories
  categoriesPopupOpened = false;//Open popup for edit categories
  /*
  Category information in edit popup
  {
    id - category id,
    name - category name,
    visible - category activity
  }
   */
  categoriesFields = {
    id: null,
    name: '',
    visible: 1
  }
  /*
   Selected Category information
   {
    id - Category id,
    name - Category name
   }
   */
  selectedCategoryId = {
    id: null,
    name: ''
  };
  subcategories:any = [];//Sub categories list
  subcategoriesList:any = Array();//Sub categories list for selects
  subcategoriesPopupOpened = false;//Open popup for edit sub categories
  /*
  Sub category information in edit popup
  {
    id - sub category id,
    name - sub category name,
    catId - category id
    visible - category activity
  }
   */
  subcategoriesFields = {
    id: null,
    name: '',
    catId: null,
    visible: 1
  };
  /*
   Selected Sub category information
   {
    id - Sub category id,
    name - Sub category name
   }
   */
  selectedSubcategoryId = {
    id:null,
    name:''
  };

  playlistPopupOpened = false;//Open popup for edit sub playlists
  /*
  Playlilst information in edit popup
  {
    id - playlist id,
    name - playlist name,
    catId - category id,
    subcatId - sub category id,
    description - playlist description,
    status - playlist status
  }
   */
  playlistFields = {
    id: null,
    name: '',
    catId: null,
    subcatId: null,
    description: '',
    status: 1
  };
  playlists:any = Array();//List of playlists
  archivePlaylist:any=Array(); //List of Archive Playlist

  selectedMainCategory = null;//Selected category id
  playlistStatusText = ['LIVE', 'EDITING', 'SUSPENDED'];//Text for playlist status
  playlistSuspendingText = ['Suspend', 'Unsuspend', 'Unsuspend'];//Text for playlist suspending

  searchStr = '';//Value of search input
  searchedPlaylists:any = [];//Array of search results
  searchPopupOpened = false;//Open search results popup

  selectedCP = null;//Selected Client playlist id
  /*
   Player information
   {
     isPlaying(string) - player status. List of possible values ['stop', 'play','pause'](default 'stop'),
     trackIndex(integer) - index of playling track in playlist(default null),
     trackId(integer) - playling track id(default null),
     filename(string) - file name of playing track(default null),
     artist(string) - playling track artist(default ''),
     album(string) - playling track album(default ''),
     storage(string) - storage of playling track(default ''),
     tracks - list of tracks in selected playlist
     token(string) - access token for logged user(default '')
     playlistID(number) - playlist id
   }
   */
  playerInfo = {
    isPlaying: 'stop',
    trackIndex: null,
    trackId: null,
    filename: null,
    album: '',
    artist: '',
    storage: '',
    tracks: [],
    playlistID: null,
    token: ''
  };
  @ViewChild(PlayerComponentComponent, { static: true }) player: PlayerComponentComponent;//Component of player
  @ViewChild(AlertComponent, { static: true }) alert: AlertComponent; //Component of alert popup
  @ViewChild(ConfirmComponent, { static: true }) confirm: ConfirmComponent;//Component of confirm popup
  /**
   Show actions for selected category/subcategory
   {
    number - number of type action,
    id - category/subcategory id
  };
   */
  isShowActions = {
    number: null,
    id: null
  };
  user = null;// information of logged user
  /**
   Value for confirmation popup
   {
     id(integer) - ID of element for action(default null),
     type(integer) - type of function for action(default 1)
   }
   **/
  confirmValues = {
    id: null,
    type: 0
  };
  isFocused = false;// Checking of inputs focus
  tabs = {
    selected: 1,
  };

  constructor(
      private playlistServ: PlaylistsService,
      private playlistCatsServ: PlaylistCategoriesService,
      private commonService: CommonService,
      private router: Router,
      private route: ActivatedRoute,
      private globals: Globals
  ) {
  }

  ngOnInit() {
    this.updateCategoriesList();
    this.commonService.getCurrentLoggedUser().subscribe(current_user => {
        this.user = current_user;
    });

    this.setCheckedElements();
  }
  ngOnDestroy(){
    if(this.playerInfo.isPlaying=='play'){
      this.player.pause();
    }
  }
  /**
   Set checked client,site,channel after back from edit playlist page
   */
  setCheckedElements(){
    this.route.queryParams.subscribe(params=>{
      if(params['pcat'] != '' && params['pcat'] != null){
        this.selectedCategoryId.id = params['pcat'];
        this.selectCategory(this.selectedCategoryId.id,'',0);
      }
      else{
        this.selectedCategoryId.id = null;
        this.categoriesList = null;
      }
      if(params['cat'] != '' && params['cat'] != null && params['pcat'] != '' && params['pcat'] != null){
        this.selectedSubcategoryId.id = params['cat'];
        this.getPlaylists(this.selectedSubcategoryId.id);
      }
      else if(params['cat'] != '' && params['cat'] != null && (params['pcat'] == '' || params['pcat'] == null)){
        this.selectedCategoryId.id = params['cat'];
        this.getPlaylists(this.selectedCategoryId.id);
      }
      else{
        this.selectedSubcategoryId.id = null;
        this.playlists = null;
      }
    });
  }

  /**
   Get list of categories
   */
  updateCategoriesList() {
    this.playlistCatsServ.getPlaylistsCategories('').subscribe(playCats => {
        this.categoriesList = playCats;
        if(this.selectedCategoryId.id && this.selectedCategoryId.name == ''){
          let c = this.categoriesList.find(x => x.id == this.selectedCategoryId.id);
          this.selectedCategoryId.name = c.name;
        }
    });
  }

  /**
   Open popup for edit category information
   * @param e - event
   * @param cid - categoryId
   * @returns {boolean}
   */
  openCategoriesPopup(e, cid){
    this.focusInput();
    if(cid == null){
      this.categoriesFields.id = null;
    } else {
      this.categoriesFields.id = cid;
      this.playlistCatsServ.getPlaylistsCategories(cid).subscribe((category:any) => {
        this.categoriesFields.name = category[0].name;
      });
    }
    this.categoriesPopupOpened = !this.categoriesPopupOpened;
    return false;
  }
  /**
   Close all popups
   * @param e - event
   * @returns {boolean}
   */
  popupClose(e) {
    this.categoriesPopupOpened = false;
    this.subcategoriesPopupOpened = false;
    this.playlistPopupOpened = false;
    this.searchPopupOpened = false;
    this.blurInput();
    return false;
  }

  /**
   Update category information
   * @param e - event
   * @param {NgForm} form - form
   * @returns {boolean}
   */
  categoryUpdate(e, form: NgForm){
    var str = Object.keys(form.value).map(function (key) {
      var val = encodeURIComponent(form.value[key]);
      if(val == 'null' || val == null){
        val = '';
      }
      return encodeURIComponent(key) + '=' + val;
    }).join('&');
    if( this.categoriesFields.name == '' ){
      this.alert.alertOpen("Please enter category name",1);
      return false;
    }
    if(this.categoriesFields.id == null){
      this.playlistCatsServ.createCategory(str,1).subscribe(category => {
        this.updateCategoriesList();
        this.alert.alertOpen("Category added",0);
      });
    }
    else{
      this.playlistCatsServ.updatePlaylistCategory(this.categoriesFields.id, str).subscribe((category:any) => {
        this.updateCategoriesList();
        this.alert.alertOpen("Playlist updated",0);
      });
    }
    this.popupClose(e);
  }

  /**
   Select category
   * @param cid - category id
   * @param name - category name
   * @param num - type of action
   */
  selectCategory(cid,name,num){
    this.selectedSubcategoryId.id = null;
    this.isShowActions.number = 1;
    if(num==1&&this.selectedCategoryId.id==cid){
      this.selectedCategoryId.id = null;
      this.selectedCategoryId.name = '';
      this.subcategories = [];
    }
    else{
      this.selectedCategoryId.id = cid;
      this.selectedCategoryId.name = name;
      this.getSubcategories(cid);
    }

    ///this.getPlaylists(cid);
  }

  /**
   Open popup for edit sub categories information
   * @param e - event
   * @param scid - sub category id
   */
  openSubcategoriesPopup(e,scid){
    e.preventDefault();
    if(scid == null) {
      this.subcategoriesFields.id = null;
      if(this.selectedCategoryId.id != null)
        this.subcategoriesFields.catId = this.selectedCategoryId.id;
      else
        this.subcategoriesFields.catId = null;

    } else {
      this.subcategoriesFields.id = scid;
      this.playlistCatsServ.getPlaylistsCategories(scid).subscribe((subcat:any) => {
        this.subcategoriesFields.name = subcat[0].name;
        this.subcategoriesFields.catId = subcat[0].parent_id;
      });
    }
    this.focusInput();
    this.subcategoriesPopupOpened = !this.subcategoriesPopupOpened;
  }

  /**
   Update sub category information
   * @param e - event
   * @param {NgForm} form - form
   * @returns {boolean}
   */
  subcategoryUpdate(e,form:NgForm){
    e.preventDefault();
    var parentId = form.value.parent_id;
    if(this.subcategoriesFields.id == null){
      delete form.value.parent_id;
    }
    if(this.subcategoriesFields.catId == null || this.subcategoriesFields.name == '' ){
      this.alert.alertOpen("Please select name and parent category",1);
      return false;
    }
    var str = Object.keys(form.value).map(function (key) {
      var val = encodeURIComponent(form.value[key]);
      if(val == 'null' || val == null){
        val = '';
      }
      return encodeURIComponent(key) + '=' + val;
    }).join('&');

    if (this.subcategoriesFields.id == null) {
      this.playlistCatsServ.createCategory(str, parentId).subscribe(subcabcategory => {
        this.updateCategoriesList();
        this.alert.alertOpen("Sub Category added",0);
      });
    }
    else {
      this.playlistCatsServ.updatePlaylistCategory(this.subcategoriesFields.id, str).subscribe(subcabcategory => {
        this.updateCategoriesList();
        this.alert.alertOpen("Sub Category updated",0);
      });
    }
    this.popupClose(e);
  }

  /**
   Select subcategory
   * @param scid - sub category id
   * @param name - sub category name
   * @param num - type of action
   */
  selectSubcategory( scid, name, num ){
    this.isShowActions.number = 2;
    if( num == 1 && this.selectedSubcategoryId.id == scid ){
      this.selectedSubcategoryId.id = null;
      this.selectedSubcategoryId.name = '';
      this.playlists = [];
    }
    else{
      this.selectedSubcategoryId.id = scid;
      this.selectedSubcategoryId.name = name;
      this.getPlaylists(scid);
      this.getArchivePlaylists(scid);
    }

  }

  /**
   Get sub categories list
   * @param cid - category id
   */
  getSubcategories(cid){
    this.playlistCatsServ.getPlaylistsCategories(cid).subscribe((subcats:any) => {
      this.subcategories = subcats[0].children;
      if(this.selectedSubcategoryId.id && this.selectedSubcategoryId.name == ''){
        let sc = this.subcategories.find(x => x.id == this.selectedSubcategoryId.id);
        this.selectedSubcategoryId.name = sc.name;
      }
    });
  }

  /**
   Get playlists
   * @param id - playlist id
   */
  getPlaylists(id){
    this.playlistServ.getPlaylists(id).subscribe(playlists => {
      this.playlists = playlists;
    })
  }

  getArchivePlaylists(id){
    this.playlistServ.getarchivePlaylists(id).subscribe(playlists => {
      this.archivePlaylist = playlists;
    })
  }
  /**
   Open popup for edit playlist information
   * @param e - event
   * @param pid - playlist id
   * @returns {boolean}
   */
  openPlaylistPopup(e, pid){
    this.searchPopupOpened = false;
    if(pid == null){
      this.playlistFields.id = null;
      if(this.selectedCategoryId.id != null){
        this.playlistFields.catId = this.selectedCategoryId.id;
        this.updateSubcategories(this.playlistFields.catId);
      }
      else{
        this.playlistFields.catId = null;
        this.subcategoriesList = [];
      }
      if(this.selectedSubcategoryId.id != null)
        this.playlistFields.subcatId = this.selectedSubcategoryId.id;
      else
        this.playlistFields.subcatId = null;
      
    }
    else{
      this.playlistFields.id = pid;
      this.playlistServ.getPlaylistById(pid).subscribe((playlist:any) => {
        this.playlistFields.name = playlist.name;
        this.playlistCatsServ.getPlaylistCategoriesByID(playlist.foundation_category_id).subscribe((category:any) =>{
          if(category.parent_id == null){
            this.updateSubcategories(playlist.foundation_category_id);
            this.playlistFields.catId = playlist.foundation_category_id;
            this.playlistFields.subcatId = null;
          }
          else{
            this.playlistFields.subcatId = playlist.foundation_category_id
            this.playlistCatsServ.getPlaylistCategoriesByID(category.parent_id).subscribe((cat:any)=>{
              this.updateSubcategories(cat.id);
              this.playlistFields.catId = cat.id;

            })
          }
        });
        this.playlistFields.description = playlist.description;
      });
    }
    this.focusInput();
    this.playlistPopupOpened = !this.playlistPopupOpened;
    return false;
  }

  /**
   Change category in select
   * @param e - event
   */
  changeCategory(e){
    this.updateSubcategories(e.target.value);
  }

  /**
   Get sub categories list
   * @param id - category id
   */
  updateSubcategories(id){
    this.playlistCatsServ.getPlaylistsCategories(id).subscribe((categories:any) => {
      this.subcategoriesList = categories[0].children;
    });
  }

  /**
   Update playlist information
   * @param e - event
   * @param {NgForm} form - form
   * @returns {boolean}
   */
  playlistDataUpdate(e,form:NgForm){
    var categoryID = form.value.foundation_category_id;
    let subcategoryID = form.value.foundation_subcategory_id;
    if (subcategoryID != 'undefined' && subcategoryID != null) {
      categoryID = subcategoryID;
    }
    if(form.value.foundation_subcategory_id != null&&form.value.foundation_category_id != null){
      this.playlistFields.subcatId = form.value.foundation_subcategory_id;
      delete form.value.foundation_subcategory_id;
      delete form.value.foundation_category_id;
    }
    else if(form.value.foundation_subcategory_id == null&&form.value.foundation_category_id != null){
      this.alert.alertOpen("Please select sub category",1);
      return false;
    }
    else{
      this.playlistFields.subcatId = null;
      this.alert.alertOpen("Please select category and sub category",1);
      return false;
    }
    if(this.playlistFields.name == ''){
      this.alert.alertOpen("Please enter playlist name",1);
      return false;
    }


    var str = Object.keys(form.value).map(function (key) {
      var val = encodeURIComponent(form.value[key]);
      if(val == 'null' || val == null){
        val = '';
      }
      return encodeURIComponent(key) + '=' + val;
    }).join('&');

    if (this.playlistFields.id == null) {
      this.playlistServ.createPlaylist(categoryID, str).subscribe((playlists:any) => {
        if(playlists.error == undefined){
          this.alert.alertOpen("Playlist added",0);
          var cat = this.selectedCategoryId.id;
          if(this.selectedSubcategoryId.id != null){
            cat = this.selectedSubcategoryId.id;
          }
          this.getPlaylists(cat);
        }
        else{
          this.alert.alertOpen(playlists.error,1);
        }
      });
    } else {
      this.playlistServ.updatePlaylist(this.playlistFields.id, str + '&foundation_category_id=' + categoryID).subscribe((playlists:any) => {
        if(playlists.error == undefined){
          this.alert.alertOpen("Playlist updated",0);
          var cat = this.selectedCategoryId.id;
          if(this.selectedSubcategoryId.id != null){
            cat = this.selectedSubcategoryId.id;
          }
          this.getPlaylists(cat);
        }
        else{
          this.alert.alertOpen(playlists.error,1);
        }
      });
    }
    this.popupClose(e);
  }

  /**
   Delete category
   * @param id - category id
   */
  deleteCategory(id) {
    this.playlistCatsServ.deleteCategory(id).subscribe(playlistDel => {
      this.updateCategoriesList();
      this.getSubcategories(this.selectedCategoryId.id);
      this.alert.alertOpen("Category deleted",0);
      this.playlists = [];
    });
  }

  /**
   Delete playlist
   * @param id - playlist id
   */
  deletePlaylist(id) {
    this.playlistServ.deletePlaylist(id).subscribe(playlistDel => {
      this.alert.alertOpen("Playlist deleted",0);
      var cat = this.selectedCategoryId.id;
      if(this.selectedSubcategoryId.id != null){
        cat = this.selectedSubcategoryId.id;
      }
      if(this.searchPopupOpened){
        this.search(window.event);
      }
      this.getPlaylists(cat);
      this.getArchivePlaylists(cat);
    });
  }
  /**
   Cliek link to edit client playlist
   * @param id - playlist id
   */
  manageClick(id){
    let navigationExtras: NavigationExtras={
      queryParams:{
        'playlistID': id
      }
    };
    this.router.navigate(['manage-playlists'], navigationExtras);
  }
  /**
   Set status for playlist
   * @param e - event
   * @param pid - playlist id
   * @param sid - category id
   */
  setStatus(e, pid){
    console.log(pid,e.target.value);
    e.preventDefault();
    this.playlistServ.setStatus( pid, e.target.value).subscribe(status => {
      if(this.searchPopupOpened){
        this.search(e);
      }
      this.getPlaylists(this.selectedSubcategoryId.id);
      this.getArchivePlaylists(this.selectedSubcategoryId.id);
    });
  }
  /**
   Get search results
   @param e - event
   */
  search(e){
    e.preventDefault();
    
    if(this.searchStr != ''){
      if(this.searchStr.length < 6){
        this.alert.alertOpen("Please enter minimum 6 characters to search",1);
        return false;
      } 
      this.playlistServ.searchPlaylists(this.searchStr).subscribe(playlists => {
        if(playlists!=null)
          this.searchedPlaylists = playlists;
        else
          this.searchedPlaylists = [];
        this.searchPopupOpened = true;
      });
    }
  }
  /**
   Crear search results
   @param e - event
   * @returns {boolean}
   */
  clearSearch(e){
    e.preventDefault();
    this.searchStr = '';
  }
  /**
   Start playing tracks from playlist
   * @param e - event
   * @param pid - playlist id
   * @returns {boolean}
   */
  startPlaylist( e , pid ){
    if(pid != null){
      if(pid != this.playerInfo.playlistID){
        this.playlistServ.getPlaylistTracks(pid,'ASC','add_date').subscribe((tracks:any) => {
          this.playerInfo.tracks = tracks;
          this.playerInfo.playlistID = pid;
          this.playerInfo.trackIndex = 0;
          this.playerInfo.token = this.globals.access_token;
          if(this.playerInfo.tracks.length > 0){
            this.playerInfo.filename = this.playerInfo.tracks[this.playerInfo.trackIndex].file_name;
            this.playerInfo.trackId = this.playerInfo.tracks[this.playerInfo.trackIndex].id;
            this.playerInfo.album = this.playerInfo.tracks[this.playerInfo.trackIndex].album;
            this.playerInfo.artist = this.playerInfo.tracks[this.playerInfo.trackIndex].artist;
            this.playerInfo.storage = this.playerInfo.tracks[this.playerInfo.trackIndex].storage;
            this.player.start( this.playerInfo.trackId, true );
          }
        });
      }
      else{
        if(this.playerInfo.isPlaying=='play'){
          this.player.pause();
        }
        else{
          this.player.start( this.playerInfo.trackId, true );
        }
      }
    }
    return false;
  }
  /**
   Chegking tracks playing
   * @param e - event
   */
  checkPlaying(e){
    this.playerInfo.isPlaying = e;
  }
  /**
   Actions after breadcrumbs click
   * @param num - number of back action
   * @returns {boolean}
   */
  resetCheckedElements(num){
    if(num < 3){
      this.selectedSubcategoryId.id = null;
    }
    if(num < 2){
      this.selectedCategoryId.id = null;
    }
    return false;
  }
  /**
   If track playing play next track in playlist
   * @param e - event
   */
  nextTrack(e){
    if(this.playerInfo.isPlaying == 'play'){
      if( this.playerInfo.tracks.length > this.playerInfo.trackIndex + 1 ){
        this.playerInfo.trackIndex = this.playerInfo.trackIndex + 1;
        this.playerInfo.trackId = this.playerInfo.tracks[this.playerInfo.trackIndex].id;
        this.playerInfo.filename = this.playerInfo.tracks[this.playerInfo.trackIndex].file_name;
        this.playerInfo.album = this.playerInfo.tracks[this.playerInfo.trackIndex].album;
        this.playerInfo.artist = this.playerInfo.tracks[this.playerInfo.trackIndex].artist;
        this.playerInfo.storage = this.playerInfo.tracks[this.playerInfo.trackIndex].storage;
        this.playerInfo.token = this.globals.access_token;
        this.player.start( this.playerInfo.trackId, true );
      }
    }
  }
  /**
   If track playing play previous track in playlist
   * @param e - event
   */
  prevTrack(e){
    if(this.playerInfo.isPlaying == 'play'){
      if( this.playerInfo.trackIndex > 0 ){
        this.playerInfo.trackIndex = this.playerInfo.trackIndex - 1;
        this.playerInfo.trackId = this.playerInfo.tracks[this.playerInfo.trackIndex].id;
        this.playerInfo.filename = this.playerInfo.tracks[this.playerInfo.trackIndex].file_name;
        this.playerInfo.album = this.playerInfo.tracks[this.playerInfo.trackIndex].album;
        this.playerInfo.artist = this.playerInfo.tracks[this.playerInfo.trackIndex].artist;
        this.playerInfo.storage = this.playerInfo.tracks[this.playerInfo.trackIndex].storage;
        this.playerInfo.token = this.globals.access_token;
        this.player.start( this.playerInfo.trackId, true );
      }
    }
  }
  showActions( id, num ){
    return false;
  }
  /**
   Click outside element
   * @param $event
   */
  @HostListener('document:click', ['$event']) clickedOutside($event){
    this.isShowActions.number=3;
  }
  /**
   Click inside element
   * @param e - event
   */
  clickedInside(e){
    e.preventDefault();
    e.stopPropagation();
  }
  /**
   Confirm action in confirmation popup
   * @param e - event
   */
  isConfirm(e){
    if(e){
      if( this.confirmValues.type == 0 )
        this.deleteCategory(this.confirmValues.id);
      else if( this.confirmValues.type == 1 )
        this.deletePlaylist(this.confirmValues.id);
    }
  }
  /**
   Open confirmation popup
   * @param id - id of action
   * @param t - text for popup
   * @returns {boolean}
   */
  openConfirmPopup(id, t){
    var text = "Do you want delete this category?";
    if(t == 1)
      text = "Do you want delete this playlist?";

    this.confirmValues.id = id;
    this.confirmValues.type = t;
    this.confirm.confirmOpen(text);
    return false;
  }

  /**
   Show client playlists dropdown
   * @param pid - playlist id
   */
  showCP(pid){
    if(this.selectedCP == pid){
      this.selectedCP = null;
    }
    else{
      this.selectedCP = pid;
    }
  }
  /**
   Cliek link to edit client playlist
   * @param id - playlist id
   */
  manageCPClick(id){
    let navigationExtras: NavigationExtras={
      queryParams:{
        'playlistID': id
      }
    };
    this.router.navigate(['manage-client-playlists'], navigationExtras);
  }
  /**
   Set param if input focused
   */
  focusInput(){
    this.isFocused = true;
  }
  /**
   Set param if input blured
   */
  blurInput(){
    this.isFocused = false;
  }

  selectTab(tabId) {
    this.tabs.selected = tabId;
    console.log(this.tabs.selected);
  }
}

