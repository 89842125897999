<div class="ph_block">
  <app-page-header *ngIf="isShowHeader" [pageTitle]="'Create, edit and manage your playlists'"></app-page-header>
  <div class="top_actions_box"  *ngIf="isShowHeader" [ngClass]="{fullscreen: screenParams.isFullScreen}">
    <div class="left_box" *ngIf="!screenParams.isPlaylist">
      <h2>Track Library
        <div class="upper_left_box">
          <button class="search_button_uid" (click)="searchUidPopUpOpen()">Bulk UID Search</button>
        </div>
      </h2>
    </div>
    <div class="right_box" *ngIf="screenParams.isPlaylist||!screenParams.isFullScreen">
      <h2>Create Playlist
        <button (click)="openConfirmPopup(0,4)" title="Export">Export</button>
      </h2>
      <div class="buttons_box">
        <a href="#" class="back_btn btn" (click)="backBtnClick($event)" title="Go Back"></a>
        <a href="#" class="add_playlist btn" (click)="openCreatePlaylistPopup($event)" *ngIf="current_user!=null&&(current_user.type=='Consultant'||current_user.type=='Administrator')" title="New Playlist"></a>
        <a href="#" class="open_new_window btn" (click)="openNewWindow($event)" title="Open the FP in New Window"></a>
      </div>
    </div>
  </div>
  <div class="manage_fp_main" [ngClass]="{fullscreen: screenParams.isFullScreen}">
    <div class="manage_fp_left" *ngIf="!screenParams.isPlaylist">
      <div class="actions_box">
        <div class="search_box">
          <form method="get" #searchForm="ngForm">
            <input type="text" (focus)="focusInput()" (blur)="blurInput()" name="search" value="{{searchStr}}" placeholder="Track search" class="searchInput" [(ngModel)]="searchStr" (keyup)="searchPress($event)"/>
            <button type="submit" class="searchBtn" (click)="search($event)"></button>
            <a href="#" class="clear_search" (click)="clearSearch($event)"></a>
          </form>
        </div>
        <button (click)="openFilterPopup()" class="with_arrow" title="Filter Tracks/Convert to Playlist">Smart filter</button>
        <button (click)="addTracks($event,selectedRows,tabs[selectedTabIndex].playlistInfo.id)" *ngIf="current_user!=null&&(current_user.type=='Consultant'||current_user.type=='Administrator')" title="Add selected track to FP">Add to FP</button>
        <div class="to_other_wrap" (click)="clickInside($event)" *ngIf="current_user!=null&&(current_user.type=='Consultant'||current_user.type=='Administrator')&&!screenParams.isFullScreen">
          <button (click)="openAnotherPlaylists($event,false)" class="addother_btn" title="Add selected track to another FP of same category">+ to Other</button>
          <ul [ngClass]="{opened: openPlaylistDrop2}">
            <li *ngFor="let fpsc of openSubcats.subcatsArr">
              <span (click)="getPlaylistInfo(2,fpsc.id)" [ngClass]="{opened: openPlaylist.subcategory.id == fpsc.id }">{{fpsc.name}}</span>
              <ul [ngClass]="{opened: openPlaylist.subcategory.id == fpsc.id }">
                <li *ngFor="let fp of openPlaylist.playlists.playlistsArr">
                  <span (click)="getPlaylistInfo(3,fp.id)">{{fp.name}}</span>
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <a href="#" class="open_new_window btn separate_btn" (click)="openSeparateWindow($event)" *ngIf="current_user!=null&&(current_user.type=='Consultant'||current_user.type=='Administrator')&&!screenParams.isFullScreen" title="Open Track Library and FP in different Window"></a>
        <div class="tracks_count">{{tracksCount.display}}/{{tracksCount.total}} tracks</div>
      </div>
      <div class="table_wrap tracks_table" dndDropzone (dndDrop)="onDropRemove($event)">
        <div class="table_titles">
          <div class="order" (click)="setAllTracksOrder('mc_tags')"
            [ngClass]="{active: tracksOrder.orderBy=='mc_tags',asc:tracksOrder.order=='ASC'}">
            MC Tag</div>
          <div class="order" (click)="setAllTracksOrder('title')" [ngClass]="{active: tracksOrder.orderBy=='title',asc:tracksOrder.order=='ASC'}">Name</div>
          <div class="order" (click)="setAllTracksOrder('artist')" [ngClass]="{active: tracksOrder.orderBy=='artist',asc:tracksOrder.order=='ASC'}">Artist</div>

          <div class="order bpm" (click)="setAllTracksOrder('bpm')" [ngClass]="{active: tracksOrder.orderBy=='bpm',asc:tracksOrder.order=='ASC'}">BPM</div>
          <div></div>
        </div>
        <div class="table_content" *ngIf="tracks.length > 0" infinite-scroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="300" [scrollWindow]="false" (scrolled)="onScroll($event)">
          <div class="table_row" [dndDraggable]="draggable.data" [dndEffectAllowed]="draggable.effectAllowed" [dndDisableIf]="draggable.disable" (dndStart)="addDragTrack($event,t.id)" *ngFor="let t of tracks;let i=index" (click)="selectRow($event,t.id,i)"  [ngClass]="{selected: checkSelectedRow(selectedRows,t.id)!=-1,'isDeleted': t.deleted_at != null,'kv-tracks':(t.storage == 'no_storage' || t.storage == 'storage_kv')}" [ngStyle]="setBgFromTag(t.tags)">
            <div><em>{{t.mc_tags}}</em></div>
            <div><strong>{{t.title}}</strong></div>
            <div><em>{{t.artist}}</em></div>

            <div><em>{{t.bpm}}</em></div>
            <div>

              <a href="#" class="track_info"  *ngIf="t.deleted_at == null " (click)="showEdit($event, t.id, null, 0)" title="Detail"></a>
              <a href="#" class="add_track" (click)="addTrack($event,t.id,tabs[selectedTabIndex].playlistInfo.id, false)" title="Add" *ngIf="current_user!=null&&(current_user.type=='Consultant'||current_user.type=='Administrator')"></a>
              <span *ngIf="playerInfo.trackId == t.id && playerInfo.trackIndex == i && playerInfo.isPlaying  == 'play'" class="volume"></span>
            </div>
          </div>
        </div>
        <div class="table_content nf" *ngIf="tracks.length <= 0">
          <span>No results found</span>
        </div>
      </div>
    </div>
    <div class="manage_fp_right" *ngIf="screenParams.isPlaylist||!screenParams.isFullScreen">
      <div class="actions_box">
        <button (click)="openInfoPopup()" class="" title="Info" style="float: right;">Info</button>
        <button (click)="openTagsPopup()" class="tag_manager_btn with_arrow" title="Edit Tags">Tag Manager</button>
      </div>
      <div class="tabs" *ngIf="tabs!=null">
        <ul class="tab">
          <li *ngFor="let t of tabs; let i = index" [ngClass]="{selected: t.selected}" (click)="deselectTab(i)">
            <span *ngIf="t.playlistInfo!=null" title="{{t.tab_str}}">{{t.playlistInfo.name}}</span>
            <a href="#" class="close_tab" (click)="closeTab(i)" *ngIf="i > 0"></a>
          </li>
          <li class="open_playlist" (click)="clickInside($event)">
            <span (click)="openPlaylists($event,false)" title="Open another FP"></span>
            <ul [ngClass]="{opened: openPlaylistDrop}">

              <li style="margin-top: 6px;"><input *ngIf="openCPPlaylist.users.usersArr.length > 0" type="text" class="form-control" style="width: 100%;" value="{{searchStrCP}}" [(ngModel)]="searchStrCP" (keyup)="searchPressCPDropdown($event)" >
                <a *ngIf="!loadingCPSearch" href="#" class="clear_search_icon" (click)="clearSearchCP()"></a>
                <img *ngIf="loadingCPSearch" src="../../assets/img/loader.gif" alt="loader" class="" style="position: absolute;left: 94%;height: 25px;"/>
              </li>

              <li>
                <span [ngClass]="{opened: playlistType == 1 }" (click)="selectPlaylistType(1)">Client playlists</span>
                <ul [ngClass]="{opened: playlistType == 1 }">
                  <li *ngFor="let cpu of openCPPlaylist.users.usersArr">
                    <span
                      *ngIf=" (searchStrCP == '' && filteredClientIDsCP.length == 0 ) || (filteredClientIDsCP.length > 0 && filteredClientIDsCP.indexOf(cpu.id) > -1)" (click)="getCPPlaylistInfo(1,cpu.id)" [ngClass]="{opened: openCPPlaylist.users.id == cpu.id }">{{cpu.first_name}}</span>
                    <ul [ngClass]="{opened: openCPPlaylist.users.id == cpu.id }">
                      <li *ngFor="let cps of openCPPlaylist.sites.sitesArr">
                        <span
                          *ngIf=" (searchStrCP == '' && filteredParentIDsCP.length == 0 ) || (filteredParentIDsCP.length > 0 && filteredParentIDsCP.indexOf(cps.id) > -1)" (click)="getCPPlaylistInfo(2,cps.id)" [ngClass]="{opened: openCPPlaylist.sites.id == cps.id }">{{cps.name}}</span>
                        <ul *ngIf="cps.children!=undefined" [ngClass]="{opened: openCPPlaylist.sites.id == cps.id }">
                          <li *ngFor="let cpc of cps.children">
                            <span
                              *ngIf="(searchStrCP == '' && filteredChildIDsCP.length == 0 ) || (filteredChildIDsCP.length > 0 && filteredChildIDsCP.indexOf(cpc.id) > -1)" (click)="getCPPlaylistInfo(3,cpc.id)" [ngClass]="{opened: openCPPlaylist.channels.id == cpc.id }">{{cpc.name}}</span>
                            <ul [ngClass]="{opened: openCPPlaylist.channels.id == cpc.id }">
                              <li *ngFor="let cp of openCPPlaylist.playlists.playlistsArr">
                                <span
                                *ngIf="searchStrCP == '' || ( searchStrCP != '' && filteredChildIDsCP.length > 0 && cp.name.toLowerCase().includes(searchStrCP.toLowerCase()))" (click)="getCPPlaylistInfo(4,cp.id)">{{cp.name}}</span>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li>
                <span [ngClass]="{opened: playlistType == 2 }" (click)="selectPlaylistType(2)">Foundation playlists</span>
                <ul [ngClass]="{opened: playlistType == 2 }">
                  <li *ngFor="let fpc of openPlaylist.category.catsArr">
                    <span
                      *ngIf=" (searchStrCP == '' && filteredParentIDsFP.length == 0 ) || (filteredParentIDsFP.length > 0 && filteredParentIDsFP.indexOf(fpc.id) > -1)" [ngClass]="{opened: openPlaylist.category.id == fpc.id }" (click)="getPlaylistInfo(1,fpc.id)">{{fpc.name}}</span>
                    <ul *ngIf="fpc.children!=undefined" [ngClass]="{opened: openPlaylist.category.id == fpc.id }">
                      <li *ngFor="let fpsc of fpc.children">
                        <span
                          *ngIf="(searchStrCP == '' && filteredChildIDsFP.length == 0 ) || (filteredChildIDsFP.length > 0 && filteredChildIDsFP.indexOf(fpsc.id) > -1)" (click)="getPlaylistInfo(2,fpsc.id)" [ngClass]="{opened: openPlaylist.subcategory.id == fpsc.id }">{{fpsc.name}}</span>
                        <ul [ngClass]="{opened: openPlaylist.subcategory.id == fpsc.id }">
                          <li *ngFor="let fp of openPlaylist.playlists.playlistsArr">
                            <span
                              *ngIf="searchStrCP == '' || ( searchStrCP != '' && filteredChildIDsFP.length > 0 && fp.name.toLowerCase().includes(searchStrCP.toLowerCase()))" (click)="getPlaylistInfo(3,fp.id)">{{fp.name}}</span>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
        <div class="tab_content" *ngFor="let t of tabs" [ngClass]="{selected: t.selected}" dndDropzone (dndDrop)="onDrop($event, t.playlistInfo.id)">
          <div class="info_box">
            <div class="info">BPM: <strong>{{t.averageBpm}}</strong></div>
            <div class="info" *ngIf="t.playlistInfo!=null">Last Autosave: <em>{{lastAutosave(t.playlistInfo.updated_sec)}}</em> ago</div>
            <div class="info" *ngIf="t.playlistInfo!=null">Current Status: <strong>{{playlistStatusText[t.playlistInfo.status]}}</strong></div>
            <div class="info">
              <a href="#" class="btn_linked_playlists" (click)="openLinkedPlaylistsPopup($event,t.playlistInfo.id)">Linked CP</a>
            </div>
            <div class="info" *ngIf="t.playlistInfo!=null">Tracks duration: <strong>{{convertTime(t.playlistInfo.total_track_duration)}}</strong></div>
            <div class="info" *ngIf="t!=null">#Tracks: <strong>{{t.tracks.length}}</strong></div>
          </div>

          <div class="table_wrap playlist_tracks">
            <div class="table_titles" style="line-height: 19px;">
              <div class="order" (click)="setOrder('mc_tags')"
                [ngClass]="{active: tabs[selectedTabIndex].orderBy=='mc_tags',asc:tabs[selectedTabIndex].orderBy.order=='ASC'}">
                MC Tag</div>
              <div class="order" (click)="setOrder('title')" [ngClass]="{active: tabs[selectedTabIndex].orderBy=='title',asc:tabs[selectedTabIndex].order=='ASC'}">Name</div>
              <div class="order" (click)="setOrder('artist')" [ngClass]="{active: tabs[selectedTabIndex].orderBy=='artist',asc:tabs[selectedTabIndex].order=='ASC'}">Artist</div>

              <div class="order" (click)="setOrder('add_date')" [ngClass]="{active: tabs[selectedTabIndex].orderBy=='add_date',asc:tabs[selectedTabIndex].order=='ASC'}">Date Added<span style="    font-weight: normal;
                font-style: italic;
                text-transform: lowercase;
                font-size: 9px;
              font-family: Verdana,sans-serif ;
                line-height: 1%
                   ">(FP)</span></div>
                    <div class="order" (click)="setOrder('add_date_lib')" [ngClass]="{active: tabs[selectedTabIndex].orderBy=='add_date_lib',asc:tabs[selectedTabIndex].order=='ASC'}">
                      <span> Date Added </span><span style="    font-weight: normal;
                      font-style: italic;
                      text-transform: lowercase;
                      font-size: 9px;
                    font-family:Verdana,sans-serif ;;
                      line-height: 1%
                         ">(Library)</span>

                          </div>

              <div class="order" (click)="setOrder('bpm')" [ngClass]="{active: tabs[selectedTabIndex].orderBy=='bpm',asc:tabs[selectedTabIndex].order=='ASC'}">BPM</div>
              <!-- <div>Tag</div> -->



              <div></div>
            </div>
            <div class="table_content" *ngIf="t.tracks.length > 0">
              <div class="table_row" [dndDraggable]="draggableRemove.data" [dndEffectAllowed]="draggableRemove.effectAllowed" [dndDisableIf]="draggableRemove.disable" (dndStart)="removeDragTrack($event,pt.pivot_id)" *ngFor="let pt of t.tracks; let i = index" (click)="selectPlaylistRow($event, pt.id, pt.pivot_id, i)"  [ngClass]="{selected: checkSelectedRow(t.selectedRows,pt.pivot_id)!=-1, 'kv-tracks':(pt.storage == 'no_storage' || pt.storage == 'storage_kv')}" [ngStyle]="setBgFromTag(pt.tags)">
                <div><em>{{pt.mc_tags}}</em></div>
                <div><strong>{{pt.title}}</strong></div>
                <div><em>{{pt.artist}}</em></div>

                <div>{{pt.add_date}}</div>
                <div>{{pt.created_at }}</div>
                <div><em>{{pt.bpm}}</em></div>
                <!-- <div class="track_tags"><span *ngFor="let ptt of pt.tags">{{ptt.name}}<span class="comma">, </span></span></div> -->

                <div class="func_col">
                  <a href="#" class="track_info" (click)="showEdit($event,pt.id,pt.pivot_id,1)" title="Detail"></a>

                  <a href="#" [ngClass]="playerInfo.trackId == pt.id && playerInfo.trackIndex == i && playerInfo.isPlaying  == 'play' ? 'track_paused active' : 'play_icon active'" (click)="playTrack($event, pt.id, pt.file_name, i, pt.album, pt.artist,pt.title, pt.storage)" title="Play"></a>
                  <a href="#" class="remove_track" (click)="openConfirmPopup(pt.pivot_id,0)" title="Delete" *ngIf="current_user!=null&&(current_user.type=='Consultant'||current_user.type=='Administrator')"></a>
                  <span *ngIf="playerInfo.trackId == pt.id && playerInfo.trackIndex == i && playerInfo.isPlaying  == 'play'" class="volume"></span>
                </div>
              </div>
            </div>
            <div class="table_content nf" *ngIf="t.tracks.length <= 0">
              <span>No results found</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="popup track_info_popup" [ngClass]="{opened: popups.editPopupOpened}" *ngIf="current_user!=null&&current_user.type=='Technician'" ngDraggable ngResizable [rzHandles]="'n,e,s,w,se,sw,ne,nw'" [handle]="dragHandle">
  <a href="#" class="popup_close" (click)="popupClose($event)"></a>
  <div class="popup_title" #dragHandle>File Information</div>
  <div class="popup_info"  *ngIf="trackInfo!=undefined">
      <img src="{{trackInfo.data.storage}}/waves/{{trackInfo.data.id}}.png" alt="">
      <div class="left_info">
        <div class="info_wrap">Title: {{trackInfo.data.title}}</div>
        <div class="info_wrap">Rating: {{trackInfo.data.rating!=null ? trackInfo.data.rating : '0'}}</div>
        <div class="info_wrap">Contributing Artists: {{trackInfo.data.contribute_artist!=null ? trackInfo.data.contribute_artist.name : ''}}</div>
        <div class="info_wrap">Album: {{trackInfo.data.album!=null ? trackInfo.data.album.name : ''}}</div>
        <div class="info_wrap">Project: {{trackInfo.data.genre!=null ? trackInfo.data.genre.name : ''}}</div>
        <div class="info_wrap">Length: {{trackInfo.data.length_time}}</div>
        <div class="info_wrap">Start: {{trackInfo.data.start}}</div>
        <div class="info_wrap">Highestchartpos: {{trackInfo.data.highestchartpos}}</div>
        <div class="info_wrap">Composers: {{trackInfo.data.composer!=null ? trackInfo.data.composer.name : ''}}</div>
        <div class="info_wrap">Mood: {{trackInfo.data.mood!=null ? trackInfo.data.mood.name : ''}}</div>
        <div class="info_wrap">Publisher: {{trackInfo.data.publisher!=null ? trackInfo.data.publisher.name : ''}}</div>
        <div class="info_wrap">Additional attributes: <span *ngFor="let a of additional">{{a}} </span></div>
      </div>
      <div class="right_info">
        <div class="info_wrap">Sub Title: {{trackInfo.data.sub_title}}</div>
        <div class="info_wrap">Comments: {{trackInfo.data.comments}}</div>
        <div class="info_wrap">Album Artist: {{trackInfo.data.artist!=null ? trackInfo.data.artist.name : ''}}</div>
        <div class="info_wrap">Year: {{trackInfo.data.year}}</div>
        <div class="info_wrap">Category: {{trackInfo.data.track_category!=null ? trackInfo.data.track_category.name : ''}}</div>
        <div class="info_wrap">Bitrate: {{trackInfo.data.bitrate_encoded}}</div>
        <div class="info_wrap">Finish: {{trackInfo.data.finish}}</div>
        <div class="info_wrap">Conductors: {{trackInfo.data.conductor!=null ? trackInfo.data.conductor.name : ''}}</div>
        <div class="info_wrap">BPM: {{trackInfo.data.bpm}}</div>
        <div class="info_wrap">Copyright: {{trackInfo.data.copyright}}</div>
      </div>
      <div class="popup_btns">
        <button type="button" class="cancel" (click)="popupClose($event)">Cancel</button>
      </div>
  </div>
</div>
<div *ngIf="current_user!=null&&(current_user.type=='Consultant'||current_user.type=='Administrator')" class="popup edit_track_popup" [ngClass]="{opened: popups.editPopupOpened}" ngDraggable ngResizable [rzHandles]="'n,e,s,w,se,sw,ne,nw'" [handle]="dragHandle2">
  <a href="#" class="popup_close" (click)="popupClose($event)"></a>
  <div class="popup_title" #dragHandle2>File Information</div>
  <div class="popup_form"  *ngIf="trackInfo!=undefined">
    <form #updateTrackForm="ngForm">
      <input type="hidden" name="id" value="{{trackInfo.data.id}}" [(ngModel)]="trackInfo.data.id">
      <div class="left_form">
        <div class="input_wrap">
          <label>Title</label>
          <input name="title" [disabled]="disabledInputs.title ? true : null" type="text" value = "{{trackInfo.data.title}}" [(ngModel)]="trackInfo.data.title" (focus)="focusInput()" (blur)="blurInput()">
        </div>
        <div class="input_wrap">
          <label>UID</label>
          <input name="uid" [readonly]="true" type="text" value = "{{trackInfo.data.uid}}">
        </div>
        <div class="input_wrap">
          <label>Rating</label>
          <select name="rating" [(ngModel)]="trackInfo.data.rating">
            <option value="null" [selected]="trackInfo.data.rating==null">Select rating</option>
            <option value="1" [selected]="trackInfo.data.rating=='1'">1</option>
            <option value="2" [selected]="trackInfo.data.rating=='2'">2</option>
            <option value="3" [selected]="trackInfo.data.rating=='3'">3</option>
            <option value="4" [selected]="trackInfo.data.rating=='4'">4</option>
            <option value="5" [selected]="trackInfo.data.rating=='5'">5</option>
          </select>
        </div>
        <div class="input_wrap">
          <label>Contributing Artists</label>
          <app-input-autocomplete
            [inputVal]="trackInfo.data.contribute_artist!=null ? trackInfo.data.contribute_artist.name : ''"
            [inputName]="'contribute_artist'"
            [queryData]="'contribute_artist'" (focus)="autoFocus($event)">
          </app-input-autocomplete>
        </div>
        <div class="input_wrap">
          <label>Album</label>
          <app-input-autocomplete
            [disabled]="disabledInputs.album ? true : null"
            [inputVal]="trackInfo.data.album!=null ? trackInfo.data.album.name : ''"
            [inputName]="'album'"
            [queryData]="'album'" (focus)="autoFocus($event)">
          </app-input-autocomplete>
        </div>
        <div class="input_wrap">
          <label>Project</label>
          <app-input-autocomplete
            [inputVal]="trackInfo.data.genre!=null ? trackInfo.data.genre.name : ''"
            [inputName]="'genre'"
            [queryData]="'genre'" (focus)="autoFocus($event)">
          </app-input-autocomplete>
        </div>
        <div class="input_wrap">
          <label>Length</label>
          <input type="text" value="{{trackInfo.data.length_time}}" disabled="disabled" (focus)="focusInput()" (blur)="blurInput()">
        </div>
        <div class="input_wrap">
          <label>Start</label>
          <input name="start" type="text" value="{{trackInfo.data.start}}" [(ngModel)]="trackInfo.data.start" (focus)="focusInput()" (blur)="blurInput()">
        </div>
        <div class="input_wrap">
          <label>Highest Chart Pos</label>
          <input name="highestchartpos" type="text" value="{{trackInfo.data.highestchartpos}}" [(ngModel)]="trackInfo.data.highestchartpos" (focus)="focusInput()" (blur)="blurInput()">
        </div>
        <div class="input_wrap">
          <label>Composers</label>
          <app-input-autocomplete
            [inputVal]="trackInfo.data.composer!=null ? trackInfo.data.composer.name : ''"
            [inputName]="'composer'"
            [queryData]="'composer'" (focus)="autoFocus($event)">
          </app-input-autocomplete>
        </div>
        <div class="input_wrap">
          <label>Mood</label>
          <app-input-autocomplete
            [inputVal]="trackInfo.data.mood!=null ? trackInfo.data.mood.name : ''"
            [inputName]="'mood'"
            [queryData]="'mood'" (focus)="autoFocus($event)">
          </app-input-autocomplete>
        </div>
        <div class="input_wrap">
          <label>Publisher</label>
          <app-input-autocomplete
            [inputVal]="trackInfo.data.publisher!=null ? trackInfo.data.publisher.name : ''"
            [inputName]="'publisher'"
            [queryData]="'publisher'" (focus)="autoFocus($event)">
          </app-input-autocomplete>
        </div>
        <div class="input_wrap" (click)="focusInput()">
          <label>Additional attributes</label>
          <ng-multiselect-dropdown
            [placeholder]="'MC Tags'"
            [settings]="dropdownSettings"
            [data]="mcTagsList"
            [(ngModel)]="selectedMCTags"
            (onSelect)="onItemSelect($event)"
            (onSelectAll)="onSelectAll($event)"
            name="mc_tags"
            class="mc_tag"
          ></ng-multiselect-dropdown>

        </div>

      </div>
      <div class="right_form">
        <div class="input_wrap">
          <label>Sub Title</label>
          <input name="sub_title" type="text" value="{{trackInfo.data.sub_title}}" [(ngModel)]="trackInfo.data.sub_title" (focus)="focusInput()" (blur)="blurInput()">
        </div>
        <div class="input_wrap">
          <label>File name</label>
          <input name="file_name" [readonly]="true" type="text" value = "{{trackInfo.data.file_name}}">
        </div>
        <div class="input_wrap">
          <label>Comments</label>
          <input name="comments" type="text" value="{{trackInfo.data.comments}}" [(ngModel)]="trackInfo.data.comments" (focus)="focusInput()" (blur)="blurInput()">
        </div>
        <div class="input_wrap">
          <label>Album Artist</label>
          <app-input-autocomplete
            [disabled]="disabledInputs.artist ? true : null"
            [inputVal]="trackInfo.data.artist!=null ? trackInfo.data.artist.name : ''"
            [inputName]="'artist'"
            [queryData]="'artist'" (focus)="autoFocus($event)">
          </app-input-autocomplete>
        </div>
        <div class="input_wrap">
          <label>Year</label>
          <input name="year" [disabled]="disabledInputs.year ? true : null" type="text" value="{{trackInfo.data.year}}" [(ngModel)]="trackInfo.data.year" (focus)="focusInput()" (blur)="blurInput()">
        </div>
        <div class="input_wrap">
          <label>Category</label>
          <app-input-autocomplete
            [inputVal]="trackInfo.data.track_category!=null ? trackInfo.data.track_category.name : ''"
            [inputName]="'track_category'"
            [queryData]="'track_category'" (focus)="autoFocus($event)">
          </app-input-autocomplete>
        </div>
        <div class="input_wrap">
          <label>Bitrate</label>
          <input type="text" value="{{trackInfo.data.bitrate_encoded}}" disabled="disabled" (focus)="focusInput()" (blur)="blurInput()">
        </div>
        <div class="input_wrap">
          <label>Finish</label>
          <input name="finish" type="text" value="{{trackInfo.data.finish}}" [(ngModel)]="trackInfo.data.finish" (focus)="focusInput()" (blur)="blurInput()">
        </div>
        <div class="input_wrap">
          <label>Conductors</label>
          <app-input-autocomplete
            [inputVal]="trackInfo.data.conductor!=null ? trackInfo.data.conductor.name : ''"
            [inputName]="'conductor'"
            [queryData]="'conductor'" (focus)="autoFocus($event)">
          </app-input-autocomplete>
        </div>
        <div class="input_wrap">
          <label>BPM</label>
          <input name="bpm" pattern="[0-9]*" type="text" value="{{trackInfo.data.bpm}}" [(ngModel)]="trackInfo.data.bpm" (focus)="focusInput()" (blur)="blurInput()">
        </div>
        <div class="input_wrap">
          <label>Copyright</label>
          <input name="copyright" type="text" value="{{trackInfo.data.copyright}}" [(ngModel)]="trackInfo.data.copyright" (focus)="focusInput()" (blur)="blurInput()">
        </div>
        <div class="input_wrap">
          <label>Encoded file name</label>
          <input type="text" value="{{trackInfo.data.file_name_altered_id}}" disabled="disabled">
        </div>
        <div class="input_wrap with_list">
          <label>Clients assigned track</label>
          <ul [ngClass]="{opened: drops.clients}" (click)="openDrop(1)">
            <li *ngFor="let cas of trackInfo.cp_lists_with_this_track">{{cas.name}}</li>
          </ul>
        </div>
        <div class="input_wrap with_list">
          <label>FPs assigned track</label>
          <ul [ngClass]="{opened: drops.fp}" (click)="openDrop(2)">
            <li *ngFor="let fas of trackInfo.fp_lists_with_this_track">{{fas.name}}</li>
          </ul>
        </div>
        <div class="input_wrap">
          <label>Wave form</label>
          <img src="{{trackInfo.data.storage}}/waves/{{trackInfo.data.id}}.png" alt="track wave" style="width:350px">
        </div>
      </div>
      <div class="right_main_form">
        <div class="input_wrap">
          <label>Explicit</label>
          <ui-switch
          labelOn="Y" labelOff="N"
          size="small"
          name="hidden"
          switchColor="#7a7a7a"
          defaultBoColor="#a8a8a8"
          [checked]="musicFileDetails.explicit == '1' "
          (change)="updateExplicit($event,trackInfo.data.id)"></ui-switch>
      </div>
        <div class="input_wrap">
          <label>Hard-In-Point</label>
          <input type="text" [value]="musicFileDetails.hard_in_point"  [(ngModel)]="musicFileDetails.hard_in_point"
          name="hard_in_point"  (focus)="focusInput()" (blur)="blurInput()" />
        </div>
        <div class="input_wrap">
          <label>Hard-Out-Point</label>
          <input type="text" [value]="musicFileDetails.hard_out_point"  [(ngModel)]="musicFileDetails.hard_out_point"
          name="hard_out_point"  (focus)="focusInput()" (blur)="blurInput()" />
        </div>

         <div class="input_wrap">
          <label>Hidden</label>
          <ui-switch
          labelOn="Y" labelOff="N"
          size="small"
          name="hidden"
          switchColor="#7a7a7a"
          defaultBoColor="#a8a8a8"
          [checked]="hiddenset == '1' "
          (change)="setHidden($event,trackInfo.data.id)"></ui-switch>
        </div>
        <div class="input_wrap">
          <label>Quality</label>
          <select style="width: 50%;" name="quality" [(ngModel)]="selectedqualityOption">
            <option *ngFor="let option of qualityoptions" [value]="option.value">{{ option.label }}</option>
          </select>
        </div>
        <div class="input_wrap">
          <label>Clip-Type</label>
          <select style="width: 50%;" name="clipType" [(ngModel)]="selectedclipTypeOption">
            <option *ngFor="let option of cliptypeoptions" [value]="option.value">{{ option.label }}</option>
          </select>
        </div>
        <div class="input_wrap">
          <label>Genre-1</label>
          <select style="width: 50%;" name="genre1" [(ngModel)]="selectedmusicGenre1">
            <option *ngIf="selectedmusicGenre1 === -1" [value]="-1">Please choose an option</option>
            <option *ngFor="let option of trackInfo.genre_list;let i=index" [value]="option.kvs_id"> {{ option.genre_name }}</option>
          </select>
        </div>
        <div class="input_wrap">
          <label>Genre-2</label>
          <select style="width: 50%;" name="genre2" [(ngModel)]="selectedmusicGenre2">
            <option *ngIf="selectedmusicGenre2 === -1" [value]="-1">Please choose an option</option>
            <option *ngFor="let option of trackInfo.genre_list;let i=index" [value]="option.kvs_id"> {{ option.genre_name }}</option>
          </select>
        </div>
        <div class="input_wrap">
          <label>Genre-3</label>
          <select style="width: 50%;" name="genre3" [(ngModel)]="selectedmusicGenre3">
            <option *ngIf="selectedmusicGenre3 === -1" [value]="-1">Please choose an option</option>
            <option *ngFor="let option of trackInfo.genre_list;let i=index" [value]="option.kvs_id"> {{ option.genre_name }}</option>
          </select>
        </div>
        <div class="input_wrap">
          <label>ISRC</label>
          <input name="isrc" type="text" value="{{ trackInfo.data.isrc }}" [(ngModel)]="trackInfo.data.isrc" (focus)="focusInput()" (blur)="blurInput()" />
        </div>
      </div>
      <div class="popup_btns">
        <a href="#" class="btn prev" (click)="getPrevTrackInfo($event)">Prev</a>
        <a href="#" class="btn next" (click)="getNextTrackInfo($event)">Next</a>
        <button type="button" class="delete" (click)="openConfirmPopup(trackInfo.data.id,2)"></button>
        <button type="button" class="cancel" (click)="popupClose($event)">Cancel</button>
        <button type="submit" (click)="trackDataUpdate($event,updateTrackForm)">Update</button>
      </div>
    </form>
  </div>
</div>
<div class="popup smart_filter_popup" [ngClass]="{opened: popups.smartFilterPopupOpened}" ngDraggable ngResizable [rzHandles]="'n,e,s,w,se,sw,ne,nw'" [handle]="dragHandle3">
  <a href="#" class="popup_close" (click)="popupClose($event)"></a>
  <div class="popup_title" #dragHandle3>Smart Filter</div>
  <div class="filter_inner">
    <div class="top_filter">
      Match <select [(ngModel)]="matchSelect">
        <option value="all">All</option>
        <option value="any">Any</option>
        <option value="none">None</option>
      </select> of the following rules:
        <span style="float: right;" *ngIf="profileFilterCount > 1">
        <select [(ngModel)]="andOrSelect">
          <option value="and">AND</option>
          <option value="or">OR</option>
        </select>
      </span>
    </div>
    <div class="filter_line" *ngFor="let filter of smartFilters;let i = index">
      <div class="left_col">
        <select (change)="changeSmartSelect($event,i)" [disabled]="filter.isHidden" [(ngModel)]="smartFilters[i].categorySelect">
          <option value="null">Select...</option>
          <option *ngFor="let sf of defaultSmartFilters"  value="{{sf.field}}">
            {{ sf.field === 'foundation_play_list_id' ? 'FP Playlist Name' : sf.field === 'cp_playlist_id' ? 'CP Playlist Name' : sf.title }}
          </option>

        </select>
      </div>
      <div class="middle_col">
        <select *ngIf="filter.compare!=null" [(ngModel)]="smartFilters[i].compareSelect">
          <option value="null">Select...</option>
          <option *ngFor="let c of filter.compare" value="{{c}}">{{c}}</option>
        </select>
      </div>
      <div class="right_col" *ngIf="smartFilters[i].categorySelect == 'mc_tags'; else default">
        <select [(ngModel)]="smartFilters[i].field">
          <option value="null">Select...</option>
          <option *ngFor="let tag of mcTagsList"  value="{{tag.item_text}}">{{tag.item_text}}</option>
        </select>
      </div>

      <ng-template #default>
      <div class="right_col" *ngIf="filter.type=='str'">
        <input type="text" value="" [(ngModel)]="smartFilters[i].field"/>
      </div>
      <div class="right_col" *ngIf="filter.type=='date'">
        <my-date-picker name="date1" [options]="myDatePickerOptions" placeholder="dd/mm/yyyy" [(ngModel)]="smartFilters[i].arrFields[0]"></my-date-picker>
        <my-date-picker name="date1" [options]="myDatePickerOptions" placeholder="dd/mm/yyyy" [(ngModel)]="smartFilters[i].arrFields[1]"></my-date-picker>
      </div>
      <div class="right_col" *ngIf="filter.type=='int'">
        <input type="number" class="number" value="" [(ngModel)]="smartFilters[i].arrFields[0]"/>
        <input type="number" class="number" value="" [(ngModel)]="smartFilters[i].arrFields[1]" *ngIf="smartFilters[i].compareSelect=='between'"/>
      </div>
      </ng-template>
      <div class="func_col">
        <a href="#" *ngIf="smartFilters.length>1" class="btn_remove" (click)="removeFilterField($event,i)"></a>
        <a href="#" class="btn_add" (click)="addFilterField($event)"></a>
      </div>
    </div>
    <div class="popup_btns">
      <!--Matches: 890 tracks-->
      <button type="button" class="cancel" (click)="clearFilter($event)">Clear Filter</button>
      <button type="button" class="cancel" (click)="popupClose($event)">Cancel</button>
      <button type="submit" class="submit" (click)="smartSearch(true)">Search</button>
    </div>
  </div>
</div>
<div class="popup tag_manager_popup" [ngClass]="{opened: popups.manageTagsPopupOpened}" ngDraggable ngResizable [rzHandles]="'n,e,s,w,se,sw,ne,nw'" [handle]="dragHandle4">
  <a href="#" class="popup_close" (click)="popupClose($event)"></a>
  <div class="popup_title" #dragHandle4>Tag Manager</div>
  <div class="tag_manager_inner">
    <div class="tags_column">
      <ul>
        <li *ngIf="tags[0]!=undefined">
          <span>1</span>
          <input type="text" value="{{tags[0].name}}" [(ngModel)]="tags[0].name"/>
          <input [value]="tags[0].color" [(colorPicker)]="tags[0].color" (colorPickerChange)="changeBg( 0)" [(style.background)]="tags[0].bgColor" class="colorpicker"/>
          <a href="#" class="clear_values" (click)="clearTags($event,0)">X</a>
        </li>
        <li *ngIf="tags[1]!=undefined">
          <span>2</span>
          <input type="text" value="{{tags[1].name}}" [(ngModel)]="tags[1].name"/>
          <input [value]="tags[1].color" [(colorPicker)]="tags[1].color" (colorPickerChange)="changeBg( 1 )" [(style.background)]="tags[1].bgColor" class="colorpicker"/>
          <a href="#" class="clear_values" (click)="clearTags($event,1)">X</a>
        </li>
        <li *ngIf="tags[2]!=undefined">
          <span>3</span>
          <input type="text" value="{{tags[2].name}}" [(ngModel)]="tags[2].name"/>
          <input [value]="tags[2].color" [(colorPicker)]="tags[2].color" (colorPickerChange)="changeBg( 2)" [(style.background)]="tags[2].bgColor" class="colorpicker"/>
          <a href="#" class="clear_values" (click)="clearTags($event,2)">X</a>
        </li>
        <li *ngIf="tags[3]!=undefined">
          <span>4</span>
          <input type="text" value="{{tags[3].name}}" [(ngModel)]="tags[3].name"/>
          <input [value]="tags[3].color" [(colorPicker)]="tags[3].color" (colorPickerChange)="changeBg( 3 )" [(style.background)]="tags[3].bgColor" class="colorpicker"/>
          <a href="#" class="clear_values" (click)="clearTags($event,3)">X</a>
        </li>
        <li *ngIf="tags[4]!=undefined">
          <span>5</span>
          <input type="text" value="{{tags[4].name}}" [(ngModel)]="tags[4].name"/>
          <input [value]="tags[4].color" [(colorPicker)]="tags[4].color" (colorPickerChange)="changeBg( 4 )" [(style.background)]="tags[4].bgColor" class="colorpicker"/>
          <a href="#" class="clear_values" (click)="clearTags($event,4)">X</a>
        </li>
      </ul>
    </div>
    <div class="tags_column">
      <ul>
        <li *ngIf="tags[5]!=undefined">
          <span>6</span>
          <input type="text" value="{{tags[5].name}}" [(ngModel)]="tags[5].name"/>
          <input [value]="tags[5].color" [(colorPicker)]="tags[5].color" (colorPickerChange)="changeBg( 5 )" [(style.background)]="tags[5].bgColor" class="colorpicker"/>
          <a href="#" class="clear_values" (click)="clearTags($event,5)">X</a>
        </li>
        <li *ngIf="tags[6]!=undefined">
          <span>7</span>
          <input type="text" value="{{tags[6].name}}" [(ngModel)]="tags[6].name"/>
          <input [value]="tags[6].color" [(colorPicker)]="tags[6].color" (colorPickerChange)="changeBg( 6 )" [(style.background)]="tags[6].bgColor" class="colorpicker"/>
          <a href="#" class="clear_values" (click)="clearTags($event,6)">X</a>
        </li>
        <li *ngIf="tags[7]!=undefined">
          <span>8</span>
          <input type="text" value="{{tags[7].name}}" [(ngModel)]="tags[7].name"/>
          <input [value]="tags[7].color" [(colorPicker)]="tags[7].color" (colorPickerChange)="changeBg( 7 )" [(style.background)]="tags[7].bgColor" class="colorpicker"/>
          <a href="#" class="clear_values" (click)="clearTags($event,7)">X</a>
        </li>
        <li *ngIf="tags[8]!=undefined">
          <span>9</span>
          <input type="text" value="{{tags[8].name}}" [(ngModel)]="tags[8].name"/>
          <input [value]="tags[8].color" [(colorPicker)]="tags[8].color" (colorPickerChange)="changeBg( 8 )" [(style.background)]="tags[8].bgColor" class="colorpicker"/>
          <a href="#" class="clear_values" (click)="clearTags($event,8)">X</a>
        </li>
        <li *ngIf="tags[9]!=undefined">
          <span>0</span>
          <input type="text" value="{{tags[9].name}}" [(ngModel)]="tags[9].name"/>
          <input [value]="tags[9].color" [(colorPicker)]="tags[9].color" (colorPickerChange)="changeBg( 9 )" [(style.background)]="tags[9].bgColor" class="colorpicker"/>
          <a href="#" class="clear_values" (click)="clearTags($event,9)">X</a>
        </li>
      </ul>
    </div>
    <div class="popup_btns">
      <button type="button" class="cancel" (click)="popupClose($event)">Cancel</button>
      <button type="submit" class="submit" (click)="updateTags($event)">Save</button>
    </div>
  </div>
</div>


<app-player-component [isInputFocused]="isFocused" [storage]="playerInfo.storage" [token]="playerInfo.token" [track]="playerInfo.trackId" [wave]="playerInfo.trackId" [album]="playerInfo.album" [artist]="playerInfo.artist"  (outputPlaying)="checkPlaying($event)" (next)="nextTrack($event)" (prev)="prevTrack($event)"></app-player-component>

<div class="popup create_playlist_popup" [ngClass]="{opened: popups.playlistPopupOpened}" ngDraggable ngResizable [rzHandles]="'n,e,s,w,se,sw,ne,nw'" [handle]="dragHandle6">
  <a href="#" class="popup_close" (click)="popupClose($event)"></a>
  <div class="popup_title" #dragHandle6>Create/Edit Playlist</div>
  <div class="popup_form">
    <form #updateFoundationPlaylist="ngForm">
      <input type="hidden" name="status" value="{{playlistFields.status}}" [(ngModel)]="playlistFields.status">
      <div class="input_wrap">
        <label>Name</label>
        <input maxlength="255" autocomplete="off" type="text" name="name" value="{{playlistFields.name}}" [(ngModel)]="playlistFields.name"/>
      </div>
      <div class="input_wrap">
        <label>Category</label>
        <select name="foundation_category_id" *ngIf="categoriesList!=null" [(ngModel)]="playlistFields.catId" (change)="changeCategory($event)">
          <option *ngFor="let c of categoriesList" value="{{c.id}}" [selected]="playlistFields.catId == c.id">{{c.name}}</option>
        </select>
      </div>

      <div class="input_wrap" *ngIf="subcategoriesList!=null">
        <label>Subcategory</label>
        <select name="foundation_subcategory_id" [(ngModel)]="playlistFields.subcatId">
          <option *ngFor="let sc of subcategoriesList" value="{{sc.id}}" [selected]="playlistFields.subcatId == sc.id">{{sc.name}}</option>
        </select>
      </div>


      <div class="popup_btns">
        <button type="button" class="cancel" (click)="popupClose($event)">Cancel</button>
        <button type="submit" (click)="playlistDataUpdate($event,updateFoundationPlaylist)">Save</button>
      </div>
    </form>
  </div>
</div>
<div class="popup linked_playlist_popup" [ngClass]="{opened: popups.linkedPlaylistsPopupOpened}" ngDraggable ngResizable [rzHandles]="'n,e,s,w,se,sw,ne,nw'" [handle]="dragHandle7">
  <a href="#" class="popup_close" (click)="popupClose($event)"></a>
  <div class="popup_title" #dragHandle7>Linked Playlists</div>
  <div class="popup_form">
    <div class="table_wrap linked_playlist_table">
      <div class="table_titles">
        <div>Client</div>
        <div>Site</div>
        <div>Channel</div>
        <div>Playlist</div>
        <div>Actions</div>
      </div>
      <div class="table_content">
        <div class="table_row" *ngFor="let lp of linkedPlaylists">
          <div>{{lp.client}}</div>
          <div>{{lp.site}}</div>
          <div>{{lp.channel}}</div>
          <div>{{lp.name}}</div>
          <div>
            <a class="edit_icon" title="Edit" href="#" (click)="openLinkedPlaylistsEditPopup($event,lp.id)"></a>
            <a class="delete_icon" title="Delete" href="#" (click)="openConfirmPopup(lp.id,3)"></a>
          </div>
        </div>
      </div>
    </div>
    <div class="popup_btns">
      <button type="button" class="cancel" (click)="popupClose($event)">Close</button>
    </div>
  </div>
</div>
<div class="popup create_playlist_popup" [ngClass]="{opened: popups.linkedPlaylistEditPopupOpened}" ngDraggable ngResizable [rzHandles]="'n,e,s,w,se,sw,ne,nw'" [handle]="dragHandle8">
  <a href="#" class="popup_close" (click)="popupLinkedClose($event)"></a>
  <div class="popup_title" #dragHandle8>Create/Edit Playlist</div>
  <div class="popup_form">
    <form #updatePlaylist="ngForm">
      <input type="hidden" name="status" value="linkedPlaylistFields.status" [(ngModel)]="linkedPlaylistFields.status">
      <input type="hidden" name="user_id" *ngIf="linkedPlaylistFields.id != null" value="{{linkedPlaylistFields.user}}" [(ngModel)]="linkedPlaylistFields.user"/>
      <div class="input_wrap">
        <label>Name</label>
        <input maxlength="255" autocomplete="off" type="text" name="name" value="{{linkedPlaylistFields.name}}" [(ngModel)]="linkedPlaylistFields.name"/>
      </div>
      <div class="input_wrap">
        <label>Site</label>
        <select name="site_id" [(ngModel)]="linkedPlaylistFields.site" (change)="siteChange($event)">
          <option *ngFor="let site of linkedPlaylistFields.sitesList" value="{{site.id}}" [selected]="linkedPlaylistFields.site==site.id">{{site.name}}</option>
        </select>
      </div>
      <div class="input_wrap">
        <label>Channel</label>
        <select name="chanel_id" [(ngModel)]="linkedPlaylistFields.chanel">
          <option *ngFor="let c of linkedPlaylistFields.channelsList" value="{{c.id}}" [selected]="linkedPlaylistFields.chanel==c.id">{{c.name}}</option>
        </select>
      </div>
      <div class="popup_btns">
        <button type="button" class="cancel" (click)="popupLinkedClose($event)">Cancel</button>
        <button type="submit" (click)="linkedPlaylistUpdate($event,updatePlaylist)">Save</button>
      </div>
    </form>
  </div>
</div>

<div class="popup playlist_report_popup" [ngClass]="{opened: playlist_report_dialog}" ngDraggable ngResizable [rzHandles]="'n,e,s,w,se,sw,ne,nw'" [handle]="dragHandle9">
  <a href="#" class="popup_close" (click)="popupCloseRD()"></a>
  <div class="popup_title" #dragHandle9>Playlist report</div>
  <div class="popup_form">
    <div class="table_wrap playlist_report_table">
      <div class="table_titles">
        <div>MC tag</div>
        <div>% of tracks</div>
      </div>
      <div class="table_content">
        <div class="table_row" *ngFor="let report of playlist_report | keyvalue">
          <div>{{report.key}}</div>
          <div>{{report.value}}</div>
        </div>
        <div class="table_row" *ngIf="playlist_report">
          <div style="border-top: 1px solid #3e3d3b;">Total</div>
          <div style="border-top: 1px solid #3e3d3b;">100</div>
        </div>
      </div>
    </div>
    <div class="popup_btns">
      <button type="button" class="cancel" (click)="popupCloseRD()">Close</button>
    </div>
  </div>
</div>

<!--search Uid PopUP-->
<div class="popup " [ngClass]="{ opened: searchBulkUidPopUpOpen }" ngDraggable ngResizable
  [rzHandles]="'n,e,s,w,se,sw,ne,nw'" [handle]="dragHandle5">
  <a href="#" class="popup_close" (click)="popupClose($event)"></a>
  <div class="popup_title" #dragHandle5>Bulk UID Search</div>
  <div class="filter_inner">
    <label for="searchUID">Enter UID</label>
    <textarea name="searchUID" id="searchUID" rows="10" (focus)="focusInput()" [(ngModel)]="searchUID"></textarea>
    <div class="popup_btns">
      <button type="button" class="clear_search" (click)="clearBoxUID()">Clear</button>
      <button type="button" class="cancel" (click)="popupClose($event)">Cancel</button>
      <button type="submit" class="submit" (click)="searchUIDFunction()">Search</button>
    </div>
  </div>
</div>


<div class="fade" [ngClass]="{opened: isLoad}"></div>
<img src="../../assets/img/loader.gif" alt="loader" class="loader" [ngClass]="{active:isLoad}" style="z-index:100"/>
<app-alert></app-alert>
<app-confirm (isConfirm)="isConfirm($event)"></app-confirm>
