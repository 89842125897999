import { Component, OnInit,ViewChild } from '@angular/core';
import { Google2FAsharedDataService } from '../services/google2-fashared-data.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { NgForm } from "@angular/forms";
import { UserService } from "../services/user.service";
import { AlertComponent } from "../alert/alert.component";
import { CookieService } from "ngx-cookie-service";

@Component({
  selector: 'app-google2-fa',
  templateUrl: './google2-fa.component.html',
  styleUrls: ['./google2-fa.component.scss']
})
export class Google2FaComponent implements OnInit {

  @ViewChild(AlertComponent, { static: true }) alert: AlertComponent;//Component of alert popup
  constructor(
    private Google2FAsharedDataService: Google2FAsharedDataService,
    private sanitizer: DomSanitizer,
    private userService: UserService,
    private cookieService: CookieService,
  ) {}

  receivedData: any;
  sanitizedQRImage: SafeHtml;
  OtpPage=false;
  otp: string;
  errorMessage: string | null = null;

  loginData = {
    username: "",
    password: "",
    client_id: 1,
    client_secret: "ylTeDJSWV7j8HlWrdmQSDo9gAwkFjUBsGyOvdwwQ",
    grant_type: "password",
    scope: "*"
  }

  ngOnInit(): void {

    this.receivedData = this.Google2FAsharedDataService.getData();
    if(this.receivedData.google2fa_qr_scanned ==1){
      this.OtpPage=true;
    }
    this.sanitizeQRImage();
    this.Google2FAsharedDataService.clearData();

  }
  sanitizeQRImage(): void {
    this.sanitizedQRImage = this.sanitizer.bypassSecurityTrustHtml(this.receivedData.qr_image);
  }

  GotoOtpPage(){

    this.OtpPage=true;

  }

  verifyOtp() {

    if (!this.otp && (this.otp='undefined')) {
      this.alert.alertOpen('OTP is required',1);
    } else {

      const str = `otp=${encodeURIComponent(this.otp)}&username=${encodeURIComponent(this.receivedData.username)}&client_id=${encodeURIComponent(this.loginData.client_id)}&client_secret=${encodeURIComponent(this.loginData.client_secret)}&grant_type=${encodeURIComponent(this.loginData.grant_type)}&scope=${encodeURIComponent(this.loginData.scope)}&password=${encodeURIComponent(this.receivedData.password)}`;
      this.userService.verify2FA(str).subscribe(
        token => {
          if (token != null) {
            this.cookieService.set("access", token["access_token"]);
            if (window.location.pathname == "/Google2Fa") {
              setTimeout(() => {
                window.location.href = "/";
              }, 100);
            }
          }
        },
        (error: any) => {
          this.alert.alertOpen("Invalid 2FA code", 1);
        }
      );
    }
  }

}
