import { HttpClient, HttpHeaders, HttpParams, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Globals } from '../globals';

@Injectable()
export class ClientSampleService {

  httpOptions = {
    headers: new HttpHeaders({
      'Host': environment.dev_url_prefix + '/',
      'Authorization': 'Bearer ' + this.globals.access_token,
      'Accept': 'application/json'
    })
  };
  postOptions = {
    headers: new HttpHeaders({
      'Host': environment.dev_url_prefix + '/',
      'Authorization': 'Bearer ' + this.globals.access_token,
      'Accept': 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded'
    })
  }
  deleteOptions = {
    headers: new HttpHeaders({
      'Host': environment.dev_url_prefix + '/',
      'Authorization': 'Bearer ' + this.globals.access_token,
      'Accept': 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded'
    })
  }
  httpRequestHeaders = {
    headers: new HttpHeaders({
      'Host': environment.dev_url_prefix + '/',
      'Authorization': 'Bearer ' + this.globals.access_token,
      'Accept': 'application/json',
      'Content-Type': 'multipart/form-data'
    })
  }

  constructor(
    private http: HttpClient,
    private globals: Globals
  ) { }

  /**
   Get playlist information
   * @param pid - playlist id
   * @returns {Observable<any>}
   */
  getclientlistbyname(str) {
    return this.http.get(environment.dev_url_prefix + '/api/V1/client_sample/search/' + str, this.httpOptions).pipe(map(data => {
      return data;
    }));
  }

  getAllClient() {
    return this.http.get(environment.dev_url_prefix + '/api/V1/client_sample/allClients', this.httpOptions).pipe(map(data => {
      return data;
    }));
  }


  importClientandChannels(clientsabdChannels, clientname) {
    return this.http.get(environment.dev_url_prefix + '/api/V1/client_sample/importdata/' + clientsabdChannels + '/' + clientname, this.httpOptions).pipe(map(data => {
      return data;
    }));
  }

  getClientsList() {
    return this.http.get(environment.dev_url_prefix + '/api/V1/client_sample/getclientlist', this.httpOptions).pipe(map(data => {
      return data;
    }));
  }

  getChannelsByuser(uid) {
    return this.http.get(environment.dev_url_prefix + '/api/V1/client_sample/getchannelslist/' + uid, this.httpOptions).pipe(map(data => {
      return data;
    }));
  }

  /**
   * 
   * @param str property name
   * @param cid selected Client id 
   * @param uid user id 
   * @returns 
   */

  storeAndUpdateClientProperty(str, cid, uid) {
    return this.http.get(environment.dev_url_prefix + '/api/V1/client_sample/clientpropertytype/' + str + '/' + cid + '/' + uid, this.httpOptions).pipe(map(data => {
      return data;
    }));
  }

  getClientPropertydata(cid) {
    return this.http.get(environment.dev_url_prefix + '/api/V1/client_sample/getclientproperty/' + cid, this.httpOptions).pipe(map(data => {
      return data;
    }));
  }

  uploadImageandPDF(file: any) {
    // const req = new HttpRequest("POST",environment.dev_url_prefix + '/api/V1/client_sample/uploadfilename' , file, {
    //   reportProgress: true,
    //   responseType: "json",
    //   headers: this.httpRequestHeaders,
    // });
    // return this.http.request(req);

    console.log(file);
    return this.http.post(environment.dev_url_prefix + '/api/V1/client_sample/uploadfilename', file, this.postOptions).pipe(map(data => {
      return data;
    }));
  }

  addcommentsonproperty(str, uid, pid) {
    return this.http.get(environment.dev_url_prefix + '/api/V1/client_sample/addcomment/' + str + '/' + uid + '/' + pid, this.httpOptions).pipe(map(data => {
      return data;
    }));
  }

  updatecommentsonproperty(str, uid, clientproerprtycommentid) {
    return this.http.get(environment.dev_url_prefix + '/api/V1/client_sample/editcomment/' + str + '/' + uid + '/' + clientproerprtycommentid, this.httpOptions).pipe(map(data => {
      return data;
    }));
  }

  deletecommentsonproperty(client_id) {
    return this.http.delete(environment.dev_url_prefix + '/api/V1/client_sample/deletecomment/' + client_id, this.deleteOptions).pipe(map(data => {
      return data;
    }));

  }

  downloadPDFfile(filename) {
    return this.http.get(environment.dev_url_prefix + '/api/V1/client_sample/downloadPDF/' + filename, this.httpOptions).pipe(map(data => {
      return data;
    }));
  }

  editChannelNameById(str: string, cid: any) {
    return this.http.get(environment.dev_url_prefix + '/api/V1/client_sample/edit_Channel_byId/' + str + '/' + cid, this.httpOptions).pipe(map(data => {
      return data;
    }));
  }

  /**
    Create new playlist
    * @param pid - channel id
    * @param formData - post data
    * @returns {Observable<any>}
    */
  createPlaylist(formData) {
    return this.http.post(environment.dev_url_prefix + "/api/V1/client_sample/create", formData, this.postOptions).pipe(map(playlists => {
      return playlists;
    })
    );
  }

  /**
   * Get all playlist 
   * @param sid  channel id
   * 
   */
  getPlaylists(sid: any) {
    return this.http.get(environment.dev_url_prefix + '/api/V1/client_sample/getplaylist/' + sid, this.httpOptions).pipe(map(data => {
      return data;
    }));
  }
  /**
   * Get playlist by playlist id 
   * @param pid playlist id 
   */

  getPlaylistById(pid) {
    return this.http.get(environment.dev_url_prefix + '/api/V1/client_sample/getplaylistbyid/' + pid, this.httpOptions).pipe(map(data => {
      return data;
    }));
  }

  /**
   * Update playlist 
   * @param sid 
   * @param pid 
   * @param formData 
   */

  updatePlaylist(pid: any, formData) {
    return this.http.post(environment.dev_url_prefix + "/api/V1/client_sample/update/" + pid, formData, this.postOptions).pipe(map(playlists => {
      return playlists;
    })
    );
  }

  setStatus(pid, status) {
    return this.http.get(environment.dev_url_prefix + '/api/V1/client_sample/setstatus/' + pid + '/' + status, this.httpOptions).pipe(map(data => {
      return data;
    }));
  }

  getArchivePlaylists(sid) {
    return this.http.get(environment.dev_url_prefix + '/api/V1/client_sample/archiveplaylist/' + sid, this.httpOptions).pipe(map(data => {
      return data;
    }));
  }

  /**
 Delete playlist
 * @param pid - playlist id
 * @returns {Observable<any>}
 */
  deletePlaylist(pid) {
    return this.http.delete(environment.dev_url_prefix + '/api/V1/client_sample/delete/' + pid, this.deleteOptions).pipe(map(playlistDelete => {
      return playlistDelete;
    }));
  }


  /**
   Get playlist information for manager page with music files 
   * @param pid - playlist id
   * @returns {Observable<any>}
   */
  getmanagerPlaylistById(pid) {
    return this.http.get(environment.dev_url_prefix + '/api/V1/client_sample/getmanagerPlaylistById/' + pid, this.httpOptions).pipe(map(data => {
      return data;
    }));
  }


  /**
  Add new track to playlist
  * @param pid - playlist id
  * @param tid - track id
  * @param fpid - foundation playlist id
  * @returns {Observable<any>}
  */
  addTrackToPlaylist(pid, tid, fpid) {
    var str = '';
    if (fpid != null) {
      str = '/' + fpid;
    }
    return this.http.post(environment.dev_url_prefix + '/api/V1/client_sample/file/add/' + pid + '/' + tid + str, '', this.postOptions).pipe(map(tracks => {
      return tracks;
    }));
  }


  /**
   Get all tracks in playlist
   * @param pid - playlist id
   * @param order - order of diplaying tracks
   * @param orderBy - ordering rule
   * @returns {Observable<any>}
   */
  getPlaylistTracks(pid, order, orderBy) {
    return this.http.get(environment.dev_url_prefix + '/api/V1/client_sample/files/' + pid + '/' + orderBy + '/' + order, this.httpOptions).pipe(map(tracks => {
      return tracks;
    }));
  }

  /**
  Delete track from playlist
  * @param pid - playlist id
  * @param tid - track id
  * @returns {Observable<any>}
  */
  deletePlaylistTrack(pid, tid) {
    return this.http.delete(environment.dev_url_prefix + '/api/V1/client_sample/file/delete/' + pid + '/' + tid, this.deleteOptions).pipe(map(trackDelete => {
      return trackDelete;
    }));
  }

  /**
  Search tracks in playlist
  * @param str - search string
  * @param cid - playlist id
  * @returns {Observable<any>}
  */
  search(str, cid) {
    return this.http.get(environment.dev_url_prefix + '/api/V1/client_sample/track_search/' + str + "/" + cid + "/0/100", this.httpOptions).pipe(map(tracks => {
      return tracks;
    }));
  }


  getPlaylistsCategories() {
    return this.http.get(environment.dev_url_prefix + '/api/V1/client_sample/getplaylistcategory', this.httpOptions).pipe(map(data => {
      return data;
    }));
  }

  searchPlaylistsFP(str) {

    return this.http.get(environment.dev_url_prefix + "/api/V1/client_sample/cps_category_search/" + str, this.httpOptions).pipe(map(category => {
      return category;
    }));
  }

   /**
   Search tracks in playlist
   * @param str - search string
   * @param pid - playlist id
   * @returns {Observable<any>}
   */
   searchbar(str, pid) {return this.http.get(environment.dev_url_prefix +"/api/V1/client_sample/track_search_csp/" +str +"/" +pid +"/0/100",this.httpOptions).pipe(map(tracks => {
          return tracks;
        })
      );
  }

  retain(formData) {
    return this.http.post(environment.dev_url_prefix + '/api/V1/client_sample/retain', JSON.stringify(formData), this.postOptions).pipe(map(playlists => {
      return playlists;
    }));
  }
  removeRetain(formData) {
    return this.http.post(environment.dev_url_prefix + '/api/V1/client_sample/remove_retain', JSON.stringify(formData), this.postOptions).pipe(map(playlists => {
      return playlists;
    }));
  }

  updateExcluded(cpmf_id, formData, type) {
    let url = '/api/V1/client_sample/updateCSpmfExcluded/';
    return this.http.put(environment.dev_url_prefix + url + cpmf_id, formData, this.postOptions).pipe(map(playlists => {
      return playlists;
    }));
  }

  createLink(playlist_id){
    return this.http.get(environment.dev_url_prefix +"/api/V1/client_sample/createtoken/" +playlist_id ,this.httpOptions).pipe(map(token => {
      return token;
    })
  );
  }

  getCommentsByPid(pid){
    return this.http.get(environment.dev_url_prefix +"/api/V1/client_sample/getCommentsByPid/" +pid ,this.httpOptions).pipe(map(data => {
      return data;
    })
  );
  }



}
