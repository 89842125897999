import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
import { CPPlaylistCategoriesService } from '../playlist-categories/cp_playlist-categories.service';
import { CPPlaylistsService } from '../services/cp_playlists.service';
import { ConsultantService } from '../services/consultant.service';
import { CommonService } from '../services/common.service';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { UserService } from '../services/user.service';
import { PlayerComponentComponent } from '../player-component/player-component.component';
import { TracksService } from '../services/tracks.service';
import { IMyDpOptions } from 'mydatepicker';
import { ScheduleService } from '../services/schedule.service';
import { AlertComponent } from '../alert/alert.component';
import { ConfirmComponent } from '../confirm/confirm.component';
import { Globals } from '../globals';
import { HttpHeaders, HttpClient } from "@angular/common/http";
import { environment } from '../../environments/environment';
import { ProfileStatementService } from '../services/profile_statements.service';
import { PlaylistCategoriesService } from '../playlist-categories/playlist-categories.service';
import { PlaylistsService } from '../services/playlists.service';


@Component({
  selector: "app-consultant-functions",
  templateUrl: "./consultant-functions.component.html",
  styleUrls: ["./consultant-functions.component.scss"],
  providers: [CPPlaylistsService, CPPlaylistCategoriesService, CommonService, UserService, TracksService, ScheduleService, ConsultantService, ProfileStatementService, PlaylistCategoriesService, PlaylistsService]
})
export class ConsultantFunctionsComponent implements OnInit {
  current_user = null;
  usersList: any = Array();
  checkedUserId = {
    id: null,
    name: ''
  };
  userFields = {
    id: null,
    name: '',
    address: '',
    address_2: '',
    address_3: '',
    address_4: '',
    city: '',
    county: '',
    postcode: '',
    country: '',
    /*password: '',
    repeat_password: '',*/
    type: 'Client',
    active: 1
  };
  userPopupOpened = false;
  fileUploadPopupOpened = false;
  PDFPopupOpened = false;


  sitesList: any = null;
  sitePopupOpened = false;
  siteFields = {
    id: null,
    name: '',
    address: '',
    address_2: '',
    address_3: '',
    address_4: '',
    city: '',
    county: '',
    postcode: '',
    country: '',
    visible: 1,
    user: null
  }
  checkedSiteId = {
    id: null,
    name: ''
  };

  chanelsList: any = null;
  chanelPopupOpened = false;
  chanelFields = {
    id: null,
    name: '',
    visible: 1,
    site: null,
    user: null,
    sitesList: []
  };
  checkedChanelId = {
    id: null,
    name: ''
  };

  playlists;
  playlistFields = {
    id: null,
    name: '',
    status: 1,
    chanel: null,
    site: null,
    description: '',
    user: null,
    sitesList: [],
    channelsList: []
  };
  playlistPopupOpened = false;
  playlistStatusText = ['LIVE', 'EDITING', 'SUSPENDED', 'DISTRIBUTED'];
  playlistSuspendingText = ['Suspend', 'Unsuspend', 'Unsuspend', 'Unsuspend'];

  searchStr = '';
  searchedPlaylists: any = [];
  searchPopupOpened = false;

  selectedFoundation = null;
  profileStatementFields = {
    id: null,
    name: '',
    visible: 1
  }

  popups = {
    restoredPlaylistPopupOpened: false,
    restoredDatesPopupOpened: false,
    restoredChanelsPopupOpened: false,
    restoredTracksPopupOpened: false,
    schedulePopupOpened: false,
    recurrencePopupOpened: false,
    masteringPopupOpened: false,
    createSchedulePopupOpened: false,
    profileStatementOpened: false,
    profileHistoryopened: false,
    profileCreatePopupOpened: false
  };
  playerInfo = {
    isPlaying: 'stop',
    trackIndex: null,
    trackId: null,
    filename: null,
    album: '',
    artist: '',
    storage: '',
    tracks: [],
    token: '',
    playlistID: null
  };
  @ViewChild(PlayerComponentComponent) player: PlayerComponentComponent;
  @ViewChild(AlertComponent) alert: AlertComponent;
  @ViewChild(ConfirmComponent) confirm: ConfirmComponent;
  restoredPlaylistTracks: any = [];
  historyDates: any = [];
  restoredChanels: any = [];
  mysqlRestoredDate = null;
  isShowActions = {
    number: null,
    id: null
  };
  tabs = {
    selected: 1
  };
  timeArr = [];
  finishTimeArr = [];
  time = {
    h: 0,
    m: 0
  };
  schedule = {
    userId: null,
    sitesList: [],
    siteId: null,
    channelsList: [],
    channelId: null,
    playlistList: [],
    playlistId: null,
    name: '',
    begin_date: '',
    finish_date: '',
    begin_time: '00:00',
    finish_time: '00:00',
    bgColor: '#FFFFFF',
    txtColor: '#FF0000',
    recurrence_fl: '0',
    random: '0',
    mastering_fl: '0',
    clash_distance: '0',
    duration: '0',
    duration_unit: 'mins',
    artistsNumber: 0,
    recurrence: {
      radio_value: '1',
      daily: {
        days: 1
      },
      weekly: {
        week: 1,
        days: []
      },
      monthly: {
        month: 1,
        monthSelection: '1',
        days: [],
        number: 1,
        type: 'SU'
      },
      yearly: {
        year: 1,
        monthes: [],
        monthSelection: false,
        number: 1,
        type: 'SU'
      }
    },
    mastering: {
      m_switch: '0',
      days: 1,
      months: 1,
      months_2: 1,
      every_week_day: 1,
      recurrence_dow: "MO"
    }
  };
  editSchedule = {
    id: null,
    name: '',
    beginDate: null,
    endDate: null,
    beginTime: null,
    endTime: null
  };
  recurrence = {
    radio_value: '1',
    daily: {
      days: 1
    },
    weekly: {
      week: 1,
      days: []
    },
    monthly: {
      month: 1,
      monthSelection: '1',
      days: [],
      number: 1,
      type: 'SU'
    },
    yearly: {
      year: 1,
      monthes: [],
      monthSelection: false,
      number: 1,
      type: 'SU'
    }
  };
  mastering = {
    m_switch: '0',
    days: 1,
    months: 1,
    months_2: 1,
    every_week_day: 1,
    recurrence_dow: "MO"
  };
  scheduleValidation = {
    userId: true,
    siteId: true,
    channelId: true,
    playlistId: true,
    name: true,
    begin_date: true,
    finish_date: true,
    begin_time: true,
    finish_time: true,
    bgColor: true,
    txtColor: true,
    recurrence_fl: true,
    random: true,
    mastering: true,
    clash_distance: true,
    duration: true
  };
  daysNamesShort = ["SU", "MO", "TU", "WE", "TH", "FR", "SA"];
  schedulesList: any = [];
  public myDatePickerOptions: IMyDpOptions = {
    dateFormat: 'dd/mm/yyyy',
    editableDateField: false,
    inline: false,
    height: '20px'
  };

  confirmValues = {
    id: null,
    type: 0
  };
  isFocused = false;
  colorPalette = [
    ['#ffffff', '#005555'], ['#ffffff', '#1460aa'], ['#ffffff', '#d25852'], ['#ffffff', '#2e343b'], ['#ffffff', '#5a440d'], ['#ffffff', '#d43900'], ['#ffffff', '#d50000'],
    ['#ff0000', '#baf73c'], ['#ff0000', '#0000b5'], ['#ff0000', '#39134c'], ['#ff0000', '#e8e8e8'], ['#ff0000', '#c9f227'], ['#ff0000', '#551700'], ['#ff0000', '#320a0a'],
    ['#00ff00', '#008000'], ['#00ff00', '#34495e'], ['#00ff00', '#db0a5b'], ['#00ff00', '#050709'], ['#00ff00', '#483c0c'], ['#00ff00', '#80503d'], ['#00ff00', '#cf000f'],
    ['#0000ff', '#3cf73c'], ['#0000ff', '#81cfe0'], ['#0000ff', '#fc6399'], ['#0000ff', '#bdc3c7'], ['#0000ff', '#aaa789'], ['#0000ff', '#f89406'], ['#0000ff', '#e7903c'],
    ['#000000', '#4ecdc4'], ['#000000', '#59abe3'], ['#000000', '#e000e0'], ['#000000', '#696969'], ['#000000', '#f27927'], ['#000000', '#eb974e'], ['#000000', '#e00000'],
    ['#ff00ff', '#152a23'], ['#ff00ff', '#004055'], ['#ff00ff', '#360036'], ['#ff00ff', '#fefefe'], ['#ff00ff', '#1d1905'], ['#ff00ff', '#552f00'], ['#ff00ff', '#5d1212'],
    ['#ffff00', '#007a4b'], ['#ffff00', '#527ac2'], ['#ffff00', '#9400d3'], ['#ffff00', '#1c2833'], ['#ffff00', '#b8860b'], ['#ffff00', '#aa2e00'], ['#ffff00', '#d24d57'],
    ['#00ffff', '#315131'], ['#0000ff', '#3455db'], ['#0000ff', '#886288'], ['#0000ff', '#4f5a65'], ['#0000ff', '#483c0c'], ['#0000ff', '#66380f'], ['#0000ff', '#803224']
  ];

  document: any;
  path: any
  documentName: any;
  temp: any;
  storegePath: string = 'storage/document/';
  originalpath: any;
  file: any;
  channel_id: any;
  documentList: any;
  download: any;

  public viewDoc:boolean = false;
  public docUrl = '';
  public loaded:boolean = false;

  constructor(
    private usersService: UserService,
    private commonService: CommonService,
    private chanelsService: CPPlaylistCategoriesService,
    private playlistServ: CPPlaylistsService,
    private router: Router,
    private route: ActivatedRoute,
    private tracksService: TracksService,
    private scheduleService: ScheduleService,
    private globals: Globals,
    private http: HttpClient,
    private consultantService: ConsultantService,
    private profile_statementService: ProfileStatementService,
    private fp_categoriesService: PlaylistCategoriesService,
    private fpService: PlaylistsService,

  ) {
    this.route.queryParams.subscribe(params=>{
      if(params['docID']){
        this.viewDoc = true;
        this.playlistServ.getClientDocumentView(params['docID']).subscribe( (view:any) => {
          this.loaded = true;
          let host = location.host;
          let protocol = 'https://';
          if (location.protocol != 'https:'){
            protocol = 'http://'
          }
          this.docUrl = protocol + host + '/' + this.storegePath + view.path + view.document_name;
          console.log('docUrl',this.docUrl);
        });  
      }
    });
  }


  ngOnInit() {
    this.commonService.getCurrentLoggedUser().subscribe(current_user => {
      this.current_user = current_user;
      this.updateClientsList();
      this.setCheckedElements();
      this.setTime();
      this.setRecurrenceDaysArr();
      this.setRecurrenceMonthlyDaysArr();
      this.setRecurrenceMonthesArr();
    });
  }
  ngOnDestroy() {
    if (this.playerInfo.isPlaying == 'play') {
      this.player.pause();
    }
  }
  updateClientsList() {
    this.chanelsService.getClientsList().subscribe(clients => {
      this.usersList = clients;
    });
  }
  setCheckedElements() {
    this.route.queryParams.subscribe(params => {
      if (params['uid'] != '' && params['uid'] != null) {
        this.checkedUserId.id = params['uid'];
        this.getSites(this.checkedUserId.id, '', 0);
      }
      else {
        this.checkedUserId.id = null;
      }
      if (params['pcat'] != '' && params['pcat'] != null) {
        this.checkedSiteId.id = params['pcat'];
        this.showChanels('', this.checkedSiteId.id, '', 0);
      }
      else {
        this.checkedSiteId.id = null;
        this.sitesList = null;
      }
      if (params['cat'] != '' && params['cat'] != null) {
        this.checkedChanelId.id = params['cat'];
        this.showPlaylists('', this.checkedChanelId.id, '', 0);
      }
      else {
        this.checkedChanelId.id = null;
        this.playlists = null;
      }
      if (params['tab'] == '2') {
        this.tabs.selected = 2;
      }
    });
  }
  resetCheckedElements(num) {
    if (num < 4) {
      this.checkedChanelId.id = null;
    }
    if (num < 3) {
      this.checkedSiteId.id = null;
    }
    if (num < 2) {
      this.checkedUserId.id = null;
    }
    return false;
  }
  openUserPopup(e, uid) {
    this.popupClose(e);
    if (uid != null) {
      this.chanelsService.getClientById(uid).subscribe((userInfo: any) => {
        this.userFields.id = userInfo.id;
        this.userFields.name = userInfo.first_name;
        this.userFields.address = userInfo.address;
        this.userFields.address_2 = userInfo.address_2;
        this.userFields.address_3 = userInfo.address_3;
        this.userFields.address_4 = userInfo.address_4;
        this.userFields.city = userInfo.city;
        this.userFields.county = userInfo.county;
        this.userFields.postcode = userInfo.postcode;
        this.userFields.country = userInfo.country;
        this.userFields.type = userInfo.type;
        this.userFields.active = userInfo.active;
      });
    }
    else {
      this.userFields.id = null;
    }
    this.userPopupOpened = !this.userPopupOpened;
    this.focusInput();
    return false;
  }
  
  openFileUploadPopup(cid) {
    this.fileUploadPopupOpened = !this.fileUploadPopupOpened;
    this.channel_id = cid;
    this.playlistServ.getClientDocuments(this.channel_id).subscribe(documents => {
      this.documentList = documents;
    });
  }

  Passchannelid() {
    return this.channel_id;
  }

  DownloadPDF(e, id) {
    this.playlistServ.getClientDocumentView(id).subscribe(view => {
      this.temp = view;
      this.originalpath = this.storegePath + this.temp.path + this.temp.document_name;
      let headers = new HttpHeaders();
      if ('pdf' == this.temp.document_type) {
        // if pdf 
        headers = headers.set('Accept', 'application/pdf');
        return this.http.get(environment.dev_url_prefix + '/' + this.originalpath, { headers: headers, responseType: 'blob' }).subscribe(response => {
          const blob = new Blob([response], { type: 'application/pdf' });
          const url = window.URL.createObjectURL(blob);
          window.open(url);

        });
      }
      // docx
      if ('docx' == this.temp.document_type) {
        headers = headers.set('Accept', 'application/msword');
        return this.http.get(environment.dev_url_prefix + '/' + this.originalpath, { headers: headers, responseType: 'blob' }).subscribe(response => {
          const blob = new Blob([response], { type: 'application/msword' });
          const url = window.URL.createObjectURL(blob);
          window.open(url);
        });
      }
      // csv
      if ('csv' == this.temp.document_type) {
        headers = headers.set('Accept', 'text/csv');
        return this.http.get(environment.dev_url_prefix + '/' + this.originalpath, { headers: headers, responseType: 'blob' }).subscribe(response => {
          const blob = new Blob([response], { type: 'text/csv' });
          const url = window.URL.createObjectURL(blob);
          var anchor = document.createElement("a");
          anchor.download = this.temp.document_name;
          anchor.href = url;
          anchor.click();
        });

      }
      // xls
      if ('bin' == this.temp.document_type) {
        headers = headers.set('Accept', 'application/vnd.ms-excel');
        return this.http.get(environment.dev_url_prefix + '/' + this.originalpath, { headers: headers, responseType: 'blob' }).subscribe(response => {
          const blob = new Blob([response], { type: 'application/vnd.ms-excel' });
          const url = window.URL.createObjectURL(blob);
          var anchor = document.createElement("a");
          anchor.download = this.temp.document_name;
          anchor.href = url;
          anchor.click();
        });

      }


    });
    return false;

  }

  deleteDocument(sid) {
    this.playlistServ.deleteDocument(sid).subscribe((sch: any) => {
      if (sch.error != undefined) {
        this.alert.alertOpen(sch.error, 1);
      }
      else {
        this.alert.alertOpen("Docuement removed", 0);        
        this.showChanels('', this.site_id, name, 2);
      }
    });
    return false;
  }


  // profile Pop-up Edit 
  openProfileEditPopup(e) {
    this.popupClose(e);
    this.focusInput();
    this.profileName = [];
    this.consultantService.getProfilePlaylistName(this.playlistInfoId).subscribe((profile: any) => {
      this.profileName = profile.profile_name;
    });
    this.popups.profileCreatePopupOpened = true;
    return false;
  }
  profileUpdate(e, form: NgForm) {
    var str = Object.keys(form.value).map(function (key) {
      var val = encodeURIComponent(form.value[key]);
      if (val == 'null' || val == null) {
        val = '';
      }
      return encodeURIComponent(key) + '=' + val;
    }).join('&');
    if (this.profileStatementFields.name == '') {
      this.alert.alertOpen("Please enter profile Stament name", 1);
      return false;
    }
    if (this.playlistInfoId != null) {
      this.profile_statementService.createNewProfileStatement(this.playlistInfoId, str).subscribe((category: any) => {
        this.alert.alertOpen("Profile Statement Updated", 0);
      });
    }
    // updated list get 
    if (this.checkedChanelId.id != null) {
      this.consultantService.getProfileBriefMusic(this.checkedChanelId.id).subscribe(list => {
        this.playlists = list;
      });
    }
    // end
    this.popupClose(e);
  }

  popupClose(e) {
    this.userPopupOpened = false;
    this.fileUploadPopupOpened = false;
    this.PDFPopupOpened = false;
    this.sitePopupOpened = false;
    this.chanelPopupOpened = false;
    this.playlistPopupOpened = false;
    this.searchPopupOpened = false;
    this.popups.restoredPlaylistPopupOpened = false;
    this.popups.restoredDatesPopupOpened = false;
    this.popups.restoredChanelsPopupOpened = false;
    this.popups.restoredTracksPopupOpened = false;
    this.popups.schedulePopupOpened = false;
    this.popups.recurrencePopupOpened = false;
    this.popups.masteringPopupOpened = false;
    this.popups.createSchedulePopupOpened = false;
    this.popups.profileStatementOpened = false;
    this.popups.profileHistoryopened = false;
    this.popups.profileCreatePopupOpened = false;
    this.blurInput();
    return false;
  }
  formUserUpdate(e, form: NgForm) {
    e.preventDefault();
    
    var str = Object.keys(form.value).map(function (key) {
      var val = encodeURIComponent(form.value[key]);
      if (val == 'null' || val == null) {
        val = '';
      }
      return encodeURIComponent(key) + '=' + val;
    }).join('&');
    if (this.userFields.name != '') {
      if (this.userFields.id == null) {
        this.chanelsService.createNewClient(str).subscribe((client: any) => {
          if (client.error != undefined) {
            this.alert.alertOpen(client.error, 1)
          }
          else {
            this.updateClientsList();
            this.alert.alertOpen("New customer created", 0);
            this.popupClose(e);
          }
        });
      }
      else {
        this.chanelsService.editClient(this.userFields.id, str).subscribe(client => {
          console.log(client);
          this.updateClientsList();
          this.alert.alertOpen("Client Info Updated", 0);
          this.popupClose(e);
        });
      }
    }
    else {
      this.alert.alertOpen("Please fill First name, Last name and Email fields", 1);
    }
  }
  deleteUser(uid) {
    this.chanelsService.deleteClient(uid).subscribe(user => {
      this.updateClientsList();
      this.checkedUserId.id = null;
      this.checkedSiteId.id = null;
      this.checkedChanelId.id = null;
      this.alert.alertOpen("User deleted!", 0);
    });
  }
  getSites(uid, name, num) {
    this.checkedSiteId.id = null;
    this.checkedChanelId.id = null;
    this.isShowActions.number = 1;
    this.clearChanelsData();
    this.clearPlaylistsData();
    if (num == 1 && this.checkedUserId.id == uid) {
      this.checkedUserId.id = null;
      this.checkedUserId.name = '';
    }
    else {
      this.checkedUserId.id = uid;
      this.checkedUserId.name = name;
      this.chanelsService.getSitesByUser(uid).subscribe(sites => {
        this.sitesList = sites;
      });
    }
    this.tabs.selected = null;
  }
  openSitePopup(e, sid) {
    this.popupClose(e);
    this.siteFields.id = sid;
    if (sid == null) {
      
      if (this.checkedUserId.id != null)
        this.siteFields.user = this.checkedUserId.id;
      else
        this.siteFields.user = null;

    }
    else {
      this.chanelsService.getPlaylistsCategories(sid).subscribe((site: any) => {
        this.siteFields.name = site.name;
        this.siteFields.user = site.user_id;
        this.siteFields.address = site.address_1;
        this.siteFields.address_2 = site.address_2;
        this.siteFields.address_3 = site.address_3;
        this.siteFields.address_4 = site.address_4;
        this.siteFields.city = site.city;
        this.siteFields.county = site.county;
        this.siteFields.postcode = site.postcode;
        this.siteFields.country = site.country;
      });
    }
    this.focusInput();
    this.sitePopupOpened = !this.sitePopupOpened;
    return false;
  }
  formSiteUpdate(e, form: NgForm) {
    var str = Object.keys(form.value).map(function (key) {
      var val = encodeURIComponent(form.value[key]);
      if (val == 'null' || val == null) {
        val = '';
      }
      return encodeURIComponent(key) + '=' + val;
    }).join('&');
    if (this.siteFields.user != null) {
      if (this.siteFields.id == null) {
        this.chanelsService.createCategory(str, '').subscribe(site => {
          console.log(site);
          this.getSites(this.checkedUserId.id, '', 0);
          this.alert.alertOpen("Site added", 0);
        });
      }
      else {
        this.chanelsService.updatePlaylistCategory(this.siteFields.id, str).subscribe(site => {
          this.getSites(this.checkedUserId.id, '', 0);
          this.alert.alertOpen("Site updated", 0);
        });
      }
      this.popupClose(e);
    }
    else {
      this.alert.alertOpen("Please select user", 1);
    }
  }
  deleteSite(id, num) {
    this.chanelsService.deleteCategory(id).subscribe(siteDel => {
      this.getSites(this.checkedUserId.id, '', 0);
      if (num == 1)
        this.alert.alertOpen("Site deleted", 0);
      else if (num == 2)
        this.alert.alertOpen("Channel deleted", 0);
    });
  }
  site_id: any;
  showChanels(e, sid, name, num) {
    this.site_id = sid;
    this.isShowActions.number = 2;
    this.checkedChanelId.id = null;
    this.clearPlaylistsData();


    if (num == 1 && this.checkedSiteId.id == sid) {
      this.checkedSiteId.id = null;
      this.checkedSiteId.name = '';
    }
    else {
      this.checkedSiteId.id = sid;
      this.checkedSiteId.name = name;
    }
    this.playlistServ.getClientDocuments(sid).subscribe(documents => {
      this.documentList = documents;
    });
    this.tabs.selected = 2;


  }
  openChanelsPopup(e, cid) {
    this.popupClose(e);
    this.chanelFields.id = cid;
    if (this.chanelFields.id == null) {
      
      if (this.checkedUserId.id != null) {
        this.chanelFields.user = this.checkedUserId.id;
        this.updateSites(this.chanelFields.user, 1);
      }
      else {
        this.chanelFields.user = null;
        this.chanelFields.sitesList = [];
      }
      if (this.checkedSiteId.id != null)
        this.chanelFields.site = this.checkedSiteId.id;
      else
        this.chanelFields.site = null;

    }
    else {
      this.chanelsService.getPlaylistsCategories(this.chanelFields.id).subscribe((chanel: any) => {
        this.chanelFields.name = chanel.name;
        this.chanelFields.site = chanel.parent_id;
        this.chanelFields.user = chanel.user_id;
        this.updateSites(this.chanelFields.user, 1);
      })
    }
    this.focusInput();
    this.chanelPopupOpened = !this.chanelPopupOpened;
    return false;
  }
  userChange(e, num) {
    if (e.target.value == null) {
      if (num == 1) {
        this.chanelFields.sitesList = [];
      }
      else if (num == 2) {
        this.playlistFields.sitesList = [];
      }
      else if (num == 3) {
        this.schedule.sitesList = [];
      }
    }
    else {
      this.updateSites(e.target.value, num);
    }
  }
  updateSites(uid, num) {
    this.chanelsService.getSitesByUser(uid).subscribe((sites: any) => {
      if (num == 1) {
        this.chanelFields.sitesList = sites;
      }
      else if (num == 2) {
        this.playlistFields.sitesList = sites;
      }
      else if (num == 3) {
        this.schedule.sitesList = sites;
      }
    });
  }
  formChanelUpdate(e, form: NgForm) {
    var parentID = form.value.parent_id;
    if (this.chanelFields.id == null) {
      delete form.value.parent_id;
    }
    var str = Object.keys(form.value).map(function (key) {
      var val = encodeURIComponent(form.value[key]);
      if (val == 'null' || val == null) {
        val = '';
      }
      return encodeURIComponent(key) + '=' + val;
    }).join('&');
    if (this.chanelFields.user != null && this.chanelFields.site != null) {
      if (this.chanelFields.id == null) {
        this.chanelsService.createCategory(str, parentID).subscribe(chanel => {
          this.getSites(this.checkedUserId.id, '', 0);
          this.alert.alertOpen("Channel added", 0);
        });
      }
      else {
        this.chanelsService.updatePlaylistCategory(this.chanelFields.id, str).subscribe(chanel => {
          this.getSites(this.checkedUserId.id, '', 0);
          this.alert.alertOpen("Channel updated", 0);
        });
      }
      this.popupClose(e);
    }
    else {
      this.alert.alertOpen("Please select user and site", 1);
    }
  }
  showPlaylists(e, cid, name, num) {
    this.isShowActions.number = 3;
    if (num == 1 && this.checkedChanelId.id == cid) {
      this.checkedChanelId.id = null;
      this.checkedChanelId.name = '';
      this.playlists = [];
      this.schedulesList = [];
    }
    else {
      this.checkedChanelId.id = cid;
      this.checkedChanelId.name = name;
      this.updatePlaylists(0);
     
    }
    this.tabs.selected = 1;
  }
  openPlaylistPopup(e, pid) {
    this.popupClose(e);
    this.playlistFields.id = pid;
    this.searchPopupOpened = false;
    if (this.playlistFields.id == null) {
      
      if (this.checkedUserId.id != null) {
        this.playlistFields.user = this.checkedUserId.id;
        this.updateSites(this.playlistFields.user, 2);
      }
      else {
        this.playlistFields.user = null;
        this.playlistFields.sitesList = [];
      }
      if (this.checkedSiteId.id != null) {
        this.playlistFields.site = this.checkedSiteId.id;
        this.updateChanels(this.playlistFields.site, 1);
      }
      else {
        this.playlistFields.site = null;
        this.playlistFields.channelsList = [];
      }
      if (this.checkedChanelId.id != null)
        this.playlistFields.chanel = this.checkedChanelId.id;
      else
        this.playlistFields.chanel = null;
      
    }
    else {
      this.playlistServ.getPlaylistById(pid).subscribe((playlist: any) => {
        this.playlistFields.id = playlist.id;
        this.playlistFields.chanel = playlist.cp_category_id;
        
        this.playlistFields.user = playlist.client_id;
        this.updateSites(playlist.client_id, 2);
        this.chanelsService.getPlaylistsCategories(this.playlistFields.chanel).subscribe((chanel: any) => {
          this.playlistFields.site = chanel.parent_id;
          this.updateChanels(this.playlistFields.site, 1);
        });
        this.playlistFields.name = playlist.name;
        this.playlistFields.description = playlist.description;
      });
    }
    this.focusInput();
    this.playlistPopupOpened = !this.playlistPopupOpened;
    return false;
  }
  categoryChange(e, num) {
    if (e.target.value == null || e.target.value == 'null') {
      if (num == 1) {
        this.playlistFields.channelsList = [];
        this.playlistFields.chanel = null;
      }
      else {
        this.schedule.channelsList = [];
        this.schedule.channelId = null;
      }
    }
    else {
      this.updateChanels(e.target.value, num);
    }
  }
  updateChanels(sid, num) {
    this.chanelsService.getPlaylistsCategories(sid).subscribe((chanels: any) => {
      if (num == 1) {
        this.playlistFields.channelsList = chanels.children;
      }
      else {
        this.schedule.channelsList = chanels.children;
      }
    });
  }
  playlistUpdate(e, form: NgForm) {
    if (form.value.chanel_id != null) {
      this.playlistFields.chanel = form.value.chanel_id;
      delete form.value.site_id;
      delete form.value.chanel_id;
    }
    else {
      this.playlistFields.chanel = null;
      this.alert.alertOpen("Please select site and channel", 1);
      return false;
    }

    var str = Object.keys(form.value).map(function (key) {
      var val = encodeURIComponent(form.value[key]);
      if (val == 'null' || val == null) {
        val = '';
      }
      return encodeURIComponent(key) + '=' + val;
    }).join('&');
    if (this.playlistFields.chanel != null && this.playlistFields.user != null) {
      if (this.playlistFields.id == null) {
        this.playlistServ.createPlaylist(this.playlistFields.chanel, str).subscribe((playlists: any) => {
          console.log(playlists);
          if (playlists.error == undefined) {
            this.alert.alertOpen("Playlist added", 0);
            this.updatePlaylists(0);
          }
          else {
            this.alert.alertOpen(playlists.error, 1);
          }
        });
      }
      else {
        this.playlistServ.updatePlaylist(this.playlistFields.chanel, this.playlistFields.id, str).subscribe((playlists: any) => {
          if (playlists.error == undefined) {
            this.alert.alertOpen("Playlist updated", 0);
            this.updatePlaylists(0);
          }
          else {
            this.alert.alertOpen(playlists.error, 1);
          }
        });
      }
    }
    else {
      this.alert.alertOpen("Please select user and channel", 1);
    }

  }
  channelChange($event, num) {
    this.updatePlaylists(num);
  }
  updatePlaylists(num) {
    if (num == 1) {
      if (this.schedule.channelId != null) {
        this.playlistServ.getPlaylists(this.schedule.channelId).subscribe((playlists: any) => {
          this.schedule.playlistList = playlists;
        });
      }
    }
    else {
      if (this.checkedChanelId.id != null) {
        this.consultantService.getProfileBriefMusic(this.checkedChanelId.id).subscribe(list => {
          this.playlists = list;
        });
      }
    }
  }

  setStatus(e, pid, sid) {
    e.preventDefault();
    this.playlistServ.setStatus(pid, sid).subscribe(status => {
      if (this.searchPopupOpened) {
        this.search(e);
      }
      this.updatePlaylists(0);
    });
  }
  clearPlaylistsData() {
    this.playlists = null;
  }
  clearChanelsData() {
    this.checkedChanelId.id = null;
    this.chanelsList = null;
  }
  manageClick(id) {
    let navigationExtras: NavigationExtras = {
      queryParams: {
        'playlistID': id,
        'pcat': 2,
        'uid': this.checkedUserId.id
      }
    };
    this.router.navigate(['manage-client-playlists'], navigationExtras);
  }
  manageFPClick(id) {
    let navigationExtras: NavigationExtras = {
      queryParams: {
        'playlistID': id
      }
    };
    this.router.navigate(['manage-playlists'], navigationExtras);
  }
  manageScheduleClick(id) {
    let navigationExtras: NavigationExtras = {
      queryParams: {
        'scheduleID': id,
        'cid': this.checkedChanelId.id,
        'uid': this.checkedUserId.id
      }
    };
    this.router.navigate(['manage-schedule'], navigationExtras);
  }
  deletePlaylist(id) {
    this.playlistServ.deletePlaylist(id).subscribe(playlistDel => {
      this.alert.alertOpen("Playlist deleted", 0);
      if (this.searchPopupOpened) {
        this.search(window.event);
      }
      this.updatePlaylists(0);
    });
  }
  search(e) {
    e.preventDefault();
    if (this.searchStr != '') {
      this.playlistServ.searchPlaylists(this.searchStr).subscribe(playlists => {
        if (playlists != null)
          this.searchedPlaylists = playlists;
        else
          this.searchedPlaylists = [];
        this.searchPopupOpened = true;
      });
    }
  }
  clearSearch(e) {
    e.preventDefault();
    this.searchStr = '';
  }
  startPlaylist(e, pid) {
    if (pid != null) {
      if (pid != this.playerInfo.playlistID) {
        this.playlistServ.getPlaylistTracks(pid, 'ASC', 'add_date').subscribe((tracks: any) => {
          this.playerInfo.tracks = tracks;
          this.playerInfo.playlistID = pid;
          this.playerInfo.trackIndex = 0;
          if (this.playerInfo.tracks.length > 0) {
            this.playerInfo.filename = this.playerInfo.tracks[this.playerInfo.trackIndex].file_name;
            this.playerInfo.trackId = this.playerInfo.tracks[this.playerInfo.trackIndex].id;
            this.playerInfo.album = this.playerInfo.tracks[this.playerInfo.trackIndex].album;
            this.playerInfo.artist = this.playerInfo.tracks[this.playerInfo.trackIndex].artist;
            this.playerInfo.storage = this.playerInfo.tracks[this.playerInfo.trackIndex].storage;
            this.playerInfo.token = this.globals.access_token;
            this.player.start(this.playerInfo.trackId, true);
          }
        });
      }
      else {
        if (this.playerInfo.isPlaying == 'play') {
          this.player.pause();
        }
        else {
          this.player.start(this.playerInfo.trackId, true);
        }
      }
    }
    return false;
  }
  checkPlaying(e) {
    this.playerInfo.isPlaying = e;
  }
  nextTrack(e) {
    if (this.playerInfo.isPlaying == 'play') {
      if (this.playerInfo.tracks.length > this.playerInfo.trackIndex + 1) {
        this.playerInfo.trackIndex = this.playerInfo.trackIndex + 1;
        this.playerInfo.trackId = this.playerInfo.tracks[this.playerInfo.trackIndex].id;
        this.playerInfo.filename = this.playerInfo.tracks[this.playerInfo.trackIndex].file_name;
        this.playerInfo.album = this.playerInfo.tracks[this.playerInfo.trackIndex].album;
        this.playerInfo.artist = this.playerInfo.tracks[this.playerInfo.trackIndex].artist;
        this.playerInfo.storage = this.playerInfo.tracks[this.playerInfo.trackIndex].storage;
        this.playerInfo.token = this.globals.access_token;
        this.player.start(this.playerInfo.trackId, true);
      }
    }
  }
  prevTrack(e) {
    if (this.playerInfo.isPlaying == 'play') {
      if (this.playerInfo.trackIndex > 0) {
        this.playerInfo.trackIndex = this.playerInfo.trackIndex - 1;
        this.playerInfo.trackId = this.playerInfo.tracks[this.playerInfo.trackIndex].id;
        this.playerInfo.filename = this.playerInfo.tracks[this.playerInfo.trackIndex].file_name;
        this.playerInfo.album = this.playerInfo.tracks[this.playerInfo.trackIndex].album;
        this.playerInfo.artist = this.playerInfo.tracks[this.playerInfo.trackIndex].artist;
        this.playerInfo.storage = this.playerInfo.tracks[this.playerInfo.trackIndex].storage;
        this.playerInfo.token = this.globals.access_token;
        this.player.start(this.playerInfo.trackId, true);
      }
    }
  }
  openPlaylistRestorePopup(e, pid, date) {
    this.mysqlRestoredDate = date;
    this.popupClose(e);
    this.playlistServ.getTracksWithDeleted(pid, date).subscribe((tracks: any) => {
      if (tracks.result == undefined && tracks.error == undefined) {
        this.restoredPlaylistTracks = tracks;
      }
      else if (tracks.result != undefined) {
        this.restoredPlaylistTracks = [];
        this.alert.alertOpen(tracks.result, 0);
      }
      else {
        this.restoredPlaylistTracks = [];
        this.alert.alertOpen(tracks.error, 1);
      }
      this.popups.restoredPlaylistPopupOpened = true;
    });
    return false;
  }
  openRestoredDatesPopup(e, cid) {
    this.popupClose(e);
    this.playlistServ.getModifyDates(cid).subscribe((dates: any) => {
      if (dates.result != 'There are no records for this category') {
        this.historyDates = dates;
        this.popups.restoredDatesPopupOpened = true;
      }
      else {
        this.alert.alertOpen('There are no records for this category', 0);
      }
    });
    return false;
  }
  openChanelsRestorePopup(e, cid, date) {
    this.popupClose(e);
    this.playlistServ.getModifyPlaylists(cid, date).subscribe((playlists: any) => {
      this.mysqlRestoredDate = date;
      if (playlists.result == undefined) {
        this.restoredChanels = playlists;
      }
      else {
        this.alert.alertOpen(playlists.result, 1);
      }
      this.popups.restoredChanelsPopupOpened = true;
    });
    return false;
  }
  openTrackRestorePopup(e, tid) {
    this.popupClose(e);
    this.tracksService.getModifyTracks(tid, this.mysqlRestoredDate).subscribe(tracks => {
      console.log(tracks);
    });
    this.popups.restoredTracksPopupOpened = true;
    return false;
  }
  showFoundation(pid) {
    if (this.selectedFoundation == pid) {
      this.selectedFoundation = null;
    }
    else {
      this.selectedFoundation = pid;
    }
  }
  showActions(id, num) {
    return false;
  }
  validatePassword(str) {
    if (str == '') {
      return true;
    }
    var spec = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
    if (str.length < 8 && str.length > 16) {
      return false;
    }
    if (!str.match(/[A-z]/) || !str.match(/[A-Z]/) || !str.match(/[0-9]/)) {
      return false;
    }
    if (!spec.test(str)) {
      return false;
    }
    return true;
  }
  validateEmail(email) {
    var pattern = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    return pattern.test(email);
  }
  keyPress(event: any) {
    const pattern = /[0-9\ ]/;
    let inputChar = String.fromCharCode(event.charCode);

    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }
  selectTab(tabId) {
    this.tabs.selected = tabId;
  }
  @HostListener('document:click', ['$event']) clickedOutside($event) {
    this.isShowActions.number = 4;
  }
  clickedInside(e) {
    e.preventDefault();
    e.stopPropagation();
  }
  setTime() {
    var isSet = true;
    var index = 0;

    while (isSet) {
      if (this.time.h < 10) {
        this.timeArr[index] = '0' + this.time.h.toString() + ':';
      }
      else {
        this.timeArr[index] = this.time.h.toString() + ':';
      }
      if (this.time.m == 0) {
        this.timeArr[index] += '00';
      }
      else {
        this.timeArr[index] += this.time.m;
      }
      if (this.time.m == 45) {
        this.time.h += 1;
        this.time.m = 0;
      }
      else {
        this.time.m += 15;
      }
      if (this.time.h == 24) {
        this.time.h = 0;
        isSet = false;
      }
      index += 1;
    }
    this.finishTimeArr = this.timeArr;
  }
  setRecurrenceDaysArr() {
    for (var i = 0; i < 7; i++) {
      this.schedule.recurrence.weekly.days[i] = false;
    }
  }
  setRecurrenceMonthlyDaysArr() {
    for (var i = 0; i < 31; i++) {
      var o = {
        num: null,
        value: null
      };
      o.num = i + 1;
      o.value = false;
      this.schedule.recurrence.monthly.days[i] = o;
    }
  }
  setRecurrenceMonthesArr() {
    for (var i = 0; i < 12; i++) {
      this.schedule.recurrence.yearly.monthes[i] = false;
    }
  }
  changeRecurrence() {
    if (this.schedule.recurrence_fl == '1') {
      this.recurrence = Object.assign({}, this.schedule.recurrence);
      this.popups.recurrencePopupOpened = true;
    }
  }
  changeMastering() {
    if (this.schedule.mastering_fl == '1') {
      this.mastering = Object.assign({}, this.schedule.mastering);
      this.popups.masteringPopupOpened = true;
    }
  }
  openSchedulePopup(e) {
    this.popupClose(e);
    this.focusInput();
    this.popups.schedulePopupOpened = !this.popups.schedulePopupOpened;
    var colorPos = this.getRandomColor(this.colorPalette);
    if (this.checkedUserId.id != null) {
      this.schedule.userId = this.checkedUserId.id;
      this.updateSites(this.schedule.userId, 3);
    }
    else {
      this.schedule.userId = null;
      this.schedule.sitesList = [];
    }
    if (this.checkedSiteId.id != null) {
      this.schedule.siteId = this.checkedSiteId.id;
      this.updateChanels(this.schedule.siteId, 2);
    }
    else {
      this.schedule.siteId = null;
      this.schedule.channelsList = [];
    }
    if (this.checkedChanelId.id != null) {
      this.schedule.channelId = this.checkedChanelId.id;
      this.updatePlaylists(1);
    }
    else {
      this.schedule.channelId = null;
      this.schedule.playlistList = [];
    }
    return false;
  }
  getRandomColor(colors) {
    var item = Math.floor(Math.random() * colors.length);
    var el = Math.floor(Math.random() * 2);
    this.schedule.bgColor = colors[item][el];
    if (el == 0)
      this.schedule.txtColor = colors[item][1];
    else
      this.schedule.txtColor = colors[item][0];
  }
  checkSchedule() {
    if (this.schedule.finish_date == undefined || this.schedule.finish_date == '' || this.schedule.finish_date == null) {
      this.openConfirmPopup(null, 5);
    }
    else {
      this.createSchedule();
    }
  }
  createSchedule() {
    if (this.scheduleValidate(this.schedule)) {
      var str = this.createScheduleStr(this.schedule);
      this.scheduleService.createSchedule(this.schedule.channelId, str).subscribe((sch: any) => {
        if (sch.error != undefined) {
          this.alert.alertOpen(sch.error, 1);
        }
        else {
          this.updateSchedules(this.checkedChanelId.id);
          this.alert.alertOpen("New schedule created", 0);
          this.popupClose(window.event);
        }
      });
    }
    else {
      this.alert.alertOpen("Please fill all required fields", 1);
    }
  }
  createScheduleStr(sch) {
    var str = 'name=' + sch.name + '&cp_playlist_id=' + sch.playlistId + '&begin_date=' + this.parseDate(sch.begin_date.formatted) + '&finish_date=' + this.parseDate(sch.finish_date.formatted) + '&begin_time=' + sch.begin_time + '&finish_time=' + sch.finish_time;
    if (sch.recurrence == '0') {
      str += '&recurrence_flag=' + sch.recurrence_fl;
      str += '&recurrence_every=1&recurrence_type=monthly';
    }
    else {
      str += '&recurrence_flag=' + sch.recurrence_fl;
      if (sch.recurrence.radio_value == '1') {
        str += '&recurrence_type=daily&recurrence_every=' + sch.recurrence.daily.days;
      }
      else if (sch.recurrence.radio_value == '2') {
        str += '&recurrence_type=weekly&recurrence_every=' + sch.recurrence.weekly.week;
        str += '&recurrence_dow=';
        var daySet = false;
        for (var i = 0; i < 7; i++) {
          if (sch.recurrence.weekly.days[i]) {
            if (daySet)
              str += ',';
            str += this.daysNamesShort[i];
            daySet = true;
          }
        }
      }
      else if (sch.recurrence.radio_value == '3') {
        str += '&recurrence_type=monthly&recurrence_every=' + sch.recurrence.monthly.month;
        if (sch.recurrence.monthly.monthSelection == '1') {
          str += '&recurrence_days=';
          var daySet = false;
          for (var i = 0; i < sch.recurrence.monthly.days.length; i++) {
            if (sch.recurrence.monthly.days[i].value) {
              if (daySet)
                str += ',';
              str += sch.recurrence.monthly.days[i].num;
              daySet = true;
            }
          }
        }
        else if (sch.recurrence.monthly.monthSelection == '2') {
          str += '&recurrence_additional_rule_1=' + sch.recurrence.monthly.number + '&recurrence_additional_rule_2=' + sch.recurrence.monthly.type;
        }
      }
      else if (sch.recurrence.radio_value == '4') {
        str += '&recurrence_type=yearly&recurrence_every=' + sch.recurrence.yearly.year;
        str += '&month_s=';
        var monthSet = false;
        for (var i = 0; i < sch.recurrence.yearly.monthes.length; i++) {
          if (sch.recurrence.yearly.monthes[i]) {
            if (monthSet)
              str += ',';
            str += i + 1;
            monthSet = true;
          }
        }
        if (sch.recurrence.yearly.monthSelection) {
          str += '&recurrence_additional_rule_1=' + sch.recurrence.yearly.number + '&recurrence_additional_rule_2=' + sch.recurrence.yearly.type;
        }
      }
    }
    str += '&mastering=' + sch.mastering_fl;
    if (sch.mastering_fl == '1') {
      str += '&mastering_switch=' + sch.mastering.m_switch;
      if (sch.mastering.m_switch == '0') {
        str += '&mastering_days=' + sch.mastering.days + '&mastering_months=' + sch.mastering.months;
      }
      else {
        str += '&mastering_every_week_day=' + sch.mastering.every_week_day + '&mastering_recurrence_dow=' + sch.mastering.recurrence_dow + '&mastering_months=' + sch.mastering.months_2;
      }
    }
    str += '&rnd_order=' + sch.random + '&clash_distance=' + sch.clash_distance + '&override_duration=' + sch.duration + '&override_duration_unit=' + sch.duration_unit + '&background_color=' + sch.bgColor + '&text_color=' + sch.txtColor;

    return str;
  }
  parseDate(date) {
    if (date == undefined) {
      return 'indefinite';
    }
    else {
      var dateArr = date.split('/');
      var str = dateArr[2] + '-' + dateArr[1] + '-' + dateArr[0];
      return str;
    }
  }
  recurrencePopupClose(num) {
    if (num == 0) {
      
      this.schedule.recurrence = this.recurrence;
    }
    this.popups.recurrencePopupOpened = false;
    return false;
  }
  masteringPopupClose(num) {
    if (num == 0) {
      this.schedule.mastering = this.mastering;
    }
    this.popups.masteringPopupOpened = false;
    return false;
  }
  updateSchedules(cid) {
    this.scheduleService.getSchedulesList(cid).subscribe((sch: any) => {
      console.log(sch);
      if (sch.result == undefined)
        this.schedulesList = sch;
    });
  }
  deleteSchedule(sid) {
    this.scheduleService.deleteSchedule(sid).subscribe((sch: any) => {
      if (sch.error != undefined) {
        this.alert.alertOpen(sch.error, 1);
      }
      else {
        this.alert.alertOpen("Schedule removed", 0);
        this.updateSchedules(this.checkedChanelId.id);
      }
    });
    return false;
  }
  scheduleValidate(sch) {
    var isTrue = true;
    if (!this.checkValidate(sch.name)) {
      isTrue = false;
      this.scheduleValidation.name = false;
    }
    else { this.scheduleValidation.name = true }
    if (!this.checkValidate(sch.userId)) {
      isTrue = false;
      this.scheduleValidation.userId = false;
    }
    else { this.scheduleValidation.userId = true; }
    if (!this.checkValidate(sch.siteId)) {
      isTrue = false;
      this.scheduleValidation.siteId = false;
    }
    else { this.scheduleValidation.siteId = true; }
    if (!this.checkValidate(sch.channelId)) {
      isTrue = false;
      this.scheduleValidation.channelId = false;
    }
    else { this.scheduleValidation.channelId = true; }
    if (!this.checkValidate(sch.playlistId)) {
      isTrue = false;
      this.scheduleValidation.playlistId = false;
    }
    else { this.scheduleValidation.playlistId = true; }
    if (!this.checkValidate(sch.begin_date)) {
      if (!this.checkValidate(sch.begin_date.formatted)) {
        isTrue = false;
        this.scheduleValidation.begin_date = false;
      }
      else { this.scheduleValidation.begin_date = true; }
    }
    
    if (!this.checkValidate(sch.begin_time)) {
      isTrue = false;
      this.scheduleValidation.begin_time = false;
    }
    else { this.scheduleValidation.begin_time = true; }
    if (!this.checkValidate(sch.finish_time)) {
      isTrue = false;
      this.scheduleValidation.finish_time = false;
    }
    else { this.scheduleValidation.finish_time = true; }
    if (!this.checkValidate(sch.clash_distance)) {
      isTrue = false;
      this.scheduleValidation.clash_distance = false;
    }
    else { this.scheduleValidation.clash_distance = true; }
    if (!this.checkValidate(sch.bgColor)) {
      isTrue = false;
      this.scheduleValidation.bgColor = false;
    }
    else { this.scheduleValidation.bgColor = true; }
    if (!this.checkValidate(sch.txtColor)) {
      isTrue = false;
      this.scheduleValidation.txtColor = false;
    }
    else { this.scheduleValidation.txtColor = true; }
    if (isTrue) {
      return true;
    }
    return false;
  }
  checkValidate(val) {
    if (val == '' || val == null || val == undefined) {
      return false
    }
    return true;
  }
  checkDuration() {
    if (parseInt(this.schedule.duration) != 0) {
      if (isNaN(parseInt(this.schedule.duration))) {
        this.alert.alertOpen("Please enter number", 1);
        this.schedule.duration = '0';
      } else {
        this.scheduleValidation.duration = false;
      }
    }
    else {
      this.scheduleValidation.duration = true;
    }
  }
  checkDistance() {
    var dist = parseInt(this.schedule.clash_distance);
    if (isNaN(dist)) {
      this.alert.alertOpen("Please enter number", 1);
      this.schedule.clash_distance = '0';
    }
    else {
      if (dist > this.schedule.artistsNumber) {
        this.alert.alertOpen("Total number of Artists present in playlist is only " + this.schedule.artistsNumber + ". Please give less than that count!", 1);
        this.scheduleValidation.clash_distance = false;
      }
      else {
        this.scheduleValidation.clash_distance = true;
      }
    }
  }
  changeFinishTime() {
    var index = this.getTimeIndex(this.schedule.begin_time);
    var fIndex = this.getTimeIndex(this.schedule.finish_time);
    if (fIndex < index) {
      this.schedule.finish_time = null;
    }
    this.finishTimeArr = [];
    if (index != -1) {
      for (var i = index, j = 0; i < this.timeArr.length; i++ , j++) {
        this.finishTimeArr[j] = this.timeArr[i];
      }
    }
  }
  getTimeIndex(time) {
    for (var i = 0; i < this.timeArr.length; i++) {
      if (this.timeArr[i] == time) {
        return i;
      }
    }
    return -1;
  }
  getArtistsNumber() {
    this.playlistServ.getArtistsNumber(this.schedule.playlistId).subscribe((num: any) => {
      this.schedule.artistsNumber = parseInt(num.artists);
    });
  }
  openEditSchedulePopup(sid, name) {
    this.editSchedule.id = sid;
    this.editSchedule.name = name;
    this.focusInput();
    this.popups.createSchedulePopupOpened = !this.popups.createSchedulePopupOpened;
    return false;
  }
  updateSchedule(e, form: NgForm) {
    if (this.editSchedule.name != '') {
      var str = 'name=' + this.editSchedule.name;
      this.scheduleService.updateScheduleName(this.editSchedule.id, str).subscribe((sch: any) => {
        console.log(sch);
        if (sch.error != undefined) {
          this.alert.alertOpen(sch.error, 1);
        }
        else {
          this.updateSchedules(this.checkedChanelId.id);
          this.alert.alertOpen("Schedule name updated", 0);
          this.popupClose(e);
        }
      });
    }
    else {
      this.alert.alertOpen("Please fill all required fields", 1);
    }
  }
  isConfirm(e) {
    if (e) {
      if (this.confirmValues.type == 0)
        this.deleteUser(this.confirmValues.id);
      else if (this.confirmValues.type == 1)
        this.deleteSite(this.confirmValues.id, 1);
      else if (this.confirmValues.type == 2)
        this.deleteSite(this.confirmValues.id, 2);
      else if (this.confirmValues.type == 3)
        this.deletePlaylist(this.confirmValues.id);
      else if (this.confirmValues.type == 4)
        this.deleteSchedule(this.confirmValues.id);
      else if (this.confirmValues.type == 5)
        this.createSchedule();
      else if (this.confirmValues.type == 6)
        this.deleteDocument(this.confirmValues.id);

    }
  }
  openConfirmPopup(id, t) {
    var text = "Do you want to delete this client?";
    if (t == 1)
      text = "Do you want delete this site?";
    if (t == 2)
      text = "Do you want delete this channel?";
    if (t == 3)
      text = "Do you want delete this playlist?";
    if (t == 4)
      text = "Do you want to delete schedule?";
    if (t == 5)
      text = "Would you like to set this schedule for a indefinite date?";
    if (t == 6)
      text = "Do you want to delete Document?";

    this.confirmValues.id = id;
    this.confirmValues.type = t;
    this.confirm.confirmOpen(text);
    return false;
  }
  focusInput() {
    this.isFocused = true;
  }
  blurInput() {
    this.isFocused = false;
  }

  //  profile stamenent 
  profileStatements = [{
    id: null,
    fp_id: null,
    cover: '',
    ratio: '',
    note: ''
  }];
  psSelects = [{
    catId: null,
    subcatId: null,
    fp_id: null,
    fp_arr: [],
    dropOpened: false,
    title: "Select Foundation playlist..."
  }];
  fpCategories = [];
  focusedInput = {
    index: null,
    num: null
  };
  playlistInfoId: any;
  profileName: any;
  playlistName: any;


  openProfilePopup(e, pid) {
    console.log(pid);
    this.playlistInfoId = pid;
    this.focusInput();
    this.profile_statementService.getPSList(pid).subscribe((ps: any) => {
      if (ps.length > 0) {
        this.profileStatements = [];
        this.psSelects = [];
      }
      //result is empty  Again push empty object
      if (ps.length == null) {
        this.focusInput();
        this.profileStatements = [];
        this.psSelects = [];
        var o = {
          id: null,
          fp_id: null,
          cover: '',
          ratio: '',
          note: '',
          sCat: null,
          sSubcat: null
        };
        this.profileStatements.push(o);
        var so = {
          catId: null,
          subcatId: null,
          fp_id: null,
          fp_arr: [],
          dropOpened: false,
          title: "Select Foundation playlist..."
        };
        this.psSelects.push(so);
      }

      for (var i = 0; i < ps.length; i++) {
        var oo = {
          id: ps[i].id,
          fp_id: ps[i].foundation_play_list_id,
          cover: ps[i].cover,
          ratio: ps[i].ratio,
          note: ps[i].note
        };
        this.profileStatements.push(oo);
        var so = {
          catId: null,
          subcatId: null,
          fp_id: ps[i].foundation_play_list_id,
          fp_arr: [],
          dropOpened: false,
          title: ""
        };
        this.psSelects.push(so);
        this.setPSSelects(i);
      }
    });
    this.fp_categoriesService.getPlaylistsCategories('').subscribe((cats: any) => {
      this.fpCategories = cats;
      this.popups.profileStatementOpened = true;
    });

    // get profile statement and playlist
    this.consultantService.getProfilePlaylistName(pid).subscribe((profile: any) => {
      this.profileName = profile.profile_name;
      this.playlistName = profile.name;
    });

    return false;
  }

  // profile statement popup operations
  profileActiveStatus: any;
  profileDeleteStatus: any;
  profileModifiedStatus: any;
  openProfileHistoryPopup() {
    this.popupClose('');
    this.profile_statementService.getPSHistory(this.playlistInfoId).subscribe((history: any) => {
      this.profileActiveStatus = history.profileActive;
      this.profileDeleteStatus = history.profileDelete;
      this.profileModifiedStatus = history.profileModified;
      this.popups.profileHistoryopened = true;
    });
  }

  addProfile() {
    var o = {
      id: null,
      fp_id: null,
      cover: '',
      ratio: '',
      note: '',
      sCat: null,
      sSubcat: null
    };
    this.profileStatements.push(o);
    var so = {
      catId: null,
      subcatId: null,
      fp_id: null,
      fp_arr: [],
      dropOpened: false,
      title: "Select Foundation playlist..."
    };
    this.psSelects.push(so);
    return false;
  }
  removeProfile(index, pid, splice) {
    if (pid != null) {
      this.profile_statementService.deletePS(pid).subscribe((ps: any) => {
        if (ps.error != undefined) {
          this.alert.alertOpen(ps.error, 1);
        }
        else {
          if (splice) {
            this.splicePS(index);
          }
        }
      });
    }
    else {
      if (splice) {
        this.splicePS(index);
      }
    }
    return false;
  }
  splicePS(index) {
    this.profileStatements.splice(index, 1);
    this.psSelects.splice(index, 1);
  }
  sendCalc(index, save, param) {
    if (this.profileStatements[index].fp_id != null) {
      var ps = this.profileStatements[index];
      var str = 'cover=' + ps.cover;
      if (param == 2) {
        str = 'rating=' + ps.ratio;
      }
      this.profile_statementService.sendCalculating(ps.fp_id, this.playlistInfoId, str, false).subscribe((calc: any) => {
        if (calc.error != undefined) {
          this.alert.alertOpen(calc.error, 1);
        }
        else {
          if (calc.propoused_cover_seconds != undefined) {
            this.profileStatements[index].cover = calc.propoused_cover_seconds;
          }
          if (calc.propoused_rating_percents != undefined) {
            this.profileStatements[index].ratio = calc.propoused_rating_percents
          }
        }
      });
    }
  }
  checkFPDublicates(index) {
    this.profileStatements[index].cover = '';
    this.profileStatements[index].ratio = '';
    this.profileStatements[index].note = '';
    if (this.profileStatements[index].id != null) {
      this.removeProfile(index, this.profileStatements[index].id, false);
    }
    this.profileStatements[index].id = null;
    for (var i = 0; i < this.profileStatements.length; i++) {
      if (index != i && this.profileStatements[i].fp_id == this.profileStatements[index].fp_id) {
        this.alert.alertOpen("You are selecting a duplicate playlist", 1);
        this.profileStatements[index].fp_id = null;
        this.psSelects[index].fp_id = null;
        this.psSelects[index].title = 'Select Foundation playlist...';
        return false;
      }
    }
    return true;
  }
  saveProfileStatement() {
    
    var ps = this.profileStatements[this.focusedInput.index];
    var str = 'cover=' + ps.cover;
    if (this.focusedInput.num == 2) {
      str = 'rating=' + ps.ratio;
    }
    this.profile_statementService.sendCalculating(ps.fp_id, this.playlistInfoId, str, false).subscribe((calc: any) => {
      if (calc.error != undefined) {
        this.alert.alertOpen(calc.error, 1);
      }
      else {
        if (calc.propoused_cover_seconds != undefined) {
          this.profileStatements[this.focusedInput.index].cover = calc.propoused_cover_seconds;
        }
        if (calc.propoused_rating_percents != undefined) {
          this.profileStatements[this.focusedInput.index].ratio = calc.propoused_rating_percents
        }
        var ps_str = this.getPSString(this.profileStatements);
        this.profile_statementService.savePS(this.playlistInfoId, ps_str).subscribe((ps: any) => {
          if (ps.error != undefined) {
            this.alert.alertOpen(ps.error, 1);
          }
          else {
            this.alert.alertOpen("Profile statement saved", 0);

            this.popupClose('');
          }
        });
      }
    });
  }
  getTotalCover() {
    var t = 0;
    for (var i = 0; i < this.profileStatements.length; i++) {
      var n = parseInt(this.profileStatements[i].cover);
      if (typeof n == 'number')
        t += n;
    }
    return t;
  }
  getTotalRatio() {
    var t = 0;
    for (var i = 0; i < this.profileStatements.length; i++) {
      var n = parseInt(this.profileStatements[i].ratio);
      if (typeof n == 'number')
        t += n;
    }
    return t;
  }
  updateInputs(index, num) {
    this.focusedInput = {
      index: index,
      num: num
    };
  }
  getPSString(arr) {
    var str = '';
    for (var i = 0; i < arr.length; i++) {
      if (arr[i].fp_id != null) {
        if (i > 0) {
          str += '&'
        }
        str += 'fp_id[' + i + ']=' + arr[i].fp_id + '&cover[' + i + ']=' + arr[i].cover + '&rating[' + i + ']=' + arr[i].ratio + '&notes[' + i + ']=' + arr[i].note;
        if (arr[i].id != null) {
          str += '&id[' + i + ']=' + arr[i].id;
        }
        else {
          str += '&id[' + i + ']=undefined';
        }
      }
    }
    return str;
  }
  convertTime(time) {
    var str = '';
    if (time != 'NaN') {
      var t = new Date(time * 1000);
      var h = t.getUTCHours();
      var m = t.getUTCMinutes();
      var s = t.getUTCSeconds()

      if (h < 10) {
        str = '0' + h.toString();
      }
      else {
        str = h.toString();
      }
      if (m < 10) {
        str += ':0' + m.toString();
      }
      else {
        str += ':' + m.toString();
      }
      if (s < 10) {
        str += ':0' + s.toString();
      }
      else {
        str += ':' + s.toString();
      }
    }
    else {
      str = '00:00:00';
    }
    return str;
  }

  psSelectCategory(cid, index) {
    this.psSelects[index].catId = cid;
    this.psSelects[index].fp_arr = [];
  }
  psSelectSubcategory(scid, index) {
    this.psSelects[index].subcatId = scid;
    this.fpService.getPlaylists(scid).subscribe((playlists: any) => {
      this.psSelects[index].fp_arr = playlists;
    });
  }
  psSelectPlaylist(pid, index, title) {
    this.psSelects[index].fp_id = pid;
    this.profileStatements[index].fp_id = pid;
    this.psSelects[index].title = title;
    this.psSelects[index].dropOpened = false;
    this.checkFPDublicates(index);
  }
  psOpenDrop(index) {
    for (var i = 0; i < this.psSelects.length; i++) {
      if (index != i)
        this.psSelects[i].dropOpened = false;
    }
    this.psSelects[index].dropOpened = !this.psSelects[index].dropOpened;
  }
  setPSSelects(index) {
    this.fpService.getPlaylistById(this.psSelects[index].fp_id).subscribe((playlist: any) => {
      this.psSelects[index].title = playlist.name;
      this.psSelects[index].catId = playlist.parent_node.id;
      this.psSelects[index].subcatId = playlist.foundation_category_id;
      this.psSelectSubcategory(this.psSelects[index].subcatId, index);
    });
  }

  openNewWindow(e,id){
    this.viewDoc = true;
    e.preventDefault();
    this.playlistServ.getClientDocumentView(id).subscribe(view => {
      this.temp = view;
      this.originalpath = this.storegePath + this.temp.path + this.temp.document_name;
      let path = environment.dev_url_prefix + '/' + this.originalpath;
      this.docUrl = path;
      
    });  
  }

}
