<button (click)="openPopup()" title="Upload Tracks">Import foundation playlist</button>
<div class="popup " [ngClass]="{opened: popupOpened}" ngDraggable ngResizable [rzHandles]="'n,e,s,w,se,sw,ne,nw'" [handle]="dragHandle" *ngIf="page == 'upload'">
  <a href="#" class="popup_close" (click)="popupClose($event)"></a>
  <div class="popup_title" #dragHandle>Upload file</div>
  <div style="padding:20px;margin:20px">
  <select name="site_id" [(ngModel)]="selectedCategoryId" style="margin-right:20px" (change)="setCategoryID($event)">
    <option value="null" [selected]="selectedCategoryId==null">Select Category...</option>
    <option *ngFor="let category of categoriesList" value="{{category.id}}" [selected]="selectedCategoryId==category.id">{{category.name}}</option>
  </select>
  <select name="site_id" [(ngModel)]="selectedSubCategoryId" (change)="setSubCategoryID($event)">
    <option value="null" [selected]="selectedSubCategoryId==null">Select Sub Category...</option>
    <option *ngFor="let subCategory of subcategories" value="{{subCategory.id}}" [selected]="selectedSubCategoryId==subCategory.id">{{subCategory.name}}</option>
  </select>
  </div>
  <div class="popup_form" *ngIf="selectedSubCategoryId">
    <dropzone
      [config]="cconfig"
      [message]="'Click or drag files here to upload'"
      (error)="onUploadError($event)"
      (success)="onUploadSuccess($event)"
      (sending)="onSending($event)"
      >
    </dropzone>
    <div class="popup_btns">
      <button type="button" *ngIf="responseData.length > 0" (click)="showResult()" style="background:#366a36">Matching Result</button>
      <button type="button" class="hide" (click)="popupClose($event)">Cancel</button>

    </div>
  </div>
</div>
<app-alert></app-alert>
<app-confirm (isConfirm)="isConfirm($event)"></app-confirm>

<div class="popup " [ngClass]="{opened: popupOpened}" ngDraggable ngResizable [rzHandles]="'n,e,s,w,se,sw,ne,nw'" [handle]="dragHandle" *ngIf="page == 'result'">
  <a href="#" class="popup_close" (click)="popupClose($event)"></a>
  <div class="popup_title" #dragHandle>Import result</div>
    <div class="table_wrap search_table">
      <div class="table_titles">
        <div>File Name</div>
        <div>Matching result</div>
        <div>Delete action needed tracks (Curator)</div>
      </div>
      <div class="table_content">
        <div class="table_row" *ngFor="let profile of responseData">
          <div>{{profile.name}}.xml</div>
          <div>
            <button type="button" (click)="downloadFile(profile.trackInfo,profile.name)" *ngIf="profile.trackInfo && !profile?.error">Export</button>
            <p *ngIf="profile?.error">{{profile.error}}</p>
          </div>
          <div>
            <button type="button" (click)="downloadFileDelete(profile.deletedNeededModified,profile.name)" *ngIf="profile?.deletedNeededModified?.length > 0">Export</button>
            <button type="button" (click)="openConfirmPopup(profile?.deletedNeeded, 0)" *ngIf="profile?.deletedNeeded">Delete</button>

          </div>
        </div>
      </div>
    </div>

  <div class="popup_btns">
    <button type="button" (click)="goBack()">Back</button>
  </div>
</div>