<ul>
  <li *ngFor="let pc of playlistCats" (click)="onClick($event,pc,0)" [ngClass]="{opened: isActive(pc,0)}">
    <span>{{pc.name}}</span>
    <a href="#" class="playlist_categories_edit" (click)="editCategory($event,pc.id)"></a>
    <a href="#" class="playlist_categories_delete" (click)="removeCategory($event, pc.id)"></a>
    <ul *ngIf="pc.children !=0">
      <li *ngFor="let pcc of pc.children" (click)="onClick($event,pcc,1)" [ngClass]="{opened: isActive(pcc,1)}">
        <span>{{pcc.name}}</span>
        <a href="#" class="playlist_categories_edit" (click)="editSubcategory($event,pcc.id)"></a>
        <a href="#" class="playlist_categories_delete" (click)="removeCategory($event, pcc.id)"></a>
      </li>
    </ul>
  </li>
</ul>

