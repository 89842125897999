import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {map} from 'rxjs/operators';
import { environment } from '../../environments/environment';
import {Globals} from '../globals';

@Injectable()
export class ConsultantService{
    httpOptions = {
        headers: new HttpHeaders({
          'Host': environment.dev_url_prefix + '/',
          'Authorization': 'Bearer ' + this.globals.access_token,
          'Accept': 'application/json'
        })
      };
      postOptions={
        headers: new HttpHeaders({
          'Host': environment.dev_url_prefix + '/',
          'Authorization': 'Bearer ' + this.globals.access_token,
          'Accept': 'application/json',
          'Content-Type':'application/x-www-form-urlencoded'
        })
      }
      deleteOptions={
        headers: new HttpHeaders({
          'Host': environment.dev_url_prefix + '/',
          'Authorization': 'Bearer ' + this.globals.access_token,
          'Accept': 'application/json',
          'Content-Type':'application/x-www-form-urlencoded'
        })
      }
    
      constructor(
        private http: HttpClient,
        private globals: Globals
      ) { }


      getProfileBriefMusic(channel_id){
        return this.http.get(environment.dev_url_prefix + '/api/V1/consultant/get_profile_brief_tracks/' + channel_id, this.httpOptions).pipe(map(list => {
          return list;
        }));
      }
      getProfilePlaylistName(channel_id){
        return this.http.get(environment.dev_url_prefix + '/api/V1/consultant/get_profileName_playlistName/' + channel_id, this.httpOptions).pipe(map(profile => {
          return profile;
        }));
      }



}
