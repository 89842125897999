<div class="page_wrap">
  <app-page-header
    [logout]="true"
    [pageTitle]="'Create, edit and manage your playlists'"
  ></app-page-header>

    <form #loginForm="ngForm" class="login_form">
      <h2>Log in</h2>
      <input
        type="hidden"
        value="{{ loginData.client_id }}"
        name="client_id"
        [(ngModel)]="loginData.client_id"
      />
      <input
        type="hidden"
        value="{{ loginData.client_secret }}"
        name="client_secret"
        [(ngModel)]="loginData.client_secret"
      />
      <input
        type="hidden"
        value="{{ loginData.grant_type }}"
        name="grant_type"
        [(ngModel)]="loginData.grant_type"
      />
      <input
        type="hidden"
        value="{{ loginData.scope }}"
        name="scope"
        [(ngModel)]="loginData.scope"
      />
      <div class="input_wrap">
        <label>Username</label>
        <input
          type="email"
          value="{{ loginData.username }}"
          name="username"
          [(ngModel)]="loginData.username"
          [email]="true"
        />
      </div>
      <div class="input_wrap">
        <label>Password</label>
        <input
          type="password"
          name="password"
          value="{{ loginData.password }}"
          [(ngModel)]="loginData.password"
        />
      </div>
      <div class="popup_btns">
        <button type="submit" (click)="loginDataSend($event, loginForm)">
          Login
        </button>
        <a routerLink="/forgot-password">Forgot password?</a>
      </div>
      <a routerLink="/create-new-user" class="send_request"
        ><strong
          >Not registered yet? Please send your request to admin</strong
        ></a
      >
    </form>
  <app-alert></app-alert>
</div>
