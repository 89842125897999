import { HttpClient, HttpHeaders, HttpParams, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Globals } from '../globals';

@Injectable()
export class FpSampleService {

  httpOptions = {
    headers: new HttpHeaders({
      'Host': environment.dev_url_prefix + '/',
      'Authorization': 'Bearer ' + this.globals.access_token,
      'Accept': 'application/json'
    })
  };
  postOptions = {
    headers: new HttpHeaders({
      'Host': environment.dev_url_prefix + '/',
      'Authorization': 'Bearer ' + this.globals.access_token,
      'Accept': 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded'
    })
  }
  deleteOptions = {
    headers: new HttpHeaders({
      'Host': environment.dev_url_prefix + '/',
      'Authorization': 'Bearer ' + this.globals.access_token,
      'Accept': 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded'
    })
  }
  httpRequestHeaders = {
    headers: new HttpHeaders({
      'Host': environment.dev_url_prefix + '/',
      'Authorization': 'Bearer ' + this.globals.access_token,
      'Accept': 'application/json',
      'Content-Type': 'multipart/form-data'
    })
  }

  constructor(
    private http: HttpClient,
    private globals: Globals
  ) { }

  /**
  Create new category/sub category
  * @param formData - post data
  * @param parentID - category parent id
  * @returns {Observable<any>}
  */
  createCategory(formData, parentID) {
    var str = '/' + parentID;
    if (parentID == '') {
      str = '';
    }
    return this.http.post(environment.dev_url_prefix + '/api/V1/fp_sample/create' + str, formData, this.postOptions).pipe(map(playlistsCats => {
      return playlistsCats;
    }));
  }

  getClientsList() {
    return this.http.get(environment.dev_url_prefix + '/api/V1/fp_sample/getclientlist', this.httpOptions).pipe(map(data => {
      return data;
    }));
  }

  getChannelsByuser(uid) {
    return this.http.get(environment.dev_url_prefix + '/api/V1/fp_sample/getchannelslist/' + uid, this.httpOptions).pipe(map(data => {
      return data;
    }));
  }

  getCategoriesbyid(uid) {
    return this.http.get(environment.dev_url_prefix + '/api/V1/fp_sample/getcategorybyid/' + uid, this.httpOptions).pipe(map(data => {
      return data;
    }));
  }

  /**
   Create new Subcategory
   * @param pid - category
   * @param formData - post data
   * @returns {Observable<any>}
   */
  createSubCategory(formData) {
    return this.http.post(environment.dev_url_prefix + "/api/V1/fp_sample/createsubcategory", formData, this.postOptions).pipe(map(data => {
      return data;
    })
    );
  }

  /**
* Update subcategory 
* @param cid 
* @param formData 
*/

  updatesubCategory(cid: any, formData) {
    return this.http.post(environment.dev_url_prefix + "/api/V1/fp_sample/updatesubcategory/" + cid, formData, this.postOptions).pipe(map(data => {
      return data;
    })
    );
  }

  /**
   Create new playlist
   * @param pid - channel id
   * @param formData - post data
   * @returns {Observable<any>}
   */
  createPlaylist(formData) {
    return this.http.post(environment.dev_url_prefix + "/api/V1/fp_sample/createPlaylist", formData, this.postOptions).pipe(map(playlists => {
      return playlists;
    })
    );
  }

  /**
* Get all playlist 
* @param sid  channel id
* 
*/
  getPlaylists(sid: any) {
    return this.http.get(environment.dev_url_prefix + '/api/V1/fp_sample/getplaylist/' + sid, this.httpOptions).pipe(map(data => {
      return data;
    }));
  }

  /**
 * Get playlist by playlist id 
 * @param pid playlist id 
 */

  getPlaylistByid(pid) {
    return this.http.get(environment.dev_url_prefix + '/api/V1/fp_sample/getplaylistbyid/' + pid, this.httpOptions).pipe(map(data => {
      return data;
    }));
  }

  /**
* Update playlist 
* @param sid 
* @param pid 
* @param formData 
*/

  updatePlaylist(pid: any, formData) {
    return this.http.post(environment.dev_url_prefix + "/api/V1/fp_sample/update/" + pid, formData, this.postOptions).pipe(map(playlists => {
      return playlists;
    })
    );
  }

  /**
Delete playlist
* @param pid - playlist id
* @returns {Observable<any>}
*/
  deletePlaylist(pid) {
    return this.http.delete(environment.dev_url_prefix + '/api/V1/fp_sample/delete/' + pid, this.deleteOptions).pipe(map(playlistDelete => {
      return playlistDelete;
    }));
  }

  getArchivePlaylists(sid) {
    return this.http.get(environment.dev_url_prefix + '/api/V1/fp_sample/archiveplaylist/' + sid, this.httpOptions).pipe(map(data => {
      return data;
    }));
  }


  setStatus(pid, status) {
    return this.http.get(environment.dev_url_prefix + '/api/V1/fp_sample/setstatus/' + pid + '/' + status, this.httpOptions).pipe(map(data => {
      return data;
    }));
  }


  deleteCategory(pid) {
    return this.http.delete(environment.dev_url_prefix + '/api/V1/fp_sample/deletesubcat/' + pid, this.deleteOptions).pipe(map(subcat => {
      return subcat;
    }));
  }

  getPlaylistsCategories() {
    return this.http.get(environment.dev_url_prefix + '/api/V1/fp_sample/getplaylistcategory', this.httpOptions).pipe(map(data => {
      return data;
    }));
  }

  getPlaylistTracks(pid, order, orderBy) {
    return this.http.get(environment.dev_url_prefix + "/api/V1/fp_sample/files/" + pid + "/" + orderBy + "/" + order, this.httpOptions).pipe(map(tracks => {
      return tracks;
    }));
  }

  searchPlaylistsFP(str) {
    return this.http.get(environment.dev_url_prefix + "/api/V1/fp_sample/fps_category_search/" + str, this.httpOptions).pipe(map(category => {
      return category;
    }));
  }

     /**
   Search tracks in playlist
   * @param str - search string
   * @param pid - playlist id
   * @returns {Observable<any>}
   */
   search(str, pid) {return this.http.get(environment.dev_url_prefix +"/api/V1/fp_sample/track_search_sfp/" +str +"/" +pid +"/0/100",this.httpOptions).pipe(map(tracks => {
    return tracks;
  })
);
}

  /**
   Get playlist information for manager page with music files 
   * @param pid - playlist id
   * @returns {Observable<any>}
   */
   getmanagerPlaylistById(pid) {
    return this.http.get(environment.dev_url_prefix + '/api/V1/fp_sample/getmanagerPlaylistById/' + pid, this.httpOptions).pipe(map(data => {
      return data;
    }));
  }

  addTrackToPlaylist(pid, tid, fpid) {
    var str = '';
    if (fpid != null) {
      str = '/' + fpid;
    }
    return this.http.post(environment.dev_url_prefix + '/api/V1/fp_sample/file/add/' + pid + '/' + tid + str, '', this.postOptions).pipe(map(tracks => {
      return tracks;
    }));
  }

  addTracksToPlaylist(playlistId: string, trackIds: string[], fpId?: string) {
    return this.http.post(environment.dev_url_prefix + `/api/V1/fp_sample/files/add/${playlistId}`, 
      JSON.stringify({ file_ids: trackIds, fp_id: fpId }), this.postOptions).pipe(map(playlists => {
        return playlists;
    }));
  }

  retain(formData) {
    return this.http.post(environment.dev_url_prefix + '/api/V1/fp_sample/retain', JSON.stringify(formData), this.postOptions).pipe(map(playlists => {
      return playlists;
    }));
  }
  removeRetain(formData) {
    return this.http.post(environment.dev_url_prefix + '/api/V1/fp_sample/remove_retain', JSON.stringify(formData), this.postOptions).pipe(map(playlists => {
      return playlists;
    }));
  }

  updateExcluded(cpmf_id, formData, type) {
    let url = '/api/V1/fp_sample/updateCSpmfExcluded/';
    return this.http.put(environment.dev_url_prefix + url + cpmf_id, formData, this.postOptions).pipe(map(playlists => {
      return playlists;
    }));
  }

  deletePlaylistTrack(pid, tid) {
    return this.http.delete(environment.dev_url_prefix + '/api/V1/fp_sample/file/delete/' + pid + '/' + tid, this.deleteOptions).pipe(map(trackDelete => {
      return trackDelete;
    }));
  }

  copyplaylist(pid: any, formData) {
    return this.http.post(environment.dev_url_prefix + "/api/V1/fp_sample/copy/" + pid, formData, this.postOptions).pipe(map(playlists => {
      return playlists;
    })
    );
  }


}
