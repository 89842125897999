import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {Globals} from '../globals';

@Injectable()
export class SmartFilterService{
  httpOptions = {
    headers: new HttpHeaders({
      'Host': environment.dev_url_prefix + '/',
      'Authorization': 'Bearer ' + this.globals.access_token,
      'Accept': 'application/json'
    })
  };
  postOptions={
    headers: new HttpHeaders({
      'Host': environment.dev_url_prefix + '/',
      'Authorization': 'Bearer ' + this.globals.access_token,
      'Accept': 'application/json',
      'Content-Type':'application/x-www-form-urlencoded'
    })
  };
  deleteOptions={
    headers: new HttpHeaders({
      'Host': environment.dev_url_prefix + '/',
      'Authorization': 'Bearer ' + this.globals.access_token,
      'Accept': 'application/json',
      'Content-Type':'application/x-www-form-urlencoded'
    })
  }

  constructor(
    private http: HttpClient,
    private globals: Globals
  ){}

  /**
   Get default smart filters
   * @param type - playlist type '' or 'fp'
   * @returns {Observable<any>}
   */
  getFilters(type){
    var str = '';
    if(type=='fp'){
      str = '?type=fp';
    }
    return this.http.get(environment.dev_url_prefix + '/api/V1/tracks/get_smart_filters' + str, this.httpOptions).pipe(map(filters => {
      return filters;
    }));
  }

  /**
   Get smart filter results
   * @param formData - post data
   * @returns {Observable<any>}
   */
  getFilteredTracks(formData){
    return this.http.post(environment.dev_url_prefix + '/api/V1/tracks/smart_search', formData, this.postOptions).pipe(map(tracks => {
      return tracks;
    }));
  }

  getFilteredTracksCount(formData){
    return this.http.post(environment.dev_url_prefix + '/api/V1/tracks/smart_search_ps', formData, this.postOptions).pipe(map(tracks => {
      return tracks;
    }));
  }

}
