export class Arrays {
  /**
   Checking element in array
   * @param arr - array
   * @param el - element
   * @returns {boolean}
   */
  checkElementInArray(arr, el){
    for( var i = 0; i < arr.length; i++ ){
      if(arr[i] == el){
        return true;
      }
    }
    return false;
  }

  /**
   Get element position in array
   * @param arr - array
   * @param el - element
   * @returns {number}
   */
  getElementPositionInArray( arr, el ){
    for( var i = 0; i < arr.length; i++ ){
      if(arr[i] == el){
        return i;
      }
    }
    return -1;
  }
}
