<div class="alert" [ngClass]="{opened: opened}">
  <a href="#" class="popup_close" (click)="confirmClose()"></a>
  <div class="popup_title">Confirmation</div>
  <div class="alert_wrap">
    <p>{{message}}</p>
    <div class="popup_btns">
      <button type="button" class="cancel" (click)="confirm()">Ok</button>
      <button type="button" class="cancel" (click)="confirmClose()">Cancel</button>
    </div>
  </div>
</div>
<div class="fade" [ngClass]="{opened: opened}"></div>
