import {Component, Input, Output, OnInit, EventEmitter, ElementRef, HostListener} from '@angular/core';
import {Howl} from 'howler';
import {interval, Subscription} from 'rxjs';

@Component({
  selector: 'app-player-component',
  templateUrl: './player-component.component.html',
  styleUrls: ['./player-component.component.scss']
})
export class PlayerComponentComponent implements OnInit {
  @Input() track: any = null;//Track id
  @Input() artist = 'Artist';//Track artist
  @Input() album = 'Album';//Track album
  @Input() wave = null;//Path to wave
  @Input() storage = '';//Track storage
  @Input() token = '';//User access token
  @Input() isInputFocused = false;//Check input focus
  trackDublicate = null;//Dublicate track id
  player: Howl;//Player component
  @Output() isPlaying  = false;//Play track
  isPause = false;//Track pausing
  @Output() outputPlaying  = new EventEmitter<string>();//Playing event
  innerWidth = 0;//Width of wave
  public value: number;//Track current time
  public maxValue: number;//Track duration
  @Output() next = new EventEmitter<boolean>();//Next track event
  @Output() prev = new EventEmitter<boolean>();//Previous track event
  playingSec = 0;//Track playing in seconds
  duration = 0;//Track duration
  playerObj={
    artist: 'Artist',
    album: 'Album',
    wave: null,
    storage: ''
  };//Player information object
  isSpace = false;//Is key 'Space' pressed
  muted = false;

  private updateObservable: any;
  private updateSubscriber: Subscription;

  constructor(private elementRef: ElementRef) { }

  ngOnInit() {
    this.setupUpdateObservable();
  }

  /**
   Player actions
   * @returns {boolean}
   */
  startPlay(){
    var decodedStr=btoa('id='+this.track+'&token=Bearer '+this.token);
    if(this.track != null){
      if(this.isPlaying){
        this.isPlaying = false;
        this.outputPlaying.emit('pause');
        this.player.pause();
        
        if(this.isSpace&&this.trackDublicate != this.track){
          this.startPlay();
        }
      }
      else{
        if(this.trackDublicate != this.track){
          this.trackDublicate = this.track;
          if(this.player != undefined){
            this.player.stop();
            if (this.player.state() != 'unloaded') this.player.unload()
          }
          this.player = new Howl({
            // src: ['/' + this.storage + '/encoded/' + this.track],
            src: ['/tracks/stream/' + decodedStr],
            //src:[''],
            html5: true
          });
          
         
          this.player.on('play', () => this.onPlay());
          this.player.on('pause', () => this.onPause());
          this.player.on('stop', () => this.onStop());
          this.player.on('end', () => this.onEnd());
          this.playerObj = {
            artist: this.artist,
            album: this.album,
            wave: this.wave,
            storage: this.storage
          };
          this.isSpace = false;
        }
        
        this.player.play();
      }
    }
    return false;
  }

  /**
   Start play
   */
  onPlay(){
    this.isPause = false;
    this.duration = this.player.duration();
    this.subscribe();
    this.isPlaying = true;
    this.outputPlaying.emit('play');
    
  }

  /**
   Pause track
   */
  onPause(){
    
    this.isPause = true;
    this.unsubscribe();
  }

  /**
   Stop track
   */
  onStop(){
    
    this.isPause = false;
    this.innerWidth = 0;
    this.playingSec = 0;
    this.isPlaying = false;
    this.outputPlaying.emit('stop');
    this.unsubscribe();
  }

  /**
   End of track
   */
  onEnd(){
    this.isPause = false;
    this.innerWidth = 0;
    this.playingSec = 0;
    this.unsubscribe();
    this.nextTrack();
  }

  /**
   Next track event
   * @returns {boolean}
   */
  nextTrack(){
    this.next.emit(true);
    return false;
  }
  /**
   Previous track event
   * @returns {boolean}
   */
  prevTrack(){
    this.prev.emit(true);
    return false;
  }

  /**
   Timer
   */
  private setupUpdateObservable() {
    this.value = 0;
    this.maxValue = 0;
    this.updateObservable = interval(500);
  }

  /**
   Update track and wave information
   */
  private subscribe() {
    this.updateSubscriber = this.updateObservable.subscribe(() => {
      this.value = this.getCurrentDuration();
      this.maxValue = this.getMaxDuration();
      var percent = this.value/this.maxValue;
      this.innerWidth = 650 * percent;
      this.playingSec = this.player.seek();
    });
  }

  /**
   Get current time of playing track
   * @returns {number}
   */
  private getCurrentDuration(): number {
    return Math.round(<number>this.player.seek());
  }

  /**
   Get track duration
   * @returns {number}
   */
  private getMaxDuration(): number {
    return Math.round(<number>this.player.duration());
  }

  /**
   Stop timer
   */
  private unsubscribe() {
    this.updateSubscriber.unsubscribe();
  }

  /**
   Set width for wave
   * @param e
   */
  setPosition(e){
    if(this.player != undefined){
      this.innerWidth = e.layerX - this.elementRef.nativeElement.children.player.children.progressbar.offsetLeft;
      this.player.seek( (this.innerWidth / 650) * this.maxValue );
    }
  }

  /**
   Convert time for player
   * @param time - time string
   * @returns {string}
   */
  convertTime(time){
    var str = '';
    if(time != 'NaN'){
      var t = new Date(time * 1000);
      var h = t.getUTCHours();
      var m = t.getUTCMinutes();
      var s = t.getUTCSeconds()

      if(h < 10){
        str = '0' + h.toString();
      }
      else{
        str = h.toString();
      }
      if(m < 10){
        str += ':0' + m.toString();
      }
      else{
        str += ':' + m.toString();
      }
      if(s < 10){
        str += ':0' + s.toString();
      }
      else{
        str += ':' + s.toString();
      }
    }
    else{
      str = '00:00:00';
    }
    return str;
  }

  /**
   Play track from start
   * @returns {boolean}
   */
  toStart(){
    if(this.track != null && this.isPlaying){
      this.player.seek(0);
    }
    return false;
  }

  /**
   Add 30 seconds to time for playing track
   * @returns {boolean}
   */
  add30sec(){
    if(this.track != null && this.isPlaying){
      
      if(this.playingSec + 30 < this.player.duration()){
        this.player.seek(this.playingSec + 30);
      }
      else{
        this.player.seek(this.player.duration() - 1);
      }
    }
    return false;
  }

  /**
   Start new track
   * @param tid - track id
   * @param start - playing track or not
   */
  public start( tid, start ){
    if(tid == this.wave && !start){
      this.player.pause();
      this.isPlaying = false;
      this.outputPlaying.emit('pause');
    }
    else{
      this.isPlaying = false;
      this.outputPlaying.emit('stop');
      
      setTimeout(() => this.startPlay(), 500 );
    }
  }

  /**
   Pause track
   */
  public pause(){
    this.isPlaying = false;
    this.outputPlaying.emit('pause');
    this.player.pause();
  }

  /**
   Stop playing track
   * @returns {boolean}
   */
  public stop(){
    if(this.player!=undefined)
      this.player.stop();
    return false;
  }
  /**
   Checking events on click keys on keyboard
   * @param {KeyboardEvent} event - event
   */
  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent){
    if( event.which == 32 && !this.isInputFocused ){
      event.preventDefault();
      if(this.isPlaying){
       
        this.isSpace = true;
      }
      this.startPlay();
      setTimeout(() => {
        if(this.trackDublicate != this.track){
          this.startPlay();
        }
      }, 500 );

    }
    if (!this.isInputFocused) {
      if (event.key === "." && this.isPlaying) {
        this.skipForward30Seconds();
      } else if (event.key === "," && this.isPlaying) {
        this.skipBackward30Seconds();
      }
    }
  }
  return(){
    return false;
  }


  mute(){
    if(this.player!=undefined)
      this.player.mute(!this.muted);
    this.muted = !this.muted;  
    return false;  
  }
  /**
   Checking width for wave and wrapper for wave
   * @param wrapper - wrapper html element
   * @param inner - wave html element
   * @returns {boolean}
   */
  isLong( wrapper, inner ){
    if( inner.offsetWidth > wrapper.offsetWidth ){
     return true;
    }
    else{
      return false;
    }
  }
  skipBackward30Seconds() {
    if (this.track != null && this.isPlaying) {
      const newTime = this.playingSec - 30;
      if (newTime >= 0) {
        this.player.seek(newTime);
      } else {
        this.player.seek(0);
      }
    }
  }
  skipForward30Seconds() {
    if (this.track != null && this.isPlaying) {
      const newTime = this.playingSec + 30;
      if (newTime < this.player.duration()) {
        this.player.seek(newTime);
      } else {
        this.player.seek(this.player.duration() - 1);
      }
    }
  }
  
}
