import {Component, OnInit, ViewChild} from '@angular/core';
import {DropzoneComponent, DropzoneConfigInterface, DropzoneDirective} from 'ngx-dropzone-wrapper';
import {AppComponent} from '../app.component';
import {AdminFunctionsComponent} from '../admin-functions/admin-functions.component';
import {Globals} from '../globals';
import {AlertComponent} from '../alert/alert.component';
import {PlaylistCategoriesService} from '../playlist-categories/playlist-categories.service';
import {ConfirmComponent} from '../confirm/confirm.component';

@Component({
  selector: 'app-import-fp',
  templateUrl: './import-fp.component.html',
  styleUrls: ['./import-fp.component.scss'],
  providers: [PlaylistCategoriesService]
})


export class ImportFpComponent implements OnInit {
  popupOpened = false;//Open popup for upload tracks
  cconfig: DropzoneConfigInterface;//Dropzone component
  @ViewChild(DropzoneComponent) componentRef?: DropzoneComponent;//Dropzone component
  @ViewChild(DropzoneDirective) directiveRef?: DropzoneDirective;//Dropzone component
  @ViewChild(AlertComponent, { static: true }) alert: AlertComponent;//Component of alert popup
  @ViewChild(ConfirmComponent, { static: true }) confirm: ConfirmComponent;//Component of confirm popup

  /**
   Count files for uploading
   {
    added - count of added files,
    error - count of files uploaded with errors,
    success - count of files with success upload
  }
   */
  filesCount = {
    added: 0,
    error: 0,
    success: 0
  };
  profileList = [];
  categoriesList:any = Array();
  subcategories:any = Array();
  public selectedCategoryId = null;
  public selectedSubCategoryId = null;
  public responseData:any = [];
  public page = 'upload';
  confirmValues = {
    data: null,
    type: 0
  };

  constructor(
    private appComp: AppComponent,
    private adminFunctionsComponent: AdminFunctionsComponent,
    private globals: Globals,
    private playlistCatsServ: PlaylistCategoriesService,
  ) { }

  ngOnInit() {
    this.getCategories();
    this.cconfig = {
      // Change this to your upload POST address:
      url: '/api/V1/fp/file/upload',
      maxFilesize: 512,
      acceptedFiles: '.zip',
      method: 'POST',
      timeout: 180000,
      headers: {
        'Authorization': 'Bearer ' + this.globals.access_token
      }
    };
  }

  getCategories(){
    this.playlistCatsServ.getPlaylistsCategories('').subscribe(playCats => {
      this.categoriesList = playCats;
    });
  }
  
  setCategoryID(e){
    this.selectedCategoryId = e.target.value;
    this.getSubcategories();
  }
  
  setSubCategoryID(e){
    this.selectedSubCategoryId = e.target.value;
  }

  getSubcategories(){
    this.playlistCatsServ.getPlaylistsCategories(this.selectedCategoryId).subscribe((subcats:any) => {
      this.subcategories = subcats[0].children;
    });
  }

  /**
   Open dropzone popup
   */
  openPopup(){
    this.popupOpened=!this.popupOpened;
  }

  /**
   Close dropzone popup
   * @param e - event
   */
  popupClose(e){
    e.preventDefault();
    this.popupOpened=!this.popupOpened;
  }

  /**
   Canceling upload filex
   * @param e - event
   */
  cancelUpload(e){
    e.preventDefault();
    this.componentRef.directiveRef.reset();
    this.popupOpened=!this.popupOpened;
  }

  /**
   Event if file uploaded with error
   * @param e - event
   */
  onUploadError(e){
    
    console.log(e);
  }
  /**
   Event if file uploaded correct
   * @param e - event
   */
  onUploadSuccess(e) {

    this.responseData = e[1];
    for (let index = 0; index < this.responseData.length; index++) {
      const element = this.responseData[index].deletedNeeded;

      let arr = [];
      if (element != undefined) {
        Object.keys(element).forEach(function (key) {
          arr.push({
            ['title']: element[key].title,
            ['artist']: element[key].artist
          })
          return arr;
        });
        this.responseData[index].deletedNeededModified = arr;
      }
    }
  }

  /**
   Reset counter
   */
  resetFilesCount(){
    this.filesCount.added = 0;
    this.filesCount.error = 0;
    this.filesCount.success = 0;
  }

  /**
   Display error if upload multiple files
   */
  showMultipleError(){
    this.alert.alertOpen(this.filesCount.error + " file(s) not uploaded",1);
  }

  onSending(data): void {
    this.responseData = [];
  
    const formData = data[2];
    formData.append('categoryID',this.selectedCategoryId);
    formData.append('subCategoryID',this.selectedSubCategoryId);
   
  }

  showResult(){
    this.page = 'result';
  }
  goBack(){
    this.page = 'upload';
  }
  downloadFile(data: any, name:any) {
    const replacer = (key, value) => (value === null ? '' : value); // specify how you want to handle null values here
    const header = Object.keys(data[0]);
    const csv = data.map((row) =>
      header
        .map((fieldName) => JSON.stringify(row[fieldName], replacer))
        .join(',')
    );
    csv.unshift(header.join(','));
    const csvArray = csv.join('\r\n');
  
    const a = document.createElement('a');
    const blob = new Blob([csvArray], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
  
    a.href = url;
    a.download = 'Matching Result - '+name+'.csv';
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }

  downloadFileDelete(data: any, name:any) {
    const replacer = (key, value) => (value === null ? '' : value); // specify how you want to handle null values here
    const header = Object.keys(data[0]);
    const csv = data.map((row) =>
      header
        .map((fieldName) => JSON.stringify(row[fieldName], replacer))
        .join(',')
    );
    csv.unshift(header.join(','));
    const csvArray = csv.join('\r\n');
  
    const a = document.createElement('a');
    const blob = new Blob([csvArray], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
  
    a.href = url;
    a.download = 'Delete needed - '+name+'.csv';
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }

  deleteTracks(data){
    
    let arr = [];  
    if(data != undefined)
    Object.keys(data).forEach(function(key){  
      arr.push(key)  
      return arr;  
    }); 
    
    var str = 'data='+JSON.stringify(arr);
    
    this.playlistCatsServ.deleteTracks(str,this.selectedSubCategoryId).subscribe((res:any) => {
      console.log('res');
      if(res.error == undefined){
        if(res && res.message != undefined)
          this.alert.alertOpen(res.message ,0);
      }else{
        this.alert.alertOpen(res.error ,1);
      }
    });
  }

    /**
   Confirm action in confirmation popup
   * @param e - event
   */
  isConfirm(e){
    if(e){
      if( this.confirmValues.type == 0 )
        this.deleteTracks(this.confirmValues.data);
    }
  }
  /**
   Open confirmation popup
   * @param id - id of action
   * @param t - text for popup
   * @returns {boolean}
   */
  openConfirmPopup(data, t){
    var text = "Make sure you have export the file and confirm the list of tracks that will be deleted. Please confirm the delete action?";
    this.confirmValues.data = data;
    this.confirmValues.type = t;
    this.confirm.confirmOpen(text);
    return false;
  }
}
