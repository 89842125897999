<div class="schedule_calendar">
  <ul class="tabs">
    <li class="selected" *ngIf="scheduleInfo!=undefined" title="{{tab_str}}">{{scheduleInfo.name}}</li>
    <li class="expand" [ngClass]="{closed: !showForm}"><a href="#" (click)="expandForm()"></a></li>
  </ul>
  <div class="schedule_form" [ngClass]="{closed: !showForm}">
    <form #scheduleForm="ngForm">
      <div class="form_column">
        <div class="input_wrap">
          <label>Playlist Name</label>
          <select name="playlist_name" *ngIf="schedule.playlistList!=undefined" [(ngModel)]="schedule.playlistId">
            <option *ngFor="let p of schedule.playlistList" value="{{p.id}}" [selected]="schedule.playlistId == p.id">{{p.name}}</option>
          </select>
        </div>
        <div class="input_wrap multiple">
          <label>In Date</label>
         
          <select style="width: 15%;margin-right: 10px;" name="begin-date-year" [(ngModel)]="schedule.begin_date.year"
            (ngModelChange)="schedule.begin_date.year = $event ? $event : 'YYYY'">
            <optgroup label="current value">
              <option value="{{schedule.begin_date.year}}">{{schedule.begin_date.year}}</option>
            </optgroup>
            <optgroup label="options">
              <option value="YYYY">YYYY</option>
              <option value="1899">1899</option>
              <option *ngFor="let year of year_fr" value="{{year}}">{{year}}</option>
              <option value="2121">2121</option>
            </optgroup>
            </select>
            <select style="width: 13%;margin-right: 10px;" name="begin-date-month}" [(ngModel)]="schedule.begin_date.month"
            (ngModelChange)="schedule.begin_date.month = $event ? $event : 'MM'">
              <option value="MM">MM</option>
              <option *ngFor="let month of month_fr" value="{{month}}">{{month}}</option>
            </select>
            <select style="width: 13%;" name="begin-date-day" [(ngModel)]="schedule.begin_date.day"
            (ngModelChange)="schedule.begin_date.day = $event ? $event : 'DD'">
              <option value="DD">DD</option>
              <option *ngFor="let day of day_fr(schedule.begin_date.year,schedule.begin_date.month)" value="{{day}}">{{day}}</option>
            </select>
        </div>
        <div class="input_wrap">  
          <label>Out Date</label>
          
            <select style="width: 15%;margin-right: 10px;" name="enddate-year" [(ngModel)]="schedule.finish_date.year"
            (ngModelChange)="schedule.finish_date.year = $event ? $event : ''">
              <optgroup label="current value">
                <option value="{{schedule.finish_date.year}}">{{schedule.finish_date.year}}</option>
              </optgroup>
              <optgroup label="options">
                <option value=""></option>
                <option value="YYYY">YYYY</option>
                <option value="1899">1899</option>
                <option *ngFor="let year of year_fr" value="{{year}}">{{year}}</option>
                <option value="2121">2121</option>
              </optgroup>
            </select>
            <select style="width: 13%;margin-right: 10px;" name="enddate-month" [(ngModel)]="schedule.finish_date.month"
            (ngModelChange)="schedule.finish_date.month = $event ? $event : ''">
              <option value=""></option>
              <option value="MM">MM</option>
              <option *ngFor="let month of month_fr" value="{{month}}">{{month}}</option>
            </select>
            <select style="width: 13%;" name="enddate-day" [(ngModel)]="schedule.finish_date.day"
            (ngModelChange)="schedule.finish_date.day = $event ? $event : ''">
              <option value=""></option>
              <option value="DD">DD</option>
              <option *ngFor="let day of day_fr(schedule.finish_date.year,schedule.finish_date.month)" value="{{day}}">{{day}}</option>
            </select>
        </div>
        <div class="input_wrap">
          <label class="double">On Demand / Intersub</label>
          <input style="width: auto;height: auto;" type="checkbox" id="onDemand" name="onDemand" value="schedule.onDemand" (click)="onDemandClick($event)" [checked]="schedule.begin_time.hours == '00' && schedule.begin_time.minutes == '00' && schedule.finish_time.hours == '00' && schedule.finish_time.minutes == '00'">

        </div>
        <div class="input_wrap split">
          <label>Start Time</label>
          <select name="begin_time_hour" [(ngModel)]="schedule.begin_time.hours"
          (ngModelChange)="schedule.begin_time.hours = $event ? $event : '00'"
          (change)="changeFinishTime()" [disabled]="schedule.onDemand">
            <option *ngFor="let hour of begin_hours" value="{{hour}}">{{hour}}</option>
          </select>
          <label for="begin_time_hour" class="mini_label">H</label>

          <select name="begin_time_minute" [(ngModel)]="schedule.begin_time.minutes"
          (ngModelChange)="schedule.begin_time.minutes = $event ? $event : '00'"
          (change)="changeFinishTime()" [disabled]="schedule.onDemand">
            <option *ngFor="let minute of begin_minutes" value="{{minute}}">{{minute}}</option>
          </select>
          <label for="begin_time_minute" class="mini_label" style="margin-right: 5px;">M</label>

          
          <label style="width: 15%;">End Time</label>
          <select name="finish_time_hour" [(ngModel)]="schedule.finish_time.hours"
          (ngModelChange)="schedule.finish_time.hours = $event ? $event : '00'" 
          (change)="changeFinishTime()" [disabled]="schedule.onDemand">
            <option *ngFor="let hour of finish_hours" value="{{hour}}">{{hour}}</option>
          </select>
          <label for="finish_time_hour" class="mini_label">H</label>

          <select name="finish_time_minute" [(ngModel)]="schedule.finish_time.minutes"
          (ngModelChange)="schedule.finish_time.minutes = $event ? $event : '00'" 
          (change)="changeFinishTime()" [disabled]="schedule.onDemand">
            <option *ngFor="let minute of finish_minutes" value="{{minute}}">{{minute}}</option>
          </select>
          <label for="finish_time_minute" class="mini_label">M</label>

          
        </div>
        
        <div class="input_wrap">
          <label>Overlay</label>
          <input style="width: auto;height: auto;" type="checkbox" id="onOverlay" name="onOverlay" value="schedule.onOverlay" (click)="onOverlayClick($event)" [checked]="schedule.onOverlay">
        </div>


      <div class="input_wrap frequency_range" *ngIf="schedule.onOverlay">
          <label>Frequency Range</label>
          <div class="foundation_playlist_selects" style="min-width: 900px;">
            <div class="row titles">
              <div style="width:20%">Start Date</div>
              <div style="width:20%">End Date</div>
              <div style="width:20%">Start Time</div>
              <div style="width:20%">End Time</div>
              <div style="width:10%">Frequency</div>
              <div style="width:10%">&nbsp;</div>
            </div>
            <div class="">
            <div class="filter_line row" *ngFor="let fr of schedule.frequency_range;let i=index">
              <div style="width: 20%;">
                
                <select style="width: 33%;margin-right: 10px;" name="startdate-year-{{i}}" [(ngModel)]="fr.startDate.year"
                (ngModelChange)="fr.startDate.year = $event ? $event : 'YYYY'">
                  <option value="YYYY">YYYY</option>
                  <option *ngFor="let year of year_fr" value="{{year}}">{{year}}</option>
                </select>
                <select style="width: 27%;margin-right: 10px;" name="startdate-month-{{i}}" [(ngModel)]="fr.startDate.month"
                (ngModelChange)="fr.startDate.month = $event ? $event : 'MM'">
                  <option value="MM">MM</option>
                  <option *ngFor="let month of month_fr" value="{{month}}">{{month}}</option>
                </select>
                <select style="width: 25%;" name="startdate-day-{{i}}" [(ngModel)]="fr.startDate.day"
                (ngModelChange)="fr.startDate.day = $event ? $event : 'DD'">
                  <option value="DD">DD</option>
                  <option *ngFor="let day of day_fr(fr.startDate.year,fr.startDate.month)" value="{{day}}">{{day}}</option>
                </select>
              </div>
              <div style="width: 20%;">
                
                <select style="width: 33%;margin-right: 10px;" name="enddate-year-{{i}}" [(ngModel)]="fr.endDate.year"
                (ngModelChange)="fr.endDate.year = $event ? $event : 'YYYY'">
                  <option value="YYYY">YYYY</option>
                  <option *ngFor="let year of year_fr" value="{{year}}">{{year}}</option>
                </select>
                <select style="width: 27%;margin-right: 10px;" name="enddate-month-{{i}}" [(ngModel)]="fr.endDate.month"
                (ngModelChange)="fr.endDate.month = $event ? $event : 'MM'">
                  <option value="MM">MM</option>
                  <option *ngFor="let month of month_fr" value="{{month}}">{{month}}</option>
                </select>
                <select style="width: 25%;" name="enddate-day-{{i}}" [(ngModel)]="fr.endDate.day"
                (ngModelChange)="fr.endDate.day = $event ? $event : 'DD'">
                  <option value="DD">DD</option>
                  <option *ngFor="let day of day_fr(fr.endDate.year,fr.endDate.month)" value="{{day}}">{{day}}</option>
                </select>
              </div>
              <div style="width: 20%;">
                <select style="width: 23%;margin-right: 2px;" name="sth-{{i}}" [(ngModel)]="fr.startTime.hours"
                (ngModelChange)="fr.startTime.hours = $event ? $event : '00'">
                  <option *ngFor="let hour of begin_hours_fr" value="{{hour}}">{{hour}}</option>
                </select>
                <label for="begin_time_hour" class="mini_label">H</label>
                <select style="width: 23%;margin-right: 2px;margin-left: 10px;" name="stm-{{i}}" [(ngModel)]="fr.startTime.minutes"
                (ngModelChange)="fr.startTime.minutes = $event ? $event : '00'">
                  <option *ngFor="let minute of begin_minutes_fr" value="{{minute}}">{{minute}}</option>
                </select>
                <label for="begin_time_minute" class="mini_label" style="margin-right: 5px;">M</label>
              </div>
              <div style="width: 20%;">
                <select style="width: 23%;margin-right: 2px;" name="fth-{{i}}" [(ngModel)]="fr.endTime.hours"
                (ngModelChange)="fr.endTime.hours = $event ? $event : '00'">
                  <option *ngFor="let hour of finish_hours_fr" value="{{hour}}">{{hour}}</option>
                </select>
                <label for="finish_time_hour" class="mini_label">H</label>
                <select style="width: 23%;margin-right: 2px;margin-left: 10px;" name="ftm-{{i}}" [(ngModel)]="fr.endTime.minutes"
                (ngModelChange)="fr.endTime.minutes = $event ? $event : '00'">
                  <option *ngFor="let minute of finish_minutes_fr" value="{{minute}}">{{minute}}</option>
                </select>
                <label for="finish_time_minute" class="mini_label">M</label>
              </div>
              <div style="width: 10%;">
                <select name="frequency-{{i}}" [(ngModel)]="fr.frequency"
                (ngModelChange)="fr.frequency = $event ? $event : '00'">
                  <option *ngFor="let frequency of frequency_fr" value="{{frequency}}">{{frequency}}</option>
                </select>

              </div>
              <div class="func_col" style="width: 10%;">
                <a href="#" class="btn_remove" 
                  (click)="removeFrequencyRange(i,fr.id,true)"></a>

                <a href="#" class="btn_add" (click)="addFrequencyRange()"></a>
              </div>
            </div>  
            </div>
          </div>

        </div>  
      </div>
      <div class="form_column">
        <div class="input_wrap">
          <label>Recurrence</label>
          <div class="radio_wrap">
            <input id="i-rec-1" (click)="changeRecurrence()" name="rec" type="radio" value="1" [(ngModel)]="schedule.recurrence_fl" [checked]="schedule.recurrence_fl=='1'" (change)="changeRecurrence()"/>
            <label for="i-rec-1" (click)="changeRecurrence()">Yes</label>
          </div>
          <div class="radio_wrap">
            <input id="i-rec-2" name="rec" type="radio" value="0" [(ngModel)]="schedule.recurrence_fl" [checked]="schedule.recurrence_fl=='0'" (change)="changeRecurrence()"/>
            <label for="i-rec-2">No</label>
          </div>
        </div>
        <div class="input_wrap">
          <label>Random Order</label>
          <div class="radio_wrap">
            <input id="i-rand-1" name="rand" type="radio" value="1" [(ngModel)]="schedule.random" [checked]="schedule.random=='1'"/>
            <label for="i-rand-1">Yes</label>
          </div>
          <div class="radio_wrap">
            <input id="i-rand-2" name="rand" checked="checked" type="radio" value="0" [(ngModel)]="schedule.random" [checked]="schedule.random=='0'"/>
            <label for="i-rand-2">No</label>
          </div>
        </div>
        <div class="input_wrap small">
          <label>Artist Clash Distance</label>
          <input (blur)="checkDistance()" type="text" value="{{schedule.clash_distance}}" [(ngModel)]="schedule.clash_distance" name="clash_distance" [ngClass]="{error: !scheduleValidation.clash_distance}"/>
          <label>&nbsp;&nbsp;Tracks</label>
        </div>
        
        <div class="input_wrap duration">
            
          <label class="double">Maximum Override Duration</label>
          
          <select name="duration_hour" [(ngModel)]="schedule.override_duration.hours"
          (ngModelChange)="schedule.override_duration.hours = $event ? $event : '00'">
            <option *ngFor="let hour of ['00','01','02','03','04','05','06','07','08','09','10','11']" value="{{hour}}">{{hour}}</option>
          </select>
          <label for="duration_hour" class="small_label">Hours</label>

          <select name="duration_minute" [(ngModel)]="schedule.override_duration.minutes"
          (ngModelChange)="schedule.override_duration.minutes = $event ? $event : '00'">
            <option *ngFor="let minute of 
            ['00','01','02','03','04','05','06','07','08','09','10',
             '11','12','13','14','15','16','17','18','19','20',
             '21','22','23','24','25','26','27','28','29','30',
             '31','32','33','34','35','36','37','38','39','40',
             '41','42','43','44','45','46','47','48','49','50',
             '51','52','53','54','55','56','57','58','59']" value="{{minute}}">{{minute}}</option>
          </select>
          <label for="duration_minute" class="small_label">Minutes</label>

         
        </div>
        
      </div>
      <div class="form_column">
        <div class="input_wrap">
          <label>Mastering</label>
          <div class="radio_wrap">
            <input id="i-mast-1" name="mast" type="radio" value="1" [(ngModel)]="schedule.mastering_fl" [checked]="schedule.mastering_fl=='1'" (change)="changeMastering()"/>
            <label for="i-mast-1" (click)="changeMastering()">Yes</label>
          </div>
          <div class="radio_wrap">
            <input id="i-mast-2" name="mast" type="radio" value="0" [(ngModel)]="schedule.mastering_fl" [checked]="schedule.mastering_fl=='0'" (change)="changeMastering()"/>
            <label for="i-mast-2">No</label>
          </div>
        </div>
        <div class="input_wrap multiple">
          <label>Background</label>
          <input [value]="schedule.bgColor" [(colorPicker)]="schedule.bgColor" [cpWidth]="'200px'" [(style.background)]="schedule.bgColor" [cpPosition]="'right'" class="colorpicker" [cpPresetColors]="['#ffffff', '#000000', '#00ff00', '#ff0000', '#aaaaaa', '#ff00ff', '#00ffff', '#eaeaea', '#0000ff', '#777777']"/>
          <label>Text</label>
          <input [value]="schedule.txtColor" [(colorPicker)]="schedule.txtColor" [cpWidth]="'200px'" [(style.background)]="schedule.txtColor" [cpPosition]="'left'" class="colorpicker" [cpPresetColors]="['#ffffff', '#000000', '#00ff00', '#ff0000', '#aaaaaa', '#ff00ff', '#00ffff', '#eaeaea', '#0000ff', '#777777']"/>
        </div>
          <div class="input_wrap">
            <label style="line-height: 10px">&nbsp;</label>
          </div>
          <div class="input_wrap">
            <label style="line-height: 10px">&nbsp;</label>
          </div>
        <div class="popup_btns">
          <button type="button" class="cancel" (click)="changeSchedule(sheduleId)">Cancel</button>
          <button type="submit" (click)="checkScheduleDate()">Save</button>
        </div>
      </div>
    </form>
  </div>
  <div class="calendar_wrap" [ngClass]="{opened: !showForm}">
    <div class="schedule_top_line">
      <div class="title">Schedule overview</div>
      <div class="arrows">
        <a href="#" (click)="prevWeek()">&lt;</a>
        <div class="this_week">{{monthFullNames[thisWeek[0].getMonth()]}} {{thisWeek[0].getDate()}}-{{thisWeek[6].getDate()}}, {{thisWeek[0].getFullYear()}}</div>
        <a href="#" (click)="nextWeek()">&gt;</a>
      </div>
    </div>
    <div class="columns_wrap_titles">
      <div class="dateColumn">
        <div>&nbsp;</div>
        <div class="with_button">
          <button class="btn_expand" (click)="showCalendar()">
            <i style="font-size:24px" class="fa">&#xf065;</i>
          </button>
        </div>
      </div>
      <div *ngFor="let day of thisWeek;let i = index" class="dateColumn">
        <div class="day">
          <span>{{daysNames[i]}}</span>
        </div>
        <div class="date">
          <span>{{monthNames[day.getMonth()]}} {{day.getDate()}}</span>
        </div>
      </div>
    </div>
    <div class="columns_wrap_main" [ngClass]="{opened: isShowCalendar}">
      <div class="dateColumn">
        <div class="time" *ngFor="let t of timeArr;let i = index">
          <span>{{t}}</span>
        </div>
      </div>
      <div *ngFor="let day of thisWeek;let i = index" class="dateColumn">
        <div class="date_box" *ngFor="let t of timeArr;let j = index">
          <span class="plist_span" *ngIf="checkSchedule(day,j).length > 0; else elseBlock"
            [(style.background)]="'#'+scheduleDates[checkSchedule(day, j)[checkSchedule(day,j).length - 1]].background_color" 
            [(style.color)]="'#'+scheduleDates[checkSchedule(day, j)[checkSchedule(day,j).length - 1]].text_color">
            <ng-template #tooltipContent>
              <span>{{ getTooltipContent(checkSchedule(day, j)) }}</span>
            </ng-template>
            <div *ngIf="isShowCalendar" 
              [options]="tooltipOptions"
              [tooltip]="tooltipContent"
              contentType="template"> {{ getTooltipContent(checkSchedule(day, j)) }} </div> 
            <!-- <span class="plist_span" *ngIf="isShowCalendar && scheduleDates[checkSchedule(day,j)]!=undefined && 
            (scheduleDates[checkSchedule(day,j)].start_time != '00:00' || scheduleDates[checkSchedule(day,j)].end_time != '00:00') && scheduleDates[checkSchedule(day,j)].overlay_playlist_id == null;
            else elseBlock" [(style.background)]="'#'+scheduleDates[checkSchedule(day,j)].background_color" [(style.color)]="'#'+scheduleDates[checkSchedule(day,j)].text_color">
              {{ scheduleDates[checkSchedule(day,j)].name.split('-').pop() }}
            </span> -->
          </span>
          <ng-template #elseBlock><em>&nbsp;</em></ng-template>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="popup calendar_popup schedule_calendar" [ngClass]="{opened: popups.calendarPopupOpened}" ngDraggable ngResizable [rzHandles]="'n,e,s,w,se,sw,ne,nw'" [handle]="dragHandle" style="width:95%; top: 15%; left: 16%; max-height: 80%; height: 80%;">
  <a href="#" class="popup_close" (click)="calendarPopupClose()"></a>
  <div class="popup_title" #dragHandle>Schedule overview</div>
  <div class="popup_form calendar_wrap" style="position: relative;">
    <div class="schedule_top_line" style="text-align: right;">
      <div class="arrows">
        <a href="#" (click)="prevWeek()">&lt;</a>
        <div class="this_week">{{monthFullNames[thisWeek[0].getMonth()]}} {{thisWeek[0].getDate()}}-{{thisWeek[6].getDate()}}, {{thisWeek[0].getFullYear()}}</div>
        <a href="#" (click)="nextWeek()">&gt;</a>
      </div>
    </div>
    <div class="columns_wrap_titles">
      <div class="dateColumn">
        <div>&nbsp;</div>
        <!-- <div class="with_button">
          <button class="btn_expand" (click)="showCalendar()">+</button>
        </div> -->
      </div>
      <div *ngFor="let day of thisWeek;let i = index" class="dateColumn">
        <div class="day">
          <span>{{daysNames[i]}}</span>
        </div>
        <div class="date">
          <span>{{monthNames[day.getMonth()]}} {{day.getDate()}}</span>
        </div>
      </div>
    </div>
    <div class="columns_wrap_main opened">
      <div class="dateColumn">
        <div class="time" *ngFor="let t of timeArr;let i = index">
          <span>{{t}}</span>
        </div>
      </div>
      <div *ngFor="let day of thisWeek;let i = index" class="dateColumn">
        <div class="date_box" *ngFor="let t of timeArr;let j = index">
          <!-- <span class="plist_span" *ngIf="scheduleDates[checkSchedule(day,j)]!=undefined && (scheduleDates[checkSchedule(day,j)].start_time != '00:00' || scheduleDates[checkSchedule(day,j)].end_time != '00:00') && scheduleDates[checkSchedule(day,j)].overlay_playlist_id == null; else elseBlock" [(style.background)]="'#'+scheduleDates[checkSchedule(day,j)].background_color" [(style.color)]="'#'+scheduleDates[checkSchedule(day,j)].text_color">
            {{ scheduleDates[checkSchedule(day,j)].name.split('-').pop() }}
          </span> -->
          <span class="plist_span" *ngIf="checkSchedule(day,j).length > 0; else elseBlock"
            [(style.background)]="'#'+scheduleDates[checkSchedule(day, j)[checkSchedule(day,j).length - 1]].background_color" 
            [(style.color)]="'#'+scheduleDates[checkSchedule(day, j)[checkSchedule(day,j).length - 1]].text_color">
            <ng-template #tooltipContent>
              <span>{{ getTooltipContent(checkSchedule(day, j)) }}</span>
            </ng-template>
            <div 
              [options]="tooltipOptions"
              [tooltip]="tooltipContent"
              contentType="template"> {{ getTooltipContent(checkSchedule(day, j)) }} 
            </div>
          </span>
          <ng-template #elseBlock><em>&nbsp;</em></ng-template>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="popup recurrence_popup" [ngClass]="{opened: popups.recurrencePopupOpened}" ngDraggable ngResizable [rzHandles]="'n,e,s,w,se,sw,ne,nw'" [handle]="dragHandle">
  <a href="#" class="popup_close" (click)="recurrencePopupClose(0)"></a>
  <div class="popup_title" #dragHandle>Recurrence</div>
  <div class="popup_form">
    <form #recurrenceForm="ngForm">
      <div class="left_form">
        <div class="input_wrap">
          <input id="rec-1" name="recurrence" type="radio" value="1" [(ngModel)]="schedule.recurrence.radio_value" [checked]="schedule.recurrence.radio_value=='1'"/>
          <label for="rec-1">Daily</label>
        </div>
        <div class="input_wrap">
          <input id="rec-2" name="recurrence" type="radio" value="2" [(ngModel)]="schedule.recurrence.radio_value" [checked]="schedule.recurrence.radio_value=='2'"/>
          <label for="rec-2">Weekly</label>
        </div>
        <div class="input_wrap">
          <input id="rec-3" name="recurrence" type="radio" value="3" [(ngModel)]="schedule.recurrence.radio_value" [checked]="schedule.recurrence.radio_value=='3'"/>
          <label for="rec-3">Monthly</label>
        </div>
        <div class="input_wrap">
          <input id="rec-4" name="recurrence" type="radio" value="4" [(ngModel)]="schedule.recurrence.radio_value" [checked]="schedule.recurrence.radio_value=='4'"/>
          <label for="rec-4">Yearly</label>
        </div>
      </div>
      <div class="right_form">
        <div class="daily_form" *ngIf="schedule.recurrence.radio_value=='1'">
          <div class="input_wrap">
            <label>Every</label>
            <input type="text" name="days" value="{{schedule.recurrence.daily.days}}" [(ngModel)]="schedule.recurrence.daily.days"/>
            <label>days</label>
          </div>
        </div>
        <div class="weekly_form" *ngIf="schedule.recurrence.radio_value=='2'">
          <div class="input_wrap">
            <label>Every</label>
            <input type="text" name="week" value="{{schedule.recurrence.weekly.week}}" [(ngModel)]="schedule.recurrence.weekly.week"/>
            <label>week(s) on</label>
          </div>
          <select name="playlist_name" [(ngModel)]="schedule.recurrence.weekly.days"
          (ngModelChange)="schedule.recurrence.weekly.day = $event ? $event : null">
            <option value="null" >Every day of week</option>
            <option value="SU" >Sunday</option>
            <option value="MO" >Monday</option>
            <option value="TU" >Tuesday</option>
            <option value="WE" >Wednesday</option>
            <option value="TH" >Thursday</option>
            <option value="FR" >Friday</option>
            <option value="SA" >Saturday</option>
          </select>
        
        </div>
        <div class="monthly_form" *ngIf="schedule.recurrence.radio_value=='3'">
          <div class="input_wrap">
            <label>Every</label>
            <input type="text" name="month" value="{{schedule.recurrence.monthly.month}}" [(ngModel)]="schedule.recurrence.monthly.month"/>
            <label>month(s)</label>
          </div>
          <div class="input_wrap">
            <input id="ms-1" name="month_selection" type="radio" value="1" [(ngModel)]="schedule.recurrence.monthly.monthSelection" [checked]="schedule.recurrence.monthly.monthSelection=='1'"/>
            <label for="ms-1">On this days</label>
          </div>
          <div class="days" *ngIf="schedule.recurrence.monthly.monthSelection == '1'">
            <div class="chb_day" *ngFor="let d of schedule.recurrence.monthly.days">
              <input type="checkbox" id="chb_mday-{{d.num}}" name="chb_mday-{{d.num}}" [checked]="schedule.recurrence.monthly.days[d.num-1].value" [(ngModel)]="schedule.recurrence.monthly.days[d.num-1].value" />
              <label for="chb_mday-{{d.num}}">{{d.num}}</label>
            </div>
          </div>
          <div class="input_wrap">
            <input id="ms-2" name="month_selection" type="radio" value="2" [(ngModel)]="schedule.recurrence.monthly.monthSelection" [checked]="schedule.recurrence.monthly.monthSelection=='2'"/>
            <label for="ms-2">On</label>
          </div>
          <div class="days2" *ngIf="schedule.recurrence.monthly.monthSelection=='2'">
            <select name="m_num" [(ngModel)]="schedule.recurrence.monthly.number">
              <option value="1" [selected]="schedule.recurrence.monthly.number=='1'">First</option>
              <option value="2" [selected]="schedule.recurrence.monthly.number=='2'">Second</option>
              <option value="3" [selected]="schedule.recurrence.monthly.number=='3'">Third</option>
              <option value="4" [selected]="schedule.recurrence.monthly.number=='4'">Fourth</option>
              <option value="5" [selected]="schedule.recurrence.monthly.number=='5'">Fifth</option>
              <option value="last" [selected]="schedule.recurrence.monthly.number=='last'">Last</option>
            </select>
            <select name="m_type" [(ngModel)]="schedule.recurrence.monthly.type">
              <option value="SU" [selected]="schedule.recurrence.monthly.type=='SU'">Sunday</option>
              <option value="MO" [selected]="schedule.recurrence.monthly.type=='MO'">Monday</option>
              <option value="TU" [selected]="schedule.recurrence.monthly.type=='TU'">Tuesday</option>
              <option value="WE" [selected]="schedule.recurrence.monthly.type=='WE'">Wednesday</option>
              <option value="TH" [selected]="schedule.recurrence.monthly.type=='TH'">Thursday</option>
              <option value="FR" [selected]="schedule.recurrence.monthly.type=='FR'">Friday</option>
              <option value="SA" [selected]="schedule.recurrence.monthly.type=='SA'">Saturday</option>
              <option value="day" [selected]="schedule.recurrence.monthly.type=='day'">Day</option>
            </select>
          </div>
        </div>
        <div class="yearly_form" *ngIf="schedule.recurrence.radio_value=='4'">
          <div class="input_wrap">
            <label>Every</label>
            <input type="text" name="month" value="{{schedule.recurrence.yearly.year}}" [(ngModel)]="schedule.recurrence.yearly.year"/>
            <label>year(s) in</label>
          </div>
          <div class="monthes">
            <div class="chb_month">
              <input type="checkbox" id="chb_month-1" name="chb_month[0]" value="0" [(ngModel)]="schedule.recurrence.yearly.monthes[0]" [checked]="schedule.recurrence.yearly.monthes[0]">
              <label for="chb_month-1">Jan</label>
            </div>
            <div class="chb_month">
              <input type="checkbox" id="chb_month-2" name="chb_month[1]" value="1" [(ngModel)]="schedule.recurrence.yearly.monthes[1]" [checked]="schedule.recurrence.yearly.monthes[1]">
              <label for="chb_month-2">Feb</label>
            </div>
            <div class="chb_month">
              <input type="checkbox" id="chb_month-3" name="chb_month[2]" value="2" [(ngModel)]="schedule.recurrence.yearly.monthes[2]" [checked]="schedule.recurrence.yearly.monthes[2]">
              <label for="chb_month-3">Mar</label>
            </div>
            <div class="chb_month">
              <input type="checkbox" id="chb_month-4" name="chb_month[3]" value="3" [(ngModel)]="schedule.recurrence.yearly.monthes[3]" [checked]="schedule.recurrence.yearly.monthes[3]">
              <label for="chb_month-4">Apr</label>
            </div>
            <div class="chb_month">
              <input type="checkbox" id="chb_month-5" name="chb_month[4]" value="4" [(ngModel)]="schedule.recurrence.yearly.monthes[4]" [checked]="schedule.recurrence.yearly.monthes[4]">
              <label for="chb_month-5">May</label>
            </div>
            <div class="chb_month">
              <input type="checkbox" id="chb_month-6" name="chb_month[5]" value="5" [(ngModel)]="schedule.recurrence.yearly.monthes[5]" [checked]="schedule.recurrence.yearly.monthes[5]">
              <label for="chb_month-6">Jun</label>
            </div>
            <div class="chb_month">
              <input type="checkbox" id="chb_month-7" name="chb_month[6]" value="6" [(ngModel)]="schedule.recurrence.yearly.monthes[6]" [checked]="schedule.recurrence.yearly.monthes[6]">
              <label for="chb_month-7">Jul</label>
            </div>
            <div class="chb_month">
              <input type="checkbox" id="chb_month-8" name="chb_month[7]" value="7" [(ngModel)]="schedule.recurrence.yearly.monthes[7]" [checked]="schedule.recurrence.yearly.monthes[7]">
              <label for="chb_month-8">Aug</label>
            </div>
            <div class="chb_month">
              <input type="checkbox" id="chb_month-9" name="chb_month[8]" value="8" [(ngModel)]="schedule.recurrence.yearly.monthes[8]" [checked]="schedule.recurrence.yearly.monthes[8]">
              <label for="chb_month-9">Sep</label>
            </div>
            <div class="chb_month">
              <input type="checkbox" id="chb_month-10" name="chb_month[9]" value="9" [(ngModel)]="schedule.recurrence.yearly.monthes[9]" [checked]="schedule.recurrence.yearly.monthes[9]">
              <label for="chb_month-10">Oct</label>
            </div>
            <div class="chb_month">
              <input type="checkbox" id="chb_month-11" name="chb_month[10]" value="10" [(ngModel)]="schedule.recurrence.yearly.monthes[10]" [checked]="schedule.recurrence.yearly.monthes[10]">
              <label for="chb_month-11">Nov</label>
            </div>
            <div class="chb_month">
              <input type="checkbox" id="chb_month-12" name="chb_month[11]" value="11" [(ngModel)]="schedule.recurrence.yearly.monthes[11]" [checked]="schedule.recurrence.yearly.monthes[11]">
              <label for="chb_month-12">Dec</label>
            </div>
          </div>
          <div class="input_wrap">
            <input type="checkbox" id="year_selection" name="year_selection" [(ngModel)]="schedule.recurrence.yearly.monthSelection" [checked]="schedule.recurrence.yearly.monthSelection">
            <label for="year_selection">On</label>
          </div>
          <div class="days2" *ngIf="schedule.recurrence.yearly.monthSelection==true">
            <select name="m_num" [(ngModel)]="schedule.recurrence.yearly.number">
              <option value="1" [selected]="schedule.recurrence.yearly.number==1">First</option>
              <option value="2" [selected]="schedule.recurrence.yearly.number==2">Second</option>
              <option value="3" [selected]="schedule.recurrence.yearly.number==3">Third</option>
              <option value="4" [selected]="schedule.recurrence.yearly.number==4">Fourth</option>
              <option value="5" [selected]="schedule.recurrence.yearly.number==5">Fifth</option>
              <option value="last" [selected]="schedule.recurrence.yearly.number==6">Last</option>
            </select>
            <select name="m_type" [(ngModel)]="schedule.recurrence.yearly.type">
              <option value="SU" [selected]="schedule.recurrence.yearly.type=='SU'">Sunday</option>
              <option value="MO" [selected]="schedule.recurrence.yearly.type=='MO'">Monday</option>
              <option value="TU" [selected]="schedule.recurrence.yearly.type=='TU'">Tuesday</option>
              <option value="WE" [selected]="schedule.recurrence.yearly.type=='WE'">Wednesday</option>
              <option value="TH" [selected]="schedule.recurrence.yearly.type=='TH'">Thursday</option>
              <option value="FR" [selected]="schedule.recurrence.yearly.type=='FR'">Friday</option>
              <option value="SA" [selected]="schedule.recurrence.yearly.type=='SA'">Saturday</option>
              <option value="day" [selected]="schedule.recurrence.yearly.type=='day'">Day</option>
            </select>
          </div>
        </div>
      </div>
      <div class="popup_btns">
        <button type="button" class="cancel" (click)="recurrencePopupClose(0)">Cancel</button>
        <button type="submit" (click)="recurrencePopupClose(1)">Save</button>
      </div>
    </form>
  </div>
</div>
<div class="popup mastering_popup" [ngClass]="{opened: popups.masteringPopupOpened}" ngDraggable ngResizable [rzHandles]="'n,e,s,w,se,sw,ne,nw'" [handle]="dragHandle2">
  <a href="#" class="popup_close" (click)="masteringPopupClose(0)"></a>
  <div class="popup_title" #dragHandle2>Mastering</div>
  <div class="popup_form">
    <form #recurrenceForm="ngForm">
      <div class="input_wrap">
        <input type="radio" id="mastering-1" name="mastering-1" value="0" [(ngModel)]="schedule.mastering.m_switch" [checked]="schedule.mastering.m_switch == '0'"/>
        <label for="mastering-1">Day</label>
        <input type="text" name="mastering_day" [(ngModel)]="schedule.mastering.days" value="{{schedule.mastering.days}}"/>
        <label>of every</label>
        <input type="text" name="mastering_month" [(ngModel)]="schedule.mastering.months" value="{{schedule.mastering.months}}"/>
        <label>month(s)</label>
      </div>
      <div class="input_wrap">
        <input type="radio" id="mastering-2" name="mastering-1" value="1" [(ngModel)]="schedule.mastering.m_switch" [checked]="schedule.mastering.m_switch == '1'"/>
        <label for="mastering-2">The</label>
        <select name="mastering_m_num" [(ngModel)]="schedule.mastering.every_week_day">
          <option value="1" [selected]="schedule.mastering.every_week_day=='1'">First</option>
          <option value="2" [selected]="schedule.mastering.every_week_day=='2'">Second</option>
          <option value="3" [selected]="schedule.mastering.every_week_day=='3'">Third</option>
          <option value="4" [selected]="schedule.mastering.every_week_day=='4'">Fourth</option>
          <option value="5" [selected]="schedule.mastering.every_week_day=='5'">Fifth</option>
          <option value="last" [selected]="schedule.mastering.every_week_day=='6'">Last</option>
        </select>
        <select name="mastering_m_type" [(ngModel)]="schedule.mastering.recurrence_dow">
          <option value="SU" [selected]="schedule.mastering.recurrence_dow=='SU'">Sunday</option>
          <option value="MO" [selected]="schedule.mastering.recurrence_dow=='MO'">Monday</option>
          <option value="TU" [selected]="schedule.mastering.recurrence_dow=='TU'">Tuesday</option>
          <option value="WE" [selected]="schedule.mastering.recurrence_dow=='WE'">Wednesday</option>
          <option value="TH" [selected]="schedule.mastering.recurrence_dow=='TH'">Thursday</option>
          <option value="FR" [selected]="schedule.mastering.recurrence_dow=='FR'">Friday</option>
          <option value="SA" [selected]="schedule.mastering.recurrence_dow=='SA'">Saturday</option>
          <option value="day" [selected]="schedule.mastering.recurrence_dow=='day'">Day</option>
        </select>
        <label>of every</label>
        <input type="text" name="mastering_month_2" [(ngModel)]="schedule.mastering.months_2" value="{{schedule.mastering.months_2}}"/>
        <label>month(s)</label>
      </div>
      <div class="popup_btns">
        <button type="button" class="cancel" (click)="masteringPopupClose(0)">Cancel</button>
        <button type="submit" (click)="masteringPopupClose(1)">Save</button>
      </div>
    </form>
  </div>
</div>
<app-alert></app-alert>
<app-confirm (isConfirm)="isConfirm($event)"></app-confirm>
<div class="fade" [ngClass]="{opened: isLoad}"></div>
<img src="../../assets/img/loader.gif" alt="loader" class="loader" [ngClass]="{active:isLoad}"/>
