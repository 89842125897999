<div class="page" *ngIf="isLogged; else elseBlock">
  <app-navigation *ngIf="!hideNavigation"></app-navigation>
  <div class="content"  >
    <router-outlet ></router-outlet>
  </div>
</div>
<ng-template #elseBlock>
  <div class="page logout">
    <router-outlet></router-outlet>
  </div>
</ng-template>
<app-alert></app-alert>
