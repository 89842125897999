<button (click)="import_client_playlist()"  title="Import client playlist">Import CP</button>
<div class="popup create_playlist_popup" [ngClass]="{opened: importPopUpOpen}" ngDraggable ngResizable
    [rzHandles]="'n,e,s,w,se,sw,ne,nw'" [handle]="dragHandle">
    <a href="#" class="popup_close" (click)="popupClose($event)"></a>
    <div class="popup_title" #dragHandle>Import Client Playlist</div>
    <div class="popup_form">
        <form #importCLientPlaylist="ngForm">
            <input type="hidden" name="status" value="playlistFields.status" [(ngModel)]="playlistFields.status">
            <input type="hidden" name="country_code" value="GBR" [(ngModel)]="playlistFields.countrycode">
        <input type="hidden" name="user_id" *ngIf="playlistFields.id != null" value="{{playlistFields.user}}" [(ngModel)]="playlistFields.user"/>

            <div class="input_wrap" *ngIf="playlistFields.id == null">
                <label>Client</label>
                <select name="client_id" *ngIf="usersList!=null" [(ngModel)]="playlistFields.user"
                    (change)="userChange($event, 2)">
                    <option value="null" [selected]="playlistFields.user==null">Select Client...</option>
                    <option *ngFor="let u of usersList" value="{{u.id}}" [selected]="playlistFields.user == u.id">
                        {{u.first_name}}</option>
                </select>
            </div>
            <div class="input_wrap">
                <label>Site</label>
                <select name="site_id" [(ngModel)]="playlistFields.site" (change)="categoryChange($event,1)">
                    <option value="null" [selected]="playlistFields.site==null">Select Site...</option>
                    <option *ngFor="let site of playlistFields.sitesList" value="{{site.id}}"
                        [selected]="playlistFields.site==site.id">{{site.name}}</option>
                </select>
            </div>
            <div class="input_wrap">
                <label>Channel</label>
                <select name="chanel_id" [(ngModel)]="playlistFields.chanel">
                    <option value="null" [selected]="playlistFields.chanel==null">Select Channel...</option>
                    <option *ngFor="let c of playlistFields.channelsList" value="{{c.id}}"
                        [selected]="playlistFields.chanel==c.id">{{c.name}}</option>
                </select>
            </div>

            <div class="statusText">
                <p>The uploaded file should have Title, Artist, Album columns.The order of the columns should be Title, Artist, Album.</p>
            </div>
            <dropzone  
                ngDefaultControl
                name="name" 
                [(ngModel)]="playlistFields.name" 
                value="playlistFields.name" 
                *ngIf="playlistFields.chanel" 
                [config]="config" 
                [message]="'Click or drag files here to upload'" 
                (error)="onUploadError($event)"
                (success)="onUploadSuccess($event)" 
                (addedFile)="addFile($event)"
                >
            </dropzone>
            <div class="popup_btns">
                <button *ngIf="matchedTracks?.length > 0" type="button" class="cancel"
                    (click)="matchedtrackspopUp()">Matched Tracks</button>
                <button *ngIf="unmatchedTracks?.length > 0" type="button" class="cancel"
                    (click)="unmatchedtrackspopUp()">Unmatched Tracks </button>
                <button *ngIf="matchedTracks?.length > 0" type="submit" class="cancel"
                    (click)="createClientPlaylist($event,importCLientPlaylist)">Create CP</button>
                <button type="button" class="cancel" (click)="popupClose($event)">Cancel</button>
                <!-- <button type="submit" (click)="">Save</button> -->
            </div>
        </form>
    </div>
</div>
<!-- matched track open popup -->


<div class="popup matchedtrackopenpopup" [ngClass]="{ opened: matchedtrackopenpopup }" ngDraggable ngResizable
    *ngIf="matchedTracks != null" [rzHandles]="'n,e,s,w,se,sw,ne,nw'" [handle]="dragHandle6">
    <a href="#" class="popup_close" (click)="popupClosematchdata($event)"></a>
    <div class="popup_title" #dragHandle6>Matched Tracks</div>
    <div class="">
        <div style="text-align: right;padding: 5px 10px;">
            <button title="export" (click)="exportMatchedTracks()">Export</button>
        </div>
        <div class="table_titles">
            <div style="width: 40%; max-width:40%" class="order">Track Name</div>
            <div style="width: 30%; max-width: 30%" class="order">Artist</div>
            <div style="width: 30%; max-width: 30%" class="order">Album</div>
        </div>
        <div class="table_content selected_track_list" style="padding-bottom: 35px">
            <div class="table_row" *ngFor="
            let tc of matchedTracks" style="text-transform: capitalize;">
                <div style="width: 40%; max-width: 40%">{{ tc.title }}</div>
                <div style="width: 30%; max-width: 30%; display: inline-grid">
                    {{ tc.artist }}
                </div>
                <div style="width: 20%; max-width: 20%; display: inline-grid">
                    {{ tc.album }}</div>
            </div>
        </div>
    </div>    
</div>

<!-- unmatched tracks open popup -->

<div class="popup matchedtrackopenpopup" [ngClass]="{ opened: unmatchedtrackopenpopup }" ngDraggable ngResizable
    *ngIf="unmatchedTracks != null" [rzHandles]="'n,e,s,w,se,sw,ne,nw'" [handle]="dragHandle7">
    <a href="#" class="popup_close" (click)="popupunClosematchdata($event)"></a>
    <div class="popup_title" #dragHandle7>Unmatched Tracks</div>
    <div class="">
        <div style="text-align: right;padding: 5px 10px;">
            <button title="export" (click)="exportNotMatchedTracks()">Export</button>
        </div>
        <div class="table_titles">
            <div style="width: 40%; max-width:40%" class="order">Track Name</div>
            <div style="width: 30%; max-width: 30%" class="order">Artist</div>
            <div style="width: 30%; max-width: 30%" class="order">Album</div>
        </div>
        <div class="table_content selected_track_list" style="padding-bottom: 35px">
            <div class="table_row" *ngFor="
            let tc of unmatchedTracks" style="text-transform: capitalize;">
                <div style="width: 40%; max-width: 40%">{{ tc.title }}</div>
                <div style="width: 30%; max-width: 30%; display: inline-grid">
                    {{ tc.artist }}
                </div>
                <div style="width: 20%; max-width: 20%; display: inline-grid">
                    {{ tc.album }}</div>
            </div>
        </div>
        <div clas="text-right">
            <button title="export" (click)="exportNotMatchedTracks()">Export</button>
        </div>
    </div>    
</div>


<app-alert></app-alert>