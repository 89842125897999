import {
  Component,
  Injectable,
  OnInit,
  ViewChild,
  Input,
  Output, EventEmitter
} from "@angular/core";
import { AlertComponent } from "../alert/alert.component";
import {
  DropzoneComponent,
  DropzoneConfigInterface,
  DropzoneDirective,
} from "ngx-dropzone-wrapper";
import { AdminFunctionsComponent } from "../admin-functions/admin-functions.component";
import { Globals } from "../globals";
import { CommonService } from "../services/common.service";
import { CPPlaylistCategoriesService } from "../playlist-categories/cp_playlist-categories.service";
import { CPPlaylistsService } from "../services/cp_playlists.service";
import { NgForm } from "@angular/forms";
import { ExcelService } from "../services/excel.service";

@Component({
  selector: "app-import-client-playlist",
  templateUrl: "./import-client-playlist.component.html",
  styleUrls: ["./import-client-playlist.component.scss"],
  providers: [CPPlaylistCategoriesService,
     CommonService,
     CPPlaylistsService,
     AdminFunctionsComponent,
     ExcelService
    ],
})

 

@Injectable()
export class ImportClientPlaylistComponent implements OnInit {
  popupOpened = false; //Open popup for upload tracks
  config: DropzoneConfigInterface; //Dropzone component
  @ViewChild(DropzoneComponent, { static: true })
  componentRef?: DropzoneComponent; //Dropzone component
  @ViewChild(DropzoneDirective) directiveRef?: DropzoneDirective; //Dropzone component
  @ViewChild(AlertComponent, { static: true }) alert: AlertComponent; //Component of alert popup

  @Output() afterUpload: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private commonService: CommonService,
    private chanelsService: CPPlaylistCategoriesService,
    private globals: Globals,
    private adminFunctionsComponent: AdminFunctionsComponent,
    private playlistServ: CPPlaylistsService,
    private excelService: ExcelService
  ) {}

  ngOnInit() {
    this.commonService.getCurrentLoggedUser().subscribe((current_user) => {
      this.current_user = current_user;
      this.updateClientsList();
    });

    this.config = {
      // Change this to your upload POST address:
      // url: "http://localhost:8000/api/V1/tracks/file/import_cp_playlist",
      url: '/api/V1/tracks/file/import_cp_playlist',
      maxFilesize: 512,
      maxFiles:1,
      method: "POST",
      timeout: 900000, // 15 minutes
      parallelUploads: 1,
      headers: {
        Authorization: "Bearer " + this.globals.access_token,
      },
    };
  }
 
  @Input() datapasstochild:[];
  isFocused = false;
  current_user = null; // information of logged user
  usersList: any = Array(); //List of Clients
  usersListRaw: any = Array();
  public isLoad: boolean = false;
  matchedTracks=[];
  unmatchedTracks=[];
  paginationpage: number = 1;
  unmatchpaginationpage: number = 1;
  addTrackslist=null;
  /**
    Set param if input focused
    */
  focusInput() {
    this.isFocused = true;
  }
  /**
   Set param if input blured
   */
  blurInput() {
    this.isFocused = false;
  }
  importPopUpOpen = false;
  matchedtrackopenpopup=false;
  unmatchedtrackopenpopup=false;

  checkedUserId = {
    id: null,
    name: "",
  };
  /*
Channel information in edit popup
{
  id - channel id,
  name - channel name,
  visible - channel activity,
  site - site id,
  user - client id
  sitesList - list of sites for select
}
 */
  chanelFields = {
    id: null,
    name: "",
    visible: 1,
    site: null,
    user: null,
    sitesList: [],
  };
  playlists; //List of playlists
  /*
  Playlilst information in edit popup
  {
    id - playlist id,
    name - playlist name,
    status - playlist activity,
    channel - selected channel for playlist,
    site - selected site for playlist,
    description - playlist description,
    user - Client id,
    sitesList - sites list for select,
    channelsList - channels list for select
  }
   */
  playlistFields = {
    id: null,
    name: "",
    status: 1,
    chanel: null,
    site: null,
    description: "",
    user: null,
    sitesList: [],
    channelsList: [],
    countrycode:'GBR',
  };

  /**
  Count files for uploading
  {
   added - count of added files,
   error - count of files uploaded with errors,
   success - count of files with success upload
 }
  */
  filesCount = {
    added: 0,
    error: 0,
    success: 0,
  };
  addCount = {
    count: 1,
    counter: 1,
    errors: 0
  };
  export_data: any = [];
  showbutton = false;
  full_file_information: any;
  import_client_playlist() {
    if (this.playlistFields.id == null) {
        this.playlistFields.user = this.datapasstochild['user'];
        this.updateSites(this.playlistFields.user, 2);
        this.playlistFields.site = this.datapasstochild['site'];
        this.updateChanels(this.playlistFields.site, 1);
        this.playlistFields.chanel=this.datapasstochild['channel'];
    }
    this.importPopUpOpen = !this.importPopUpOpen;
    return false;
  }

  popupClose(e: any) {
    this.importPopUpOpen = false;
    this.playlistFields.user=null;
    this.playlistFields.site=null;
    this.playlistFields.chanel=null;
    this.matchedTracks=null;
    this.unmatchedTracks=null;
    this.blurInput();
    return false;
  }

  /**
  Get list of Clients
  */
  updateClientsList() {
    this.chanelsService.getClientsList().subscribe((clients) => {
      this.usersList = clients;
      this.usersListRaw = clients;

      if (this.checkedUserId.id && this.checkedUserId.name == "") {
        let user = this.usersList.find((x) => x.id == this.checkedUserId.id);
        this.checkedUserId.name = user.first_name;
      }
      this.isLoad = false;
    });
  }

  /**
   Actions when changed user in select
   * @param e - event
   * @param num - type of action in popup
   */
  userChange(e, num) {
    if (e.target.value == null) {
      if (num == 2) {
        
        this.playlistFields.sitesList = [];
      }
    } else {
      this.updateSites(e.target.value, num);
    }
  }

  /**
  Get sites list
  * @param uid - user id
  * @param num - number of edit popup
  */
  updateSites(uid, num) {
   
    this.chanelsService.getSitesByUser(uid).subscribe((sites: any) => {
      if (num == 1) {
        this.chanelFields.sitesList = sites;
      } else if (num == 2) {
        this.playlistFields.sitesList = sites;
      }
    });
  }

  /**
 Actions when changed site in select
 * @param e - event
 * @param num - type of action
 */
  categoryChange(e, num) {
    if (e.target.value == null || e.target.value == "null") {
      if (num == 1) {
        this.playlistFields.channelsList = [];
        this.playlistFields.chanel = null;
      }
    } else {
      this.updateChanels(e.target.value, num);
    }
  }

  /**
  Get channels list
  * @param sid - site id
  * @param num - type of action
  */
  updateChanels(sid, num) {
    this.chanelsService
      .getPlaylistsCategories(sid)
      .subscribe((chanels: any) => {
        if (num == 1) {
          this.playlistFields.channelsList = chanels.children;
        }
      });
  }

  /**
  Display error if upload multiple files
  */
  showMultipleError() {
    this.alert.alertOpen(this.filesCount.error + " file(s) not uploaded", 1);
  }

  /**
 Reset counter
 */
  resetFilesCount() {
    this.filesCount.added = 0;
    this.filesCount.error = 0;
    this.filesCount.success = 0;
  }

  /**
 Add file to counter
 */
  addFile() {
    this.filesCount.added += 1;
  }

  onUploadError(e: any) {
    this.filesCount.error += 1;
    var file_info = JSON.parse(e[2].responseText);
    if (file_info.info != undefined) {
      var full_file_information = {
        title: file_info.info.title,
        artist: file_info.info.artist,
        album: file_info.info.album,
        failed_reason: file_info[1],
      };
      this.export_data.push(full_file_information);
      this.showbutton = true;
    }
    if (
      this.filesCount.added ==
      this.filesCount.error + this.filesCount.success
    ) {
      if (this.filesCount.added > 1) {
        this.showMultipleError();
      } else {
        if (
          this.filesCount.added ==
          this.filesCount.error + this.filesCount.success
        ) {
          try {
            var error = JSON.parse(e[2].responseText);
            this.alert.alertOpen(error[1], 1);
          } catch (e) {
            this.alert.alertOpen("Unknown error", 1);
          }
        }
      }
      this.resetFilesCount();
    }
  }

  /**
  Event if file uploaded correct
  * @param e - event
  */
  onUploadSuccess(e) {
    console.log(e[1]);
    this.playlistFields.name=e[1].filename;
    if(e[1].matchedData.length>0){
      this.addTrackslist=e[1].matchFilesdataFromDb;
      this.matchedTracks=e[1].matchedData;
    }
    if(e[1].unmatchedData.length>0){
      this.unmatchedTracks=e[1].unmatchedData;
    }
    
    this.playlistFields.name=e[1].filename;
    this.filesCount.success += 1;
    if (
      this.filesCount.added ==
      this.filesCount.error + this.filesCount.success
    ) {
      if (this.filesCount.error > 0) this.showMultipleError();
      this.resetFilesCount();
    }
  }

  matchedtrackspopUp(){
    this.matchedtrackopenpopup = !this.matchedtrackopenpopup;
    return false;

  }
  popupClosematchdata(e:any){
    this.matchedtrackopenpopup = false;
    this.blurInput();
    return false;
  }

  unmatchedtrackspopUp(){
    this.unmatchedtrackopenpopup=!this.unmatchedtrackopenpopup;
    return false;
  }

  popupunClosematchdata(e:any){
    this.unmatchedtrackopenpopup = false;
    this.blurInput();
    return false;
  }

  createClientPlaylist(e, form: NgForm){
    var data={};
    if (this.playlistFields.chanel == null ) {
      this.alert.alertOpen("Please select Site and Channel", 1);
      return false;
    } 
    var str = Object.keys(form.value)
    .map(function (key) {
      var val = encodeURIComponent(form.value[key]);
      if (val == "null" || val == null) {
        val = "";
      }
      return encodeURIComponent(key) + "=" + val;
    })
    .join("&");
  
    console.log(str);
    this.playlistServ
    .createPlaylist(this.playlistFields.chanel, str)
    .subscribe((playlists: any) => {
      if (playlists.error == undefined) {
        this.addtrackInPlaylist(this.addTrackslist,playlists.id,true);
        this.alert.alertOpen("Playlist added", 0);
      } else {
        this.alert.alertOpen(playlists.error, 1);
      }
    });
  }
 /**
   Add track to playlist
   * @param tid - track id
   * @param pid - playlist id
   * @param once(boolean) - is adding single track or multiple
   * @returns {boolean}
   */
  addtrackInPlaylist(tracklist,plId,once){
    var fpid = null;
    tracklist.forEach((element, index, array) => {
      console.log(element);
      this.playlistServ.addTrackToPlaylist(plId, element,fpid).subscribe((tracks:any) => {
        if(once){
          this.addCount.counter += 1;
        }
        if(tracks.error != undefined){
          if(once){
            this.addCount.errors += 1;
          }
          else{
            this.alert.alertOpen(tracks.error,1);
          }
        }
      });
      if (index === (array.length -1)) {
        this.afterUpload.emit();
      }
    });
  }

  exportMatchedTracks(){
    const newArray = this.matchedTracks.map(({ 
                      // id,
                      // file_name,
                      ...item 
                    }) => item);
    this.excelService.exportAsExcelFile(newArray, "Matched tracks");
  }

  exportNotMatchedTracks(){
    const newArray = this.unmatchedTracks.map(({ 
                      // id,
                      // file_name,
                      ...item 
                    }) => item);
    this.excelService.exportAsExcelFile(newArray, "Not matched tracks");
  }

}
