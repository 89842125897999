// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  authorization_token: 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6IjE0NTA5OTQ0YmU0MjhkNjM4N2QzYzMyMDExODFmMTJkY2Q4MjUyNGI3YmQyNGQzOGY3NDVjZWE4NDU1MTVkOTEwOWEzNjk0NzA5NzliOGUyIn0.eyJhdWQiOiIxIiwianRpIjoiMTQ1MDk5NDRiZTQyOGQ2Mzg3ZDNjMzIwMTE4MWYxMmRjZDgyNTI0YjdiZDI0ZDM4Zjc0NWNlYTg0NTUxNWQ5MTA5YTM2OTQ3MDk3OWI4ZTIiLCJpYXQiOjE1MzI2ODUyODcsIm5iZiI6MTUzMjY4NTI4NywiZXhwIjoxNTY0MjIxMjg3LCJzdWIiOiIyIiwic2NvcGVzIjpbIioiXX0.JWSirOLzQselSaSncIZJ5ztTwT2MnWdj74OA30b3n_dk1qlf1BdC2et3OSg4o2CHtXf2oRP_-zVfco5RnwDaU0uBQpBTUYE91Ty_WY98QQWu5byO9EEYvAKElkEzx9QJo_GNPQRXxoVg-pkda_ldymnNe4-n0F0ptram9zaeiklKZEqsRqEJhGITRCKFMF2M2JaT6m2Meah5K-7qL97tsnairsJExDQvqm5lpySBJPDMkIRxCeQe4Lk5d4BZUW9znrEE6K3etxGBUhKA9E73vXb0fmJRKWFTwLkkMH9XC-KNUjiw7dm0EzVvO20MoGg_OkFJd8K9PeeUDaCM8BZdXJh-BbRwFwAN-vPhKvEhFE91aJ_3l5RZEeh_MMobq3MzwzZJwMYZm2cV5WxV9CnswudScfzPYM68c3ZwrXv3Y2UWAEfEf-ugOuiwIrryA-5wVwaZn3NBvFGIfYALbNEYMhVn_8oo92Mceww2VZc6vDltdYzOOl1UBjpVs2KxjC5RtDNlcajubCvGZOlmQFRvi44HgSelgwAYYUxjpr5WvHJZXWJ695O201hST3uHILoBsjLEPZqmJDmI4LO6cbkO3oVQfhzX5MgOnUD8-fwsOAX1UiNCxs57rP-61xAAF5tjHph3tlCNj3-cq5g_eJOtYk8ojs0ALS0PQijVOIJcHnM',
  dev_url_prefix: ''
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

