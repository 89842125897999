import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sample-playlists',
  templateUrl: './sample-playlists.component.html',
  styleUrls: ['./sample-playlists.component.scss']
})
export class SamplePlaylistsComponent implements OnInit {

  ngOnInit() {
    // Do nothing
  }

}
