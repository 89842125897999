<app-page-header [pageTitle]="'Create, edit and manage your playlists'"></app-page-header>
<div class="top_box">
  <div class="mf_buttons">
    <div class="search_box">
      <form method="get" #searchForm="ngForm">
        <input type="text" name="search" value="" placeholder="Schedule search" class="searchInput"/>
        <button type="submit" class="searchBtn"></button>
        <a href="#" class="clear_search" (click)="clearSearch($event)"></a>
      </form>
    </div>
    <div class="buttons_box">
      <a href="#" class="add_schedule btn" (click)="openCreateSchedulePopup()"></a>
      <a href="#" class="back_btn btn" (click)="backBtnClick()"></a>
    </div>
  </div>
</div>
<div class="schedule_main">
  <div class="left_block">
    <div class="schedules_list box">
      <ul>
        <li *ngFor="let sch of schedulesList" [ngClass]="{opened: checkedScheduleId == sch.id}" (click)="selectSchedule(sch.id,sch.playlist?.profile_type)">
          <span>{{sch.name}}</span>
          <div class="actions_wrap">
            <a href="#" class="arrow"></a>
          </div>
        </li>
      </ul>
    </div>
  </div>
  <div class="playlists_list_block">
    <div class="schedule_wrap">
      <app-cp-schedule [sheduleId]="checkedScheduleId" [channelId]="channelId"></app-cp-schedule>
    </div>
  </div>
</div>
<div class="popup create_schedule_popup" [ngClass]="{opened: popups.schedulePopupOpened}" ngDraggable ngResizable [rzHandles]="'n,e,s,w,se,sw,ne,nw'" [handle]="dragHandle">
  <a href="#" class="popup_close" (click)="popupClose()"></a>
  <div class="popup_title" #dragHandle>Create Schedule</div>
  <div class="popup_form">
    <form #createScheduleForm="ngForm">
      <div class="input_wrap">
        <label>Schedule Name</label>
        <input type="text" name="name" value="{{scheduleFields.name}}" [(ngModel)]="scheduleFields.name"/>
      </div>
      <div class="input_wrap">
        <label>Playlists</label>
        <select name="cp_playlist" *ngIf="playlistsList!=null" [(ngModel)]="scheduleFields.playlistID">
          <option *ngFor="let p of playlistsList" value="{{p.id}}" [selected]="scheduleFields.playlistID == p.id">{{p.name}}</option>
        </select>
      </div>
      <div class="popup_btns">
        <button type="button" class="cancel" (click)="popupClose()">Cancel</button>
        <button type="submit" (click)="createSchedule($event,createScheduleForm)">Save</button>
      </div>
    </form>
  </div>
</div>
<app-alert></app-alert>
