<div class="player" id="player">
  <a href="#" (click)="prevTrack()" class="prev_track" title="Backward"></a>
  <a href="#" [ngClass]="isPlaying ? 'btn_pause' : 'btn_play' " (click)="startPlay()" title="Play"></a>
  <a href="#" (click)="stop()" class="stop_track" title="Stop"></a>
  <a href="#" class="next_track" (click)="nextTrack()" title="Forward"></a>
  <a href="#" class="s30" (click)="add30sec()" *ngIf="isPlaying" title="+30 seconds">&gt;30s</a>
  <a href="#" (click)="mute()" class="volume" title="Mute"></a>
  

  <div class="artist_info" *ngIf="isPlaying||isPause">
    <span #player_artist [ngClass]="{ marque: isLong( player_artist, artist_inner ) }">
      <span #artist_inner class="marque_inner">
        <span class="marque_content">{{playerObj.artist}}</span></span>
    </span>
    
    <i #player_album [ngClass]="{ marque: isLong( player_album, album_inner ) }">
      <span #album_inner class="marque_inner">
        <span class="marque_content">{{playerObj.album}}</span>
      </span>
    </i>
  </div>
  
  <div class="track_time">
    <span class="duration">{{convertTime(duration)}}</span>
  </div>
  <div class="progresbar_wrapper" id="progressbar" [ngStyle]="{'background-image': 'url(/' + playerObj.storage + '/waves/'+ playerObj.wave +'_white.png)'}">
    <div class="progressbar" (click)="setPosition($event)">
      <div class="inner" [ngStyle]="{'width.px': innerWidth, 'background-image': 'url(/' + playerObj.storage + '/waves/'+ playerObj.wave +'_red.png)'}"></div>
    </div>
  </div>
  
  <div class="track_time">
    <span class="remaining_time">{{convertTime(duration-playingSec)}}</span>
  </div>
</div>