import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { environment } from "../../environments/environment";
import { Globals } from "../globals";

@Injectable()
export class PlaylistsService {
  httpOptions = {
    headers: new HttpHeaders({
      Host: environment.dev_url_prefix + "/",
      Authorization: "Bearer " + this.globals.access_token,
      Accept: "application/json"
    })
  };
  postOptions = {
    headers: new HttpHeaders({
      Host: environment.dev_url_prefix + "/",
      Authorization: "Bearer " + this.globals.access_token,
      Accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded"
    })
  };
  deleteOptions = {
    headers: new HttpHeaders({
      Host: environment.dev_url_prefix + "/",
      Authorization: "Bearer " + this.globals.access_token,
      Accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded"
    })
  };

  constructor(private http: HttpClient, private globals: Globals) {}

  /**
   Get playlist information
   * @param pid - playlist id
   * @returns {Observable<any>}
   */
  getPlaylistById(pid) {
    return this.http
      .get(
        environment.dev_url_prefix + "/api/V1/fp_playlists/read/" + pid,
        this.httpOptions
      )
      .pipe(
        map(playlist => {
          return playlist;
        })
      );
  }
  /**
   Get playlists from channel
   * @param pid - sub category id
   * @returns {Observable<any>}
   */
  getPlaylists(pid) {
    var str = "";
    if (pid != null) {
      str = "/" + pid;
    }
    return this.http
      .get(
        environment.dev_url_prefix + "/api/V1/fp_playlists/list" + str,
        this.httpOptions
      )
      .pipe(
        map(playlists => {
          return playlists;
        })
      );
  }
  /**
   Create new playlist
   * @param pid - channel id
   * @param formData - post data
   * @returns {Observable<any>}
   */
  createPlaylist(pid, formData) {
    return this.http
      .post(
        environment.dev_url_prefix + "/api/V1/fp_playlists/create/" + pid,
        formData,
        this.postOptions
      )
      .pipe(
        map(playlists => {
          return playlists;
        })
      );
  }
  /**
   Update playlist
   * @param cat_id - channel id
   * @param pid - playlist id
   * @param formData - post data
   * @returns {Observable<any>}
   */
  updatePlaylist(pid, formData) {
    return this.http
      .put(
        environment.dev_url_prefix + "/api/V1/fp_playlists/update/" + pid,
        formData,
        this.postOptions
      )
      .pipe(
        map(playlists => {
          return playlists;
        })
      );
  }
  /**
   Delete playlist
   * @param pid - playlist id
   * @returns {Observable<any>}
   */
  deletePlaylist(pid) {
    return this.http
      .delete(
        environment.dev_url_prefix + "/api/V1/fp_playlists/delete/" + pid,
        this.deleteOptions
      )
      .pipe(
        map(playlistDelete => {
          return playlistDelete;
        })
      );
  }
  /**
   Add new track to playlist
   * @param pid - playlist id
   * @param tid - track id
   * @returns {Observable<any>}
   */
  addTrackToPlaylist(pid, tid) {
    return this.http
      .post(
        environment.dev_url_prefix +
          "/api/V1/fp_playlists/file/add/" +
          pid +
          "/" +
          tid,
        "",
        this.postOptions
      )
      .pipe(
        map(tracks => {
          return tracks;
        })
      );
  }
  /**
   Get all tracks in playlist
   * @param pid - playlist id
   * @param order - order of diplaying tracks
   * @param orderBy - ordering rule
   * @returns {Observable<any>}
   */
  getPlaylistTracks(pid, order, orderBy) {
    return this.http
      .get(
        environment.dev_url_prefix +
          "/api/V1/fp_playlists/files/" +
          pid +
          "/" +
          orderBy +
          "/" +
          order,
        this.httpOptions
      )
      .pipe(
        map(tracks => {
          return tracks;
        })
      );
  }
  /**
   Delete track from playlist
   * @param pid - playlist id
   * @param tid - track id
   * @returns {Observable<any>}
   */
  deletePlaylistTrack(pid, tid) {
    return this.http
      .delete(
        environment.dev_url_prefix +
          "/api/V1/fp_playlists/file/delete/" +
          pid +
          "/" +
          tid,
        this.deleteOptions
      )
      .pipe(
        map(trackDelete => {
          return trackDelete;
        })
      );
  }
  /**
   Set status for playlist
   * @param pid - playlist id
   * @param statusId - status id
   * @returns {Observable<any>}
   */
  setStatus(pid, statusId) {
    return this.http
      .get(
        environment.dev_url_prefix +
          "/api/V1/fp_playlists/status_change/" +
          pid +
          "/" +
          statusId,
        this.httpOptions
      )
      .pipe(
        map(status => {
          return status;
        })
      );
  }
  /**
   Search playlist
   * @param str - search string
   * @returns {Observable<any>}
   */
  searchPlaylists(str) {
    return this.http
      .get(
        environment.dev_url_prefix + "/api/V1/fp_playlists/search/" + str,
        this.httpOptions
      )
      .pipe(
        map(playlists => {
          return playlists;
        })
      );
  }

   /**
   Search playlist
   * @param str - search string
   * @returns {Observable<any>}
   */
  searchPlaylistsFP(str) {
    return this.http
      .get(
        environment.dev_url_prefix + "/api/V1/foundations/fp_category_search/" + str,
        this.httpOptions
      )
      .pipe(
        map(category => {
          return category;
        })
      );
  }

     /**
   Search playlist
   * @param str - search string
   * @returns {Observable<any>}
   */
  searchPlaylistsCP(str) {
    return this.http
      .get(
        environment.dev_url_prefix + "/api/V1/foundations/cp_search/" + str,
        this.httpOptions
      )
      .pipe(
        map(category => {
          return category;
        })
      );
  }

  /**
   Get all playlists with user accesses
   * @param uid - user id
   * @returns {Observable<any>}
   */
  getAllPlaylists(uid) {
    var str = uid;
    if (uid != "") {
      str = "/" + uid;
    }
    return this.http
      .get(
        environment.dev_url_prefix + "/api/V1/fp_playlists/list/users" + uid,
        this.httpOptions
      )
      .pipe(
        map(playlists => {
          return playlists;
        })
      );
  }

  /**
   Get linked Client playlists
   * @param pid - playlist id
   * @returns {Observable<any>}
   */
  getLinkedPlaylists(pid) {
    return this.http
      .get(
        environment.dev_url_prefix + "/api/V1/fp_playlists/cp_list/" + pid,
        this.httpOptions
      )
      .pipe(
        map(playlists => {
          return playlists;
        })
      );
  }
  /**
   Search tracks in playlist
   * @param str - search string
   * @param pid - playlist id
   * @returns {Observable<any>}
   */
  search(str, pid) {
    return this.http
      .get(
        environment.dev_url_prefix +
          "/api/V1/fp_playlists/track_search/" +
          str +
          "/" +
          pid +
          "/0/100",
        this.httpOptions
      )
      .pipe(
        map(tracks => {
          return tracks;
        })
      );
  }
  getarchivePlaylists(pid) {
    var str = "";
    if (pid != null) {
      str = "/" + pid;
    }
    return this.http
      .get(
        environment.dev_url_prefix + "/api/V1/fp_playlists/archivelist" + str,
        this.httpOptions
      )
      .pipe(
        map(playlists => {
          return playlists;
        })
      );
  }
}
