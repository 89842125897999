<div class="page_wrap">
  <app-page-header [pageTitle]="'Create, edit and manage your playlists'"></app-page-header>
  <form #newUserForm="ngForm" class="forgot_form">
    <h2>Send request for admin</h2>
    <div class="input_wrap">
      <label>First name</label>
      <input type="text" value="{{first_name}}" name="first_name" [(ngModel)]="first_name"/>
    </div>
    <div class="input_wrap">
      <label>Last name</label>
      <input type="text" value="{{last_name}}" name="last_name" [(ngModel)]="last_name"/>
    </div>
    <div class="input_wrap">
      <label>Email</label>
      <input type="text" value="{{email}}" name="email" [(ngModel)]="email"/>
    </div>
    <div class="popup_btns">
      <button type="submit" (click)="newUserSend($event,newUserForm)">Send</button>
      <a routerLink="/login">Back to homepage</a>
    </div>
  </form>
</div>
<app-alert></app-alert>
