import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {map} from 'rxjs/operators';
import { environment } from '../../environments/environment';
import {Globals} from '../globals';

@Injectable()
export class ReportsService {

  httpOptions = {
    headers: new HttpHeaders({
      'Host': environment.dev_url_prefix + '/',
      'Authorization': 'Bearer ' + this.globals.access_token,
      'Accept': 'application/json'
    })
  };
  putOptions = {
    headers: new HttpHeaders({
      'Host': environment.dev_url_prefix + '/',
      'Authorization': 'Bearer ' + this.globals.access_token,
      'Accept': 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded'
    })
  };
  postOptions={
    headers: new HttpHeaders({
      'Host': environment.dev_url_prefix + '/',
      'Authorization': 'Bearer ' + this.globals.access_token,
      'Accept': 'application/json',
      'Content-Type':'application/x-www-form-urlencoded'
    })
  }
  deleteOptions = {
    headers: new HttpHeaders({
      'Host': '/',
      'Authorization': 'Bearer ' + this.globals.access_token,
      'Accept': 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded'
    })
  };

  constructor(
    private http: HttpClient,
    private globals: Globals
  ) { }

  /**
   Get all tracks
   * @param order - order of diplaying tracks
   * @param orderBy - ordering rule
   * @param offet - count for offset tracks
   * @param limit - limit tracks
   * @returns {Observable<any>}
   */
  getReportList(type) {
    return this.http.get(environment.dev_url_prefix + '/api/V1/reports/' + type , this.httpOptions).pipe(map(list => {
      return list;
    }));
  }

  getPlaylists() {
    return this.http
      .get(
        environment.dev_url_prefix + "/api/V1/fp_playlists/list",
        this.httpOptions
      )
      .pipe(
        map(playlists => {
          return playlists;
        })
      );
  }

  searchReport(formData){
      return this.http.post(environment.dev_url_prefix + '/api/V1/reports/search',formData,this.postOptions).pipe(map(res => {
        return res;
      }));
  }
 
}
