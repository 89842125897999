
import {Component, OnInit, ViewChild, Output, EventEmitter} from '@angular/core';
import {DropzoneComponent, DropzoneConfigInterface, DropzoneDirective} from 'ngx-dropzone-wrapper';
import {AppComponent} from '../app.component';
import { ClientPlaylistsComponent } from "../client-playlists/client-playlists.component";
import {Globals} from '../globals';
import {AlertComponent} from '../alert/alert.component';
import {CPPlaylistsService} from '../services/cp_playlists.service';

@Component({
  selector: 'app-upload-files',
  templateUrl: './upload-files.component.html',
  styleUrls: ['./upload-files.component.scss'],
  providers:[CPPlaylistsService]
})
export class UploadFilesComponent implements OnInit {

  popupOpened = false;
  config: DropzoneConfigInterface;
  @ViewChild(DropzoneComponent, { static: true }) componentRef?: DropzoneComponent;
  @ViewChild(DropzoneDirective) directiveRef?: DropzoneDirective;
  @ViewChild(AlertComponent, { static: true }) alert: AlertComponent;
  filesCount = {
    added: 0,
    error: 0,
    success: 0,
  };
  
  documentList : any = Array();
  @Output() onDocumentUpdate: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private appComp: AppComponent,
    private ClientPlaylistsComponent: ClientPlaylistsComponent,
    private globals: Globals,
    private playlistServ: CPPlaylistsService,
  ) { }

  ngOnInit() {
    this.config = {
      // Change this to your upload POST address: 
      url: '/api/V1/Document/file',
      maxFilesize: 512,
      acceptedFiles: 'application/vnd.ms-excel,application/pdf,.csv,.docx,',
      method: 'POST',
      timeout: 180000,
      headers: {
        'Authorization': 'Bearer ' + this.globals.access_token
      }
    };
  }
  // openPopup(){
  //   this.popupOpened=!this.popupOpened;
  // }
  popupClose(e){
    e.preventDefault();
    this.popupOpened=!this.popupOpened;
    this.cancelUpload(e);
  }
  cancelUpload(e){
    e.preventDefault();
    this.componentRef.directiveRef.reset();
    this.popupOpened=!this.popupOpened;
  }
  onUploadError(e){
    
    console.log(e);
    console.log('error');
    this.filesCount.error += 1;
    if(this.filesCount.added == this.filesCount.error + this.filesCount.success){
     if(this.filesCount.added > 1){
       this.showMultipleError();
     }
     else{
       if(this.filesCount.added == this.filesCount.error + this.filesCount.success){
         try {
           var error = JSON.parse(e[2].responseText);
           this.alert.alertOpen(error[1],1);
         } catch (e) {
           this.alert.alertOpen("Unsupported DocumentType Please Check",1);
         }
       }
     }
     this.resetFilesCount();
    }
    this.site_id = this.ClientPlaylistsComponent.PassSiteId();
    this.channel_id = this.ClientPlaylistsComponent.PassChannelId();
    console.log( this.site_id);
   
  }
  onUploadSuccess(e){
    console.log(e);
   
    this.filesCount.success += 1;
    if(this.filesCount.added == this.filesCount.error + this.filesCount.success){
      if(this.filesCount.error>0)
        this.showMultipleError();
      this.resetFilesCount();
    }
    
    this.site_id = this.ClientPlaylistsComponent.PassSiteId();
    this.channel_id = this.ClientPlaylistsComponent.PassChannelId();

    let type = this.ClientPlaylistsComponent.currentEntityType();
    if(type == 'site'){
      this.playlistServ.getClientDocuments(this.site_id ).subscribe(documents => {
        this.documentList = documents;
        this.onDocumentUpdate.emit(documents);
      });
    }
    if(type == 'channel'){
      this.playlistServ.getClientDocumentsChannel(this.channel_id ).subscribe(documents => {
        this.documentList = documents;
        this.onDocumentUpdate.emit(documents);
      });
    }  
  }

  site_id : any;
  year :any;
  month:any;
  type:any;
  channel_id:any;

  onSending(data): void {
   
    // data [ File , xhr, formData]
    this.year = new Date().getFullYear();
    this.month = new Date().getMonth() + 1;

    this.site_id = this.ClientPlaylistsComponent.PassSiteId();
    this.channel_id = this.ClientPlaylistsComponent.PassChannelId();
    
    const formData = data[2];
    formData.append('site_id', this.site_id );
    formData.append('channel_id', this.channel_id);
    formData.append('year', this.year);
    formData.append('month', this.month);
   
  }

 
  addFile(){
    this.filesCount.added += 1;
  }
  
  resetFilesCount(){
    this.filesCount.added = 0;
    this.filesCount.error = 0;
    this.filesCount.success = 0;
  }
  showMultipleError(){
    this.alert.alertOpen(this.filesCount.error + " file(s) not uploaded",1);
  }
}
