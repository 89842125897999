import {Component, OnInit, ViewChild} from '@angular/core';
import {DropzoneComponent, DropzoneConfigInterface, DropzoneDirective} from 'ngx-dropzone-wrapper';
import {AppComponent} from '../app.component';
import {AdminFunctionsComponent} from '../admin-functions/admin-functions.component';
import {Globals} from '../globals';
import {AlertComponent} from '../alert/alert.component';
import { ExcelService } from "../services/excel.service";
import {ConfirmComponent} from '../confirm/confirm.component';

@Component({
  selector: 'app-onboard-tracks',
  templateUrl: './onboard-tracks.component.html',
  styleUrls: ['./onboard-tracks.component.scss'],
  providers: [ExcelService]
})


export class OnboardTracksComponent implements OnInit {
  popupOpened = false;//Open popup for upload tracks
  config: DropzoneConfigInterface;//Dropzone component
  @ViewChild(DropzoneComponent, { static: true }) componentRef?: DropzoneComponent;//Dropzone component
  @ViewChild(DropzoneDirective) directiveRef?: DropzoneDirective;//Dropzone component
  @ViewChild(AlertComponent) alert: AlertComponent;//Component of alert popup
  @ViewChild(ConfirmComponent) confirm: ConfirmComponent; //Component of confirm popup
  /**
   Count files for uploading
   {
    added - count of added files,
    error - count of files uploaded with errors,
    success - count of files with success upload
  }
   */
  filesCount = {
    added: 0,
    error: 0,
    success: 0
  };
  export_data:any=[];
  confirmValues = {
    id: null,
    type: 0
  };
  showbutton=false;
  full_file_information:any;
  constructor(
    private appComp: AppComponent,
    private adminFunctionsComponent: AdminFunctionsComponent,
    private globals: Globals,
    private excelService: ExcelService,
  ) { }

  ngOnInit() {
    this.config = {
      // Change this to your upload POST address:
      //  url: 'http://localhost:8000/api/V1/tracks/file/upload',  
     url: '/api/V1/tracks/file/upload',
      maxFilesize: 512,
      acceptedFiles: 'audio/*',
      method: 'POST',
      timeout: 900000,// 15 minutes
      parallelUploads: 1,
      headers: {
        'Authorization': 'Bearer ' + this.globals.access_token
      }
    };
  }

  /**
   Open dropzone popup
   */
  openPopup(){
    this.showbutton=false;
    this.popupOpened=!this.popupOpened;
  }

  /**
   Close dropzone popup
   * @param e - event
   */
  popupClose(e){
    e.preventDefault();
    this.componentRef.directiveRef.reset();
    this.popupOpened=!this.popupOpened;
    this.export_data=[];
  }

  /**
   Canceling upload filex
   * @param e - event
   */
  cancelUpload(e){
    e.preventDefault();
    this.componentRef.directiveRef.reset();
    this.popupOpened=!this.popupOpened;
   
  }

  /**
   Event if file uploaded with error
   * @param e - event
   */
  onUploadError(e){ 
    this.filesCount.error += 1;
    var file_info=JSON.parse(e[2].responseText);
    if(file_info.info != undefined){
      var full_file_information = {
        "title"   : file_info.info.title,
        "artist"  : file_info.info.artist,
        "album"   : file_info.info.album,
        "failed_reason" : file_info[1]
      };   
      this.export_data.push(full_file_information);
      this.showbutton=true;  
    }
    if(this.filesCount.added == this.filesCount.error + this.filesCount.success){
     if(this.filesCount.added > 1){
       this.showMultipleError();
     }
     else{
       if(this.filesCount.added == this.filesCount.error + this.filesCount.success){
         try {
           var error = JSON.parse(e[2].responseText);
           this.alert.alertOpen(error[1],1);
         } catch (e) {
           this.alert.alertOpen("Unknown error",1);
         }
       }
     }
     this.resetFilesCount();
    }
  }
  /**
   Event if file uploaded correct
   * @param e - event
   */
  onUploadSuccess(e){
    console.log(e);
    this.adminFunctionsComponent.getTracks(false);
    this.filesCount.success += 1;
    if(this.filesCount.added == this.filesCount.error + this.filesCount.success){
      if(this.filesCount.error>0)
        this.showMultipleError();
      this.resetFilesCount();
    }
  }

  /**
   Add file to counter
   */
  addFile(){
    this.filesCount.added += 1;
  }

  /**
   Reset counter
   */
  resetFilesCount(){
    this.filesCount.added = 0;
    this.filesCount.error = 0;
    this.filesCount.success = 0;
  }

  /**
   Display error if upload multiple files
   */
  showMultipleError(){
   
    this.alert.alertOpen(this.filesCount.error + " file(s) not uploaded",1);
  }

  /**
   * 
   * Store failed data in local storage for short term  
   */

  //  SaveDataToLocalStorage(data:any)
  // {
  //     var a = [];
  //     // Parse the serialized data back into an aray of objects
  //     a = JSON.parse(localStorage.getItem('latest_failed_data')) || [];
  //     // Push the new data (whether it be an object or anything else) onto the array
  //     a.push(data);
  //     // Alert the array value
  //     // Re-serialize the array back into a string and store it in localStorage
  //     localStorage.setItem('latest_failed_data', JSON.stringify(a));
  // }



  openConfirmPopup(){
    var  text = "Do you want to export the failed playlist?";
    // this.exportPlaylist();
    this.confirm.confirmOpen(text);
    return false;
  }

  isConfirm(e){
    if(e){
      if( this.showbutton == true )
        this.exportPlaylist();
    }
  }
  /**
   * Download Export Failed Infomation of tracks
   */
  exportPlaylist(): void{
    // const data=JSON.parse(localStorage.getItem('latest_failed_data')) || [];  
    const failed_data="Failed tracks";
    const newArray =this.export_data.map(({ 
      Title,
      Artist,
      Album,
      Reason_for_failed,
      ...item 
    }) => item);
  this.excelService.exportAsExcelFile(newArray, failed_data);
  }
}
