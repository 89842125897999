import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {IMyDpOptions} from 'mydatepicker';
import {ScheduleService} from '../services/schedule.service';
import {CPPlaylistsService} from '../services/cp_playlists.service';
import {AlertComponent} from '../alert/alert.component';
import {ConfirmComponent} from '../confirm/confirm.component';

@Component({
  selector: 'app-cp-schedule',
  templateUrl: './cp-schedule.component.html',
  styleUrls: ['./cp-schedule.component.scss'],
  providers: [ ScheduleService, CPPlaylistsService ]
})
export class CpScheduleComponent implements OnInit {
  @Input() sheduleId = null;//Schedule id
  @Input() channelId = null;//Channel id
  date: any = new Date();//Today date object
  time = {
    h: 6,
    m: 0
  };//Selected time for schedule h-hours, m-minutes
  updatePlaylist;
  timeArr = [];//Array of time for start schedule
  finishTimeArr = [];//Array of time for finish schedule
  firstWeekDay: any = null;//First day of month
  thisWeek: any = [];//dates of current week
  isShowCalendar = true;//Showing calendar
  monthNames = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];//Monthes short names
  monthFullNames = ["January","February","March","April","May","June","July","August","September","October","November","December"];//Monthes full names
  daysNames = ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"];//Days full names
  daysNamesShort = ["SU","MO","TU","WE","TH","FR","SA"];//Days short names
  tab_str = '';//title for tab

  org_begin_hours = ['06','07','08','09','10','11','12','13','14','15','16','17','18','19','20','21','22','23','00','01','02','03','04','05'];
  org_begin_minutes = ['00','01','02','03','04','05','06','07','08','09','10','11','12','13','14','15','16','17','18','19','20','21','22','23','24','25','26','27','28','29','30','31','32','33','34','35','36','37','38','39','40','41','42','43','44','45','46','47','48','49','50','51','52','53','54','55','56','57','58','59'];
  org_finish_hours = ['06','07','08','09','10','11','12','13','14','15','16','17','18','19','20','21','22','23','00','01','02','03','04','05'];
  org_finish_minutes = ['00','01','02','03','04','05','06','07','08','09','10','11','12','13','14','15','16','17','18','19','20','21','22','23','24','25','26','27','28','29','30','31','32','33','34','35','36','37','38','39','40','41','42','43','44','45','46','47','48','49','50','51','52','53','54','55','56','57','58','59'];
  begin_hours = this.org_begin_hours.slice();
  begin_minutes = this.org_begin_minutes.slice();
  finish_hours = this.org_finish_hours.slice();
  finish_minutes = this.org_finish_minutes.slice();
  begin_hours_fr = this.org_begin_hours.slice();
  begin_minutes_fr = this.org_begin_minutes.slice();
  finish_hours_fr = this.org_finish_hours.slice();
  finish_minutes_fr = this.org_finish_minutes.slice();
  /*
   Popups list. Params for opening/closing popups
   */
  popups = {
    recurrencePopupOpened: false,
    masteringPopupOpened: false,
    calendarPopupOpened: false
  };
  /*
    Information in schedule recurrence edit popup
    {
      radio_value - Value for recurence time unit
      daily: {
        days - count of days
      },
      weekly: {
        week - count of weeks
        days - array of days
      },
      monthly: {
        month - count of monthes
        monthSelection - value for selection type,
        days - count of days,
        number - value for recurrence select,
        type - days of week
      },
      yearly: {
        year - count of years,
        monthes - list of monthes,
        monthSelection - value for selection type,
        number- value for recurrence select,
        type - days of week
      }
    }
   */
  recurrence = {
    radio_value: '1',
    daily:{
      days: 1
    },
    weekly:{
      week: 1,
      days: []
    },
    monthly:{
      month: 1,
      monthSelection: '1',
      days:[],
      number: 1,
      type: 'SU'
    },
    yearly:{
      year:1,
      monthes:[],
      monthSelection: false,
      number: 1,
      type: 'SU'
    }
  };
  /*
    Information in mastering edit popup
    {
      m_switch - value of mastering type,
      days - count of days,
      months - count of monthes,
      months_2 - count of monthes,
      every_week_day - value for recurrence select,
      recurrence_dow - days of week
    }
   */
  mastering = {
    m_switch: '0',
    days: '1',
    months: '1',
    months_2: '1',
    every_week_day: '1',
    recurrence_dow: "MO"
  };
  /*
   Schedule information in edit popup
   {
    id - schedule id
    playlistList - playlists for select,
    playlistId - selected playlist id,
    name - schedule name,
    begin_date - schedule start date,
    finish_date - schedule end date,
    begin_time - schedule start time,
    finish_time - schedule end time,
    bgColor - color for background in schedule table,
    txtColor - color for text in schedule table,
    recurrence_fl - value for schedule recurrence,
    random - value for schedule random,
    mastering_fl - value for schedule mastering,
    clash_distance - value for schedule clash distance,
    duration - schedule duration,
    duration_unit - schedule duration time unit,
    artistsNumber - count of artists in schedule,
    recurrence: {
      radio_value - Value for recurence time unit
      daily: {
        days - count of days
      },
      weekly: {
        week - count of weeks
        days - array of days
      },
      monthly: {
        month - count of monthes
        monthSelection - value for selection type,
        days - count of days,
        number - value for recurrence select,
        type - days of week
      },
      yearly: {
        year - count of years,
        monthes - list of monthes,
        monthSelection - value for selection type,
        number- value for recurrence select,
        type - days of week
      }
    },//Recurrence settings
    mastering: {
      m_switch - value of mastering type,
      days - count of days,
      months - count of monthes,
      months_2 - count of monthes,
      every_week_day - value for recurrence select,
      recurrence_dow - days of week
    }//Mastering settings
   }
   */
  schedule = {
    id: null,
    playlistList: [],
    playlistId: null,
    name: '',
    begin_date: {
      year:'',
      month:'',
      day:''
    },
    finish_date: {
      year:'',
      month:'',
      day:''
    },
    begin_time: {
      hours: '00',
      minutes:'00',
    },
    //'00:00',
    finish_time: {
      hours: '00',
      minutes:'00',
    },
    //'00:00',
    bgColor: '#FFFFFF',
    txtColor: '#FF0000',
    recurrence_fl: '0',
    random: '0',
    mastering_fl: '0',
    clash_distance: '',
    duration: '',
    override_duration: {
      hours: '00',
      minutes:'00',
    },
    duration_unit: 'mins',
    artistsNumber: 0,
    recurrence: {
      radio_value: '1',
      daily:{
        days: 1
      },
      weekly:{
        week: 1,
        days: []
      },
      monthly:{
        month: 1,
        monthSelection: '1',
        days:[],
        number: 1,
        type: 'SU'
      },
      yearly:{
        year:1,
        monthes:[],
        monthSelection: false,
        number: 1,
        type: 'SU'
      }
    },
    mastering: {
      m_switch: '0',
      days: '1',
      months: '1',
      months_2: '1',
      every_week_day: '1',
      recurrence_dow: "MO"
    },
    onDemand: false,
    onOverlay: false,
    frequency_range:[{
      id:null,
      startDate:{
        year:'YYYY',
        month:'MM',
        day:'DD'
      },
      endDate:{
        year:'YYYY',
        month:'MM',
        day:'DD'
      },
      startTime:{
        hours: '00',
        minutes:'00',
      },
      endTime:{
        hours: '00',
        minutes:'00',
      },
      frequency:null
    }]
  };
  scheduleValidation = {
    name: true,
    begin_date: true,
    finish_date: true,
    begin_time: true,
    finish_time: true,
    bgColor: true,
    txtColor: true,
    recurrence_fl: true,
    random: true,
    mastering: true,
    clash_distance: true,
    duration: true
  };//Field for schedule validation
  scheduleDates:any = [];//Array of dates for schedule actions
  scheduleWeek: any = [];//Array of weeks for schedule actions
  public myDatePickerOptions: IMyDpOptions = {
    dateFormat: 'dd/mm/yyyy',
    editableDateField: false,
    inline: false,
    height: '20px'
  };//params for Date picker module
  scheduleInfo:any = null;//Schedule information
  @ViewChild(AlertComponent, { static: true }) alert: AlertComponent;//Component of alert popup
  @ViewChild(ConfirmComponent, { static: true }) confirm: ConfirmComponent;//Component of confirm popup
  showForm = true;//Showing for for edit schedule
  /**
   Value for confirmation popup
   {
     id(integer) - ID of element for action(default null),
     type(integer) - type of function for action(default 1)
   }
   **/
  confirmValues = {
    id: null,
    type: 0
  };
  isLoad = false;//Display preloader

  public year_fr = this.getAllNumbersBetween(new Date().getFullYear(),new Date().getFullYear()+30);
  public month_fr = this.getAllNumbersBetween(1,12);
  // public day_fr = this.getAllNumbersBetween(1,31);
  public frequency_fr = ['1','2','3','4','5','6','7','8','9','10','11','12','13','14','15','16','17','18','19','20'].slice();
  getAllNumbersBetween(x, y) {
    var numbers = [];
    for (var i = x; i <= y; i++) {
      i = (i < 10 ? '0' : '') + i;
      numbers.push(i);
    }
    return numbers;
  }
  // public get begin_day_fr() {
  //   const dayCount = this.getDaysInMonth(this.schedule.begin_date.year, this.schedule.begin_date.month);
  //   return Array(dayCount).fill(0).map((i,idx) => {
  //     idx = idx + 1;
  //     return (idx < 10 ? '0' + idx : idx);
  //   })
  // }
  // public get finish_day_fr() {
  //   const dayCount = this.getDaysInMonth(this.schedule.finish_date.year, this.schedule.finish_date.month);
  //   return Array(dayCount).fill(0).map((i,idx) => {
  //     idx = idx + 1;
  //     return (idx < 10 ? '0' + idx : idx);
  //   })
  // }

  public day_fr(year,month){
    let dayCount = 31;
    if(year != 'YYYY' && month != 'MM'){
      dayCount = this.getDaysInMonth(year, month);
    }
    return Array(dayCount).fill(0).map((i,idx) => {
      idx = idx + 1;
      return (idx < 10 ? '0' + idx : idx);
    });
  }

  public getDaysInMonth(year: any, month: any) {
    return 32 - new Date(year, month - 1, 32).getDate();
  }

  constructor(
    private scheduleService: ScheduleService,
    private cpPlaylistService: CPPlaylistsService
  ) { }


  ngOnInit() {
    if(this.channelId != null){
      this.cpPlaylistService.getPlaylists(this.channelId).subscribe((playlists:any) => {
        this.schedule.playlistList = playlists;
      });
    }
    var today = this.date.getDay();
    this.setDays( this.date, today);
    this.setTime();
    this.setRecurrenceDaysArr();
    this.setRecurrenceMonthlyDaysArr();
    this.setRecurrenceMonthesArr();
    this.getScheduleDates(this.channelId);
  }

  /**
   Fill array of days for recurrence
   */
  setRecurrenceDaysArr(){
    for( var i = 0; i < 7; i++ ){
      this.schedule.recurrence.weekly.days[i] = false;
    }
  }
  /**
   Set days for calendar
   */
  setRecurrenceMonthlyDaysArr(){
    for( var i = 0; i < 31; i++ ){
      var o = {
        num: null,
        value: null
      };
      o.num = i + 1;
      o.value = false;
      this.schedule.recurrence.monthly.days[i] = o;
    }
  }
  /**
   Fill array of monthes for recurrence
   */
  setRecurrenceMonthesArr(){
    for( var i = 0; i < 12; i++ ){
      this.schedule.recurrence.yearly.monthes[i] = false;
    }
  }

  /**
   Fill calendar
   * @param date - first date of week
   * @param today - today date
   */
  setDays(date,today){
    var dayms = 1000 * 60 * 60 * 24;
    this.firstWeekDay = new Date(date - (dayms * today) );
    for( var i = 0; i < 7; i++ ){
      this.thisWeek[i] = new Date(Number(this.firstWeekDay) + (dayms * i));
    }
  }

  /**
   Fill calendar for next week
   * @returns {boolean}
   */
  nextWeek(){
    this.setDays(new Date(Number(this.thisWeek[6]) + (1000 * 60 * 60 * 24)),0 );
    return false;
  }
  /**
   Fill calendar for previous week
   * @returns {boolean}
   */
  prevWeek(){
    this.setDays(new Date(Number(this.thisWeek[0]) - (1000 * 60 * 60 * 24 * 7)),0 );
    return false;
  }
  /**
   Checking and confirmation schedule to indefinite date
   */
  checkSchedule( day, timeIndex ){
    // var str = day.getFullYear() + '-';
    // if((day.getMonth()+1)<10){
    //   str += '0';
    // }
    // str += (day.getMonth()+1) + '-';
    // if(day.getDate() < 10){
    //   str += '0';
    // }
    // str += day.getDate();
    let resultIndexes = [];
    for( var i = 0; i < this.scheduleDates.length; i++ ){
      if( (this.scheduleDates[i].start_time == '00:00' && this.scheduleDates[i].end_time == '00:00') || this.scheduleDates[i].overlay_playlist_id != null){
        continue;
      }
      var startDate = new Date(this.scheduleDates[i].begin_date.toString()); 
      var endDate = new Date(this.scheduleDates[i].finish_date.toString());
      if(this.scheduleDates[i].recurrence_flag == 1 && this.scheduleDates[i].recurrence_type == 'weekly'
        && ( this.scheduleDates[i].recurrence_dow != '' && this.scheduleDates[i].recurrence_dow != null)){
          let dayIndex = this.daysNamesShort.indexOf(this.scheduleDates[i].recurrence_dow);
          for (let j = 0; j < [dayIndex].length; j++) {
            let include = [dayIndex][j];
            if(day.getDay() == include) {
              var startIndex = this.getTimeIndexStart(this.scheduleDates[i].start_time);
              var endIndex = this.getTimeIndexEnd(this.scheduleDates[i].end_time);
              // console.log('day.getDay()',day.getDay())
              // console.log('this.time',this.timeArr);
              // console.log('this.scheduleDates[i].start_time',this.scheduleDates[i].start_time)
              // console.log('this.scheduleDates[i].end_time',this.scheduleDates[i].end_time)
              // console.log('startIndex',startIndex)
              // console.log('endIndex',endIndex)
              // console.log('timeIndex',timeIndex)
              if(endIndex > startIndex){
                if(startIndex <= timeIndex && endIndex >= timeIndex){
                  resultIndexes.push(i);
                  //return i;
                }
              }
              else{
                if(startIndex <= timeIndex){
                  resultIndexes.push(i);
                  // return i;
                }
              }
            }
          }
      }
      else{
        if(day >= startDate && day <= endDate){
          var startIndex = this.getTimeIndexStart(this.scheduleDates[i].start_time);
          var endIndex = this.getTimeIndexEnd(this.scheduleDates[i].end_time);
          if(endIndex > startIndex){
            if(startIndex <= timeIndex && endIndex >= timeIndex){
              resultIndexes.push(i);
              // return i;
            }
          }
          else{
            if(startIndex <= timeIndex){
              resultIndexes.push(i);
              // return i;
            }
          }
        }
      } 
    }
    return resultIndexes;
    // return -1;
  }
  /**
   Get time index from array
   * @param time - time string
   * @returns {number}
   */
  getTimeIndex(time){
    for( var i = 0; i < this.timeArr.length; i++ ){
      if( this.timeArr[i] == time){
        return i;
      }
    }
    return -1;
  }

  getTimeIndexStart(time){
    for( var i = 0; i < this.timeArr.length; i++ ){
      if( this.timeArr[i] == time  || ( this.timeArr[i] > time && this.timeArr[i-1] < time)){
        return i;
      }
    }
    return -1;
  }

  getTimeIndexEnd(time){
    for( var i = 0; i < this.timeArr.length; i++ ){
      if( this.timeArr[i] == time || ( this.timeArr[i] > time && this.timeArr[i-1] < time)){
        return i;
      }
    }
    return -1;
  }

  /**
   Parse time for selects
   */
  setTime(){
    var isSet = true;
    var index = 0;

    while(isSet){
      if(this.time.h == 5 && this.time.m == 45){

        isSet = false;
      }
      if(this.time.h < 10){
        this.timeArr[index] = '0' + this.time.h.toString() + ':';
      }
      else{
        this.timeArr[index] = this.time.h.toString() + ':';
      }
      if(this.time.m == 0){
        this.timeArr[index] += '00';
      }
      else{
        this.timeArr[index] += this.time.m;
      }
      if(this.time.m == 45){
        this.time.h += 1;
        this.time.m = 0;
      }
      else{
        this.time.m += 15;
      }

      if(this.time.h == 24){

        this.time.h = 0;
        this.time.m = 0;

      }

      index += 1;
    }
    this.finishTimeArr = this.timeArr;
  }

  /**
   Displaying calendar
   */
  showCalendar(){
      this.popups.calendarPopupOpened = true;
    // this.isShowCalendar = !this.isShowCalendar;
  }
  /**
   Open recurrence popup
   * @returns {boolean}
   */
  openRecurrencePopup(){
    this.popups.recurrencePopupOpened = true;
    return false;
  }
  /**
   Close all popups
   * @returns {boolean}
   */
  popupClose(){
    this.popups.recurrencePopupOpened = false;
    return false;
  }
  /**
   Change recurrence
   */
  changeRecurrence(){
    if( this.schedule.recurrence_fl == '1' ){
      this.recurrence = Object.assign({}, this.schedule.recurrence);
      this.popups.recurrencePopupOpened = true;
    }
  }
  /**
   Change mastering
   */
  changeMastering(){
    if( this.schedule.mastering_fl == '1' ){
      this.mastering = Object.assign({}, this.schedule.mastering);
      this.popups.masteringPopupOpened = true;
    }
  }

  /**
   Update schedule information
   * @param sid - schedule id
   */
  changeSchedule( sid ){
    this.isLoad = true;
    this.scheduleService.getScheduleInfo(sid).subscribe(schedule => {
      this.isLoad = false;
      this.scheduleInfo = schedule[0];
      this.getPlaylistInfo(this.scheduleInfo.cp_playlist_id);
      this.setScheduleInfo(this.scheduleInfo);
    });
  }

  /**
   Get playlist information
   * @param id - playlist id
   */
  getPlaylistInfo(id){
    var info:any = '';
    this.cpPlaylistService.getPlaylistById(id).subscribe((playlist:any) => {
      info = playlist;
      this.tab_str = info.client_name+' -> '+info.site_name+' -> '+info.channel_name+' -> '+this.scheduleInfo.name;
    })
  }

  /**
   Set schedule information in form
   * @param info - schedule information
   */
  setScheduleInfo(info){
    this.schedule.id = info.id;
    this.schedule.playlistId = info.cp_playlist_id;
    this.schedule.name = info.name;

    let begin_date_array_date = info.begin_date.split(" ");
    let begin_date_array = begin_date_array_date[0].split("-");
    let finish_date_array_date = info.finish_date.split(" ");
    let finish_date_array = finish_date_array_date[0].split("-");
    let begin_time_array = begin_date_array_date[1].split(":");
    let finish_time_array = finish_date_array_date[1].split(":");

    this.schedule.begin_date.year = begin_date_array[0];
    this.schedule.begin_date.month = begin_date_array[1];
    this.schedule.begin_date.day = begin_date_array[2];
    this.schedule.finish_date.year = finish_date_array[0];
    this.schedule.finish_date.month = finish_date_array[1];
    this.schedule.finish_date.day = finish_date_array[2];
    this.schedule.begin_time.hours = begin_time_array[0];
    this.schedule.begin_time.minutes = begin_time_array[1];
    this.schedule.finish_time.hours = finish_time_array[0];
    this.schedule.finish_time.minutes = finish_time_array[1];


    this.schedule.bgColor = '#' + info.background_color;
    this.schedule.txtColor = '#' + info.text_color;
    this.schedule.recurrence_fl = info.recurrence_flag.toString();
    this.schedule.random = info.rnd_order.toString();
    this.schedule.mastering_fl = info.mastering.toString();
    this.schedule.clash_distance = info.clash_distance;
    this.schedule.duration = info.override_duration;
    this.schedule.duration_unit = info.override_duration_unit;

    if(info.override_duration_unit && info.override_duration_unit.length == 4){
      this.schedule.override_duration.hours = info.override_duration_unit.substr(0,2);
      this.schedule.override_duration.minutes = info.override_duration_unit.substr(2,2);
    }
    if(this.schedule.recurrence_fl == '1'){
      if( info.recurrence_type == 'daily' ){
        this.schedule.recurrence.radio_value = '1';
        this.schedule.recurrence.daily.days = info.recurrence_every;
      }
      else if( info.recurrence_type == 'weekly' ){
        this.schedule.recurrence.radio_value = '2';
        this.schedule.recurrence.weekly.week = info.recurrence_every;

        if(!info.recurrence_dow)
          info.recurrence_dow = 'null';
        this.schedule.recurrence.weekly.days = info.recurrence_dow;
      }
      else if( info.recurrence_type == 'monthly' ){
        this.schedule.recurrence.radio_value = '3';
        this.schedule.recurrence.monthly.month = info.recurrence_every;
        var days = info.recurrence_days;
        this.setRecurrenceMonthlyDaysArr();
        if(days != null){
          this.schedule.recurrence.monthly.monthSelection = '1';
          this.schedule.recurrence.monthly.days = this.parseDays( days, this.schedule.recurrence.monthly.days );
        }
        else if( info.recurrence_additional_rule_1 != null && info.recurrence_additional_rule_2 != null ){
          this.schedule.recurrence.monthly.monthSelection = '2';
          this.schedule.recurrence.monthly.number = info.recurrence_additional_rule_1;
          this.schedule.recurrence.monthly.type = info.recurrence_additional_rule_2;
        }
      }
      else if( info.recurrence_type == 'yearly' ){
        this.schedule.recurrence.radio_value = '4';
        this.schedule.recurrence.yearly.year = info.recurrence_every;
        var monthes = info.month_s;
        this.schedule.recurrence.yearly.monthes = this.parseMonthes( monthes, this.schedule.recurrence.yearly.monthes );
        if( info.recurrence_additional_rule_1 != null && info.recurrence_additional_rule_2 != null ){
          this.schedule.recurrence.yearly.monthSelection = true;
          this.schedule.recurrence.yearly.number = info.recurrence_additional_rule_1;
          this.schedule.recurrence.yearly.type = info.recurrence_additional_rule_2;
        }
        else{
          this.schedule.recurrence.yearly.monthSelection = false;
        }
      }
    }
    if(this.schedule.mastering_fl == '1'){
      this.schedule.mastering.m_switch = info.mastering_switch.toString();
      if( this.schedule.mastering.m_switch == '0' ){
        this.schedule.mastering.days = info.mastering_days;
        this.schedule.mastering.months = info.mastering_months;
      }
      else if(info.mastering_switch == '1'){
        this.schedule.mastering.every_week_day = info.mastering_every_week_day;
        this.schedule.mastering.recurrence_dow = info.mastering_recurrence_dow;
        this.schedule.mastering.months_2 = info.mastering_months;
      }
    }

    let fr = [{
      id:null,
      startDate:{
        year:'YYYY',
        month:'MM',
        day:'DD'
      },
      endDate:{
        year:'YYYY',
        month:'MM',
        day:'DD'
      },
      startTime:{
        hours: '00',
        minutes:'00',
      },
      endTime:{
        hours: '00',
        minutes:'00',
      },
      frequency:null
    }];
    this.schedule.onOverlay = false;
    if(info.frequency_ranges.length > 0){
      fr = [];
      this.schedule.onOverlay = true;
    }
    for (let index = 0; index < info.frequency_ranges.length; index++) {
      const element = info.frequency_ranges[index];

      let startdate_array_date = element.startdate.split(" ");
      let startdate_array = startdate_array_date[0].split("-");
      let enddate_array_date = element.enddate.split(" ");
      let enddate_array = enddate_array_date[0].split("-");
      let starttime_array = startdate_array_date[1].split(":");
      let endtime_array = enddate_array_date[1].split(":");

      fr.push({
        id: element.id,
        startDate:{
          year: startdate_array[0],
          month: startdate_array[1],
          day: startdate_array[2],
        },
        endDate:{
          year: enddate_array[0],
          month: enddate_array[1],
          day: enddate_array[2],
        },
        // endDate.year: this.setDate(element.enddate),
        startTime:{
          hours: starttime_array[0],
          minutes: starttime_array[1],
        },
        endTime:{
          hours: endtime_array[0],
          minutes: endtime_array[1],
        },
        frequency:element.frequency,

      });
      if(!this.year_fr.includes(startdate_array[0]) && startdate_array[0] !== "YYYY"){
        this.year_fr.unshift(startdate_array[0]);
      }
      if(!this.year_fr.includes(enddate_array[0]) && enddate_array[0] !== "YYYY"){
        this.year_fr.unshift(enddate_array[0]);
      }
    }

    console.log('fr',fr);
    this.schedule.frequency_range = fr;
    this.getArtistsNumber();
    this.changeFinishTime();
  }

  /**
   Set dates in date picker
   * @param date - date string
   * @returns {{date: {year: number; month: number; day: number}; formatted: any}}
   */
  setDate(date){
    var dateArr = date.split('/');
    return {date: { year: parseInt(dateArr[2]), month: parseInt(dateArr[1]), day: parseInt(dateArr[0])},formatted: date};
  }


  /**
   Parse day names
   * @param dows - day names string
   * @param arr - day numbers array
   * @returns {any}
   */
  parseDows(dows,arr){
    var dArr = dows.split(',');
    for( var i = 0; i < dows.length; i++ ){
      if(dArr[i]=='SU'){arr[0]=true}
      else if(dArr[i]=='MO'){arr[1]=true}
      else if(dArr[i]=='TU'){arr[2]=true}
      else if(dArr[i]=='WE'){arr[3]=true}
      else if(dArr[i]=='TH'){arr[4]=true}
      else if(dArr[i]=='FR'){arr[5]=true}
      else if(dArr[i]=='SA'){arr[6]=true}
    }
    return arr;
  }

  /**
   Parse dates
   * @param days - dates string
   * @param arr - dates array
   * @returns {any}
   */
  parseDays(days, arr) {
    var dArr = days.split(',');

    for (let row of dArr) {
      var d = parseInt(row);
      arr[d - 1].value = true;
      arr[d - 1].value = true;
    }
    return arr;
  }

  /**
   Parse monthes
   * @param monthes - monthes string
   * @param arr - monthes array
   * @returns {any}
   */
  parseMonthes( monthes, arr ){
    var mArr = monthes.split(',');
    for (let row of mArr) {
      var m = parseInt(row);
      arr[ m - 1 ] = true;
    }
    return arr;
  }
  /**
   Close recurrence popup
   * @param num - number of action
   * @returns {boolean}
   */
  recurrencePopupClose(num){
    if(num == 0){

      this.schedule.recurrence = this.recurrence;
    }
    this.popups.recurrencePopupOpened = false;
    return false;
  }
  /**
   Close mastering popup
   * @param num - number of action
   * @returns {boolean}
   */
  masteringPopupClose(num){
    if(num == 0){
      this.schedule.mastering = this.mastering;
    }
    this.popups.masteringPopupOpened = false;
    return false;
  }
  calendarPopupClose(){
    this.popups.calendarPopupOpened = false;
    return false;
  }

  /**
   Checking and confirmation schedule to indefinite date
   */
  checkScheduleDate(){

    this.scheduleValidation.begin_time = true;
    this.scheduleValidation.finish_time = true;
    this.scheduleValidation.duration = true;
    if(this.schedule.begin_time.hours == ''){
      this.alert.alertOpen('Please select start time',1);
      this.scheduleValidation.begin_time = false;
      return false;
    }
    if(this.schedule.finish_time.hours == ''){
      this.alert.alertOpen('Please select finish time',1);
      this.scheduleValidation.finish_time = false;
      return false;
    }
    // Call frequency range validation function
    if (!this.validateFrequencyRange()) {
      return false;
    }
    // if (this.findOverlaps().length > 0) {
    //   this.alert.alertOpen('The frequency range dates overlap!', 1);
    //   return false;
    // }

    if(this.schedule.finish_date.year == undefined || this.schedule.finish_date.year == '' || this.schedule.finish_date.year == null){
      this.openConfirmPopup(null,0);
    }
    else{
      if(this.schedule.finish_date.month == undefined || this.schedule.finish_date.month == '' || this.schedule.finish_date.month == null){
        this.alert.alertOpen('Please select outdate month!',1);
        return false;
      }
      if(this.schedule.finish_date.day == undefined || this.schedule.finish_date.day == '' || this.schedule.finish_date.day == null){
        this.alert.alertOpen('Please select outdate day!',1);
        return false;
      }
      this.createSchedule();
    }
  }
  /**
   Creating new schedule
   */
  createSchedule(){
    if( this.schedule.playlistId != null && this.schedule.name != '' ){
      var str = this.createScheduleStr(this.schedule);
      this.isLoad = true;
      if(this.schedule.id != null){
        this.scheduleService.updateSchedule(this.schedule.id,str).subscribe((sch:any)=>{

          if(sch.message!=undefined && sch.message!=''){

            let message = sch.message.replace(/;/g, " <br /> ");
            this.alert.alertOpen(message,2);
          }
          if(sch.error!=undefined){
            this.alert.alertOpen(sch.error,1);
          }
          else{

            this.changeSchedule(this.sheduleId)
            this.alert.alertOpen("Schedule updated",0);
            this.getScheduleDates(this.channelId);
          }
          this.isLoad = false;
        });
      }
      else{
        this.scheduleService.createSchedule( this.channelId, str ).subscribe( (sch:any) => {

          if(sch.message!=undefined && sch.message!=''){
            let message = sch.message.replace(/;/g, " <br /> ");
            this.alert.alertOpen(message,2);
          }
          if(sch.error!=undefined){
            this.alert.alertOpen(sch.error,1);
          }
          else{

            this.alert.alertOpen("New schedule created",0);
          }
          this.isLoad = false;
        });
      }
    }
    else{
      this.alert.alertOpen( "Please select playlist name and schedule name" ,1);
    }
  }
  /**
   Create string for query for schedule creating/editing
   * @param sch - schedule object
   * @returns {string}
   */
  createScheduleStr(sch){
    var str = 'name=' + sch.name + '&cp_playlist_id=' + sch.playlistId
    if(sch.begin_date.year == ""||sch.begin_date.year == null||sch.begin_date.year==undefined)
      sch.begin_date.year = '1899';
    if(sch.begin_date.month == ""||sch.begin_date.month == null||sch.begin_date.month==undefined)
      sch.begin_date.month = '01';
    if(sch.begin_date.day == ""||sch.begin_date.day == null||sch.begin_date.day==undefined)
      sch.begin_date.day = '01';
    str += '&begin_date=' + sch.begin_date.year+'-'+sch.begin_date.month+'-'+sch.begin_date.day;

    if(sch.finish_date.year == ""||sch.finish_date.year == null||sch.finish_date.year==undefined)
      str += '&finish_date=' + this.parseDate(sch.finish_date.year);
    else{

      str += '&finish_date=' + sch.finish_date.year+'-'+sch.finish_date.month+'-'+sch.finish_date.day;
    }

    str += '&begin_time=' + sch.begin_time.hours+':'+sch.begin_time.minutes + '&finish_time=' + sch.finish_time.hours+':'+sch.finish_time.minutes
    if(sch.recurrence == '0'){
      str += '&recurrence_flag=' + sch.recurrence_fl;
      str += '&recurrence_every=1&recurrence_type=monthly';
    }
    else{
      str += '&recurrence_flag=' + sch.recurrence_fl;
      if(sch.recurrence.radio_value == '1'){
        str += '&recurrence_type=daily&recurrence_every=' + sch.recurrence.daily.days;
      }
      else if(sch.recurrence.radio_value == '2'){
        str += '&recurrence_type=weekly&recurrence_every=' + sch.recurrence.weekly.week;
        if(sch.recurrence.weekly.days != 'null')
          str += '&recurrence_dow='+sch.recurrence.weekly.days;

      }
      else if(sch.recurrence.radio_value == '3'){
        str += '&recurrence_type=monthly&recurrence_every=' + sch.recurrence.monthly.month;
        if(sch.recurrence.monthly.monthSelection == '1'){
          str += '&recurrence_days=';
          var daySet = false;
          for( var i = 0; i < sch.recurrence.monthly.days.length; i++ ){
            if(sch.recurrence.monthly.days[i].value){
              if(daySet)
                str += ',';
              str += sch.recurrence.monthly.days[i].num;
              daySet = true;
            }
          }
        }
        else if(sch.recurrence.monthly.monthSelection == '2'){
          str += '&recurrence_additional_rule_1='+ sch.recurrence.monthly.number + '&recurrence_additional_rule_2=' + sch.recurrence.monthly.type;
        }
      }
      else if(sch.recurrence.radio_value == '4'){
        str += '&recurrence_type=yearly&recurrence_every=' + sch.recurrence.yearly.year;
        str += '&month_s=';
        var monthSet = false;
        for( var i = 0; i < sch.recurrence.yearly.monthes.length; i++ ){
          if(sch.recurrence.yearly.monthes[i]){
            if(monthSet)
              str += ',';
            str += i+1;
            monthSet = true;
          }
        }
        if(sch.recurrence.yearly.monthSelection){
          str += '&recurrence_additional_rule_1='+ sch.recurrence.yearly.number + '&recurrence_additional_rule_2=' + sch.recurrence.yearly.type;
        }
      }
    }
    str += '&mastering=' + sch.mastering_fl;
    if(sch.mastering_fl == '1'){
      str += '&mastering_switch=' + sch.mastering.m_switch;
      if(sch.mastering.m_switch == '0'){
        str += '&mastering_days=' + sch.mastering.days + '&mastering_months=' + sch.mastering.months;
      }
      else{
        str += '&mastering_every_week_day=' + sch.mastering.every_week_day + '&mastering_recurrence_dow=' + sch.mastering.recurrence_dow + '&mastering_months=' + sch.mastering.months_2;
      }
    }
    str += '&rnd_order=' + sch.random + '&clash_distance=' + sch.clash_distance
    //+'&override_duration=' + sch.override_duration
    + '&override_duration_unit=' + sch.override_duration.hours+sch.override_duration.minutes + '&background_color=' + sch.bgColor + '&text_color=' + sch.txtColor;

    if(sch.onOverlay)
     for( var i = 0; i < sch.frequency_range.length; i++ ){
      if(sch.frequency_range[i].id != null){
        str += '&frequency_range_id[' + i + ']=' + sch.frequency_range[i].id;
      }
      else{
        str += '&frequency_range_id[' + i + ']=undefined';
      }
    //   //if(sch.frequency_range[i].fp_id!=null){
    //     if(i>0){
    //       str+='&'
    //     }
     // str += '&startDate[' + i + ']=' + this.parseDate(sch.frequency_range[i].startDate.formatted) + '&endDate['+i+']=' + this.parseDate(sch.frequency_range[i].endDate.formatted);
     str += '&startDate[' + i + ']=' + sch.frequency_range[i].startDate.year+'-'+sch.frequency_range[i].startDate.month+'-'+sch.frequency_range[i].startDate.day + '&endDate['+i+']=' + sch.frequency_range[i].endDate.year+'-'+sch.frequency_range[i].endDate.month+'-'+sch.frequency_range[i].endDate.day;
      str += '&startTime['+i+']=' + sch.frequency_range[i].startTime.hours+':'+sch.frequency_range[i].startTime.minutes + '&endTime['+i+']=' + sch.frequency_range[i].endTime.hours+':'+sch.frequency_range[i].endTime.minutes
      str +='&frequency[' + i + ']=' + sch.frequency_range[i].frequency;
      //}
    }
    str += '&overlay=' + sch.onOverlay;
    return str;
  }
  /**
   Parsing date for schedule string
   * @param date - date string
   * @returns {string}
   */
  parseDate(date){
    if(date == "" || date == undefined || date == null){
      return 'indefinite';
    }
    else{
      var dateArr = date.split('/');
      var str = dateArr[2] + '-' + dateArr[1] + '-' + dateArr[0];
      return str;
    }
  }

  /**
   Get schedule dates actions
   * @param cid - schedule id
   */
  getScheduleDates(cid){
    // Do nothing
    this.scheduleService.getScheduleDates(cid).subscribe((dates:any) => {
      this.scheduleDates = dates;
      // this.updateScheduleDates();
    });
  }

  /**
   Update dates in calendar
   */
  updateScheduleDates(){
    var sch = [];
    var getDateArray = function(start, end) {
        var arr = new Array();
        var dt = new Date(start);
        while (dt <= end) {
            arr.push(new Date(dt));
            dt.setDate(dt.getDate() + 1);
        }
        return arr;
    }

    var filterWeekDays = function(dates, includeDays) {
      var weekdays = [];
      // cycle dates
      dates.forEach(function(day){
        // cycle days to be included (so==0, mo==1, etc.)
        includeDays.forEach(function(include) {
          if(day.getDay() == include) {
            weekdays.push(day);
          }
        });
      });
      return weekdays;
    }

    for( var i = 0; i < this.scheduleDates.length; i++ ){
      var startDate = new Date(this.scheduleDates[i].begin_date.toString()); //YYYY-MM-DD
      var endDate = new Date(this.scheduleDates[i].finish_date.toString()); //YYYY-MM-DD
      var dateArr = [];//getDateArray(startDate, endDate);

      if(this.scheduleDates[i].recurrence_flag == 1 && this.scheduleDates[i].recurrence_type == 'weekly'
        && ( this.scheduleDates[i].recurrence_dow != '' && this.scheduleDates[i].recurrence_dow != null)){
          let dayIndex = this.daysNamesShort.indexOf(this.scheduleDates[i].recurrence_dow);

          dateArr = filterWeekDays(dateArr, [dayIndex]);
      }else{
        this.scheduleDates[i].schedule_day = this.scheduleDates[i].begin_date.toString();
      }

      for (var j = 0; j < dateArr.length; j++) {
        var el = Object.assign({}, this.scheduleDates[i] );
        var d = new Date(dateArr[j]);
        d.setDate(d.getDate())
        //if(el.start_time > )
        // if(j>0)
        //   el.start_time = '06:00';
        el.schedule_day = d.getFullYear() + '-';
        if(d.getMonth() + 1 < 10)
          el.schedule_day += '0';
        el.schedule_day += (d.getMonth()+1) + '-';
        if(d.getDate() < 10)
          el.schedule_day += '0';
        el.schedule_day += d.getDate();
        sch.push(el);
      }
    }
    this.scheduleDates = this.scheduleDates.concat(sch);
  }

  /**
   Check schedule finish time
   */
  changeFinishTime(){
    var begin_hour = this.schedule.begin_time.hours;
    var begin_minute = this.schedule.begin_time.minutes;


    if( this.schedule.begin_time.hours == '00'
      && this.schedule.begin_time.minutes == '00'
      && this.schedule.finish_time.hours == '00'
      && this.schedule.finish_time.minutes == '00'){
        this.schedule.onDemand = true;
        return false;
    }
    else{
      this.schedule.onDemand = false;
    }

    this.finish_hours = this.org_finish_hours.slice();
    this.finish_minutes = this.org_finish_minutes.slice();


    // if(Number(begin_hour) < 6){ //#Mani-Jan6,2020: Remove starttime/endtime validation as per Paul request#
    //   this.finish_hours = this.finish_hours.filter(item => (Number(item) < 6 && Number(item) >= Number(begin_hour)) );
    // }
    // if(Number(begin_hour) >= 6){ //#Mani-Jan6,2020: Remove starttime/endtime validation as per Paul request#
      let index: number = this.org_finish_hours.indexOf(begin_hour);
      if (index !== -1) {
        if(begin_minute == '59'){
          index = index+1;
        }
        this.finish_hours.splice(0, index);
      }
    // }

    if(this.schedule.finish_time.hours < this.finish_hours[0] && !(this.schedule.finish_time.hours >= '00' && this.schedule.finish_time.hours < '06')){
      this.schedule.finish_time.hours = this.finish_hours[0];
    }
    if(this.schedule.finish_time.hours < this.finish_hours[0] && (this.schedule.begin_time.hours > '00' && this.schedule.begin_time.hours < '06') && (this.schedule.finish_time.hours > '00' && this.schedule.finish_time.hours < '06')){
      this.schedule.finish_time.hours = this.finish_hours[0];
    }
    var finish_hour = this.schedule.finish_time.hours;
    if(Number(begin_hour) == Number(finish_hour)){
      this.finish_minutes = this.finish_minutes.filter(item => Number(item) > Number(begin_minute));
      if(this.schedule.finish_time.minutes == '00' || this.schedule.finish_time.minutes < this.finish_minutes[0])
        this.schedule.finish_time.minutes = this.finish_minutes[0];
    }else{
      if(this.schedule.finish_time.minutes < this.finish_minutes[0])
        this.schedule.finish_time.minutes = this.finish_minutes[0];
    }

  }
  /**
   Cheking schedule distance
   */
  checkDistance(){
    var dist = parseInt(this.schedule.clash_distance);
    if(isNaN(dist)){
      this.alert.alertOpen("Please enter number",1);
      this.schedule.clash_distance = '0';
    }
    else{
      if(dist > this.schedule.artistsNumber){
        this.alert.alertOpen("Total number of Artists present in playlist is only "+ this.schedule.artistsNumber + ". Please give less than that count!",1);
        this.scheduleValidation.clash_distance = false;
      }
      else{
        this.scheduleValidation.clash_distance = true;
      }
    }
  }
  /**
   Get number of artists for schedule
   */
  getArtistsNumber(){
    this.cpPlaylistService.getArtistsNumber(this.schedule.playlistId).subscribe((num:any) => {
      this.schedule.artistsNumber = parseInt(num.artists);
    });
  }

  /**
   Open/close schedule form for edit information
   * @returns {boolean}
   */
  expandForm(){
    this.showForm = !this.showForm;
    return false;
  }
  /**
   Confirm action in confirmation popup
   * @param e - event
   */
  isConfirm(e){
    if(e){
      if( this.confirmValues.type == 0 )
        this.createSchedule();
    }
  }
  /**
   Open confirmation popup
   * @param id - id of action
   * @param t - text for popup
   * @returns {boolean}
   */
  openConfirmPopup(id, t){
    var text = "Would you like to set this schedule for a indefinite date?";

    this.confirmValues.id = id;
    this.confirmValues.type = t;
    this.confirm.confirmOpen(text);
    return false;
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  onDemandClick(e){
    this.finish_minutes = ['00'];
    this.schedule.begin_time.hours = '00';
    this.schedule.begin_time.minutes = '00';
    this.schedule.finish_time.hours = '00';
    this.schedule.finish_time.minutes = '00';
    this.schedule.onDemand = e.target.checked;
  }

  onOverlayClick(e){
    this.schedule.onOverlay = e.target.checked;
  }
  addFrequencyRange(){
    var a = {
      id: null,
      startDate:{
        year:'YYYY',
        month:'MM',
        day:'DD'
      },
      endDate:{
        year:'YYYY',
        month:'MM',
        day:'DD'
      },
      startTime:{
        hours: '00',
        minutes:'00',
      },
      endTime:{
        hours: '00',
        minutes:'00',
      },
      frequency:null
    }
    this.schedule.frequency_range.push(a);
    console.log('this.schedule.frequency_range',this.schedule.frequency_range);
    return false;
  }

  removeFrequencyRange(index,frid,splice){
    if(frid != null){
      this.scheduleService.deleteFR(frid).subscribe((ps:any) => {
        if(ps.error != undefined){
          this.alert.alertOpen(ps.error,1);
        }
        else{
          if(splice){
            this.schedule.frequency_range.splice( index, 1 );
          }
        }
      });
    }
    else{
      if(splice){
        this.schedule.frequency_range.splice( index, 1 );
      }
    }
    return false;
  }

  checkOverlap(range1: { startDate: Date, endDate: Date }, range2: { startDate: Date, endDate: Date }): boolean {
    return range1.startDate <= range2.endDate && range2.startDate <= range1.endDate;
  }

  findOverlaps(): { startDate: Date, endDate: Date }[] {
    const overlaps: { startDate: Date, endDate: Date }[] = [];
    let dateRanges:{ startDate: Date, endDate: Date }[] = [];//this.schedule.frequency_range;
    for (let i = 0; i < this.schedule.frequency_range.length; i++) {
      const frequencyRange = this.schedule.frequency_range[i];
      let startYear = frequencyRange.startDate.year;
      let endYear = frequencyRange.endDate.year;
      if(frequencyRange.startDate.year == 'YYYY'){
        startYear = new Date().getFullYear().toString();
      }
      if(frequencyRange.endDate.year == 'YYYY'){
        endYear = new Date().getFullYear().toString();
      }
      const startDate = new Date(
        Number(startYear), // Convert year to number
        Number(frequencyRange.startDate.month) - 1, // Convert month to number and subtract 1 for zero-index
        Number(frequencyRange.startDate.day), // Convert day to number
        Number(frequencyRange.startTime.hours),
        Number(frequencyRange.startTime.minutes)
      );

      const endDate = new Date(
        Number(endYear),
        Number(frequencyRange.endDate.month) - 1,
        Number(frequencyRange.endDate.day),
        Number(frequencyRange.endTime.hours),
        Number(frequencyRange.endTime.minutes)
      );
      dateRanges.push({
        startDate: startDate,
        endDate: endDate
      })
    }
    for (let i = 0; i < dateRanges.length; i++) {
      for (let j = i + 1; j < dateRanges.length; j++) {
        if (this.checkOverlap(dateRanges[i], dateRanges[j])) {
          overlaps.push({
            startDate: dateRanges[i].startDate,
            endDate: dateRanges[i].endDate
          });
          overlaps.push({
            startDate: dateRanges[j].startDate,
            endDate: dateRanges[j].endDate
          });
        }
      }
    }
    return overlaps;
  }

  validateFrequencyRange(): boolean {
    if (this.schedule.onOverlay && this.schedule.frequency_range.length > 0) {
      for (let i = 0; i < this.schedule.frequency_range.length; i++) {
        const frequencyRange = this.schedule.frequency_range[i];

        // Check if startTime or endTime is null
        if (frequencyRange.startTime == null || frequencyRange.endTime == null) {
          this.alert.alertOpen('Frequency range - time cannot be empty!', 1);
          return false;
        }

        // Check if frequency is null
        if (frequencyRange.frequency == null) {
          this.alert.alertOpen('Frequency range - frequency cannot be empty!', 1);
          return false;
        }

        const startDate = new Date(
          Number(frequencyRange.startDate.year), // Convert year to number
          Number(frequencyRange.startDate.month) - 1, // Convert month to number and subtract 1 for zero-index
          Number(frequencyRange.startDate.day), // Convert day to number
          Number(frequencyRange.startTime.hours),
          Number(frequencyRange.startTime.minutes)
        );

        const endDate = new Date(
          Number(frequencyRange.endDate.year),
          Number(frequencyRange.endDate.month) - 1,
          Number(frequencyRange.endDate.day),
          Number(frequencyRange.endTime.hours),
          Number(frequencyRange.endTime.minutes)
        );

        if (endDate <= startDate) {
          this.alert.alertOpen(`Error in row ${i + 1}: The end date must be later than the start date for the selected frequency range.`, 1);

          return false;
        }
      }
    }
    return true;
  }

  tooltipOptions = {
    placement: "right",
    // "show-delay": 800,
    'theme':'light'
    // width: "300",
  };
  getTooltipContent(scheduleIndexes: number[]): string {
    return scheduleIndexes
      .map(index => this.scheduleDates[index]?.name.split('-').pop() || '') // Retrieve the names
      .filter(name => name) // Remove any undefined or empty values
      .join(', '); // Join them into a single string separated by commas
  }

}
