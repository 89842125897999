<div class="page_wrap">
  <app-page-header [logout]="true" [pageTitle]="'Set Up Google/Microsoft Authenticator'"></app-page-header>

  <div style="margin: 100px;">
    <div class="container" *ngIf="!OtpPage">
      <div class="row">
        <div class="col-md-12 mt-4">
          <div class="card card-default">
            <h4 class="card-heading text-center mt-3">Set Up Google/Microsoft Authenticator</h4>
            <div class="card-body text-center">
              <p>
                Set up two-factor authentication by scanning the barcode below.
                Alternatively, you can use the <strong>{{ receivedData.secret_key }}</strong>
              </p>
              <div [innerHTML]="sanitizedQRImage"></div>
              <p>You must save the Google/Microsoft Authenticator app before continuing. You will be unable to login
                otherwise.
              </p>
              <div>
                <button (click)="GotoOtpPage()">Click for OTP</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
    <div class="container_otp" *ngIf="OtpPage">
      <div class="otp-panel">
        <div class="otp-header">
          <h2>Two-Factor Authentication</h2>
          <p>Please enter the <strong>OTP</strong> generated on your Authenticator App.</p>
          <p>Ensure you submit the current one because it refreshes every 30 seconds.</p>
        </div>

        <div class="otp-body">
          <div class="form-group">
            <label for="one_time_password" class="control-label">One Time Password</label>
            <input id="one_time_password" type="number" class="form-control" [(ngModel)]="otp" name="one_time_password"
              required autofocus>
          </div>
        </div>

        <div class="otp-footer">
          <button class="btn-verify" (click)="verifyOtp()">Verify & Login</button>
        </div>
      </div>
    </div>
  <app-alert></app-alert>
</div>
