import { Component, OnInit, HostListener } from '@angular/core';
import {ActivatedRoute, NavigationExtras, Router} from '@angular/router';
import {CookieService} from 'ngx-cookie-service';
import {UserService} from '../services/user.service';
import {AppComponent} from '../app.component';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
  providers: [UserService]
})
export class NavigationComponent implements OnInit {
  menuOpened = false;//Collapse menu
  isShowHeader = true;// Sets displaying header or not
  userInfo: any = {};// information of logged user
  path = null;//url in browser string

  constructor(
    private router: Router,
    private cookieService: CookieService,
    private userService: UserService,
    private appComp: AppComponent,
    private route: ActivatedRoute
  ){}
  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      if( params['sh'] != null&&params['sh'] == 0 ){
        this.isShowHeader = false;
      }
    });
    this.userService.getCurrentUserInfo().subscribe(userInfo => {
      this.appComp.userInfo = userInfo;
      this.userInfo = userInfo;
      console.log(this.userInfo);
      
    });
  }
  /**
   Click outside element
   * @param $event
   */
  @HostListener('document:click', ['$event']) clickedOutside($event){
    this.navClose($event);
  }
  /**
   Click inside element
   * @param e - event
   */
  clickedInside(e){
    localStorage.removeItem("cp_es_keyword");
    e.preventDefault();
    e.stopPropagation();
  }

  /**
   Expand navigation
   * @param e - event
   */
  navOpen(e){
    e.preventDefault();
    if(this.menuOpened){
      this.menuOpened = false;
    }
    else{
      this.menuOpened = true;
    }
  }

  /**
   Collapse navigation
   * @param e - event
   */
  navClose(e){
    this.menuOpened = false;
  }

  /**
   Click to logout link
   * @param e - event
   */
  logout(e){
    e.preventDefault();
    this.navClose(e);
    this.cookieService.delete('access');
    this.cookieService.delete('enable');
    if(this.cookieService.get('access') == null || this.cookieService.get('access') == undefined || this.cookieService.get('access')==''){
      this.userService.logoutUser().subscribe(info => {
        this.isShowHeader = false;
        this.router.navigate(['/login']);
      });
    }
  }

  /**
   Click to edit user link
   * @param e - event
   * @param id - logged user id
   */
  editClick(e, id ){
    this.navClose(e);
    e.preventDefault();
    let navigationExtras: NavigationExtras = {
      queryParams:{
        'uid': this.userInfo.id
      }
    };
    this.router.navigate(['edit-user'], navigationExtras);
  }

  /**
   Checking url
   * @param url - url
   * @returns {any}
   */
  checkUrl(url){
    var u = url.split('?');
    return u[0];
  }
}
