<div *ngIf="viewDoc">
    <div *ngIf="loaded">
      <ngx-doc-viewer [url]="docUrl" viewer="google" style="position:absolute;width:100%;height:100%;"></ngx-doc-viewer>
    </div>
    <div *ngIf="!loaded">
      <img src="../../assets/img/loader.gif" alt="loader" class="custom-loader" />
    </div>
</div>
<div *ngIf="!viewDoc">
<app-page-header [pageTitle]="'Create, edit and manage your playlists'"></app-page-header>
<div class="top_box">
  <div class="mf_buttons">
    <div class="search_box">
      <form method="get" #searchForm="ngForm">
        <input type="text" name="search" (focus)="focusInput()" (blur)="blurInput()" value="{{searchStr}}"
          placeholder="Playlist search" class="searchInput" [(ngModel)]="searchStr" maxlength="30" />
        <button type="submit" class="searchBtn" (click)="search($event)"></button>
        <a href="#" class="clear_search" (click)="clearSearch($event)"></a>
      </form>
    </div>

  </div>
</div>
<div class="foundation_playlist_main">
  <div class="breadcrumbs">
    <a href="#" (click)="resetCheckedElements(1)">Consultant Functions</a>
    <a href="#" *ngIf="checkedUserId.id!=null" (click)="resetCheckedElements(2)">{{checkedUserId.name}}</a>
    <a href="#" *ngIf="checkedSiteId.id!=null" (click)="resetCheckedElements(3)">{{checkedSiteId.name}}</a>
    <a href="#" *ngIf="checkedChanelId.id!=null" (click)="resetCheckedElements(4)">{{checkedChanelId.name}}</a>
  </div>
  <div class="left_block">
    <div class="users_block box">
      <ul>
        <li *ngFor="let u of usersList" (click)="clickedInside($event)" [ngClass]="{opened: checkedUserId.id == u.id}">
          <span (click)="getSites(u.id, u.first_name ,1)">{{u.first_name}}</span>
          <div class="actions_wrap">
            <a href="#" class="arrow" (click)="getSites(u.id, u.first_name ,1)"></a>
            <ul class="actions_popup"
              *ngIf="isShowActions.number==1&&current_user!=null&&(current_user.type=='Consultant'||current_user.type=='Administrator')">
              <li>
                <a href="#" class="users_edit" (click)="openUserPopup($event,u.id)"></a>
              </li>
              <li>
                <a href="#" class="users_delete" (click)="openConfirmPopup(u.id,0)"></a>
              </li>
            </ul>
          </div>
        </li>
      </ul>
    </div>
    <div class="playlists_block box" *ngIf="checkedUserId.id!=null">
      <ul *ngIf="sitesList!=null">
        <li *ngFor="let site of sitesList" (click)="clickedInside($event)"
          [ngClass]="{opened: checkedSiteId.id == site.id}">
          <div class="wrap">
            <span (click)="showChanels($event,site.id,site.name,1)">{{site.name}} </span>
          </div>
          <div class="actions_wrap">
            <a href="#" class="arrow" (click)="showChanels($event,site.id,site.name,1)"></a>
            <ul class="actions_popup"
              *ngIf="isShowActions.number==2&&current_user!=null&&(current_user.type=='Consultant'||current_user.type=='Administrator')">
              <li>
                <a href="#" class="playlist_categories_edit" (click)="openSitePopup($event,site.id)"></a>
              </li>
              <li>
                <a href="#" class="playlist_categories_delete" (click)="openConfirmPopup( site.id, 1)"></a>
              </li>
            </ul>
          </div>
          <ul *ngIf="site.children !=0">
            <li *ngFor="let chanel of site.children" (click)="clickedInside($event)"
              [ngClass]="{opened: checkedChanelId.id == chanel.id}">
              <span (click)="showPlaylists($event,chanel.id,chanel.name, 1)">{{chanel.name}}</span>
              <div class="actions_wrap">
                <a href="#" class="arrow" (click)="showPlaylists($event,chanel.id,chanel.name, 1)"></a>
                <ul class="actions_popup"
                  *ngIf="isShowActions.number==3&&current_user!=null&&(current_user.type=='Consultant'||current_user.type=='Administrator')">
                  <li>
                    <a href="#" class="playlist_categories_edit" (click)="openChanelsPopup($event,chanel.id)"></a>
                  </li>
                  <li>
                    <a href="#" class="playlist_categories_delete" (click)="openConfirmPopup( chanel.id, 2)"></a>
                  </li>
                  <li>
                    <a href="#" class="playlist_categories_restore"
                      (click)="openRestoredDatesPopup($event,chanel.id)"></a>
                  </li>
                 
                </ul>
              </div>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
  <div class="playlists_list_block" *ngIf="current_user!=undefined&&checkedChanelId.id!=null"
    [ngClass]="{'client_playlist_block': current_user.type != 'Administrator' && current_user.type != 'Manager' 
    && current_user.type != 'Consultant'}">
   
    <div class="table_wrap" [ngClass]="{selected: tabs.selected == 1}">
      <div class="table_titles">
        <div>Name</div>
        <div>Type</div>
        <div>Owner</div>
        <div>Date Created</div>
        <div>Last Updated</div>
        <div>Actions</div>
      </div>
      <div class="table_content">
        <div class="table_row" *ngFor="let p of playlists">
          <div>{{p.profile_name}}</div>
          <div>Profile Statement</div>
          <div>{{checkedUserId.name}}</div>
          <div>{{p.created_at | date}}</div>
          <div>{{p.updated_at | date}}</div>

          <div>

            <div class="editing_wrap"
              *ngIf="current_user!=null&&(current_user.type=='Consultant'||current_user.type=='Administrator')">
              <a class="playlist_edit" title="Edit" (click)="openProfilePopup($event,p.id)"></a>
            </div>
            <a href="#" class="open_new_window" target="_blank" (click)="openNewWindow()"
              title="Open the CP in New Window"></a>
            
          </div>
        </div>
      </div>
    </div>

  </div>
  <div class="playlists_list_block " *ngIf="current_user!=undefined&&this.checkedSiteId.id!=null"
    [ngClass]="{'client_playlist_block': current_user.type != 'Administrator' && current_user.type != 'Manager'
     && current_user.type != 'Consultant'}"
    style="padding-left: 420px; ">
    <div class="table_wrap " [ngClass]="{selected: tabs.selected == 2}">
      <div class="table_titles">
        <div style="min-width: 27%;"> Document Name </div>
        <div>Type</div>
        <div>Date Created</div>
        <div>Actions</div>
      </div>
      <div class="table_content">
        <div class="table_row" *ngFor="let d of documentList">
          <div style="min-width: 27%;">{{d.document_name}}</div>
          <div>{{d.document_type}}</div>
          <div>{{d.created_at | date}}</div>
          <div *ngIf="current_user!=null&&(current_user.type=='Consultant'||current_user.type=='Administrator')"> 
            <a href="#"><img alt="download" src="../../assets/img/download.png" class="action-icons" (click)="DownloadPDF($event,d.id)" title="Priview/Download"></a> &nbsp;
            <a> <img alt="delete" src="../../assets/img/delete.png" class="action-icons" (click)="openConfirmPopup(d.id,6)" title="Delete"></a>
           
            <a href="/consultant-functions?docID={{d.id}}" class="open_new_window" target="_blank" title="Open the doc in New Window" style="width: 16px;height: 16px;background-size: 16px auto;"></a>
              </div>
        
        </div>
      </div>
    </div>
  </div>
</div>





<div class="popup create_playlist_popup" [ngClass]="{opened: playlistPopupOpened}" ngDraggable ngResizable
  [rzHandles]="'n,e,s,w,se,sw,ne,nw'" [handle]="dragHandle">
  <a href="#" class="popup_close" (click)="popupClose($event)"></a>
  <div class="popup_title" #dragHandle>Create/Edit Playlist</div>
  <div class="popup_form">
    <form #updatePlaylist="ngForm">
      <input type="hidden" name="status" value="playlistFields.status" [(ngModel)]="playlistFields.status">
      <input type="hidden" name="user_id" *ngIf="playlistFields.id != null" value="{{playlistFields.user}}"
        [(ngModel)]="playlistFields.user" />
      <div class="input_wrap">
        <label>Name</label>
        <input type="text" maxlength="50" name="name" value="{{playlistFields.name}}"
          [(ngModel)]="playlistFields.name" />
      </div>
      <div class="input_wrap" *ngIf="playlistFields.id == null">
        <label>Client</label>
        <select name="client_id" *ngIf="usersList!=null" [(ngModel)]="playlistFields.user"
          (change)="userChange($event, 2)">
          <option value="null" [selected]="playlistFields.user==null">Select user...</option>
          <option *ngFor="let u of usersList" value="{{u.id}}" [selected]="playlistFields.user == u.id">
            {{u.first_name}}
          </option>
        </select>
      </div>
      <div class="input_wrap">
        <label>Site</label>
        <select name="site_id" [(ngModel)]="playlistFields.site" (change)="categoryChange($event,1)">
          <option value="null" [selected]="playlistFields.site==null">Select site...</option>
          <option *ngFor="let site of playlistFields.sitesList" value="{{site.id}}"
            [selected]="playlistFields.site==site.id">{{site.name}}</option>
        </select>
      </div>
      <div class="input_wrap">
        <label>Channel</label>
        <select name="chanel_id" [(ngModel)]="playlistFields.chanel">
          <option value="null" [selected]="playlistFields.chanel==null">Select channel...</option>
          <option *ngFor="let c of playlistFields.channelsList" value="{{c.id}}"
            [selected]="playlistFields.chanel==c.id">{{c.name}}</option>
        </select>
      </div>
      
      <div class="popup_btns">
        <button type="button" class="cancel" (click)="popupClose($event)">Cancel</button>
        <button type="submit" (click)="playlistUpdate($event,updatePlaylist)">Save</button>
      </div>
    </form>
  </div>
</div>
<div class="popup create_site_popup" [ngClass]="{opened: sitePopupOpened}" ngDraggable ngResizable
  [rzHandles]="'n,e,s,w,se,sw,ne,nw'" [handle]="dragHandle2">
  <a href="#" class="popup_close" (click)="popupClose($event)"></a>
  <div class="popup_title" #dragHandle2>
    <span *ngIf="siteFields.id!=null">Edit Site</span>
    <span *ngIf="siteFields.id==null">Create New Site</span>
  </div>
  <div class="popup_form">
    <form #updateSite="ngForm">
      <input type="hidden" name="visible" value="{{siteFields.visible}}" [(ngModel)]="siteFields.visible">
      <input type="hidden" name="user_id" *ngIf="siteFields.id != null" value="{{siteFields.user}}"
        [(ngModel)]="siteFields.user" />
      <div class="left_form">
        <div class="input_wrap">
          <label>Name</label>
          <input type="text" maxlength="50" name="name" value="{{siteFields.name}}" [(ngModel)]="siteFields.name" />
        </div>
        <div class="input_wrap">
          <label>Address</label>
          <input type="text" name="address_1" value="{{siteFields.address}}" [(ngModel)]="siteFields.address" />
        </div>
        <div class="input_wrap">
          <label>Address 2</label>
          <input type="text" name="address_2" value="{{siteFields.address_2}}" [(ngModel)]="siteFields.address_2" />
        </div>
        <div class="input_wrap">
          <label>Address 3</label>
          <input type="text" name="address_3" value="{{siteFields.address_3}}" [(ngModel)]="siteFields.address_3" />
        </div>
        <div class="input_wrap">
          <label>Address 4</label>
          <input type="text" name="address_4" value="{{siteFields.address_4}}" [(ngModel)]="siteFields.address_4" />
        </div>
      </div>
      <div class="right_form">
        <div class="input_wrap">
          <label>City</label>
          <input type="text" name="city" value="{{siteFields.city}}" [(ngModel)]="siteFields.city" />
        </div>
        <div class="input_wrap">
          <label>County</label>
          <input type="text" name="county" value="{{siteFields.county}}" [(ngModel)]="siteFields.county" />
        </div>
        <div class="input_wrap">
          <label>Postcode</label>
          <input type="text" maxlength="10" (keypress)="keyPress($event)" name="postcode"
            value="{{siteFields.postcode}}" [(ngModel)]="siteFields.postcode" />
        </div>
        <div class="input_wrap">
          <label>Country</label>
          <input type="text" name="country" value="{{siteFields.country}}" [(ngModel)]="siteFields.country" />
        </div>
        <div class="input_wrap" *ngIf="siteFields.id == null">
          <label>Client</label>
          <select name="client_id" *ngIf="usersList!=null" [(ngModel)]="siteFields.user">
            <option value="null" [selected]="siteFields.user==null">Select user...</option>
            <option *ngFor="let u of usersList" value="{{u.id}}" [selected]="siteFields.user==u.id">{{u.first_name}}
            </option>
          </select>
        </div>
      </div>
      <div class="popup_btns">
        <button type="button" class="cancel" (click)="popupClose($event)">Cancel</button>
        <button type="submit" (click)="formSiteUpdate($event, updateSite)">Save</button>
      </div>
    </form>
  </div>
</div>
<div class="popup create_chanel_popup" [ngClass]="{opened: chanelPopupOpened}" ngDraggable ngResizable
  [rzHandles]="'n,e,s,w,se,sw,ne,nw'" [handle]="dragHandle3">
  <a href="#" class="popup_close" (click)="popupClose($event)"></a>
  <div class="popup_title" #dragHandle3>
    <span *ngIf="chanelFields.id!=null">Edit Channel</span>
    <span *ngIf="chanelFields.id==null">Create New Channel</span>
  </div>
  <div class="popup_form">
    <form #updateChanel="ngForm">
      <input type="hidden" name="visible" value="{{chanelFields.visible}}" [(ngModel)]="chanelFields.visible">
      <input type="hidden" name="user_id" *ngIf="chanelFields.id != null" value="{{chanelFields.user}}"
        [(ngModel)]="chanelFields.user" />
      <div class="input_wrap">
        <label>Name</label>
        <input type="text" maxlength="50" name="name" value="{{chanelFields.name}}" [(ngModel)]="chanelFields.name" />
      </div>
      <div class="input_wrap" *ngIf="chanelFields.id == null">
        <label>Client</label>
        <select name="client_id" *ngIf="usersList!=null" [(ngModel)]="chanelFields.user"
          (change)="userChange($event, 1)">
          <option value="null" [selected]="chanelFields.user==null">Select user...</option>
          <option *ngFor="let u of usersList" value="{{u.id}}" [selected]="chanelFields.user==u.id">{{u.first_name}}
          </option>
        </select>
      </div>
      <div class="input_wrap">
        <label>Parent site node</label>
        <select name="parent_id" [(ngModel)]="chanelFields.site">
          <option value="null" [selected]="chanelFields.site == null">Select site...</option>
          <option *ngFor="let s of chanelFields.sitesList" value="{{s.id}}" [selected]="chanelFields.site==s.id">
            {{s.name}}</option>
        </select>
      </div>
      <div class="popup_btns">
        <button type="button" class="cancel" (click)="popupClose($event)">Cancel</button>
        <button type="submit" (click)="formChanelUpdate($event, updateChanel)">Save</button>
      </div>
    </form>
  </div>
</div>
<div class="popup create_user_popup" [ngClass]="{opened: userPopupOpened}" ngDraggable ngResizable
  [rzHandles]="'n,e,s,w,se,sw,ne,nw'" [handle]="dragHandle4">
  <a href="#" class="popup_close" (click)="popupClose($event)"></a>
  <div class="popup_title" #dragHandle4>
    <span *ngIf="userFields.id!=null">Edit Client</span>
    <span *ngIf="userFields.id==null">Create New Client</span>
  </div>
  <div class="popup_form">
    <form #updateUser="ngForm">
      <input type="hidden" name="active" value="{{userFields.active}}" [(ngModel)]="userFields.active" />
      <input type="hidden" name="type" value="{{userFields.type}}" [(ngModel)]="userFields.type">
      <div class="left_form">
        <div class="input_wrap">
          <label>Name</label>
          <input type="text" maxlength="50" name="name" value="{{userFields.name}}"
            [(ngModel)]="userFields.name" />
        </div>
        <div class="input_wrap">
          <label>Address</label>
          <input type="text" name="address" value="{{userFields.address}}" [(ngModel)]="userFields.address" />
        </div>
        <div class="input_wrap">
          <label>Address 2</label>
          <input type="text" name="address_2" value="{{userFields.address_2}}" [(ngModel)]="userFields.address_2" />
        </div>
        <div class="input_wrap">
          <label>Address 3</label>
          <input type="text" name="address_3" value="{{userFields.address_3}}" [(ngModel)]="userFields.address_3" />
        </div>
        <div class="input_wrap">
          <label>Address 4</label>
          <input type="text" name="address_4" value="{{userFields.address_4}}" [(ngModel)]="userFields.address_4" />
        </div>
      </div>
      <div class="right_form">
        <div class="input_wrap">
          <label>City</label>
          <input type="text" name="city" value="{{userFields.city}}" [(ngModel)]="userFields.city" />
        </div>
        <div class="input_wrap">
          <label>County</label>
          <input type="text" name="county" value="{{userFields.county}}" [(ngModel)]="userFields.county" />
        </div>
        <div class="input_wrap">
          <label>Postcode</label>
          <input type="text" maxlength="10" (keypress)="keyPress($event)" name="postcode"
            value="{{userFields.postcode}}" [(ngModel)]="userFields.postcode" />
        </div>
        <div class="input_wrap">
          <label>Country</label>
          <input type="text" name="country" value="{{userFields.country}}" [(ngModel)]="userFields.country" />
        </div>
      </div>
      <div class="popup_btns">
        <button type="button" class="cancel" (click)="popupClose($event)">Cancel</button>
        <button type="submit" (click)="formUserUpdate($event,updateUser)">Save</button>
      </div>
    </form>
  </div>
</div>

<!-- file upload pop-up -->
<div class="popup" [ngClass]="{opened: fileUploadPopupOpened}" ngDraggable ngResizable
  [rzHandles]="'n,e,s,w,se,sw,ne,nw'" [handle]="dragHandle14">
  <a href="#" class="popup_close" (click)="popupClose($event)"></a>
  <div class="popup_title" #dragHandle14>
    <span>Brief Statement Upload</span>
  </div>
 

  <table style="width: 100%" aria-describedby="file upload pop-up">
    <tr>
      <th scope="col">No</th>
      <th scope="col" style="width:17%">Name</th>
      <th scope="col">Doc Type</th>
      <th scope="col">Uploaded Date</th>
      <th scope="col">Actions</th>
    </tr>

    <tr *ngFor="let d of documentList let i = index">
      <td>{{ i + 1 }}</td>

      <td>{{d.document_name}}</td>
      <td>{{d.document_type}}</td>
      <td>{{d.updated_at | date}}</td>

      <td *ngIf="current_user!=null&&(current_user.type=='Consultant'||current_user.type=='Administrator')"><a><img alt="download"
            src="../../assets/img/download.png" class="action-icons" (click)="DownloadPDF($event,d.id)"
            title="Priview/Download"></a> &nbsp;<a> <img alt="delete" src="../../assets/img/delete.png" class="action-icons"
            (click)="openConfirmPopup(d.id,6)" title="Delete"></a> </td>
    </tr>
  </table>

  <div class="popup_btns">
    <button type="button" class="cancel" (click)="popupClose($event)">Close</button>

  </div>
  <br>
</div>



<div class="popup search_playlist_popup" [ngClass]="{opened: searchPopupOpened}" ngDraggable ngResizable
  [rzHandles]="'n,e,s,w,se,sw,ne,nw'" [handle]="dragHandle5">
  <a href="#" class="popup_close" (click)="popupClose($event)"></a>
  <div class="popup_title" #dragHandle5>Search: {{searchStr}}</div>
  <div class="popup_form">
    <div class="table_wrap search_table">
      <div class="table_titles">
        <div>Name</div>
        <div>Client</div>
        <div>Channel</div>
        <div>Site</div>
        <div>Date Added</div>
        <div>Date Updated</div>
        <div># of Tracks</div>
        <div>Status</div>
        <div>Functions</div>
        <div>Sourced/Linked</div>
      </div>
      <div class="table_content" *ngIf="searchedPlaylists.length > 0">
        <div class="table_row" *ngFor="let sp of searchedPlaylists">
          <div>{{sp.name}}</div>
          <div>{{sp.user!=null ? sp.user.first_name : '' }} {{sp.user!=null ? sp.user.last_name : '' }}</div>
          <div>{{sp.cp_category!=null ? sp.cp_category.name : ''}}</div>
          <div>{{sp.site_name}}</div>
          <div>{{sp.created_at}}</div>
          <div>{{sp.updated_at}}</div>
          <div>{{sp.file_counts}}</div>
          <div>{{ playlistStatusText[sp.status]}}</div>
          <div>
            <a class="playlist_manage" title="Manage" (click)="manageClick(sp.id)"
              routerLink="/manage-playlists">Manage</a>&nbsp;
            <span class="playlist_suspend" *ngIf="sp.status=='1'">{{playlistSuspendingText[sp.status]}}</span>
            <a class="playlist_suspend" *ngIf="sp.status=='0'" (click)="setStatus($event,sp.id,2)" title="Suspend"
              href="#">{{playlistSuspendingText[sp.status]}}</a>
            <a class="playlist_suspend" *ngIf="sp.status=='2'" (click)="setStatus($event,sp.id,0)" title="Unsuspend"
              href="#">{{playlistSuspendingText[sp.status]}}</a>
            <div class="editing_wrap">
              <a class="edit_icon" title="Edit" href="#" (click)="openPlaylistPopup($event,sp.id)"></a>
              <ul>
                <li (click)="setStatus($event,sp.id,0)">LIVE</li>
                <li (click)="setStatus($event,sp.id,1)">EDITING</li>
                <li (click)="setStatus($event,sp.id,2)">SUSPENDED</li>
              </ul>
            </div>
            <a class="delete_icon" title="Delete" href="#" (click)="openConfirmPopup(sp.id,3)"></a>
          </div>
          <div>
            <div class="linked_foundation_wrap" [ngClass]="{selected: selectedFoundation == sp.id}"
              (click)="showFoundation(sp.id)">
              <a routerLink="/manage-playlists" (click)="manageFPClick(sp.foundation_ids[0].id)"
                *ngIf="sp.foundation_ids.length > 0 && sp.foundation_ids.length < 2">{{sp.foundation_ids[0].name}}</a>
              <span *ngIf="sp.foundation_ids.length > 1">{{sp.foundation_ids[0].name}}</span>
              <ul *ngIf="sp.foundation_ids.length > 1">
                <li *ngFor="let fi of sp.foundation_ids"><a routerLink="/manage-playlists"
                    (click)="manageFPClick(fi.id)">{{fi.name}}</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="table_content nf" *ngIf="searchedPlaylists.length <= 0">
        <span>No results found</span>
      </div>
    </div>
    <div class="popup_btns">
      <button type="button" class="cancel" (click)="popupClose($event)">Close</button>
    </div>
  </div>
</div>

<app-player-component [isInputFocused]="isFocused" [storage]="playerInfo.storage" [token]="playerInfo.token"
  [track]="playerInfo.trackId" [wave]="playerInfo.trackId" [album]="playerInfo.album" [artist]="playerInfo.artist"
  (outputPlaying)="checkPlaying($event)" (next)="nextTrack($event)" (prev)="prevTrack($event)">
</app-player-component>
<app-alert></app-alert>
<app-confirm (isConfirm)="isConfirm($event)"></app-confirm>

<div class="popup restored_tracks_popup" [ngClass]="{opened: popups.restoredTracksPopupOpened}" ngDraggable ngResizable
  [rzHandles]="'n,e,s,w,se,sw,ne,nw'" [handle]="dragHandle6">
  <a href="#" class="popup_close" (click)="popupClose($event)"></a>
  <div class="popup_title" #dragHandle6>&nbsp;</div>
  <div class="popup_form">
    <div class="table_wrap restored_playlist_table">
      <div class="table_titles">
        <div>Track name</div>
        <div>Field name</div>
        <div>Prev Value</div>
        <div>Next value</div>
        <div>Modified date</div>
      </div>
      
    </div>
    <div class="popup_btns">
      <button type="button" class="cancel" (click)="popupClose($event)">Close</button>
    </div>
  </div>
</div>
<div class="popup restored_playlist_popup" [ngClass]="{opened: popups.restoredPlaylistPopupOpened}" ngDraggable
  ngResizable [rzHandles]="'n,e,s,w,se,sw,ne,nw'" [handle]="dragHandle7">
  <a href="#" class="popup_close" (click)="popupClose($event)"></a>
  <div class="popup_title" #dragHandle7>&nbsp;</div>
  <div class="popup_form">
    <div class="table_wrap restored_playlist_table">
      <div class="table_titles">
        <div>Playlist name</div>
        <div>Track name</div>
        <div>Allocated date</div>
        <div>Dellocated date</div>
        <div>Actions</div>
        <div>Username</div>
      </div>
      <div class="table_content">
        <div class="table_row" *ngFor="let rpt of restoredPlaylistTracks">
          <div>{{rpt.playlist_name}}</div>
          <div (click)="openTrackRestorePopup($event,rpt.id,null)">{{rpt.title}}</div>
          <div>{{rpt.updated_at}}</div>
          <div>{{rpt.deleted_at}}</div>
          <div>
            <a class="track_restore" title="Restore">Restore</a>
          </div>
          <div>{{rpt.user_name}}</div>
        </div>
      </div>
    </div>
    <div class="popup_btns">
      <button type="button" class="cancel" (click)="popupClose($event)">Close</button>
    </div>
  </div>
</div>
<div class="popup restored_chanels_popup" [ngClass]="{opened: popups.restoredChanelsPopupOpened}" ngDraggable
  ngResizable [rzHandles]="'n,e,s,w,se,sw,ne,nw'" [handle]="dragHandle8">
  <a href="#" class="popup_close" (click)="popupClose($event)"></a>
  <div class="popup_title" #dragHandle8>&nbsp;</div>
  <div class="popup_form">
    <div class="table_wrap restored_playlist_table">
      <div class="table_titles">
        <div>Channel</div>
        <div>Site</div>
        <div>Playlist name</div>
        <div>Allocated date</div>
        <div>Dellocated date</div>
        <div>Actions</div>
        <div>Username</div>
      </div>
      <div class="table_content">
        <div class="table_row" *ngFor="let rp of restoredChanels">
          <div>{{rp.chanel}}</div>
          <div>{{rp.site}}</div>
          <div><a href="#" (click)="openPlaylistRestorePopup($event,rp.id,mysqlRestoredDate)">{{rp.name}}</a></div>
          <div>{{rp.updated_at}}</div>
          <div>{{rp.deleted_at}}</div>
          <div>
            <a class="plalist_restore" title="Restore">Restore</a>
          </div>
          <div>{{rp.first_name}} {{rp.last_name}}</div>
        </div>
      </div>
    </div>
    <div class="popup_btns">
      <button type="button" class="cancel" (click)="popupClose($event)">Close</button>
    </div>
  </div>
</div>
<div class="popup restored_dates_popup" [ngClass]="{opened: popups.restoredDatesPopupOpened}" ngDraggable ngResizable
  [rzHandles]="'n,e,s,w,se,sw,ne,nw'" [handle]="dragHandle9">
  <a href="#" class="popup_close" (click)="popupClose($event)"></a>
  <div class="popup_title" #dragHandle9>&nbsp;</div>
  <div class="popup_form">
    <ul class="restored_dates">
      <li *ngFor="let d of historyDates" (click)="openChanelsRestorePopup($event,d.cp_category_id,d.modify_date_mysql)">
        {{d.modify_date}}</li>
    </ul>
    <div class="popup_btns">
      <button type="button" class="cancel" (click)="popupClose($event)">Close</button>
    </div>
  </div>
</div>




<div class="popup edit_track_popup profile_popup" [ngClass]="{opened: popups.profileStatementOpened}" ngDraggable
  ngResizable [rzHandles]="'n,e,s,w,se,sw,ne,nw'" [handle]="dragHandle9">
  <a href="#" class="popup_close" (click)="popupClose($event)"></a>
  <div class="popup_title" #dragHandle9>Profile Statement</div>
  <div class="popup_form">
    <form #profileStatement="ngForm">
      <div class="left_form" style="width:45%">
        <div class="input_wrap">
          <label>Client</label>
          <label>{{checkedUserId.name}}</label>
        </div>
        <div class="input_wrap">
          <label>Channel</label>
          <label> {{checkedChanelId.name}}</label>
        </div>
        <div class="input_wrap">
          <label>Playlist Duration</label>
          <input type="text" name="dur" value="{{(getTotalCover()/60/60).toFixed(2)}}" disabled="disabled">
          <label> hrs</label>
        </div>
      </div>
      <div class="right_form" style="width:45%">
        <div class="input_wrap">
          <label>Site</label>
          <label>{{checkedSiteId.name}}</label>
        </div>
        <div class="input_wrap">
          <label>Playlist</label>
          <label>{{  this.playlistName}}</label>
        </div>
        <div class="input_wrap">
          <label>Profile Name</label>
          <label>{{ this.profileName  }} <a href="#" class="playlist_edit" title="Edit"
              (click)="openProfileEditPopup($event)"></a></label>

        </div>
      </div>
      <div class="history_botton_right">
        <button type="button" class="History" (click)="openProfileHistoryPopup()" title="History">History</button>
      </div>

      <div class="foundation_playlist_selects">
        <div class="row titles">
          <div>Foundation playlist</div>
          <div>Cover</div>
          <div>Ratio %</div>
          <div>Notes</div>
          <div>&nbsp;</div>
        </div>
        <div class="">
          <div class="row" *ngFor="let ps of profileStatements;let i=index">
            <div>
              <div class="select_fp">
                <span class="fp_name" (click)="psOpenDrop(i)">{{psSelects[i].title}}</span>
                <div class="list_wrap" [ngClass]="{opened: psSelects[i].dropOpened}">
                  <ul class="bor-adjust">
                    <li *ngFor="let fc of fpCategories" [ngClass]="{selected: psSelects[i].catId == fc.id}"
                      class="font-adj">
                      <span (click)="psSelectCategory(fc.id,i)">{{fc.name}}</span>
                      <ul *ngIf="fc.children.length>0" style="margin: 0px;">
                        <li *ngFor="let pcc of fc.children" [ngClass]="{selected: psSelects[i].subcatId == pcc.id}"
                          class="font-adj" style="padding: 0px;">
                          <span (click)="psSelectSubcategory(pcc.id,i)">{{pcc.name}}</span>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <ul class="playlists bor-adjust ">
                    <li *ngFor="let fp of psSelects[i].fp_arr" (click)="psSelectPlaylist(fp.id,i,fp.name)"
                      [ngClass]="{selected: psSelects[i].fp_id == fp.id}" class="font-adj">
                      <span>{{fp.name}}</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div>
              <input (focus)="updateInputs(i,1)" (blur)="sendCalc(i,false,1)" type="text" name="cover-{{i}}"
                [(ngModel)]="profileStatements[i].cover" />
            </div>
            <div>
              <input (focus)="updateInputs(i,2)" (blur)="sendCalc(i,false,2)" type="text" name="ratio-{{i}}"
                [(ngModel)]="profileStatements[i].ratio" />
            </div>
            <div>
              <input type="text" name="note-{{i}}" [(ngModel)]="profileStatements[i].note" />
            </div>
            <div>
              <a href="#" class="btn_remove" *ngIf="profileStatements.length>1"
                (click)="removeProfile(i,ps.id,true)"></a>
              <a href="#" class="btn_add" (click)="addProfile()"></a>
            </div>
          </div>
        </div>
        <div class="row last">
          <div>&nbsp;</div>
          <div>
            <label>Total</label>
          </div>
          <div>
            <label>{{getTotalRatio()}}</label>
          </div>
          <div></div>
        </div>
      </div>
      <div class="popup_btns">
        <button type="button" class="cancel" (click)="popupClose($event)">Cancel</button>
        <button type="submit" (click)="saveProfileStatement()"
          *ngIf="current_user!=null&&(current_user.type=='Consultant'||current_user.type=='Administrator')">Save</button>
      </div>
    </form>
  </div>
</div>


<!-- History pop up -->
<div class="popup edit_track_popup profile_popup" [ngClass]="{opened: popups.profileHistoryopened}" ngDraggable
  ngResizable [rzHandles]="'n,e,s,w,se,sw,ne,nw'" [handle]="dragHandle11">
  <a href="#" class="popup_close" (click)="popupClose($event)"></a>
  <div class="popup_title" #dragHandle11>History</div>
  <div class="popup_form">
    <div>
      <div class="left_form">
        <div class="input_wrap">
          <label>Client</label>
          <label>{{checkedUserId.name}}</label>
        </div>
        <div class="input_wrap">
          <label>Channel</label>
          <label>{{checkedChanelId.name}}</label>
        </div>
      </div>
      <div class="right_form">
        <div class="input_wrap">
          <label>Site</label>
          <label>{{checkedSiteId.name}}</label>
        </div>
        <div class="input_wrap">
          <label>Playlist</label>
          <label>{{this.playlistName}}</label>
        </div>
      </div>

      <div class="foundation_playlist_selects">
        <div class="row titles foundation-inline-width">
          <div>Fplaylist</div>
          <div>Cover</div>
          <div>Ratio %</div>
          <div>created_by</div>
          <div>Created_Date</div>
          <div>Modified_Date</div>
          <div>Action</div>
          <div>Name</div>
          <div style="width: 0%;"></div>
        </div>
        <div class="table_content" style="height:160px">
          <div class="table_row foundation-inline-width" *ngFor="let u of profileActiveStatus">
            <div class="wd-22">{{u.foundation_name}}</div>
            <div class="wd-22">{{u.cover}}</div>
            <div class="wd-22">{{u.ratio}}</div>
            <div class="wd-22">{{u.first_name}}</div>
            <div class="wd-22">{{u.created_at}} </div>
            <div class="wd-22"> NULL </div>
            <div class="wd-22">FP Active</div>
            <div class="wd-22">{{ u.first_name}}</div>
          </div>

          <div class="table_row foundation-inline-width" *ngFor="let u of profileModifiedStatus">
            <div class="wd-22">{{u.foundation_name}}</div>
            <div class="wd-22">{{u.cover}}</div>
            <div class="wd-22">{{u.ratio}}</div>
            <div class="wd-22">{{u.first_name}}</div>
            <div class="wd-22">{{u.created_at }} </div>
            <div class="wd-22">{{u.updated_at }} </div>
            <div class="wd-22">{{u.status}}</div>
            <div class="wd-22">{{ u.Modified_Fname}}</div>
          </div>

          <div class="table_row foundation-inline-width" *ngFor="let u of profileDeleteStatus">
            <div class="wd-22">{{u.foundation_name}}</div>
            <div class="wd-22">{{u.cover}}</div>
            <div class="wd-22">{{u.ratio}}</div>
            <div class="wd-22">{{u.Create_Fname}}</div>
            <div class="wd-22">{{u.created_at }} </div>
            <div class="wd-22">{{u.updated_at }}</div>
            <div class="wd-22">Deleted</div>
            <div class="wd-22">{{ u.first_name}}</div>
          </div>
        </div>
      </div>
      <div class="popup_btns">
        <button type="button" class="cancel" (click)="popupClose($event)">Cancel</button>
      </div>
    </div>
  </div>
</div>

<div class="popup create_profileStatement_popup" [ngClass]="{opened: popups.profileCreatePopupOpened}" ngDraggable
  ngResizable [rzHandles]="'n,e,s,w,se,sw,ne,nw'" [handle]="dragHandle14">
  <a href="#" class="popup_close" (click)="popupClose($event)"></a>
  <div class="popup_title" #dragHandle14>Edit Profile </div>
  <div class="popup_form">
    <form #updateProfileStament="ngForm">
      <input type="hidden" name="visible" value="{{profileStatementFields.visible}}"
        [(ngModel)]="profileStatementFields.visible">
      <div class="input_wrap">
        <label>Profile Name</label>
        <input type="text" maxlength="50" name="name" value="{{this.profileName}}"
          [(ngModel)]="profileStatementFields.name" />

      </div>
      <div class="popup_btns">
        <button type="button" class="cancel" (click)="popupClose($event)">Cancel</button>
        <button type="submit" (click)="profileUpdate($event, updateProfileStament)">Save</button>
      </div>
    </form>
  </div>
</div>

<!-- end  -->

<!-- end  -->

</div>