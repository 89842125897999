

    <div class="popup_form2">
      <dropzone
        [config]="config"
        [message]="'Click or drag files here to upload'"
        (error)="onUploadError($event)"
       
        (success)="onUploadSuccess($event)" (addedFile)="addFile()"  style="height: 163px;" (sending)="onSending($event)">
      </dropzone>
     
      <div class="popup_btns" style="float: right">
        
        <button type="button" class="cancel" (click)="cancelUpload($event)">Clear Uploads</button>
        <br>
      </div>
    </div>
    
  <app-alert></app-alert>
  
  