import {Component, EventEmitter, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss']
})
export class ConfirmComponent implements OnInit {
  opened = false;//Open confirmation popup
  message = '';//Confirmation message
  @Output() isConfirm = new EventEmitter<boolean>();//Confirm event
  
  ngOnInit() {
    this.isConfirm.emit(false);
  }

  /**
   Open confirm popup
   * @param message - confirmation message
   * @returns {boolean}
   */
  public confirmOpen(message){
    this.message = message;
    this.opened = true;
    this.isConfirm.emit(false);
    return false;
  }

  /**
   Close confirmation popup
   * @returns {boolean}
   */
  public confirmClose(){
    this.opened = false;
    return false;
  }

  /**
   Confirmation event
   * @returns {boolean}
   */
  confirm(){
    this.isConfirm.emit(true);
    this.opened = false;
    return false;
  }
}
