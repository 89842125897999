import { Component, OnInit, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";
import { ActivatedRoute, NavigationExtras, Router } from "@angular/router";
import { AlertComponent } from "../alert/alert.component";
import { ConfirmComponent } from "../confirm/confirm.component";
import { CommonService } from "../services/common.service";
import { DistributionGroupService } from "../services/distribution_group.service";
import { CPPlaylistCategoriesService } from "../playlist-categories/cp_playlist-categories.service";
import { CPPlaylistsService } from "../services/cp_playlists.service";
import { ExcelService } from "../services/excel.service";
@Component({
  selector: "app-distribution-group",
  templateUrl: "./distribution-group.component.html",
  styleUrls: ["./distribution-group.component.scss"],
  providers: [
    DistributionGroupService,
    CommonService,
    CPPlaylistCategoriesService,
    CPPlaylistsService,
    ExcelService,
  ],
})
export class DistributionGroupComponent implements OnInit {
  // DEFINE VARIABLES START

  createPopupOpened = false;
  dgFormFields = {
    id: null,
    name: "",
  };
  dgsCount = {
    total: 0,
    display: 0,
  };
  dgsOrder = {
    order: "DESC",
    orderBy: "updated_at",
  };
  isLoad = false; //Display preloader
  infiniteScroll = {
    items: 0,
    addItems: 100,
  };
  dgs = []; //DG list
  dg: any = {
    id: null,
    name: "",
    entities: [
      {
        id: null,
        client_id: null,
        site_id: null,
        channel_id: null,
        client_name: null,
        site_name: null,
        channel_name: null,
        masterlength: null,
        mastersdata: null,
        showbuildbutton:1,
        showhawkbutton:1,
        build:false,
        buildResponse:false,
        hawk:false,
        hawkResponse:false
      },
    ],
  }; //DG Info
  clientsRaw: any = [];
  clients: any = [];
  sites: any = [];
  channels: any = [];
  dg_id = null;
  masterscount = null;
  showForm = false;
  addForm = {
    client_id: null,
    site_id: null,
    channel_id: null,
  };

  confirmValues = {
    id: null,
    type: 0,
  }; // Confirmation popup
  current_user = null; // information of logged user
  // DEFINE VARIABLES END
  distributionSearchStr = ""; //search keyword
  /**
   Set param if input focused
   */
  isFocused = false;
  focusInput() {
    this.isFocused = true;
  }
  /**
   Set param if input blured
   */
  blurInput() {
    this.isFocused = false;
  }
  tooltipOptions = {
    placement: "right",
    "show-delay": 800,
    //'theme':'light'
    width: "300",
  };

  logResponse: any = null;
  statuschange: any = null;
  hawkstatuschange: any = null;
  hawkAllstatuschange = false;
  buildallstatuschange = false;

  @ViewChild(AlertComponent, { static: true }) alert: AlertComponent; //Component of alert popup
  @ViewChild(ConfirmComponent, { static: true }) confirm: ConfirmComponent; //Component of confirm popup
  public isLoadCHawk: boolean = false;
  
  constructor(
    private dgService: DistributionGroupService,
    private commonService: CommonService,
    private channelService: CPPlaylistCategoriesService,
    private cpplaylistService: CPPlaylistsService,
    private router: Router,
    private route: ActivatedRoute,
    private excelService: ExcelService
  ) {}

  ngOnInit() {
    this.getClients(0);
    this.route.queryParams.subscribe((params) => {
      this.dg_id = params["dg_id"];
      if (params["dg_id"] != null && params["dg_id"] != "") {
        this.showForm = true;
        this.getDgInfo(params["dg_id"]);
      } else {
        this.loadDGs(false);
      }
    });
  }

  // PRIMARY FUNCTIONS START
  openCreateDgPopup(e) {
    this.createPopupOpened = !this.createPopupOpened;
    return false;
  }

  createDg(e, form: NgForm) {
    this.isLoad = true;
    if (this.dgFormFields.name == "") {
      this.alert.alertOpen("Please enter name", 1);
      return false;
    }
    if (this.dgFormFields.id == null) {
      var str = Object.keys(form.value)
        .map(function (key) {
          var val = encodeURIComponent(form.value[key]);
          if (val == "null" || val == null) {
            val = "";
          }
          return encodeURIComponent(key) + "=" + val;
        })
        .join("&");

      this.dgService.createDg(str).subscribe((dg: any) => {
        this.isLoad = false;
        if (dg.error == undefined) {
          this.popupClose(e);
          this.alert.alertOpen("Distribution group created", 0);
          this.loadDGs(true);
        } else {
          this.alert.alertOpen(dg.error, 1);
        }
      });
    }
  }

  updateDg(form: NgForm) {
    this.isLoad = true;
    if (this.dg.name == "") {
      this.isLoad = false;
      this.alert.alertOpen("Please enter name", 1);
      return false;
    }

    if (this.dg.id != null) {
      let str = this.createStr();

      this.dgService.updateDg(this.dg_id, str).subscribe((dg: any) => {
        this.isLoad = false;
        if (dg.error == undefined) {
          this.alert.alertOpen("Distribution group updated", 0);
          this.goBack();
        } else {
          this.alert.alertOpen(dg.error, 1);
        }
      });
    } else {
      this.alert.alertOpen("Invalid ID", 1);
      return false;
    }
  }

  onScroll(e) {
    if (this.distributionSearchStr != "") {
      this.distributionSearch(this.distributionSearchStr);
    } else {
      this.appendItems();
    }
  }
  appendItems() {
    this.infiniteScroll.items += this.infiniteScroll.addItems;
    this.loadDGs(1);
  }

  clearDistributionSearch(e) {
    this.isLoad = true;
    e.preventDefault();
    this.distributionSearchStr = "";
    this.loadDGs(1);
  }

  distributionSearch(e) {
    this.isLoad = true;
    if (this.distributionSearchStr != "") {
      this.dgService
        .distributionSearch(this.distributionSearchStr.toLowerCase())
        .subscribe((dgs: any) => {
          this.isLoad = false;
          this.dgsCount.total = dgs.total;
          this.dgsCount.display = dgs.total;
          this.dgs = dgs.list;
        });
    } else {
      this.loadDGs(true);
    }
  }

  loadDGs(loaded) {
    this.isLoad = true;
    var offset = this.infiniteScroll.items;
    var limit = this.infiniteScroll.addItems;
    if (loaded) {
      offset = 0;
      limit = this.infiniteScroll.items + this.infiniteScroll.addItems;
    }
    this.dgService
      .getDGs(this.dgsOrder.order, this.dgsOrder.orderBy, offset, limit)
      .subscribe((dgs: any) => {
        this.isLoad = false;
        this.dgsCount.total = dgs.total;
        this.dgsCount.display = dgs.display;
        if (offset == 0) this.dgs = dgs.list;
        else this.dgs = this.dgs.concat(dgs.list);
      });
  }

  setOrder(order) {
    if (this.dgsOrder.orderBy == order) {
      if (this.dgsOrder.order == "DESC") this.dgsOrder.order = "ASC";
      else this.dgsOrder.order = "DESC";
    } else {
      this.dgsOrder.orderBy = order;
    }
    this.infiniteScroll.items = 0;
    //if( this.searchStr == '' && !this.isSmartSearch )
    this.loadDGs(false);
  }

  deleteDg(id) {
    this.isLoad = true;
    this.dgService.deleteDg(id).subscribe((playlistDel) => {
      this.isLoad = false;
      this.alert.alertOpen("Distribution group deleted", 0);
      this.loadDGs(true);
    });
  }

  editDg(id) {
    let navigationExtras: NavigationExtras = {
      queryParams: {
        dg_id: id,
      },
    };
    this.router.navigate(["edit-dg"], navigationExtras);
  }

  getDgInfo(id) {
    this.isLoad = true;
    if (id == null || id == "") {
      this.isLoad = false;
      this.alert.alertOpen("ID not found error", 1);
      return false;
    }
    this.dgService.getDgInfo(id).subscribe((dg: any) => {
      this.isLoad = false;

      this.dg.id = dg.id;
      this.dg.name = dg.name;

      let entity = [
        // {
        // id:null,
        // client_id:null,
        // site_id:null,
        // channel_id:null,
        // client_name:null,
        // site_name:null,
        // channel_name:null
        // }
      ];
      if (dg.entities.length > 0) {
        entity = [];
      }

      for (let index = 0; index < dg.entities.length; index++) {
        const element = dg.entities[index];

        entity.push({
          id: element.id,
          client_id: element.client_id,
          site_id: element.site_id,
          channel_id: element.channel_id,
          client_name: element.client_name,
          site_name: element.site_name,
          channel_name: element.channel_name,
          masterlength: element.masters.length,
          mastersdata: element.masters,
          build:false,
          buildResponse:false,
          hawk:false,
          hawkResponse:false
        });
      }

      this.dg.entities = entity;
      this.buildstatus(entity);
    });
  }

  getClients(index) {
    this.isLoad = true;
    this.clients = [];
    this.channelService.getClientsList().subscribe((clients) => {
      this.clients = clients;

      this.isLoad = false;
    });
  }

  getSites(client_id, index, loadChannels = false, site_id = 0) {
    if (client_id == null || client_id == "" || client_id == 0) {
      this.alert.alertOpen("Please select client", 1);
      return false;
    }
    this.isLoad = true;
    this.sites = [];
    this.channelService.getSitesByUser(client_id).subscribe((sites: any) => {
      this.isLoad = false;

      this.sites = sites;
      if (loadChannels && site_id > 0) {
        this.getChannels(site_id, index);
      }
    });
  }

  getChannels(site_id, index) {
    if (site_id == null || site_id == "" || site_id == 0) {
      this.alert.alertOpen("Please select site", 1);
      return false;
    }
    let site = this.sites.find((site) => site.id == site_id);

    if (site != undefined) {
      this.channels = site.children;
    }
  }
  // PRIMARY FUNCTIONS END

  // SECONDARY FUNCTIONS START
  addRecord() {
    if (this.addForm.client_id == "" || this.addForm.client_id == null) {
      this.isLoad = false;
      this.alert.alertOpen("Please select client", 1);
      return false;
    }
    if (this.addForm.site_id == "" || this.addForm.site_id == null) {
      this.isLoad = false;
      this.alert.alertOpen("Please select site", 1);
      return false;
    }
    if (this.addForm.channel_id == "" || this.addForm.channel_id == null) {
      this.isLoad = false;
      this.alert.alertOpen("Please select channel", 1);
      return false;
    }
    let entityExist = this.dg.entities.find(
      (entity) =>
        entity.client_id == this.addForm.client_id &&
        entity.site_id == this.addForm.site_id &&
        entity.channel_id == this.addForm.channel_id
    );
    if (entityExist != undefined) {
      this.alert.alertOpen("The entity already exists in this group", 1);
      return false;
    }

    let client = this.clients.find(
      (client) => client.id == this.addForm.client_id
    );
    let site = this.sites.find((site) => site.id == this.addForm.site_id);
    let channel = this.channels.find(
      (channel) => channel.id == this.addForm.channel_id
    );
    console.log(channel);
    var a = {
      id: null,
      client_id: this.addForm.client_id,
      site_id: this.addForm.site_id,
      channel_id: this.addForm.channel_id,
      client_name: client.first_name,
      site_name: site.name,
      channel_name: channel.name,
      masterlength: 0,
      showbuildbutton:0,
      showhawkbutton:0,
      build:false,
      buildResponse:false,
      hawk:false,
      hawkResponse:false
    };
    console.log(a);
    this.dg.entities.push(a);
  }

  addEntity(index) {
    this.clients[index + 1] = this.clientsRaw;
    console.log("this.clients[index+1]", this.clients[index + 1]);
    var a = {
      id: null,
      client_id: null,
      site_id: null,
      channel_id: null,
    };
    this.dg.entities.push(a);

    return false;
  }

  removeEntity(index, id, splice) {
    this.dg.entities.splice(index, 1);
    return false;
  }

  popupClose(e) {
    this.createPopupOpened = false;
    return false;
  }

  openConfirmPopup(id, t) {
    var text = "Do you want to delete ?";
    if (t == 1) text = "Do you want to delete this distribution group?";

    this.confirmValues.id = id;
    this.confirmValues.type = t;
    this.confirm.confirmOpen(text);
    return false;
  }
  isConfirm(e) {
    if (e) {
      switch (this.confirmValues.type) {
        case 1:
          this.deleteDg(this.confirmValues.id);
          break;

        default:
          break;
      }
    }
  }
  goBack() {
    this.router.navigate(["distribution-group"]);
  }

  createStr() {
    let str = "name=" + this.dg.name;
    for (var i = 0; i < this.dg.entities.length; i++) {
      if (this.dg.entities[i].client_id != null) {
        str += "&client_id[" + i + "]=" + this.dg.entities[i].client_id;
      } else {
        str += "&client_id[" + i + "]=undefined";
      }
      if (this.dg.entities[i].site_id != null) {
        str += "&site_id[" + i + "]=" + this.dg.entities[i].site_id;
      } else {
        str += "&site_id[" + i + "]=undefined";
      }
      if (this.dg.entities[i].channel_id != null) {
        str += "&channel_id[" + i + "]=" + this.dg.entities[i].channel_id;
      } else {
        str += "&channel_id[" + i + "]=undefined";
      }
    }
    return str;
  }
  // SECONDARY FUNCTIONS END

  goToLink(e, client_id, site_id, channel_id) {
    localStorage.removeItem("cp_es_keyword");
    window.open(
      "/client-playlists?cat=" +
        channel_id +
        "&uid=" +
        client_id +
        "&pcat=" +
        site_id,
      "_blank"
    );
    console.log(e, client_id, site_id, channel_id);
  }

  public progressValue = [];
  progressBarDisplay(current_progress,maxValue,index){
    var interval = setInterval(() => {
      current_progress += 1;
      this.progressValue[index] = current_progress;
      
      if (current_progress >= maxValue)
        clearInterval(interval);
    }, 10);
  }

  polling: any = [];
  getBuildReponse(id,index){
   var c = this;
  //  c.dg.entities[index].build = true;
   c.polling[index] = setInterval(function x() {
    c.cpplaylistService.getBuildResponse(id).subscribe(res => {
      if(res['statusCode'] == 200 && res['result']){
        if(res['result']['response'] != null && res['result']['response']['statusCode'] == 200){
          c.progressBarDisplay(70,100,index);
          let msg = c.dg.entities[index].channel_name+': <br> Build Success';
          let type = 0; //success
          if( res['result']['response']['statusMessage']['returnvalue'] != undefined ){
            let returnValue = res['result']['response']['statusMessage']['returnvalue'];
            if(returnValue['NumberOfClips'] != c.ConvertToInt(res['result']['clip_count']) || 
              returnValue['NumberOfProfiles'] != c.ConvertToInt(res['result']['profile_count'])
            ){
              msg = c.dg.entities[index].channel_name+': <br> Build failed - Count mismatch';
              type = 1; // error
            }
          }
          c.alert.alertOpen(msg,type);
        }
        if(res['result']['response'] != null && res['result']['response']['statusCode'] == 'BUILD_FAILED'){
          c.alert.alertOpen(c.dg.entities[index].channel_name+': <br> Build Failed',1);
        }        
        if(res['result']['response']){
          c.dg.entities[index].build = false;
          c.dg.entities[index].buildResponse = res['result'];
          clearInterval(c.polling[index]);
          // if(c.api_logs_temp != undefined){
          //   let key = c.datePipe.transform(new Date(),"yyyy-MM-dd");
          //   c.api_logs_temp.data[key][0].buildid = res['buildid'];
          //   c.api_logs_temp.data[key][0].response = res['result']['response'];
          // }
        }
      }
      console.log('res',res);
    });
    return x;
    }(), 15000);
  }

  build(channel_id:any, index) {
    this.dg.entities[index].build = true;
    this.progressBarDisplay(0,10,index);
    this.cpplaylistService.buildPlaylist(channel_id).subscribe((buildinfo: any) => {
      if (buildinfo.error == undefined) {
        this.progressBarDisplay(30,70,index);
        // this.dg.entities[index].buildResponse = 'Build request created. waiting for response!';
        this.getBuildReponse(buildinfo.data.odbapi_log_id,index);
        this.alert.alertOpen("Job created", 0);
      } else {
        this.alert.alertOpen(this.dg.entities[index].channel_name+': <br>'+buildinfo.error, 1, 30);
      }
      // this.dg.entities[index].build = false;
      // this.dg.entities[index].buildResponse = 'Error: Build request not created!';
    });
  }

  buildAll(): void {
    this.buildallstatuschange = true;
    // var channel_ids = [];
    for (let index = 0; index < this.dg.entities.length; index++) {
      this.progressBarDisplay(0, 10, index);
      this.dg.entities[index].build = true;
      // channel_ids.push(this.dg.entities[index].channel_id);
      setTimeout(
        () => this.build(this.dg.entities[index].channel_id, index),
        5000 * index
      );
    }
    // this.cpplaylistService
    //   .buildPlaylistArray(channel_ids)
    //   .subscribe((buildinfo: any) => {
    //     for (const key in buildinfo) {
    //       if (`${buildinfo[key].error}` == "undefined") {
    //         this.progressBarDisplay(30,70,key);
    //         this.alert.alertOpen("Job created", 0);
    //       } else {
    //         //this.buildallstatuschange=;
    //         this.alert.alertOpen(`${buildinfo[key].error}`, 1, 15);
    //       }
    //     }
    //     this.getBuildResponseAll();
    //   });
  }

  buildstatus(entities:any) {
    this.statuschange = null;
    this.hawkstatuschange = null;
    var channel_ids = [];
    for (let index = 0; index < entities.length; index++) {
      channel_ids.push(entities[index].channel_id);
    }
    this.cpplaylistService.buildresponse(channel_ids).subscribe((buildinfo: any) => {
      //entities.buildResponse = buildinfo;
      this.logResponse = buildinfo;
      for (let index = 0; index < this.dg.entities.length; index++) {
        this.dg.entities[index].buildResponse = buildinfo[index];
        this.dg.entities[index].hawkResponse = buildinfo[index];
      }
    });
  }

  hawk(site_id:any, channel_id:any,index) {
    this.hawkstatuschange = channel_id;
    this.isLoadCHawk = true;
    this.dg.entities[index].hawk = true;
    this.cpplaylistService.hawk(site_id, channel_id, null).subscribe((res) => {
      this.isLoadCHawk = false;
      this.dg.entities[index].hawk = false;
      if (res["statusCode"] == 200 && res["result"].length > 0) {
        this.dg.entities[index].hawkResponse = res;
        // this.cpplaylistService.buildresponse(channel_id).subscribe((buildinfo: any) => {
        //   this.dg.entities[index].hawkResponse = buildinfo;
        // });
        this.alert.alertOpen(this.dg.entities[index].channel_name+': <br>'+res["result"], 0);
      } else {
        this.alert.alertOpen(this.dg.entities[index].channel_name+': <br>'+res["error"], 1);
      }
    });
  }

  hawkAll() {
    this.hawkstatuschange = null;
    this.hawkAllstatuschange = true;

    for (let index = 0; index < this.dg.entities.length; index++) {
      this.hawk(
        this.dg.entities[index].site_id,
        this.dg.entities[index].channel_id,
        index
      );
    }
  }

  Export() {
    var excelarrayfile = [];
    var filename = "Export Build and Hawk report";

    for (let index = 0; index < this.dg.entities.length; index++) {
      const element = this.dg.entities[index];

      for (let i = 0; i < this.logResponse.length; i++) {
        const log = this.logResponse[i];
        if (log.channel_id == element.channel_id) {
          excelarrayfile.push({
            client_name: element.client_name,
            site_name: element.site_name,
            channel_name: element.channel_name,
            build_staus:
              log.response &&
              log.response.statusMessage.returnvalue &&
              (log.response.statusMessage.returnvalue.NumberOfClips !=
                log.clip_count ||
                log.response.statusMessage.returnvalue.NumberOfProfiles !=
                  log.profile_count)
                ? "Failed"
                : log.response && log.response.statusCode == 200
                ? "Success"
                : log.response == null
                ? "In Progress"
                : "Failed",
            hawk_status:
              log.hawk_status == 1
                ? "Success"
                : log.hawk_status == 0
                ? "In Progress"
                : log.hawk_status == null
                ? ""
                : "Failed",
          });
        }
      }
    }

    this.excelService.exportAsExcelFile(excelarrayfile, filename);
  }

  ConvertToInt(val){
    return parseInt(val) || 0;
  }

  getHawkStatusValue(hawk_status){
    let st = '';
    switch (hawk_status) {
      case 0:
        st = 'In progress';
        break;
      case 1:
        st = 'Success';
        break;
      case -1:
        st = 'Failed';
        break;
      default:
        st = '';
        break;
    }
    return st;
  }
}
