import {HttpClient,HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {map} from 'rxjs/operators';
import { environment } from '../../environments/environment';
import {Globals} from '../globals';

@Injectable()
export class AutocompleteService{
  httpOptions = {
    headers: new HttpHeaders({
      'Host': environment.dev_url_prefix + '/',
      'Authorization': 'Bearer ' + this.globals.access_token,
      'Accept': 'application/json'
    })
  };
  constructor(
    private http: HttpClient,
    private globals: Globals
  ){}

  /**
   Query for autocomplete fields
   * @param str - search string
   * @param query - query for search
   * @returns {Observable<any>}
   */
  getAutocompleteData( str, query){
    return this.http.get(environment.dev_url_prefix + '/api/V1/autocomplete/' + query + '?name=' + str, this.httpOptions).pipe(map(data => {
      return data;
    }));
  }
}
