<script src="../manage-cp/manage-cp.component.ts"></script>

<app-page-header [pageTitle]="'Create, edit and manage your playlists'"></app-page-header>
<div class="top_box">
  <div class="mf_buttons">
    <div class="search_box">
      <form method="get" #searchForm="ngForm">
        <input type="text" name="search" (focus)="focusInput()" (blur)="blurInput()" value="{{searchStr}}" placeholder="Playlist search" class="searchInput" [(ngModel)]="searchStr" maxlength="30"/>
        <button type="submit" class="searchBtn" (click)="search($event)"></button>
        <a href="#" class="clear_search" (click)="clearSearch($event)"></a>
      </form>
    </div>
    <div class="search_box">
        <form method="get" #searchForm="ngForm">
          <input type="text" name="search" (focus)="focusInput()" (blur)="blurInput()" value="{{entitySearchStr}}" placeholder="Entity search" class="searchInput" [(ngModel)]="entitySearchStr" maxlength="30"/>
          <button type="submit" class="searchBtn" (click)="entitySearch($event)"></button>
          <a href="#" class="clear_search" (click)="clearEntitySearch($event)"></a>
        </form>
      </div>
    <!-- <button *ngIf="current_user!=null&&(current_user.type=='Consultant'||current_user.type=='Administrator')" class="create_user_btn" (click)="openUserPopup($event,null)" title="New Client">Create New Client</button>
    <button *ngIf="current_user!=null&&(current_user.type=='Consultant'||current_user.type=='Administrator')" class="create_category_btn" (click)="openSitePopup($event,null)" title="New Site" >Create New Site</button>
    <button *ngIf="current_user!=null&&(current_user.type=='Consultant'||current_user.type=='Administrator')" class="create_subcategory_btn" (click)="openChanelsPopup($event,null)" title="New Channel" >Create New Channel</button> -->

    <button *ngIf="current_user!=null&&(current_user.type=='Consultant'||current_user.type=='Administrator')" class="create_playlist_btn" (click)="openPlaylistPopup($event,null)" title="New Playlist">Create New Playlist</button>
    <button *ngIf="current_user!=null&&(current_user.type=='Consultant'||current_user.type=='Administrator')" class="create_schedule_btn" (click)="openSchedulePopup($event,0)" title="New Schedule">Create New Schedule</button>
    <button *ngIf="current_user!=null&&(current_user.type=='Consultant'||current_user.type=='Administrator')" class="create_schedule_btn" class="create_new_playSCh" (click)="playlistSchduleCreate($event,null)" title="New Schedule">Create New Playlist+Schedule</button>
    <button *ngIf="current_user!=null&&(current_user.type=='Consultant'||current_user.type=='Administrator')" class="create_overlay_btn"  (click)="overlayProfileandSCh($event,null)" title="New Overlay">Create New Overlay</button>
    <button *ngIf="remove_Playlist && current_user!=null&&(current_user.type=='Consultant'||current_user.type=='Administrator')" class="create_schedule_btn" (click)="batchSelectAndDelete()" title="Batch Select and Delete">Remove Playlist</button>
  </div>
</div>
<div class="foundation_playlist_main">
  <div class="breadcrumbs">
    <a href="#" (click)="resetCheckedElements(1)">Client Playlists</a>
    <a href="#" *ngIf="checkedUserId.name!=''" (click)="resetCheckedElements(2)">{{checkedUserId.name}}</a>
    <a href="#" *ngIf="checkedSiteId.name!=''" (click)="resetCheckedElements(3)">{{checkedSiteId.name}}</a>
    <a href="#" *ngIf="checkedChanelId.name!=''" (click)="resetCheckedElements(4)">{{checkedChanelId.name}}</a>
    <div>
      
      <!-- <label  class="build_btn closed" title="closed" *ngIf="checkedSiteId.id!=null"  >
        <input type="checkbox" (click)="closed($event)"  [checked]="closedinfo.checkout" > Closed
      </label> -->

      <button style="margin-right: 7px;" class="build_btn" title="Build" *ngIf="checkedChanelId.id!=null" (click)="buildClick()" [disabled]="isLoader">
        {{ isLoader ? 'Processing....' : 'Build' }}
      </button>
      <button style="margin-right: 7px;" class="build_btn" title="Hawk" (click)="channelHawk()" *ngIf="checkedChanelId.id!=null" [disabled]="isLoadCHawk">
        {{ isLoadCHawk ? 'Processing....' : 'Hawk' }}
      </button> 
      <app-import-client-playlist [datapasstochild]="passdatatochild" style="float: right;margin-right: 7px;" *ngIf="checkedChanelId.id!=null" (afterUpload)="afterUpload($event)"></app-import-client-playlist>
      <span *ngIf="checkedSiteId.id!=null && checkedChanelId.id==null">
        <!-- cancelHawk(checkedSiteId.id) -->
        <button style="margin-right: 7px;" class="build_btn" title="Cancel" (click)="openConfirmPopup(checkedSiteId.id,11)" [disabled]="isCancelHawk">
          {{ isCancelHawk ? 'Processing....' : 'Cancel Hawk' }}
        </button>

        <button style="margin-right: 7px;" class="build_btn" title="Hawk" (click)="openConfirmPopup(checkedSiteId.id,9)" [disabled]="isLoadSHawk">
          {{ isLoadSHawk ? 'Processing....' : 'Hawk' }}
        </button>
        
        <!-- log.buildid,log.serial_number,log.hawk_id,logs.key,inner_key -->
        <button class="build_btn"  style="margin-right: 7px;"(click)="openConfirmPopup(checkedSiteId.id,10)" [disabled]="isLoadHawk">{{ isLoadHawk ? 'Processing....' : 'Mark as complete' }}</button>
      </span>

      <span *ngIf="checkedSiteId.id!=null && checkedChanelId.id==null">
        <button style="margin-right: 7px;" class="build_btn" title="Build" (click)="siteBuildClick()" [disabled]="isLoader">
          {{ isLoader ? 'Processing....' : 'Build' }}
        </button>
      </span>
    </div>
  </div>
  <div class="left_block" [ngStyle]="entitySearchResult.length > 0 && {'width': '210px'}">
    <div class="users_block box" *ngIf="entitySearchResult.length==0">
      <ul>
        <li *ngFor="let u of usersList" (click)="clickedInside($event)" [ngClass]="{opened: checkedUserId.id == u.id}" id="user-list-{{u.id}}">
          <ng-template #ClientPopupContent>
            <span style="font-size: 12px !important;">Entity ID : {{u.entity_id}}</span>
          </ng-template>  
          <span [options]="tooltipOptions" [tooltip]="ClientPopupContent" contentType="template" (click)="getSites(u.id, u.first_name ,1)" [style.color]="u.closed === 1 ? '#E25E3E' : 'inherit'">{{u.first_name}}</span>
          <div class="actions_wrap">
            <a href="#" class="arrow" (click)="getSites(u.id, u.first_name ,1)"></a>
            <ul class="actions_popup" *ngIf="isShowActions.number==1&&current_user!=null&&(current_user.type=='Consultant'||current_user.type=='Administrator')">
              <li>
                <a href="#" class="users_edit" (click)="openUserPopup($event,u.id)"></a>
              </li>
              <!-- <li>
                <a href="#" class="users_delete" (click)="openConfirmPopup(u.id,0)"></a>
              </li> -->
              <li>
                <a href="#" [ngClass]="{'playlist_categories_delete': u.closed === 0, 'playlist_categories_open': u.closed === 1}" (click)="clientClosed($event, u.closed)"></a>
              </li> 
            </ul>
          </div>
        </li>
        <!-- <div id="user-list">Test</div> -->
      </ul>
      
    </div>
    <div class="playlists_block box" *ngIf="checkedUserId.id!=null && entitySearchResult.length==0">
      <ul *ngIf="sitesList!=null">
        <li *ngFor="let site of sitesList" (click)="clickedInside($event)" [ngClass]="{opened: checkedSiteId.id == site.id}" >
          <!-- , site_hidden: checkEntityFilterSite(site) -->
          <div class="wrap">
            <ng-template #SitePopupContent>
              <span style="font-size: 12px !important;">Entity ID : {{site.entity_id}} <br> 
                Parent Entity ID : {{site.parent_entity_id}} <br> 
                Parent Entity Name : {{site.parent_entity_name}} <br>
                Total tracks : {{site.total_tracks}}
              </span>
            </ng-template>    
            <span [tooltip]="SitePopupContent" [options]="tooltipOptions" (click)="showChanels($event,site.id,site.name,1)" contentType="template"  [style.color]="site.closed === 1 ? '#E25E3E' : 'inherit'">{{site.name}}</span>
          </div>
          <div class="actions_wrap">
            <a href="#" class="arrow" (click)="showChanels($event,site.id,site.name,1)"></a>
            <ul class="actions_popup" *ngIf="isShowActions.number==2&&current_user!=null&&(current_user.type=='Consultant'||current_user.type=='Administrator')">
              <li>
                <a href="#" class="playlist_categories_edit" (click)="openSitePopup($event,site.id)"></a>
              </li>
              <!-- <li>
                <a href="#" class="playlist_categories_delete" (click)="openConfirmPopup( site.id, 1)"></a>
              </li> -->
              <li>
                <a href="#" [ngClass]="{'playlist_categories_delete': site.closed === 0, 'playlist_categories_open': site.closed === 1}" (click)="siteClosed($event,site.closed)"></a>
              </li> 
              <li>
                <a href="#" *ngIf="current_user!=null&&(current_user.type=='Consultant'||current_user.type=='Administrator')" class="playlist_categories_upload" (click)="openFileUploadPopup(site.id)"></a>
              </li>
            </ul>
          </div>
          <ul *ngIf="site.children !=0">
            <li *ngFor="let chanel of site.children" (click)="clickedInside($event)" [ngClass]="{opened: checkedChanelId.id == chanel.id}">
              <ng-template #ChannelPopupContent>
                <span style="font-size: 12px;">Entity ID : {{chanel.entity_id}} <br>
                  Parent Entity ID : {{site.entity_id}} <br>
                  Parent Entity Name : {{site.name}} <br>
                  Total Tracks : {{chanel.countDetails.clip_count}}
                </span>  
              </ng-template>      
              <span [tooltip]="ChannelPopupContent" [options]="tooltipOptions" contentType="template" (click)="showPlaylists($event,chanel.id,chanel.name, 1)"  [style.color]="chanel.closed === 1 ? '#E25E3E' : 'inherit'">{{chanel.name}}</span>
              <div class="actions_wrap">
                <a href="#" class="arrow" (click)="showPlaylists($event,chanel.id,chanel.name, 1)"></a>
                <ul class="actions_popup" *ngIf="isShowActions.number==3&&current_user!=null&&(current_user.type=='Consultant'||current_user.type=='Administrator')">
                  <li>
                    <a href="#" class="playlist_categories_edit" (click)="openChanelsPopup($event,chanel.id)"></a>
                  </li>
                  <!-- <li>
                    <a href="#" class="playlist_categories_delete" (click)="openConfirmPopup( chanel.id, 2)"></a>
                  </li> -->
                  <li>
                    <a href="#" [ngClass]="{'playlist_categories_delete': chanel.closed === 0, 'playlist_categories_open': chanel.closed === 1}" (click)="channelClosed($event,chanel.closed)" ></a>
                  </li> 
                  <li>
                    <a href="#" class="playlist_categories_restore" (click)="openRestoredDatesPopup($event,chanel.id)"></a>
                  </li>
                 
                </ul>
              </div>
            </li>
          </ul>
        </li>
      </ul>
    </div>
    <div class="playlists_block box" *ngIf="entitySearchResult.length > 0">
      <ul>
        <li *ngFor="let site of entitySearchResult" (click)="clickedInside($event)" [ngClass]="{opened: checkedSiteId.id == site.id}" >
          <!-- , site_hidden: checkEntityFilterSite(site) -->
        <div class="wrap">
          <ng-template #SitePopupContent>
            <span style="font-size: 12px !important;">Entity ID : {{site.entity_id}} <br> 
              Parent Entity ID : {{site.parent_entity_id}} <br> 
              Parent Entity Name : {{site.parent_entity_name}} <br>
              Total tracks : {{site.total_tracks}}
            </span>
          </ng-template>      
          <span [tooltip]="SitePopupContent" [options]="tooltipOptions" (click)="showChanels($event,site.id,site.name,1)" contentType="template"  [style.color]="site.closed === 1 ? '#E25E3E' : 'inherit'">{{site.name}}</span>
        </div>
        <div class="actions_wrap">
          <a href="#" class="arrow" (click)="showChanels($event,site.id,site.name,1)"></a>
          <ul class="actions_popup" *ngIf="isShowActions.number==2&&current_user!=null&&(current_user.type=='Consultant'||current_user.type=='Administrator')">
            <li>
              <a href="#" class="playlist_categories_edit" (click)="openSitePopup($event,site.id)"></a>
            </li>
            <!-- <li>
              <a href="#" class="playlist_categories_delete" (click)="openConfirmPopup( site.id, 1)"></a>
            </li> -->
            <li>
              <a href="#" [ngClass]="{'playlist_categories_delete': site.closed === 0, 'playlist_categories_open': site.closed === 1}" (click)="siteClosed($event,site.closed)" ></a>
            </li> 
            <li>
              <a href="#" *ngIf="current_user!=null&&(current_user.type=='Consultant'||current_user.type=='Administrator')" class="playlist_categories_upload" (click)="openFileUploadPopup(site.id)"></a>
            </li>
          </ul>
        </div>
        <ul *ngIf="site.children !=undefined">
          <li *ngFor="let channel of site.children" (click)="clickedInside($event)" [ngClass]="{opened: checkedChanelId.id == channel.id}">
            <ng-template #ChannelPopupContentAlpha>
              <span style="font-size: 12px;">Entity ID : {{channel.entity_id}} <br>
                Parent Entity ID : {{site.entity_id}}<br>
              </span>  
            </ng-template>      
            <span [tooltip]="ChannelPopupContentAlpha" [options]="tooltipOptions" contentType="template" (click)="showPlaylists($event,channel.id,channel.name, 1)" [style.color]="channel.closed === 1 ? '#E25E3E' : 'inherit'">{{channel.name}}</span>
            <div class="actions_wrap">
              <a href="#" class="arrow" (click)="showPlaylists($event,channel.id,channel.name, 1)"></a>
              <ul class="actions_popup" *ngIf="isShowActions.number==3&&current_user!=null&&(current_user.type=='Consultant'||current_user.type=='Administrator')">
                <li>
                  <a href="#" class="playlist_categories_edit" (click)="openChanelsPopup($event,channel.id)"></a>
                </li>
                <!-- <li>
                  <a href="#" class="playlist_categories_delete" (click)="openConfirmPopup( channel.id, 2)"></a>
                </li> -->
                <li>
                  <a href="#" [ngClass]="{'playlist_categories_delete': channel.closed === 0, 'playlist_categories_open': channel.closed === 1}" (click)="channelClosed($event,channel.closed)" ></a>
                </li> 
                <li>
                  <a href="#" class="playlist_categories_restore" (click)="openRestoredDatesPopup($event,channel.id)"></a>
                </li>
              </ul>
            </div>
          </li>
        </ul>
        </li>
    </ul>
    </div>
  </div>

  <!-- SITE BLOCK -->
  <div class="playlists_list_block" *ngIf="current_user!=undefined&&checkedSiteId.id!=null&&checkedChanelId.id==null" 
  [ngClass]="{'client_playlist_block': current_user.type != 'Administrator' && current_user.type != 'Manager' && current_user.type != 'Consultant'}" [ngStyle]="entitySearchResult.length > 0 ">
      <ul class="tabs">
        <li [ngClass]="{selected: siteTabs.selected == 1}" (click)="selectSiteTab(1)">Build</li>
      </ul>
      <div class="table_wrap" [ngClass]="{selected: siteTabs.selected == 1}"> 
        <div>
          <table class="status-table" aria-describedby="status-table">
            <tr><td colspan="3" style="text-align: right;">
                <button class="btn btn-sm" title="Get System Status" (click)="getSystemStatus()" [disabled]="isLoadStatus">
                    {{ isLoadStatus ? 'Processing....' : 'Get Status' }}
                </button>
            </td></tr>
            <tr><td>Status</td> <td>:</td> <td>
                <span class="green_circle" *ngIf="systemStatus.status == 1"></span>
                <span class="red_circle" *ngIf="systemStatus.status && systemStatus.status == 0"></span>
            </td></tr>
            <tr><td>Called In</td> <td>:</td> <td>{{systemStatus.called_in}}</td></tr>
            <tr><td>Next Expected Call</td> <td>:</td> <td>{{systemStatus.next_expected_call}}</td></tr>
          </table>
        </div>
        <div style="min-height: calc(100vh - 250px);margin-bottom: 60px;">
          <table class="filter-table" aria-describedby="filter-table">
            <tr>
              <td>
                  <label class="custom-label">Sort : </label> 
                  <select id="Id" #Id="ngModel" class="custom-select hideLabel form-control" [(ngModel)]="sortFilterValue" name="Id" >  
                    <option value="null">All</option> 
                    <option value="today">Today</option>  
                    <option value="yesterday">Yesterday</option>  
                    <option value="this_week">This week</option>  
                    <option value="last week">Last week</option>  
                    <option value="this_month">This Month</option>  
                    <option value="last_month">Last Month</option>  
                    <option value="this_year">This Year</option>  
                    <option value="last_year">Last Year</option> 
                    <option value="date_range">Date Range</option>  
                  </select>  
              </td>
              
              <td>
                  <div class="btn-group " data-toggle="buttons">
                    <label class="custom-label">Build Status : </label>
                    <label class="status-label btn btn-primary" [class.active]="buildSuccess">
                      <input class="" type="checkbox" [(ngModel)]="buildSuccess"> 
                      Success
                    </label>
                    <label class="status-label btn btn-primary" [class.active]="buildFailed">
                      <input class="" type="checkbox" [(ngModel)]="buildFailed"> Failed
                    </label>
                  </div>
              </td>
            </tr>
            <tr style="height: 80px;">
                <td>
                  <div *ngIf="sortFilterValue === 'date_range'">
                    <div class="date-input"><label class="custom-label">From :</label> <my-date-picker name="filterFromDate" [options]="filterDatePickerOptions" placeholder="dd/mm/yyyy" [(ngModel)]="filterFromDate" ></my-date-picker></div>
                    <div class="date-input"><label class="custom-label">To :</label> <my-date-picker name="filterToDate" [options]="filterDatePickerOptions" placeholder="dd/mm/yyyy" [(ngModel)]="filterToDate" ></my-date-picker></div>
                  </div>  
                </td>
              <td colspan="2" class="align-right">
                <button class="btn btn-lg" (click)="showOdbApiLog(checkedSiteId.id)">Submit</button>
              </td>
            </tr>
          </table>  
          <!-- Site level build log -->
          <custom-accordion *ngIf="api_logs_temp && !api_logs" >
            <span *ngFor="let logs of api_logs_temp.data | keyvalue: reverseKeyOrder">
              <group title="{{logs.value[0].site_name}} ({{logs.key}})" label="{{api_logs_temp.successCount[logs.key]}} / {{logs.value.length}}" progress_bar_value="{{api_logs_temp.successCount[logs.key]*(100/logs.value.length)}}" opened="true">
                <!-- Your content for the first accordion -->
              <span *ngFor="let log of logs.value; let inner_key = index">
              
                <div class="inner-row">
                  <div class="col-3"> {{log.channel_name}} </div>
                  <div class="col-4 hawk-label">
                    <span>{{log.serial_number?log.serial_number:'-'}}</span> -
                    <span style="text-transform: capitalize">{{log.hawk_status?log.hawk_status:'-'}}</span> -
                    <span>{{log.hawk_status_updated_at?log.hawk_status_updated_at:'-'}}</span>
                  </div>
                  <div class="col-2 hawk-label">
                   
                  </div>
                  <div class="col-1"  class="col-1 hawk-label">
                    <span >{{ log.clip_count }} / {{ log.profile_count }}</span><br>
                    <small *ngIf="log.shared_clips && log.shared_clips!='0'">( {{ log.shared_clips }} shared clips)</small>
                  </div>
                  <div class="col-2" style="text-align:center"  *ngIf="log.buildid != ''">
                    <span [ngClass]="(log.response && log.response.statusCode == 200) ? 'text-success' : 'text-danger'" >
                      {{(log.response && log.response.statusCode == 200 ? 'success' : ( log.response == null ? 'No response' : 'failed') )}}
                    </span>
                  </div>
                  <div class="col-2" style="text-align:center" *ngIf="log.buildid == ''">
                    <progress-bar [progress]="progressValue" [color-degraded]="{'0': '#f00e0e',  '25': '#f35b08', '50': '#f2be0e' , '75': '#04db52'}">
                    </progress-bar>
                    
                  </div>
                </div>
              </span>
              </group>
            </span>
          </custom-accordion>
          <custom-accordion *ngIf="api_logs && !api_logs_temp" >
            <span *ngFor="let logs of api_logs.data | keyvalue: reverseKeyOrder;">
              <group title="{{logs.value[0].site_name}} ({{logs.key}})" label="{{api_logs.successCount[logs.key]}} / {{logs.value.length}}" progress_bar_value="{{api_logs.successCount[logs.key]*(100/logs.value.length)}}">
                <!-- Your content for the first accordion -->
            <span *ngFor="let log of logs.value; let inner_key = index" >
            
              <div class="inner-row">
                <div class="col-2"> {{log.channel_name}} </div>
                <div class="col-5 hawk-label">
                  <span>{{log.serial_number?log.serial_number:'-'}}</span> -
                  <span style="text-transform: capitalize">{{log.hawk_status?log.hawk_status:'-'}}</span> -
                  <span>{{log.hawk_status_updated_at?log.hawk_status_updated_at:'-'}}</span>
                </div>
                <div class="col-0 hawk-label"></div>
                <div class="col-5 hawk-label">
                  <span>
                    Curator - Total Assignments: {{ ConvertToInt(log.clip_count) + ConvertToInt(log.profile_count)}} | 
                    Clips: {{ ConvertToInt(log.clip_count) }} 
                    <small *ngIf="log.shared_clips && log.shared_clips!='0'"> ( {{log.shared_clips}} shared clips)</small> | 
                    Profiles: {{ log.profile_count }} </span>
                  <span *ngIf="log.response && log.response.statusMessage.returnvalue && log.response.statusMessage.returnvalue.NumberOfClips" > <br> 
                  API - Total Assignments: {{ log.response.statusMessage.returnvalue.NumberOfAssignments }} |  Clips: {{ log.response.statusMessage.returnvalue.NumberOfClips }} | 
                  Profiles: {{ log.response.statusMessage.returnvalue.NumberOfProfiles }}</span>
                </div>
                <div class="col-1" style="text-align:center"  *ngIf="log.buildid != ''">
                  <span [ngClass]="( log.response && log.response.statusMessage.returnvalue && ( log.response.statusMessage.returnvalue.NumberOfClips != ConvertToInt(log.clip_count) || log.response.statusMessage.returnvalue.NumberOfProfiles != log.profile_count) ? 'text-danger': (log.response && log.response.statusCode == 200) ? 'text-success' : 'text-danger')" >
                    {{ (log.response && log.response.statusMessage.returnvalue && (log.response.statusMessage.returnvalue.NumberOfClips != ConvertToInt(log.clip_count)  || log.response.statusMessage.returnvalue.NumberOfProfiles != log.profile_count) ? 'failed' : (log.response && log.response.statusCode == 200 ? 'success' : ( log.response == null ? 'No response' : 'failed') ) ) }}
                  </span>
                </div>
                <div class="col-1" style="text-align:center" *ngIf="log.buildid == ''">
                  <progress-bar [progress]="progressValue" [color-degraded]="{'0': '#f00e0e',  '25': '#f35b08', '50': '#f2be0e' , '75': '#04db52'}">
                  </progress-bar>
                  <span [ngClass]="(log.response && log.response.statusCode == 200) ? 'text-success' : 'text-danger'" >
                    {{(log.response && log.response.statusCode == 200 ? 'success' : ( log.response == null ? 'No response' : 'failed') )}}
                  </span>
                </div>
              </div>
            </span>
            </group>
          </span>
          </custom-accordion>
        
        <div *ngIf="!api_logs_temp && !api_logs && !isLoad">
          <h2 style="text-align: center;margin: 40px 10px 10px;padding: 20px;">No result!</h2>
        </div>
        </div>
      </div>
  </div>

<!-- CHANNEL BLOCK -->
  <div class="playlists_list_block" *ngIf="current_user!=undefined&&checkedChanelId.id!=null" [ngClass]="{'client_playlist_block': current_user.type != 'Administrator' && current_user.type != 'Manager' && current_user.type != 'Consultant' }" [ngStyle]="entitySearchResult.length > 0 ">
    <ul class="tabs">
      <li [ngClass]="{selected: tabs.selected == 1}" (click)="selectTab(1)">Playlists</li>
      <li [ngClass]="{selected: tabs.selected == 2}" (click)="selectTab(2)">Schedule</li>
      <li [ngClass]="{selected: tabs.selected == 5}" (click)="selectTab(5)">Profile statement</li>
      <li [ngClass]="{selected: tabs.selected == 3}" (click)="selectTab(3)">Build</li>

      <li *ngIf="current_user.type == 'Administrator'" [ngClass]="{selected: tabs.selected == 4}" (click)="selectTab(4)">SyncProfile</li>
      <li [ngClass]="{selected: tabs.selected == 6}" (click)="selectTab(6)">AutoUpdate Log</li>
      <li [ngClass]="{selected: tabs.selected == 7}" (click)="selectTab(7)">Archive</li>
    </ul>
    <div class="table_wrap" [ngClass]="{selected: tabs.selected == 1}">
      <div class="table_titles">
        <div class="order" (click)="setOrderTC('name')" [ngClass]="{active: orderBy=='name',asc:order=='ASC'}">Name</div>
        <div class="order" (click)="setOrderTC('date_added')" [ngClass]="{active: orderBy=='date_added',asc:order=='ASC'}">Date Added</div>
        <div class="order" (click)="setOrderTC('date_updated')" [ngClass]="{active: orderBy=='date_updated',asc:order=='ASC'}">Date Updated</div>
       
        <div class="order" (click)="setOrderTC('schedule')" [ngClass]="{active: orderBy=='schedule',asc:order=='ASC'}">Schedule Time</div>
        <div># of Tracks</div>
        <div >Hidden</div>
        <div (click)="statusChangeCheck()">Status <span class="status_show" >({{ playlistStatusheaderFilter }})</span></div>
        <div>Functions</div>
        <div>Actions 
          <!-- <input class="checkBoxAll" type="checkbox"  (change)="selectAllPlaylists($event.target.checked)"> -->
        </div>
      </div>
      <div class="table_content">
        <div *ngFor="let p of playlists">
        <div class="table_row" 
        [ngClass]="{
          'status-editing': p.status === 1,
          'status-suspended': p.status === 2,
          'status-overlay ': p.schedule?.overlay_playlist_id !== null,
          'highlight': p.profile_type == 1 && (p.sync == 0 || p.schedule?.sync == 0)
        }" 
          *ngIf="p.profile_name!='temp_profile'">
          <div>
            <ng-template #MasterTooltipContent>
              <span style="font-size: 12px !important;" *ngIf="p.dg_name">Distribution group : {{p.dg_name}}</span>
            </ng-template>  
            
            <span [options]="tooltipOptions" [tooltip]="MasterTooltipContent" contentType="template" *ngIf="p.profile_type == 1" class="master_icon"></span> 
            
            {{p.name}}</div>
          <div>{{p.created_at}}</div>
          <div>{{p.updated_at}}</div>
          <div  (click)="p.schedule?.id && manageScheduleClick(p.schedule?.id)">
            <ng-template #dotToolTip>
              <span style="font-size: 12px !important;"[style.color]="getBackgroundColorForFinishDate(p.schedule.finish_date)">{{ tooltiptextForDot }}</span>
            </ng-template>   
            <span *ngIf="p.schedule && p.schedule.finish_date && p.schedule.finish_date != '0000-00-00 00:00:00'"
            [options]="tooltipOptionsForDot"
            [tooltip]="dotToolTip"
            contentType="template"
            class="dot"
            [style.background-color]="getBackgroundColorForFinishDate(p.schedule.finish_date)">
            </span>
            <span *ngIf="current_user!=null && (current_user.email == 'subramanian.baskaran@globalizeme.com' || current_user.email == 'Maheshamahy92@gmail.com')">
              {{p.schedule?.begin_date}} - {{p.schedule?.finish_date}}
            </span>
            <span *ngIf="p.schedule && p.schedule.begin_date && p.schedule.begin_date != '0000-00-00 00:00:00'">
              {{p.schedule.begin_date | slice:11:16 }}
            </span>
            <span *ngIf="!p.schedule || (p.schedule.begin_date && p.schedule.begin_date == '0000-00-00 00:00:00')">--</span>
             | 
            <span *ngIf="p.schedule && p.schedule.finish_date && p.schedule.finish_date != '0000-00-00 00:00:00'">{{p.schedule.finish_date | slice:11:16 }}</span>
            <span *ngIf="!p.schedule || (p.schedule && p.schedule.finish_date && p.schedule.finish_date == '0000-00-00 00:00:00')">--</span>
            <span style="margin-left: 3px;" *ngIf="p.schedule?.recurrence_type=='weekly' && p.schedule.recurrence_dow">({{  dayMapping[p.schedule.recurrence_dow] || p.schedule.recurrence_dow }})</span>
            
          </div>
          <div>{{p.files_count}}</div>
          <div>
            <ui-switch 
            labelOn="Y" labelOff="N"
            size="small" 
            id="p.id" name="p.id"
            switchColor="#7a7a7a"
            defaultBoColor="#a8a8a8"
            [checked]="p.hidden === 'YES'" (change)="setHidden($event,p.id)"></ui-switch>
            <!-- {{p.hidden}} -->
          </div>

          <div>
            <select id="Id" #Id="ngModel" class="custom-select hideLabel form-control" [(ngModel)]="p.status" name="Id" style="width: 90px;margin-left: 13px;" [disabled]="p.profile_type == 2 && current_user.email != 'subramanian.baskaran@globalizeme.com'" (change)="((p.profile_type == 0 || p.profile_type == 1 || p.profile_type == 3) || current_user.email == 'subramanian.baskaran@globalizeme.com') && statusChange($event,p.id)">  
              <option value="0">LIVE</option>  
              <option value="1">EDITING</option>  
              <option value="2">SUSPENDED</option>
              <option value="4">ARCHIVE</option>
            </select>
          </div>
          <div>
            <a *ngIf="p.profile_type != 2" class="playlist_manage" title="Manage" (click)="manageClick(p.id)" routerLink="/manage-client-playlists">Manage</a>
            <a style="width: 35px;display: inline-block;" *ngIf="p.profile_type == 2" class="lock_icon" title="Unlock profile" href="#" (click)="openUnlockPopup($event,p.id,p.master_id)"></a>
            <!-- 
              MUTLI SITE : TEMP HIDE FOR LIVE
            -->
            <!-- <a class="dg_icon" title="Copy distribution group" href="#" [class.disabled]="p.profile_type == 2 || p.profile_type == 1" (click)="p.profile_type == 0 && openCopyDgPopup($event,p.id)"></a>
            <a class="sync_icon" title="Sync changes to distribution group" href="#" [class.disabled]="((p.profile_type == 2 || p.profile_type == 0) && p.sync == 0) || (p.sync == 1 && p.schedule?.sync == 1)" (click)="p.profile_type == 1 && (p.sync == 0 || p.schedule?.sync == 0) && openConfirmPopup(p.id,8)"></a>
            <a class="sync_icon" title="Copy changes to selected channels" href="#" 
            (click)="openCopyDgPopupChannels($event,p.id)"></a> -->
            <div class="dropdown" style="margin-left:5px;">
              <a class="multisite_btn" href="javascript:void(0);">Multisite</a>
              <div class="dropdown-content">
                <a class="dg_icon" title="Copy distribution group" href="#" [class.disabled]="p.profile_type == 2 || p.profile_type == 1" (click)="p.profile_type == 0 && openCopyDgPopup($event,p.id)">&nbsp;&nbsp;Copy</a>
                <a class="sync_icon" title="Sync changes to distribution group" href="#" [class.disabled]="((p.profile_type == 2 || p.profile_type == 0) && p.sync == 0) || (p.sync == 1 && p.schedule?.sync == 1)" (click)="p.profile_type == 1 && (p.sync == 0 || p.schedule?.sync == 0) && openConfirmPopup(p.id,8)">&nbsp;&nbsp;Sync</a>
                <a class="dg_icon" title="Copy changes to selected channels" href="#" [class.disabled]="p.profile_type == 2" 
                (click)="p.profile_type != 2 && openCopyDgPopupChannels($event,p.id)">&nbsp;&nbsp;Copy (selected channels)</a>
                <a  class="remove_master" title="Remove master" href="#" *ngIf="p.profile_type == 1"  (click)="removeMaster($event,p.id)">&nbsp;&nbsp;Remove Master</a>

              </div>
            </div>
            <!--
              [class.disabled]="((p.profile_type == 2 || p.profile_type == 0) && p.sync == 0) || (p.sync == 1 && p.schedule?.sync == 1)" (click)="p.profile_type == 1 && (p.sync == 0 || p.schedule?.sync == 0) && openCopyDgPopupChannels($event,p.id)"
            -->
          </div>
          <div> 
            <div class="editing_wrap" *ngIf="current_user!=null&&(current_user.type=='Consultant'||current_user.type=='Administrator')">
              <a class="playlist_edit" title="Edit" href="#" [class.disabled]="p.profile_type == 2" (click)="(p.profile_type == 0 || p.profile_type == 1) && openPlaylistPopup($event,p.id)"></a>  
            </div>
            <a class="playlist_delete" title="Delete" *ngIf="current_user!=null &&(current_user.type=='Consultant'||current_user.type=='Administrator')" href="#" (click)="openConfirmPopup(p.id,3)"></a>
            <a [ngClass]="playerInfo.playlistID == p.id && playerInfo.isPlaying == true ? 'track_paused' : 'play_icon'" title="Play" href="#" (click)="startPlaylist($event,p.id)"></a>
            <input type="checkbox" (ngModelChange)="toggleSelection(p)" [ngModel]="isSelected(p.id)">
          </div>
        </div>
        </div>
      </div>
    </div>
    
    <div class="table_wrap" [ngClass]="{selected: tabs.selected == 2}">
      <div class="table_titles">
        <div>Name</div>
        <div>Date Added</div>
        <div>Date Updated</div>
        <div style="width: 15%;max-width: 15%;">Playlist Name</div>
        <div style="width: 10%;max-width: 10%;">Status</div>
        <div style="width: 15%;max-width: 15%;">Functions</div>
        <div style="width: 15%;max-width: 15%;">Actions</div>
      </div>
      <div class="table_content">
        <div  *ngFor="let sch of schedulesList">
        <div class="table_row"  [ngClass]="{
          'status-editing': sch.playlist?.status === 1,
          'status-suspended': sch.playlist?.status === 2,
          'status-overlay ': sch?.overlay_playlist_id !== null ||  checkOnDemandSchedule(sch) , 
          'status-default': sch.playlist?.status === 0
           }">
         
          <div >
            <ng-template #MasterSchTooltipContent>
              <span  style="font-size: 12px !important;" *ngIf="sch.playlist?.dg_name">Distribution group : {{sch.playlist?.dg_name}}</span>
            </ng-template>  
            
            <span [options]="tooltipOptions" [tooltip]="MasterSchTooltipContent" contentType="template" *ngIf="sch.playlist?.profile_type == 1" class="master_icon"></span> 
            {{sch.name}}
          </div>
          <div>{{sch.created_at}}</div>
          <div>{{sch.updated_at}}</div>
          <div style="width: 15%;max-width: 15%;">{{sch.playlist?.name}}</div>
          <div style="width: 10%;max-width: 10%;">
            <span *ngIf="current_user!=null && (current_user.email == 'subramanian.baskaran@globalizeme.com' || current_user.email == 'Maheshamahy92@gmail.com')">
              {{sch.begin_date}} - {{sch.finish_date}}
            </span>
            <div *ngIf="sch.playlist?.status == 0">LIVE</div>
            <div *ngIf="sch.playlist?.status == 1">EDITING</div>
            <div *ngIf="sch.playlist?.status == 2">SUSPENDED</div>
            <div *ngIf="sch.playlist?.status == null">--</div>
          </div>
          <div style="width: 15%;max-width: 15%;">
            <a *ngIf="sch.lock_status == 0" class="playlist_manage" title="Manage" (click)="manageScheduleClick(sch.id)" routerLink="/manage-schedule">Manage</a>
            <a style="width: 35px;display: inline-block;text-align: center;" *ngIf="sch.lock_status == 1" class="lock_icon" title="Unlock profile" href="#" (click)="openSchUnlockPopup($event,sch.id,sch.playlist?.master_id)"></a>
          </div>
          <div style="width: 15%;max-width: 15%;">
            
            <a class="playlist_edit" title="Edit" href="#" [class.disabled]="sch.lock_status == 1" (click)="sch.lock_status == 0 && openEditSchedulePopup(sch.id,sch.name)" *ngIf="current_user!=null&&(current_user.type=='Consultant'||current_user.type=='Administrator')"></a>
            <a class="playlist_delete" title="Delete" href="#" [class.disabled]="sch.lock_status == 1" (click)="sch.lock_status == 0 && openConfirmPopup(sch.id,4)" *ngIf="current_user!=null&&(current_user.type=='Consultant'||current_user.type=='Administrator')"></a>
          </div>
        </div>
        </div>
      </div>
    </div>

    <div class="table_wrap" [ngClass]="{selected: tabs.selected == 3}">
      <div>
        <table class="status-table" aria-describedby="status-table">
          <tr><td colspan="3" style="text-align: right;">
              <button class="btn btn-sm" title="Get System Status" (click)="getSystemStatus()" [disabled]="isLoadStatus">
                  {{ isLoadStatus ? 'Processing....' : 'Get Status' }}
              </button>
          </td></tr>
          <tr><td>Status</td> <td>:</td> <td>
              <span class="green_circle" *ngIf="systemStatus.status == 1"></span>
              <span class="red_circle" *ngIf="systemStatus.status == 0"></span>
          </td></tr>
          <tr><td>Called In</td> <td>:</td> <td>{{systemStatus.called_in}}</td></tr>
          <tr><td>Next Expected Call</td> <td>:</td> <td>{{systemStatus.next_expected_call}}</td></tr>
        </table>
      </div>
      <div style="min-height: calc(100vh - 250px);margin-bottom: 60px;">
      <table class="filter-table" aria-describedby="filter-table">
        <tr>
          <td>
              <label class="custom-label">Sort : </label> 
              <select id="Id" #Id="ngModel" class="custom-select hideLabel form-control" [(ngModel)]="sortFilterValue" name="Id" >  
                <option value="null">All</option> 
                <option value="today">Today</option>  
                <option value="yesterday">Yesterday</option>  
                <option value="this_week">This week</option>  
                <option value="last week">Last week</option>  
                <option value="this_month">This Month</option>  
                <option value="last_month">Last Month</option>  
                <option value="this_year">This Year</option>  
                <option value="last_year">Last Year</option> 
                <option value="date_range">Date Range</option>  
              </select>  
          </td>
          <td>
              <div class="btn-group " data-toggle="buttons">
                <label class="custom-label">Build Status : </label>
                <label class="status-label btn btn-primary" [class.active]="buildSuccess">
                  <input class="" type="checkbox" [(ngModel)]="buildSuccess"> 
                  Success
                </label>
                <label class="status-label btn btn-primary" [class.active]="buildFailed">
                  <input class="" type="checkbox" [(ngModel)]="buildFailed"> Failed
                </label>
              </div>
          </td>
        </tr>
        <tr style="height: 80px;">
            <td>
              <div *ngIf="sortFilterValue === 'date_range'">
                <div class="date-input"><label class="custom-label">From :</label> <my-date-picker name="filterFromDate" [options]="filterDatePickerOptions" placeholder="dd/mm/yyyy" [(ngModel)]="filterFromDate" ></my-date-picker></div>
                <div class="date-input"><label class="custom-label">To :</label> <my-date-picker name="filterToDate" [options]="filterDatePickerOptions" placeholder="dd/mm/yyyy" [(ngModel)]="filterToDate" ></my-date-picker></div>
              </div>  
            </td>
          <td colspan="2" class="align-right">
            <button class="btn btn-lg" (click)="showOdbApiLog(checkedSiteId.id,checkedChanelId.id)">Submit</button>
          </td>
          </tr>
      </table>   
      <br> 
      <!-- Channel level build log -->
      <custom-accordion *ngIf="api_logs_temp && !api_logs" >
        <span *ngFor="let logs of api_logs_temp.data| keyvalue: reverseKeyOrder">
          <group title="{{logs.value[0].site_name}} ({{logs.key}}) " label="{{api_logs_temp.successCount[logs.key]}} / {{logs.value.length}}" progress_bar_value="{{api_logs_temp.successCount[logs.key]*(100/logs.value.length)}}" opened="true">
          <span *ngFor="let log of logs.value; let inner_key = index">
          
            <div class="inner-row">
              <div class="col-3"> {{log.channel_name}} </div>
              <div class="col-4 hawk-label">
                <span>{{log.serial_number?log.serial_number:'-'}}</span> -
                <span style="text-transform: capitalize">{{log.hawk_status?log.hawk_status:'-'}}</span> -
                <span>{{log.hawk_status_updated_at?log.hawk_status_updated_at:'-'}}</span>
              </div>
              <div class="col-2 hawk-label">
                
              </div>
              <div class="col-1"  class="col-1 hawk-label">
                <span > {{ log.clip_count }} / {{ log.profile_count }}</span><br>
                <small *ngIf="log.shared_clips && log.shared_clips!='0'">( {{ log.shared_clips }} shared clips)</small>
              </div>
              <div class="col-2" style="text-align:center"  *ngIf="log.buildid != ''">
                <span [ngClass]="(log.response && log.response.statusCode == 200) ? 'text-success' : 'text-danger'" >
                  {{(log.response && log.response.statusCode == 200 ? 'success' : ( log.response == null ? 'No response' : 'failed') )}}
                </span>
              </div>
              <div class="col-2" style="text-align:center" *ngIf="log.buildid == ''">
                <progress-bar [progress]="progressValue" [color-degraded]="{'0': '#f00e0e',  '25': '#f35b08', '50': '#f2be0e' , '75': '#04db52'}">
                </progress-bar>
                
              </div>
            </div>
          </span>
          </group>
        </span>
      </custom-accordion>
      <custom-accordion *ngIf="api_logs && !api_logs_temp" >
      <span *ngFor="let logs of api_logs.data| keyvalue: reverseKeyOrder;let first=first">
        <group title="{{logs.value[0].site_name}} ({{logs.key}}) " label="{{api_logs.successCount[logs.key]}} / {{logs.value.length}}" progress_bar_value="{{api_logs.successCount[logs.key]*(100/logs.value.length)}}" [opened]="first">
        <span *ngFor="let log of logs.value; let inner_key = index">
        <!-- <group title="{{log.channel_name}}" label="{{ log.clip_count }} / {{ log.profile_count }}" progress_bar_value="" build_status="{{(log.response && log.response.statusCode == 200 ? 'success' : ( log.response == null ? 'No response' : 'failed') )}}"  hawkLabel="{{log.serial_number?log.serial_number:'-'}} / {{log.hawk_status?log.hawk_status:'-'}} / {{log.hawk_status_updated_at?log.hawk_status_updated_at:'-'}}">
        </group> -->
          <div class="inner-row">
            <div class="col-2"> {{log.channel_name}} </div>
            <div class="col-5 hawk-label">
              <span>{{log.serial_number?log.serial_number:'-'}}</span> -
              <span style="text-transform: capitalize">{{log.hawk_status?log.hawk_status:'-'}}</span> -
              <span>{{log.hawk_status_updated_at?log.hawk_status_updated_at:'-'}}</span>
            </div>
            <div class="col-0 hawk-label">
              
            </div>
            <div class="col-5 hawk-label">
              <span>
                Curator - Total Assignments: {{ ConvertToInt(log.clip_count) + ConvertToInt(log.profile_count)}} | 
                Clips: {{ ConvertToInt(log.clip_count) }} 
                <small *ngIf="log.shared_clips && log.shared_clips!='0'"> ( {{log.shared_clips}} shared clips)</small> | 
                Profiles: {{ log.profile_count }} </span>
              <span *ngIf="log.response && log.response.statusMessage.returnvalue && log.response.statusMessage.returnvalue.NumberOfClips" > <br> 
              API - Total Assignments: {{ log.response.statusMessage.returnvalue.NumberOfAssignments }} |  Clips: {{ log.response.statusMessage.returnvalue.NumberOfClips }} | 
              Profiles: {{ log.response.statusMessage.returnvalue.NumberOfProfiles }}</span>
            </div>
            
            <div class="col-1" style="text-align:center"  *ngIf="log.buildid != ''">
              <span [ngClass]="(log.response && log.response.statusMessage.returnvalue && (log.response.statusMessage.returnvalue.NumberOfClips != ConvertToInt(log.clip_count) || log.response.statusMessage.returnvalue.NumberOfProfiles != log.profile_count) ? 'text-danger': (log.response && log.response.statusCode == 200) ? 'text-success' : 'text-danger')" >
                {{ (log.response && log.response.statusMessage.returnvalue && (log.response.statusMessage.returnvalue.NumberOfClips != ConvertToInt(log.clip_count) || log.response.statusMessage.returnvalue.NumberOfProfiles != log.profile_count) ? 'failed' : (log.response && log.response.statusCode == 200 ? 'success' : ( log.response == null ? 'No response' : 'failed') ) ) }}
              </span>
            </div>
            <div class="col-1" style="text-align:center" *ngIf="log.buildid == ''">
              <progress-bar [progress]="progressValue" [color-degraded]="{'0': '#f00e0e',  '25': '#f35b08', '50': '#f2be0e' , '75': '#04db52'}">
              </progress-bar>
              <span [ngClass]="(log.response && log.response.statusCode == 200) ? 'text-success' : 'text-danger'" >
                {{(log.response && log.response.statusCode == 200 ? 'success' : ( log.response == null ? 'No response' : 'failed') )}}
              </span>
            </div>
          </div>
        </span>
        </group>
      </span>
      </custom-accordion>
   
    <div *ngIf="!api_logs_temp && !api_logs && !isLoad">
      <h2 style="text-align: center;margin: 40px 10px 10px;padding: 20px;">No result!</h2>
    </div>
    </div>
    </div>
    <div class="table_wrap" [ngClass]="{selected: tabs.selected == 4}">
      <h2 style="float: left;
      margin-top: 30px;
      margin-left: 30px;
      font-weight: normal;">
        Duplicates log in clip assignments
      </h2>
      <div style="min-height: calc(100vh - 250px);margin-bottom: 60px;">
      <table class="filter-table" aria-describedby="filter-tables">
        <tr>
          <td>
              <label class="custom-label">Sort : </label> 
              <select id="Id" #Id="ngModel" class="custom-select hideLabel form-control" [(ngModel)]="sortFilterValue" name="Id" >  
                <option value="null">All</option> 
                <option value="today">Today</option>  
                <option value="yesterday">Yesterday</option>  
                <option value="this_week">This week</option>  
                <option value="last week">Last week</option>  
                <option value="this_month">This Month</option>  
                <option value="last_month">Last Month</option>  
                <option value="this_year">This Year</option>  
                <option value="last_year">Last Year</option> 
                <option value="date_range">Date Range</option>  
              </select>  
          </td>
          <td></td>
        </tr>
        <tr style="height: 80px;">
            <td>
              <div *ngIf="sortFilterValue === 'date_range'">
                <div class="date-input"><label class="custom-label">From :</label> <my-date-picker name="filterFromDate" [options]="filterDatePickerOptions" placeholder="dd/mm/yyyy" [(ngModel)]="filterFromDate" ></my-date-picker></div>
                <div class="date-input"><label class="custom-label">To :</label> <my-date-picker name="filterToDate" [options]="filterDatePickerOptions" placeholder="dd/mm/yyyy" [(ngModel)]="filterToDate" ></my-date-picker></div>
              </div>  
            </td>
          <td colspan="2" class="align-right">
            <button class="btn btn-lg" (click)="showSyncProfileLog(checkedSiteId.id,checkedChanelId.id)">Submit</button>
          </td>
          </tr>
      </table>   
      <br> 
      
      <div *ngFor="let logs of sync_profile_logs | keyvalue: reverseKeyOrder" [ngClass]="spl_key==logs.key?'spl_log_div_opened':'spl_log_div_closed'" >
        <div class="spl_log_div">
          <span>{{logs.key}}</span>
          <span> | Duplicates processed : {{logs.value.length}} </span>
          <a href="javascript:void(0);" (click)="showSpLog(logs.key)" style="float: right;margin: 4px;" [ngClass]="spl_key==logs.key?'custom-icon-opened':'custom-icon-closed'"></a>
          <div class="spl_log_div_inner" *ngIf="spl_key==logs.key">
              <table class="table spl_log_table" style="height: 500px;" aria-describedby="table spl log table">
                <thead>
                  <tr>
                    <th scope="col" style="width: 33%;">Clip UID</th>
                    <th scope="col" style="width: 33%;">Mcluid active</th>
                    <th scope="col" style="width: 34%;">Mcluid deleted</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody *ngFor="let log of logs.value; let inner_key = index">
                  <tr [ngClass]="spl_inner_key==log.id?'spl_inner_row_opened':'spl_inner_row_closed'">
                    <td style="width: 33%;">{{log.mediaclipuid}}</td>
                    <td style="width: 33%;">{{log.org_mcluid}}</td>
                    <td style="width: 34%;">{{log.dup_mcluid}}</td>
                    <td> 
                      <a style="float: right;margin-right: 5px;" href="javascript:void(0);" (click)="viewSplDetail(log.org_mcluid,log.dup_mcluid,log.id)" [ngClass]="spl_inner_key==log.id?'custom-icon-opened':'custom-icon-closed'"></a>
                    </td>
                  </tr>
                  
                    <ng-container *ngIf="spl_detail.length > 0 && spl_inner_key==log.id">
                      <tr class="spl_inner_row_head">
                        <th scope="col">Start Date</th>
                        <th scope="col">Finish Date</th>
                       
                        <th scope="col">Status</th>
                        <th scope="col">Distrubuted</th>
                      </tr>
                      <tr class="spl_inner_row_detail" *ngFor="let log_detail of spl_detail">
                        <td>{{log_detail.startdate}}</td>
                        <td>{{log_detail.finishdate}}</td>
                        
                        <td [ngClass]="log_detail.deleted==0?'active_text':'deleted_text'">{{log_detail.deleted == 0 ? 'Active':'Deleted'}}</td>
                        <td>{{log_detail.deleted == 1 ? ( log_detail.deleted_at != null ? 'Yes' : 'No' ) : ''}}</td>
                      </tr>
                    </ng-container>
                 
                </tbody>  
              </table>
          </div>
        </div>
       
      </div> 
   
    <div *ngIf="sync_profile_logs.length == 0 && !isLoad">
      <h2 style="text-align: center;margin: 40px 10px 10px;padding: 20px;">No result!</h2>
    </div>
  </div>
    </div>
    <div class="table_wrap" [ngClass]="{selected: tabs.selected == 5}">
      <div style="text-align: right;margin: 5px 15px;">
        <button (click)="createNewPS()" class="btn btn-sm" title="Create new profile statement">Create profile statement</button>
      </div>
      <div class="table_titles">
        <div style="width: 40%;max-width: 40%;">Name</div>
        <div style="width: 20%;max-width: 20%;">Date Added</div>
        <div style="width: 20%;max-width: 20%;">Date Updated</div>
        <div style="width: 20%;max-width: 20%;">Actions</div>
      </div>
      <div class="table_content">
        <div class="table_row" *ngFor="let ps of profileStatementList">
          <div style="width: 40%;max-width: 40%;">{{ps.name}}</div>
          <div style="width: 20%;max-width: 20%;">{{ps.created_at}}</div>
          <div style="width: 20%;max-width: 20%;">{{ps.updated_at}}</div>
          <div style="width: 20%;max-width: 20%;">
            <a class="playlist_edit" title="Edit" href="#" (click)="openProfilePopup(ps.id)" *ngIf="current_user!=null&&(current_user.type=='Consultant'||current_user.type=='Administrator')"></a>
            <a class="playlist_delete" title="Delete" href="#" (click)="openConfirmPopup(ps.id,7)" *ngIf="current_user!=null&&(current_user.type=='Consultant'||current_user.type=='Administrator')"></a>
          </div>
        </div>
      </div>
      
    </div>
    <div class="table_wrap" [ngClass]="{selected: tabs.selected == 6}">
      <h2 style="float: left;
      margin-top: 30px;
      margin-left: 30px;
      font-weight: normal;">
        AutoUpdate Log
      </h2>
      <div style="min-height: calc(100vh - 250px);margin-bottom: 60px;">
      <table class="filter-table" aria-describedby="filter-table">
        <tr>
          <td>
              <label class="custom-label">Sort : </label> 
              <select id="Id" #Id="ngModel" class="custom-select hideLabel form-control" [(ngModel)]="sortFilterValue" name="Id" >  
                <option value="null">All</option> 
                <option value="today">Today</option>  
                <option value="yesterday">Yesterday</option>  
                <option value="this_week">This week</option>  
                <option value="last_week">Last week</option>  
                <option value="this_month">This Month</option>  
                <option value="last_month">Last Month</option>  
                <option value="this_year">This Year</option>  
                <option value="last_year">Last Year</option> 
                <option value="date_range">Date Range</option>  
              </select>  
          </td>
          <td></td>
        </tr>
        <tr style="height: 80px;">
            <td>
              <div *ngIf="sortFilterValue === 'date_range'">
                <div class="date-input"><label class="custom-label">From :</label> <my-date-picker name="filterFromDate" [options]="filterDatePickerOptions" placeholder="dd/mm/yyyy" [(ngModel)]="filterFromDate" ></my-date-picker></div>
                <div class="date-input"><label class="custom-label">To :</label> <my-date-picker name="filterToDate" [options]="filterDatePickerOptions" placeholder="dd/mm/yyyy" [(ngModel)]="filterToDate" ></my-date-picker></div>
              </div>  
            </td>
          <td colspan="2" class="align-right">
            <button class="btn btn-lg" (click)="showAutoUpdateLog(checkedChanelId.id)">Submit</button>
          </td>
          </tr>
      </table>   
      <br> 
      <div *ngFor="let auto_update_log of auto_update_logs | keyvalue: reverseKeyOrder" >
        <div class="spl_log_div">
          <span>{{auto_update_log.key}}</span>

          <a href="javascript:void(0);" (click)="showAULog(auto_update_log.key)" style="float: right;margin: 4px;" [ngClass]="aul_key==auto_update_log.key?'custom-icon-opened':'custom-icon-closed'"></a>
          <div class="spl_log_div_inner" *ngIf="aul_key==auto_update_log.key">
              <table class="table spl_log_table" style="display:inline-table;height: auto;width: 100%;" aria-describedby="table spl_log_table">
                <thead>
                  <tr>
                    <th scope="col">Profile Name</th>
                    <th scope="col">Updated Tracks Count</th>
                    <th scope="col">Build Status</th>
                    <th scope="col">Created At</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let log of auto_update_log.value; let inner_key = index">
                    <td>{{log.profile_name}}</td>
                    <td>{{log.tracks_count}}</td>
                    <td>{{log.build_status}}</td>
                    <td>{{log.created_at}}</td>
                  </tr>
                </tbody>  
              </table>
          </div>
        </div>
        
      </div> 
    
  </div>
    </div>


    <!-- ARCHIVE COL--------------- -->
    
    <div class="table_wrap" [ngClass]="{selected: tabs.selected == 7}">
      <div class="table_titles">
        <div class="order" (click)="setOrderTC('name')" [ngClass]="{active: orderBy=='name',asc:order=='ASC'}">Name</div>
        <div class="order" (click)="setOrderTC('date_added')" [ngClass]="{active: orderBy=='date_added',asc:order=='ASC'}">Date Added</div>
        <div class="order" (click)="setOrderTC('date_updated')" [ngClass]="{active: orderBy=='date_updated',asc:order=='ASC'}">Date Updated</div>
       
        <div class="order" (click)="setOrderTC('schedule')" [ngClass]="{active: orderBy=='schedule',asc:order=='ASC'}">Schedule Time</div>
        <div># of Tracks</div>
        <div >Hidden</div>
        <div (click)="statusChangeCheck()">Status <span class="status_show" ></span></div>
        <div>Functions</div>
        <div>Actions <input class="checBoxAll" type="checkbox" (change)="selectAllPlaylists($event.target.checked)"></div>
      </div>
      <div class="table_content">
        <div *ngFor="let p of archivePlaylist">
        <div class="table_row" 
        [ngStyle]="{
          'color': (p.status === 1 ? '#E25E3E' : (p.status === 2 ? 'grey' : (p.schedule?.overlay_playlist_id !== null ? '#FBC901' : '')))
        }"
       
          [ngClass]="{'highlight': p.profile_type == 1 && (p.sync == 0 || p.schedule?.sync == 0)}" 
          *ngIf="p.profile_name!='temp_profile'">
          <div>
            <ng-template #MasterTooltipContent>
              <span style="font-size: 12px !important;" *ngIf="p.dg_name">Distribution group : {{p.dg_name}}</span>
            </ng-template>  
            
            <span [options]="tooltipOptions" [tooltip]="MasterTooltipContent" contentType="template" *ngIf="p.profile_type == 1" class="master_icon"></span> 
            
            {{p.name}}</div>
          <div>{{p.created_at}}</div>
          <div>{{p.updated_at}}</div>
          <div  (click)="p.schedule?.id && manageScheduleClick(p.schedule?.id)">
            <ng-template #dotToolTip>
              <span style="font-size: 12px !important;">hello</span>
            </ng-template>  
            <span [options]="tooltipOptionsForDot" [tooltip]="dotToolTip" contentType="template"  class="dot"></span> 

            <span *ngIf="current_user!=null && (current_user.email == 'subramanian.baskaran@globalizeme.com' || current_user.email == 'Maheshamahy92@gmail.com')">
              {{p.schedule?.begin_date}} - {{p.schedule?.finish_date}}
            </span>
            <span *ngIf="p.schedule && p.schedule.begin_date && p.schedule.begin_date != '0000-00-00 00:00:00'">
              {{p.schedule.begin_date | slice:11:16 }}
            </span>
            <span *ngIf="!p.schedule || (p.schedule.begin_date && p.schedule.begin_date == '0000-00-00 00:00:00')">--</span>
             | 
            <span *ngIf="p.schedule && p.schedule.finish_date && p.schedule.finish_date != '0000-00-00 00:00:00'">{{p.schedule.finish_date | slice:11:16 }}</span>
            <span *ngIf="!p.schedule || (p.schedule && p.schedule.finish_date && p.schedule.finish_date == '0000-00-00 00:00:00')">--</span>
            <span style="margin-left: 3px;" *ngIf="p.schedule?.recurrence_type=='weekly' && p.schedule.recurrence_dow">({{  dayMapping[p.schedule.recurrence_dow] || p.schedule.recurrence_dow }})</span>
            
          </div>
          <div>{{p.files_count}}</div>
          <div>
            <ui-switch 
            labelOn="Y" labelOff="N"
            size="small" 
            id="p.id" name="p.id"
            switchColor="#7a7a7a"
            defaultBoColor="#a8a8a8"
            [checked]="p.hidden === 'YES'" (change)="setHidden($event,p.id)"></ui-switch>
            <!-- {{p.hidden}} -->
          </div>
          <div>
            <select id="Id" #Id="ngModel" class="custom-select hideLabel form-control" [(ngModel)]="p.status" name="Id" style="width: 90px;" [disabled]="p.profile_type == 2 && current_user.email != 'subramanian.baskaran@globalizeme.com'" (change)="((p.profile_type == 0 || p.profile_type == 1 || p.profile_type == 3) || current_user.email == 'subramanian.baskaran@globalizeme.com') && statusChange($event,p.id)">  
              <option value="0">LIVE</option>  
              <option value="1">EDITING</option>  
              <option value="2">SUSPENDED</option>
              <option value="4">ARCHIVE</option>
            </select>
          </div>
          <div>
            <a *ngIf="p.profile_type != 2" class="playlist_manage" title="Manage" (click)="manageClick(p.id)" routerLink="/manage-client-playlists">Manage</a>
            <a style="width: 35px;display: inline-block;" *ngIf="p.profile_type == 2" class="lock_icon" title="Unlock profile" href="#" (click)="openUnlockPopup($event,p.id,p.master_id)"></a>
            <!-- 
              MUTLI SITE : TEMP HIDE FOR LIVE
            -->
            <!-- <a class="dg_icon" title="Copy distribution group" href="#" [class.disabled]="p.profile_type == 2 || p.profile_type == 1" (click)="p.profile_type == 0 && openCopyDgPopup($event,p.id)"></a>
            <a class="sync_icon" title="Sync changes to distribution group" href="#" [class.disabled]="((p.profile_type == 2 || p.profile_type == 0) && p.sync == 0) || (p.sync == 1 && p.schedule?.sync == 1)" (click)="p.profile_type == 1 && (p.sync == 0 || p.schedule?.sync == 0) && openConfirmPopup(p.id,8)"></a>
            <a class="sync_icon" title="Copy changes to selected channels" href="#" 
            (click)="openCopyDgPopupChannels($event,p.id)"></a> -->
            <div class="dropdown" style="margin-left:5px;">
              <a class="multisite_btn" href="javascript:void(0);">Multisite</a>
              <div class="dropdown-content">
                <a class="dg_icon" title="Copy distribution group" href="#" [class.disabled]="p.profile_type == 2 || p.profile_type == 1" (click)="p.profile_type == 0 && openCopyDgPopup($event,p.id)">&nbsp;&nbsp;Copy</a>
                <a class="sync_icon" title="Sync changes to distribution group" href="#" [class.disabled]="((p.profile_type == 2 || p.profile_type == 0) && p.sync == 0) || (p.sync == 1 && p.schedule?.sync == 1)" (click)="p.profile_type == 1 && (p.sync == 0 || p.schedule?.sync == 0) && openConfirmPopup(p.id,8)">&nbsp;&nbsp;Sync</a>
                <a class="dg_icon" title="Copy changes to selected channels" href="#" [class.disabled]="p.profile_type == 2" 
                (click)="p.profile_type != 2 && openCopyDgPopupChannels($event,p.id)">&nbsp;&nbsp;Copy (selected channels)</a>
                <a  class="remove_master" title="Remove master" href="#" *ngIf="p.profile_type == 1"  (click)="removeMaster($event,p.id)">&nbsp;&nbsp;Remove Master</a>

              </div>
            </div>
            <!--
              [class.disabled]="((p.profile_type == 2 || p.profile_type == 0) && p.sync == 0) || (p.sync == 1 && p.schedule?.sync == 1)" (click)="p.profile_type == 1 && (p.sync == 0 || p.schedule?.sync == 0) && openCopyDgPopupChannels($event,p.id)"
            -->
          </div>
          <div> 
            <div class="editing_wrap" *ngIf="current_user!=null&&(current_user.type=='Consultant'||current_user.type=='Administrator')">
              <a class="playlist_edit" title="Edit" href="#" [class.disabled]="p.profile_type == 2" (click)="(p.profile_type == 0 || p.profile_type == 1) && openPlaylistPopup($event,p.id)"></a>  
            </div>
            <a class="playlist_delete" title="Delete" *ngIf="p.uid == '' && current_user!=null &&(current_user.type=='Consultant'||current_user.type=='Administrator')" href="#" [class.disabled]="p.profile_type == 2" (click)="(p.profile_type == 0 || p.profile_type == 1) && openConfirmPopup(p.id,3)"></a>
            <a class="playlist_delete disabled" title="Delete" *ngIf="p.uid != '' && current_user!=null &&(current_user.type=='Consultant'||current_user.type=='Administrator')"></a>
            <a [ngClass]="playerInfo.playlistID == p.id && playerInfo.isPlaying == true ? 'track_paused' : 'play_icon'" title="Play" href="#" (click)="startPlaylist($event,p.id)"></a>
            <input type="checkbox" (ngModelChange)="toggleSelection(p.id)"   [ngModel]="isSelected(p.id)">
            
          </div>
        </div>
        </div>
      </div>
    </div>

  </div>
</div>
<div class="popup create_playlist_popup" [ngClass]="{opened: playlistPopupOpened}" ngDraggable ngResizable [rzHandles]="'n,e,s,w,se,sw,ne,nw'" [handle]="dragHandle">
  <a href="#" class="popup_close" (click)="popupClose($event)"></a>
  <div class="popup_title" #dragHandle>Create/Edit Playlist</div>
  <div class="popup_form">
    <form #updatePlaylist="ngForm">
      <input type="hidden" name="status" value="playlistFields.status" [(ngModel)]="playlistFields.status">
      <input type="hidden" name="user_id" *ngIf="playlistFields.id != null" value="{{playlistFields.user}}" [(ngModel)]="playlistFields.user"/>
      <div class="input_wrap">
        <label>Name</label>
        <input autocomplete="off"  type="text" maxlength="50" name="name" value="{{playlistFields.name}}" [(ngModel)]="playlistFields.name"/>
      </div>
      <div class="input_wrap" *ngIf="playlistFields.id == null">
        <label>Client</label>
        <select name="client_id" *ngIf="usersList!=null" [(ngModel)]="playlistFields.user" (change)="userChange($event, 2)">
          <option value="null" [selected]="playlistFields.user==null">Select Client...</option>
          <option *ngFor="let u of usersList" value="{{u.id}}" [selected]="playlistFields.user == u.id">{{u.first_name}}</option>
        </select>
      </div>
      <div class="input_wrap">
        <label>Site</label>
        <select name="site_id" [(ngModel)]="playlistFields.site" (change)="categoryChange($event,1)">
          <option value="null" [selected]="playlistFields.site==null">Select Site...</option>
          <option *ngFor="let site of playlistFields.sitesList" value="{{site.id}}" [selected]="playlistFields.site==site.id">{{site.name}}</option>
        </select>
      </div>
      <div class="input_wrap">
        <label>Channel</label>
        <select name="chanel_id" [(ngModel)]="playlistFields.chanel" (change)="subCategoryChange($event)">
          <option value="null" [selected]="playlistFields.chanel==null">Select Channel...</option>
          <option *ngFor="let c of playlistFields.channelsList" value="{{c.id}}" [selected]="playlistFields.chanel==c.id">{{c.name}}</option>
        </select>
      </div>
      <div class="input_wrap">
        <label>Profile statement</label>
        <select name="psr_id" [(ngModel)]="playlistFields.profileStatementID">
          <option value="null" [selected]="playlistFields.profileStatementID==null">Select profile statement...</option>
          <option *ngFor="let ps of playlistFields.profileStatementList" value="{{ps.id}}" [selected]="playlistFields.profileStatementID==ps.id">{{ps.name}}</option>
        </select>
      </div>
      <div class="input_wrap">
        <label>Country</label>
        <select name="country_code" class="country_select"  [(ngModel)]="playlistFields.countrycode">
          <option *ngFor="let cc of countrycodeList" [disabled]="cc.name.includes('------')" [value]="cc.code" [selected]="playlistFields.countrycode==cc.code">{{ cc.code }} - {{cc.name}}</option>
        </select>
      </div>
     
      <div class="popup_btns">
        <button type="button" class="cancel" (click)="popupClose($event)">Cancel</button>
        <button type="submit" (click)="handleSave($event, updatePlaylist)"> Save</button>

      </div>
    </form>
  </div>
</div>
<div class="popup create_site_popup" [ngClass]="{opened: sitePopupOpened}" ngDraggable ngResizable [rzHandles]="'n,e,s,w,se,sw,ne,nw'" [handle]="dragHandle2">
  <a href="#" class="popup_close" (click)="popupClose($event)"></a>
  <div class="popup_title" #dragHandle2>
    <span *ngIf="siteFields.id!=null">Edit Site</span>
    <span *ngIf="siteFields.id==null">Create New Site</span>
  </div>
  <div class="popup_form">
    <form #updateSite="ngForm">
      <input type="hidden" name="visible" value="{{siteFields.visible}}" [(ngModel)]="siteFields.visible">
      <input type="hidden" name="user_id" *ngIf="siteFields.id != null" value="{{siteFields.user}}" [(ngModel)]="siteFields.user"/>
      <div class="left_form">
        <div class="input_wrap">
          <label>Name</label>
          <input autocomplete="off" type="text" maxlength="50" name="name" value="{{siteFields.name}}" [(ngModel)]="siteFields.name"/>
        </div>
        <div class="input_wrap">
          <label>Address</label>
          <input autocomplete="off" type="text" name="address_1" value="{{siteFields.address}}" [(ngModel)]="siteFields.address"/>
        </div>
        <div class="input_wrap">
          <label>Address 2</label>
          <input autocomplete="off" type="text" name="address_2" value="{{siteFields.address_2}}" [(ngModel)]="siteFields.address_2"/>
        </div>
        <div class="input_wrap">
          <label>Address 3</label>
          <input autocomplete="off" type="text" name="address_3" value="{{siteFields.address_3}}" [(ngModel)]="siteFields.address_3"/>
        </div>
        <div class="input_wrap">
          <label>Address 4</label>
          <input autocomplete="off" type="text" name="address_4" value="{{siteFields.address_4}}" [(ngModel)]="siteFields.address_4"/>
        </div>
      </div>
      <div class="right_form">
        <div class="input_wrap">
          <label>City</label>
          <input autocomplete="off" type="text" name="city" value="{{siteFields.city}}" [(ngModel)]="siteFields.city"/>
        </div>
        <div class="input_wrap">
          <label>County</label>
          <input autocomplete="off" type="text" name="county" value="{{siteFields.county}}" [(ngModel)]="siteFields.county"/>
        </div>
        <div class="input_wrap">
          <label>Postcode</label>
          <input autocomplete="off" type="text" maxlength="10" (keypress)="keyPress($event)" name="postcode" value="{{siteFields.postcode}}" [(ngModel)]="siteFields.postcode"/>
        </div>
        <div class="input_wrap">
          <label>Country</label>
          <input autocomplete="off" type="text" name="country" value="{{siteFields.country}}" [(ngModel)]="siteFields.country"/>
        </div>
        <div class="input_wrap" *ngIf="siteFields.id == null">
          <label>Client</label>
          <select name="client_id" *ngIf="usersList!=null" [(ngModel)]="siteFields.user">
            <option value="null" [selected]="siteFields.user==null">Select Client...</option>
            <option *ngFor="let u of usersList" value="{{u.id}}" [selected]="siteFields.user==u.id">{{u.first_name}}</option>
          </select>
        </div>
      </div>
      <div class="popup_btns">
        <button type="button" class="cancel" (click)="popupClose($event)">Cancel</button>
        <button type="submit" (click)="formSiteUpdate($event, updateSite)">Save</button>
      </div>
    </form>
  </div>
</div>
<div class="popup create_chanel_popup" [ngClass]="{opened: chanelPopupOpened}" ngDraggable ngResizable [rzHandles]="'n,e,s,w,se,sw,ne,nw'" [handle]="dragHandle3">
  <a href="#" class="popup_close" (click)="popupClose($event)"></a>
  <div class="popup_title" #dragHandle3>
    <span *ngIf="chanelFields.id!=null">Edit Channel</span>
    <span *ngIf="chanelFields.id==null">Create New Channel</span>
  </div>
  <div class="popup_form">
    <form #updateChanel="ngForm">
      <input type="hidden" name="visible" value="{{chanelFields.visible}}" [(ngModel)]="chanelFields.visible">
      <input type="hidden" name="user_id" *ngIf="chanelFields.id != null" value="{{chanelFields.user}}" [(ngModel)]="chanelFields.user"/>
      <div class="input_wrap">
        <label>Name</label>
        <input autocomplete="off" type="text" maxlength="50" name="name" value="{{chanelFields.name}}" [(ngModel)]="chanelFields.name"/>
      </div>
      <div class="input_wrap" *ngIf="chanelFields.id == null">
        <label>Client</label>
        <select name="client_id" *ngIf="usersList!=null" [(ngModel)]="chanelFields.user" (change)="userChange($event, 1)">
          <option value="null" [selected]="chanelFields.user==null">Select Client...</option>
          <option *ngFor="let u of usersList" value="{{u.id}}" [selected]="chanelFields.user==u.id">{{u.first_name}}</option>
        </select>
      </div>
      <div class="input_wrap">
        <label>Parent site node</label>
        <select name="parent_id" [(ngModel)]="chanelFields.site">
          <option value="null" [selected]="chanelFields.site == null">Select Site...</option>
          <option *ngFor="let s of chanelFields.sitesList" value="{{s.id}}" [selected]="chanelFields.site==s.id">{{s.name}}</option>
        </select>
      </div>
      <div class="popup_btns">
        <button type="button" class="cancel" (click)="popupClose($event)">Cancel</button>
        <button type="submit" (click)="formChanelUpdate($event, updateChanel)">Save</button>
      </div>
    </form>
  </div>
</div>
<div class="popup create_user_popup" [ngClass]="{opened: userPopupOpened}" ngDraggable ngResizable [rzHandles]="'n,e,s,w,se,sw,ne,nw'" [handle]="dragHandle4">
  <a href="#" class="popup_close" (click)="popupClose($event)"></a>
  <div class="popup_title" #dragHandle4>
    <span *ngIf="userFields.id!=null">Edit Client</span>
    <span *ngIf="userFields.id==null">Create New Client</span>
  </div>
  <div class="popup_form">
    <form #updateUser="ngForm">
      <input type="hidden" name="active" value="{{userFields.active}}" [(ngModel)]="userFields.active"/>
      <input type="hidden" name="type" value="{{userFields.type}}" [(ngModel)]="userFields.type">
      <div class="left_form">
        <div class="input_wrap">
          <label>Name</label>
          <input autocomplete="off" type="text" maxlength="50" name="name" value="{{userFields.name}}" [(ngModel)]="userFields.name"/>
        </div>
        <div class="input_wrap">
          <label>Address</label>
          <input autocomplete="off" type="text" name="address" value="{{userFields.address}}" [(ngModel)]="userFields.address"/>
        </div>
        <div class="input_wrap">
          <label>Address 2</label>
          <input autocomplete="off" type="text" name="address_2" value="{{userFields.address_2}}" [(ngModel)]="userFields.address_2"/>
        </div>
        <div class="input_wrap">
          <label>Address 3</label>
          <input autocomplete="off" type="text" name="address_3" value="{{userFields.address_3}}" [(ngModel)]="userFields.address_3"/>
        </div>
        <div class="input_wrap">
          <label>Address 4</label>
          <input autocomplete="off" type="text" name="address_4" value="{{userFields.address_4}}" [(ngModel)]="userFields.address_4"/>
        </div>
      </div>
      <div class="right_form">
        <div class="input_wrap">
          <label>City</label>
          <input autocomplete="off" type="text" name="city" value="{{userFields.city}}" [(ngModel)]="userFields.city"/>
        </div>
        <div class="input_wrap">
          <label>County</label>
          <input autocomplete="off" type="text" name="county" value="{{userFields.county}}" [(ngModel)]="userFields.county"/>
        </div>
        <div class="input_wrap">
          <label>Postcode</label>
          <input autocomplete="off" type="text" maxlength="10" (keypress)="keyPress($event)" name="postcode" value="{{userFields.postcode}}" [(ngModel)]="userFields.postcode"/>
        </div>
        <div class="input_wrap">
          <label>Country</label>
          <input autocomplete="off" type="text" name="country" value="{{userFields.country}}" [(ngModel)]="userFields.country"/>
        </div>
      </div>
      <div class="popup_btns">
        <button type="button" class="cancel" (click)="popupClose($event)">Cancel</button>
        <button type="submit" (click)="formUserUpdate($event,updateUser)">Save</button>
      </div>
    </form>
  </div>
</div>

<!-- file upload pop-up -->
<div class="popup" [ngClass]="{opened: fileUploadPopupOpened}" ngDraggable ngResizable [rzHandles]="'n,e,s,w,se,sw,ne,nw'" [handle]="dragHandle14">
  <a href="#" class="popup_close" (click)="popupClose($event)"></a>
  <div class="popup_title" #dragHandle14>
    <span>Brief Statement Upload</span>
  </div>
  <div>
    <app-upload-files (onDocumentUpdate)="onDocumentUpdate($event)"></app-upload-files>
  </div>

  <table style="width: 100%" aria-describedby="file upload pop-up">
      <tr>
        <th scope="col" style="width:5%">No</th>
        <th scope="col" style="width:30%">Name</th>
        <th scope="col" style="width:10%">Doc Type</th>
        <th scope="col" style="width:20%">Uploaded Date</th>
        <th scope="col" style="width:15%">Actions</th>
      </tr>

      <tr *ngFor="let d of documentList let i = index"   >
        <td style="width:5%">{{ i + 1 }}</td>

          <td style="width:30%">{{d.document_name}}</td>
        <td style="width:10%">{{d.document_type}}</td>
        <td style="width:20%">{{d.updated_at | date}}</td>

        <td style="width:15%" *ngIf="current_user!=null&&(current_user.type=='Consultant'||current_user.type=='Administrator')"><a><img alt="download" src="../../assets/img/download.png" class="action-icons" (click)="DownloadPDF($event,d.id)"  title="Priview/Download"></a> &nbsp;<a  > <img alt="delete" src="../../assets/img/delete.png" class="action-icons" (click)="openConfirmPopup(d.id,6)"  title="Delete"></a>
        <a href="/consultant-functions?docID={{d.id}}" class="open_new_window" target="_blank" title="Open the doc in New Window" style="width: 16px;height: 16px;background-size: 16px auto;"></a>
         </td>
      </tr>
    </table>

    <div class="popup_btns">
    <button type="button" class="cancel" (click)="popupClose($event)">Close</button>

      </div>
      <br>
</div>


<div class="popup search_playlist_popup" [ngClass]="{opened: searchPopupOpened}" ngDraggable ngResizable [rzHandles]="'n,e,s,w,se,sw,ne,nw'" [handle]="dragHandle5">
  <a href="#" class="popup_close" (click)="popupClose($event)"></a>
  <div class="popup_title" #dragHandle5>Search: {{searchStr}}</div>
  <div class="popup_form">
    <div class="table_wrap search_table">
      <div class="table_titles">
        <div>Name</div>
        <div>Client</div>
        <div>Channel</div>
        <div>Site</div>
        <div>Date Added</div>
        <div>Date Updated</div>
        <div># of Tracks</div>
        <div>Status</div>
        <div>Functions</div>
        <div>Sourced/Linked</div>
      </div>
      <div class="table_content" *ngIf="searchedPlaylists.length > 0">
        <div class="table_row" *ngFor="let sp of searchedPlaylists">
          <div>{{sp.name}}</div>
          <div>
            <!-- {{sp.user!=null ? sp.user.first_name : '' }} {{sp.user!=null ? sp.user.last_name : '' }} -->
            {{sp.client_name}}
          </div>
          <div>
            <a href="#" (click)="goToChannel($event,sp.cp_category?.client_id,sp.cp_category?.parent_id,sp.cp_category?.id)">{{sp.cp_category!=null ? sp.cp_category.name : ''}}</a>
          </div>
          <div><a href="#" (click)="goToSite($event,sp.cp_category?.client_id,sp.cp_category?.parent_id)">{{sp.site_name}}</a></div>
          <div>{{sp.created_at}}</div>
          <div>{{sp.updated_at}}</div>
          <div>{{sp.file_counts}}</div>
          <div>
            <select id="Id" #Id="ngModel" class="custom-select hideLabel form-control" [(ngModel)]="sp.status" name="Id" style="width: 95px;" (change)="statusChange($event,sp.id)">  
              <option value="0">LIVE</option>  
              <option value="1">EDITING</option>  
              <option value="2">SUSPENDED</option>
            </select>
            <!-- {{ playlistStatusText[sp.status]}} -->
          </div>
          <div>
            
            <div class="editing_wrap">
              <a class="edit_icon" title="Edit" href="#" (click)="openPlaylistPopup($event,sp.id)"></a>
             
            </div> 
            <a class="delete_icon" title="Delete" href="#" (click)="openConfirmPopup(sp.id,3)"></a>
          </div>
          <div>
            <div class="linked_foundation_wrap" [ngClass]="{selected: selectedFoundation == sp.id}" (click)="showFoundation(sp.id)">
              <a routerLink="/manage-playlists" (click)="manageFPClick(sp.foundation_ids[0].id)" *ngIf="sp.foundation_ids.length > 0 && sp.foundation_ids.length < 2">{{sp.foundation_ids[0].name}}</a>
              <span *ngIf="sp.foundation_ids.length > 1">{{sp.foundation_ids[0].name}}</span>
              <ul *ngIf="sp.foundation_ids.length > 1">
                <li *ngFor="let fi of sp.foundation_ids"><a routerLink="/manage-playlists" (click)="manageFPClick(fi.id)">{{fi.name}}</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="table_content nf" *ngIf="searchedPlaylists.length <= 0">
        <span>No results found</span>
      </div>
    </div>
    <div class="popup_btns">
      <button type="button" class="cancel" (click)="popupClose($event)">Close</button>
    </div>
  </div>
</div>

<app-player-component [isInputFocused]="isFocused" [storage]="playerInfo.storage" [token]="playerInfo.token" [track]="playerInfo.trackId" [wave]="playerInfo.trackId" [album]="playerInfo.album" [artist]="playerInfo.artist" (outputPlaying)="checkPlaying($event)" (next)="nextTrack($event)" (prev)="prevTrack($event)"></app-player-component>
<app-alert></app-alert>
<app-confirm #confirm (isConfirm)="isConfirm($event)"></app-confirm>
<app-confirm #confirmAll (isConfirm)="isAllConfirm($event)"></app-confirm>

<div class="popup restored_tracks_popup" [ngClass]="{opened: popups.restoredTracksPopupOpened}" ngDraggable ngResizable [rzHandles]="'n,e,s,w,se,sw,ne,nw'" [handle]="dragHandle6">
  <a href="#" class="popup_close" (click)="popupClose($event)"></a>
  <div class="popup_title" #dragHandle6>&nbsp;</div>
  <div class="popup_form">
    <div class="table_wrap restored_playlist_table">
      <div class="table_titles">
        <div>Track name</div>
        <div>Field name</div>
        <div>Prev Value</div>
        <div>Next value</div>
        <div>Modified date</div>
      </div>
      
    </div>
    <div class="popup_btns">
      <button type="button" class="cancel" (click)="popupClose($event)">Close</button>
    </div>
  </div>
</div>
<div class="popup restored_playlist_popup" [ngClass]="{opened: popups.restoredPlaylistPopupOpened}" ngDraggable ngResizable [rzHandles]="'n,e,s,w,se,sw,ne,nw'" [handle]="dragHandle7">
  <a href="#" class="popup_close" (click)="popupClose($event)"></a>
  <div class="popup_title" #dragHandle7>&nbsp;</div>
  <div class="popup_form">
    <div class="table_wrap restored_playlist_table">
      <div class="table_titles">
        <div>Playlist name</div>
        <div>Track name</div>
        <div>Allocated date</div>
        <div>Dellocated date</div>
        <div>Actions</div>
        <div>Username</div>
      </div>
      <div class="table_content">
        <div class="table_row" *ngFor="let rpt of restoredPlaylistTracks">
          <div>{{rpt.playlist_name}}</div>
          <div (click)="openTrackRestorePopup($event,rpt.id,null)">{{rpt.title}}</div>
          <div>{{rpt.updated_at}}</div>
          <div>{{rpt.deleted_at}}</div>
          <div>
            <a class="track_restore" title="Restore">Restore</a>
          </div>
          <div>{{rpt.user_name}}</div>
        </div>
      </div>
    </div>
    <div class="popup_btns">
      <button type="button" class="cancel" (click)="popupClose($event)">Close</button>
    </div>
  </div>
</div>
<div class="popup restored_chanels_popup" [ngClass]="{opened: popups.restoredChanelsPopupOpened}" ngDraggable ngResizable [rzHandles]="'n,e,s,w,se,sw,ne,nw'" [handle]="dragHandle8">
  <a href="#" class="popup_close" (click)="popupClose($event)"></a>
  <div class="popup_title" #dragHandle8>&nbsp;</div>
  <div class="popup_form">
    <div class="table_wrap restored_playlist_table">
      <div class="table_titles">
        <div>Channel</div>
        <div>Site</div>
        <div>Playlist name</div>
        <div>Allocated date</div>
        <div>Dellocated date</div>
        <div>Actions</div>
        <div>Username</div>
      </div>
      <div class="table_content">
        <div class="table_row" *ngFor="let rp of restoredChanels">
          <div>{{rp.chanel}}</div>
          <div>{{rp.site}}</div>
          <div><a href="#" (click)="openPlaylistRestorePopup($event,rp.id,mysqlRestoredDate)">{{rp.name}}</a></div>
          <div>{{rp.updated_at}}</div>
          <div>{{rp.deleted_at}}</div>
          <div>
            <a class="plalist_restore" title="Restore">Restore</a>
          </div>
          <div>{{rp.first_name}} {{rp.last_name}}</div>
        </div>
      </div>
    </div>
    <div class="popup_btns">
      <button type="button" class="cancel" (click)="popupClose($event)">Close</button>
    </div>
  </div>
</div>
<div class="popup restored_dates_popup" [ngClass]="{opened: popups.restoredDatesPopupOpened}" ngDraggable ngResizable [rzHandles]="'n,e,s,w,se,sw,ne,nw'" [handle]="dragHandle9">
  <a href="#" class="popup_close" (click)="popupClose($event)"></a>
  <div class="popup_title" #dragHandle9>&nbsp;</div>
  <div class="popup_form">
    <ul class="restored_dates">
      <li *ngFor="let d of historyDates" (click)="openChanelsRestorePopup($event,d.cp_category_id,d.modify_date_mysql)">{{d.modify_date}}</li>
    </ul>
    <div class="popup_btns">
      <button type="button" class="cancel" (click)="popupClose($event)">Close</button>
    </div>
  </div>
</div>
<div class="popup schedule_popup" [ngClass]="{opened: popups.schedulePopupOpened}" ngDraggable ngResizable [rzHandles]="'n,e,s,w,se,sw,ne,nw'" [handle]="dragHandle10">
  <a href="#" class="popup_close" (click)="popupClose($event)"></a>
  <div class="popup_title" #dragHandle10>Create schedule</div>
  <div class="popup_form">
    <div class="schedule_form">
      <form #scheduleForm="ngForm">
        <div class="form_column">
          <div class="input_wrap">
            <label>Name*</label>
            <input autocomplete="off" type="text" name="name" value="{{schedule.name}}" [(ngModel)]="schedule.name" [ngClass]="{error: !scheduleValidation.name}"/>
          </div>
          <div class="input_wrap">
            <label>Client*</label>
            <select name="user_id" *ngIf="usersList!=null" [(ngModel)]="schedule.userId" (change)="userChange($event, 3)" [ngClass]="{error: !scheduleValidation.userId}">
              <option value="null" [selected]="schedule.userId==null">Select Client...</option>
              <option *ngFor="let ul of usersList" value="{{ul.id}}" [selected]="schedule.userId == ul.id">{{ul.first_name}}</option>
            </select>
          </div>
          <div class="input_wrap">
            <label>Site*</label>
            <select name="site_id" [(ngModel)]="schedule.siteId" (change)="categoryChange($event,2)" [ngClass]="{error: !scheduleValidation.siteId}">
              <option value="null" [selected]="schedule.siteId==null">Select Site...</option>
              <option *ngFor="let s of schedule.sitesList" value="{{s.id}}" [selected]="schedule.siteId == s.id">{{s.name}}</option>
            </select>
          </div>
          <div class="input_wrap">
            <label>Channel*</label>
            <select name="chanel_id" [(ngModel)]="schedule.channelId" (change)="channelChange($event,1)" [ngClass]="{error: !scheduleValidation.channelId}">
              <option value="null" [selected]="schedule.channelId==null">Select Channel...</option>
              <option *ngFor="let c of schedule.channelsList" value="{{c.id}}" [selected]="schedule.channelId == c.id">{{c.name}}</option>
            </select>
          </div>
          <div class="input_wrap">
            <label>Playlist Name*</label>
            <select name="playlist_id" [(ngModel)]="schedule.playlistId" (change)="getArtistsNumber()" [ngClass]="{error: !scheduleValidation.playlistId}">
              <option value="null" [selected]="schedule.playlistId==null">Select playlist...</option>
              <option *ngFor="let p of schedule.playlistList" value="{{p.id}}" [selected]="schedule.playlistId==p.id">{{p.name}}</option>
            </select>
          </div>
          <div class="input_wrap">
            <label>In Date*</label>
            
            <select style="width: 18%;min-width:18%;margin-right: 5px;" name="begin-date-year" [(ngModel)]="schedule.begin_date.year"
            (ngModelChange)="schedule.begin_date.year = $event ? $event : 'YYYY'">
              <optgroup label="current value">
                <option value="{{schedule.begin_date.year}}">{{schedule.begin_date.year}}</option>
              </optgroup>
              <optgroup label="options">
                <option value=""></option>
                <option value="YYYY">YYYY</option>
                <option value="1899">1899</option>
                <option *ngFor="let year of year_fr" value="{{year}}">{{year}}</option>
                <option value="2121">2121</option>
              </optgroup>
            </select>
            <select style="width: 14%;min-width:14%;margin-right: 5px;" name="begin-date-month" [(ngModel)]="schedule.begin_date.month"
            (ngModelChange)="schedule.begin_date.month = $event ? $event : 'MM'">
              <option value="MM">MM</option>
              <option *ngFor="let month of month_fr" value="{{month}}">{{month}}</option>
            </select>
            <select style="width: 14%;min-width:14%;" name="begin-date-day" [(ngModel)]="schedule.begin_date.day"
            (ngModelChange)="schedule.begin_date.day = $event ? $event : 'DD'">
              <option value="DD">DD</option>
              <option *ngFor="let day of day_fr(schedule.begin_date.year,schedule.begin_date.month)" value="{{day}}">{{day}}</option>
            </select>
          </div>
          <div class="input_wrap">
            <label>Out Date*</label>
            
            <select style="width: 18%;min-width:18%;margin-right: 5px;" name="enddate-year" [(ngModel)]="schedule.finish_date.year"
            (ngModelChange)="schedule.finish_date.year = $event ? $event : ''">
              <optgroup label="current value">
                <option value="{{schedule.finish_date.year}}">{{schedule.finish_date.year}}</option>
              </optgroup>
              <optgroup label="options">
                <option value=""></option>
                <option value="YYYY">YYYY</option>
                <option value="1899">1899</option>
                <option *ngFor="let year of year_fr" value="{{year}}">{{year}}</option>
                <option value="2121">2121</option>
              </optgroup>
            </select>
            <select style="width: 14%;min-width:14%;margin-right: 5px;" name="enddate-month" [(ngModel)]="schedule.finish_date.month"
            (ngModelChange)="schedule.finish_date.month = $event ? $event : ''">
              <option value=""></option>
              <option value="MM">MM</option>
              <option *ngFor="let month of month_fr" value="{{month}}">{{month}}</option>
            </select>
            <select style="width: 14%;min-width:14%;" name="enddate-day" [(ngModel)]="schedule.finish_date.day"
            (ngModelChange)="schedule.finish_date.day = $event ? $event : ''">
              <option value=""></option>
              <option value="DD">DD</option>
              <option *ngFor="let day of day_fr(schedule.finish_date.year,schedule.finish_date.month)" value="{{day}}">{{day}}</option>
            </select>
          </div>
          <div class="input_wrap split">
            <label>Start Time*</label>
            <select name="begin_time_hour" [(ngModel)]="schedule.begin_time.hours"
            (ngModelChange)="schedule.begin_time.hours = $event ? $event : '00'"
            (change)="changeFinishTime()" [disabled]="schedule.onDemand">
              <option *ngFor="let hour of begin_hours" value="{{hour}}">{{hour}}</option>
            </select>
            <label for="begin_time_hour" class="mini_label">H</label>

            <select name="begin_time_minute" [(ngModel)]="schedule.begin_time.minutes"
            (ngModelChange)="schedule.begin_time.minutes = $event ? $event : '00'"
            (change)="changeFinishTime()" [disabled]="schedule.onDemand">
              <option *ngFor="let minute of begin_minutes" value="{{minute}}">{{minute}}</option>
            </select>
            <label for="begin_time_minute" class="mini_label" style="margin-right: 5px;">M</label>
            
          </div>
          <div class="input_wrap split">
            <label>End Time*</label>
            <select name="finish_time_hour" [(ngModel)]="schedule.finish_time.hours"
            (ngModelChange)="schedule.finish_time.hours = $event ? $event : '00'" 
            (change)="changeFinishTime()" [disabled]="schedule.onDemand">
              <option *ngFor="let hour of finish_hours" value="{{hour}}">{{hour}}</option>
            </select>
            <label for="finish_time_hour" class="mini_label">H</label>

            <select name="finish_time_minute" [(ngModel)]="schedule.finish_time.minutes"
            (ngModelChange)="schedule.finish_time.minutes = $event ? $event : '00'" 
            (change)="changeFinishTime()" [disabled]="schedule.onDemand">
              <option *ngFor="let minute of finish_minutes" value="{{minute}}">{{minute}}</option>
            </select>
            <label for="finish_time_minute" class="mini_label">M</label>
           
          </div>
        </div>
        <div class="form_column">
          <div class="input_wrap">
            <label>Recurrence*</label>
            <div class="radio_wrap">
              <input id="i-rec-1" name="rec" type="radio" value="1" [(ngModel)]="schedule.recurrence_fl" [checked]="schedule.recurrence_fl=='1'" (click)="changeRecurrence()" (change)="changeRecurrence()"/>
              <label for="i-rec-1" (click)="changeRecurrence()">Yes</label>
            </div>
            <div class="radio_wrap">
              <input id="i-rec-2" name="rec" type="radio" value="0" [(ngModel)]="schedule.recurrence_fl" [checked]="schedule.recurrence_fl=='0'" (change)="changeRecurrence()"/>
              <label for="i-rec-2">No</label>
            </div>
          </div>
          <div class="input_wrap">
            <label>Random Order*</label>
            <div class="radio_wrap">
              <input id="i-rand-1" name="rand" type="radio" value="1" [(ngModel)]="schedule.random" [checked]="schedule.random=='1'"/>
              <label for="i-rand-1">Yes</label>
            </div>
            <div class="radio_wrap">
              <input id="i-rand-2" name="rand" type="radio" value="0" [(ngModel)]="schedule.random" [checked]="schedule.random=='0'"/>
              <label for="i-rand-2">No</label>
            </div>
          </div>
          <div class="input_wrap small">
            <label>Artist Clash Distance*</label>
            <input type="text"  name="clash_distance" value="{{schedule.clash_distance}}" [(ngModel)]="schedule.clash_distance" [ngClass]="{error: !scheduleValidation.clash_distance}"/>
            <label>&nbsp;&nbsp;Tracks</label>
          </div>
          <div class="input_wrap duration">
            <label class="double">Maximum Override Duration</label>
            <select name="duration_hour" [(ngModel)]="schedule.override_duration.hours"
            (ngModelChange)="schedule.override_duration.hours = $event ? $event : '00'">
              <option *ngFor="let hour of ['00','01','02','03','04','05','06','07','08','09','10','11']" value="{{hour}}">{{hour}}</option>
            </select>
            <label for="duration_hour" class="small_label">Hours</label>

            <select name="duration_minute" [(ngModel)]="schedule.override_duration.minutes"
            (ngModelChange)="schedule.override_duration.minutes = $event ? $event : '00'">
              <option *ngFor="let minute of 
              ['00','01','02','03','04','05','06','07','08','09','10',
              '11','12','13','14','15','16','17','18','19','20',
              '21','22','23','24','25','26','27','28','29','30',
              '31','32','33','34','35','36','37','38','39','40',
              '41','42','43','44','45','46','47','48','49','50',
              '51','52','53','54','55','56','57','58','59']" value="{{minute}}">{{minute}}</option>
            </select>
            <label for="duration_minute" class="small_label">Minutes</label>

            
          </div>
          <div class="input_wrap">
            <label>Mastering*</label>
            <div class="radio_wrap">
              <input id="i-mast-1" name="mast" type="radio" value="1" [(ngModel)]="schedule.mastering_fl" [checked]="schedule.mastering_fl=='1'" (change)="changeMastering()"/>
              <label for="i-mast-1" (click)="changeMastering()">Yes</label>
            </div>
            <div class="radio_wrap">
              <input id="i-mast-2" name="mast" type="radio" value="0" [(ngModel)]="schedule.mastering_fl" [checked]="schedule.mastering_fl=='0'" (change)="changeMastering()"/>
              <label for="i-mast-2">No</label>
            </div>
          </div>
          <div class="input_wrap">
            <label>Background*</label>
            <input [value]="schedule.bgColor" [(colorPicker)]="schedule.bgColor" [cpWidth]="'200px'" [(style.background)]="schedule.bgColor" [cpPosition]="'right'" class="colorpicker"  [ngClass]="{error: !scheduleValidation.bgColor}" [cpPresetColors]="['#ffffff', '#000000', '#00ff00', '#ff0000', '#aaaaaa', '#ff00ff', '#00ffff', '#eaeaea', '#0000ff', '#777777']"/>
          </div>
          <div class="input_wrap">
            <label>Text*</label>
            <input [value]="schedule.txtColor" [(colorPicker)]="schedule.txtColor" [cpWidth]="'200px'" [(style.background)]="schedule.txtColor" [cpPosition]="'right'" class="colorpicker" [ngClass]="{error: !scheduleValidation.txtColor}" [cpPresetColors]="['#ffffff', '#000000', '#00ff00', '#ff0000', '#aaaaaa', '#ff00ff', '#00ffff', '#eaeaea', '#0000ff', '#777777']"/>
          </div>
          <div class="input_wrap">
            <label class="double">On Demand / Intersub</label>
            <input style="width: auto;height: auto;" type="checkbox" id="onDemand" name="onDemand" value="schedule.onDemand" (click)="onDemandClick($event)" [checked]="schedule.onDemand">
          </div>
        </div>
      </form>
    </div>
    <div class="popup_btns">
      <button type="button" class="cancel" (click)="popupClose($event)">Cancel</button>
      <button type="submit" (click)="checkSchedule()">Save</button>
    </div>
  </div>
</div>
<div class="popup recurrence_popup" [ngClass]="{opened: popups.recurrencePopupOpened}" ngDraggable ngResizable [rzHandles]="'n,e,s,w,se,sw,ne,nw'" [handle]="dragHandle11">
  <a href="#" class="popup_close" (click)="recurrencePopupClose(0)"></a>
  <div class="popup_title" #dragHandle11>Recurrence</div>
  <div class="popup_form">
    <form #recurrenceForm="ngForm">
      <div class="left_form">
        <div class="input_wrap">
          <input id="rec-1" name="recurrence" type="radio" value="1" [(ngModel)]="schedule.recurrence.radio_value" [checked]="schedule.recurrence.radio_value=='1'"/>
          <label for="rec-1">Daily</label>
        </div>
        <div class="input_wrap">
          <input id="rec-2" name="recurrence" type="radio" value="2" [(ngModel)]="schedule.recurrence.radio_value" [checked]="schedule.recurrence.radio_value=='2'"/>
          <label for="rec-2">Weekly</label>
        </div>
        <div class="input_wrap">
          <input id="rec-3" name="recurrence" type="radio" value="3" [(ngModel)]="schedule.recurrence.radio_value" [checked]="schedule.recurrence.radio_value=='3'"/>
          <label for="rec-3">Monthly</label>
        </div>
        <div class="input_wrap">
          <input id="rec-4" name="recurrence" type="radio" value="4" [(ngModel)]="schedule.recurrence.radio_value" [checked]="schedule.recurrence.radio_value=='4'"/>
          <label for="rec-4">Yearly</label>
        </div>
      </div>
      <div class="right_form">
        <div class="daily_form" *ngIf="schedule.recurrence.radio_value=='1'">
          <div class="input_wrap">
            <label>Every</label>
            <input type="text" name="day" value="{{schedule.recurrence.daily.days}}" [(ngModel)]="schedule.recurrence.daily.days"/>
            <label>days</label>
          </div>
        </div>
        <div class="weekly_form" *ngIf="schedule.recurrence.radio_value=='2'">
          <div class="input_wrap">
            <label>Every</label>
            <input type="text" name="week" value="{{schedule.recurrence.weekly.week}}" [(ngModel)]="schedule.recurrence.weekly.week"/>
            <label>week(s) on</label>
          </div>
          <select name="playlist_name" [(ngModel)]="schedule.recurrence.weekly.days"
          (ngModelChange)="schedule.recurrence.weekly.day = $event ? $event : null">
            <option value="null" >Every day of week</option>
            <option value="SU" >Sunday</option>
            <option value="MO" >Monday</option>
            <option value="TU" >Tuesday</option>
            <option value="WE" >Wednesday</option>
            <option value="TH" >Thursday</option>
            <option value="FR" >Friday</option>
            <option value="SA" >Saturday</option>
          </select>
          
        </div>
        <div class="monthly_form" *ngIf="schedule.recurrence.radio_value=='3'">
          <div class="input_wrap">
            <label>Every</label>
            <input type="text" name="month" value="{{schedule.recurrence.monthly.month}}" [(ngModel)]="schedule.recurrence.monthly.month"/>
            <label>month(s)</label>
          </div>
          <div class="input_wrap">
            <input id="ms-1" name="month_selection" type="radio" value="1" [(ngModel)]="schedule.recurrence.monthly.monthSelection" [checked]="schedule.recurrence.monthly.monthSelection=='1'"/>
            <label for="ms-1">On this days</label>
          </div>
          <div class="days" *ngIf="schedule.recurrence.monthly.monthSelection == '1'">
            <div class="chb_day" *ngFor="let d of schedule.recurrence.monthly.days">
              <input type="checkbox" id="chb_mday-{{d.num}}" name="chb_mday-{{d.num}}" [checked]="d.value" [(ngModel)]="schedule.recurrence.monthly.days[d.num-1].value" />
              <label for="chb_mday-{{d.num}}">{{d.num}}</label>
            </div>
          </div>
          <div class="input_wrap">
            <input id="ms-2" name="month_selection" type="radio" value="2" [(ngModel)]="schedule.recurrence.monthly.monthSelection" [checked]="schedule.recurrence.monthly.monthSelection=='2'"/>
            <label for="ms-2">On</label>
          </div>
          <div class="days2" *ngIf="schedule.recurrence.monthly.monthSelection=='2'">
            <select name="m_num" [(ngModel)]="schedule.recurrence.monthly.number">
              <option value="1" [selected]="schedule.recurrence.monthly.number=='1'">First</option>
              <option value="2" [selected]="schedule.recurrence.monthly.number=='2'">Second</option>
              <option value="3" [selected]="schedule.recurrence.monthly.number=='3'">Third</option>
              <option value="4" [selected]="schedule.recurrence.monthly.number=='4'">Fourth</option>
              <option value="5" [selected]="schedule.recurrence.monthly.number=='5'">Fifth</option>
              <option value="last" [selected]="schedule.recurrence.monthly.number=='last'">Last</option>
            </select>
            <select name="m_type" [(ngModel)]="schedule.recurrence.monthly.type">
              <option value="SU" [selected]="schedule.recurrence.monthly.type=='SU'">Sunday</option>
              <option value="MO" [selected]="schedule.recurrence.monthly.type=='MO'">Monday</option>
              <option value="TU" [selected]="schedule.recurrence.monthly.type=='TU'">Tuesday</option>
              <option value="WE" [selected]="schedule.recurrence.monthly.type=='WE'">Wednesday</option>
              <option value="TH" [selected]="schedule.recurrence.monthly.type=='TH'">Thursday</option>
              <option value="FR" [selected]="schedule.recurrence.monthly.type=='FR'">Friday</option>
              <option value="SA" [selected]="schedule.recurrence.monthly.type=='SA'">Saturday</option>
              <option value="day" [selected]="schedule.recurrence.monthly.type=='day'">Day</option>
            </select>
          </div>
        </div>
        <div class="yearly_form" *ngIf="schedule.recurrence.radio_value=='4'">
          <div class="input_wrap">
            <label>Every</label>
            <input type="text" name="month" value="{{schedule.recurrence.yearly.year}}" [(ngModel)]="schedule.recurrence.yearly.year"/>
            <label>year(s) in</label>
          </div>
          <div class="monthes">
            <div class="chb_month">
              <input type="checkbox" id="chb_month-1" name="chb_month[0]" value="0" [(ngModel)]="schedule.recurrence.yearly.monthes[0]" [checked]="schedule.recurrence.yearly.monthes[0]">
              <label for="chb_month-1">Jan</label>
            </div>
            <div class="chb_month">
              <input type="checkbox" id="chb_month-2" name="chb_month[1]" value="1" [(ngModel)]="schedule.recurrence.yearly.monthes[1]" [checked]="schedule.recurrence.yearly.monthes[1]">
              <label for="chb_month-2">Feb</label>
            </div>
            <div class="chb_month">
              <input type="checkbox" id="chb_month-3" name="chb_month[2]" value="2" [(ngModel)]="schedule.recurrence.yearly.monthes[2]" [checked]="schedule.recurrence.yearly.monthes[2]">
              <label for="chb_month-3">Mar</label>
            </div>
            <div class="chb_month">
              <input type="checkbox" id="chb_month-4" name="chb_month[3]" value="3" [(ngModel)]="schedule.recurrence.yearly.monthes[3]" [checked]="schedule.recurrence.yearly.monthes[3]">
              <label for="chb_month-4">Apr</label>
            </div>
            <div class="chb_month">
              <input type="checkbox" id="chb_month-5" name="chb_month[4]" value="4" [(ngModel)]="schedule.recurrence.yearly.monthes[4]" [checked]="schedule.recurrence.yearly.monthes[4]">
              <label for="chb_month-5">May</label>
            </div>
            <div class="chb_month">
              <input type="checkbox" id="chb_month-6" name="chb_month[5]" value="5" [(ngModel)]="schedule.recurrence.yearly.monthes[5]" [checked]="schedule.recurrence.yearly.monthes[5]">
              <label for="chb_month-6">Jun</label>
            </div>
            <div class="chb_month">
              <input type="checkbox" id="chb_month-7" name="chb_month[6]" value="6" [(ngModel)]="schedule.recurrence.yearly.monthes[6]" [checked]="schedule.recurrence.yearly.monthes[6]">
              <label for="chb_month-7">Jul</label>
            </div>
            <div class="chb_month">
              <input type="checkbox" id="chb_month-8" name="chb_month[7]" value="7" [(ngModel)]="schedule.recurrence.yearly.monthes[7]" [checked]="schedule.recurrence.yearly.monthes[7]">
              <label for="chb_month-8">Aug</label>
            </div>
            <div class="chb_month">
              <input type="checkbox" id="chb_month-9" name="chb_month[8]" value="8" [(ngModel)]="schedule.recurrence.yearly.monthes[8]" [checked]="schedule.recurrence.yearly.monthes[8]">
              <label for="chb_month-9">Sep</label>
            </div>
            <div class="chb_month">
              <input type="checkbox" id="chb_month-10" name="chb_month[9]" value="9" [(ngModel)]="schedule.recurrence.yearly.monthes[9]" [checked]="schedule.recurrence.yearly.monthes[9]">
              <label for="chb_month-10">Oct</label>
            </div>
            <div class="chb_month">
              <input type="checkbox" id="chb_month-11" name="chb_month[10]" value="10" [(ngModel)]="schedule.recurrence.yearly.monthes[10]" [checked]="schedule.recurrence.yearly.monthes[10]">
              <label for="chb_month-11">Nov</label>
            </div>
            <div class="chb_month">
              <input type="checkbox" id="chb_month-12" name="chb_month[11]" value="11" [(ngModel)]="schedule.recurrence.yearly.monthes[11]" [checked]="schedule.recurrence.yearly.monthes[11]">
              <label for="chb_month-12">Dec</label>
            </div>
          </div>
          <div class="input_wrap">
            <input type="checkbox" id="year_selection" name="year_selection" value="false" [(ngModel)]="schedule.recurrence.yearly.monthSelection" [checked]="schedule.recurrence.yearly.monthSelection">
            <label for="year_selection">On</label>
          </div>
          <div class="days2" *ngIf="schedule.recurrence.yearly.monthSelection==true">
            <select name="m_num" [(ngModel)]="schedule.recurrence.yearly.number">
              <option value="1" [selected]="schedule.recurrence.yearly.number==1">First</option>
              <option value="2" [selected]="schedule.recurrence.yearly.number==2">Second</option>
              <option value="3" [selected]="schedule.recurrence.yearly.number==3">Third</option>
              <option value="4" [selected]="schedule.recurrence.yearly.number==4">Fourth</option>
              <option value="5" [selected]="schedule.recurrence.yearly.number==5">Fifth</option>
              <option value="last" [selected]="schedule.recurrence.yearly.number==6">Last</option>
            </select>
            <select name="m_type" [(ngModel)]="schedule.recurrence.yearly.type">
              <option value="SU" [selected]="schedule.recurrence.yearly.type=='SU'">Sunday</option>
              <option value="MO" [selected]="schedule.recurrence.yearly.type=='MO'">Monday</option>
              <option value="TU" [selected]="schedule.recurrence.yearly.type=='TU'">Tuesday</option>
              <option value="WE" [selected]="schedule.recurrence.yearly.type=='WE'">Wednesday</option>
              <option value="TH" [selected]="schedule.recurrence.yearly.type=='TH'">Thursday</option>
              <option value="FR" [selected]="schedule.recurrence.yearly.type=='FR'">Friday</option>
              <option value="SA" [selected]="schedule.recurrence.yearly.type=='SA'">Saturday</option>
              <option value="day" [selected]="schedule.recurrence.yearly.type=='day'">Day</option>
            </select>
          </div>
        </div>
      </div>
      <div class="popup_btns">
        <button type="button" class="cancel" (click)="recurrencePopupClose(0)">Cancel</button>
        <button type="submit" (click)="recurrencePopupClose(1)">Save</button>
      </div>
    </form>
  </div>
</div>
<div class="popup mastering_popup" [ngClass]="{opened: popups.masteringPopupOpened}" ngDraggable ngResizable [rzHandles]="'n,e,s,w,se,sw,ne,nw'" [handle]="dragHandle12">
  <a href="#" class="popup_close" (click)="masteringPopupClose(0)"></a>
  <div class="popup_title" #dragHandle12>Mastering</div>
  <div class="popup_form">
    <form #recurrenceForm="ngForm">
      <div class="input_wrap">
        <input type="radio" id="mastering-1" name="mastering-1" value="0" [(ngModel)]="schedule.mastering.m_switch" [checked]="schedule.mastering.m_switch == '0'">
        <label for="mastering-1">Day</label>
        <input type="text" name="mastering_day" [(ngModel)]="schedule.mastering.days" value="{{schedule.mastering.days}}"/>
        <label>of every</label>
        <input type="text" name="mastering_month" [(ngModel)]="schedule.mastering.months" value="{{schedule.mastering.months}}"/>
        <label>month(s)</label>
      </div>
      <div class="input_wrap">
        <input type="radio" id="mastering-2" name="mastering-1" value="1" [(ngModel)]="schedule.mastering.m_switch" [checked]="schedule.mastering.m_switch == '1'">
        <label for="mastering-2">The</label>
        <select name="mastering_m_num" [(ngModel)]="schedule.mastering.every_week_day">
          <option value="1" [selected]="schedule.mastering.every_week_day==1">First</option>
          <option value="2" [selected]="schedule.mastering.every_week_day==2">Second</option>
          <option value="3" [selected]="schedule.mastering.every_week_day==3">Third</option>
          <option value="4" [selected]="schedule.mastering.every_week_day==4">Fourth</option>
          <option value="5" [selected]="schedule.mastering.every_week_day==5">Fifth</option>
          <option value="last" [selected]="schedule.mastering.every_week_day==6">Last</option>
        </select>
        <select name="mastering_m_type" [(ngModel)]="schedule.mastering.recurrence_dow">
          <option value="SU" [selected]="schedule.mastering.recurrence_dow=='SU'">Sunday</option>
          <option value="MO" [selected]="schedule.mastering.recurrence_dow=='MO'">Monday</option>
          <option value="TU" [selected]="schedule.mastering.recurrence_dow=='TU'">Tuesday</option>
          <option value="WE" [selected]="schedule.mastering.recurrence_dow=='WE'">Wednesday</option>
          <option value="TH" [selected]="schedule.mastering.recurrence_dow=='TH'">Thursday</option>
          <option value="FR" [selected]="schedule.mastering.recurrence_dow=='FR'">Friday</option>
          <option value="SA" [selected]="schedule.mastering.recurrence_dow=='SA'">Saturday</option>
          <option value="day" [selected]="schedule.mastering.recurrence_dow=='day'">Day</option>
        </select>
        <label>of every</label>
        <input type="text" name="mastering_month_2" [(ngModel)]="schedule.mastering.months_2" value="{{schedule.mastering.months_2}}"/>
        <label>month(s)</label>
      </div>
      <div class="popup_btns">
        <button type="button" class="cancel" (click)="masteringPopupClose(0)">Cancel</button>
        <button type="submit" (click)="masteringPopupClose(1)">Save</button>
      </div>
    </form>
  </div>
</div>
<div class="popup create_schedule_popup" [ngClass]="{opened: popups.createSchedulePopupOpened}" ngDraggable ngResizable [rzHandles]="'n,e,s,w,se,sw,ne,nw'" [handle]="dragHandle13">
  <a href="#" class="popup_close" (click)="popupClose($event)"></a>
  <div class="popup_title" #dragHandle13>Edit Schedule</div>
  <div class="popup_form">
    <form #editScheduleForm="ngForm">
      <div class="input_wrap">
        <label>Schedule Name</label>
        <input autocomplete="off" type="text" name="name" value="{{editSchedule.name}}" [(ngModel)]="editSchedule.name"/>
      </div>
      <div class="popup_btns">
        <button type="button" class="cancel" (click)="popupClose($event)">Cancel</button>
        <button type="submit" (click)="updateSchedule($event,editScheduleForm)">Save</button>
      </div>
    </form>
  </div>
</div>

<div class="fade" [ngClass]="{opened:isLoad}"></div>
<img src="../../assets/img/loader.gif" alt="loader" class="loader" [ngClass]="{active:isLoad}"/>
<div class="fade" [ngClass]="{opened: isLoadHawk}"></div>
<img src="../../assets/img/loader.gif" alt="loader" class="loader" [ngClass]="{active:isLoadHawk}"/>

<!-- PROFILE STATEMENT CREATE POPUP -->
<div class="popup create_profileStatement_popup" [ngClass]="{opened: popups.profileCreatePopupOpened}" ngDraggable ngResizable [rzHandles]="'n,e,s,w,se,sw,ne,nw'" [handle]="dragHandle20">
  <a href="#" class="popup_close" (click)="popupClose($event)"></a>
  <div class="popup_title" #dragHandle20>Create Profile  </div>
  <div class="popup_form">
    <form #updateProfileStament="ngForm">
      <input type="hidden" name="visible" value="{{profileStatementFields.visible}}" [(ngModel)]="profileStatementFields.visible">
      <div class="input_wrap">
        <label>Profile  Name</label>
        <input autocomplete="off" type="text" maxlength="50" name="name" value="{{profileStatementFields.name}}" [(ngModel)]="profileStatementFields.name"/>

      </div>
      <div class="popup_btns">
        <button type="button" class="cancel" (click)="popupClose($event)">Cancel</button>
        <button type="submit" (click)="profileUpdate($event, updateProfileStament,0)">Save</button>
      </div>
    </form>
  </div>
</div>

<!-- PROFILE STATEMENT MAIN POPUP -->
<div class="popup edit_track_popup profile_popup" [ngClass]="{opened: popups.profileStatementOpened}" ngDraggable
  ngResizable [rzHandles]="'n,e,s,w,se,sw,ne,nw'" [handle]="dragHandle15">
  <a href="#" class="popup_close" (click)="popupClose($event)"></a>
  <div class="popup_title" #dragHandle15>Profile Statement</div>
  <div class="popup_form">
    <form #profileStatement="ngForm" >
      <div class="left_form" style="width:45%">
        <div class="input_wrap">
          <label>Client</label>
          <label>{{checkedUserId.name}}</label>
        </div>
        <div class="input_wrap">
          <label>Channel</label>
          <label>{{checkedChanelId.name}}</label>
        </div>
        <div class="input_wrap">
          <label>Profile Statement</label>
          <input type="text" name="prs_name" [(ngModel)]='seletedProfileStatementName'>
        </div>
        <div class="input_wrap">
          <label>Playlist Duration</label>
          
          <input type="text" name="dur" [(ngModel)]="playlist_duration_ps" >
          <label> hrs</label>
        </div>
        <div class="input_wrap">
          <label>Percentage of playlist to update</label>
          <input type="text" name="perToUpt" [(ngModel)]="percentage_to_update">
          <label> %</label>
        </div>
      </div>
      <div class="right_form" style="width:45%">
        <div class="input_wrap">
          <label>Site</label>
          <label>{{checkedSiteId.name}}</label>
        </div>
        <div class="input_wrap">
          <label>Add tracks without remove any</label>
          <ui-switch 
            labelOn="Y" labelOff="N"
            size="small" 
            id="add_only" name="add_only"
            switchColor="#7a7a7a"
            defaultBoColor="#a8a8a8"
            [checked]="add_only" [(ngModel)]="add_only"></ui-switch>
        </div>
      </div>
      

      <div class="foundation_playlist_selects">
        <div class="row titles">
          <div style="width:15%">Foundation playlist</div>
          <div style="width:5%">Cover</div>
          <div style="width:7%">Ratio %</div>
          <div style="width:40%">Filter Rules</div>
          <div style="width:5%">Notes</div>
          <div style="width:4%">&nbsp;</div>
          <div style="width:12%">Available Tracks</div>
          <div style="width:12%">Future Coverage</div>
        </div>
        <div class="">
          <div class="row" *ngFor="let ps of profileStatements;let i=index">
            <div style="width:15%">
              <div class="select_fp" *ngIf="psSelects[i] != undefined">
                <span class="fp_name" (click)="psOpenDrop(i)">{{psSelects[i].title}}</span>
                <div class="custom-fp-wrap list_wrap" [ngClass]="{opened: psSelects[i].dropOpened}" 
                style="width: 630%;">
                  <ul class="custom-fp-list">
                    <li *ngFor="let fc of fpCategories" [ngClass]="{selected: psSelects[i].catId == fc.id}">
                      <span (click)="psSelectCategory(fc.id,i)">{{fc.name}}</span>
                      <ul *ngIf="fc.children.length>0" style="margin-top: 0px;">
                        <li *ngFor="let pcc of fc.children" [ngClass]="{selected: psSelects[i].subcatId == pcc.id}">
                          <span (click)="psSelectSubcategory(pcc.id,i)">{{pcc.name}}</span>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <ul class="playlists custom-fp-list-last">
                    <li *ngFor="let fp of psSelects[i].fp_arr" (click)="psSelectPlaylist(fp.id,i,fp.name)"
                      [ngClass]="{selected: psSelects[i].fp_id == fp.id}">
                      <span>{{fp.name}}</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div style="width:5%">
              <select name="cover-{{i}}" [(ngModel)]="profileStatements[i].cover">
                
                <option [selected]="profileStatements[i].cover==cover" *ngFor="let cover of coverOptions" value="{{cover}}" >
                  {{cover}}</option>
              </select>
              
            </div>
            <div style="width:7%">
              <select name="ratio-{{i}}" [(ngModel)]="profileStatements[i].ratio">
                <!-- <option value="null" [selected]="profileStatements[i].ratio==null">0</option> -->
                <option [selected]="profileStatements[i].ratio==ratio" *ngFor="let ratio of ratioOptions" value="{{ratio}}" >
                 {{ratio}} %</option>
              </select>
              
            </div>

            <div style="width:40%">
              <div style="width: 100%;" class="filter_line" *ngFor="let filter of profileStatements[i].smartFiltersPS;let k = index">
                <div class="left_col">
                  <select name="categorySelect_{{i}}_{{k}}" (change)="changeSmartSelectPS($event,i,k)" [disabled]="filter.isHidden"
                    [(ngModel)]="profileStatements[i].smartFiltersPS[k].categorySelect">
                    <option value="null">Select...</option>
                    <option *ngFor="let sf of defaultSmartFiltersPS" value="{{sf.field}}" >

                      {{sf.title}}</option>
                  </select>
                </div>
                <div class="middle_col">
                  <select name="compareSelect_{{i}}_{{k}}" *ngIf="filter.compare!=null" [(ngModel)]="profileStatements[i].smartFiltersPS[k].compareSelect">
                    <option value="null">Select...</option>
                    <option *ngFor="let c of filter.compare" value="{{c}}">{{c}}</option>
                  </select>
                </div>
                <div class="right_col" *ngIf="profileStatements[i].smartFiltersPS[k].categorySelect == 'mc_tags'; else checkCpDr">
                  <select name="defaultField_{{i}}_{{k}}" [(ngModel)]="profileStatements[i].smartFiltersPS[k].field">
                    <option value="null">Select...</option>
                    <option *ngFor="let tag of mcTagsList"  value="{{tag.item_text}}">{{tag.item_text}}</option>
                  </select>
                </div>
                <ng-template #checkCpDr>
                  <div class="right_col" *ngIf="profileStatements[i].smartFiltersPS[k].categorySelect == 'foundation_play_list_id'; else default">
                    <select name="fpInputField_{{i}}_{{k}}" [(ngModel)]="profileStatements[i].smartFiltersPS[k].field">
                      <option value="null">Select...</option>
                      <option *ngFor="let tag of cpPlaylistList"  value="{{tag.item_text}}">{{tag.item_text}}</option>
                    </select>
                  </div>
                </ng-template>
                <ng-template #default>
                <div class="right_col" *ngIf="filter.type=='str'">
                  <input  name="strInputField_{{i}}_{{k}}" type="text" value="" [(ngModel)]="profileStatements[i].smartFiltersPS[k].field" />
                </div>
                <div class="right_col" *ngIf="filter.type=='date'">
                  <my-date-picker name="date1_{{i}}_{{k}}" [options]="myDatePickerOptions" placeholder="dd/mm/yyyy"
                    [(ngModel)]="profileStatements[i].smartFiltersPS[k].arrFields[0]"></my-date-picker>
                  <my-date-picker name="date2_{{i}}_{{k}}" [options]="myDatePickerOptions" placeholder="dd/mm/yyyy"
                    [(ngModel)]="profileStatements[i].smartFiltersPS[k].arrFields[1]"></my-date-picker>
                </div>
                <div class="right_col" *ngIf="filter.type=='int'">
                  <input name="intInputField_{{i}}_{{k}}" type="number" class="number" value="" [(ngModel)]="profileStatements[i].smartFiltersPS[k].arrFields[0]" />
                  <input name="intBetInputField_{{i}}_{{k}}" type="number" class="number" value="" [(ngModel)]="profileStatements[i].smartFiltersPS[k].arrFields[1]"
                    *ngIf="profileStatements[i].smartFiltersPS[k].compareSelect=='between'" />
                </div>
                </ng-template>
                <div class="func_col" style="width: 12%;">
                  <a href="#" *ngIf="profileStatements[i].smartFiltersPS.length>1" class="btn_remove" (click)="removeFilterFieldPS(i,k)" style="margin-right: 3px;width:16px;height:18px;"></a>
                  <a href="#" class="btn_add_filter" (click)="addFilterFieldPS(i)" style="margin-right: 0px;width:16px;height:18px;"></a>
                </div>
                <div style="width:10%;">
                  <button class="load_btn" style="height: 20px;line-height: 20px;" type="button" (click)="smartSearchPS(i)">Load</button>
                </div>
              </div>
            </div>


            <div style="width:5%">
              <input type="text" name="note-{{i}}" [(ngModel)]="profileStatements[i].note" />
            </div>
            <div style="width:4%">
              <a href="#" class="btn_remove" *ngIf="profileStatements.length>1"
                (click)="removeProfile(i,ps.id,true)"></a>
              <a href="#" class="btn_add" (click)="addProfile()"></a>
            </div>
            <div style="width:12%;font-size: 10px;padding: 10px;">{{profileStatements[i].availableTracks}}</div>
            <div style="width:12%;font-size: 10px;padding: 10px;">{{profileStatements[i].futureCoverage}}</div>
          </div>
        </div>
        <div class="row last">
          <div style="width: 15%;">
            <label>Total</label>
          </div>
          <div style="width: 5%;text-align: center;">
            <label>{{getTotalCover()}}</label>
          </div>
          <div style="width: 7%;text-align: center;">
            <label>{{getTotalRatio()}} %</label>
          </div>
        </div>
      </div>
      <div class="popup_btns">

        <button type="button" class="cancel" (click)="popupClose($event)">Cancel</button><br/>
        <button type="submit" (click)="saveProfileStatement($event)"
          *ngIf="current_user!=null&&(current_user.type=='Consultant'||current_user.type=='Administrator')">Save</button>
      </div>
    </form>
  </div>
</div>

<!-- History pop up -->
<div class="popup edit_track_popup profile_popup" [ngClass]="{opened: popups.profileHistoryopened}" ngDraggable
  ngResizable [rzHandles]="'n,e,s,w,se,sw,ne,nw'" [handle]="dragHandle17">
  <a href="#" class="popup_close" (click)="popupClose($event)"></a>
  <div class="popup_title" #dragHandle17>History</div>
  <div class="popup_form">
    <div >
      <div class="left_form" style="width:45%">
        <div class="input_wrap">
          <label>Client</label>
          <label>{{checkedUserId.name}}</label>
        </div>
        <div class="input_wrap">
          <label>Channel</label>
          <label>{{checkedChanelId.name}}</label>
        </div>
      </div>

      <div class="right_form" >
        <div class="input_wrap">
          <label>Site</label>
          <label>{{checkedSiteId.name}}</label>
        </div>
        <div class="input_wrap">
          <label>Profile Name</label>
          <label>{{profileStatementFields.name}}</label>
        </div>
      </div>

      <div class="foundation_playlist_selects">
        <div class="row titles foundation-inline-width">
            <div>Fplaylist</div>
            <div>Cover</div>
            <div>Ratio %</div>
            <div>created_by</div>
            <div>Created_Date</div>
            <div>Modified_Date</div>
            <div>Action</div>
            <div>Name</div>
         <div style="width: 0%;"></div>
        </div>
        <div class="table_content" style="height:160px">
            <div class="table_row foundation-inline-width" *ngFor="let u of profileActiveStatus">
                  <div class="wd-22">{{u.foundation_name}}</div>
                  <div class="wd-22">{{u.cover}}</div>
                  <div class="wd-22">{{u.ratio}}</div>
                  <div class="wd-22">{{u.first_name}}</div>
                  <div class="wd-22">{{u.created_at}} </div>
                  <div class="wd-22"> NULL </div>
                  <div class="wd-22">FP Active</div>
                  <div class="wd-22">{{ u.first_name}}</div>
            </div>

            <div class="table_row foundation-inline-width" *ngFor="let u of profileModifiedStatus">
              <div class="wd-22">{{u.foundation_name}}</div>
              <div class="wd-22">{{u.cover}}</div>
              <div class="wd-22">{{u.ratio}}</div>
              <div class="wd-22">{{u.first_name}}</div>
              <div class="wd-22">{{u.created_at }} </div>
              <div class="wd-22">{{u.updated_at }} </div>
              <div class="wd-22">{{u.status}}</div>
              <div class="wd-22">{{ u.Modified_Fname}}</div>
        </div>

            <div class="table_row foundation-inline-width" *ngFor="let u of profileDeleteStatus">
                <div class="wd-22">{{u.foundation_name}}</div>
                <div class="wd-22">{{u.cover}}</div>
                <div class="wd-22">{{u.ratio}}</div>
                <div class="wd-22">{{u.Create_Fname}}</div>
                <div class="wd-22">{{u.created_at }} </div>
                <div class="wd-22">{{u.updated_at }}</div>
                <div class="wd-22">Deleted</div>
                <div class="wd-22">{{ u.first_name}}</div>
              </div>
        </div>
      </div>
      <div class="popup_btns">
        <button type="button" class="cancel" (click)="popupClose($event)">Cancel</button>
      </div>
    </div>
  </div>
</div>

<!-- DG POPUP -->
<div class="popup edit_track_popup " [ngClass]="{opened: popups.dgOpened}" ngDraggable
  ngResizable [rzHandles]="'n,e,s,w,se,sw,ne,nw'" [handle]="dragHandle16" style="min-width: 1200px;left: 30%;top:50px;height: 100%;">
  <a href="#" class="popup_close" (click)="popupClose($event)"></a>
  <div class="popup_title" #dragHandle16>Copy to distribution group</div>
  <div class="popup_form">
    <div *ngIf="dgCopyResults.length == 0">
      <form #dgForm="ngForm" >
      <div class="left_form" style="width: 100%;">
        <div class="input_wrap">
          <label style="width: 20%">Distribution group</label>
          <select (change)="onChangeDG()" style="width: 50%;margin-right: 10px;" name="dg_id" [(ngModel)]="dgFormFields.dg_id">
            <option value="0">-select distribution group-</option>
            <option *ngFor="let dg of dgs" value="{{dg.id}}">{{dg.name}}</option>
          </select>
          <button class="btn btn-sm" title="show masters to replace" (click)="showMasters()">
            {{ 'Show masters to replace' }}
          </button>
          <br>
          <div *ngIf="masters.length > 0">
          <label style="width: 20%">Masters</label>
          <select style="width: 50%;margin-right: 10px;" name="master_id" [(ngModel)]="dgFormFields.master_id">
            <option value="0">-select a playlist to replace-</option>
            <option *ngFor="let master of masters" value="{{master.id}}">{{master.name}}</option>
          </select>
          </div>
        </div>
      </div>

      <div class="popup_btns">
        <button type="button" class="cancel" (click)="popupClose($event)" [disabled]="isLoad">Cancel</button>
        <button type="submit" (click)="copyDg($event)" [disabled]="isLoad"
        *ngIf="current_user!=null&&(current_user.type=='Consultant'||current_user.type=='Administrator')">{{showMastersSec ? 'Replace' : 'Copy'}}</button>
      </div>
      </form>
    </div>
    <div class="table_wrap" *ngIf="dgCopyResults.length > 0" style="margin-bottom: 20px;box-shadow: 0px 0px 5px #ceb892;">
      <div class="table_titles" style="background: #d2d2d2;width: 100%;">
        <div style="max-width:30%; width: 30%;">Channel - Profile</div>
        <div style="max-width:70%; width: 70%;">Result</div>
      </div>
      <div class="table_content" style="height:300px; max-height: 300px; overflow-y: auto;">
        <div class="overwrite_all_row">
          <div>
            MasterDate: <strong>{{dgCopyResults[0].master_begindate}}</strong> to <strong>{{dgCopyResults[0].master_finishdate}}</strong>
          </div>
          <div *ngIf="hasConflicts">
            <button type="button" (click)="overwriteAllMasterSchedule()" style="margin-right: 5px;
                margin-bottom: 5px;">Overwrite (ALL)</button>
            <button type="button" (click)="adjustAllMasterSchedule()" >Adjust to schedule to fit  (ALL)</button>
          </div>
        </div>
        <div *ngFor="let dgCopyResult of dgCopyResults; let i = index">
          <div class="table_row" style="border: 1px solid #e6e4e4;">
            <div style="max-width:30%; width: 30%;" innerHTML="{{dgCopyResult.channel}}"></div>
            <div style="max-width:70%; width: 70%;">
              <div innerHTML="{{dgCopyResult.message}}"></div><br>

              <div *ngIf="dgCopyResult.keepOverwrite" style="margin-right: 5px;border-right: 1px solid #e1e1e1;padding-right: 5px;">
                <span>Conflict with schedule :</span>
                <strong>{{dgCopyResult.name}}</strong><br>
                <strong>Begin Date: {{dgCopyResult.begin_date}} </strong><br>
                <strong>Finish Date: {{dgCopyResult.finish_date}} </strong><br>
                <div *ngIf="dgCopyResult.keepMessage ==''">
                  <button type="button" (click)="overwriteifsamenameprofile(dgCopyResult.master_schedule_id,dgCopyResult.child_schedule_id,dgCopyResult.profile_to_rename,i)" style="margin-right: 5px;margin-bottom: 5px;">Overwrite schedule and assign to new profile</button>
                  <button type="button" (click)="keepschule_as_same(dgCopyResult.master_schedule_id,dgCopyResult.child_schedule_id,dgCopyResult.profile_to_rename,i)" >Keep schedule and assign to new profile</button>
                </div>
                <div *ngIf="dgCopyResult.keepMessage !=''" class="text-success">{{ dgCopyResult.keepMessage }}</div>
              </div>

              <div *ngFor="let schedule_conflict of dgCopyResult.scheduleConflict; let j = index" style="margin-right: 5px;border-right: 1px solid #e1e1e1;padding-right: 5px;">
                <span>Conflict with schedule :</span>
                <strong>{{schedule_conflict.schedule_details.name}}</strong><br>
                <strong>Begin Date: {{schedule_conflict.schedule_details.begin_date}} </strong><br>
                <strong>Finish Date: {{schedule_conflict.schedule_details.finish_date}} </strong><br>
                <div *ngIf="schedule_conflict.message==''">
                  <button type="button" (click)="overwriteMasterSchedule(dgCopyResult.scheduleConflictSourceID,schedule_conflict.schedule_id,i,j)" style="margin-right: 5px;
                  margin-bottom: 5px;">Overwrite</button>
                  <button type="button" (click)="adjustMasterSchedule(dgCopyResult.scheduleConflictSourceID,schedule_conflict.schedule_id,i,j)" >Adjust to schedule to fit</button>
                </div>
                <div *ngIf="schedule_conflict.message!=''" class="text-success">{{schedule_conflict.message}}</div>
              </div>
            </div>
          </div>
       </div>
      </div>
    </div>

    <!-- Hided Mani Jul19 : CR18 -->
    <!-- <div class="table_wrap" *ngIf="buildResults.length > 0" style="margin-bottom: 20px;box-shadow: 0px 0px 5px #ceb892;max-height: 170px;">
      <div class="table_titles" style="background: #bfbfbf;width: 100%;text-align: center;">Build results</div>
      <div class="table_titles" style="background: #d2d2d2;width: 100%;">
        <div style="max-width:30%; width: 30%;">Channel</div>
        <div style="max-width:70%; width: 70%;">Build Result</div>
      </div>
      <div class="table_content" style="min-height: 180px;height:100%; max-height: 300px; overflow-y: auto;">
        <div *ngFor="let buildResult of buildResults; let i = index">
          <div class="table_row" style="border: 1px solid #e6e4e4;">
            <div style="max-width:30%; width: 30%;" innerHTML="{{buildResult.channel}}"></div>
            <div style="max-width:70%; width: 70%;">
              <div innerHTML="{{buildResult.message}}"></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="table_wrap" *ngIf="hawkResults.length > 0" style="margin-bottom: 20px;box-shadow: 0px 0px 5px #ceb892;max-height: 170px;">
      <div class="table_titles" style="background: #bfbfbf;width: 100%;text-align: center;">Hawk results</div>
      <div class="table_titles" style="background: #d2d2d2;width: 100%;">
        <div style="max-width:30%; width: 30%;">Channel</div>
        <div style="max-width:70%; width: 70%;">Hawk Result</div>
      </div>
      <div class="table_content" style="min-height: 180px;height:100%; max-height: 300px; overflow-y: auto;">
        <div *ngFor="let hawkResult of hawkResults; let i = index">
          <div class="table_row" style="border: 1px solid #e6e4e4;">
            <div style="max-width:30%; width: 30%;" innerHTML="{{hawkResult.channel}}"></div>
            <div style="max-width:70%; width: 70%;">
              <div innerHTML="{{hawkResult.message}}"></div>
            </div>
          </div>
        </div>
      </div>
    </div> -->

  </div>
</div>

<!-- DG CHANNELS POPUP -->
<div class="popup edit_track_popup " [ngClass]="{opened: popups.dgChannelsOpened}" ngDraggable
  ngResizable [rzHandles]="'n,e,s,w,se,sw,ne,nw'" [handle]="dragHandle22" style="min-width: 1200px;left: 30%;top:50px;height: 100%;">
  <a href="#" class="popup_close" (click)="popupClose($event)"></a>
  <div class="popup_title" #dragHandle22>Copy to selected channels distribution group</div>
  <div class="popup_form">
    <div *ngIf="dgChannelsCopyResults.length == 0">
      <form #dgChannelForm="ngForm" >
      <div class="left_form" style="width: 100%;">
        <div class="input_wrap">
          <label style="width: 20%">Distribution group</label>
          <select (change)="onChangeDGChannels()" style="width: 50%;margin-right: 10px;" name="dg_id" [(ngModel)]="dgChannelsFormFields.dg_id">
            <option value="0">-select distribution group-</option>
            <option *ngFor="let dg of dgs" value="{{dg.id}}">{{dg.name}}</option>
          </select>
        </div>
        <div *ngIf="dgChannels.length > 0">
          <div class="input_wrap" style="display:flex;">
            <label style="width: 20%">Channels</label>
            <!-- <select style="width: 50%;margin-right: 10px;" name="channel_id" [(ngModel)]="dgChannelsFormFields.channel_ids">
              <option *ngFor="let dg_entities of dgChannels" value="{{dg_entities.channel.id}}">{{dg_entities.channel.name}}</option>
            </select> -->
            <div style="width: 80%">
            <ng-multiselect-dropdown
              [placeholder]="'Channels'"
              [settings]="dropdownSettings"
              [data]="dgChannels"
              [(ngModel)]="dgChannelsFormFields.channel_ids"
              (onSelect)="onItemSelect($event)"
              (onSelectAll)="onSelectAll($event)"
              name="dg_channels"
              class="dg_channel"
              style="font-size: 10px;line-height: 10px;"
              >
            </ng-multiselect-dropdown>
          </div>
          </div>
        </div>
      </div>

      <div class="popup_btns">
        <button type="button" class="cancel" (click)="popupClose($event)" [disabled]="isLoad">Cancel</button>
        <button type="submit" (click)="copyDg($event,true)" [disabled]="isLoad"
        *ngIf="current_user!=null&&(current_user.type=='Consultant'||current_user.type=='Administrator')">{{'Copy'}}</button>
      </div>
      </form>
    </div>
    <div class="table_wrap" *ngIf="dgCopyResults.length > 0" style="margin-bottom: 20px;box-shadow: 0px 0px 5px #ceb892;">
      <div class="table_titles" style="background: #d2d2d2;width: 100%;">
        <div style="max-width:30%; width: 30%;">Channel - Profile</div>
        <div style="max-width:70%; width: 70%;">Result</div>
      </div>
      <div class="table_content" style="height:300px; max-height: 300px; overflow-y: auto;">
        <div class="overwrite_all_row">
          <div>
            MasterDate: <strong>{{dgCopyResults[0].master_begindate}}</strong> to <strong>{{dgCopyResults[0].master_finishdate}}</strong>
          </div>
          <div *ngIf="hasConflicts">
            <button type="button" (click)="overwriteAllMasterSchedule()" style="margin-right: 5px;
                margin-bottom: 5px;">Overwrite (ALL)</button>
            <button type="button" (click)="adjustAllMasterSchedule()" >Adjust to schedule to fit  (ALL)</button>
          </div>
        </div>
        <div *ngFor="let dgCopyResult of dgCopyResults; let i = index">
          <div class="table_row" style="border: 1px solid #e6e4e4;">
            <div style="max-width:30%; width: 30%;" innerHTML="{{dgCopyResult.channel}}"></div>
            <div style="max-width:70%; width: 70%;">
              <div innerHTML="{{dgCopyResult.message}}"></div><br>

              <div *ngIf="dgCopyResult.keepOverwrite" style="margin-right: 5px;border-right: 1px solid #e1e1e1;padding-right: 5px;">
                <span>Conflict with schedule :</span>
                <strong>{{dgCopyResult.name}}</strong><br>
                <strong>Begin Date: {{dgCopyResult.begin_date}} </strong><br>
                <strong>Finish Date: {{dgCopyResult.finish_date}} </strong><br>
                <div *ngIf="dgCopyResult.keepMessage ==''">
                  <button type="button" (click)="overwriteifsamenameprofile(dgCopyResult.master_schedule_id,dgCopyResult.child_schedule_id,dgCopyResult.profile_to_rename,i)" style="margin-right: 5px;margin-bottom: 5px;">Overwrite schedule and assign to new profile</button>
                  <button type="button" (click)="keepschule_as_same(dgCopyResult.master_schedule_id,dgCopyResult.child_schedule_id,dgCopyResult.profile_to_rename,i)" >Keep schedule and assign to new profile</button>
                </div>
                <div *ngIf="dgCopyResult.keepMessage !=''" class="text-success">{{ dgCopyResult.keepMessage }}</div>
              </div>

              <div *ngFor="let schedule_conflict of dgCopyResult.scheduleConflict; let j = index" style="margin-right: 5px;border-right: 1px solid #e1e1e1;padding-right: 5px;">
                <span>Conflict with schedule :</span>
                <strong>{{schedule_conflict.schedule_details.name}}</strong><br>
                <strong>Begin Date: {{schedule_conflict.schedule_details.begin_date}} </strong><br>
                <strong>Finish Date: {{schedule_conflict.schedule_details.finish_date}} </strong><br>
                <div *ngIf="schedule_conflict.message==''">
                  <button type="button" (click)="overwriteMasterSchedule(dgCopyResult.scheduleConflictSourceID,schedule_conflict.schedule_id,i,j)" style="margin-right: 5px;
                  margin-bottom: 5px;">Overwrite</button>
                  <button type="button" (click)="adjustMasterSchedule(dgCopyResult.scheduleConflictSourceID,schedule_conflict.schedule_id,i,j)" >Adjust to schedule to fit</button>
                </div>
                <div *ngIf="schedule_conflict.message!=''" class="text-success">{{schedule_conflict.message}}</div>
              </div>
            </div>
          </div>
       </div>
      </div>
    </div>

    <!-- Hided Mani Jul19 : CR18 -->
    <!-- <div class="table_wrap" *ngIf="buildResults.length > 0" style="margin-bottom: 20px;box-shadow: 0px 0px 5px #ceb892;max-height: 170px;">
      <div class="table_titles" style="background: #bfbfbf;width: 100%;text-align: center;">Build results</div>
      <div class="table_titles" style="background: #d2d2d2;width: 100%;">
        <div style="max-width:30%; width: 30%;">Channel</div>
        <div style="max-width:70%; width: 70%;">Build Result</div>
      </div>
      <div class="table_content" style="min-height: 180px;height:100%; max-height: 300px; overflow-y: auto;">
        <div *ngFor="let buildResult of buildResults; let i = index">
          <div class="table_row" style="border: 1px solid #e6e4e4;">
            <div style="max-width:30%; width: 30%;" innerHTML="{{buildResult.channel}}"></div>
            <div style="max-width:70%; width: 70%;">
              <div innerHTML="{{buildResult.message}}"></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="table_wrap" *ngIf="hawkResults.length > 0" style="margin-bottom: 20px;box-shadow: 0px 0px 5px #ceb892;max-height: 170px;">
      <div class="table_titles" style="background: #bfbfbf;width: 100%;text-align: center;">Hawk results</div>
      <div class="table_titles" style="background: #d2d2d2;width: 100%;">
        <div style="max-width:30%; width: 30%;">Channel</div>
        <div style="max-width:70%; width: 70%;">Hawk Result</div>
      </div>
      <div class="table_content" style="min-height: 180px;height:100%; max-height: 300px; overflow-y: auto;">
        <div *ngFor="let hawkResult of hawkResults; let i = index">
          <div class="table_row" style="border: 1px solid #e6e4e4;">
            <div style="max-width:30%; width: 30%;" innerHTML="{{hawkResult.channel}}"></div>
            <div style="max-width:70%; width: 70%;">
              <div innerHTML="{{hawkResult.message}}"></div>
            </div>
          </div>
        </div>
      </div>
    </div> -->

  </div>
</div>


<!-- SYNC POPUP -->
<div class="popup edit_track_popup " [ngClass]="{opened: popups.dgSyncOpened}" ngDraggable
  ngResizable [rzHandles]="'n,e,s,w,se,sw,ne,nw'" [handle]="dragHandle17" style="min-width: 1200px;left: 30%;top:50px;height: 100%;">
  <a href="#" class="popup_close" (click)="popupClose($event)"></a>
  <div class="popup_title" #dragHandle17>Sync to distribution group</div>
  <div class="popup_form">
    <div class="table_wrap" *ngIf="dgCopyResults.length > 0" style="margin-bottom: 20px;box-shadow: 0px 0px 5px #ceb892;">
      <div class="table_titles" style="background: #d2d2d2;width: 100%;">
        <div style="max-width:30%; width: 30%;">Channel - Profile</div>
        <div style="max-width:70%; width: 70%;">Result</div>
      </div>
      <div class="table_content" style="height:300px; max-height: 300px; overflow-y: auto;">
        <div class="overwrite_all_row">
          <div>
            MasterDate: <strong>{{dgCopyResults[0].master_begindate}}</strong> to <strong>{{dgCopyResults[0].master_finishdate}}</strong>
          </div>
          <div *ngIf="hasConflicts">
            <button type="button" (click)="overwriteAllMasterSchedule()" style="margin-right: 5px;
                margin-bottom: 5px;">Overwrite (ALL)</button>
            <button type="button" (click)="adjustAllMasterSchedule()" >Adjust to schedule to fit  (ALL)</button>
          </div>
        </div>
        <div *ngFor="let dgCopyResult of dgCopyResults; let i = index">
          <div class="table_row" style="border: 1px solid #e6e4e4;">
            <div style="max-width:30%; width: 30%;" innerHTML="{{dgCopyResult.channel}}"></div>
            <div style="max-width:70%; width: 70%;">
              <div innerHTML="{{dgCopyResult.message}}"></div><br>
              
              <div *ngIf="dgCopyResult.keepOverwrite" style="margin-right: 5px;border-right: 1px solid #e1e1e1;padding-right: 5px;">
                <span>Conflict with schedule :</span>
                <strong>{{dgCopyResult.name}}</strong><br>
                <strong>Begin Date: {{dgCopyResult.begin_date}} </strong><br>
                <strong>Finish Date: {{dgCopyResult.finish_date}} </strong><br>
                <div *ngIf="dgCopyResult.keepMessage ==''">
                  <button type="button" (click)="overwriteifsamenameprofile(dgCopyResult.master_schedule_id,dgCopyResult.child_schedule_id,dgCopyResult.profile_to_rename,i)" style="margin-right: 5px;margin-bottom: 5px;">Overwrite schedule and assign to new profile</button>
                  <button type="button" (click)="keepschule_as_same(dgCopyResult.master_schedule_id,dgCopyResult.child_schedule_id,dgCopyResult.profile_to_rename,i)" >Keep schedule and assign to new profile</button>
                </div>
                <div *ngIf="dgCopyResult.keepMessage !=''" class="text-success">{{ dgCopyResult.keepMessage }}</div>
              </div>

              <div *ngFor="let schedule_conflict of dgCopyResult.scheduleConflict; let j = index" style="margin-right: 5px;border-right: 1px solid #e1e1e1;padding-right: 5px;">
                <span>Conflict with schedule :</span>
                <strong>{{schedule_conflict.schedule_details.name}}</strong><br>
                <strong>Begin Date: {{schedule_conflict.schedule_details.begin_date}} </strong><br>
                <strong>Finish Date: {{schedule_conflict.schedule_details.finish_date}} </strong><br>
                <div *ngIf="schedule_conflict.message==''">
                  <button type="button" (click)="overwriteMasterSchedule(dgCopyResult.scheduleConflictSourceID,schedule_conflict.schedule_id,i,j)" style="margin-right: 5px;
                  margin-bottom: 5px;">Overwrite</button>
                  <button type="button" (click)="adjustMasterSchedule(dgCopyResult.scheduleConflictSourceID,schedule_conflict.schedule_id,i,j)" >Adjust to schedule to fit</button>
                </div>
                <div *ngIf="schedule_conflict.message!=''" class="text-success">{{schedule_conflict.message}}</div>
              </div>
            </div>
          </div>
       </div>
      </div>
    </div>

    <!-- Hided Mani July19 : CR18 -->
    <!-- <div class="table_wrap" *ngIf="buildResults.length > 0" style="margin-bottom: 20px;box-shadow: 0px 0px 5px #ceb892;max-height: 170px;">
      <div class="table_titles" style="background: #bfbfbf;width: 100%;text-align: center;">Build results</div>
      <div class="table_titles" style="background: #d2d2d2;width: 100%;">
        <div style="max-width:30%; width: 30%;">Channel</div>
        <div style="max-width:70%; width: 70%;">Build Result</div>
      </div>
      <div class="table_content" style="min-height: 180px;height:100%; max-height: 300px; overflow-y: auto;">
        <div *ngFor="let buildResult of buildResults; let i = index">
          <div class="table_row" style="border: 1px solid #e6e4e4;">
            <div style="max-width:30%; width: 30%;" innerHTML="{{buildResult.channel}}"></div>
            <div style="max-width:70%; width: 70%;">
              <div innerHTML="{{buildResult.message}}"></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="table_wrap" *ngIf="hawkResults.length > 0" style="margin-bottom: 20px;box-shadow: 0px 0px 5px #ceb892;max-height: 170px;">
      <div class="table_titles" style="background: #bfbfbf;width: 100%;text-align: center;">Hawk results</div>
      <div class="table_titles" style="background: #d2d2d2;width: 100%;">
        <div style="max-width:30%; width: 30%;">Channel</div>
        <div style="max-width:70%; width: 70%;">Hawk Result</div>
      </div>
      <div class="table_content" style="min-height: 180px;height:100%; max-height: 300px; overflow-y: auto;">
        <div *ngFor="let hawkResult of hawkResults; let i = index">
          <div class="table_row" style="border: 1px solid #e6e4e4;">
            <div style="max-width:30%; width: 30%;" innerHTML="{{hawkResult.channel}}"></div>
            <div style="max-width:70%; width: 70%;">
              <div innerHTML="{{hawkResult.message}}"></div>
            </div>
          </div>
        </div>
      </div>
    </div> -->

  </div>
</div>

<!-- UNLOCK PROFILE POPUP -->
<div class="popup edit_track_popup " [ngClass]="{opened: popups.unlockOpened}" ngDraggable
  ngResizable [rzHandles]="'n,e,s,w,se,sw,ne,nw'" [handle]="dragHandle18">
  <a href="#" class="popup_close" (click)="popupClose($event)"></a>
  <div class="popup_title" #dragHandle18>Unlock profile</div>
  <div class="popup_form">
    <div >
      

      <div class="popup_btns">
        <button type="button" (click)="unlockProfile($event)">Unlock Profile</button>
        <button type="button" (click)="viewMasterPlaylist($event)" >View Master Playlist</button>

      </div>
    </div>
  </div>
</div>
<!-- UNLOCK SCHEDULE POPUP -->
<div class="popup edit_track_popup " [ngClass]="{opened: popups.schUnlockOpened}" ngDraggable
  ngResizable [rzHandles]="'n,e,s,w,se,sw,ne,nw'" [handle]="dragHandle19">
  <a href="#" class="popup_close" (click)="popupClose($event)"></a>
  <div class="popup_title" #dragHandle19>Unlock Schedule</div>
  <div class="popup_form">
    <div >
      <div class="popup_btns">
        <button type="button" (click)="unlockSchedule($event)">Unlock Schedule</button>
        <button type="button" (click)="viewMasterPlaylist($event)" >View Master Playlist</button>

      </div>
     
    </div>
  </div>
</div>


<div class="popup schedule_popupoverlay" [ngClass]="{opened: popups.openOverlaySchedulePopup}" ngDraggable ngResizable [rzHandles]="'n,e,s,w,se,sw,ne,nw'" [handle]="dragHandle21">
  <a href="#" class="popup_close" (click)="popupClose($event)"></a>
  <div class="popup_title" #dragHandle21>Create schedule(Overlay)</div>
  <div class="popup_form">
    <div class="schedule_form">
      <form #scheduleForm="ngForm">
        <div class="form_column">
          <div class="input_wrap">
            <label>Name*</label>
            <input autocomplete="off" type="text" name="name" value="{{overlayschedule.name}}" [(ngModel)]="overlayschedule.name" [ngClass]="{error: !scheduleValidationoverlay.name}"/>
          </div>
       
          <div class="input_wrap">
            <label>Playlist Name*</label>
            <select name="playlist_name" *ngIf="overlayschedule.playlistList!=undefined" [(ngModel)]="overlayschedule.playlistId">
              <option *ngFor="let p of overlayschedule.playlistList" value="{{p.id}}" [selected]="overlayschedule.playlistId == p.id">{{p.name}}</option>
            </select>
          </div>
          <div class="input_wrap">
            <label>In Date*</label>
            
            <select style="width: 18%;min-width:18%;margin-right: 5px;" name="begin-date-year" [(ngModel)]="overlayschedule.begin_date.year"
            (ngModelChange)="overlayschedule.begin_date.year = $event ? $event : 'YYYY'">
              <optgroup label="current value">
                <option value="{{overlayschedule.begin_date.year}}">{{overlayschedule.begin_date.year}}</option>
              </optgroup>
              <optgroup label="options">
                <option value=""></option>
                <option value="YYYY">YYYY</option>
                <option value="1899">1899</option>
                <option *ngFor="let year of year_fr" value="{{year}}">{{year}}</option>
                <option value="2121">2121</option>
              </optgroup>
            </select>
            <select style="width: 14%;min-width:14%;margin-right: 5px;" name="begin-date-month" [(ngModel)]="overlayschedule.begin_date.month"
            (ngModelChange)="overlayschedule.begin_date.month = $event ? $event : 'MM'">
              <option value="MM">MM</option>
              <option *ngFor="let month of month_fr" value="{{month}}">{{month}}</option>
            </select>
            <select style="width: 14%;min-width:14%;" name="begin-date-day" [(ngModel)]="overlayschedule.begin_date.day"
            (ngModelChange)="overlayschedule.begin_date.day = $event ? $event : 'DD'">
              <option value="DD">DD</option>
              <option *ngFor="let day of day_fr(overlayschedule.begin_date.year,overlayschedule.begin_date.month)" value="{{day}}">{{day}}</option>
            </select>
          </div>
          <div class="input_wrap">
            <label>Out Date*</label>
            
            <select style="width: 18%;min-width:18%;margin-right: 5px;" name="enddate-year" [(ngModel)]="overlayschedule.finish_date.year"
            (ngModelChange)="overlayschedule.finish_date.year = $event ? $event : ''">
              <optgroup label="current value">
                <option value="{{overlayschedule.finish_date.year}}">{{schedule.finish_date.year}}</option>
              </optgroup>
              <optgroup label="options">
                <option value=""></option>
                <option value="YYYY">YYYY</option>
                <option value="1899">1899</option>
                <option *ngFor="let year of year_fr" value="{{year}}">{{year}}</option>
                <option value="2121">2121</option>
              </optgroup>
            </select>
            <select style="width: 14%;min-width:14%;margin-right: 5px;" name="enddate-month" [(ngModel)]="overlayschedule.finish_date.month"
            (ngModelChange)="overlayschedule.finish_date.month = $event ? $event : ''">
              <option value=""></option>
              <option value="MM">MM</option>
              <option *ngFor="let month of month_fr" value="{{month}}">{{month}}</option>
            </select>
            <select style="width: 14%;min-width:14%;" name="enddate-day" [(ngModel)]="overlayschedule.finish_date.day"
            (ngModelChange)="overlayschedule.finish_date.day = $event ? $event : ''">
              <option value=""></option>
              <option value="DD">DD</option>
              <option *ngFor="let day of day_fr(overlayschedule.finish_date.year,overlayschedule.finish_date.month)" value="{{day}}">{{day}}</option>
            </select>
          </div>
          <div class="input_wrap split">
            <label>Start Time*</label>
            <select name="begin_time_hour" [(ngModel)]="overlayschedule.begin_time.hours"
            (ngModelChange)="overlayschedule.begin_time.hours = $event ? $event : '00'"
            (change)="changeFinishTime()" [disabled]="overlayschedule.onDemand">
              <option *ngFor="let hour of begin_hours" value="{{hour}}">{{hour}}</option>
            </select>
            <label for="begin_time_hour" class="mini_label">H</label>

            <select name="begin_time_minute" [(ngModel)]="overlayschedule.begin_time.minutes"
            (ngModelChange)="overlayschedule.begin_time.minutes = $event ? $event : '00'"
            (change)="changeFinishTime()" [disabled]="overlayschedule.onDemand">
              <option *ngFor="let minute of begin_minutes" value="{{minute}}">{{minute}}</option>
            </select>
            <label for="begin_time_minute" class="mini_label" style="margin-right: 5px;">M</label>
            
          </div>
          <div class="input_wrap split">
            <label>End Time*</label>
            <select name="finish_time_hour" [(ngModel)]="overlayschedule.finish_time.hours"
            (ngModelChange)="overlayschedule.finish_time.hours = $event ? $event : '00'" 
            (change)="changeFinishTime()" [disabled]="overlayschedule.onDemand">
              <option *ngFor="let hour of finish_hours" value="{{hour}}">{{hour}}</option>
            </select>
            <label for="finish_time_hour" class="mini_label">H</label>

            <select name="finish_time_minute" [(ngModel)]="overlayschedule.finish_time.minutes"
            (ngModelChange)="overlayschedule.finish_time.minutes = $event ? $event : '00'" 
            (change)="changeFinishTime()" [disabled]="overlayschedule.onDemand">
              <option *ngFor="let minute of finish_minutes" value="{{minute}}">{{minute}}</option>
            </select>
            <label for="finish_time_minute" class="mini_label">M</label> 
          </div>
          <div class="input_wrap split">
            <label class="double">Overlay</label>
            <input style="width: auto;height: auto;" type="checkbox" id="" name="onOverlay" value="overlayschedule.onOverlay" (click)="onOverlayClick($event)" [checked]="overlayschedule.onOverlay">
           <button (click)="setFrequencyChristmas()">CHRISTMAS</button>
          </div>
          <div class="input_wrap frequency_range" *ngIf="overlayschedule.onOverlay">
            <label>Frequency Range</label>
            <div class="foundation_playlist_selects" style="min-width: 900px;">
              <div class="row titles">
                <div style="width:20%">Start Date</div>
                <div style="width:20%">End Date</div>
                <div style="width:20%">Start Time</div>
                <div style="width:20%">End Time</div>
                <div style="width:10%">Frequency</div>
                <div style="width:10%">&nbsp;</div>
              </div>
              <div class="">
              <div class="filter_line row" *ngFor="let fr of overlayschedule.frequency_range;let i=index">
                <div style="width: 20%;">
                  
                  <select style="width: 33%;margin-right: 3px;" name="startdate-year-{{i}}" [(ngModel)]="fr.startDate.year"
                  (ngModelChange)="fr.startDate.year = $event ? $event : 'YYYY'">
                    <option value="YYYY">YYYY</option>
                    <option *ngFor="let year of year_fr" value="{{year}}">{{year}}</option>
                  </select>
                  <select style="width: 27%;margin-right: 3px;" name="startdate-month-{{i}}" [(ngModel)]="fr.startDate.month"
                  (ngModelChange)="fr.startDate.month = $event ? $event : 'MM'">
                    <option value="MM">MM</option>
                    <option *ngFor="let month of month_fr" value="{{month}}">{{month}}</option>
                  </select>
                  <select style="width: 25%;" name="startdate-day-{{i}}" [(ngModel)]="fr.startDate.day"
                  (ngModelChange)="fr.startDate.day = $event ? $event : 'DD'">
                    <option value="DD">DD</option>
                    <option *ngFor="let day of day_fr(fr.startDate.year,fr.startDate.month)" value="{{day}}">{{day}}</option>
                  </select>
                </div>
                <div style="width: 20%;">
                  
                  <select style="width: 33%;margin-right: 3px;" name="enddate-year-{{i}}" [(ngModel)]="fr.endDate.year"
                  (ngModelChange)="fr.endDate.year = $event ? $event : 'YYYY'">
                    <option value="YYYY">YYYY</option>
                    <option *ngFor="let year of year_fr" value="{{year}}">{{year}}</option>
                  </select>
                  <select style="width: 27%;margin-right: 3px;" name="enddate-month-{{i}}" [(ngModel)]="fr.endDate.month"
                  (ngModelChange)="fr.endDate.month = $event ? $event : 'MM'">
                    <option value="MM">MM</option>
                    <option *ngFor="let month of month_fr" value="{{month}}">{{month}}</option>
                  </select>
                  <select style="width: 25%;" name="enddate-day-{{i}}" [(ngModel)]="fr.endDate.day"
                  (ngModelChange)="fr.endDate.day = $event ? $event : 'DD'">
                    <option value="DD">DD</option>
                    <option *ngFor="let day of day_fr(fr.endDate.year,fr.endDate.month)" value="{{day}}">{{day}}</option>
                  </select>
                </div>
                <div style="width: 20%;">
                  <select style="width: 23%;margin-right: 2px;" name="sth-{{i}}" [(ngModel)]="fr.startTime.hours"
                  (ngModelChange)="fr.startTime.hours = $event ? $event : '00'">
                    <option *ngFor="let hour of begin_hours_fr" value="{{hour}}">{{hour}}</option>
                  </select>
                  <label for="begin_time_hour" class="mini_label">H</label>
                  <select style="width: 23%;margin-right: 2px;margin-left: 10px;" name="stm-{{i}}" [(ngModel)]="fr.startTime.minutes"
                  (ngModelChange)="fr.startTime.minutes = $event ? $event : '00'">
                    <option *ngFor="let minute of begin_minutes_fr" value="{{minute}}">{{minute}}</option>
                  </select>
                  <label for="begin_time_minute" class="mini_label" style="margin-right: 5px;">M</label>
                </div>
                <div style="width: 20%;">
                  <select style="width: 23%;margin-right: 2px;" name="fth-{{i}}" [(ngModel)]="fr.endTime.hours"
                  (ngModelChange)="fr.endTime.hours = $event ? $event : '00'">
                    <option *ngFor="let hour of finish_hours_fr" value="{{hour}}">{{hour}}</option>
                  </select>
                  <label for="finish_time_hour" class="mini_label">H</label>
                  <select style="width: 23%;margin-right: 2px;margin-left: 10px;" name="ftm-{{i}}" [(ngModel)]="fr.endTime.minutes"
                  (ngModelChange)="fr.endTime.minutes = $event ? $event : '00'">
                    <option *ngFor="let minute of finish_minutes_fr" value="{{minute}}">{{minute}}</option>
                  </select>
                  <label for="finish_time_minute" class="mini_label">M</label>
                </div>
                <div style="width: 10%;">
                  <select name="frequency-{{i}}" [(ngModel)]="fr.frequency"
                  (ngModelChange)="fr.frequency = $event ? $event : '00'">
                    <option *ngFor="let frequency of frequency_fr" value="{{frequency}}">{{frequency}}</option>
                  </select>
  
                </div>
                <div class="func_col" style="width: 10%;">
                  <a href="#" class="btn_remove" 
                    (click)="removeFrequencyRange(i,fr.id,true)"></a>
  
                  <a href="#" class="btn_add" (click)="addFrequencyRange()"></a>
                </div>
              </div>  
              </div>
            </div>
  
          </div>  

        </div>
        <div class="form_column">
          <div class="input_wrap">
            <label>Recurrence*</label>
            <div class="radio_wrap">
              <input id="i-rec-1" name="rec" type="radio" value="1" [(ngModel)]="overlayschedule.recurrence_fl" [checked]="overlayschedule.recurrence_fl=='1'" (click)="changeRecurrence()" (change)="changeRecurrence()"/>
              <label for="i-rec-1" (click)="changeRecurrence()">Yes</label>
            </div>
            <div class="radio_wrap">
              <input id="i-rec-2" name="rec" type="radio" value="0" [(ngModel)]="overlayschedule.recurrence_fl" [checked]="overlayschedule.recurrence_fl=='0'" (change)="changeRecurrence()"/>
              <label for="i-rec-2">No</label>
            </div>
          </div>
          <div class="input_wrap">
            <label>Random Order*</label>
            <div class="radio_wrap">
              <input id="i-rand-1" name="rand" type="radio" value="1" [(ngModel)]="overlayschedule.random" [checked]="overlayschedule.random=='1'"/>
              <label for="i-rand-1">Yes</label>
            </div>
            <div class="radio_wrap">
              <input id="i-rand-2" name="rand" type="radio" value="0" [(ngModel)]="overlayschedule.random" [checked]="overlayschedule.random=='0'"/>
              <label for="i-rand-2">No</label>
            </div>
          </div>
          <div class="input_wrap small">
            <label>Artist Clash Distance*</label>
            <input type="text"  name="clash_distance" value="{{overlayschedule.clash_distance}}" [(ngModel)]="overlayschedule.clash_distance" [ngClass]="{error: !scheduleValidationoverlay.clash_distance}"/>
            <label>&nbsp;&nbsp;Tracks</label>
          </div>
          <div class="input_wrap duration">
            <label class="double">Maximum Override Duration</label>
            <select name="duration_hour" [(ngModel)]="overlayschedule.override_duration.hours"
            (ngModelChange)="overlayschedule.override_duration.hours = $event ? $event : '00'">
              <option *ngFor="let hour of ['00','01','02','03','04','05','06','07','08','09','10','11']" value="{{hour}}">{{hour}}</option>
            </select>
            <label for="duration_hour" class="small_label">Hours</label>

            <select name="duration_minute" [(ngModel)]="overlayschedule.override_duration.minutes"
            (ngModelChange)="overlayschedule.override_duration.minutes = $event ? $event : '00'">
              <option *ngFor="let minute of 
              ['00','01','02','03','04','05','06','07','08','09','10',
              '11','12','13','14','15','16','17','18','19','20',
              '21','22','23','24','25','26','27','28','29','30',
              '31','32','33','34','35','36','37','38','39','40',
              '41','42','43','44','45','46','47','48','49','50',
              '51','52','53','54','55','56','57','58','59']" value="{{minute}}">{{minute}}</option>
            </select>
            <label for="duration_minute" class="small_label">Minutes</label>

            
          </div>
          <div class="input_wrap">
            <label>Mastering*</label>
            <div class="radio_wrap">
              <input id="i-mast-1" name="mast" type="radio" value="1" [(ngModel)]="overlayschedule.mastering_fl" [checked]="overlayschedule.mastering_fl=='1'" (change)="changeMastering()"/>
              <label for="i-mast-1" (click)="changeMastering()">Yes</label>
            </div>
            <div class="radio_wrap">
              <input id="i-mast-2" name="mast" type="radio" value="0" [(ngModel)]="overlayschedule.mastering_fl" [checked]="overlayschedule.mastering_fl=='0'" (change)="changeMastering()"/>
              <label for="i-mast-2">No</label>
            </div>
          </div>
          <div class="input_wrap">
            <label>Background*</label>
            <input [value]="overlayschedule.bgColor" [(colorPicker)]="overlayschedule.bgColor" [cpWidth]="'200px'" [(style.background)]="overlayschedule.bgColor" [cpPosition]="'right'" class="colorpicker"  [ngClass]="{error: !scheduleValidationoverlay.bgColor}" [cpPresetColors]="['#ffffff', '#000000', '#00ff00', '#ff0000', '#aaaaaa', '#ff00ff', '#00ffff', '#eaeaea', '#0000ff', '#777777']"/>
          </div>
          <div class="input_wrap">
            <label>Text*</label>
            <input [value]="overlayschedule.txtColor" [(colorPicker)]="overlayschedule.txtColor" [cpWidth]="'200px'" [(style.background)]="overlayschedule.txtColor" [cpPosition]="'right'" class="colorpicker" [ngClass]="{error: !scheduleValidationoverlay.txtColor}" [cpPresetColors]="['#ffffff', '#000000', '#00ff00', '#ff0000', '#aaaaaa', '#ff00ff', '#00ffff', '#eaeaea', '#0000ff', '#777777']"/>
          </div>
        </div>

        

      </form>
    </div>
    <div class="popup_btns">
      <button type="button" class="cancel" (click)="popupClose($event)">Cancel</button>
      <button type="submit" (click)="checkScheduleDate()">Save</button>
    </div>
  </div>
</div>


