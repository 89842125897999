import { BrowserModule, DomSanitizer } from '@angular/platform-browser';
import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { AppComponent } from './app.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ManageFoundationPlaylistsComponent } from './manage-foundation-playlists/manage-foundation-playlists.component';
import { NavigationComponent } from './navigation/navigation.component';
import {RouterModule} from '@angular/router';
import { PlaylistCategoriesComponent } from './playlist-categories/playlist-categories.component';
import {HttpClientModule} from '@angular/common/http';
import { AdminFunctionsComponent } from './admin-functions/admin-functions.component';
import {FormsModule} from '@angular/forms';
import { OnboardTracksComponent } from './onboard-tracks/onboard-tracks.component';
import {AngularFileUploaderModule} from 'angular-file-uploader';
import {DropzoneModule} from 'ngx-dropzone-wrapper';
import { InputAutocompleteComponent } from './input-autocomplete/input-autocomplete.component';
import { ManageFpComponent } from './manage-fp/manage-fp.component';
import { ClientPlaylistsComponent } from './client-playlists/client-playlists.component';
import { CookieService } from 'ngx-cookie-service';
import {PlayerDirective} from './directives/player.directive';
import { LoginComponent } from './login/login.component';
import { UserManagementComponent } from './user-management/user-management.component';
import {ColorPickerModule} from 'ngx-color-picker';
import {MyDatePickerModule} from 'mydatepicker';
import { PlayerProgressBarComponent } from './player-progress-bar/player-progress-bar.component';
import {DndModule} from 'ngx-drag-drop';
import { PageHeaderComponent } from './page-header/page-header.component';
import { EditUserComponentComponent } from './edit-user-component/edit-user-component.component';
import { ManageCpComponent } from './manage-cp/manage-cp.component';
import { SamplePlaylistsComponent } from './sample-playlists/sample-playlists.component';
import { ConsultantFunctionsComponent } from './consultant-functions/consultant-functions.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { PlayerComponentComponent } from './player-component/player-component.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import {Globals} from './globals';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { CreateNewUserComponent } from './create-new-user/create-new-user.component';
import { CpScheduleComponent } from './cp-schedule/cp-schedule.component';
import { ManageSheduleComponent } from './manage-shedule/manage-shedule.component';
import { ProfileStatementPopupComponent } from './profile-statement-popup/profile-statement-popup.component';
import { ClientInfoComponent } from './client-info/client-info.component';
import { GoogleChartsModule } from 'angular-google-charts';
import {AngularDraggableModule} from 'angular2-draggable';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import { AlertComponent } from './alert/alert.component';
import { ConfirmComponent } from './confirm/confirm.component';
import { UploadFilesComponent } from './upload-files/upload-files.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { AccordionComponent } from './client-playlists/accordion/accordion.component';
import { AccordionGroupComponent } from './client-playlists/accordion/accordion-group.component';
import {ProgressBarModule} from "angular-progress-bar"
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import {DatePipe} from '@angular/common';
import {NgxPaginationModule} from 'ngx-pagination';
import { TooltipModule } from 'ng2-tooltip-directive';
import { UiSwitchModule } from 'ngx-ui-switch';
import { ImportFpComponent } from './import-fp/import-fp.component';
import { DistributionGroupComponent } from './distribution-group/distribution-group.component';
import { ReportsComponent } from './reports/reports.component';
import { SampleCpPlaylistComponent } from './sample-cp-playlist/sample-cp-playlist.component';
import { ImportClientPlaylistComponent } from './import-client-playlist/import-client-playlist.component';
import { MangeSamplepCpComponent } from './mange-samplep-cp/mange-samplep-cp.component';
import { FpSamplePlaylistComponent } from './fp-sample-playlist/fp-sample-playlist.component';
import { ManageSampleFPComponent } from './manage-sample-fp/manage-sample-fp.component';
import { FlushComponent } from './flush/flush.component';
import { McSamplesgenerateClientComponent } from './mc-samplesgenerate-client/mc-samplesgenerate-client.component';
import { ClipboardModule } from 'ngx-clipboard';
import { Google2FaComponent } from './google2-fa/google2-fa.component';

// @Pipe({ name: 'safeHtml'})
// export class SafeHtmlPipe implements PipeTransform  {
//   constructor(private sanitized: DomSanitizer) {}
//   transform(value) {
//     return this.sanitized.bypassSecurityTrustHtml(value);
//   }
// }

const routes = [
  {path: '', component: DashboardComponent},
  {path: 'dashboard', component: DashboardComponent},
  {path: 'manage-foundation-playlists', component: ManageFoundationPlaylistsComponent},
  {path: 'admin-functions', component: AdminFunctionsComponent},
  {path: 'onboard-tracks', component: OnboardTracksComponent},
  {path: 'import-fp', component: ImportFpComponent},
  {path: 'manage-playlists', component: ManageFpComponent},
  {path: 'manage-client-playlists', component: ManageCpComponent},
  {path: 'client-playlists', component: ClientPlaylistsComponent},
  {path: 'login', component: LoginComponent},
  {path: 'users-management', component: UserManagementComponent},
  {path: 'edit-user', component: EditUserComponentComponent},
  {path: 'sample-playlists', component: SamplePlaylistsComponent},
  {path: 'consultant-functions', component: ConsultantFunctionsComponent},
  {path: 'notifications', component: NotificationsComponent},
  {path: 'forgot-password', component: ForgotPasswordComponent},
  {path: 'reset_password', component: ResetPasswordComponent},
  {path: 'create-new-user', component: CreateNewUserComponent},
  {path: 'manage-schedule', component: ManageSheduleComponent},
  {path: 'client-info', component: ClientInfoComponent},
  {path: 'distribution-group', component: DistributionGroupComponent},
  {path: 'edit-dg', component: DistributionGroupComponent},
  {path: 'reports', component: ReportsComponent},
  {path: 'client-sample', component: SampleCpPlaylistComponent},
  {path: 'manage-sample-client-playlists', component: MangeSamplepCpComponent },
  {path: 'sample-foundation-playlists', component: FpSamplePlaylistComponent },
  {path: 'manage-sample-foundation-playlists', component: ManageSampleFPComponent },
  {path: 'flush-entity', component: FlushComponent},
  {path: 'mc-sample/:token', component: McSamplesgenerateClientComponent},
  {path: 'Google2Fa', component: Google2FaComponent},
];

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    ManageFoundationPlaylistsComponent,
    NavigationComponent,
    PlaylistCategoriesComponent,
    AdminFunctionsComponent,
    OnboardTracksComponent,
    ImportFpComponent,
    InputAutocompleteComponent,
    ManageFpComponent,
    ClientPlaylistsComponent,
    PlayerDirective,
    LoginComponent,
    UserManagementComponent,
    PlayerProgressBarComponent,
    PageHeaderComponent,
    EditUserComponentComponent,
    ManageCpComponent,
    SamplePlaylistsComponent,
    ConsultantFunctionsComponent,
    NotificationsComponent,
    PlayerComponentComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    CreateNewUserComponent,
    CpScheduleComponent,
    ManageSheduleComponent,
    ProfileStatementPopupComponent,
    DistributionGroupComponent,
    ReportsComponent,
    ClientInfoComponent,
    AlertComponent,
    ConfirmComponent,
    UploadFilesComponent,
    SampleCpPlaylistComponent,
    AccordionGroupComponent ,
    AccordionComponent,
    ImportClientPlaylistComponent,
    MangeSamplepCpComponent,
    FpSamplePlaylistComponent,
    ManageSampleFPComponent,
    FlushComponent,
    McSamplesgenerateClientComponent,
    Google2FaComponent,
    // SafeHtmlPipe
  ],
  imports: [
    BrowserModule,
    RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }),
    HttpClientModule,
    FormsModule,
    AngularFileUploaderModule,
    DropzoneModule,
    ColorPickerModule,
    MyDatePickerModule,
    DndModule,
    GoogleChartsModule.forRoot(),
    AngularDraggableModule,
    InfiniteScrollModule,
    PdfViewerModule,
    ProgressBarModule,
    NgMultiSelectDropDownModule.forRoot(),
    NgxDocViewerModule,
    NgxPaginationModule,
    TooltipModule,
    UiSwitchModule,
    ClipboardModule
  ],
  providers: [CookieService, Globals,DatePipe],
  bootstrap: [AppComponent]
})
export class AppModule {
}
