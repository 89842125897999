import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {Router,ActivatedRoute, NavigationEnd} from '@angular/router';
import {CookieService} from 'ngx-cookie-service';
import {Globals} from './globals';
import {UserService} from './services/user.service';
import {AlertComponent} from './alert/alert.component';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [UserService]
})
export class AppComponent implements OnInit{

  isLogged:any = this.cookieService.get('enable');
  access_token = null;
  hideNavigation: boolean = false;
  isMcSamplesRoute = false;

  @ViewChild(AlertComponent, { static: true }) alert: AlertComponent;
  @Input() userInfo: any;
  constructor(
    private router: Router,
    private cookieService: CookieService,
    private globals: Globals,
    private userService: UserService,
    private route: ActivatedRoute,
  ){

  }
  ngOnInit(){
    this.router.events
    .pipe(filter(event => event instanceof NavigationEnd))
    .subscribe((event: NavigationEnd) => {
      this.isMcSamplesRoute = event.urlAfterRedirects.includes('/mc-sample');
      this.checkRouteForNavigation();
    });

    if( !this.isLogged || this.isLogged == 'false' ){
      if( window.location.pathname != '/login' && window.location.pathname != '/forgot-password' && window.location.pathname != '/reset_password' && window.location.pathname != '/create-new-user' && window.location.pathname != '/mc-sample' )
      if (window.location.pathname.includes('/mc-sample/')) {

        // Extract the token from the URL

        const tokenParam = new URLSearchParams(window.location.search).get('eyJpdiI');

        console.log(tokenParam)



        // Navigate to the 'mc-sample' page with the token

        this.router.navigate(['mc-sample', tokenParam]);

      } else {

        // Navigate to the default page (e.g., 'login') for other routes

        this.router.navigate(['login']);
      }
      if(window.location.pathname == '/reset_password'){
        this.cookieService.delete('access');
        this.cookieService.delete('enable');
      }
    }
    if(this.globals.access_token != null && this.globals.access_token != undefined && this.globals.access_token != ''){
      this.userService.getCurrentUserInfo().subscribe((user:any) => {
        if(user.error=='User account disabled!'){
          this.isLogged = false;
          this.alert.alertOpen('User account disabled!',1);
          this.cookieService.set('enable', 'false');
          this.router.navigate(['login']);
        }
        else{
          this.isLogged = true;
          this.cookieService.set('enable', 'true');
          if(window.location.pathname == '/login'){
            this.router.navigate(['/']);
          }
        }
      })
    }
    else{
      this.cookieService.set('enable', 'false');
      this.isLogged = false;
    }
  }

  private checkRouteForNavigation() {
    const currentRoute = this.route.snapshot.firstChild?.routeConfig?.path;
    // Hide navigation if the route is 'mc-sample/:token'
    this.hideNavigation = currentRoute?.startsWith('mc-sample') || false;
  }
}
