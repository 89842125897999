<app-alert></app-alert>

<!-- LOADER -->
<div class="fade" [ngClass]="{opened:isLoad}"></div>
<img src="../../assets/img/loader.gif" alt="loader" class="loader" [ngClass]="{active:isLoad}"/>

<div class="ph_block">
<!-- HEADER -->
<app-page-header [pageTitle]="'Manage reports'"></app-page-header>

<div class="top_box playlists_list_block">
  <div class="main_box" >
    
      <button style="float: right;" (click)="exportResult()" title="Export result">Export</button>
    
  </div>

    <ul class="tabs">
      <li [ngClass]="{selected: tabs.selected == 1}" (click)="selectTab(1)">Profile statement report</li>
      <li [ngClass]="{selected: tabs.selected == 2}" (click)="selectTab(2)">Festive profiles report</li>
    </ul>
    <!-- PRPFILE STATEMENT REPORT -->
    <div class="table_wrap" [ngClass]="{selected: tabs.selected == 1}">
    <div style="background-color: #ededed;margin: 10px 5px;padding: 10px 5px;display: flex;justify-content: space-between;">
      <div class="dgs_count" style="width: 70%;display: inline-block;"  *ngIf="loadContent">
        <ng-multiselect-dropdown
            [placeholder]="'Foundation Playlist'"
            [settings]="dropdownSettings"
            [data]="fpList"
            [(ngModel)]="selectedFP"
            name="fp_list"
            class="fp_list_filter"
          >
          </ng-multiselect-dropdown>
      </div>

      <select name="status_value" #status style="width:10%;">
        <option value="3">ALL</option>
        <option value="0">LIVE</option>  
        <option value="1">EDITING</option>  
        <option value="2">SUSPENDED</option>
      </select>

      <div style="display: inline-block;width: 15%;">
        <button style="width: 170px;" (click)="searchPsReport($event,status.value)" title="Search">Search</button>
      </div>
    </div>
    <!-- TABLE START -->
      <div class="dgs_list_block" >
        <div class="table_titles">
          <div class="order" [ngClass]="{active: orderBy=='ps_name',asc:order=='ASC'}" (click)="sort('ps_name')">Profile statement name</div>
          <div class="order" [ngClass]="{active: orderBy=='profile_name',asc:order=='ASC'}" (click)="sort('profile_name')">Profile Name</div>
          <div class="order" [ngClass]="{active: orderBy=='playlist_duration',asc:order=='ASC'}" (click)="sort('playlist_duration')">Playlist duration</div>
          <div class="order" [ngClass]="{active: orderBy=='percentage_to_update',asc:order=='ASC'}" (click)="sort('percentage_to_update')">% of playlist to update</div>
          <div class="order" [ngClass]="{active: orderBy=='fp_name',asc:order=='ASC'}" (click)="sort('fp_name')">Foundation playlist</div>
          <div class="order" [ngClass]="{active: orderBy=='cover',asc:order=='ASC'}" (click)="sort('cover')">Cover</div>
          <div class="order" [ngClass]="{active: orderBy=='ratio',asc:order=='ASC'}" (click)="sort('ratio')">Percentage</div>
          <div class="order" [ngClass]="{active: orderBy=='site_name',asc:order=='ASC'}" (click)="sort('site_name')">Site</div>
          <div class="order" [ngClass]="{active: orderBy=='channel_name',asc:order=='ASC'}" (click)="sort('channel_name')">Channel</div>
          <div class="order" [ngClass]="{active: orderBy=='hours_required',asc:order=='ASC'}" (click)="sort('hours_required')">Hours Req.</div>
        </div>
        <div class="table_content" *ngIf="reportList.length > 0">
          <div class="table_row" *ngFor="let report of reportList | paginate: { itemsPerPage: 20, currentPage: p }; index as i;">
          
            <div>{{report.ps_name}}</div>
            <div>{{report.profile_name}}</div>
            <div>{{report.playlist_duration}}</div>
            <div>{{report.percentage_to_update}}</div>
            <div>{{report.fp_name}}</div>
            <div>{{report.cover}}</div>
            <div>{{report.ratio}}</div>
            <div>{{report.site_name}}</div>
            <div>{{report.channel_name}}</div>
            <div>{{report.hours_required | number}}</div>
            
          </div>
        </div>
        <div *ngIf="reportList.length > 0" style="text-align: center; font-size: 12px;">
          <pagination-controls (pageChange)="p = $event"></pagination-controls>
        </div>
        <div class="table_content nf" *ngIf="reportList.length <= 0 && !isLoad" style="text-align: center;
        margin-top: 50px;
        font-size: 16px;">
          <span>No results found</span>
        </div>
      </div>
    <!-- TABLE END -->
    </div>

    <!-- FESTIVE PROFILE REPORT -->
    <div class="table_wrap" [ngClass]="{selected: tabs.selected == 2}">
      <div style="background-color: #ededed;margin: 10px 5px;padding: 10px 5px;display: flex;justify-content: space-between;">
        <div class="dgs_count festive_select" style="width: 70%;display: inline-block;">
          <label>Festive type</label>
          <select style="width: 30%;margin-right: 14px;" name="festive_type" [(ngModel)]="selectedFestive" >

              <option value="1">Christmas</option>
              <option value="2">Chinese New Year</option>
              <option value="3">Ramadan</option>
          </select>
          
        </div>
        <select name="status_value" style="width: 10%;" #statusforFp>
          <option value="3">ALL</option>
          <option value="0">LIVE</option>  
          <option value="1">EDITING</option>  
          <option value="2">SUSPENDED</option>
        </select>
        <div style="display: inline-block;width: 15%;">
          <button style="width: 170px;" (click)="searchFestiveReport($event,statusforFp.value)" title="Search">Search</button>
        </div>
      </div>
      <!-- TABLE START -->
      <div class="festive_list_block" >
        <div class="table_titles">
          <div class="order" [ngClass]="{active: orderByF=='site_name',asc:orderF=='ASC'}" (click)="sort('site_name')">Site name</div>
          <div class="order" [ngClass]="{active: orderByF=='channel_entity_id',asc:orderF=='ASC'}" (click)="sort('channel_entity_id')">Entity ID</div>
          <div class="order" [ngClass]="{active: orderByF=='channel_name',asc:orderF=='ASC'}" (click)="sort('channel_name')">Channel name</div>
          <div class="order" [ngClass]="{active: orderByF=='profile_name',asc:orderF=='ASC'}" (click)="sort('profile_name')">Profile name</div>
          <!-- <div class="order" [ngClass]="{active: orderByF=='start_date',asc:orderF=='ASC'}" (click)="sort('start_date')">Start date</div> -->
          <!-- <div style="display: none;"></div> -->
          <div class="order" [ngClass]="{active: orderByF=='schedule_indate',asc:orderF=='ASC'}" (click)="sort('schedule_indate')">Scheduled in date</div>
          <div class="order" [ngClass]="{active: orderByF=='schedule_outdate',asc:orderF=='ASC'}" (click)="sort('schedule_outdate')">Scheduled out date</div>
          <div class="order" [ngClass]="{active: orderByF=='date_updated',asc:orderF=='ASC'}" (click)="sort('date_updated')">Date updated</div>
          <div class="order" [ngClass]="{active: orderByF=='profile_status',asc:orderF=='ASC'}" (click)="sort('profile_status')">Profile status</div>
          <div>Link to manage the report</div>
        </div>
        <div class="table_content" *ngIf="reportListFestive.length > 0">
          <div class="table_row" *ngFor="let report of reportListFestive | paginate: { itemsPerPage: 20, currentPage: q }; index as i;">
          
            <div>{{report.site_name}}</div>
            <div>{{report.channel_entity_id}}</div>
            <div>{{report.channel_name}}</div>
            <div>{{report.profile_name}}</div>
            <!-- <div></div> -->
            <!-- <div>{{report.start_date}}</div> -->
            <div>{{report.schedule_indate}}</div>
            <div>{{report.schedule_outdate}}</div>
            <div>{{report.date_updated}}</div>
            <div>{{report.profile_status}}</div>
            <div style="text-align: center;">
              <a style="text-decoration: underline;" title="View source profile" (click)="viewPlaylist(report.playlist_id,report.channel_id,report.client_id)" >View profile</a>
            </div>
            
          </div>
        </div>
        <div *ngIf="reportListFestive.length > 0" style="text-align: center; font-size: 12px;">
          <pagination-controls (pageChange)="q = $event"></pagination-controls>
        </div>
        <div class="table_content nf" *ngIf="reportListFestive.length <= 0 && !isLoad" style="text-align: center;
        margin-top: 50px;
        font-size: 16px;">
          <span>No results found</span>
        </div>
      </div>
    <!-- TABLE END -->
    </div>

</div>
