<div class="input_autocolplete">
  <input
    type="text"
    name="{{inputName}}"
    value="{{inputVal}}"
    (blur)="blured($event)"
    (focus)="focused($event)"
    (keyup)="search($event)"
    [(ngModel)]="inputVal"
    [disabled]="disabled ? '' : null"
    id="{{inputID ? 'additional' : ''}}"/>
  <ul class="list" [ngClass]="{'active': isVisible}">
    <li *ngFor="let q of queryVal" (click)="addInfo(q.name,q.id)">{{q.name}}</li>
  </ul>
</div>
