<app-page-header [pageTitle]="'Create, edit and manage your playlists'"></app-page-header>
<div class="top_box">
  <div class="mf_buttons">
    <div class="search_box">
      <form method="get" #searchForm="ngForm">
        <input type="text" (focus)="focusInput()" (blur)="blurInput()" name="search" value="{{ searchStr }}"
          placeholder="Playlist search" class="searchInput" [(ngModel)]="searchStr" maxlength="30" />
        <button type="submit" class="searchBtn" (click)="search($event)"></button>
        <a href="#" class="clear_search" (click)="clearSearch($event)"></a>
      </form>
    </div>
    <button class="create_category_btn" (click)="openCategoriesPopup($event, null)" *ngIf="
        user != null &&
        (user.type == 'Consultant' || user.type == 'Administrator')
      " title="New Category">
      Create New Category
    </button>
    <button class="create_subcategory_btn" (click)="openSubcategoriesPopup($event, null)" *ngIf="
        user != null &&
        (user.type == 'Consultant' || user.type == 'Administrator')
      " title="New Subcategory">
      Create New Sub Category
    </button>
    <button class="create_playlist_btn" (click)="openPlaylistPopup($event, null)" *ngIf="
        user != null &&
        (user.type == 'Consultant' || user.type == 'Administrator')
      " title="New Foundation Playlist">
      Create Foundation Playlist
    </button>
  </div>
</div>
<div class="foundation_playlist_main">
  <div class="breadcrumbs">
    <a href="#" (click)="resetCheckedElements(1)">Foundation Playlists</a>
    <a href="#" *ngIf="selectedCategoryId.id != null" (click)="resetCheckedElements(2)">{{ selectedCategoryId.name
      }}</a>
    <a href="#" *ngIf="selectedSubcategoryId.id != null" (click)="resetCheckedElements(3)">{{ selectedSubcategoryId.name
      }}</a>
  </div>
  <div class="left_block">
    <div class="categories_block box" *ngIf="categoriesList != undefined">
      <ul>
        <li *ngFor="let c of categoriesList" (click)="clickedInside($event)"
          [ngClass]="{ opened: selectedCategoryId.id == c.id }">
          <span maxlength="15" (click)="selectCategory(c.id, c.name, 1)">{{
            c.name
            }}</span>
          <div class="actions_wrap">
            <a href="#" class="arrow" (click)="selectCategory(c.id, c.name, 1)"></a>
            <ul class="actions_popup" *ngIf="
                isShowActions.number == 1 &&
                user != null &&
                (user.type == 'Consultant' || user.type == 'Administrator')
              ">
              <li>
                <a href="#" class="playlist_categories_edit" (click)="openCategoriesPopup($event, c.id)"></a>
              </li>
              <li>
                <a href="#" class="playlist_categories_delete" (click)="openConfirmPopup(c.id, 0)"></a>
              </li>
            </ul>
          </div>
        </li>
      </ul>
    </div>
    <div class="playlists_block box" *ngIf="selectedCategoryId.id != null">
      <ul>
        <li *ngFor="let sc of subcategories" (click)="clickedInside($event)"
          [ngClass]="{ opened: selectedSubcategoryId.id == sc.id }">
          <span (click)="selectSubcategory(sc.id, sc.name, 1)">{{
            sc.name
            }}</span>
          <div class="actions_wrap">
            <a href="#" class="arrow" (click)="selectSubcategory(sc.id, sc.name, 1)"></a>
            <ul class="actions_popup" *ngIf="
                isShowActions.number == 2 &&
                user != null &&
                (user.type == 'Consultant' || user.type == 'Administrator')
              ">
              <li>
                <a href="#" class="playlist_categories_edit" (click)="openCategoriesPopup($event, sc.id)"></a>
              </li>
              <li>
                <a href="#" class="playlist_categories_delete" (click)="openConfirmPopup(sc.id, 0)"></a>
              </li>
            </ul>
          </div>
        </li>
      </ul>
    </div>
  </div>


  <div class="playlists_list_block" *ngIf="selectedSubcategoryId.id != null">
    <ul class="tabs">
      <li [ngClass]="{selected: tabs.selected == 1}" (click)="selectTab(1)">Playlists</li>
      <li [ngClass]="{selected: tabs.selected == 2}" (click)="selectTab(2)">Archive</li>
    </ul>
    <div class="table_wrap" *ngIf="tabs.selected == 1">
      <div class="table_titles">
        <div>Name</div>
        <div>Editor</div>
        <div>Date Added</div>

        <div>Date Updated</div>
        <div># of Tracks</div>
        <div>Status</div>
        <div>Functions</div>
        <div>Actions</div>
      </div>
      <div class="table_content">
        <div class="table_row" *ngFor="let p of playlists">
          <div>
            <span>{{ p.name }}</span>
          </div>
          <div>
            <strong *ngIf="p.last_editor != null">{{ p.last_editor.first_name }}
              {{ p.last_editor.last_name }}</strong>
          </div>
          <div>{{ p.created_at }}</div>

          <div>{{ p.updated_at }}</div>
          <div>{{ p.files_count }}</div>
          <select id="Id" #Id="ngModel" class="custom-select hideLabel form-control" [(ngModel)]="p.status" name="Id"   (change)="setStatus($event,p.id)">  
            <option value="0">LIVE</option>  
            <option value="1">EDITING</option>  
            <option value="2">SUSPENDED</option>
            <option value="3">ARCHIVE</option>
          </select>
          <div>
            <a class="playlist_manage" title="Manage" (click)="manageClick(p.id)"
              routerLink="/manage-playlists">Manage</a>
          </div>
          <div>
            <!-- <span class="playlist_suspend" *ngIf="p.status == '1'">{{
              playlistSuspendingText[p.status]
              }}</span> -->
            <!-- <a class="playlist_suspend" *ngIf="p.status == '0'" (click)="setStatus($event, p.id, 2)" title="Suspend"
              href="#">{{ playlistSuspendingText[p.status] }}</a>
            <a class="playlist_suspend" *ngIf="p.status == '2'" (click)="setStatus($event, p.id, 0)" title="Unsuspend"
              href="#">{{ playlistSuspendingText[p.status] }}</a> -->
            <!-- <div class="editing_wrap" *ngIf="
                user != null &&
                (user.type == 'Consultant' || user.type == 'Administrator')
              ">
              <a class="playlist_edit" title="Edit" href="#" (click)="openPlaylistPopup($event, p.id)"></a>
            </div> -->
            <a class="playlist_edit" title="Edit" href="#" (click)="openPlaylistPopup($event, p.id)"></a>
            <a class="playlist_delete" title="Delete" href="#" (click)="openConfirmPopup(p.id, 1)" *ngIf="
                user != null &&
                (user.type == 'Consultant' || user.type == 'Administrator')
              "></a>
            <a [ngClass]="
                playerInfo.playlistID == p.id && playerInfo.isPlaying == true
                  ? 'track_paused'
                  : 'track_play'
              " title="Play" href="#" (click)="startPlaylist($event, p.id)"></a>
          </div>
        </div>
      </div>
    </div>
    <div class="table_wrap" *ngIf="tabs.selected == 2">
      <div class="table_titles">
        <div>Name</div>
        <div>Editor</div>
        <div>Date Added</div>

        <div>Date Updated</div>
        <div># of Tracks</div>
        <div>Status</div>
        <div>Functions</div>
        <div>Actions</div>
      </div>
      <div class="table_content">
        <div class="table_row" *ngFor="let p of archivePlaylist">
          <div>
            <span>{{ p.name }}</span>
          </div>
          <div>
            <strong *ngIf="p.last_editor != null">{{ p.last_editor.first_name }}
              {{ p.last_editor.last_name }}</strong>
          </div>
          <div>{{ p.created_at }}</div>

          <div>{{ p.updated_at }}</div>
          <div>{{ p.files_count }}</div>
          <div>
            <select id="Id" #Id="ngModel" class="custom-select hideLabel form-control" [(ngModel)]="p.status" name="Id"  (change)="setStatus($event,p.id)">  
              <option value="0">LIVE</option>  
              <option value="1">EDITING</option>  
              <option value="2">SUSPENDED</option>
              <option value="3">ARCHIVE</option>
            </select>
          </div>
          <div>
            <a class="playlist_manage" title="Manage" (click)="manageClick(p.id)"
              routerLink="/manage-playlists">Manage</a>
          </div>
          <div>
            <!-- <span class="playlist_suspend" *ngIf="p.status == '1'">{{
              playlistSuspendingText[p.status]
              }}</span>
            <a class="playlist_suspend" *ngIf="p.status == '0'" (click)="setStatus($event, p.id, 2)" title="Suspend"
              href="#">{{ playlistSuspendingText[p.status] }}</a>
            <a class="playlist_suspend" *ngIf="p.status == '2'" (click)="setStatus($event, p.id, 0)" title="Unsuspend"
              href="#">{{ playlistSuspendingText[p.status] }}</a> -->
            <!-- <div class="editing_wrap" *ngIf="
                user != null &&
                (user.type == 'Consultant' || user.type == 'Administrator')
              ">
              <a class="playlist_edit" title="Edit" href="#" (click)="openPlaylistPopup($event, p.id)"></a>
            </div> -->
            <a class="playlist_delete" title="Delete" href="#" (click)="openConfirmPopup(p.id, 1)" *ngIf="
                user != null &&
                (user.type == 'Consultant' || user.type == 'Administrator')
              "></a>
            <a [ngClass]="
                playerInfo.playlistID == p.id && playerInfo.isPlaying == true
                  ? 'track_paused'
                  : 'track_play'
              " title="Play" href="#" (click)="startPlaylist($event, p.id)"></a>
          </div>
        </div>
      </div>
    </div>
   
  </div>
</div>

<div class="popup create_category_popup" [ngClass]="{ opened: categoriesPopupOpened }" ngDraggable ngResizable
  [rzHandles]="'n,e,s,w,se,sw,ne,nw'" [handle]="dragHandle">
  <a href="#" class="popup_close" (click)="popupClose($event)"></a>
  <div class="popup_title" #dragHandle>Create/Edit Category</div>
  <div class="popup_form">
    <form #updateCategories="ngForm">
      <input type="hidden" name="visible" value="{{ categoriesFields.visible }}"
        [(ngModel)]="categoriesFields.visible" />
      <div class="input_wrap">
        <label>Name</label>
        <input autocomplete="off" type="text" maxlength="50" name="name" value="{{ categoriesFields.name }}"
          [(ngModel)]="categoriesFields.name" />
      </div>
      <div class="popup_btns">
        <button type="button" class="cancel" (click)="popupClose($event)">
          Cancel
        </button>
        <button type="submit" (click)="categoryUpdate($event, updateCategories)">
          Save
        </button>
      </div>
    </form>
  </div>
</div>
<div class="popup create_subcategory_popup" [ngClass]="{ opened: subcategoriesPopupOpened }" ngDraggable ngResizable
  [rzHandles]="'n,e,s,w,se,sw,ne,nw'" [handle]="dragHandle2">
  <a href="#" class="popup_close" (click)="popupClose($event)"></a>
  <div class="popup_title" #dragHandle2>Create/Edit Sub category</div>
  <div class="popup_form">
    <form #updateSubcategories="ngForm">
      <input type="hidden" name="visible" value="{{ subcategoriesFields.visible }}"
        [(ngModel)]="subcategoriesFields.visible" />
      <div class="input_wrap">
        <label>Name</label>
        <input autocomplete="off" type="text" maxlength="50" name="name" value="{{ subcategoriesFields.name }}"
          [(ngModel)]="subcategoriesFields.name" />
      </div>
      <div class="input_wrap">
        <label>Parent category</label>
        <select name="parent_id" [(ngModel)]="subcategoriesFields.catId" *ngIf="categoriesList != null">
          <option value="null" [selected]="subcategoriesFields.catId == null">Select category...</option>
          <option *ngFor="let c of categoriesList" value="{{ c.id }}" [selected]="subcategoriesFields.catId == c.id">
            {{ c.name }}</option>
        </select>
      </div>
      <div class="popup_btns">
        <button type="button" class="cancel" (click)="popupClose($event)">
          Cancel
        </button>
        <button type="submit" (click)="subcategoryUpdate($event, updateSubcategories)">
          Save
        </button>
      </div>
    </form>
  </div>
</div>
<div class="popup create_playlist_popup" [ngClass]="{ opened: playlistPopupOpened }" ngDraggable ngResizable
  [rzHandles]="'n,e,s,w,se,sw,ne,nw'" [handle]="dragHandle3">
  <a href="#" class="popup_close" (click)="popupClose($event)"></a>
  <div class="popup_title" #dragHandle3>Create/Edit Playlist</div>
  <div class="popup_form">
    <form #updateFoundationPlaylist="ngForm">
      <input type="hidden" name="status" value="{{ playlistFields.status }}" [(ngModel)]="playlistFields.status" />
      <div class="input_wrap">
        <label>Name</label>
        <input autocomplete="off" type="text" maxlength="255" name="name" value="{{ playlistFields.name }}"
          [(ngModel)]="playlistFields.name" />
      </div>
      <div class="input_wrap">
        <label>Category</label>
        <select name="foundation_category_id" *ngIf="categoriesList != null" [(ngModel)]="playlistFields.catId"
          (change)="changeCategory($event)">
          <option value="null" [selected]="playlistFields.catId == null">Select category...</option>
          <option *ngFor="let c of categoriesList" value="{{ c.id }}" [selected]="playlistFields.catId == c.id">
            {{ c.name }}</option>
        </select>
      </div>

      <div class="input_wrap" *ngIf="subcategoriesList != null">
        <label>Sub category</label>
        <select name="foundation_subcategory_id" [(ngModel)]="playlistFields.subcatId">
          <option value="null" [selected]="playlistFields.subcatId == null">Select sub category...</option>
          <option *ngFor="let sc of subcategoriesList" value="{{ sc.id }}"
            [selected]="playlistFields.subcatId == sc.id">{{ sc.name }}</option>
        </select>
      </div>


      <div class="popup_btns">
        <button type="button" class="cancel" (click)="popupClose($event)">
          Cancel
        </button>
        <button type="submit" (click)="playlistDataUpdate($event, updateFoundationPlaylist)">
          Save
        </button>
      </div>
    </form>
  </div>
</div>
<div class="popup search_playlist_popup" [ngClass]="{ opened: searchPopupOpened }" ngDraggable ngResizable
  [rzHandles]="'n,e,s,w,se,sw,ne,nw'" [handle]="dragHandle4">
  <a href="#" class="popup_close" (click)="popupClose($event)"></a>
  <div class="popup_title" #dragHandle4>Search: {{ searchStr }}</div>
  <div class="popup_form">
    <div class="table_wrap search_table">
      <div class="table_titles">
        <div>Name</div>
        <div>Category</div>
        <div>Subcategory</div>
        <div>Date Added</div>
        <div>Date Updated</div>
        <div># of Tracks</div>
        <div>Status</div>
        <div>Functions</div>
        <div>Linked CP List</div>
      </div>
      <div class="table_content" *ngIf="searchedPlaylists.length > 0">
        <div class="table_row" *ngFor="let sp of searchedPlaylists">
          <div>{{ sp.name }}</div>
          <div>{{ sp.category_name }}</div>
          <div>{{ sp.sub_category_name }}</div>
          <div>{{ sp.created_at }}</div>
          <div>{{ sp.updated_at }}</div>
          <div>{{ sp.file_counts }}</div>
          <select id="Id" #Id="ngModel" class="custom-select hideLabel form-control" [(ngModel)]="p.status" name="Id"   (change)="setStatus($event,p.id)">  
            <option value="0">LIVE</option>  
            <option value="1">EDITING</option>  
            <option value="2">SUSPENDED</option>
            <option value="3">ARCHIVE</option>
          </select>
          <div>
            <a class="playlist_manage" title="Manage" (click)="manageClick(sp.id)"
              routerLink="/manage-playlists">Manage</a>
            <!-- <span class="playlist_suspend" *ngIf="sp.status == '1'">{{
              playlistSuspendingText[sp.status]
              }}</span>
            <a class="playlist_suspend" *ngIf="sp.status == '0'" (click)="setStatus($event, sp.id, 2)" title="Suspend"
              href="#">{{ playlistSuspendingText[sp.status] }}</a>
            <a class="playlist_suspend" *ngIf="sp.status == '2'" (click)="setStatus($event, sp.id, 0)" title="Unsuspend"
              href="#">{{ playlistSuspendingText[sp.status] }}</a> -->
            <!-- <div class="editing_wrap">
              <a class="playlist_edit" title="Edit" href="#" (click)="openPlaylistPopup($event, sp.id)"></a>
            </div> -->
            <a class="playlist_delete" title="Delete" href="#" (click)="openConfirmPopup(sp.id, 1)"></a>
          </div>
          <div>
            <div class="linked_foundation_wrap" *ngIf="sp.cp_playlists != null"
              [ngClass]="{ selected: selectedCP == sp.id }" (click)="showCP(sp.id)">
              <a routerLink="/manage-client-playlists" (click)="manageCPClick(sp.cp_playlists[0].id)"
                *ngIf="sp.cp_playlists.length > 0 && sp.cp_playlists.length < 2">{{ sp.cp_playlists[0].name }}</a>
              <span *ngIf="sp.cp_playlists.length > 1">{{
                sp.cp_playlists[0].name
                }}</span>
              <ul *ngIf="sp.cp_playlists.length > 1">
                <li *ngFor="let fi of sp.cp_playlists">
                  <a routerLink="/manage-client-playlists" (click)="manageCPClick(fi.id)">{{ fi.name }}</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="table_content nf" *ngIf="searchedPlaylists.length <= 0">
        <span>No results found</span>
      </div>
    </div>
    <div class="popup_btns">
      <button type="button" class="cancel" (click)="popupClose($event)">
        Close
      </button>
    </div>
  </div>
</div>
<app-player-component [isInputFocused]="isFocused" [storage]="playerInfo.storage" [token]="playerInfo.token"
  [track]="playerInfo.trackId" [wave]="playerInfo.trackId" [album]="playerInfo.album" [artist]="playerInfo.artist"
  (outputPlaying)="checkPlaying($event)" (next)="nextTrack($event)" (prev)="prevTrack($event)"></app-player-component>

<app-alert></app-alert>
<app-confirm (isConfirm)="isConfirm($event)"></app-confirm>
