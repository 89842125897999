<div class="page_wrap">
  <app-page-header [logout]="true" [pageTitle]="'Create, edit and manage your playlists'"></app-page-header>
  <div *ngIf="isForm == 0" class="loading">
    <h1>Loading...</h1>
  </div>
  <div *ngIf="isForm == 2" class="error">
    <h2>Error</h2>
    <h2>{{message}}</h2>
    <a routerLink="/login">Back to homepage</a>
  </div>
  <form #resetForm="ngForm" class="login_form" *ngIf="isForm == 1">
    <h2>Reset password</h2>
    <input type="hidden" value="{{userId}}" name="user_id" [(ngModel)]="userId"/>
    <input type="hidden" value="{{hash}}" name="hash" [(ngModel)]="hash"/>
    <div class="input_wrap">
      <label>Password</label>
      <input type="password" name="new_password" value="{{newPassword}}" [(ngModel)]="newPassword"/>
    </div>
    <div class="input_wrap">
      <label>Repeat password</label>
      <input type="password" name="repeat_password" value="{{repeatPassword}}" [(ngModel)]="repeatPassword"/>
    </div>
    <div class="popup_btns">
      <button type="submit" (click)="resetDataSend($event,resetForm)">Reset password</button>
      <a routerLink="/login">Back to homepage</a>
    </div>
  </form>
</div>
<app-alert></app-alert>
