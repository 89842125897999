import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {map} from 'rxjs/operators';
import { environment } from '../../environments/environment';
import {Globals} from '../globals';

@Injectable()
export class TagsService{

  httpOptions = {
    headers: new HttpHeaders({
      'Host': environment.dev_url_prefix + '/',
      'Authorization': 'Bearer ' + this.globals.access_token,
      'Accept': 'application/json'
    })
  };
  putOptions = {
    headers: new HttpHeaders({
      'Host': environment.dev_url_prefix + '/',
      'Authorization': 'Bearer ' + this.globals.access_token,
      'Accept': 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded'
    })
  };
  postOptions={
    headers: new HttpHeaders({
      'Host': environment.dev_url_prefix + '/',
      'Authorization': 'Bearer ' + this.globals.access_token,
      'Accept': 'application/json',
      'Content-Type':'application/x-www-form-urlencoded'
    })
  };
  deleteOptions = {
    headers: new HttpHeaders({
      'Host': '/',
      'Authorization': 'Bearer ' + this.globals.access_token,
      'Accept': 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded'
    })
  };

  constructor(
    private http: HttpClient,
    private globals: Globals
  ) { }

  /**
   Update tags
   * @param formData - post data
   * @returns {Observable<any>}
   */
  updatePlaylistTag(formData) {
    return this.http.post(environment.dev_url_prefix + '/api/V1/tag/tags_save', formData, this.postOptions).pipe(map(tags=>{
      return tags;
    }));
  }
  /*getPlaylistTags(pid,type) {
    return this.http.get(environment.dev_url_prefix + '/api/V1/tag/playlist/' + type + '/' + pid, this.httpOptions).pipe(map(tags => {
      return tags;
    }));
  }*/
  /**
   Get tags list
   * @returns {Observable<any>}
   */
  getTags(){
    return this.http.get(environment.dev_url_prefix + '/api/V1/tags', this.httpOptions).pipe(map(tags => {
      return tags;
    }));
  }

  /**
   Set tag for track in playlist
   * @param tid - track id
   * @param formData - post data
   * @param cat - playlist type
   * @returns {Observable<any>}
   */
  setTagsForTrack(tid, formData,cat){
    var str='';
    if(cat=='fp'){
      str = 'fp_';
    }if(cat=='scp'){
      str='scp_';
    }if(cat=='sfp'){
      str='sfp_';
    }

    
    return this.http.post(environment.dev_url_prefix + '/api/V1/tag/' + str + 'add_to_file/' + tid, formData, this.postOptions).pipe(map(tags=>{
      return tags;
    }));
  }

  /**
   Set tag for track in tracks library
   * @param tid - track id
   * @param formData - post data
   * @param cat - playlist type
   * @param pid - playlist id
   * @returns {Observable<any>}
   */
  setTagsForTrackLibrary(tid, formData, cat, pid ){
    return this.http.post(environment.dev_url_prefix + '/api/V1/tag/add_to_file_left/' + pid + '/' + cat + '/' + tid, formData, this.postOptions).pipe(map(tags=>{
      console.log(tags);
      return tags;
    }));
  }
}
