import {Injectable, OnInit} from '@angular/core';
import {CookieService} from 'ngx-cookie-service';

@Injectable()
export class Globals  implements OnInit {
  access_token = this.cookieService.get('access');
  constructor(private cookieService: CookieService){ }
  ngOnInit() {
    // Do nothing
  }
}
