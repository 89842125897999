import { Component, OnInit, ViewChild } from "@angular/core";
import { UserService } from "../services/user.service";
import { NgForm } from "@angular/forms";
import { AlertComponent } from "../alert/alert.component";

@Component({
  selector: "app-create-new-user",
  templateUrl: "./create-new-user.component.html",
  styleUrls: ["./create-new-user.component.scss"]
})
export class CreateNewUserComponent implements OnInit {
  email = "";//User email
  first_name = "";//User first name
  last_name = "";//User last name
  @ViewChild(AlertComponent, { static: true }) alert: AlertComponent;//Component of alert popup
  constructor(private userService: UserService) {}

  ngOnInit() {
    // Do nothing
  }

  /**
   Create new user
   * @param e - event
   * @param {NgForm} form - form
   */
  newUserSend(e, form: NgForm) {
    e.preventDefault();
    var str = Object.keys(form.value)
      .map(function(key) {
        return (
          encodeURIComponent(key) + "=" + encodeURIComponent(form.value[key])
        );
      })
      .join("&");
    if (this.email == "") {
      this.alert.alertOpen("Please enter a valid registered email id", 1);
    } else if (this.first_name == "" || this.last_name == "") {
      this.alert.alertOpen("Please enter First name and Last name", 1);
    } else {
      this.userService.sendNewUserRequest(str).subscribe(
        (request: any) => {
          if (request.result != undefined) {
            this.alert.alertOpen("Your request has been sent to admin", 0);
            this.email = "";
            this.first_name = "";
            this.last_name = "";
          }
          if (request.error != undefined) {
            this.alert.alertOpen(request.error, 1);
          }
        },
        (error: any) => {
          this.alert.alertOpen(error.message, 1);
          console.log(error);
        }
      );
    }
  }
}
