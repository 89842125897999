import {Component, OnInit} from '@angular/core';
import * as alertify from 'alertifyjs';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit {
  opened = false;//Open alert popup
  message = '';//Popup message
  type = 0;//Popup type

  ngOnInit() {
    // Do nothing
  }

  /**
   Open alert popup
   * @param message - alert message
   * @param type - alert type
   * @returns {boolean}
   */
  public alertOpen(message,type, delay = 5){
    alertify.set('notifier','position', 'top-right');
    alertify.set('notifier','delay', delay);
    if(type == 0){
      alertify.success(message);
    }
    else if(type == 1){
      alertify.error(message);
    }
    else if(type == 2){
      alertify.warning(message);
    }
    return false;
  }

  /**
   Close alert popup
   * @returns {boolean}
   */
  public alertClose(){
    this.opened = false;
    return false;
  }
}
